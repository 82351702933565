import messageTypes from '@/json/inlineResponseTypes';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum.ts';
import { AccordionItemBase } from './accordionItemBase';
import { MessageType } from './messageType';
import { AccordionStyle } from '../enums';
import { SelectInlineResponseType } from '.';

export class InlineResponse extends AccordionItemBase {
    constructor(items = []) {
        super(items);
        this.type = ConvoMessageItemType.InlineResponse;
        this.accordionStyle = AccordionStyle.ItemsGroup;
        this.items = [];
        this.title = 'Inline response';
        this.inlineResponseSelector = new SelectInlineResponseType();
        this.inlineResponseSelector.accordionStyle = AccordionStyle.Panel;
    }

    inlineResponseSelector: SelectInlineResponseType;

    addItem(messageItem: AccordionItemBase, type: ConvoMessageItemType) {
        const messageType: MessageType | undefined = messageTypes.find(
            (messageType: MessageType) => messageType.name === type,
        );

        messageItem.accordionStyle = AccordionStyle.Panel;
        messageItem.isDraggable = true;
        messageItem.title = messageType ? messageType.title : '';
        messageItem.hasMarginBottom = false;
        this.items = [messageItem];
    }
}
