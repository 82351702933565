import { UserCommentClient } from '@/api/user-comment';
import { UserComment } from '@/store/models';
import {
    GetUserCommentsRequest,
    UpdateUserCommentRequest,
    UserCommentDto,
} from '@/store/models/dtos';
import { UserCommentFactory } from '@/store/models/factories/userComment.factory';
import {
    SET_DEFAULT_STATE,
    SET_USER_COMMENTS,
    SET_USER_COMMENTS_STATS,
    UPDATE_USER_COMMENT,
} from '@/store/mutations.types';
import { IRootState } from '@/store/types/rootState';

import { CommentsRatingStatus, CommentsRatingStatusReason } from '../enums';
import { IPaging } from '@inconvo/types/interfaces';
import { RetrainClassification, ToxicityType } from '@inconvo/types/enums';
import Vue from 'vue';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { CommentsReportType, CommentSortingOptions } from '../enums';
import { CommentsStatsDto } from '../models/dtos/userComment.dto';
import { GetCommentsStatsRequest } from '../models/dtos/userCommentsRequests.dto';
import { UserCommentStats } from '../models/userComment';

export interface IUserCommentsState {
    comments: IPaging<UserComment>;
    stats: UserCommentStats;
}

export interface IGetUserCommentsQuery {
    page?: number;
    channelCodes?: string[];
    convoIds?: string[];
    commentsText?: string[];
    textInputItemIds?: string[];
    commentsRatingItemIds?: string[];
    status?: CommentsRatingStatus;
    reportType?: CommentsReportType;
    score?: string;
    toxicity?: ToxicityType;
    sort?: CommentSortingOptions;
    statusReason?: CommentsRatingStatusReason;
    toDate?: Date;
    fromDate?: Date;
    userIdentifier?: string;
}

const userCommentClient = new UserCommentClient();

const getDefaultState = (): IUserCommentsState => {
    return {
        comments: {
            items: [],
            total: 0,
            size: 0,
            page: 0,
        },
        stats: {
            total: 0,
            status: {
                flagged: 0,
                safe: 0,
                unclassified: 0,
            },
            retrain: {
                total: 0,
                [RetrainClassification.neutral]: 0,
                [RetrainClassification.offensive]: 0,
                [RetrainClassification.profanity]: 0,
            },
        },
    };
};

const state: IUserCommentsState = getDefaultState();

const getters: GetterTree<IUserCommentsState, IRootState> = {};

const actions: ActionTree<IUserCommentsState, any> = {
    setDefaultState: ({ commit }): void => {
        commit(SET_DEFAULT_STATE);
    },
    getUserCommentsStats: async ({ commit }, date: Date): Promise<void> => {
        const request = {} as GetCommentsStatsRequest;

        if (date) {
            request.from_date = date;
        }

        const result: CommentsStatsDto = await userCommentClient.getUserCommentsStats(request);

        commit(SET_USER_COMMENTS_STATS, result as UserCommentStats);
    },
    getUserComments: async ({ commit }, query: IGetUserCommentsQuery): Promise<void> => {
        const request = new GetUserCommentsRequest();

        if (query?.channelCodes) {
            request.channel_codes = query.channelCodes;
        }

        if (query?.convoIds) {
            request.convo_ids = query.convoIds;
        }

        if (query?.textInputItemIds) {
            request.text_input_item_ids = query.textInputItemIds;
        }

        if (query?.commentsRatingItemIds) {
            request.comments_rating_item_ids = query.commentsRatingItemIds;
        }

        if (query?.status) {
            request.status = query.status;
        }

        if (query?.statusReason) {
            request.status_reason = query.statusReason;
        }

        if (query?.reportType) {
            request.report_type = query.reportType;
        }

        if (query?.toxicity) {
            request.toxicity_type = query.toxicity;
        }

        if (query?.score) {
            if (query?.score?.includes('>=')) {
                request.confidence_gte = query.score.replace('>=', '');
            }
            if (query?.score?.includes('<')) {
                request.confidence_lt = query.score.replace('<', '');
            }
        }

        if (query?.sort) {
            request.sort = query.sort;
        }

        if (query?.commentsText) {
            request.comment_text = query.commentsText;
        }
        if (query?.userIdentifier) {
            request.user_identifier = query.userIdentifier;
        }
        if (query?.fromDate) {
            request.from_date = query.fromDate;
        }

        if (query?.toDate) {
            request.to_date = query.toDate;
        }
        request.size = 25;
        request.page = query.page || 1;

        const result: IPaging<UserCommentDto> = await userCommentClient.getUserComments(request);
        if (!result) {
            return;
        }

        const comments: IPaging<UserComment> = {
            page: result.page,
            size: result.size,
            total: result.total,
            items: result.items.map((item) => UserCommentFactory.make(item)),
        };

        commit(SET_USER_COMMENTS, comments);
    },
    updateUserComment: async ({ commit }, item: UserComment): Promise<void> => {
        const updateData = new UpdateUserCommentRequest();
        updateData.channel_code = item.channelCode;
        updateData.retrain = item.retrain;

        if (item.retrain === true) {
            updateData.retrain_classification = item.retrainClassification;
        }

        if (item.status) {
            updateData.status = item.status;
        }

        if (item.statusReason) {
            updateData.status_reason = item.statusReason;
        }

        const updatedComment = await userCommentClient.updateUserComment(item.id, updateData);
        const comment = UserCommentFactory.make(updatedComment);
        commit(UPDATE_USER_COMMENT, comment);
        Vue.prototype.$toaster.add('Comment has been updated successfully', { type: 'success' });
    },
};

const mutations: MutationTree<IUserCommentsState> = {
    [SET_USER_COMMENTS](state, comments: IPaging<UserComment>): void {
        state.comments = comments;
    },
    [SET_USER_COMMENTS_STATS](state, stats: UserCommentStats): void {
        state.stats = stats;
    },
    [UPDATE_USER_COMMENT](state, comment: UserComment): void {
        const comments = { ...state.comments };
        const commentIndex = comments.items.findIndex((item) => item.id === comment.id);

        if (commentIndex >= 0) {
            comments.items[commentIndex] = comment;
            state.comments = comments;
        }
    },
    [SET_DEFAULT_STATE](state: IUserCommentsState): void {
        Object.assign(state, getDefaultState());
    },
};

export const userComments: Module<IUserCommentsState, IRootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
