<template>
    <div class="file-upload-wrapper" :class="[componentStyleClass]">
        <div class="file-upload" :class="{ disabled }">
            <input
                v-show="!disabled"
                ref="file"
                class="file-upload__input"
                type="file"
                multiple
                :accept="acceptedContentType"
                @change="setFile($event.target.files)"
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                @dragover.prevent
                @drop="onDrop"
            />

            <div class="file-upload__input-icon">
                <circle-progress-bar
                    v-if="showUploadProgress && isUploading"
                    class="file-upload__progress-bar"
                    :percentage="uploadProgress"
                />
                <Icon
                    v-else-if="innerFileName && !innerError"
                    name="file-upload-success"
                    :size="24"
                />
                <Icon
                    v-else-if="innerError && !isUploading"
                    class="error"
                    name="file-upload-error"
                    :size="24"
                />
                <Icon v-else name="file-upload" :size="24" />
            </div>

            <div v-if="!innerFileName" class="file-upload__default-message">
                Click to browse and upload...
            </div>

            <div v-if="innerFileName" class="file-upload__filename">{{ innerFileName }}</div>

            <button
                v-if="showClearIcon && innerFileName"
                class="file-upload__cross-icon"
                type="reset"
                @click="onClear($event)"
            >
                <Icon name="cross" :size="14" />
            </button>
        </div>

        <div v-if="!innerFileName" class="file-upload__description">
            {{
                `Max file size ${filesize(maxFileSize, {
                    exponent: 2,
                })}. ${acceptedExtensionsFormatted} only.`
            }}
        </div>

        <div v-if="showUploadProgress && isUploading" class="file-upload__progress">
            Uploading... {{ uploadProgress }}% complete
        </div>

        <div v-if="innerError && !isUploading" class="error-message">{{ innerError }}</div>
    </div>
</template>

<script>
import filesize from 'filesize';
import Icon from '@/components/ui/Icon.vue';
import CircleProgressBar from '@/components/ui/CircleProgressBar.vue';
import onlyValues from '@/utils/onlyValues.ts';
import { validateFile } from '@/store/services/validationService.ts';

export default {
    name: 'FileUpload',
    components: {
        Icon,
        CircleProgressBar,
    },
    props: {
        inputStyle: {
            type: String,
            default: 'default',
            validator: onlyValues(['default', 'green']),
        },
        fileName: {
            type: String,
            default: '',
        },
        showClearIcon: {
            type: Boolean,
            default: false,
        },
        acceptedContentType: {
            type: String,
            default: 'image/*',
        },
        acceptedExtensions: {
            type: Array,
            default: () => ['jpeg', 'jpg', 'png', 'gif'],
        },
        maxFileSize: {
            type: Number,
            default: 5242880, // bytes
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        dragEnabled: {
            type: Boolean,
            default: true,
        },
        error: {
            type: String,
            default: '',
        },
        isUploading: {
            type: Boolean,
            default: false,
        },
        showUploadProgress: {
            type: Boolean,
            default: false,
        },
        uploadProgress: {
            type: Number,
            default: 0,
        },
        hasMarginBottom: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            innerFileName: this.fileName,
            dragging: false,
            innerError: this.error,
            filesize,
        };
    },
    computed: {
        acceptedExtensionsFormatted() {
            if (this.acceptedExtensions) {
                let formatted = this.acceptedExtensions.map((e) => e.toUpperCase()).join(', ');
                formatted = formatted.replace(/,([^,]*)$/, ' and $1');
                return formatted;
            }
            return '';
        },
        componentStyleClass() {
            const css = [];

            if (this.inputStyle === 'green') {
                css.push('style-green');
            }

            if (this.dragging) {
                css.push('dragging');
            }

            if (this.hasMarginBottom) {
                css.push('form-field--has-bottom-space');
            }

            return css;
        },
    },
    watch: {
        error(newValue, oldValue) {
            this.innerError = newValue;
        },
        isUploading(newValue, oldValue) {
            if (oldValue === true && newValue === false && !this.innerError) {
                this.innerFileName = '';
                this.$refs.file.value = '';
            }
        },
    },
    methods: {
        async setFile(files) {
            if (files.length > 0) {
                this.innerError = validateFile(
                    files[0],
                    {
                        maxFileSize: this.maxFileSize,
                        extensions: this.acceptedExtensions,
                    },
                    'File',
                );

                if (!this.innerError) {
                    this.innerFileName = files[0].name;
                    this.$emit('changed', files[0]);
                }
            }
        },

        onDragEnter(e) {
            if (this.dragEnabled) {
                e.preventDefault();
                this.dragging = true;
            }
        },

        onDragLeave(e) {
            if (this.dragEnabled) {
                e.preventDefault();
                this.dragging = false;
            }
        },

        onDrop(e) {
            e.preventDefault();
            e.stopPropagation();

            if (this.dragEnabled) {
                const element = this.$refs.file;
                element.files = e.dataTransfer.files;

                this.dragging = false;

                this.setFile(e.dataTransfer.files);
            }
        },

        onClear() {
            this.dragging = false;
            this.innerFileName = '';
            if (this.$refs.file) {
                this.$refs.file.value = '';
            }
            this.innerError = null;
            this.$emit('onClearImage');
        },
    },
};
</script>

<style lang="scss" scoped>
.file-upload-wrapper {
    &.style-green {
        .file-upload__description {
            color: $green-style-input;
        }
    }

    .file-upload {
        height: 40px;
        padding: 6px 10px;
        position: relative;
        cursor: pointer;
        border: 1px dashed $input-border-color;
        background: $default-light-color;
        outline-offset: -10px;
        display: flex;

        &.disabled {
            cursor: default;
        }

        &__description {
            font-size: 10px;
            font-weight: $font-weight-light;
            color: $alternate-text-color;
        }

        &__progress {
            font-size: 10px;
            font-weight: $font-weight-bold;
            color: $primary-color;
        }

        &__filename,
        &__default-message {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 1em;
            line-height: 2em;
            font-family: $font-family-default;
            font-size: 14px;
            font-weight: $font-weight-light;
            color: $alternate-text-color;
        }

        &__success,
        &__error {
            margin: -8px 0px 7px 22px;
        }

        &__cross-icon {
            position: absolute;
            top: 13px;
            right: 7px;
            color: $input-clear-icon-color;
        }

        &__input {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
            width: 100%;
            height: 100%;
        }

        &__input-icon {
            svg {
                fill: $button-main-color;
            }

            .error {
                fill: $error-color;
            }
        }

        &__progress-bar {
            margin-top: 4px;
            margin-right: 6px;
        }
    }

    &.dragging {
        .file-upload {
            border: 1px dashed $primary-color;

            button {
                pointer-events: none;
            }
        }
    }

    .error-message {
        margin-bottom: 16px;
        font-size: 12px;
        font-style: italic;
        color: $error-color;
    }
}
</style>
