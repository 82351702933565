import { IContentItem, IConvo, IResultOption } from '../interfaces';
import {
    Carousel,
    ContentItem,
    TextGlobalResponse,
    ButtonsAnswer,
    InlineResponseTextStatement,
    CarouselTile,
} from '..';
import {
    ContentItemType,
    AnswerType,
    ConvoTypes,
    LinkTarget,
    ChartType,
    SortingOrder,
} from '@/store/enums/';
import { IAnswer } from '../interfaces/answer.interface.dto';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum';
import { IWidgetReply } from '../interfaces/widgetReply.interface.dto';
import { IButton } from '../interfaces/button.interface';
import { IConvoState } from '@/store/modules/convo-details';
import { Answer } from '../answer.dto';
import { makePersonalityQuizAttr, makeTriviaQuizAttr } from './contentItemsRequest.factory';
import { TextStatement } from '../textStatement';
import { TextStatementContentItemFactory } from './textStatementContentItem.factory';
import { OperationType } from '@/store/enums/operationType.enum';

const carouselTypes: string[] = [
    ContentItemType.VerticalCarousel,
    ContentItemType.HorizontalCarousel,
    ContentItemType.MatrixCarousel,
];

export class CarouselContentItemFactory {
    public make(carousel: Carousel, state: IConvoState): IContentItem {
        const contentItem = new ContentItem();
        contentItem.id = carousel.id;
        contentItem.sort = carousel.sort;
        contentItem.shuffleAnswers = carousel.areTilesShuffled;
        contentItem.convoId = state.convo.id;
        contentItem.tags = carousel.tags || [];

        // TODO: think about a better solution instead of accessing first item of the array
        const answers = carousel.items[0].items.filter(
            (i) => i.type === ConvoMessageItemType.CarouselTile,
        );

        const carouselTiles = answers as CarouselTile[];

        const isLastAnswerPinned = carouselTiles.some((o) => o.isPinned);

        contentItem.shuffleLast = !isLastAnswerPinned;

        contentItem.answers = this.createAnswers(carousel, carouselTiles, state.convo);

        const reply = carousel.items[1].items.find(
            (i) => i.type === ConvoMessageItemType.TextGlobalResponse,
        ) as TextGlobalResponse;

        contentItem.reply = {} as IWidgetReply;
        contentItem.reply.activated = !!reply.message || carousel.showQuizResults;
        contentItem.reply.message = carousel.showQuizResults ? '' : reply.message;
        contentItem.reply.sortingOrder = reply.sortingOrder;
        contentItem.reply.sortingOrder =
            carousel.resultOptions.chartType === ChartType.None
                ? reply.sortingOrder
                : SortingOrder.None;
        contentItem.reply.resultOptions = {} as IResultOption;
        contentItem.reply.resultOptions.chartType = carousel.resultOptions.chartType;
        contentItem.reply.resultOptions.order = carousel.resultOptions.order;
        contentItem.reply.resultOptions.showResponseCount =
            carousel.resultOptions.chartType !== ChartType.None &&
            carousel.resultOptions.showResponseCount;
        contentItem.reply.tagLine = '';
        contentItem.reply.url = '';
        contentItem.reply.imageUrl = '';

        if (contentItem.answers.length > 1) {
            if (contentItem.answers.some((item) => (item?.buttons || []).length > 1)) {
                contentItem.type = ContentItemType.MatrixCarousel;
            } else {
                contentItem.type = ContentItemType.HorizontalCarousel;
            }
        } else {
            contentItem.type = ContentItemType.VerticalCarousel;
        }

        if (carousel.statement || carousel.children?.length) {
            if (carousel.children && carousel.children.length) {
                contentItem.children = this.updateOrDeleteCarouselChildren(carousel, contentItem);
            } else {
                contentItem.children = this.createCarouselChildren(carousel, contentItem, state);
            }
        }

        return contentItem;
    }

    private createAnswers(carousel: Carousel, answers: CarouselTile[], convo: IConvo) {
        const contentItemAnswers: IAnswer[] = [];

        for (const carouselTile of answers) {
            const answer: IAnswer = new Answer();
            answer.convoId = convo.id;
            answer.type = AnswerType.Reply;
            answer.id = carouselTile.tileId;
            answer.title = carouselTile.tileTitle;
            answer.subtitle = carouselTile.subtitle;
            answer.link = carouselTile.link;
            answer.image = carouselTile.image;
            answer.buttons = [] as IButton[];
            answer.buttons = this.createButtonAnswers(carousel, carouselTile, convo);
            contentItemAnswers.push(answer);
        }

        return contentItemAnswers;
    }

    private createButtonAnswers(carousel: Carousel, carouselTile: CarouselTile, convo: IConvo) {
        const answerButtons: IButton[] = [];

        for (const buttonAnswer of carouselTile.items as ButtonsAnswer[]) {
            const button: IButton = {} as IButton;

            button.convoId = convo.id;
            button.id = buttonAnswer.buttonId;
            button.type = buttonAnswer.answerType.name;
            button.text = buttonAnswer.buttonLabel;
            button.link = buttonAnswer.link;

            if (buttonAnswer.answerType.linkRequired) {
                const linkTarget = buttonAnswer.linkOpenInNewTab
                    ? LinkTarget.Blank
                    : LinkTarget.Modal;
                button.linkTarget = linkTarget;
            }

            // Logic jump
            button.targetChannel = buttonAnswer.logicJump?.items?.channel?.code || '';
            button.targetConvo = buttonAnswer.logicJump?.items?.convo?.id || 0;
            button.targetContentItem = buttonAnswer.logicJump?.items?.message?.id || 0;
            button.goToBlock =
                !button.targetContentItem && button.targetConvo ? button.targetConvo : 0;
            button.goToWidget =
                button.targetConvo && button.targetContentItem ? button.targetContentItem : 0;

            if (convo.type === ConvoTypes.PersonalityQuiz) {
                const personalityAttr = makePersonalityQuizAttr(carousel, buttonAnswer);
                button.quizResultItem = personalityAttr.quizResultItem;
            }

            if (convo.type === ConvoTypes.TriviaQuiz) {
                button.quizResultItem = undefined;
                const triviaAttr = makeTriviaQuizAttr(carousel, buttonAnswer);
                button.ignoreQuizAnswer = triviaAttr.ignoreQuizAnswer;
                button.isCorrectAnswer = triviaAttr.isCorrectAnswer;
            }

            // TODO: if we handle more response than one it will need a modification
            if (
                buttonAnswer.items &&
                buttonAnswer.items.length > 0 &&
                buttonAnswer.items[0].items &&
                buttonAnswer.items[0].items.length > 0
            ) {
                switch (buttonAnswer.items[0].items[0].type) {
                    case ConvoMessageItemType.InlineResponseTextStatement:
                        button.reply = (
                            buttonAnswer.items[0].items[0] as InlineResponseTextStatement
                        ).statement;
                        break;
                }
            }

            answerButtons.push(button);
        }

        return answerButtons;
    }

    private createCarouselChildren(
        carousel: Carousel,
        contentItem: ContentItem,
        state: IConvoState,
    ): IContentItem[] {
        const children: IContentItem[] = [];
        const contentItemFactory = new TextStatementContentItemFactory();
        const textStatment = new TextStatement();
        textStatment.statement = carousel.statement;
        const statementContentItem: ContentItem = contentItemFactory.make(textStatment, state);
        const carouselContentItem: ContentItem = { ...contentItem };
        delete carouselContentItem.id;
        children.push(statementContentItem, carouselContentItem);

        return children;
    }

    private updateOrDeleteCarouselChildren(
        carousel: Carousel,
        contentItem: ContentItem,
    ): IContentItem[] {
        const children: IContentItem[] = [];

        for (let item of carousel.children!) {
            if (carousel.statement) {
                if (item.type === ContentItemType.Statement) {
                    item.title = carousel.statement;
                }
                if (carouselTypes.includes(item.type)) {
                    item = { ...contentItem, id: item.id };
                }
            } else {
                item.operationType = OperationType.Delete;
            }

            children.push(item);
        }
        return children;
    }
}
