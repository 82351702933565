<template>
    <svg
        class="circle-progress-bar"
        enable-background="new 0 0 18 18"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            class="circle-progress-bar__bg"
            d="M9,1c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S4.6,1,9,1z"
            stroke="#c6c1d0"
            stroke-linecap="round"
            stroke-miterlimit="1"
            stroke-width="2"
            fill="none"
        />
        <path
            ref="path"
            d="M9,1c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S4.6,1,9,1z"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-miterlimit="1"
            stroke-width="2"
            fill="none"
        />
    </svg>
</template>

<script>
export default {
    name: 'CircleProgressBar',
    props: {
        percentage: {
            type: Number,
            default: 0,
            validator: function (v) {
                return Number.isInteger && v >= 0 && v <= 100;
            },
        },
    },
    watch: {
        percentage(newValue) {
            this.setPath(newValue);
        },
    },
    mounted() {
        this.setPath(this.percentage);
    },
    methods: {
        setPath(percentage) {
            const path = this.$refs.path;
            if (!path) return;
            const length = path.getTotalLength();
            path.style.strokeDasharray = length;
            const offset = (percentage / 100) * length + length;
            path.style.strokeDashoffset = offset * -1;
        },
    },
};
</script>

<style lang="scss" scoped>
$circle-progress-bar-bg-color: #c6c1d0 !default;
$circle-progress-bar-color: #24b2c3 !default;

.circle-progress-bar {
    color: $circle-progress-bar-color;
    width: 18px;
    height: 18px;

    &__bg {
        stroke: $circle-progress-bar-bg-color;
    }
}
</style>
