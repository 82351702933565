<template>
    <div class="form-group" :class="[{ 'form-group--has-m-b': hasMarginBottom }]">
        <div
            class="options-group"
            :class="{
                'form-field': isFormFieldStyled,
                'options-group--button-style': isButtonStyled,
                'options-group--grouped': isGrouped,
                'options-group--horizontal': isHorizontal,
                'form-field--has-error': errors.length > 0,
                'form-field--has-bottom-space': hasMarginBottom,
            }"
        >
            <div v-if="isFormFieldStyled" class="form-field-label">
                <span>{{ label }}</span>
            </div>

            <div v-if="description" class="options-group__description">
                {{ description }}
            </div>

            <div class="options-group__options">
                <template v-if="isButtonStyled">
                    <ButtonStyleRadioButton
                        v-for="(option, index) of options"
                        :id="`options-${option.value}`"
                        :key="index"
                        class="options-group__options__option"
                        :is-grouped="isGrouped"
                        :has-margin-bottom="false"
                        :name="name"
                        :label="option.name"
                        :input-value="option.value"
                        :disabled="option.disabled"
                        :icon="option.icon"
                        :value="value"
                        @input="(value) => $emit('input', value)"
                    />
                </template>
                <template v-else>
                    <RadioButton
                        v-for="(option, index) of options"
                        :id="`options-${option.value}`"
                        :key="index"
                        class="options-group__options__option"
                        :has-margin-bottom="false"
                        :name="name"
                        :label="option.name"
                        :input-value="option.value"
                        :icon="option.icon"
                        :disabled="option.disabled"
                        :value="value"
                        @input="(value) => $emit('input', value)"
                    />
                </template>
            </div>
        </div>

        <div class="form-field-additional-information">
            <div class="form-field-additional-information__left">
                <div v-if="errors && errors.length > 0" class="form-field-error-message">
                    <Icon name="alert" />{{ errors[0] }}
                </div>
                <div v-if="infoText" class="form-field-info-text">{{ infoText }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import RadioButton from '@/components/forms/RadioButton';
import ButtonStyleRadioButton from '@/components/forms/ButtonStyleRadioButton';
import Icon from '@/components/ui/Icon';

export default {
    name: 'RadioButtonGroup',
    components: {
        RadioButton,
        ButtonStyleRadioButton,
        Icon,
    },
    props: {
        value: {
            type: String,
            default: undefined,
        },
        name: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        errors: {
            type: Array,
            default: () => [],
        },
        isFormFieldStyled: {
            type: Boolean,
            default: false,
        },
        isButtonStyled: {
            type: Boolean,
            default: false,
        },
        isGrouped: {
            type: Boolean,
            default: false,
        },
        isHorizontal: {
            type: Boolean,
            default: false,
        },
        infoText: {
            type: String,
            default: undefined,
        },
        hasMarginBottom: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
$form-group-margin-bottom: 20px !default;

.options-group {
    &__description {
        font-weight: $font-weight-light;
    }

    &__options {
        margin-top: -12px;
        padding: 7px;

        &__option {
            padding-top: 12px;
        }
    }

    &--horizontal {
        .options-group__options {
            display: flex;
            flex-wrap: wrap;

            &__option {
                margin-right: 16px;
            }
        }
    }

    &--grouped {
        &.form-field {
            padding: 14px 12px;
        }

        .options-group__options {
            display: flex;
            flex-flow: row wrap;

            &__option {
                width: 33%;
            }
        }

        &.options-group--button-style {
            .options-group__options {
                flex-wrap: nowrap;

                &__option {
                    width: auto;
                    flex-grow: 1;
                    flex-basis: 0;
                }
            }
        }
    }
}

.form-group {
    &--has-m-b {
        margin-bottom: $form-group-margin-bottom;
    }
}
</style>
