<template>
    <div class="autocomplete-result">
        {{ result.text }}

        <div class="autocomplete-result-badges">
            <Badge
                v-if="showContentId && result.id"
                type="neutral"
                class="autocomplete-result-badge"
            >
                {{ result.id }}
            </Badge>

            <Badge v-if="result.inactive" type="neutral" class="autocomplete-result-badge">
                inactive
            </Badge>

            <Badge v-if="result.code" type="neutral" class="autocomplete-result-badge">
                {{ result.code }}
            </Badge>

            <Badge :type="result.type" class="autocomplete-result-badge">
                {{ result.label }}
            </Badge>
        </div>
    </div>
</template>

<script>
import Badge from '@/components/ui/Badge';

export default {
    name: 'AutoCompleteResult',
    components: {
        Badge,
    },
    props: {
        result: {
            type: Object,
            required: true,
        },
        showContentId: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
.autocomplete-result,
.autocomplete-result-badges {
    display: flex;
    align-items: center;
}

.autocomplete-result {
    justify-content: space-between;
}

.autocomplete-result-badges {
    margin-left: auto;
}

.autocomplete-result-badge {
    margin-left: 2px;
}
</style>
