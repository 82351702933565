<template>
    <div ref="container" class="form-group">
        <slot
            :input-events="inputEvents"
            :raw-value="rawValue"
            :interpolated-value="interpolatedValue"
            :html-value="htmlValue"
        />

        <Overlay
            css-class="variable-list-wrapper"
            disable-body-scroll
            disable-overlay-scroll
            :transparency="0.9"
            :show="isVariableSelectionActive"
            :show-close-button="true"
            @onClose="onOverlayClose"
        >
            <List
                class="variable-list"
                :data="variableList"
                :text="filterText"
                :selected-index="selectedVariableIndex"
                :is-visible="true"
                @select="onSelect"
                @listChanged="onListChanged"
            />
        </Overlay>
    </div>
</template>

<script>
import itemFormMixin from '@/mixins/itemFormMixin';
import caretUtils from '@/mixins/caretUtils';
import List from '@/components/ui/List';
import Overlay from '@/components/ui/Overlay';

export default {
    name: 'TemplateVariables',
    components: {
        Overlay,
        List,
    },
    mixins: [itemFormMixin, caretUtils],
    props: {
        value: {
            type: String,
            default: '',
        },
        sortingOrder: {
            type: String,
            default: '',
        },
        variableList: {
            type: Array,
            default: () => [],
        },
        variableValues: {
            type: Object,
            default: () => ({}),
        },
        triggerText: {
            type: String,
            default: '{{',
        },
    },
    data() {
        return {
            rawValue: this.value,
            currentValue: this.value,
            carretPosition: 0,
            variable: '',
            isVariableSelectionActive: false,
            selectedVariableIndex: -1,
            variableSelectorXYPos: {
                left: 0,
                top: 0,
            },
            currentVariables: [],
        };
    },
    computed: {
        htmlValue() {
            return this.parseText(this.value, this.variables);
        },
        interpolatedValue() {
            let value = this.value;
            if (!this.interpolationEnabled) {
                return value;
            }
            for (const key in this.variableValues) {
                value = value.replaceAll(`{{${key}}}`, this.variableValues[key]);
            }
            return value;
        },
        interpolationEnabled() {
            return Object.keys(this.variableValues).length > 0;
        },
        inputEvents() {
            return {
                input: this.onInput.bind(this),
                keyup: this.onKeyup.bind(this),
                keypress: this.onKeypress.bind(this),
                mouseup: this.onMouseup.bind(this),
                focus: this.onFocus.bind(this),
                blur: this.onBlur.bind(this),
            };
        },
        filterText() {
            return this.variable.toLowerCase().replace(new RegExp(this.triggerText, 'g'), '');
        },
        variables() {
            return this.variableList
                .map((item) => item.items)
                .reduce((acc, val) => acc.concat(val), []);
        },
        selectedVariable() {
            return this.currentVariables.find((item) => item.index === this.selectedVariableIndex);
        },
    },
    watch: {
        interpolatedValue: {
            immediate: true,
            handler(value) {
                if (this.interpolationEnabled) {
                    this.$emit('interpolated', value);
                }
            },
        },
        currentValue(newValue) {
            var container = document.createElement('div');
            container.innerHTML = newValue;

            const chips = container.querySelectorAll('.chip');
            for (const chip of chips) {
                const dataValue = chip.getAttribute('data-value');
                const variable = this.variables.find((o) => o.key === dataValue);
                if (variable) {
                    const newText = document.createTextNode(variable.value);
                    const parent = chip.parentNode;
                    parent.replaceChild(newText, chip);
                }
            }

            const parsed = container.textContent.trim();
            container.remove();

            this.rawValue = parsed;
            this.$emit('input', parsed);
        },
        isVariableSelectionActive(newValue) {
            if (newValue === false) {
                this.selectedVariableIndex = -1;
            }
        },
    },
    mounted() {
        const setCloseListener = () => {
            this.$refs.container
                .querySelectorAll('.chip .close')
                .forEach((el) => el.addEventListener('click', this.onRemoveVariable));
        };
        setTimeout(setCloseListener, 100);
    },
    beforeDestroy() {
        if (!this.contentEditorElement) {
            return;
        }
        this.$refs.container
            .querySelectorAll('.chip .close')
            .forEach((el) => el.removeEventListener('click', this.onRemoveVariable));
    },
    methods: {
        updateCarretPosition() {
            this.carretPosition = this.getCarretPosition();
        },
        parseText(text, variables) {
            let output = text;
            for (const variable of variables) {
                const hasVariable =
                    this.sortingOrder === ''
                        ? text.includes(variable.value)
                        : text.includes(variable.value) &&
                          this.sortingOrder == variable.sortingOrder;
                if (hasVariable) {
                    const chip = this.createVariable(variable.displayText, variable.key);
                    output = output.replace(variable.value, chip.outerHTML);
                }
            }
            return output;
        },
        createVariable(text, value) {
            const chipCloseElement = document.createElement('span');
            chipCloseElement.className = 'close';
            chipCloseElement.textContent = 'x  ';
            chipCloseElement.addEventListener('click', this.onRemoveVariable);
            const chipText = document.createTextNode(`  ${text}  `);
            const chipElement = document.createElement('span');
            chipElement.className = 'chip';
            chipElement.appendChild(chipText);
            chipElement.appendChild(chipCloseElement);
            chipElement.setAttribute('contenteditable', false);
            chipElement.setAttribute('data-value', value);
            return chipElement;
        },
        addVariable() {
            const chipElement = this.createVariable(
                this.selectedVariable.displayText,
                this.selectedVariable.key,
            );
            this.replaceContent(this.triggerText, chipElement);
            this.onCarretPositionChanged();
            this.selectedVariableIndex = -1;
            this.currentValue = this.contentEditorElement.innerHTML;
        },
        onRemoveVariable(event) {
            const chip = event.srcElement.parentNode;
            const containerContent = this.contentEditorElement.innerHTML;
            const newValue = containerContent.replace(chip.outerHTML, '');
            this.currentValue = newValue;
            chip.remove();
        },
        onOverlayClose(event) {
            this.isVariableSelectionActive = false;
            this.variable = this.getCurrentWord(this.triggerText).text;

            if (this.variable) {
                const containerContent = this.contentEditorElement.innerHTML;
                this.replaceContent(this.triggerText, '');
                const newValue = containerContent.replace(this.triggerText, '');
                this.currentValue = newValue.trim();
                this.variable = '';
            }
        },
        onCarretPositionChanged() {
            this.variable = this.getCurrentWord(this.triggerText).text;
            if (this.variable) {
                this.isVariableSelectionActive = true;
            } else {
                this.variable = '';
            }
        },
        onInput(value) {
            if (!this.isVariableSelectionActive) {
                this.currentValue = value;
            }
        },
        onKeypress(event) {
            if (this.isVariableSelectionActive) {
                if (event.keyCode === 13 || event.keyCode === 38 || event.keyCode === 40) {
                    // enter or arrow-up or arrow-down
                    event.preventDefault();
                    if (event.keyCode === 13 && this.selectedVariableIndex >= 0) {
                        if (this.variables.length) {
                            this.addVariable();
                            this.variable = '';
                            this.isVariableSelectionActive = false;
                        }
                    }
                } else {
                    event.preventDefault();
                }
            }
        },
        onKeyup(event) {
            const keycode = event.keyCode || event.which;
            if (this.isVariableSelectionActive) {
                if (keycode === 40) {
                    // arrow-down
                    if (this.selectedVariableIndex < this.variables.length - 1) {
                        this.selectedVariableIndex++;
                    }
                } else if (keycode === 38) {
                    // arrow-up
                    if (this.selectedVariableIndex > 0) {
                        this.selectedVariableIndex--;
                    }
                } else {
                    event.preventDefault();
                }
            } else {
                if (keycode !== 38 && keycode !== 40) {
                    // arrow-up or arrow-up
                    this.updateCarretPosition();
                    this.onCarretPositionChanged();
                }
            }
        },
        onMouseup() {
            this.onCarretPositionChanged();
            this.updateCarretPosition();
        },
        onFocus(event) {
            this.contentEditorElement = event.target;
        },
        onBlur() {
            setTimeout(() => (this.variable = ''), 100);
        },
        onSelect(value) {
            this.contentEditorElement.focus();
            this.setPos(this.carretPosition);
            if (value >= 0) {
                const variable = this.variables[value];
                this.selectedVariableIndex = value;
                this.addVariable();
                this.variable = '';
                this.isVariableSelectionActive = false;
                this.$emit('sortingOrder', variable.sortingOrder);
            }
        },
        onListChanged(value, selected) {
            this.currentVariables = value;
        },
    },
};
</script>

<style lang="scss">
.chip {
    border-radius: 4px;
    border: solid 1px $chip-border-color;
    background-color: $chip-background-color;
    display: inline-block;
    color: $chip-text-color;
    font-size: $font-size-xs;
    padding: 0;
    cursor: default;
    white-space: pre;

    .close {
        color: $chip-border-color;
        text-transform: uppercase;
        margin-left: 5px;
        cursor: pointer;
    }
}

.variable-list-wrapper.overlay {
    .variable-list {
        position: absolute;
        max-width: 300px;
        width: 300px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: $layer-10;
    }
    .close-button {
        position: absolute;
        right: calc(50% - 160px);
        top: calc(50% - 142px);
        z-index: $layer-10;
    }
}
</style>
