import { IContentOption } from '@/store/models/interfaces';
import { ContentType, ChannelStatus, ContentItemType, ConvoTypes } from '../enums';

export class ContentOption implements IContentOption {
    constructor(data: IContentOption) {
        this.text = data.text;
        this.type = data.type;
        this.info = data.info;
        this.id = data.id;
        this.code = data.code;
        this.published = data.published;
        this.convoType = data.convoType;

        switch (data.type) {
            case ContentType.Channel:
                this.label = 'Channel';
                this.inactive = data.status === ChannelStatus.Inactive;
                break;
            case ContentType.Convo:
                this.label = 'Convo';
                break;
            case ContentType.Message:
                this.label = 'Message';
                this.contentItemType = data.contentItemType as ContentItemType;
                this.isMultipleChoice = data.isMultipleChoice;
                break;
            case ContentType.Menu:
                this.label = 'Menu';
                break;
            default:
                this.label = '';
        }
    }

    text: string;
    type: ContentType;
    label: string;
    contentItemType?: ContentItemType;
    convoType?: ConvoTypes;
    info?: string;
    id?: number;
    code?: string;
    inactive?: boolean;
    published?: boolean;
    isMultipleChoice?: boolean;
}
