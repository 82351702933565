<template>
    <TemplateVariables
        :sorting-order="sortingOrder"
        :variable-list="variableList"
        :value="value"
        @sortingOrder="$emit('sortingOrder', $event)"
        @input="$emit('input', $event)"
    >
        <template v-slot:default="{ inputEvents, htmlValue }">
            <ContentEditable
                :id="id"
                :type="type"
                :label="label"
                :value="htmlValue"
                :placeholder="placeholder"
                :errors="errors"
                :info-text="infoText"
                @mouseup="inputEvents.mouseup"
                @keypress="inputEvents.keypress"
                @keyup="inputEvents.keyup"
                @input="inputEvents.input"
                @focus="inputEvents.focus"
                @blur="inputEvents.blur"
            />
        </template>
    </TemplateVariables>
</template>

<script>
import ContentEditable from '@/components/forms/ContentEditable';
import TemplateVariables from '@/components/TemplateVariables';

export default {
    name: 'ContentEditableWithVariables',
    components: {
        ContentEditable,
        TemplateVariables,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        variableList: {
            type: Array,
            default: () => [],
        },
        value: {
            type: String,
            default: '',
        },
        errors: {
            type: Array,
            default: () => [],
        },
        sortingOrder: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        type: {
            type: String,
            default: 'input',
            validator: (value) => ['input', 'textarea'].includes(value),
        },
        infoText: {
            type: String,
            default: undefined,
        },
    },
};
</script>
