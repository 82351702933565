import { ChannelStatus } from '@/store/enums/channelStatus.enum';
import { ITag } from '@/store/models/interfaces/tag.interface';
import { YougovWelcomeFlowType } from '@inconvo/types/enums';
import { IChannelStyle } from '../interfaces/channel.interface';
import { Topic } from '../topic';
import { WelcomeMessage } from './channel.dto';
import { ChannelLandingPageByLocale } from './channelLandingPageByLocale.dto';
import { ConvoScheduleRequest } from './convoScheduleRequests.dto';

export interface IChannelYougovPreferencesRequest {
    welcomeFlows: IChannelYougovWelcomeFlowRequest[];
}

export interface IChannelYougovWelcomeFlowRequest {
    type: YougovWelcomeFlowType;
    id: number;
    name: string;
    weight: number;
}

export class GetChannelsRequest {
    q: string;
    includeInactive: number;
    yougovWelcomeSurvey: number;
    page: number;
    size: number;
}

export class ChannelFacebookRequest {
    fbPageId: string;
    fbAppId: string;
    fbAccessToken: string;
    style: IChannelStyle;
}

export class ChannelRequest {
    slug: string;
    name: string;
    color: string;
    description: string;
    categoryId: number;
    tags: ITag[];
    topics: Topic[];
    platforms: string[];
    portraitImage: string;
    landscapeImage: string;
    squareImage: string;
    tagLine: string;
    sharingTitle: string;
    sharingDescription: string;
    status: ChannelStatus;
    welcomeMessage: WelcomeMessage;
    facebookData: ChannelFacebookRequest;
    version: string;
    showMemberCount: boolean;
    isFeatured: boolean;
    isStandalone: boolean;
    landingPage: ChannelLandingPageByLocale;
    convoSchedule: ConvoScheduleRequest;
    emailVariables: Record<string, string>;
    emailLocale: string;
    timezoneIdentifier: string;
    yougovPreferences: IChannelYougovPreferencesRequest;
}
