<script lang="js">
export default {
    name: 'DynamicHtmlTag',
    props: {
        tag: {
            type: String,
            required: true,
        },
    },
    render(createElement) {
        return createElement(this.tag, this.$slots.default);
    },
};
</script>
