import { IValidatable } from '@/store/models/interfaces/validatable.interface';
import { getErrorMessage } from '@/store/services/validationService';
import sluggify from '@/utils/sluggify';
import { IChannelYougovPreferences } from '@inconvo/types/interfaces';
import {
    ArrayMinSize,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    Length,
    validate as validator,
    ValidateIf,
} from 'class-validator';
import hash from 'object-hash';
import { ChannelStatus } from '../enums/channelStatus.enum';
import { IChannelState } from '../modules/channel';
import { ChannelLandingPage } from './channelLandingPage';
import { ConvoSchedule } from './convoSchedule';
import { ChannelRequestFactory } from './factories/channelRequest.factory';
import { ICategory, IPlatform } from './interfaces';
import { IChannelItem, IChannelStyle } from './interfaces/channel.interface';
import { IObservableItem } from './interfaces/observableItem.interface';
import { ITag } from './interfaces/tag.interface';

export class Channel
    implements IChannelItem, IValidatable<IChannelItem>, IObservableItem<IChannelState>
{
    private _slug: string;

    constructor() {
        this.id = 0;
        this.code = '';
        this.slug = '';
        this.name = '';
        this.description = '';
        this.tagLine = '';
        this.sharingTitle = '';
        this.sharingDescription = '';
        this.categoryId = undefined;
        this.color = '';
        this.tags = [];
        this.topics = [];
        this.platforms = [];
        this.portraitImage = '';
        this.landscapeImage = '';
        this.squareImage = '';
        this.status = ChannelStatus.Active;
        this.fbPageId = '';
        this.fbAppId = '';
        this.fbAccessToken = '';
        this.welcomeMessage = '';
        this.showMemberCount = false;
        this.isFeatured = false;
        this.isStandalone = false;
        this.landingPage = new ChannelLandingPage();
        this.convoSchedule = new ConvoSchedule();
        this.emailLocale = '';
        this.yougovPreferences = {} as IChannelYougovPreferences;
    }

    id: number;
    code: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Name') })
    name: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Description') })
    description: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Slug') })
    set slug(text: string) {
        this._slug = sluggify(text);
    }

    get slug(): string {
        return this._slug;
    }

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Portrait image') })
    portraitImage: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Landscape image') })
    landscapeImage: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Square image') })
    squareImage: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Tag Line') })
    tagLine: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Sharing Title') })
    sharingTitle: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Sharing Description') })
    sharingDescription: string;

    showMemberCount: boolean;
    status: ChannelStatus;
    category: ICategory;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Color') })
    color: string;

    @IsOptional()
    @IsNumber({}, { message: getErrorMessage('selectOption', 'Category') })
    categoryId: number | undefined;

    @ArrayMinSize(1, { message: getErrorMessage('isNotEmpty', 'Platforms') })
    platforms: IPlatform[];

    platformCodes: string[];
    style?: IChannelStyle;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    redirectUrl?: string | undefined;

    @ArrayMinSize(1, { message: getErrorMessage('isNotEmpty', 'Tags') })
    tags: ITag[];

    @ArrayMinSize(1, { message: getErrorMessage('isNotEmpty', 'Topics') })
    topics: ITag[];

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Facebook Page ID') })
    @ValidateIf((o) => o.isFacebook)
    fbPageId: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Facebook App ID') })
    @ValidateIf((o) => o.isFacebook)
    fbAppId: string;

    fbVerifyToken: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Facebook Access Token') })
    @ValidateIf((o) => o.isFacebook)
    fbAccessToken: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Welcome Message') })
    @Length(0, 640, { message: getErrorMessage('length', 'Welcome Message', { min: 0, max: 640 }) })
    welcomeMessage: string;

    originalVersion: string;

    isFeatured: boolean;
    isStandalone: boolean;
    landingPage: ChannelLandingPage;
    convoSchedule: ConvoSchedule;
    emailLocale: string;
    timezoneIdentifier: string;
    yougovPreferences: IChannelYougovPreferences;

    itemHasChanges(state: IChannelState): boolean {
        const channelRequestFactory = new ChannelRequestFactory();
        const channelRequest = channelRequestFactory.make(this);
        channelRequest.version = hash(channelRequest);
        if (this.originalVersion) {
            return channelRequest.version !== this.originalVersion;
        }
        return false;
    }

    async validate(): Promise<object> {
        const errors = await validator(this);

        return errors.reduce((acc, cur) => {
            acc[cur.property] = Object.values(cur.constraints || {});
            return acc;
        }, <any>{});
    }

    get isFacebook() {
        return this.platforms && this.platforms.some((p) => p.code === 'facebook');
    }
}
