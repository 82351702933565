<template>
    <div class="stepper" :class="{ 'nav-is-opened': navIsOpened }">
        <div class="content-wrapper">
            <div v-if="showPreviewButton || showButtonsSlot" class="content-wrapper__left">
                <Button
                    v-if="showPreviewButton"
                    class="preview-button m-r-1"
                    left-aligned-text
                    :button-style="previewButtonStyle"
                    :disabled="disablePreviewButton"
                    @click="$emit('previewButtonOnClick', $event)"
                >
                    {{ previewButtonText }}
                </Button>

                <slot v-if="showButtonsSlot" name="buttons" />
            </div>

            <div class="content-wrapper__right">
                <slot v-if="$slots.default" />

                <template v-else>
                    <div v-if="!showBackButton && showNextButton" class="info-text">
                        {{ nextButtonHelp }}
                    </div>
                    <Button
                        v-else-if="showBackButton"
                        button-style="secondary"
                        class="back-button"
                        :disabled="backButtonIsDisabled"
                        @click="$emit('backButtonOnClick', $event)"
                    >
                        {{ backButtonText }}
                    </Button>
                    <Button
                        v-if="showNextButton"
                        class="next-button"
                        has-border
                        :is-loading="isLoading"
                        :disabled="nextButtonIsDisabled"
                        @click="$emit('nextButtonOnClick', $event)"
                    >
                        {{ nextButtonText }}
                    </Button>
                    <Button
                        v-else-if="showCancelButton"
                        button-style="secondary"
                        class="back-button"
                        :disabled="cancelButtonIsDisabled"
                        @click="$emit('cancelButtonOnClick', $event)"
                    >
                        {{ cancelButtonText }}
                    </Button>
                    <Button
                        v-if="showAddButton"
                        class="next-button"
                        has-border
                        :is-loading="isLoading"
                        :disabled="addButtonIsDisabled"
                        @click="$emit('addButtonOnClick', $event)"
                    >
                        {{ addButtonText }}
                    </Button>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { ButtonStyle } from '@/store/enums/buttonStyle.enum.ts';
import Button from '@/components/ui/Button';

export default {
    name: 'Stepper',
    components: {
        Button,
    },
    props: {
        backButtonText: {
            type: String,
            default: 'Back',
        },
        nextButtonText: {
            type: String,
            default: 'Next',
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel',
        },
        addButtonText: {
            type: String,
            default: 'Add Image',
        },
        navIsOpened: {
            type: Boolean,
            default: false,
        },
        showBackButton: {
            type: Boolean,
            default: false,
        },
        showNextButton: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        backButtonIsDisabled: {
            type: Boolean,
            default: false,
        },
        nextButtonIsDisabled: {
            type: Boolean,
            default: false,
        },
        showCancelButton: {
            type: Boolean,
            default: false,
        },
        showAddButton: {
            type: Boolean,
            default: false,
        },
        cancelButtonIsDisabled: {
            type: Boolean,
            default: false,
        },
        addButtonIsDisabled: {
            type: Boolean,
            default: false,
        },
        nextButtonHelp: {
            type: String,
            default: 'Click Next to continue',
        },
        showPreviewButton: {
            type: Boolean,
            default: false,
        },
        showButtonsSlot: {
            type: Boolean,
            default: false,
        },
        previewButtonText: {
            type: String,
            default: 'Preview',
        },
        previewButtonStyle: {
            type: String,
            default: ButtonStyle.Secondary,
        },
        disablePreviewButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ButtonStyle,
        };
    },
};
</script>

<style lang="scss" scoped>
.stepper {
    z-index: $stepper-z-index;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-left: 50px;
    background-color: $bg-color;
    transition: padding-left 0.5s;

    &.nav-is-opened {
        padding-left: 240px;
    }
}

.content-wrapper {
    display: flex;
    height: 54px;
    width: 100%;
    min-width: 840px;
    padding: 0 40px;

    @include media-query(medium) {
        height: 80px;
    }

    @include media-query(large) {
        padding: 0 120px 0 45px;
    }

    &__left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-top: 1px solid $stepper-border-color;
        width: 100%;
    }

    &__right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-top: 1px solid $stepper-border-color;
        width: 100%;
    }
}

.info-text {
    margin-right: 40px;
    font-size: 14px;
    font-style: italic;
    color: $alternate-text-color;
}

.back-button {
    @include media-query(medium) {
        margin-right: 10px;
    }
}
</style>
