import { Extension, Plugin } from 'tiptap';

export default class PlainText extends Extension {
    get name() {
        return 'PlainText';
    }

    get plugins() {
        return [
            new Plugin({
                filterTransaction: (transaction) => {
                    const plainText =
                        (transaction && transaction.doc && transaction.doc.textContent) || '';
                    this.options.onChange(plainText);
                    return true;
                },
            }),
        ];
    }
}
