<template>
    <div class="form-group" :class="[{ 'form-group--has-m-b': hasMarginBottom }]">
        <div
            class="tags-wrapper form-field"
            :class="{
                'form-field--is-focused': isFocused,
                'form-field--has-error': errors.length > 0,
                'form-field--is-disabled': disabled,
                'form-field--has-bottom-space': hasMarginBottom,
            }"
        >
            <label class="form-field-label">
                <span>{{ label }}</span>
            </label>

            <vue-tags-input
                v-model="newTag"
                class="vue-tags-input"
                :tags="mappedTags"
                :autocomplete-items="mappedAutocompleteTags"
                :placeholder="placeholder"
                :add-only-from-autocomplete="onlyAutocomplete"
                :add-from-paste="addFromPaste"
                :autocomplete-min-length="autocompleteMinLength"
                :separators="separators"
                :disabled="disabled"
                :max-tags="maxTags"
                @tags-changed="onTagsChanged"
                @input="onAutocompleteInput"
                @focus="onFocus"
                @blur="onBlur"
            />
        </div>
        <div class="form-field-additional-information">
            <div class="form-field-additional-information__left">
                <div v-if="errors && errors.length > 0" class="form-field-error-message">
                    <Icon name="alert" />{{ errors[0] }}
                </div>
                <div v-if="infoText" class="form-field-info-text">{{ infoText }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import Icon from '@/components/ui/Icon';
import debounce from 'lodash/debounce';

export default {
    name: 'Tags',
    components: {
        VueTagsInput,
        Icon,
    },
    props: {
        value: {
            type: Array,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: 'Add tag',
        },
        label: {
            type: String,
            default: 'Tags',
        },
        onlyAutocomplete: {
            type: Boolean,
            default: false,
        },
        autocompleteItems: {
            type: Array,
            default: function () {
                return [];
            },
        },
        errors: {
            type: Array,
            default: () => [],
        },
        infoText: {
            type: String,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hasMarginBottom: {
            type: Boolean,
            default: true,
        },
        maxTags: {
            type: Number,
            default: undefined,
        },
        addFromPaste: {
            type: Boolean,
            default: false,
        },
        separators: {
            type: Array,
            default: () => [';'],
        },
        autocompleteMinLength: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            newTag: '',
            isFocused: false,
        };
    },
    computed: {
        mappedAutocompleteTags() {
            if (!this.autocompleteItems.length) {
                return [];
            }
            const tags = this.autocompleteItems
                .filter((o) => o.name.toLowerCase().includes(this.newTag.toLowerCase()))
                .map((tag) => {
                    return { id: tag.id, text: tag.name };
                });
            return tags;
        },
        mappedTags() {
            if (!this.value) {
                return [];
            }
            const tags = this.value.map((tag) => ({ id: tag.id, text: tag.name }));
            return tags;
        },
    },
    methods: {
        onTagsChanged: debounce(function (tags) {
            const tagsOut = tags.map((tag) => ({ name: tag.text, id: tag.id }));
            this.$emit('input', tagsOut);
        }, 200),
        onAutocompleteInput: debounce(function (event) {
            this.$emit('autocomplete', event);
        }, 200),
        onFocus() {
            this.isFocused = true;
        },
        onBlur() {
            this.isFocused = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.error-message {
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 12px;
    font-style: italic;
    color: $error-color;
}

.tags-wrapper {
    position: relative;
}
</style>
