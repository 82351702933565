<template>
    <ModalBase class="modal-auth" content-width="full" padding-size="medium" tagline="CMS">
        <template #logo>
            <Icon class="modal-auth-logo" name="logo-yougov-chat" />
        </template>

        <div class="p-t-3">
            <slot />
        </div>
    </ModalBase>
</template>

<script>
import ModalBase from '@/components/Modal/ModalBase';
import Icon from '@/components/ui/Icon';

export default {
    name: 'ModalBaseAuth',
    components: {
        ModalBase,
        Icon,
    },
};
</script>

<style scoped lang="scss">
.modal-auth-logo {
    width: round(178px * 0.85);
    height: round(24px * 0.85);
}

.modal-auth {
    @include media-query(medium) {
        width: 450px;
    }
}
</style>
