import { validate, IsNotEmpty, Length, ValidationError, IsUrl } from 'class-validator';
import hash from 'object-hash';
import { AccordionItemBase } from '../models/accordionItemBase';
import { AccordionStyle } from '@/store/enums/accordionStyle.enum';
import { ConvoMessageItemType } from '../enums';
import { IConvoState } from '../modules/convo-details';
import { IAnswer } from './interfaces/answer.interface.dto';
import { ResultAnswerFactory } from './factories/resultAnswerFactory.factory';
import { IContentItem } from './interfaces';
import { ResultAccordianItemFactory } from './factories/resultAccordianItem.factory';
import { IObservableItem } from './interfaces/observableItem.interface';
import { getErrorMessage } from '@/store/services/validationService';

export class Result extends AccordionItemBase implements IObservableItem<IConvoState> {
    constructor(items = []) {
        super(items);

        this.type = ConvoMessageItemType.Result;
        this.accordionStyle = AccordionStyle.Result;
        this.icon = 'award';
        this.title = '';
        this.subtitle = '';
        this.imageUrl = '';
        this.isDeletable = true;

        this.sharingTitle = '';
        this.sharingSubtitle = '';
    }

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Title') })
    @Length(0, 200, { message: getErrorMessage('length', 'Title', { min: 0, max: 200 }) })
    title: string = '';
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Subtitle') })
    @Length(0, 200, { message: getErrorMessage('length', 'Subtitle', { min: 0, max: 200 }) })
    subtitle: string = '';
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Image') })
    imageUrl: string = '';

    sharingTitle: string;
    sharingSubtitle: string;

    itemHasChanges(state: IConvoState): boolean {
        const contentItemAnswerFactory = new ResultAnswerFactory();
        const contentItemAnswer = contentItemAnswerFactory.make(this, state);

        contentItemAnswer.version = hash(contentItemAnswer);

        if (this.originalVersion) {
            return contentItemAnswer.version !== this.originalVersion;
        }
        return false;
    }

    toContentItemAnswer(state: IConvoState): IAnswer {
        const contentItemAnswerFactory = new ResultAnswerFactory();

        const contentItemAnswer = contentItemAnswerFactory.make(this, state);

        return contentItemAnswer;
    }

    toAccordionItem(contentItem: IContentItem, isClone?: boolean): AccordionItemBase {
        const accordianItemFactory = new ResultAccordianItemFactory();
        return accordianItemFactory.make(contentItem);
    }

    async validate(state: IConvoState, path: number[]): Promise<any> {
        const errors: ValidationError[] = await validate(this);
        const errorMessages: any[] = [];

        if (errors.length > 0) {
            errors.forEach((item: ValidationError) => {
                errorMessages.push({
                    path,
                    errors: { [item.property]: Object.values(item.constraints || {}) },
                });
            });
        }

        return errorMessages;
    }
}
