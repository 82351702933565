import axios from 'axios';
import Vue from 'vue';
import store from '../store';
import { SET_ERROR, SET_ERROR_MESSAGE } from '@/store/mutations.types';
import apiErrorMessages from '@/json/apiErrorMessages';
import * as Sentry from '@sentry/vue';

declare module 'axios' {
    export interface AxiosRequestConfig {
        loadingId?: string;
        errorMessage?: string;
    }
}

const service = axios.create({
    baseURL: process.env.VUE_APP_API,
    timeout: 30000,
});

service.interceptors.request.use(
    async (config) => {
        config.headers['content-type'] = 'application/json';
        config.headers['x-inconvo'] = 'sorkin';

        try {
            await store.dispatch('auth/refreshSession');
            const accessToken = store.getters['auth/accessToken'];
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        } catch (error) {
            Sentry.captureException(error);
            Vue.prototype.$toaster.add('Session expired, please login again.');
            // TODO: redirect to sign in view
        }

        if (config.hasOwnProperty('loadingId')) {
            store.dispatch('wait/start', config.loadingId);
        }

        return config;
    },
    (error) => {
        Vue.prototype.$toaster.add('Something went wrong');
        if (error.config.hasOwnProperty('loadingId')) {
            store.dispatch('wait/end', error.config.loadingId);
        }
        Vue.$log.error('request', error);
    },
);

service.interceptors.response.use(
    (response) => {
        if (response.config.hasOwnProperty('loadingId')) {
            store.dispatch('wait/end', response.config.loadingId);
        }
        return response.data;
    },
    (error) => {
        store.commit(`main/${SET_ERROR}`, true);
        store.commit(`main/${SET_ERROR_MESSAGE}`, error);

        Vue.$log.error('response', error);

        const defaultMessage = error.config?.errorMessage;
        const errorMessage = error.response?.data?.code
            ? apiErrorMessages[error.response?.data?.code] ?? defaultMessage
            : defaultMessage;
        if (errorMessage) {
            Vue.prototype.$toaster.add(errorMessage);
        } else {
            Vue.prototype.$toaster.add('Something went wrong');
        }

        if (error.config.hasOwnProperty('loadingId')) {
            store.dispatch('wait/end', error.config.loadingId);
        }
    },
);

export default service;
