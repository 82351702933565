<template>
    <DragAndDrop
        v-model="dragAndDropItems"
        :is-draggable="isDraggable"
        handle=".drag-and-drop-handle"
        @onDragStart="onDragStart"
        @onDragEnd="onDragEnd"
    >
        <AccordionItemWithContent
            v-for="(item, index) in dragAndDropItems"
            :key="index"
            :item="item"
            :start-index="startIndex"
            :are-message-items-valid="areMessageItemsValid"
            :index="index"
            :path="path"
            :drag="drag"
            :is-draggable="isDraggable && dragAndDropItems.length > 1"
            :yougov-definitions="yougovDefinitions"
            :convo-categories="convoCategories"
            :show-correlator="showCorrelator"
            v-bind="$attrs"
            @openLogicJump="$emit('openLogicJump', $event)"
            @headerOnClick="($event, path) => $emit('headerOnClick', $event, path)"
            @onExpand="(path) => $emit('onExpand', path)"
            @onExpanded="(path) => $emit('onExpanded', path)"
            @onContract="(path) => $emit('onContract', path)"
            @onContracted="(path) => $emit('onContracted', path)"
            @deleteOnClick="
                (event, path, parentPath) => $emit('deleteOnClick', event, path, parentPath)
            "
            @cloneOnClick="
                (event, path, parentPath) => $emit('cloneOnClick', event, path, parentPath)
            "
            @undoDeletedItemOnClick="(event, path) => $emit('undoDeletedItemOnClick', event, path)"
            @onDrop="(path, items) => $emit('onDrop', path, items)"
            @messageTypeOnSelect="
                (event, path, index) => $emit('messageTypeOnSelect', event, path, index)
            "
            @propertyOnChange="$emit('propertyOnChange', $event)"
            @addItemOnClick="(event, path, type) => $emit('addItemOnClick', event, path, type)"
            @pinItem="(path) => $emit('pinItem', path)"
            @pinAnswer="(path, isPinned) => $emit('pinAnswer', path, isPinned)"
            @ignoreQuizQuestion="(path, isPinned) => $emit('ignoreQuizQuestion', path, isPinned)"
            @addAttribute="(payload) => $emit('addAttribute', payload)"
            @removeAttribute="(payload) => $emit('removeAttribute', payload)"
            @openCorrelationContent="$emit('openCorrelationContent', $event)"
            @closeCorrelationContentSeletor="$emit('closeCorrelationContentSeletor')"
        />
    </DragAndDrop>
</template>

<script>
import DragAndDrop from '@/components/DragAndDrop';
import AccordionItemWithContent from '@/components/ui/Accordion/AccordionItemWithContent';

export default {
    name: 'Accordion',
    components: {
        DragAndDrop,
        AccordionItemWithContent,
    },
    props: {
        isDraggable: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            default: undefined,
        },
        path: {
            type: Array,
            default: () => [],
        },
        startIndex: {
            type: Number,
            default: 1,
        },
        areMessageItemsValid: {
            type: Boolean,
            default: true,
        },
        yougovDefinitions: {
            type: Array,
            default: () => [],
        },
        convoCategories: {
            type: Array,
            default: () => [],
        },
        showCorrelator: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            drag: false,
        };
    },
    computed: {
        dragAndDropItems: {
            get() {
                return this.items;
            },
            set(value) {
                this.$emit('onDrop', this.path, value);
            },
        },
    },
    methods: {
        onDragStart() {
            this.drag = true;
        },
        onDragEnd() {
            setTimeout(() => {
                this.drag = false;
            }, 0);
        },
    },
};
</script>
