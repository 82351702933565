import { IContentItem } from '../interfaces';
import { AttachmentUrl } from '..';
import { makeLogicJump } from './accordionItems.factory';

export class AttachmentUrlAccordionItemFactory {
    public make(contentItem: IContentItem): AttachmentUrl {
        const attachmentUrlItem = new AttachmentUrl();
        const answer = contentItem.answers && contentItem.answers[0];

        attachmentUrlItem.url = contentItem.attachment;
        attachmentUrlItem.setTitle();
        attachmentUrlItem.id = contentItem.id || 0;
        attachmentUrlItem.sort = contentItem.sort;
        attachmentUrlItem.tags = contentItem.tags;

        if (answer) {
            attachmentUrlItem.logicJump = makeLogicJump(answer);
        }

        attachmentUrlItem.title = 'Image';

        return attachmentUrlItem;
    }
}
