import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IGetTagsRequest, TagClient } from '@/api/tag';
import { IRootState } from '../../types/rootState';
import { ITag } from '@/store/models/interfaces';
import { SET_TAGS, RESET_STATE } from './mutations.types';
import { TagType } from '@/store/enums';

const tagClient = new TagClient();

const getDefaultState = () => ({
    tags: [],
});

export interface IGetTagsAction extends IGetTagsRequest {
    force?: boolean;
}

export interface ITagsState {
    tags: ITag[];
}

const state: ITagsState = getDefaultState();

const getters: GetterTree<ITagsState, IRootState> = {
    tagsByType:
        (state) =>
        (type: TagType): ITag[] => {
            return state.tags.filter((tag: ITag) => tag.type === type);
        },
};

const actions: ActionTree<ITagsState, any> = {
    async getTags({ commit, state }, request?: IGetTagsAction) {
        if (!state.tags.length || request?.force) {
            const tags: ITag[] = await tagClient.fetch({
                type: request?.type,
                query: request?.query,
            });
            commit(SET_TAGS, tags);
        }
    },
};

const mutations: MutationTree<ITagsState> = {
    [SET_TAGS](state: ITagsState, payload: ITag[]) {
        state.tags = payload;
    },
    [RESET_STATE](state: ITagsState) {
        Object.assign(state, getDefaultState());
    },
};

export const tags: Module<ITagsState, IRootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
