import { IConvoState } from '@/store/modules/convo-details';
import { Result } from '..';
import { IAnswer } from '../interfaces/answer.interface.dto';
import { Answer } from '../answer.dto';

export class ResultAnswerFactory {
    public make(result: Result, state: IConvoState): IAnswer {
        const answer = new Answer();

        answer.message = result.title;
        answer.tagline = result.subtitle;
        answer.imageUrl = result.imageUrl;
        answer.inviteMessage = result.sharingTitle;
        answer.inviteTagline = result.sharingSubtitle;

        return answer;
    }
}
