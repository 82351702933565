import { validate as validateClass, IsNotEmpty } from 'class-validator';
import { GifAccordionItemFactory } from './factories/gifAccordionItem.factory';
import truncateStringWithEllipsis from '@/utils/truncateStringWithEllipsis';
import { IObservableItem } from './interfaces/observableItem.interface';
import { IContentItemConvertible, IContentItem, IGif } from './interfaces';
import { IConvoState } from '../modules/convo-details';
import { ConvoMessageItemType } from '../enums';
import { AccordionItemBase } from '.';
import hash from 'object-hash';
import { GifContentItemFactory } from './factories/gifContentItem.factory';

export class Gif
    extends AccordionItemBase
    implements IContentItemConvertible, IObservableItem<IConvoState>
{
    constructor() {
        super([]);
        this.icon = 'giphy';
        this.type = ConvoMessageItemType.Gif;
        this.isDraggable = true;
        this.isDeletable = true;
    }

    @IsNotEmpty({ message: 'Must include a GIF' })
    gif: IGif;

    setTitle() {
        const subtitle = this?.gif?.title || '--';
        this.subtitle = truncateStringWithEllipsis(subtitle, 50);
    }

    itemHasChanges(state: IConvoState): boolean {
        const contentItemFactory = new GifContentItemFactory();
        const contentItem = contentItemFactory.make(this, state);
        contentItem.version = hash(contentItem);
        if (this.originalVersion) {
            return contentItem.version !== this.originalVersion;
        }
        return false;
    }

    toContentItem(state: IConvoState): IContentItem {
        const contentItemFactory = new GifContentItemFactory();
        return contentItemFactory.make(this, state);
    }

    toAccordionItem(contentItem: IContentItem, isClone?: boolean): AccordionItemBase {
        const accordionItemFactory = new GifAccordionItemFactory();
        return accordionItemFactory.make(contentItem);
    }

    async validate(state: IConvoState, path: number[]): Promise<any[]> {
        const errors = await validateClass(this);
        const errorMessages: any[] = [];

        if (errors.length > 0) {
            errors.forEach((item) => {
                errorMessages.push({
                    path,
                    errors: { [item.property]: Object.values(item.constraints || {}) },
                });
            });
        }

        return errorMessages;
    }
}
