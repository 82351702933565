import { sanitize } from '@/utils/sanitizer';
import { CommentsRating } from '..';
import { CommentsRatingTargetInput } from '../commentsRatingTargetInput';
import { ITargetContentItem, IContentItem } from '../interfaces';
import { LogicJump } from '../logicJump';
import { ContentType } from '@/store/enums';

export class CommentsRatingAccordianItemFactory {
    public make(contentItem: IContentItem): CommentsRating {
        const commentsRatingItem = new CommentsRating();
        const plainTextStatement = sanitize(contentItem.title);
        commentsRatingItem.title = 'Comments Rating';
        commentsRatingItem.subtitle = plainTextStatement;
        commentsRatingItem.plainText = plainTextStatement;
        commentsRatingItem.text = contentItem.title;
        commentsRatingItem.commentingOnText = contentItem.targetTextInputs?.text!;
        commentsRatingItem.id = contentItem.id || 0;
        commentsRatingItem.sort = contentItem.sort;
        commentsRatingItem.tags = contentItem.tags;

        const targetInputs = (contentItem.targetTextInputs?.targetInputs ||
            []) as ITargetContentItem[];

        for (const targetInput of targetInputs) {
            const commentsRatingTargetInput = new CommentsRatingTargetInput();
            commentsRatingTargetInput.logicJump = new LogicJump({
                channel: {
                    type: ContentType.Channel,
                    text: targetInput.channel!,
                },
                convo: {
                    type: ContentType.Convo,
                    text: targetInput.convo!,
                },
                message: {
                    type: ContentType.Message,
                    id: targetInput.contentItemId,
                    text: targetInput.contentItemText,
                },
            });
            commentsRatingItem.items.push(commentsRatingTargetInput);
        }

        return commentsRatingItem;
    }
}
