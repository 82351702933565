import { ChannelStatus } from '../enums';
import { IListChannel } from './interfaces/listChannel.interface';

export class ListChannel implements IListChannel {
    id: number;
    code: string;
    slug: string;
    name: string;
    description: string;
    image: string;
    isFeatured: boolean;
    status: ChannelStatus;
    channelId: string;
    redirectUrl?: string | undefined;
}
