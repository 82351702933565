import { AudienceDto } from '@/store/models/dtos';
import { Audience } from '@/store/models/audience';
import { format } from 'date-fns';

const dateFormat = 'yyyy-MM-dd HH:mm:ss';
export class AudienceFactory {
    public static make(data: AudienceDto): Audience {
        const audience = new Audience();

        if (data.id) {
            audience.id = Number(data.id);
        }

        if (data.tenantCode) {
            audience.tenantCode = data.tenantCode;
        }

        if (data.exported) {
            audience.exported = data.exported;
        }

        if (data.exportTo) {
            audience.exportTo = data.exportTo;
        }

        if (data.exportPlatform) {
            audience.exportPlatform = data.exportPlatform;
        }

        if (data.updatedAt) {
            audience.updatedAt = format(new Date(data.updatedAt), dateFormat);
        }

        if (data.createdAt) {
            audience.createdAt = format(new Date(data.createdAt), dateFormat);
        }
        audience.name = data.name;

        return audience;
    }
}
