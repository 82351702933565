import { ListMarker } from '@/store/enums/listMarker.enum';
import { AccordionStyle } from '@/store/enums/accordionStyle.enum';
import { ConvoMessageItemType } from '@/store/enums';
import { IValidatable, IRatingTarget } from './interfaces';
import { IConvoState } from '../modules/convo-details';
import { LogicJump } from '.';
import { ITag } from './interfaces';

export class AccordionItemBase implements IValidatable<IConvoState> {
    constructor(items: AccordionItemBase[]) {
        this.items = items;
    }

    isOpen: boolean = false;
    isDraggable: boolean = false;
    isDeletable: boolean = false;
    isOpenable: boolean = true;
    isDeleted: boolean = false;
    isPdl: boolean = false;
    isExternalPdl: boolean = false;
    shouldSkipIfAnswered: boolean = true;
    type: string = '';
    sort: number = 0;
    title: string = '';
    subtitle: string = '';
    items: AccordionItemBase[];
    accordionStyle: string = AccordionStyle.Default;
    hasMarginBottom: boolean = true;
    markerType: string = ListMarker.Numeral;
    icon?: string;
    marker?: number | string;
    itemsMaxLength?: number;
    isPinned: boolean = false;
    id: number = 0;
    hasChanges: boolean = false;
    originalVersion: string;
    errors: any = {};
    isValid: boolean = true;
    logicJump?: LogicJump;
    isNew: boolean = false;
    externalId?: string;
    tags?: ITag[];
    rating?: number;
    ratingTarget?: IRatingTarget;

    setTitle(): void {}

    addItem(item: AccordionItemBase, type?: ConvoMessageItemType): void {
        if (!this.itemsMaxLength || this.itemsMaxLength > this.items.length) {
            this.items.push(item);
        }
    }

    deleteItemCallback(path?: number[]): void {}

    open(): void {
        this.isOpen = true;
    }

    close(): void {
        this.isOpen = false;
    }

    toggle(): void {
        this.isOpen = !this.isOpen;
    }

    clearErrorMessages(): void {
        this.errors = {};
        this.isValid = true;
    }

    getValidationState(): boolean {
        return Object.keys(this.errors).length === 0;
    }

    async validate(state: IConvoState, path?: number[]): Promise<any[]> {
        return [];
    }
}
