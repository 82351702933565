<template>
    <div
        class="button-style-radio-button"
        :class="{
            'form-group--has-m-b': hasMarginBottom,
            'button-style-radio-button--grouped': isGrouped,
        }"
    >
        <input
            :id="id"
            v-model="innerChecked"
            type="radio"
            v-bind="$attrs"
            :disabled="disabled"
            :value="inputValue"
        />
        <label :for="id">
            <Icon v-if="icon" class="icon" :name="icon" />
            {{ label }}
        </label>
        <div v-if="errors && errors.length > 0" class="error-message">{{ errors[0] }}</div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    name: 'ButtonStyleRadioButton',
    components: {
        Icon,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        inputValue: {
            type: String,
            default: undefined,
        },
        value: {
            type: String,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hasMarginBottom: {
            type: Boolean,
            default: true,
        },
        errors: {
            type: Array,
            default: undefined,
        },
        icon: {
            type: String,
            default: undefined,
        },
        isGrouped: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        innerChecked: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
$form-group-margin-bottom: 20px !default;

.form-group {
    &--has-m-b {
        margin-bottom: $form-group-margin-bottom;
    }
}

input[type='radio'] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    + label {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        max-width: 200px;
        height: 44px;
        padding: 2px 5px;
        border: solid 1px $color-cadet-blue;
        border-radius: 4px;
        background-color: lighten($color-cadet-blue, 25%);
        font-size: 10px;
        color: $color-cadet-blue;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;

        .icon {
            display: block;
            width: 100%;
            margin-bottom: -3px;
            font-size: 16px;
            color: $color-cadet-blue;
        }
    }

    &:checked,
    &:focus {
        + label {
            z-index: 1;
            border-color: $color-primary;
            background-color: $color-primary-lightest;
            color: $color-primary;

            .icon {
                color: $color-primary;
            }
        }
    }

    &:disabled {
        + label {
            cursor: default;
            border: solid 1px lighten($color-cadet-blue, 10%);
            background-color: lighten($color-cadet-blue, 30%);
            color: lighten($color-cadet-blue, 10%);

            .icon {
                color: lighten($color-cadet-blue, 10%);
            }

            &::before,
            &::after {
                opacity: 0.6;
            }
        }
    }
}

.button-style-radio-button {
    &--grouped {
        &:not(:last-child) {
            label {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        + .button-style-radio-button {
            label {
                width: calc(100% + 2px);
                margin-left: -1px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}

.error-message {
    padding-left: 35px;
    font-size: 12px;
    font-style: italic;
    color: $color-error;
}
</style>
