<template>
    <div>
        <div v-if="data.video" class="iframe-wrapper m-b-2">
            <iframe
                width="560"
                height="315"
                :src="data.video.url"
                title="YouTube video player"
                allowfullscreen
            ></iframe>
        </div>
        <ErrorMessage v-if="errorMessage">{{ errorMessage }}</ErrorMessage>
        <TextButton v-if="data.video" icon="cross" @click="onClear()"> Clear Video </TextButton>

        <TextButton v-else icon="plus-2" @click="showVideoSelector = true"> Add Video </TextButton>

        <VideoSelector
            :show="showVideoSelector"
            @close="showVideoSelector = false"
            @input="onInput('video', $event)"
        />
    </div>
</template>

<script>
import TextButton from '@/components/ui/TextButton';
import ErrorMessage from '@/components/ui/ErrorMessage';
import itemFormMixin from '@/mixins/itemFormMixin';

export default {
    name: 'Video',
    components: {
        VideoSelector: () => import('@/components/VideoSelector/VideoSelector'),
        TextButton,
        ErrorMessage,
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        currentChannel: {
            type: Object,
            default: undefined,
        },
        currentConvo: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            showVideoSelector: false,
        };
    },
    computed: {
        errorMessage() {
            if (this.data.errors && this.data.errors.video && this.data.errors.video.length > 0) {
                return this.data.errors.video[0];
            }
            return null;
        },
    },
    methods: {
        onClear() {
            this.onInput('video', undefined);
            this.$forceUpdate();
        },
    },
};
</script>

<style lang="scss" scoped>
.iframe-wrapper {
    position: relative;
    height: 0;
    padding-bottom: 56.25%; // 16:9 ratio

    iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
</style>
