import { ChannelClient } from '@/api/channel';
import { TagClient } from '@/api/tag';
import { ConvoOperation, CategoryType } from '@/enums';
import { router } from '@/router';
import { CategoryClient } from '@/api/category';
import {
    Attributes,
    BroadcastStatus,
    BroadcastType,
    ContentItemType,
    ContentType,
    ConvoMessageItemType,
    ConvoTypes,
    TagType,
} from '@/store/enums';

import { IBroadcastDto } from '@/store/models/dtos/broadcast.dto';
import { ConvoFactory } from '@/store/models/factories/convo.factory';
import { getErrorMessage, validateDatumResponses } from '@/store/services/validationService';
import { Poller } from '@/utils/poller';
import { IPaging } from '@inconvo/types/interfaces';
import hash from 'object-hash';
import Vue from 'vue';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import {
    ButtonsAnswer,
    CarouselTile,
    Convo,
    InlineResponseTextStatement,
    LogicJump,
    QuickReplyAnswer,
} from '../models';
import { AccordionItemBase } from '../models/accordionItemBase';
import { Answer } from '../models/answer.dto';
import {
    ContentItemsRequest,
    CreateConvoRequest,
    GetChannelsRequest,
    ListChannel,
} from '../models/dtos';
import { AccordionItemsFactory } from '../models/factories/accordionItems.factory';
import { ContentItemsRequestFactory } from '../models/factories/contentItemsRequest.factory';
import { ConvoPreviewMessagesFactory } from '../models/factories/convoPreviewMessages.factory';
import {
    IAnswer,
    IContentItem,
    IContentItemConvertible,
    IContentOption,
    IConvo,
    IObservableItem,
    IPublishRequest,
    IQuizContentItem,
    IConvoCategory,
    ITag,
} from '@/store/models/interfaces';
import { PersonalityQuizResult } from '../models/personalityQuizResult';
import { Publishing } from '../models/publishing';
import { Result } from '../models/result';
import { SelectMessageType } from '../models/selectMessageType';
import { DatumContentItem } from '../models/types';
import { YougovWelcomeSurveyButtons } from '../models/ygWelcomeSurvey';
import {
    ADD_ATTRIBUTE,
    ADD_CONVO_MESSAGE_ITEMS,
    ADD_MESSAGE_ITEM,
    ADD_RESULT_ITEM,
    CLEAR_CONVO_MESSAGES_ERRORS,
    CLEAR_CONVO_MESSAGES_VIEW_ERRORS,
    CLEAR_MESSAGE_ITEM_DATUM_DEFINITION,
    CLEAR_RESULT_ITEMS_ERRORS,
    CLONE_MESSAGE_ITEM,
    CLOSE_ALL_MESSAGE_ITEMS_ACCORDION_ITEMS,
    CLOSE_MESSAGE_ITEMS_ACCORDION_CHILDREN,
    CLOSE_MESSAGE_ITEMS_ACCORDION_SIBLINGS,
    CLOSE_RESULT_ITEMS_ACCORDION_CHILDREN,
    CLOSE_RESULT_ITEMS_ACCORDION_SIBLINGS,
    DELETE_MESSAGE_ITEM,
    DELETE_RESULT_ITEM,
    IGNORE_QUIZ_QUESTION,
    OPEN_ALL_MESSAGE_ITEMS_ACCORDION_ITEMS,
    PIN_MESSAGE_ANSWER,
    PIN_MESSAGE_ITEM,
    REMOVE_ATTRIBUTE,
    RESET_CONVO_DETAILS_STORE,
    RESET_CONVO_DETAILS_VALIDATION_STATE,
    SET_ACTIVE_MESSAGE_PATH,
    SET_CONVO,
    SET_CONVO_CATEGORIES,
    SET_CONVO_EMAIL_CAMPAIGN_POLLING_STATE,
    SET_CONVO_ERRORS,
    SET_CONVO_MESSAGES_ERRORS,
    SET_CONVO_MESSAGES_VIEW_ERRORS,
    SET_CONVO_MESSAGE_ITEMS,
    SET_CONVO_NAME,
    SET_CONVO_PROPERTY,
    SET_CONVO_RESULT_ITEMS,
    SET_CONVO_TAGS_ATTRIBUTE,
    SET_CONVO_TYPE,
    SET_CONVO_WARNINGS,
    SET_DEFAULT_STATE,
    SET_MESSAGE_ITEM,
    SET_MESSAGE_ITEMS_ACCORDION_ITEMS,
    SET_MESSAGE_ITEMS_TOP_LEVEL_ORDER,
    SET_MESSAGE_ITEM_BY_KEY,
    SET_MESSAGE_ITEM_HAS_CHANGES,
    SET_MESSAGE_TYPE,
    SET_PERSONALITY_QUIZ_STATE,
    SET_PUBLISHING_PROPERTY,
    SET_RESULT_ERRORS,
    SET_RESULT_ITEM,
    SET_RESULT_ITEMS_ERRORS,
    SET_SELECTED_TAGS,
    SET_TAGS,
    SET_TRIVIA_QUIZ_STATE,
    SET_WELCOME_SURVEY_CHANNELS,
    SET_YOUGOV_WELCOME_SURVEY_STATE,
    TOGGLE_MESSAGE_ITEMS_ACCORDION_ITEM,
    TOGGLE_RESULT_ITEMS_ACCORDION_ITEM,
    UNDO_DELETED_MESSAGE_ITEM,
    UNDO_DELETED_RESULT_ITEM,
} from '../mutations.types';
import * as accordionMutators from '../mutators/accordionMutators';
import { getAccordionItem, getLevel, traversal } from '../services/accordionService';
import { getConvoMessageItemByType } from '../services/convoMessageItemService';
import { IRootState } from '../types/rootState';
import { IChannelDetailsView } from './channel';
export interface IResultsView {
    resultItems: AccordionItemBase[];
    errors: any[];
    validated: boolean;
}

export interface IMessagesView {
    messageItems: AccordionItemBase[];
    errors: any[];
    validated: boolean;
    activeMessagePath: number[] | null;
    yougovSurveyChannels?: string[];
}

export interface IConvoState {
    convo: Convo;
    messagesView: IMessagesView;
    availableTags: ITag[];
    selectedTags: ITag[];
    resultsView: IResultsView;
    avatarUrl: string;
    publishing: Publishing;
    [key: string]: any;
}

export interface ITagPayload {
    tags: ITag[];
    type?: TagType;
}

const initialState = () => {
    const state: IConvoState = {
        convo: new Convo(),
        messagesView: {
            messageItems: [],
            errors: [],
            validated: false,
            activeMessagePath: null,
            yougovSurveyChannels: [],
        },
        availableTags: [],
        availableConvoCategories: [],
        selectedTags: [],
        resultsView: {
            resultItems: [],
            errors: [],
            validated: false,
        },
        avatarUrl: '/inconvo-avatar.png',
        publishing: new Publishing(),
    };

    return state;
};

const state: IConvoState = initialState();

const getters: GetterTree<IConvoState, IRootState> = {
    convoDetailsView: () => state,
    isNewConvo: (state) => state.convo.id === 0,
    isConvoValid: (state) => {
        return state.convo.errors ? !Object.keys(state.convo.errors).length : true;
    },
    isMessageViewValid: (state) => {
        return state.messagesView.errors ? !Object.keys(state.messagesView.errors).length : true;
    },
    areMessageItemsValid: (state) => {
        let isValid = true;

        if (state.messagesView.messageItems.filter((o) => !o.isDeleted).length === 0) {
            isValid = false;
        }

        traversal(
            state.messagesView.messageItems.filter((o) => !o.isDeleted),
            (item) => {
                if (isValid) {
                    isValid = item.getValidationState();
                }
            },
        );

        if (state.messagesView.errors.length) {
            isValid = false;
        }

        return isValid;
    },
    areResultItemsValid: (state) => {
        let isValid = true;

        if (state.resultsView.resultItems.filter((o) => !o.isDeleted).length === 0) {
            isValid = false;
        }

        traversal(
            state.resultsView.resultItems.filter((o) => !o.isDeleted),
            (item) => {
                if (isValid) {
                    isValid = item.getValidationState();
                }
            },
        );

        return isValid;
    },
    availableTags: (state) => (type?: TagType) => {
        if (!type) {
            return state.availableTags;
        }
        return state.availableTags.filter((tag) => tag.type === type);
    },
    availableConvoCategories: (state) => state.availableConvoCategories,
    selectedTags: (state) => (type?: TagType | TagType[]) => {
        if (!type) {
            return state.selectedTags;
        }
        if (Array.isArray(type)) {
            const tags: ITag[] = [];
            for (const tagType of type) {
                state.selectedTags.forEach((tag) => {
                    if (tag.type === tagType) {
                        tags.push(tag);
                    }
                });
            }
            return tags;
        }
        return state.selectedTags.filter((tag) => tag.type === type);
    },
    personalityQuizResults: (state) => {
        const results: PersonalityQuizResult[] = [];

        for (const [index, item] of Object.entries(state.resultsView.resultItems)) {
            const resultItem = item as Result;
            const result = new PersonalityQuizResult(Number(index), resultItem.title);
            results.push(result);
        }

        return results;
    },
    convoModelHasChanges: (state) => {
        return state.convo.hasChanges();
    },
    messagesModelHasChanges: (state) => {
        for (const item of state.messagesView.messageItems) {
            const observableItem = item as unknown as IObservableItem<IConvoState>;
            const itemHasChanges =
                observableItem &&
                observableItem.itemHasChanges &&
                observableItem.itemHasChanges(state);

            if (itemHasChanges) {
                return true;
            }
        }

        // creates
        const newItems = state.messagesView.messageItems.filter((o) => o.id === 0);
        if (newItems.length) {
            return true;
        }

        // deletes
        return state.messagesView.messageItems.some((item) => item.isDeleted);
    },
    resultsModelHasChanges: (state) => {
        if (
            state.convo.type !== ConvoTypes.PersonalityQuiz &&
            state.convo.type !== ConvoTypes.TriviaQuiz
        ) {
            return false;
        }

        // updates
        for (const item of state.resultsView.resultItems) {
            const observable = item as unknown as IObservableItem<IConvoState>;
            const itemHasChanges =
                observable && observable.itemHasChanges && observable.itemHasChanges(state);

            if (itemHasChanges) {
                return true;
            }
        }

        // creates
        const personalityResultfromServer = state.convo.contentItems.find(
            (item) => item.type == ContentItemType.PersonalityQuizResult,
        );

        if (
            personalityResultfromServer &&
            personalityResultfromServer.answers.length < state.resultsView.resultItems.length
        ) {
            return true;
        }

        // deletes
        return state.resultsView.resultItems.some((item) => item.isDeleted);
    },
    convoPreviewMessages: (state) => {
        const convoPreviewMessagesFactory: ConvoPreviewMessagesFactory =
            new ConvoPreviewMessagesFactory();
        return convoPreviewMessagesFactory.make(
            state.messagesView.messageItems.filter((item) => !item.isDeleted),
            state.avatarUrl,
        );
    },
    messageByPath:
        (state) =>
        (path: number[]): AccordionItemBase | undefined => {
            return getAccordionItem(state.messagesView.messageItems, path);
        },
    activeMessagePath: (state) => {
        return state.messagesView.activeMessagePath;
    },
    hasSinglePostbackMessage: (state) => {
        const postbackTypes = [
            ConvoMessageItemType.Buttons,
            ConvoMessageItemType.QuickReply,
            ConvoMessageItemType.Carousel,
            ConvoMessageItemType.TextInput,
            ConvoMessageItemType.DatePicker,
        ];
        return (
            state.messagesView.messageItems.filter((item: AccordionItemBase) => {
                return postbackTypes.includes(item.type as ConvoMessageItemType) && !item.isDeleted;
            }).length === 1
        );
    },
    yougovSurveyChannels: (state) => {
        return state.messagesView.yougovSurveyChannels;
    },
};

const actions: ActionTree<IConvoState, any> = {
    async pollConvoEmailCampaignStatus({ commit }, convoId: string) {
        commit(SET_CONVO_EMAIL_CAMPAIGN_POLLING_STATE, true);
        const interval = 10_000; // every 10 seconds
        const timeout = 600_000; // 10 minutes
        const poller = new Poller(interval, timeout);

        await poller.poll(async (done: Function) => {
            try {
                const result: IPaging<IBroadcastDto> = await Vue.$api.broadcast.getByConvoId({
                    convoId,
                    type: BroadcastType.ConvoServiceEmail,
                });
                const broadcast = result?.items[0];

                if (
                    ![BroadcastStatus.InProgress, BroadcastStatus.Created].includes(
                        broadcast.status as BroadcastStatus,
                    )
                ) {
                    done();
                }
            } catch (error) {
                done();
            }
        });
        commit(SET_CONVO_EMAIL_CAMPAIGN_POLLING_STATE, false);
    },
    setDefaultState: async ({ commit }) => {
        commit(SET_DEFAULT_STATE);
    },
    setPersonalityQuizState: async ({ commit }) => {
        commit(SET_PERSONALITY_QUIZ_STATE);
    },
    setTriviaQuiz: async ({ commit }) => {
        commit(SET_TRIVIA_QUIZ_STATE);
    },
    setYougovWelcomeSurveyState: async ({ commit }) => {
        commit(SET_YOUGOV_WELCOME_SURVEY_STATE);
    },
    setActiveMessagePath: ({ commit }, path: number[]) => {
        commit(SET_ACTIVE_MESSAGE_PATH, path);
    },
    clearActiveMessagePath: ({ commit }) => {
        commit(SET_ACTIVE_MESSAGE_PATH, null);
    },
    toggleMessageItem: async ({ commit }, path: number[]) => {
        commit(CLOSE_MESSAGE_ITEMS_ACCORDION_SIBLINGS, path);
        commit(CLOSE_MESSAGE_ITEMS_ACCORDION_CHILDREN, path);
        commit(TOGGLE_MESSAGE_ITEMS_ACCORDION_ITEM, path);
    },
    toggleResultItem: async ({ commit }, path: number[]) => {
        commit(CLOSE_RESULT_ITEMS_ACCORDION_SIBLINGS, path);
        commit(CLOSE_RESULT_ITEMS_ACCORDION_CHILDREN, path);
        commit(TOGGLE_RESULT_ITEMS_ACCORDION_ITEM, path);
    },
    setConvoType: async ({ commit, dispatch }, convoType) => {
        commit(SET_CONVO_TYPE, convoType);
        switch (convoType) {
            case ConvoTypes.PersonalityQuiz:
                dispatch('setPersonalityQuizState');
                break;
            case ConvoTypes.TriviaQuiz:
                dispatch('setTriviaQuiz');
                break;
            case ConvoTypes.PDLConvo:
                dispatch('setDefaultState');
                break;
            case ConvoTypes.YougovWelcomeSurvey:
                dispatch('setYougovWelcomeSurveyState');
                break;
            default:
                dispatch('setDefaultState');
        }
    },
    setConvoCategories: async ({ commit }) => {
        const response = await CategoryClient.getCategories({
            size: 100,
            page: 1,
            type: CategoryType.convo,
        });
        const categories = response.items || [];
        commit(SET_CONVO_CATEGORIES, categories);
    },
    setTags: async ({ commit }, data: { query?: string; type?: TagType }) => {
        const tags = await new TagClient().fetch(data);
        commit(SET_TAGS, tags);
    },
    setSelectedTags: async ({ commit, dispatch }, payload: ITagPayload) => {
        commit(SET_SELECTED_TAGS, payload);
        commit(SET_CONVO_TAGS_ATTRIBUTE, payload.tags);
        const contentItemIds = state.messagesView.messageItems.map((message) => message.id);
        dispatch('setMessageItemHasChanges', contentItemIds);
    },
    setConvoName: async ({ commit }, name: string) => {
        commit(SET_CONVO_NAME, name);
    },
    deleteMessage: async (
        { commit, dispatch },
        { path, parentPath }: { path: number[]; parentPath?: number[] },
    ) => {
        commit(DELETE_MESSAGE_ITEM, { path, parentPath });

        const topLevelParent = state.messagesView.messageItems[path[0]];
        if (topLevelParent && topLevelParent.id > 0) {
            dispatch('setMessageItemHasChanges', [topLevelParent.id]);
        }

        if (path.length === 1) {
            dispatch('setMessageItemsTopLevelOrder');
        }
    },
    cloneMessage: async (
        { commit, dispatch },
        { path, parentPath }: { path: number[]; parentPath?: number[] },
    ) => {
        commit(CLONE_MESSAGE_ITEM, { path, parentPath });

        const topLevelParent = state.messagesView.messageItems[path[0]];
        if (topLevelParent && topLevelParent.id > 0) {
            dispatch('setMessageItemHasChanges', [topLevelParent.id]);
        }

        if (path.length === 1) {
            dispatch('setMessageItemsTopLevelOrder');
        }
    },
    deleteResult: async (
        { commit },
        { path, parentPath }: { path: number[]; parentPath?: number[] },
    ) => {
        commit(DELETE_RESULT_ITEM, { path, parentPath });
    },
    saveConvo: async (
        { commit, state },
        data?: { name: string; isPdl: boolean; isGlobal: boolean; isContentLibrary: boolean },
    ) => {
        const request = new CreateConvoRequest();
        const convoFactory = new ConvoFactory();
        request.name = data?.name || state.convo.name;
        request.description = state.convo.description || '';
        request.userId = 0; // TODO: get current user id
        request.channelCode = router.currentRoute.params.channelCode || '';
        request.type = state.convo.type;
        request.isPdl = data?.isPdl === undefined ? false : !!data.isPdl;
        request.isContentLibrary =
            data?.isContentLibrary === undefined ? false : !!data.isContentLibrary;
        request.isGlobal = data?.isGlobal != undefined ? data.isGlobal : state.convo.isGlobal;
        request.countries = state.convo.countries;
        request.dailyChat = state.convo.dailyChat;
        request.tags = state.convo.tags;
        request.topics = state.convo.topics;
        request.categories = state.convo.categories;

        const result: IConvo = await Vue.$api.content.createConvo(request);

        if (!result.contentItems) {
            result.contentItems = [];
        }
        const convo = convoFactory.make(result);
        convo.originalVersion = hash(convo);

        // TODO: error handling
        commit(SET_CONVO, convo);
    },
    saveConvoMessages: async ({ dispatch, commit, state, rootState }) => {
        const requestFactory = new ContentItemsRequestFactory();
        const request: ContentItemsRequest = requestFactory.make(state);
        const convo = {
            ...state.convo,
            categories: state.convo.categories.map((c) => ({
                id: c.id,
                slug: c.slug,
                isPrimary: c.isPrimary,
            })),
        };

        await Vue.$api.content.updateConvoAttributes(convo);
        await Vue.$api.content.saveContentItems(request);

        commit(RESET_CONVO_DETAILS_VALIDATION_STATE);
        const channelDetails: IChannelDetailsView = rootState.channel.channelDetails;
        const options: any = { id: state.convo.id };
        if (state.convo.channelId > 0) {
            options.channelCode = channelDetails.channel.code;
        }
        dispatch('loadConvo', options);
    },
    loadPdlConvo: async ({ commit }, { id }) => {
        const convoData: IConvo = await Vue.$api.content.getConvo(id);
        const convoFactory = new ConvoFactory();
        const convo = convoFactory.make(convoData);

        if (convo.contentItems.length) {
            const factory = new AccordionItemsFactory();
            const accordianItems: AccordionItemBase[] = factory.make(convo.contentItems);
            const sort = state.messagesView.messageItems.filter((item) => !item.isDeleted).length;
            const messageItems: AccordionItemBase[] = accordianItems.reduce((arr, item, idx) => {
                if (item.type !== ConvoMessageItemType.Result) {
                    item.isPdl = true;
                    item.id = 0;
                    item.sort = sort + idx;
                    item.isExternalPdl = !!convoData.ygdCountry;
                    return [...arr, item];
                }
                return arr;
            }, <AccordionItemBase[]>[]);

            commit(ADD_CONVO_MESSAGE_ITEMS, messageItems);
        }
    },
    loadConvo: async (
        { commit, dispatch },
        { id, channelCode }: { id: number; channelCode?: string },
    ) => {
        let savedConvo: IConvo;

        if (channelCode) {
            savedConvo = await Vue.$api.content.getConvo(id, channelCode);
        } else {
            savedConvo = await Vue.$api.content.getConvo(id);
        }

        const convoFactory = new ConvoFactory();
        const convo = convoFactory.make(savedConvo);
        convo.originalVersion = hash(convo);

        commit(SET_CONVO, convo);
        commit(SET_SELECTED_TAGS, { tags: [...convo.tags, ...convo.pdlTagsInConvo] });

        if (state.convo.contentItems.length > 0) {
            const accordianItemFactory = new AccordionItemsFactory();

            const accordianItems: AccordionItemBase[] = accordianItemFactory.make(
                state.convo.contentItems,
            );
            const messageItems: AccordionItemBase[] = accordianItems.reduce((arr, item) => {
                if (item.type !== ConvoMessageItemType.Result) {
                    item.isExternalPdl = !!savedConvo.ygdCountry;
                    return [...arr, item];
                }
                return arr;
            }, <AccordionItemBase[]>[]);

            dispatch('setConvoMessageItems', messageItems);

            if (convo.type === ConvoTypes.PersonalityQuiz || convo.type === ConvoTypes.TriviaQuiz) {
                let resultItems: AccordionItemBase[] = [];
                let answer: IAnswer = new Answer();

                const type =
                    convo.type === ConvoTypes.PersonalityQuiz
                        ? ContentItemType.PersonalityQuizResult
                        : ContentItemType.TriviaQuizResult;

                const quizResultItems: IContentItem[] = state.convo.contentItems.filter(
                    (item) => item.type == type,
                );

                if (quizResultItems.length > 0) {
                    resultItems = accordianItems.filter(
                        (item) => item.type == ConvoMessageItemType.Result,
                    );
                    answer = quizResultItems[0].answers[0];
                }

                dispatch('setConvoResultItems', { resultItems });
            }
        } else if (state.convo.type === ConvoTypes.PersonalityQuiz) {
            dispatch('setPersonalityQuizState');
        } else if (state.convo.type === ConvoTypes.TriviaQuiz) {
            dispatch('setTriviaQuiz');
        } else if (state.convo.type === ConvoTypes.YougovWelcomeSurvey) {
            dispatch('setYougovWelcomeSurveyState');
        } else {
            dispatch('setDefaultState');
        }

        if (convo.showFromDate) {
            dispatch('setPublishingProperty', {
                key: 'showFromDate',
                value: convo.showFromDate,
                validate: false,
            });
        }

        if (convo.hideFromDate) {
            dispatch('setPublishingProperty', {
                key: 'hideFromDate',
                value: convo.hideFromDate,
                validate: false,
            });
        }

        if (state.convo.lastPublishedVersion === state.convo.version) {
            let publish = undefined;

            if (state.convo.lastPublishedVersion) {
                publish = state.convo.published;
            }

            dispatch('setPublishingProperty', { key: 'publish', value: publish, validate: false });
        }
    },
    loadYougovSurveyChannels: async ({ commit, state }) => {
        const request = new GetChannelsRequest();
        request.page = 1;
        request.size = 200;
        request.yougovWelcomeSurvey = state.convo.id;

        const result: IPaging<ListChannel> = await ChannelClient.getChannels(request);
        const channels = result.items.map((o) => o.name);

        commit(SET_WELCOME_SURVEY_CHANNELS, channels);
    },
    setMessageItem: async (
        { commit, dispatch },
        { path, item }: { path: number[]; item: AccordionItemBase },
    ) => {
        commit(SET_MESSAGE_ITEM, { path, item });

        const topLevelParent = state.messagesView.messageItems[path[0]];
        if (topLevelParent && topLevelParent.id > 0) {
            dispatch('setMessageItemHasChanges', [topLevelParent.id]);
        }

        if (state.messagesView.validated) {
            dispatch('validateMessages');
        }
    },
    setMessageItemsOnReorder: async (
        { commit, dispatch },
        { path, items }: { path: number[]; items: AccordionItemBase },
    ) => {
        const parent = state.messagesView.messageItems[path[0]];
        commit(SET_MESSAGE_ITEMS_ACCORDION_ITEMS, { path, items });

        if (path.length === 0) {
            dispatch('setMessageItemsTopLevelOrder');
        } else if (parent.id) {
            dispatch('setMessageItemHasChanges', [parent.id]);
        }
    },
    setMessageItemsTopLevelOrder: async ({ commit, dispatch }) => {
        commit(SET_MESSAGE_ITEMS_TOP_LEVEL_ORDER);

        const messageItems = state.messagesView.messageItems.filter((o) => o.id > 0);
        if (messageItems.length) {
            const ids = messageItems.map((o) => o.id);
            if (ids.length) {
                dispatch('setMessageItemHasChanges', ids);
            }
        }
    },
    setMessageItemHasChanges: async ({ commit }, ids) => {
        const topLevelParents = state.messagesView.messageItems.filter(
            (o) => ids.indexOf(o.id) >= 0,
        );

        if (topLevelParents.length) {
            for (const parent of topLevelParents) {
                const observableItem = parent as unknown as IObservableItem<IConvoState>;
                if (observableItem) {
                    const itemHasChanges = observableItem && observableItem.itemHasChanges(state);
                    commit(SET_MESSAGE_ITEM_HAS_CHANGES, { parent, itemHasChanges });
                }
            }
        }
    },
    setResultItem: async (
        { commit, dispatch },
        { path, item }: { path: string; item: AccordionItemBase },
    ) => {
        commit(SET_RESULT_ITEM, { path, item });

        if (state.resultsView.validated) {
            dispatch('validateResult');
        }
    },
    setMessageType: async ({ commit }, { path, messageTypeIndex }) => {
        commit(SET_MESSAGE_TYPE, { path, messageTypeIndex });
    },
    validateConvo: async ({ commit }, operation: ConvoOperation) => {
        const { errorMessages, warningMessages } = await state.convo.validate(operation);

        commit(SET_CONVO_ERRORS, errorMessages);
        commit(SET_CONVO_WARNINGS, warningMessages);

        return Object.values(errorMessages).length === 0;
    },
    validateMessagesView: async ({ commit, getters, state }) => {
        commit(CLEAR_CONVO_MESSAGES_VIEW_ERRORS);
        const errors: string[] = [];
        if (state.convo.type === ConvoTypes.PDLConvo && !getters.hasSinglePostbackMessage) {
            errors.push(getErrorMessage('pdlConvoOnePostback'));
        }

        if (state.messagesView.messageItems.filter((item) => !item.isDeleted).length === 0) {
            errors.push(getErrorMessage('noMessageItems'));
        }

        commit(SET_CONVO_MESSAGES_VIEW_ERRORS, errors);
    },
    validateMessages: async ({ commit, dispatch, state }) => {
        const errorMessages = [];
        commit(CLEAR_CONVO_MESSAGES_ERRORS);

        for (let i = 0; i < state.messagesView.messageItems.length; i++) {
            const messageItem = state.messagesView.messageItems[i];

            if (messageItem && !messageItem.isDeleted) {
                errorMessages.push(...(await messageItem.validate(state, [i])));
            }
        }

        commit(SET_CONVO_MESSAGES_ERRORS, errorMessages);
        dispatch('validateMessagesView');
    },
    validateDatumResponses: async ({ commit, state, rootState }) => {
        const datumErrors = validateDatumResponses(
            state.messagesView.messageItems,
            rootState.yougov.definitions,
        );

        commit(SET_CONVO_MESSAGES_VIEW_ERRORS, [...state.messagesView.errors, ...datumErrors]);
    },
    validateResult: async ({ commit }, path) => {
        const errorMessages = [];
        commit(CLEAR_RESULT_ITEMS_ERRORS);

        if (state.resultsView.resultItems.filter((item) => !item.isDeleted).length === 0) {
            commit(SET_RESULT_ERRORS, [getErrorMessage('noResultItems')]);
        } else {
            commit(SET_RESULT_ERRORS, []);
        }

        for (let i = 0; i < state.resultsView.resultItems.length; i++) {
            const resultItem = state.resultsView.resultItems[i];

            if (resultItem && !resultItem.isDeleted) {
                errorMessages.push(...(await resultItem.validate(state, [i])));
            }
        }

        commit(SET_RESULT_ITEMS_ERRORS, errorMessages);
    },
    addMessageItem: async ({ commit }, { path, type }) => {
        commit(ADD_MESSAGE_ITEM, { path, type });
    },
    addResultItem: async ({ commit }) => {
        commit(ADD_RESULT_ITEM);
    },
    pinMessageItem: async ({ commit }, path: number[]) => {
        commit(PIN_MESSAGE_ITEM, path);
    },
    pinMessageAnswer: async ({ commit }, { path, isPinned }) => {
        commit(PIN_MESSAGE_ANSWER, { path, isPinned });
    },
    ignoreQuizQuestion: async ({ commit }, { path, isToggled }) => {
        commit(IGNORE_QUIZ_QUESTION, { path, isToggled });
    },
    setConvoMessageItems: async ({ commit, dispatch }, items: AccordionItemBase[]) => {
        commit(SET_CONVO_MESSAGE_ITEMS, items);
        dispatch('setConvoMessageItemsOriginalVersion');
    },
    setConvoResultItems: async ({ commit, dispatch }, { resultItems }) => {
        commit(SET_CONVO_RESULT_ITEMS, { resultItems });
        dispatch('setConvoResultItemsOriginalVersion');
    },
    setConvoMessageItemsOriginalVersion() {
        for (const messageItem of state.messagesView.messageItems) {
            const convertible = messageItem as unknown as IContentItemConvertible;
            if (convertible) {
                const originalContentItem = convertible.toContentItem(state);
                messageItem.originalVersion = hash(originalContentItem);
            }
        }
    },
    setConvoResultItemsOriginalVersion() {
        for (const resultItem of state.resultsView.resultItems) {
            const convertible = resultItem as Result;
            if (convertible) {
                const originalContentItem = convertible.toContentItemAnswer(state);
                resultItem.originalVersion = hash(originalContentItem);
            }
        }
    },
    undoDeletedMessageItem: async ({ commit, dispatch }, path) => {
        commit(UNDO_DELETED_MESSAGE_ITEM, path);
        dispatch('setMessageItemsTopLevelOrder');
    },
    undoDeletedResultItem: async ({ commit, dispatch }, path) => {
        commit(UNDO_DELETED_RESULT_ITEM, path);
        dispatch('setMessageItemsTopLevelOrder');
    },
    resetConvoDetailsState: async ({ commit }) => {
        commit(RESET_CONVO_DETAILS_STORE, initialState());
    },

    getLogicJumpData: async (
        { commit },
        { path, targetChannel, targetConvo, targetContentItem },
    ) => {
        if (!targetChannel && !targetConvo && !targetContentItem) {
            return;
        }

        const item = getAccordionItem(state.messagesView.messageItems, path);
        let logicJump = item?.logicJump;

        if (logicJump) {
            logicJump.isLoading = true;
            commit(SET_MESSAGE_ITEM_BY_KEY, { path, key: 'logicJump', value: logicJump });
        }

        const logicJumpData = await Vue.$api.tenant.getLogicJumpData({
            targetChannel,
            targetConvo,
            targetContentItem,
        });
        let channel: IContentOption | undefined;
        let convo: IContentOption | undefined;
        let message: IContentOption | undefined;

        if (logicJumpData.channelCode) {
            channel = {
                type: ContentType.Channel,
                code: logicJumpData.channelCode,
                text: logicJumpData.channelName,
            };
        }

        if (logicJumpData.convoId) {
            convo = {
                type: ContentType.Convo,
                id: logicJumpData.convoId,
                text: logicJumpData.convoName || '',
            };
        }

        if (logicJumpData.contentItemId) {
            message = {
                type: ContentType.Message,
                id: logicJumpData.contentItemId,
                text: logicJumpData.contentItemName || '',
            };
        }

        logicJump = new LogicJump({ channel, convo, message, originalIsSet: true });
        commit(SET_MESSAGE_ITEM_BY_KEY, { path, key: 'logicJump', value: logicJump });
    },
    setPublishingProperty: async ({ commit, dispatch }, { key, value, validate = true }) => {
        commit(SET_PUBLISHING_PROPERTY, { key, value });

        if (validate) {
            dispatch('validatePublishing', [key]);
        }
    },
    validatePublishing: async ({ commit }, keys?: string[]) => {
        const allErrors: any = await state.publishing.validate();
        let errors: any = {};

        if (keys && keys.length) {
            keys.forEach((key) => {
                if (allErrors[key]) {
                    errors[key] = allErrors[key];
                }
            });
        } else {
            errors = allErrors;
        }

        commit(SET_PUBLISHING_PROPERTY, { key: 'errors', value: errors });
    },
    publish: async (_, options?: { publish: boolean; isContentLibrary: boolean }) => {
        const data = {
            convoId: state.convo.id,
            publish: options?.publish || state.publishing.publish,
            isContentLibrary: options?.isContentLibrary,
            showFromDate: state.publishing.showFromDate,
            hideFromDate: state.publishing.hideFromDate,
        } as IPublishRequest;
        await Vue.$api.content.publish(data);
    },
    setConvoProperty: async ({ commit, dispatch }, { key, value }) => {
        commit(SET_CONVO_PROPERTY, { key, value });

        if (state.messagesView.validated) {
            dispatch('validateConvo', [key]);
        }
    },
    addAttribute: async ({ commit }, { path, value }) => {
        commit(ADD_ATTRIBUTE, { path, value });
    },
    removeAttribute: async ({ commit, dispatch }, { path, value }) => {
        commit(REMOVE_ATTRIBUTE, { path, value });

        const topLevelParent = state.messagesView.messageItems[path[0]];
        if (topLevelParent && topLevelParent.id > 0) {
            dispatch('setMessageItemHasChanges', [topLevelParent.id]);
        }
    },
    clearDatumData: ({ commit, state }) => {
        state.messagesView.messageItems.forEach((messageItem: AccordionItemBase) => {
            if ('clearDatumDefinition' in messageItem) {
                commit(CLEAR_MESSAGE_ITEM_DATUM_DEFINITION, messageItem);
            }
        });
    },
};

const mutations: MutationTree<IConvoState> = {
    [SET_CONVO_EMAIL_CAMPAIGN_POLLING_STATE](state: IConvoState, payload: boolean): void {
        state.publishing.isPollingEmailCampaign = payload;
    },
    [TOGGLE_MESSAGE_ITEMS_ACCORDION_ITEM](state: IConvoState, path): void {
        accordionMutators.toggle(state.messagesView.messageItems, path);
    },
    [TOGGLE_RESULT_ITEMS_ACCORDION_ITEM](state: IConvoState, path): void {
        accordionMutators.toggle(state.resultsView.resultItems, path);
    },
    [CLOSE_MESSAGE_ITEMS_ACCORDION_CHILDREN](state: IConvoState, path): void {
        accordionMutators.closeChildren(state.messagesView.messageItems, path);
    },
    [CLOSE_RESULT_ITEMS_ACCORDION_CHILDREN](state: IConvoState, path): void {
        accordionMutators.closeChildren(state.resultsView.resultItems, path);
    },
    [CLOSE_MESSAGE_ITEMS_ACCORDION_SIBLINGS](state: IConvoState, path): void {
        accordionMutators.closeSiblings(state.messagesView.messageItems, path);
    },
    [CLOSE_RESULT_ITEMS_ACCORDION_SIBLINGS](state: IConvoState, path): void {
        accordionMutators.closeSiblings(state.resultsView.resultItems, path);
    },
    [OPEN_ALL_MESSAGE_ITEMS_ACCORDION_ITEMS](state: IConvoState): void {
        accordionMutators.openAll(state.messagesView.messageItems);
    },
    [CLOSE_ALL_MESSAGE_ITEMS_ACCORDION_ITEMS](state: IConvoState): void {
        accordionMutators.closeAll(state.messagesView.messageItems);
    },
    [ADD_MESSAGE_ITEM](state: IConvoState, { path, type }): void {
        let item;
        const messageItem = getConvoMessageItemByType(type, state);

        if (path) {
            item = getAccordionItem(state.messagesView.messageItems, path);

            if (item) {
                accordionMutators.closeChildren(state.messagesView.messageItems, path);
                messageItem.isOpen = true;
                item.addItem(messageItem, type);
            }
        } else {
            accordionMutators.closeAll(state.messagesView.messageItems);
            messageItem.isOpen = true;
            messageItem.sort = state.messagesView.messageItems.filter(
                (item) => !item.isDeleted,
            ).length;
            messageItem.isNew = true;
            state.messagesView.messageItems.push(messageItem);
        }
    },
    [ADD_RESULT_ITEM](state: IConvoState): void {
        const resultItem = new Result();

        accordionMutators.closeAll(state.resultsView.resultItems);
        resultItem.isOpen = true;
        state.resultsView.resultItems.push(resultItem);
    },
    [DELETE_MESSAGE_ITEM](
        state: IConvoState,
        { path, parentPath }: { path: number[]; parentPath?: number[] },
    ): void {
        const item = getAccordionItem(state.messagesView.messageItems, path);
        let parent;

        if (item && item.id > 0) {
            item.isDeleted = true;
            item.close();
        } else {
            accordionMutators.deleteItem(state.messagesView.messageItems, path);
        }

        if (parentPath) {
            parent = getAccordionItem(state.messagesView.messageItems, parentPath);

            if (parent) {
                parent.deleteItemCallback(path);
            }
        }
    },
    [CLONE_MESSAGE_ITEM](
        state: IConvoState,
        { path, parentPath }: { path: number[]; parentPath?: number[] },
    ): void {
        const itemOriginal = getAccordionItem(state.messagesView.messageItems, path) as any;

        const contentItemOriginal = itemOriginal.toContentItem(state);

        if (itemOriginal?.type) {
            const item = getConvoMessageItemByType(itemOriginal?.type, state);
            const isClone: boolean = true;
            const cloneItem = item.toAccordionItem(
                contentItemOriginal,
                isClone,
            ) as AccordionItemBase;

            cloneItem.isOpen = true;
            cloneItem.isNew = true;
            cloneItem.hasChanges = true;
            cloneItem.id = 0;

            accordionMutators.closeAll(state.messagesView.messageItems);
            state.messagesView.messageItems.push(cloneItem);
        }
    },
    [DELETE_RESULT_ITEM](
        state: IConvoState,
        { path, parentPath }: { path: number[]; parentPath?: number[] },
    ): void {
        const index = path[0];
        const personalityResultfromServer = state.convo.contentItems.find(
            (item) => item.type == ContentItemType.PersonalityQuizResult,
        );

        if (personalityResultfromServer && index < personalityResultfromServer.answers.length) {
            const item = getAccordionItem(state.resultsView.resultItems, path);

            if (item) {
                item.isDeleted = true;
                item.close();
            }
        } else {
            accordionMutators.deleteItem(state.resultsView.resultItems, path);
        }

        if (parentPath) {
            const parent = getAccordionItem(state.resultsView.resultItems, parentPath);

            if (parent) {
                parent.deleteItemCallback(path);
            }
        }
    },
    [UNDO_DELETED_MESSAGE_ITEM](state: IConvoState, path: number[]): void {
        const item = getAccordionItem(state.messagesView.messageItems, path);

        if (item && item.id > 0) {
            item.isDeleted = false;
        }
    },
    [UNDO_DELETED_RESULT_ITEM](state: IConvoState, path: number[]): void {
        const item = getAccordionItem(state.resultsView.resultItems, path);

        if (item) {
            item.isDeleted = false;
        }
    },
    [SET_CONVO](state, convo: Convo): void {
        state.convo = convo;
    },
    [SET_MESSAGE_TYPE](state: IConvoState, { path, messageTypeIndex }): void {
        const item = getAccordionItem(state.messagesView.messageItems, path) as SelectMessageType;
        const messageType = item.options[messageTypeIndex];

        const messageItem = getConvoMessageItemByType(messageType.name, state);
        messageItem.accordionStyle = item.accordionStyle;
        messageItem.markerType = item.markerType;
        messageItem.title = messageType.title;
        messageItem.hasMarginBottom = item.hasMarginBottom;
        messageItem.isNew = item.isNew;
        messageItem.sort = item.sort;
        messageItem.isOpen = true;
        if (messageType.metadata?.prefix) {
            messageItem.prefix = messageType.metadata.prefix;
        }
        accordionMutators.replaceItem(state.messagesView.messageItems, path, messageItem);
    },
    [SET_CONVO_TYPE](state: IConvoState, convoType): void {
        state.convo.type = convoType;
    },
    [SET_CONVO_NAME](state: IConvoState, name: string): void {
        state.convo.name = name;
    },
    [SET_TAGS](state: IConvoState, payload: ITag[]) {
        state.availableTags = payload;
    },
    [SET_CONVO_CATEGORIES](state: IConvoState, payload: IConvoCategory[]) {
        state.availableConvoCategories = payload;
    },
    [SET_SELECTED_TAGS](state: IConvoState, payload: ITagPayload) {
        if (payload.type) {
            const selected = state.selectedTags.filter((tag) => tag.type !== payload.type);
            state.selectedTags = [...selected, ...(payload.tags || [])];
        } else {
            state.selectedTags = payload.tags;
        }

        state.messagesView.messageItems.forEach((item) => {
            const pdlTags =
                item.isPdl && item.tags ? item.tags.filter((tag) => tag.type === TagType.pdl) : [];
            item.tags = [...pdlTags, ...(payload.tags || [])];
        });
    },
    [SET_MESSAGE_ITEM](state: IConvoState, { path, item }): void {
        const currentIndex = path[path.length - 1];
        const level: AccordionItemBase[] = getLevel(state.messagesView.messageItems, path);
        Vue.set(level, currentIndex, item);
        level[currentIndex].setTitle();

        if (item instanceof CarouselTile) {
            const carousel = getAccordionItem(state.messagesView.messageItems, [path[0]]);

            if (carousel) {
                carousel.setTitle();
            }
        }
    },
    [SET_MESSAGE_ITEM_BY_KEY](state: IConvoState, { path, key, value }): void {
        const currentIndex = path[path.length - 1];
        const level: AccordionItemBase[] = getLevel(state.messagesView.messageItems, path);
        (level[currentIndex] as any)[key] = value;
        level[currentIndex].setTitle();
    },
    [SET_MESSAGE_ITEM_HAS_CHANGES](state: IConvoState, { parent, itemHasChanges }): void {
        const item = state.messagesView.messageItems.find((o) => o.id === parent.id);
        if (item) {
            item.hasChanges = itemHasChanges;
        }
    },
    [SET_MESSAGE_ITEMS_ACCORDION_ITEMS](state: IConvoState, { path, items }): void {
        const level = getLevel(state.messagesView.messageItems, [...path, 0]);
        level.splice(0, level.length, ...items);
    },
    [SET_MESSAGE_ITEMS_TOP_LEVEL_ORDER](state: IConvoState): void {
        for (const [index, message] of Object.entries(
            state.messagesView.messageItems.filter((item) => !item.isDeleted),
        )) {
            message.sort = parseInt(index);
        }
    },
    [CLEAR_MESSAGE_ITEM_DATUM_DEFINITION](_, item: DatumContentItem): void {
        item.clearDatumDefinition();
    },
    [SET_RESULT_ITEM](state: IConvoState, { path, item }): void {
        const currentIndex = path[path.length - 1];
        const level: AccordionItemBase[] = getLevel(state.resultsView.resultItems, path);
        level[currentIndex] = item;
        level[currentIndex].setTitle();
    },
    [SET_CONVO_ERRORS](state: IConvoState, errorMessages: any): void {
        state.convo.errors = errorMessages;
    },
    [SET_CONVO_WARNINGS](state: IConvoState, warningMessages: any): void {
        state.convo.warnings = warningMessages;
    },
    [CLEAR_CONVO_MESSAGES_VIEW_ERRORS](state: IConvoState): void {
        state.messagesView.errors = [];
    },
    [SET_CONVO_MESSAGES_VIEW_ERRORS](state: IConvoState, errorMessages: string[]): void {
        state.messagesView.errors = errorMessages;
    },
    [CLEAR_CONVO_MESSAGES_ERRORS](state: IConvoState): void {
        traversal(state.messagesView.messageItems, (item) => {
            item.clearErrorMessages();
        });
    },
    [SET_CONVO_MESSAGES_ERRORS](state: IConvoState, errorMessages): void {
        state.messagesView.validated = true;

        errorMessages.forEach((errorMessage: any) => {
            const item = getAccordionItem(state.messagesView.messageItems, errorMessage.path);
            const topLevelItem = state.messagesView.messageItems[errorMessage.path[0]];

            if (item) {
                item.errors = { ...item.errors, ...errorMessage.errors };
                item.isValid = false;
            }

            if (topLevelItem) {
                topLevelItem.isValid = false;
            }
        });
    },
    [CLEAR_RESULT_ITEMS_ERRORS](state: IConvoState): void {
        traversal(state.resultsView.resultItems, (item) => {
            item.clearErrorMessages();
        });
    },
    [SET_RESULT_ITEMS_ERRORS](state: IConvoState, errorMessages): void {
        errorMessages.forEach((errorMessage: any) => {
            const item = getAccordionItem(state.resultsView.resultItems, errorMessage.path);

            if (item) {
                item.errors = { ...item.errors, ...errorMessage.errors };
            }
        });

        state.resultsView.validated = true;
    },
    [SET_RESULT_ERRORS](state: IConvoState, errorMessages): void {
        state.resultsView.errors = errorMessages;
    },
    [SET_DEFAULT_STATE](state: IConvoState): void {
        const selectMessageType = new SelectMessageType(state.convo.type as ConvoTypes);

        selectMessageType.isOpen = true;
        state.messagesView.messageItems = [selectMessageType];
        state.resultsView.resultItems = [];
    },
    [SET_PERSONALITY_QUIZ_STATE](state: IConvoState): void {
        const selectMessageType = new SelectMessageType(state.convo.type as ConvoTypes);

        selectMessageType.isOpen = true;
        state.messagesView.messageItems = [selectMessageType];
        state.resultsView.resultItems = [];
    },
    [SET_TRIVIA_QUIZ_STATE](state: IConvoState): void {
        const selectMessageType = new SelectMessageType(state.convo.type as ConvoTypes);

        selectMessageType.isOpen = true;
        state.messagesView.messageItems = [selectMessageType];

        const result = new Result();
        result.isDeletable = false;
        state.resultsView.resultItems = [result];
    },
    [SET_YOUGOV_WELCOME_SURVEY_STATE](state: IConvoState): void {
        const ygWelcomeSurveyMessage = new YougovWelcomeSurveyButtons();
        state.messagesView.messageItems = [ygWelcomeSurveyMessage];
    },
    [PIN_MESSAGE_ITEM](state: IConvoState, path: number[]): void {
        const pinnedItemIndex = path[path.length - 1];
        const level = getLevel(state.messagesView.messageItems, path);

        level.forEach((item, index) => {
            if (index === pinnedItemIndex) {
                item.isPinned = !item.isPinned;
            } else {
                item.isPinned = false;
            }
        });

        const pinnedTile = level.splice(pinnedItemIndex, 1)[0];
        level.push(pinnedTile);
    },
    [PIN_MESSAGE_ANSWER](state: IConvoState, { path, isPinned }): void {
        const level = getLevel(state.messagesView.messageItems, path);
        level.forEach((item, index) => {
            if (item.type == ConvoMessageItemType.QuickReply) {
                const answerItems = item.items[0].items;
                if (isPinned) {
                    answerItems[answerItems.length - 1].isPinned = true;
                } else {
                    answerItems[answerItems.length - 1].isPinned = false;
                }
            }
        });
    },
    [IGNORE_QUIZ_QUESTION](state: IConvoState, { path }): void {
        const item = getAccordionItem(state.messagesView.messageItems, path);
        if (item) {
            (item as unknown as IQuizContentItem).handleIgnoreQuiz(state.convo.type as ConvoTypes);
        }
    },
    [ADD_CONVO_MESSAGE_ITEMS](state: IConvoState, messageItems: AccordionItemBase[]): void {
        state.messagesView.messageItems.push(...messageItems);
    },
    [SET_CONVO_MESSAGE_ITEMS](state: IConvoState, messageItems: AccordionItemBase[]): void {
        state.messagesView.messageItems = messageItems;
    },
    [SET_CONVO_RESULT_ITEMS](state: IConvoState, { resultItems }): void {
        state.resultsView.resultItems = (resultItems.length > 0 && resultItems[0].items) || [];
    },
    [RESET_CONVO_DETAILS_STORE](state: IConvoState, initialState: IConvoState): void {
        Object.keys(initialState).forEach((key) => {
            state[key] = initialState[key];
        });
    },
    [RESET_CONVO_DETAILS_VALIDATION_STATE](state: IConvoState): void {
        state.messagesView.validated = false;
        state.resultsView.validated = false;
    },
    [SET_PUBLISHING_PROPERTY](state, { key, value }): void {
        Vue.set(state.publishing, key, value);
    },
    [SET_CONVO_PROPERTY](state, { key, value }): void {
        Vue.set(state.convo, key, value);
    },
    [ADD_ATTRIBUTE](state, { path, value }): void {
        const item = getAccordionItem(state.messagesView.messageItems, path);

        if (item) {
            (item as any).attributes.push(value);

            if (value === Attributes.InlineResponse) {
                item.items[0].items.push(new InlineResponseTextStatement());
            }
        }
    },
    [REMOVE_ATTRIBUTE](state, { path, value }): void {
        const item = getAccordionItem(state.messagesView.messageItems, path) as
            | ButtonsAnswer
            | QuickReplyAnswer;

        if (item) {
            const index: number = item.attributes.indexOf(value);

            if (index > -1) {
                item.attributes.splice(index, 1);
            }
        }

        switch (value) {
            case Attributes.LogicJump:
                item.logicJump = new LogicJump();
                break;
            case Attributes.InlineResponse:
                (item.items[0].items[0] as InlineResponseTextStatement).statement = '';
                break;
            case Attributes.Media:
                if (item instanceof QuickReplyAnswer) {
                    item.buttonImageUrl = '';
                }
                break;
        }
    },
    [SET_ACTIVE_MESSAGE_PATH](state: IConvoState, payload: number[] | null): void {
        state.messagesView.activeMessagePath = payload;
    },
    [SET_CONVO_TAGS_ATTRIBUTE](state: IConvoState, payload: ITag[]): void {
        state.convo.tags = payload;
    },
    [SET_WELCOME_SURVEY_CHANNELS](state: IConvoState, payload: string[]): void {
        state.messagesView.yougovSurveyChannels = payload;
    },
};

export const convoDetails: Module<IConvoState, IRootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
