import messageTypes from '@/json/inlineResponseTypes';
import { AccordionItemBase } from './accordionItemBase';
import { MessageType } from './messageType';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum.ts';
import { ListMarker } from '../enums/listMarker.enum';
import { AccordionStyle } from '../enums/accordionStyle.enum';

export class SelectInlineResponseType extends AccordionItemBase {
    constructor(items = []) {
        super(items);
        this.subtitle = 'Select an inline response type';
        this.type = ConvoMessageItemType.SelectMessageType;
        this.isDraggable = true;
        this.isDeletable = true;
        this.accordionStyle = AccordionStyle.ThirdLevel;
        this.markerType = ListMarker.None;
        this.isOpen = true;
        this.hasMarginBottom = false;
        this.setOptions();
    }

    private setOptions() {
        this.options = messageTypes.map(
            (messageType: MessageType) =>
                new MessageType({
                    name: messageType.name,
                    title: messageType.title,
                    icon: messageType.icon,
                }),
        );
    }

    options: MessageType[];
    buttonStyle: string;
}
