<template>
    <portal v-if="show" to="overlay-portal" :order="order">
        <transition
            name="fade"
            @before-enter="$emit('beforeEnter')"
            @enter="$emit('afterEnter')"
            @after-enter="onAfterEnter"
            @enter-cancelled="$emit('enterCancelled')"
            @before-leave="onBeforeLeave"
            @leave="$emit('afterLeave')"
            @after-leave="$emit('afterLeave')"
            @leave-cancelled="$emit('leaveCancelled')"
        >
            <div
                v-if="showOverlay"
                class="overlay"
                :style="getStyle()"
                :class="[
                    cssClass,
                    {
                        transparent: transparency >= 0,
                        'overlay--disabled-scroll': disableOverlayScroll,
                    },
                ]"
            >
                <slot />
                <button
                    v-if="showCloseButton"
                    class="close-button"
                    @click="$emit('onClose', $event)"
                >
                    <Icon name="cross" :size="20" />
                </button>
            </div>
        </transition>
    </portal>
</template>

<script>
import Icon from '@/components/ui/Icon';

let windowScrollY;

export default {
    name: 'Overlay',
    components: {
        Icon,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        showCloseButton: {
            type: Boolean,
            default: false,
        },
        transparency: {
            type: Number,
            default: 1,
        },
        disableBodyScroll: {
            type: Boolean,
            default: false,
        },
        disableOverlayScroll: {
            type: Boolean,
            default: false,
        },
        cssClass: {
            type: String,
            default: undefined,
        },
        order: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            closeOnEscape: null,
            showOverlay: false,
        };
    },
    watch: {
        show(newValue) {
            this.$nextTick(() => {
                this.showOverlay = newValue;
            });
        },
    },
    created() {
        this.closeOnEscape = (event) => {
            if (event.keyCode === 27) {
                this.$emit('onClose');
            }
        };

        this.overlayController(this.show);
        this.showOverlay = this.show;
    },
    mounted() {},
    destroyed() {
        this.overlayController(false);
    },
    methods: {
        getStyle() {
            const style = {};
            if (this.transparency) {
                style['--transparency'] = this.transparency;
            }
            return style;
        },
        overlayController(disable) {
            if (disable) {
                document.body.addEventListener('keyup', this.closeOnEscape);

                if (this.disableBodyScroll) {
                    windowScrollY = window.scrollY;
                    document.body.classList.add('overlay-opened');
                }
            } else {
                document.body.removeEventListener('keyup', this.closeOnEscape);

                if (this.disableBodyScroll) {
                    document.body.classList.remove('overlay-opened');

                    if (windowScrollY) {
                        window.scrollTo(0, windowScrollY);
                    }
                }
            }
        },
        onAfterEnter() {
            this.overlayController(true);
            this.$emit('afterEnter');
        },
        onBeforeLeave() {
            this.overlayController(false);
            this.$emit('beforeLeave');
        },
    },
};
</script>

<style lang="scss" scoped>
.overlay {
    z-index: $overlay-z-index;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: $overlay-background-color;

    &.transparent {
        background: $overlay-background-transparent-color;
        @include alpha-background-color($default-light-color, var(--transparency));
    }

    &--disabled-scroll {
        overflow: hidden;
    }
}

.close-button {
    position: fixed;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.fade-enter-active {
    animation: fade-in 0.3s;
}

.fade-leave-active {
    animation: fade-in 0.3s reverse;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>
