export class Breadcrumb {
    constructor(text: string, link?: any) {
        this.text = text;
        if (link) {
            this.link = link;
        }
    }

    text: string;
    link?: any;
}
