import axios from '@/utils/request';
import { IPaging } from '@inconvo/types/interfaces';
import { LoadingFlag } from '@/store/enums/loadingIds.enum';
import { CategoryRequest, MoveCategoryRequest } from '@/store/models/dtos/categoryRequest.dto';
import { Category } from '@/store/models/dtos/category.dto';
import { GetCategoryParams } from '@/store/models/interfaces';

export class CategoryClient {
    public static async getCategories(params: GetCategoryParams = {}): Promise<IPaging<Category>> {
        return await axios.request<GetCategoryParams, IPaging<Category>>({
            url: '/category',
            method: 'GET',
            errorMessage: 'Could not load categories',
            loadingId: LoadingFlag.CategoryGet,
            params,
        });
    }

    public static async getCategory(id: string): Promise<Category> {
        return await axios.request<void, Category>({
            url: `/category/${id}`,
            method: 'GET',
            errorMessage: 'Could not load category',
            loadingId: LoadingFlag.CategoryGetOne,
        });
    }

    public static async createCategory(data: CategoryRequest): Promise<Category> {
        return await axios.request<CategoryRequest, Category>({
            url: '/category',
            method: 'POST',
            data,
            errorMessage: 'Could not create category',
            loadingId: LoadingFlag.CategorySave,
        });
    }

    public static async updateCategory(
        data: CategoryRequest,
        categoryId: number,
    ): Promise<Category> {
        return await axios.request<CategoryRequest, Category>({
            url: `/category/${categoryId}`,
            method: 'PUT',
            data,
            errorMessage: 'Could not update category',
            loadingId: LoadingFlag.CategorySave,
        });
    }

    public static async deleteCategory(categoryId: string): Promise<void> {
        return await axios.request<void, void>({
            url: `/category/${categoryId}`,
            method: 'DELETE',
            errorMessage: 'Could not delete category',
        });
    }

    public static async moveChannelsToCategory(data: MoveCategoryRequest): Promise<Category> {
        return await axios.request<void, Category>({
            url: '/channels/update-category',
            method: 'PUT',
            data,
            errorMessage: 'Could not update channels in the category',
        });
    }
}
