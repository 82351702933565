<template>
    <svg class="icon" :style="{ fill: color, fontSize: size }">
        <use :xlink:href="url" />
    </svg>
</template>

<script>
export default {
    name: 'Icon',
    props: {
        name: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: undefined,
        },
        size: {
            type: Number,
            default: undefined,
        },
    },
    data() {
        return {
            hash: process.env.VUE_APP_ICON_HASH,
        };
    },
    computed: {
        url() {
            const hashString = this.hash ? `.${this.hash}` : '';
            return `/img/icons-sprite${hashString}.svg#sprite-${this.name}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.icon {
    display: block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}
</style>
