import { RuleSet } from '@/store/models/audience/ruleSet';
import { RuleSetDto } from '../dtos/audience.dto';

export class AudienceRuleSetFactory {
    public static make(data: RuleSetDto): RuleSet {
        const ruleSet = new RuleSet();

        ruleSet.id = data.id;
        ruleSet.parentId = data.parentId;
        ruleSet.operator = data.operator;

        return ruleSet;
    }
}
