import { ChannelClient } from '@/api/channel';
import { ContentClient } from '@/api/content';
import { TenantClient } from '@/api/tenant';
import { sanitize } from '@/utils/sanitizer';
import truncateStringWithEllipsis from '@/utils/truncateStringWithEllipsis';
import { ContentItemType, ContentType, ConvoTypes } from '../enums';
import { ContentOption } from '../models';
import {
    ContentItemsRequest,
    GetChannelsRequest,
    GetConvosRequest,
    ListChannel as ChannelDto,
    SearchContentRequest,
} from '../models/dtos';
import { IContentItem, IConvo, ISearchResponse } from '../models/interfaces';
import { IPaging } from '@inconvo/types/interfaces';
import { IAnswer } from '../models/interfaces/answer.interface.dto';

export interface IContentResponse {
    channel?: ContentOption;
    convo?: ContentOption;
    message?: ContentOption;
}

const makeChannelContentOption = (item: ISearchResponse) => {
    return new ContentOption({
        type: ContentType.Channel,
        text: item.channelName,
        code: item.channelCode,
    });
};

const makeConvoContentOption = (item: ISearchResponse) => {
    return new ContentOption({
        type: item.type === ContentType.Menu ? ContentType.Menu : ContentType.Convo,
        text: item.convoName || '',
        id: item.convoId,
    });
};

const makeMessageContentOption = (item: ISearchResponse) => {
    return new ContentOption({
        type: ContentType.Message,
        contentItemType: item.contentItemType,
        text: sanitize(item.contentItemName || ''),
        id: item.contentItemId,
        info: `Convo Id: ${item.convoId}`,
        isMultipleChoice: item.isMultipleChoice,
    });
};

const makeContentOption = (item: ISearchResponse) => {
    const map: Partial<Record<ContentType, (item: ISearchResponse) => ContentOption>> = {
        [ContentType.Channel]: makeChannelContentOption,
        [ContentType.Convo]: makeConvoContentOption,
        [ContentType.Menu]: makeConvoContentOption,
        [ContentType.Message]: makeMessageContentOption,
    };

    if (!map[item.type]) {
        throw new Error('Could not find content option type');
    }

    return map[item.type]!(item);
};

export const searchChannels = async ({
    query,
    includeInactive,
    page,
    pageSize,
}: {
    query: string;
    includeInactive: boolean;
    page: number;
    pageSize?: number;
}) => {
    const request = new GetChannelsRequest();
    request.q = query || '';
    request.includeInactive = includeInactive ? 1 : 0;
    request.page = page || 1;
    request.size = pageSize || 10;

    const result = await ChannelClient.getChannels(request);
    return result.items.map((item: ChannelDto) => {
        return new ContentOption({
            type: ContentType.Channel,
            text: item.name,
            code: item.code,
            status: item.status,
        });
    });
};

export const searchConvos = async ({
    query,
    channelCode,
    channelId,
    page,
    pageSize,
    convoType,
}: {
    query: string;
    channelCode?: string;
    channelId?: number;
    page: number;
    pageSize?: number;
    convoType?: string;
}) => {
    const request = new GetConvosRequest();
    if (channelCode) {
        request.channelCode = channelCode;
    }
    if (channelId) {
        request.channelId = channelId;
    }
    request.q = query;
    request.page = page || 1;
    request.size = pageSize || 10;
    request.includeGenerated = true;
    request.type = convoType || '';

    const result: IPaging<IConvo> = await ContentClient.getConvos(request);
    return result.items.map((item: any) => {
        return new ContentOption({
            type: ContentType.Convo,
            text: item.name,
            id: item.id,
            published: !!item.published,
        });
    });
};

export const searchMessages = async ({
    query,
    convoId,
    contentTypes,
}: {
    query: string;
    convoId: number;
    contentTypes?: ContentItemType[];
}) => {
    const request = new ContentItemsRequest();
    request.convoId = convoId;

    const items: IContentItem[] = await ContentClient.getContentItems(request, query, contentTypes);
    return items.map((item: IContentItem) => {
        const firstAnswer: IAnswer = item.answers && item.answers[0];
        let text = sanitize(item.title || firstAnswer?.title || firstAnswer?.text || ' ');
        let info;
        if (item.type === ContentItemType.AttachmentUrl) {
            text = `Attachment`;
            info = truncateStringWithEllipsis(item.attachment, 50) || '--';
        }

        return new ContentOption({
            type: ContentType.Message,
            text,
            id: item.id,
            info,
            isMultipleChoice: item.multipleChoice,
        });
    });
};

export const searchContent = async ({
    query,
    contentTypes = [],
    convoTypes = [],
    requireChannel = true,
}: {
    query: string;
    contentTypes?: ContentItemType[];
    convoTypes?: ConvoTypes[];
    requireChannel?: boolean;
}) => {
    const request = new SearchContentRequest();
    request.q = query || '';
    request.requireChannel = requireChannel;

    if (contentTypes?.length) {
        request.contentTypes = contentTypes.join(',');
    }

    if (convoTypes?.length) {
        request.convoTypes = convoTypes.join(',');
    }

    const result = await TenantClient.getAnyContentType(request);

    return result.items.map((item: ISearchResponse) => {
        const option: IContentResponse = {};

        option.channel = makeChannelContentOption(item);

        if (item.type !== ContentType.Channel) {
            option.convo = makeConvoContentOption(item);
        }

        if (
            item.type !== ContentType.Channel &&
            item.type !== ContentType.Convo &&
            item.type !== ContentType.Menu
        ) {
            option.message = makeMessageContentOption(item);
        }

        return option;
    });
};

export const getContentBatch = async (params: {
    channel_codes?: string[];
    convo_ids?: string[];
    content_item_ids?: string[];
    comment_text?: string[];
}): Promise<ContentOption[]> => {
    const results: ISearchResponse[] = await ContentClient.getContentBatch(params);
    return results.map((result) => makeContentOption(result));
};

export const findContentOption = (response: IContentResponse): ContentOption => {
    return response?.message || response?.convo || response.channel!;
};

export default {
    searchChannels,
    searchConvos,
    searchMessages,
    searchContent,
    getContentBatch,
};
