<template>
    <div class="message-bubble-wrapper" :class="{ 'message-bubble-wrapper--has-arrow': showArrow }">
        <div class="message-bubble">
            <div
                v-if="imageUrl"
                class="message-bubble-image"
                :class="{ 'message-bubble-image--cropped': cropImage }"
            >
                <img
                    :src="imageUrl"
                    alt="Message image"
                    @mousedown.prevent="imageOnMousedown"
                    @mouseup="imageOnMouseup"
                />
            </div>

            <div v-if="!!$slots.default" class="message-bubble-content">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
let mouseClickStartX;
let mouseClickStartY;

export default {
    name: 'MessageBubble',
    props: {
        imageUrl: {
            type: String,
            default: undefined,
        },
        showArrow: {
            type: Boolean,
            default: true,
        },
        cropImage: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        imageOnMousedown(event) {
            mouseClickStartX = event.x;
            mouseClickStartY = event.y;
        },
        imageOnMouseup(event) {
            if (
                Math.abs(mouseClickStartX - event.x) < 5 &&
                Math.abs(mouseClickStartY - event.y) < 5
            ) {
                this.$emit('imageOnClick', event);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.message-bubble-wrapper {
    position: relative;
    line-height: 0;

    &::before {
        content: '';
        z-index: -1;
        display: none;
        position: absolute;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $inconvo-messenger-message-bubble-bg-color;
        transform: rotate(-90deg);
        left: -7px;
        bottom: 9px;
    }

    &--has-arrow {
        &::before {
            display: block;
        }
    }
}

.message-bubble {
    display: inline-flex;
    flex-direction: column;
    overflow: hidden;
    max-width: 220px;
    height: 100%;
    width: 100%;
    border-radius: 2px;
    background: $inconvo-messenger-message-bubble-bg-color;

    @include media-query(medium) {
        max-width: 136px;
    }
}

.message-bubble-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 10px;

    @include media-query(medium) {
        padding: 5px;
    }

    /deep/ p:empty::after {
        content: '\00A0';
    }
}

.message-bubble-image {
    img {
        width: 100%;
    }

    &--cropped {
        height: 120px;

        img {
            height: 120px;
            object-fit: cover;
        }
    }
}
</style>
