<template>
    <EditorInput
        v-if="$options.richTextMessagesEnabled && type === 'textarea'"
        :id="id"
        :name="name || id"
        :label="label"
        :show-remaining-char="showRemainingChar"
        :content="value"
        :readonly="isPdl"
        :max="max"
        :menu-items="[
            $options.EditorInputMenuItem.Bold,
            $options.EditorInputMenuItem.Italic,
            $options.EditorInputMenuItem.Strike,
            $options.EditorInputMenuItem.Underline,
        ]"
        :errors="errors"
        @update="$emit('input', $event)"
        @plainTextChange="$emit('plainText', $event)"
    />
    <Input
        v-else
        :id="id"
        :label="label"
        :name="name || id"
        :type="type"
        :max="max"
        :show-remaining-char="showRemainingChar"
        :has-margin-bottom="false"
        :readonly="isPdl"
        :value="value"
        :errors="errors"
        @input="
            (value) => {
                $emit('input', value);
                $emit('plainText', value);
            }
        "
    />
</template>

<script>
import { EditorInputMenuItem } from '@/store/enums';
import { EditorInput, Input } from '@/components/forms';
import itemFormMixin from '@/mixins/itemFormMixin';
import config from '@/config';

export default {
    name: 'TextAreaInput',
    components: {
        EditorInput,
        Input,
    },
    mixins: [itemFormMixin],
    props: {
        value: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'textarea',
        },
        name: {
            type: String,
            default: undefined,
        },
        label: {
            type: String,
            default: 'Text area',
        },
        max: {
            type: Number,
            default: 250,
        },
        showRemainingChar: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Array,
            default: () => [],
        },
        isPdl: {
            type: Boolean,
            default: false,
        },
    },

    created() {
        this.$options.richTextMessagesEnabled = config.featureToggle.richTextMessagesEnabled;
        this.$options.EditorInputMenuItem = EditorInputMenuItem;
    },
    methods: {},
};
</script>
<style lang="scss" scoped></style>
