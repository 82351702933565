<template>
    <div>
        <ButtonWrapper
            class="m-b-1"
            :is-draggable="isDraggable"
            :is-deletable="data.isDeletable"
            :disable-button-menu="isDisabled || isReadOnly || !data.canHaveAttributes"
            :marker="data.marker || marker"
            :has-attributes="hasAttributes"
            @deleteOnClick="$emit('deleteOnClick', $event, path)"
        >
            <template #header>
                <div class="buttons-answer-header">
                    <Select
                        v-if="$options.convoTypes.YougovWelcomeChat === currentConvo.type"
                        :id="generateId('datum-answer', path)"
                        class="answer-type-select m-b-2"
                        label="Datum Answer"
                        show-clear-icon
                        :options="yougovDefinitionOptions"
                        :value="data.datumResponseCode"
                        :has-margin-bottom="false"
                        :disabled="!yougovDefinitionOptions.length"
                        :is-loading="$wait.is($options.LoadingFlag.YougovDefinitionDetailsGet)"
                        @input="(value) => onInput('datumResponseCode', value)"
                        @changed="onChanged"
                    />

                    <Input
                        :id="generateId('buttonLabel', path)"
                        type="text"
                        label="Button Label"
                        :name="generateId('buttonLabel', path)"
                        :max="100"
                        show-remaining-char
                        :has-margin-bottom="false"
                        :value="data.buttonLabel"
                        :errors="data.errors.buttonLabel"
                        :readonly="isReadOnly"
                        :disabled="isDisabled"
                        @input="(value) => onInput('buttonLabel', value)"
                        @changed="onChanged"
                    />
                </div>
            </template>

            <template #addAttributeButtons>
                <DropDownItem
                    icon="logic-jump"
                    :disabled="isOptionDisabled(Attributes.LogicJump)"
                    :disabled-text="isMultipleChoice ? 'Not compatible with Multiple Choice' : ''"
                    @click="addAttributeOnClick(Attributes.LogicJump)"
                >
                    Logic jump
                </DropDownItem>
                <DropDownItem
                    icon="inline-response"
                    :disabled="isOptionDisabled(Attributes.InlineResponse)"
                    :disabled-text="isMultipleChoice ? 'Not compatible with Multiple Choice' : ''"
                    @click="addAttributeOnClick(Attributes.InlineResponse)"
                >
                    Inline response
                </DropDownItem>
            </template>

            <template #attributes>
                <div
                    v-if="data.attributes && data.attributes.length"
                    class="button-answer__attribute-buttons"
                >
                    <AttributeButton
                        v-if="data.attributes.indexOf(Attributes.LogicJump) > -1"
                        class="button-answer__attribute-button"
                        icon="logic-jump"
                        @click="$emit('openLogicJump', path)"
                        @remove="attributeOnRemove(Attributes.LogicJump)"
                    >
                        Logic jump
                    </AttributeButton>
                    <AttributeButton
                        v-if="data.attributes.indexOf(Attributes.InlineResponse) > -1"
                        class="button-answer__attribute-button"
                        icon="inline-response"
                        @click="showInlineResponse = true"
                        @remove="attributeOnRemove(Attributes.InlineResponse)"
                    >
                        Inline response
                    </AttributeButton>
                </div>
            </template>

            <template>
                <div>
                    <Checkbox
                        v-if="isTriviaQuiz"
                        :id="generateId('is-correct', path)"
                        has-margin-bottom
                        label="This is a correct answer"
                        :value="data.isCorrectAnswer"
                        :disabled="ignoreQuizQuestion || isDisabled"
                        @input="(value) => onInput('isCorrectAnswer', value)"
                        @changed="onChanged"
                    />
                    <div v-if="isMultipleChoice">
                        <Checkbox
                            :id="generateId('is-exclusive', path)"
                            :value="data.isExclusive"
                            class="checkbox"
                            label="Exclusive"
                            :disabled="isDisabled || isReadOnly"
                            @input="(value) => onInput('isExclusive', value)"
                            @changed="onChanged"
                        />
                    </div>

                    <Select
                        v-if="!(isTriviaQuiz || isPersonalityQuiz)"
                        class="answer-type-select m-b-2"
                        label="Answer type"
                        :options="typeOptions"
                        :value="data.answerType.name"
                        :has-margin-bottom="false"
                        :disabled="isDisabled || isReadOnly || !data.canChangeAnswerType"
                        @input="
                            (value) =>
                                onInput(
                                    'answerType',
                                    data.answerTypeOptions.find((item) => item.name === value),
                                )
                        "
                        @changed="onChanged"
                    />

                    <Input
                        v-if="data.answerType.linkRequired"
                        :id="generateId('link', path)"
                        type="text"
                        label="Link"
                        class="m-b-1"
                        :has-margin-bottom="false"
                        :name="generateId('link', path)"
                        :value="data.link"
                        :errors="data.errors.link"
                        :disabled="isDisabled || isReadOnly"
                        @input="(value) => onInput('link', value)"
                        @changed="onChanged"
                    />
                    <Toggle
                        v-if="data.answerType.linkNewTab"
                        :id="generateId('link-target', path)"
                        class="m-b-3"
                        label="Open in a new tab?"
                        :value="data.linkOpenInNewTab"
                        :disabled="isDisabled || isReadOnly"
                        @input="(value) => onInput('linkOpenInNewTab', value)"
                    />

                    <Input
                        v-if="
                            data.answerType.linkRequired &&
                            isLinkNextDisposition(data.answerType.name)
                        "
                        :id="generateId('success-disposition-link', path)"
                        type="text"
                        label="Success Disposition Link"
                        class="m-b-1"
                        :has-margin-bottom="false"
                        :name="generateId('success-disposition-link', path)"
                        :value="data.successDispositionLink"
                        :errors="data.errors.successDispositionLink"
                        :disabled="isDisabled || isReadOnly"
                        @input="onInput('successDispositionLink', $event)"
                        @changed="onChanged"
                    />

                    <Input
                        v-if="
                            data.answerType.linkRequired &&
                            isLinkNextDisposition(data.answerType.name)
                        "
                        :id="generateId('failure-disposition-link', path)"
                        type="text"
                        label="Failure Disposition Link"
                        class="m-b-1"
                        :has-margin-bottom="false"
                        :name="generateId('failure-disposition-link', path)"
                        :value="data.failureDispositionLink"
                        :errors="data.errors.failureDispositionLink"
                        :disabled="isDisabled || isReadOnly"
                        @input="onInput('failureDispositionLink', $event)"
                        @changed="onChanged"
                    />

                    <div v-if="isPersonalityQuiz" class="associate-result" style="display: block">
                        <Select
                            v-model="data.selectedPersonalityQuizResult"
                            class="associate-result-select"
                            label="Personality Quiz Result"
                            :value="data.selectedPersonalityQuizResult"
                            :options="personalityQuizResults"
                            :disabled="!isAssociateResultEnabled || isDisabled || isReadOnly"
                            :errors="data.errors.selectedPersonalityQuizResult"
                            @input="onPersonalityQuizResultInput"
                            @changed="onChanged"
                        />
                        <IconButton
                            icon="award"
                            class="associate-result__button"
                            :disabled="isDisabled || isReadOnly"
                            @click="personalityQuizResultsButtonOnClick"
                        />
                    </div>
                    <Input
                        v-if="
                            data.externalId &&
                            $options.convoTypes.YougovWelcomeChat !== currentConvo.type
                        "
                        :id="generateId('externalId', path)"
                        type="text"
                        label="External ID"
                        class="m-b-2"
                        :has-margin-bottom="false"
                        :name="generateId('externalId', path)"
                        :value="data.externalId"
                        :errors="data.errors.externalId"
                        :readonly="true"
                    />
                </div>
            </template>
        </ButtonWrapper>

        <InlineResponse
            :show="showInlineResponse"
            :data="data.items[0]"
            :path="[...path, 0]"
            @close="showInlineResponse = false"
            @propertyOnChange="$emit('propertyOnChange', $event)"
            @addItemOnClick="(event, path, type) => $emit('addItemOnClick', event, path, type)"
            @deleteOnClick="
                (event, path, parentPath) => $emit('deleteOnClick', event, path, parentPath)
            "
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Input, Checkbox, Select, Toggle } from '@/components/forms';
import IconButton from '@/components/ui/IconButton';
import AttributeButton from '@/components/ui/AttributeButton';
import DropDownItem from '@/components/ui/DropDown/DropDownItem';
import itemFormMixin from '@/mixins/itemFormMixin';
import InlineResponse from '@/components/InlineResponse';
import ButtonWrapper from '@/components/ui/ButtonWrapper';
import { ConvoTypes, Attributes, LinkTarget, AnswerType, LoadingFlag } from '@/store/enums';

export default {
    name: 'ButtonsAnswer',
    components: {
        Input,
        Checkbox,
        Toggle,
        Select,
        IconButton,
        AttributeButton,
        DropDownItem,
        ButtonWrapper,
        InlineResponse,
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        isDraggable: {
            type: Boolean,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        marker: {
            type: String,
            required: true,
        },
        ignoreQuizQuestion: {
            type: Boolean,
            default: true,
        },
        currentChannel: {
            type: Object,
            default: undefined,
        },
        currentConvo: {
            type: Object,
            default: () => ({}),
        },
        isMultipleChoice: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        yougovDefinitionResponses: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            Attributes,
            showInlineResponse: false,
        };
    },
    computed: {
        ...mapGetters('convoDetails', {
            convoDetailsView: 'convoDetailsView',
            personalityQuizResults: 'personalityQuizResults',
        }),
        isPersonalityQuiz() {
            return this.convoDetailsView.convo.type === ConvoTypes.PersonalityQuiz;
        },
        isTriviaQuiz() {
            return this.convoDetailsView.convo.type === ConvoTypes.TriviaQuiz;
        },
        isAssociateResultEnabled() {
            return (
                this.personalityQuizResults &&
                this.personalityQuizResults.length > 0 &&
                this.ignoreQuizQuestion === false
            );
        },
        typeOptions() {
            return this.data.answerTypeOptions.map((item) => {
                const canBeMultipleChoice = this.isMultipleChoice && !item.canBeMultipleChoice;
                const isExcluded =
                    item.excludeMessageItemTypes.includes(this.messageItemType) ||
                    item.excludeConvoTypes.includes(this.convoDetailsView.convo.type);
                const isDisabled = canBeMultipleChoice || isExcluded;
                return {
                    id: item.name,
                    value: item.title,
                    disabled: isDisabled,
                    disabledText: isDisabled ? 'Incompatible option' : '',
                };
            });
        },
        hasAttributes() {
            return this.data.attributes && this.data.attributes.length ? true : false;
        },
        openInANewTab() {
            return this.linkTarget === LinkTarget.Blank;
        },
        messageItemType() {
            try {
                const messageItemPath = this.path[0];
                return this.convoDetailsView.messagesView.messageItems[messageItemPath].type;
            } catch (error) {
                return 'unknown';
            }
        },
        yougovDefinitionOptions() {
            return this.yougovDefinitionResponses.map((item) => ({
                ...item,
                id: item.code,
                value: `${item.code} - ${item.description}`,
            }));
        },
    },
    created() {
        this.$options.convoTypes = ConvoTypes;
        this.$options.LoadingFlag = LoadingFlag;
    },
    methods: {
        onPersonalityQuizResultInput(value) {
            this.onInput('selectedPersonalityQuizResult', value);
        },
        attributeOnRemove(value) {
            this.$emit('removeAttribute', { path: this.path, value });
        },
        addAttributeOnClick(value) {
            this.$emit('addAttribute', { path: this.path, value });
        },
        personalityQuizResultsButtonOnClick() {
            this.$router.push({
                name: 'results',
                params: { channelCode: this.currentChannel.code, convoId: this.currentConvo.id },
            });
        },
        isOptionDisabled(attribute) {
            return this.isMultipleChoice || this.data.attributes.indexOf(attribute) > -1;
        },
        isLinkNextDisposition(value) {
            return value === AnswerType.LinkNextDisposition;
        },
    },
};
</script>
<style lang="scss" scoped>
.button-answer {
    &__attribute-buttons {
        position: relative;
        top: -5px;
        margin-left: -5px;
        margin-bottom: 10px;
    }

    &__attribute-button {
        margin: 5px;
    }
}

.buttons-answer-header {
    flex: 1 1 auto;
    padding: 10px 0;
}

.associate-result {
    position: relative;

    &__button {
        position: absolute;
        top: (36px - 22px) / 2; // (input height - button height) / 2
        right: -30px;
    }
}
</style>
