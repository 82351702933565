<template>
    <div class="view-sign-in">
        <transition name="modal" mode="out-in">
            <ModalAuthChallenge
                v-if="showAuthChallenge"
                :loading="authChallengeSent"
                @answerAuthChallenge="onAnswerAuthChallenge"
            />
            <ModalSignIn
                v-else
                :loading="otpRequested"
                @signInYouGov="onSignInYouGov"
                @signInEmail="onSignInEmail"
            />
        </transition>
    </div>
</template>
<script>
import ModalSignIn from '@/components/Modal/ModalSignIn';
import ModalAuthChallenge from '@/components/Modal/ModalAuthChallenge';
import { mapActions } from 'vuex';

export default {
    name: 'SignIn',
    components: {
        ModalSignIn,
        ModalAuthChallenge,
    },
    data() {
        return {
            showAuthChallenge: false,
            authChallengeSent: false,
            otpRequested: false,
        };
    },
    methods: {
        ...mapActions('auth', ['signIn', 'answerAuthChallenge', 'signInFederated']),
        onSignInYouGov() {
            this.signInFederated('YouGovSSOOIDC');
        },
        async onSignInEmail(email) {
            try {
                this.otpRequested = true;
                await this.signIn(email);
                this.showAuthChallenge = true;
            } catch (error) {
                this.$toaster.add("We couldn't find an account with that username");
            } finally {
                this.otpRequested = false;
            }
        },
        async onAnswerAuthChallenge(code) {
            try {
                this.authChallengeSent = true;
                await this.answerAuthChallenge(code);
                if (this.$route.query.redirect) {
                    this.$router.push({ path: this.$route.query.redirect });
                } else {
                    this.$router.push({ name: 'all-channels' });
                }
            } catch (error) {
                this.authChallengeSent = false;
                if (error === 'InvalidAuthSession') {
                    this.showAuthChallenge = false;
                    this.$toaster.add('Login failed, please try again');
                }
                if (error === 'InvalidAuthCode') {
                    this.$toaster.add('Invalid verification code');
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.view-sign-in {
    background-color: $color-athens-gray;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-enter-active,
.modal-leave-active {
    transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
}

.modal-enter,
.modal-leave-to {
    opacity: 0;
    transform: translateY(20px);
}
</style>
