import axios from '@/utils/request';
import { LoadingFlag } from '@/store/enums/loadingIds.enum';
import { ITag } from '@/store/models/interfaces';

export interface IGetTopicsRequest {
    query?: string;
    namespace?: string;
    page?: number;
    size?: number;
}
export interface IGetTopicsResponse {
    items?: ITag[];
    total?: number;
    page?: number;
    size?: number;
}
export class TopicClient {
    async fetch(params: IGetTopicsRequest): Promise<IGetTopicsResponse> {
        const result: IGetTopicsResponse = await axios.request({
            url: '/topics',
            method: 'GET',
            params,
            errorMessage: 'Could not load topics',
            loadingId: LoadingFlag.TopicGet,
        });
        return result;
    }
}
