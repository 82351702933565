import { EmailSubjectType } from '@/enums';

export class GetAudienceRequest {
    q?: string;
    page: number;
    size: number;
}

export class AudienceRequest {
    id: number;
    tenantCode: string;
    exportTo: string;
    exportPlatform: string;
    name: string;
    rules: any[];
}
