import { GetAudienceRequest, AudienceRequest } from '@/store/models/dtos/audienceRequests.dto';
import { AudienceDto } from '@/store/models/dtos';
import { IPaging } from '@inconvo/types/interfaces';
import { LoadingFlag } from '@/store/enums/loadingIds.enum';
import axios from '@/utils/request';
import { IAudienceExportStatus, ExportOption } from '@/store/models/interfaces/audience.interface';
import { EstimateDto } from '@/store/models/dtos/audience.dto';
import { EmailBroadcastRequest } from '@/store/models/dtos/emailBroadcastRequest.dto';

export class AudienceClient {
    public static async getAudiences(data: GetAudienceRequest): Promise<IPaging<AudienceDto>> {
        let url = `/audience/list?page=${data.page}&size=${data.size}`;
        if (data.q) {
            url = `${url}&q=${data.q}`;
        }
        const result = await axios.request({
            url,
            method: 'GET',
            data,
            errorMessage: 'Could not load audiences',
            loadingId: LoadingFlag.AudienceGet,
        });
        return result as unknown as Promise<IPaging<AudienceDto>>;
    }

    public static async getAudience(id: number): Promise<AudienceDto> {
        const url = `/audience/${id}`;
        const result = await axios.request({
            url,
            method: 'GET',
            errorMessage: 'Could not load audience',
            loadingId: LoadingFlag.AudienceGetOne,
        });
        return result as unknown as Promise<AudienceDto>;
    }

    public static async createAudience(data: AudienceRequest): Promise<AudienceDto> {
        const url = `/audience/save`;
        const result = await axios.request({
            url,
            method: 'POST',
            data,
            errorMessage: 'Could not create audience',
            loadingId: LoadingFlag.AudienceSave,
        });
        return result as unknown as Promise<AudienceDto>;
    }

    public static async updateAudience(data: AudienceRequest): Promise<AudienceDto> {
        const url = `/audience/${data.id}`;
        const result = await axios.request({
            url,
            method: 'PUT',
            data,
            errorMessage: 'Could not update audience',
            loadingId: LoadingFlag.AudienceSave,
        });
        return result as unknown as Promise<AudienceDto>;
    }

    public static async estimate(data: any): Promise<number> {
        const url = `/audience-builder/estimate`;
        const result = await axios.request({
            url,
            method: 'POST',
            data,
            errorMessage: 'Could not estimate rule members',
            loadingId: LoadingFlag.AudienceEstimateRuleMember,
        });
        return (result as any).value as Promise<number>;
    }

    public static async estimateAll(
        data: AudienceRequest,
        useAthena: boolean,
    ): Promise<EstimateDto[]> {
        let url = `/audience-builder/estimate-all`;
        if (process.env.VUE_APP_AUDIENCE_BUILDER_ESTIMATE_V2) {
            url = useAthena
                ? `/audience-builder/v3/estimate-all`
                : `/audience-builder/estimate-all-v2`;
        }
        const result = await axios.request({
            url,
            method: 'POST',
            data,
            errorMessage: 'Could not estimate ruleset members',
            loadingId: LoadingFlag.AudienceEstimateRuleMember,
        });
        return result as unknown as Promise<EstimateDto[]>;
    }

    public static async getExportStatus(audienceId: number): Promise<IAudienceExportStatus> {
        const url = `/audience/status/${audienceId}`;
        const result = await axios.request({
            url,
            method: 'GET',
            errorMessage: 'Could not retrieve status of export job',
        });
        return result as unknown as Promise<IAudienceExportStatus>;
    }

    public static async deleteAudience(id: number): Promise<void> {
        const url = `/audience/${id}`;
        await axios.request({
            url,
            method: 'DELETE',
            errorMessage: 'Could not delete audience',
        });
    }

    public static async exportAudience(id: number): Promise<void> {
        const url = `/audience/export/${id}`;
        await axios.request({
            url,
            method: 'GET',
            errorMessage: 'Could not export audience',
        });
    }

    public static async getExportOptions(): Promise<ExportOption[]> {
        const url = `/audience/export-options`;
        const result = await axios.request({
            url,
            method: 'GET',
            errorMessage: 'Could not get export options',
        });

        return result as unknown as ExportOption[];
    }

    public static async sendBroadcastEmail(data: EmailBroadcastRequest): Promise<void> {
        const result = await axios.request({
            url: `/audience/send-service-email`,
            method: 'POST',
            data,
            errorMessage: 'Could not send email broadcast',
            loadingId: LoadingFlag.EmailBroadcastSend,
        });
        return result as unknown as Promise<void>;
    }
}
