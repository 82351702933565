var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group",class:[{ 'form-group--has-m-b': _vm.hasMarginBottom }]},[_c('div',{staticClass:"options-group",class:{
            'form-field': _vm.isFormFieldStyled,
            'options-group--button-style': _vm.isButtonStyled,
            'options-group--grouped': _vm.isGrouped,
            'options-group--horizontal': _vm.isHorizontal,
            'form-field--has-error': _vm.errors.length > 0,
            'form-field--has-bottom-space': _vm.hasMarginBottom,
        }},[(_vm.isFormFieldStyled)?_c('div',{staticClass:"form-field-label"},[_c('span',[_vm._v(_vm._s(_vm.label))])]):_vm._e(),(_vm.description)?_c('div',{staticClass:"options-group__description"},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e(),_c('div',{staticClass:"options-group__options"},[(_vm.isButtonStyled)?_vm._l((_vm.options),function(option,index){return _c('ButtonStyleRadioButton',{key:index,staticClass:"options-group__options__option",attrs:{"id":("options-" + (option.value)),"is-grouped":_vm.isGrouped,"has-margin-bottom":false,"name":_vm.name,"label":option.name,"input-value":option.value,"disabled":option.disabled,"icon":option.icon,"value":_vm.value},on:{"input":function (value) { return _vm.$emit('input', value); }}})}):_vm._l((_vm.options),function(option,index){return _c('RadioButton',{key:index,staticClass:"options-group__options__option",attrs:{"id":("options-" + (option.value)),"has-margin-bottom":false,"name":_vm.name,"label":option.name,"input-value":option.value,"icon":option.icon,"disabled":option.disabled,"value":_vm.value},on:{"input":function (value) { return _vm.$emit('input', value); }}})})],2)]),_c('div',{staticClass:"form-field-additional-information"},[_c('div',{staticClass:"form-field-additional-information__left"},[(_vm.errors && _vm.errors.length > 0)?_c('div',{staticClass:"form-field-error-message"},[_c('Icon',{attrs:{"name":"alert"}}),_vm._v(_vm._s(_vm.errors[0])+" ")],1):_vm._e(),(_vm.infoText)?_c('div',{staticClass:"form-field-info-text"},[_vm._v(_vm._s(_vm.infoText))]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }