<template>
    <div>
        <div class="image-upload-container" :class="[componentStyleClass]">
            <!-- SHOW PREVIEW -->
            <div
                v-if="showPreview"
                class="image-upload-preview"
                :class="[thumbnailLocationClass]"
                :style="thumbnailSizeClass"
            >
                <div
                    class="image-upload-preview__thumbnail"
                    :style="{ width: `${thumbnailSize}px`, height: `${thumbnailSize}px` }"
                >
                    <div
                        v-if="value"
                        class="image image__preview"
                        :class="{ 'has-error': errors.length > 0 }"
                        :style="{ backgroundImage: `url(${value})` }"
                        @click="showImagePreview = true"
                        @mouseover="showPreviewImageButton = true"
                        @mouseout="showPreviewImageButton = false"
                    >
                        <div v-show="showPreviewImageButton" class="icon-wrapper">
                            <Icon name="eye" :size="35" />
                        </div>
                    </div>
                    <div
                        v-else
                        class="image image__placeholder"
                        :class="{ 'has-error': errors.length > 0 }"
                        @click="showImageUploader = true"
                    >
                        <Icon name="camera" :size="45" />
                        <div
                            v-if="errors.length === 0 && !value && showActionWithin"
                            class="image__placeholder--action"
                            @click="showImageUploader = true"
                        >
                            Add Image
                        </div>
                    </div>
                </div>
                <div
                    class="image-upload-preview__info"
                    :class="{ 'image-upload-preview__info--center': thumbnailLocation === 'top' }"
                >
                    <div v-if="label" class="label">{{ label }}</div>
                    <div class="actions">
                        <div v-if="value">
                            <span class="action" @click="showImageUploader = true">Change</span>
                            <span class="separator">|</span>
                            <span class="action" @click="onDeleteImage">Delete</span>
                        </div>
                        <div
                            v-if="errors.length === 0 && !value && !showActionWithin"
                            class="action"
                            @click="showImageUploader = true"
                        >
                            Add Image
                        </div>
                        <div
                            v-if="errors.length > 0"
                            class="error"
                            @click="showImageUploader = true"
                        >
                            Missing image
                        </div>
                    </div>
                </div>
            </div>

            <!-- NO PREVIEW -->
            <div v-if="!showPreview" class="image-upload-button">
                <div class="label form-field-label">
                    <span>{{ label }}</span>
                </div>
                <h3 v-if="title" class="title">{{ title }}</h3>

                <div v-if="value">
                    <div class="image-url-container">
                        <div class="image-url" @click="onCopyUrlToClipboard">{{ value }}</div>
                        <div class="delete-icon" @click="onDeleteImage">
                            <Icon name="delete" :size="21" />
                        </div>
                    </div>
                </div>
                <div v-else>
                    <button type="button" @click="showImageUploader = true">
                        <Icon name="image" :size="21" />
                        <span class="button-label">{{ buttonLabel }}</span>
                    </button>
                    <div class="description">{{ description }}</div>
                </div>
                <ErrorMessage v-if="errors.length > 0">{{ errors[0] }}</ErrorMessage>
            </div>
        </div>
        <Overlay
            disable-body-scroll
            :show="showImageUploader"
            :show-close-button="true"
            @onClose="showImageUploader = false"
        >
            <ImageUploadOverlay
                embedded
                :aspect-ratio="aspectRatio"
                :image-name-prefix="imageNamePrefix"
                @imageUploaded="onImageUploaded"
                @cancelButtonOnClick="showImageUploader = false"
            />
        </Overlay>
        <Overlay
            disable-body-scroll
            :show="showImagePreview"
            :show-close-button="true"
            @onClose="showImagePreview = false"
        >
            <div
                class="channel-image-preview"
                :style="{ backgroundImage: `url(${value})` }"
                @click="onCopyUrlToClipboard"
            ></div>
        </Overlay>
    </div>
</template>

<script>
import ImageUploadOverlay from '@/views/ImageUploadOverlay';
import Icon from '@/components/ui/Icon.vue';
import Overlay from '@/components/ui/Overlay';
import ErrorMessage from '@/components/ui/ErrorMessage';
import onlyValues from '@/utils/onlyValues.ts';

export default {
    name: 'ImageUploaderButton',
    components: {
        Icon,
        Overlay,
        ImageUploadOverlay,
        ErrorMessage,
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        showPreview: {
            type: Boolean,
            default: false,
        },
        aspectRatio: {
            type: Number,
            default: null,
        },
        title: {
            type: String,
            default: undefined,
        },
        buttonLabel: {
            type: String,
            default: 'Click to Browse and Upload Image…',
        },
        description: {
            type: String,
            default: 'Max file size 5MB. GIF, PNG and JPG only.',
        },
        inputStyle: {
            type: String,
            default: 'default',
            validator: onlyValues(['default', 'green']),
        },
        value: {
            type: String,
            default: undefined,
        },
        thumbnailLocation: {
            type: String,
            default: 'left',
        },
        thumbnailSize: {
            type: Number,
            default: 60,
        },
        imageNamePrefix: {
            type: String,
            default: 'image',
        },
        errors: {
            type: Array,
            default: () => [],
        },
        showActionWithin: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            innerValue: this.value,
            showImageUploader: false,
            showImagePreview: false,
            showPreviewImageButton: false,
        };
    },
    computed: {
        componentStyleClass() {
            if (this.inputStyle === 'green') {
                return 'style-green';
            }

            return '';
        },
        thumbnailLocationClass() {
            return `thumbnail-${this.thumbnailLocation}`;
        },
        thumbnailSizeClass() {
            if (this.thumbnailLocation === 'left') {
                return { height: `${this.thumbnailSize}px` };
            }
            return null;
        },
        previewPadding() {
            let aspectRatio = 100;
            if (this.aspectRatio) {
                if (this.aspectRatio < 1) {
                    aspectRatio = 100 - this.aspectRatio * 100;
                    aspectRatio += 100;
                } else if (this.aspectRatio > 1) {
                    aspectRatio = this.aspectRatio * 100 - 100;
                } else {
                    aspectRatio = this.aspectRatio * 100;
                }
            }
            return `${aspectRatio}%`;
        },
    },
    watch: {
        value(newValue) {
            this.innerValue = newValue;
        },
    },
    methods: {
        onImageUploaded(response) {
            this.$emit('input', response.url);
            this.$emit('input-image', response);
            this.showImageUploader = false;
        },
        onDeleteImage(event) {
            this.innerValue = '';
            this.$emit('input', this.innerValue);
            this.$emit('input-image', undefined);
            event.stopPropagation();
        },
        onCopyUrlToClipboard(event) {
            // because text inside draggable is not selectable
            var tempInput = document.createElement('input');
            tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
            tempInput.value = this.innerValue;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);
        },
    },
};
</script>

<style lang="scss" scoped>
.image-upload-container {
    width: 100%;
}

.image-upload-preview {
    display: flex;
    align-items: center;
    justify-content: center;

    &.thumbnail-top {
        flex-direction: column;
    }

    &__thumbnail {
        background: linear-gradient(90deg, $color-primary-lightest 4px, transparent 1%) center,
            linear-gradient($color-primary-lightest 4px, transparent 1%) center,
            $alternate-text-color;
        background-size: 5px 5px;

        .image {
            height: 100%;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            border: 1px solid $color-primary;
            border-radius: 4px;

            &.has-error {
                border: 1px solid $color-error;

                svg {
                    color: $color-error;
                }
            }

            &__preview {
                overflow: hidden;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;

                .icon-wrapper {
                    @include center();
                    width: 60%;
                    height: 60%;
                    max-width: 35px;
                    max-height: 35px;
                    position: absolute;
                    background: $color-primary-lightest;
                    color: $color-primary;
                    padding: 1px;
                    border-radius: 60%;

                    svg {
                        position: absolute;
                        @include center();
                    }
                }
            }

            &__placeholder {
                background-color: $color-primary-lightest;
                svg {
                    position: absolute;
                    @include center();
                }
                &--action {
                    position: relative;
                    text-align: center;
                    top: 60%;
                }
            }

            svg {
                color: $color-primary;
            }
        }
    }

    &__info {
        flex-grow: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;

        &--center {
            align-items: center;
        }

        .action,
        .label,
        .error {
            padding: 0 5px;
        }

        .actions {
            .action {
                display: inline-block;
                text-transform: uppercase;
                font-size: $font-size-xs;
                color: $default-dark-color;
                font-weight: $font-weight-bold;
                letter-spacing: 1px;
                cursor: pointer;

                .separator {
                    margin: 0 0.5em;
                }
            }
            .error {
                color: $color-error;
                text-transform: capitalize;
                font-style: italic;
            }
        }
    }
}

.image-upload-button {
    position: relative;
    &.style-green {
        .title,
        .description {
            color: $green-style-input;
        }
    }

    .label {
        z-index: $layer-10;
        background: $input-bg-color;
    }

    .title {
        font-size: 16px;
        font-weight: $font-weight-light;
        margin-bottom: 7px;
    }

    .description {
        margin: 6px 0;
        font-size: 10px;
        font-weight: $font-weight-light;
        color: $alternate-text-color;
        line-height: 1.4em;
    }

    button {
        display: flex;
        align-items: center;
        height: 40px;
        width: 100%;
        padding: 5px 10px;
        border: 1px dashed $input-border-color;
        background: $button-secondary-color;
        cursor: pointer;

        .icon {
            fill: $button-main-color;
            flex: 0 0 auto;
        }

        .button-label {
            flex: 1 1 auto;
            display: block;
            overflow: hidden;
            padding-left: 5px;
            font-size: 14px;
            font-weight: $font-weight-light;
            color: $alternate-text-color;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .image-url-container {
        position: relative;

        .image-url {
            height: 40px;
            padding: 5px 25px 5px 10px;
            border: 1px dashed $input-border-color;
            background: $button-secondary-color;
            color: $alternate-text-color;
            font-weight: $font-weight-light;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .delete-icon {
            position: absolute;
            color: $green-style-input;
            cursor: pointer;
            font-size: 20px;
            right: 5px;
            top: 10px;
        }
    }
}
</style>
<style>
.channel-image-preview {
    height: 100%;
    overflow: hidden;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
</style>
