const DEFAULT_INTERVAL = 5000;
const DEFAULT_TIMEOUT = 60000;

export class Poller {
    interval: number;
    timeout: number;
    isDone: boolean = false;
    elapsedTime: number = 0;

    constructor(interval?: number, timeout?: number) {
        this.interval = interval || DEFAULT_INTERVAL;
        this.timeout = timeout || DEFAULT_TIMEOUT;
    }

    public poll(callbackFn: Function) {
        return new Promise((resolve) => {
            const doneFn = this.getDoneFn(resolve);
            this.tick(callbackFn, doneFn);
        });
    }

    private async tick(callbackFn: Function, doneFn: Function) {
        if (this.elapsedTime >= this.timeout) {
            doneFn();
        }

        await callbackFn(doneFn);

        if (this.isDone) {
            return;
        }

        this.elapsedTime += this.interval;

        setTimeout(this.tick.bind(this, callbackFn, doneFn), this.interval);
    }

    private getDoneFn(resolve: Function) {
        return () => {
            if (!this.isDone) {
                this.isDone = true;
                resolve();
            }
        };
    }
}
