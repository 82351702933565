import axios from '@/utils/request';
import { IPaging } from '@inconvo/types/interfaces';
import { GetChannelsRequest, Channel, ListChannel } from '@/store/models/dtos';
import { ChannelRequest, ChannelFacebookRequest } from '@/store/models/dtos/channelRequests.dto';
import { LoadingFlag } from '@/store/enums/loadingIds.enum';

export class ChannelClient {
    public static async getChannels(data: GetChannelsRequest): Promise<IPaging<ListChannel>> {
        let url = `/channels?page=${data.page}&size=${data.size}`;

        if (data.q) {
            url = `${url}&q=${data.q}`;
        }

        if (data.includeInactive) {
            url = `${url}&includeInactive=1`;
        }

        if (data.yougovWelcomeSurvey) {
            url = `${url}&yougovWelcomeSurvey=${data.yougovWelcomeSurvey}`;
        }

        const result = await axios
            .request<GetChannelsRequest>({
                url,
                method: 'GET',
                data,
                errorMessage: 'Could not load channels',
                loadingId: LoadingFlag.ChannelGet,
            })
            .then((o) => o);

        return result as unknown as Promise<IPaging<ListChannel>>;
    }

    public static async getChannel(code: string): Promise<Channel> {
        const url = `/channels/${code}`;
        const result = await axios.request<ChannelRequest>({
            url,
            method: 'GET',
            errorMessage: 'Could not load channel',
            loadingId: LoadingFlag.ChannelGetOne,
        });
        return result as unknown as Promise<Channel>;
    }

    public static async createChannel(data: ChannelRequest): Promise<Channel> {
        const url = '/channels?skipFacebookSetup=1';
        const result = await axios.request<ChannelRequest>({
            url,
            method: 'POST',
            data,
            errorMessage: 'Could not create channel',
            loadingId: LoadingFlag.ChannelSave,
        });
        return result as unknown as Promise<Channel>;
    }

    public static async updateChannel(channelCode: string, data: ChannelRequest): Promise<Channel> {
        const url = `/channels/${channelCode}?skipFacebookSetup=1`;
        const result = await axios.request<ChannelRequest>({
            url,
            method: 'PUT',
            data,
            errorMessage: 'Could not update channel',
            loadingId: LoadingFlag.ChannelSave,
        });
        return result as unknown as Promise<Channel>;
    }

    public static async setupFacebook(
        channelCode: string,
        data: ChannelFacebookRequest,
    ): Promise<void> {
        const url = `/channels/${channelCode}/setup-facebook`;
        const result = await axios.request<ChannelFacebookRequest>({
            url,
            method: 'POST',
            data,
            errorMessage: 'Could not setup channel on facebook',
            loadingId: LoadingFlag.ChannelSetupFacebook,
        });
        return result as unknown as Promise<void>;
    }

    public static async getChannelTenant(
        channelId: number,
    ): Promise<{ appCode: string; appKey: string; appSecret: string }> {
        const url = `/channels/${channelId}/tenant`;
        const result = await axios.request<ChannelRequest>({
            url,
            method: 'GET',
            errorMessage: 'Could not load Tenant',
        });
        return result as unknown as Promise<{ appCode: string; appKey: string; appSecret: string }>;
    }
}
