<template>
    <div>
        <EditorInput
            v-if="$options.richTextMessagesEnabled"
            :id="generateId('text', path)"
            class="m-b-2"
            label="Message"
            show-remaining-char
            :content="data.text"
            :errors="data.errors.plainText"
            :max="640"
            :menu-items="[
                $options.EditorInputMenuItem.Bold,
                $options.EditorInputMenuItem.Italic,
                $options.EditorInputMenuItem.Strike,
                $options.EditorInputMenuItem.Underline,
            ]"
            @update="onInput('text', $event)"
            @plainTextChange="onInput('plainText', $event)"
        />
        <Input
            v-else
            :id="generateId('text', path)"
            class="m-b-2"
            type="textarea"
            label="Message"
            name="text"
            :max="640"
            show-remaining-char
            :value="data.text"
            :errors="data.errors && data.errors.input"
            @input="
                (value) => {
                    onInput('text', value);
                    onInput('plainText', value);
                }
            "
            @changed="onChanged"
        />
        <EditorInput
            :id="generateId('commenting-on', path)"
            class="m-b-2"
            label="Commenting On Description"
            show-remaining-char
            :content="data.commentingOnText"
            :errors="data.errors.commentingOnText"
            :max="640"
            :menu-items="[
                $options.EditorInputMenuItem.Bold,
                $options.EditorInputMenuItem.Italic,
                $options.EditorInputMenuItem.Strike,
                $options.EditorInputMenuItem.Underline,
            ]"
            @update="onInput('commentingOnText', $event)"
        />
        <AccordionNew
            v-if="data.items"
            :items="data.items"
            :is-draggable="false"
            :path="[...path, 0]"
            @onDrop="(path, value) => $emit('onDrop', path, value)"
        >
            <CommentsRatingCard
                v-for="(item, index) in data.items"
                :key="index"
                :data="item"
                :logic-jump-data="contentItems"
                :text="targetText(item)"
                :path="[...path, index]"
                :index="index"
                :is-draggable="false"
                :is-deletable="data.isDeletable"
                :marker="getMarker(item.marker, item.markerType, 1, index)"
                :errors="item.errors"
                @propertyOnChange="$emit('propertyOnChange', $event)"
                @deleteOnClick="
                    (event, path, parentPath) => $emit('deleteOnClick', event, path, parentPath)
                "
                @openContentSelector="onOpenContentSelector"
                @closeContentSelectorx="showContentSelector = false"
            />
        </AccordionNew>
        <TextButton
            class="comments-rating-add-button"
            button-style="primary"
            size="small"
            @click="addTargetMessageButtonOnClick"
        >
            + Add target message
        </TextButton>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import itemFormMixin from '@/mixins/itemFormMixin';
import { EditorInputMenuItem, ConvoMessageItemType } from '@/store/enums';
import Input from '@/components/forms/Input';
import EditorInput from '@/components/forms/EditorInput';
import TextButton from '@/components/ui/TextButton';
import AccordionNew from '@/components/ui/Accordion/indexNew';
import CommentsRatingCard from '@/components/CommentsRating/CommentsRatingCard';
import { ContentItemType } from '@/store/enums/contentItemType.enum';
import { getMarker } from '@/utils/listMarkers.ts';
import config from '@/config';

export default {
    name: 'CommentsRating',
    components: {
        Input,
        EditorInput,
        TextButton,
        CommentsRatingCard,
        AccordionNew,
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        currentChannel: {
            type: Object,
            default: undefined,
        },
        currentConvo: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            ContentItemType,
            showContentSelector: false,
            selectedCardIndex: null,
        };
    },
    computed: {
        ...mapGetters('logicJump', {
            contentItems: 'items',
        }),
    },
    created() {
        this.$options.richTextMessagesEnabled = config.featureToggle.richTextMessagesEnabled;
        this.$options.EditorInputMenuItem = EditorInputMenuItem;
    },
    methods: {
        ...mapActions('logicJump', {
            clearLogicJump: 'clear',
            getLogicJumpData: 'getData',
        }),
        getMarker,
        targetText(item) {
            const text = [];
            if (item.logicJump.channel) {
                text.push(item.logicJump.channel.text);
            }
            if (item.logicJump.convo) {
                text.push(item.logicJump.convo.text);
            }
            if (item.logicJump.message) {
                text.push(item.logicJump.message.text);
            }
            return text.join(' • ');
        },
        async onOpenContentSelector(childItemPath) {
            this.$emit('openLogicJump', childItemPath);
        },
        async addTargetMessageButtonOnClick(event) {
            this.$emit(
                'addItemOnClick',
                event,
                this.path,
                ConvoMessageItemType.CommentsRatingTargetInput,
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.comments-rating-add-button {
    margin: 0 auto;
}
</style>
