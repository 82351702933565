<template>
    <div>
        <div class="align-right m-b-1">
            <TextButton icon="speech-bubble" @click="showConvoAttributes = !showConvoAttributes">
                <template v-if="showConvoAttributes">Hide</template> Convo Attributes
            </TextButton>
        </div>
        <Panel v-if="showConvoAttributes" title="Convo Attributes" class="m-b-4">
            <template>
                <div class="wrapper">
                    <div class="left">
                        <Input
                            id="convo-name"
                            class=""
                            type="text"
                            label="Convo Title"
                            name="convo-name"
                            :max="200"
                            show-remaining-char
                            placeholder="Enter convo title"
                            :value="data.name"
                            :errors="data.errors.name"
                            :warnings="data.warnings.name"
                            @input="(value) => onInput('name', value)"
                        />
                        <Input
                            id="convo-description"
                            class=""
                            type="text"
                            label="Description"
                            name="convo-description"
                            :max="200"
                            show-remaining-char
                            placeholder="Enter convo description"
                            :value="data.description"
                            :errors="data.errors.description"
                            :warnings="data.warnings.description"
                            @input="(value) => onInput('description', value)"
                        />

                        <div class="m-b-2" :class="{ 'wrapper-50-50': showEndLogic }">
                            <div v-if="showEndLogic" class="left">
                                <ConvoEndLogic
                                    :errors="data.errors.endLogic"
                                    :value="data.endLogic"
                                    @input="onInput('endLogic', $event)"
                                />
                            </div>
                            <FormFieldWrapper class="m-b-2" label="Convo Groups">
                                <RadioButtonGroup
                                    name="chat-group"
                                    is-horizontal
                                    :has-margin-bottom="false"
                                    :options="$options.chatGroupOptions"
                                    :value="data.chatGroup.toString()"
                                    :errors="data.errors.chatGroup"
                                    @input="(value) => onInput('chatGroup', +value)"
                                />
                                <Toggle
                                    v-if="showDailyChat"
                                    id="daily-chat"
                                    label="Daily Chat"
                                    :value="data.dailyChat"
                                    class="toggle-daily-chat m-b-1"
                                    @input="(value) => onInput('dailyChat', value)"
                                />
                            </FormFieldWrapper>
                        </div>

                        <TagsCheckbox
                            v-if="isAttributeEnabled('categories')"
                            class="m-b-2"
                            placeholder="Add category"
                            label="Categories"
                            :value="data.categories"
                            :options="convoCategoryOptions"
                            :errors="data.errors.categories"
                            @input="onInput('categories', $event)"
                        />

                        <FormFieldWrapper :errors="data.errors.topics" for-id="topic" class="m-b-2">
                            <AutoCompleteList
                                name="topic"
                                class="form-field-autocompletelist"
                                title="topic"
                                key-ref="externalId"
                                :label="`Topics: ${data.topics.length}/5`"
                                :errors="data.errors.topics"
                                :selected-items="data.topics"
                                :items="topicSearchResult"
                                :get-badge="getTopicBadge"
                                @query="$emit('onSearchTopic', $event)"
                                @update="onInput('topics', $event)"
                            />
                        </FormFieldWrapper>
                        <Tags
                            placeholder="Add tag"
                            label="Tags"
                            :value="tagsData.selected"
                            :autocomplete-items="tagsData.available"
                            :add-from-paste="true"
                            :separators="[' ', ';']"
                            :disabled="false"
                            :max-tags="tagsData.max"
                            @autocomplete="$emit('autocomplete', $event)"
                            @input="$emit('tag-input', $event)"
                        />

                        <Tags
                            v-if="showGroup"
                            placeholder="Add group"
                            label="Group"
                            :value="tagsData.selectedGroup"
                            :autocomplete-items="tagsData.availableGroup"
                            :disabled="false"
                            :max-tags="1"
                            @autocomplete="$emit('autocomplete', $event)"
                            @input="$emit('group-input', $event)"
                        />

                        <div class="wrapper-75-25">
                            <Tags
                                placeholder="Add country"
                                :label="countrySelectorLabel"
                                :value="selectedCountries"
                                :autocomplete-items="$options.countries"
                                :add-from-paste="true"
                                :separators="[' ', ';']"
                                :disabled="data.isGlobal"
                                :errors="data.errors.countries"
                                :max-tags="attributes.countries && attributes.countries.max"
                                class="country-tag"
                                @input="onCountryInput"
                            />
                            <Checkbox
                                id="isGlobal"
                                label="Global Convo"
                                class="m-t-1"
                                :value="data.isGlobal"
                                :disabled="disableGlobalConvo"
                                @input="onIsGlobalInput"
                            />
                        </div>
                    </div>
                    <div class="right">
                        <ImageUploaderButton
                            show-preview
                            input-style="green"
                            button-label="Add image"
                            thumbnail-location="top"
                            :aspect-ratio="1.91"
                            :thumbnail-size="100"
                            :value="data.image"
                            :errors="data.errors.image"
                            @input="(value) => onInput('image', value)"
                        />
                    </div>
                </div>
            </template>
        </Panel>
    </div>
</template>

<script>
import Panel from '@/components/ui/Panel';
import ConvoEndLogic from '@/components/ui/ConvoEndLogic';
import {
    Input,
    Tags,
    RadioButtonGroup,
    Checkbox,
    Toggle,
    FormFieldWrapper,
    AutoCompleteList,
    TagsCheckbox,
} from '@/components/forms';
import ImageUploaderButton from '@/components/forms/ImageUploaderButton';
import TextButton from '@/components/ui/TextButton';
import { ChatGroup, ConvoTypes, BadgeType } from '@/store/enums';
import countryList from '@/json/countries.ts';
import config from '@/config';

export default {
    name: 'ConvoAttributes',
    components: {
        ImageUploaderButton,
        ConvoEndLogic,
        TextButton,
        Panel,
        Input,
        Tags,
        TagsCheckbox,
        RadioButtonGroup,
        Checkbox,
        Toggle,
        AutoCompleteList,
        FormFieldWrapper,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        tagsData: {
            type: Object,
            default: () => ({ selected: [], available: [], max: 1 }),
        },
        convoCategoryOptions: {
            type: Array,
            default: () => [],
        },
        topicSearchResult: {
            type: Array,
            default: () => [],
        },
        showGroup: {
            type: Boolean,
            default: false,
        },
        showEndLogic: {
            type: Boolean,
            default: false,
        },
        disableGlobalConvo: {
            type: Boolean,
            default: false,
        },
        attributes: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            showConvoAttributes: true,
        };
    },
    computed: {
        selectedCountries() {
            const selectedCountryCodes = this.data.countries || [];
            return selectedCountryCodes?.map((selectedCountry) =>
                this.$options.countries.find((country) => country.id === selectedCountry),
            );
        },
        showDailyChat() {
            return config.featureToggle.showDailyChat;
        },
        countrySelectorLabel() {
            return this.data.type === ConvoTypes.YougovWelcomeChat ? 'Country' : 'Countries';
        },
    },
    created() {
        this.$options.chatGroupOptions = [
            {
                value: ChatGroup.Editorial.toString(),
                name: 'Editorial',
            },
            { value: ChatGroup.Global.toString(), name: 'Global Chat' },
        ];
        this.ChatGroup = ChatGroup;
        this.$options.countries = countryList.reduce((prevObj, currentObj) => {
            if (currentObj.yougovCountry) {
                return [
                    ...prevObj,
                    { id: currentObj.iso2, name: `${currentObj.name} ${currentObj.flag}` },
                ];
            }
            return [...prevObj];
        }, []);
    },
    methods: {
        getTopicBadge(topic) {
            if (topic?.id) {
                return null;
            }
            return { label: 'new', type: BadgeType.Neutral };
        },
        onInput(key, value) {
            this.$emit('onChange', { key, value });
        },
        onCountryInput(payload) {
            const countryCodes = payload.map((country) => country.id).filter((code) => code);
            this.onInput('countries', countryCodes);
        },
        onIsGlobalInput(value) {
            this.clearCountrySelection();
            this.onInput('isGlobal', value);
        },
        clearCountrySelection() {
            this.onInput('countries', []);
        },
        isAttributeEnabled(attribute) {
            return !this.attributes[attribute]?.disabled;
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    display: grid;
    grid-template-columns: 75% 25%;
    grid-gap: 20px;
}

.wrapper-50-50 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 20px;
}
.wrapper-75-25 {
    display: grid;
    grid-template-columns: 1.5fr 0.5fr;
    grid-template-rows: 1fr;
    gap: 0px 20px;
}
.convo-group {
    padding-bottom: 10px;
}
.toggle-daily-chat {
    padding-left: 7px;
    padding-top: 5px;
}
.form-field-autocompletelist {
    margin: -1px;
}
.form-field-content {
    position: relative;
    z-index: 10;
    isolation: isolate;
    .form-field-label {
        z-index: 12;
    }
}
</style>
