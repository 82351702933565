<template>
    <img :src="imageUrl" class="avatar" alt="avatar" />
</template>

<script>
export default {
    name: 'Avatar',
    props: {
        imageUrl: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.avatar {
    $avatar-size: 15px;

    width: $avatar-size;
    height: $avatar-size;
    border-radius: $avatar-size;
    border: 1px solid $inconvo-messenger-avatar-border-color;
}
</style>
