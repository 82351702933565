const truncateStringWithEllipsis = (text: string, size: number) => {
    if (text.length <= size) {
        return text;
    }

    const separator = '...';

    const sepLen = separator.length;
    const charsToShow = size - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    const finalText = text.substr(0, frontChars) + separator + text.substr(text.length - backChars);

    return finalText;
};

export default truncateStringWithEllipsis;
