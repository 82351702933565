import { DateRangeType, DateType } from '@/store/models/interfaces/validationRules.interface';

export const dateTypes = [
    {
        id: DateType.Any,
        value: 'Any Date',
    },
    {
        id: DateType.Past,
        value: 'Past Date',
    },
    {
        id: DateType.Future,
        value: 'Future Date',
    },
    {
        id: DateType.PastRange,
        value: 'Past Date Range',
    },
    {
        id: DateType.FutureRange,
        value: 'Future Date Range',
    },
];

export const dateRanges = [
    {
        id: DateRangeType.Days,
        value: 'Days',
    },
    {
        id: DateRangeType.Months,
        value: 'Months',
    },
    {
        id: DateRangeType.Years,
        value: 'Years',
    },
];
