export enum SystemNotificationStatus {
    Success = 'success',
    Error = 'error',
    InProgress = 'in_progress',
    Created = 'created',
}

export enum SystemNotificationType {
    EmailCampaign = 'emailCampaign',
}
