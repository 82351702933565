import { LoadingFlag } from '@/store/enums';
import axios from '@/utils/request';
import { IContentDiscoveryMenuDto } from '@/store/models/dtos';

export class ContentDiscoveryMenuClient {
    public static async createMenu(
        data: IContentDiscoveryMenuDto,
    ): Promise<IContentDiscoveryMenuDto> {
        const result = await axios.request<any>({
            url: `/content-discovery-menus`,
            method: 'POST',
            data,
            errorMessage: 'Could not create menu',
            loadingId: LoadingFlag.ContentDiscoveryMenuCreate,
        });
        return result as unknown as Promise<IContentDiscoveryMenuDto>;
    }

    public static async updateMenu(
        id: number,
        data: IContentDiscoveryMenuDto,
    ): Promise<IContentDiscoveryMenuDto> {
        const result = await axios.request<any>({
            url: `/content-discovery-menus/${id}`,
            method: 'PUT',
            data,
            errorMessage: 'Could not update menu',
            loadingId: LoadingFlag.ContentDiscoveryMenuUpdate,
        });
        return result as unknown as Promise<IContentDiscoveryMenuDto>;
    }

    public static async getMenu(id: number): Promise<IContentDiscoveryMenuDto> {
        const result = await axios.request<any>({
            url: `/content-discovery-menus/${id}`,
            method: 'GET',
            errorMessage: 'Could not load menu',
            loadingId: LoadingFlag.ContentDiscoveryMenuGetOne,
        });
        return result as unknown as Promise<IContentDiscoveryMenuDto>;
    }

    public static async deleteMenu(data: { menuId: number; channelId: number }): Promise<void> {
        await axios.request<any>({
            url: `/content-discovery-menus/${data.menuId}`,
            method: 'DELETE',
            data: {
                channel_id: data.channelId,
                menu_id: data.menuId,
            },
            errorMessage: 'Could not delete menu',
            loadingId: LoadingFlag.ContentDiscoveryMenuDelete,
        });
    }

    public static async setDefaultMenu(data: { menuId: number; channelId: number }): Promise<void> {
        await axios.request<any>({
            url: `/content-discovery-menus/${data.menuId}/set-default`,
            method: 'PUT',
            data: {
                channel_id: data.channelId,
                menu_id: data.menuId,
            },
            errorMessage: 'Could set default menu',
            loadingId: LoadingFlag.ContentDiscoveryMenuDelete,
        });
    }

    public static async getMenuList(
        channelId?: number,
    ): Promise<Partial<IContentDiscoveryMenuDto>[]> {
        const result = await axios.request<any>({
            url: `/content-discovery-menus`,
            params: {
                channel_id: channelId,
            },
            method: 'GET',
            errorMessage: 'Could not load menus',
            loadingId: LoadingFlag.ContentDiscoveryMenuGet,
        });
        return result as unknown as Promise<Partial<IContentDiscoveryMenuDto>[]>;
    }
}
