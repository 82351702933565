import { IButtonAnswerTypeOption } from '@/json/buttonsMessageTypeAnswerOptions';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum';
import { getErrorMessage } from '@/store/services/validationService';
import { IsNotEmpty, Length, validate as validateClass, ValidationError } from 'class-validator';
import hash from 'object-hash';
import { ButtonsAnswer } from '.';
import { AnswerType, ButtonViewLayout } from '../enums';
import { IConvoState } from '../modules/convo-details';
import { AccordionItemBase } from './accordionItemBase';
import { ButtonsAnswerType } from './buttonsAnswerType';
import { YougovWelcomeSurveyButtonsAccordionItemFactory } from './factories/ygWelcomeSurveyAccordionItem.factory';
import { YougovWelcomeSurveyButtonsContentItemFactory } from './factories/ygWelcomeSurveyContentItem.factory';
import {
    IContentItem,
    IContentItemConvertible,
    IObservableItem,
    IRichText,
    ITag,
} from './interfaces';
import { ItemsGroup } from './itemsGroup';

export class YougovWelcomeSurveyButtons
    extends AccordionItemBase
    implements IContentItemConvertible, IObservableItem<IConvoState>, IRichText
{
    constructor(items = []) {
        super(items);

        this.type = ConvoMessageItemType.YougovWelcomeSurveyButtons;
        this.plainText = '';
        this.statement = '';
        this.allowMultipleChoice = false;
        this.isMultipleChoice = false;
        this.submitButtonText = '';
        this.layout = ButtonViewLayout.vertical;
        this.minLimit = 0;
        this.maxLimit = 0;
        this.isDraggable = true;
        this.isDeletable = false;
        this.isOpen = true;
        this.icon = 'welcome';
        this.maxItems = 30;
        this.tags = [];

        const buttonsAnswers = new ItemsGroup();

        this.items = [buttonsAnswers];

        const buttonAnswerSurvey = new ButtonsAnswer();
        buttonAnswerSurvey.answerType = new ButtonsAnswerType({
            name: AnswerType.YougovWelcomeSurvey,
        } as IButtonAnswerTypeOption);
        buttonAnswerSurvey.isDeletable = false;
        buttonAnswerSurvey.isDraggable = false;
        buttonAnswerSurvey.canChangeAnswerType = false;
        buttonAnswerSurvey.canHaveAttributes = false;

        buttonsAnswers.items = [buttonAnswerSurvey];
    }

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Message') })
    @Length(0, 640, { message: getErrorMessage('length', 'Message', { min: 0, max: 640 }) })
    plainText: string;
    statement: string;
    submitButtonText: string;
    maxItems: number;
    allowMultipleChoice: boolean;
    isMultipleChoice: boolean;
    layout: string;
    minLimit: number;
    maxLimit: number;
    tags?: ITag[];

    setTitle(): void {
        this.subtitle = this.plainText;
    }

    addItem(item: AccordionItemBase): void {
        this.items[0].items.push(item);
    }

    toContentItem(state: IConvoState): IContentItem {
        const contentItemFactory = new YougovWelcomeSurveyButtonsContentItemFactory();
        return contentItemFactory.make(this, state);
    }

    itemHasChanges(state: IConvoState): boolean {
        const contentItemFactory = new YougovWelcomeSurveyButtonsContentItemFactory();
        const contentItem = contentItemFactory.make(this, state);

        contentItem.version = hash(contentItem);

        if (this.originalVersion) {
            return contentItem.version !== this.originalVersion;
        }
        return false;
    }

    toAccordionItem(contentItem: IContentItem, isClone?: boolean): AccordionItemBase {
        const accordionItemFactory = new YougovWelcomeSurveyButtonsAccordionItemFactory();
        return accordionItemFactory.make(contentItem, isClone);
    }

    async validate(state: IConvoState, path: number[]): Promise<any[]> {
        const errors: ValidationError[] = await validateClass(this);
        const errorMessages: any[] = [];
        const mainLevelErrors: any = {};

        if (errors.length > 0) {
            errors.forEach((item: ValidationError) => {
                mainLevelErrors[item.property] = Object.values(item.constraints || {});
            });
        }

        if (this.items[0].items.length === 0 || this.items[0].items.length > this.maxItems) {
            mainLevelErrors.buttonsAnswers = [];
            mainLevelErrors.buttonsAnswers.push(
                getErrorMessage('buttonsLength', 'Buttons', { min: 1, max: this.maxItems }),
            );
        }

        if (Object.entries(mainLevelErrors).length > 0) {
            errorMessages.push({ path, errors: mainLevelErrors });
        }

        for (let i = 0; i < this.items[0].items.length; i++) {
            const answer = this.items[0].items[i] as ButtonsAnswer;
            const chilrenErrors: any[] = await answer.validate(state, [...path, 0, i]);

            if (Object.entries(chilrenErrors).length > 0) {
                errorMessages.push(...chilrenErrors);
            }
        }

        return errorMessages;
    }
}
