import convoTypesConfig from '@/json/convoTypes';
import { router } from '@/router';
import { IPaging } from '@inconvo/types/interfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ConvoTypes } from '../enums';
import { IConvo, ITopic } from '../models/interfaces';
import {
    SET_CONVO_LIST,
    SET_WELCOME_FLOWS,
    SET_CONVO_TOPICS,
    SET_CONVO_TOPIC_CHANGED,
    RESET_CONVO_TOPIC_CHANGED,
} from '../mutations.types';
import { getConvos } from '../services/convoService';
import { IRootState } from '../types/rootState';

export interface IUpdatedTopics {
    [key: string]: {
        name: string;
        removedTopics: ITopic[];
        addedTopics: ITopic[];
        initialTopics: ITopic[];
    };
}

export interface IConvoListState {
    convoList: IPaging<IConvo>;
    updatedTopics: IUpdatedTopics;
    welcomeFlows: IConvo[];
}

const getters: GetterTree<IConvoListState, IRootState> = {
    convoListView: () => state,
    updatedTopics: () =>
        Object.values(state.updatedTopics)
            .filter((convo) => convo.addedTopics.length || convo.removedTopics.length)
            .sort((a, b) => a.name.localeCompare(b.name)) || [],
    updatedConvosId: () => Object.keys(state.updatedTopics).map((convoId) => Number(convoId)),
};

const actions: ActionTree<IConvoListState, any> = {
    getConvos: async (
        { commit, dispatch },
        {
            page,
            channelCode,
            q,
            convoTypes,
            status,
            channelId,
            chatGroup,
            redirectName = 'messages',
            size = 15,
            setRedirectUrl = true,
            excludeTopics,
            includeTopics,
        },
    ) => {
        let result: IPaging<IConvo> = await getConvos({
            query: q,
            channelCode: channelCode || router.currentRoute.params.channelCode,
            channelId,
            page,
            pageSize: size,
            convoTypes,
            status,
            chatGroup,
            excludeTopics,
            includeTopics,
        });

        if (result) {
            if (setRedirectUrl) {
                for (const convo of result.items) {
                    const route = router.resolve({
                        name: redirectName,
                        params: { channelId: `${convo.channelId}`, convoId: `${convo.id}` },
                    });
                    convo.redirectUrl = (route && route.href) || '';
                    const convoType = convoTypesConfig.find((t) => t.name === convo.type);
                    if (convoType) {
                        convo.type = convoType.title;
                    }
                }
            }
        } else {
            result = {
                page: 0,
                size: 0,
                total: 0,
                items: [],
            };
        }

        commit(SET_CONVO_LIST, result);
    },
    getWelcomeFlows: async (
        { commit },
        {
            page,
            channelCode,
            q,
            convoTypes = [ConvoTypes.YougovWelcomeSurvey, ConvoTypes.YougovWelcomeChat],
            status,
            chatGroup,
            size = 15,
        },
    ) => {
        const result: IPaging<IConvo> = await getConvos({
            query: q,
            channelCode,
            channelId: -1,
            page,
            pageSize: size,
            convoTypes,
            status,
            chatGroup,
        });

        commit(SET_WELCOME_FLOWS, result);
    },
    clearWelcomeFlows: ({ commit }) => {
        commit(SET_WELCOME_FLOWS, { items: [] });
    },
    updateConvoTopics: ({ commit }, { convo, topics = [] }) => {
        const initialTopics = [...convo.topics];
        const finalTopics = [...topics];
        const convoAddedTopics = finalTopics.filter(
            ({ externalId }) => !initialTopics.find((topic) => topic.externalId === externalId),
        );
        const convoRemovedTopics = initialTopics.filter(
            ({ externalId }) => !finalTopics.find((topic) => topic.externalId === externalId),
        );
        commit(SET_CONVO_TOPICS, { convoId: convo.id, topics });

        commit(SET_CONVO_TOPIC_CHANGED, {
            convo,
            addedTopics: convoAddedTopics,
            removedTopics: convoRemovedTopics,
        });
    },
    convoBulkActionTopics({ commit, state }, { convoId, addedTopics = [], removedTopics = [] }) {
        const convo = state.convoList.items.find((convo) => convo.id === Number(convoId));
        const newTopics = addedTopics
            .map((topic: ITopic) => ({ ...topic, remove: false }))
            .concat(removedTopics.map((topic: ITopic) => ({ ...topic, remove: true })));
        if (convo) {
            const topics = [...convo.topics];
            const convoRemovedTopics = [] as ITopic[];
            const convoAddedTopics = [] as ITopic[];
            newTopics.forEach(
                (topic: {
                    id?: number;
                    name: string;
                    type: string;
                    externalId: string;
                    remove: boolean;
                }) => {
                    const { remove, ...convoAttr } = topic;
                    const topicIndex = convo.topics.findIndex(
                        (existingTopic) => existingTopic.externalId === topic.externalId,
                    );
                    if (topicIndex !== -1 && remove === true) {
                        convoRemovedTopics.push(topics[topicIndex]);
                        topics.splice(topicIndex, 1);
                    }
                    if (topicIndex === -1 && remove === false) {
                        convoAddedTopics.push(convoAttr);
                        topics.push(convoAttr);
                    }
                },
            );
            commit(SET_CONVO_TOPIC_CHANGED, {
                convo,
                removedTopics: convoRemovedTopics,
                addedTopics: convoAddedTopics,
            });

            commit(SET_CONVO_TOPICS, { convoId, topics });
        }
    },
    resetUpdateConvosTopics({ commit }) {
        commit(RESET_CONVO_TOPIC_CHANGED);
    },
};

const mutations: MutationTree<IConvoListState> = {
    [SET_CONVO_LIST](state: IConvoListState, convos: IPaging<IConvo>): void {
        state.convoList = convos;
    },

    [SET_WELCOME_FLOWS](state: IConvoListState, welcomeSurveys: IPaging<IConvo>): void {
        state.welcomeFlows = welcomeSurveys.items;
    },
    [SET_CONVO_TOPICS](
        state: IConvoListState,
        { convoId, topics }: { convoId: number; topics: ITopic[] },
    ): void {
        const index = state.convoList.items.findIndex((convo) => {
            return convo.id === convoId;
        });
        if (index !== -1) {
            const items = [...state.convoList.items];
            items[index] = {
                ...items[index],
                topics,
            };
            state.convoList = { ...state.convoList, items };
        }
    },

    [SET_CONVO_TOPIC_CHANGED](
        state: IConvoListState,
        {
            convo,
            removedTopics = [],
            addedTopics = [],
        }: { convo: IConvo; removedTopics: ITopic[]; addedTopics: ITopic[] },
    ): void {
        if (!state.updatedTopics[convo.id]) {
            state.updatedTopics = {
                ...state.updatedTopics,
                [convo.id]: {
                    name: convo.name,
                    removedTopics: [],
                    addedTopics: [],
                    initialTopics: convo.topics,
                },
            };
        }
        const initialTopics = state.updatedTopics[convo.id].initialTopics;

        const addedConvoTopics = addedTopics.filter(
            ({ externalId }) => !convo.topics.find((topic) => topic.externalId === externalId),
        );
        const currentConvoTopics = convo.topics.filter(
            ({ externalId }) => !removedTopics.find((topic) => topic.externalId === externalId),
        );
        const currentTopics = [...currentConvoTopics, ...addedConvoTopics];

        const newRemovedTopics = initialTopics.filter(
            ({ externalId }) => !currentTopics.find((topic) => topic.externalId === externalId),
        );
        const newAddedTopics = currentTopics.filter(
            ({ externalId }) => !initialTopics.find((topic) => topic.externalId === externalId),
        );

        state.updatedTopics = {
            ...state.updatedTopics,
            [convo.id]: {
                ...state.updatedTopics[convo.id],
                removedTopics: newRemovedTopics,
                addedTopics: newAddedTopics,
            },
        };
    },
    [RESET_CONVO_TOPIC_CHANGED](state: IConvoListState): void {
        state.updatedTopics = {};
    },
};

const state: IConvoListState = {
    convoList: {} as IPaging<IConvo>,
    updatedTopics: {} as IUpdatedTopics,
    welcomeFlows: [],
};
export const convoList: Module<IConvoListState, IRootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
