import { ISearchResponse, IContentOption } from '@/store/models/interfaces';
import { TenantClient } from '@/api/tenant';
import { ContentType } from '@/store/enums';

interface ILogicJumpData {
    channel?: IContentOption;
    convo?: IContentOption;
    message?: IContentOption;
}

export const getLogicJumpData = async ({
    targetChannel,
    targetConvo,
    targetContentItem,
}: {
    targetChannel: string;
    targetConvo: number;
    targetContentItem: number;
}) => {
    const logicJumpData: ISearchResponse = await TenantClient.getLogicJumpData({
        targetChannel,
        targetConvo,
        targetContentItem,
    });

    const result: ILogicJumpData = {};

    if (logicJumpData.channelCode) {
        result.channel = {
            type: ContentType.Channel,
            code: logicJumpData.channelCode,
            text: logicJumpData.channelName,
        };
    }

    if (logicJumpData.convoId) {
        result.convo = {
            type: logicJumpData.type === ContentType.Menu ? ContentType.Menu : ContentType.Convo,
            id: logicJumpData.convoId,
            text: logicJumpData.convoName || '',
        };
    }

    if (logicJumpData.contentItemId) {
        result.message = {
            type: ContentType.Message,
            id: logicJumpData.contentItemId,
            text: logicJumpData.contentItemName || '',
        };
    }

    return result;
};
