import { RuleDto } from '@/store/models/dtos/audience.dto';
import { Rule } from '@/store/models/audience/rule';
import countryList from '@/json/countries.ts';

export class LocationRule extends Rule {
    constructor(data: RuleDto) {
        super(data);
    }

    getContent(): Promise<any> {
        return Promise.resolve(countryList);
    }

    getSummaryTitle(): string {
        const country = countryList.find((country) => country.iso2 === this.value);
        return country ? country.name : '';
    }
}
