export interface IQuickReplyAnswer {
    label: string;
    imageUrl?: string;
}

export class QuickReplyAnswer implements IQuickReplyAnswer {
    label: string;
    imageUrl?: string;

    constructor(label: string, imageUrl?: string) {
        this.label = label;
        this.imageUrl = imageUrl;
    }
}
