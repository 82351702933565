<template>
    <FormFieldWrapper
        :label="label"
        :errors="errors"
        :is-focused="isFocused"
        :is-disabled="disabled"
        :info-text="infoText"
        @blur="onBlur"
    >
        <ol class="tags-group tags-group--selected" @click="onFocus">
            <li
                v-for="item in selectedOptions"
                :key="item[idKey]"
                class="tag tag-selected"
                :class="{
                    'tag-highlighted': isHighlighted(item),
                }"
            >
                <Icon v-if="isHighlighted(item)" name="pin" :size="14" />
                {{ item[nameKey] }}
                <button class="tag-icon" @click.stop="onRemove(item[idKey])">
                    <Icon name="cross-simple" :size="8" />
                </button>
            </li>
            <li v-if="!(value && value.length)" class="tag tag-placeholder">
                {{ placeholder }}
            </li>
        </ol>
        <div v-if="showDropdown" class="dropdown">
            <div
                v-for="{ id, name, children } in optionsByCategory"
                :key="id"
                class="tags-category"
            >
                <div v-if="name" class="tags-category-name">
                    {{ name }}
                </div>
                <ul class="tags-group">
                    <li
                        v-for="item in children"
                        :key="item[idKey]"
                        class="tag-option"
                        :class="{
                            'tag-highlighted': isHighlighted(item),
                        }"
                    >
                        <Checkbox
                            :id="item[idKey]"
                            :value="isSelected(item)"
                            :label="item[nameKey]"
                            :has-margin-bottom="false"
                            @input="onChange(item, $event)"
                        />
                        <button
                            class="tag-icon"
                            :title="highlightTitle"
                            @click="setHighlight(item)"
                        >
                            <Icon name="pin" />
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </FormFieldWrapper>
</template>

<script>
import Icon from '@/components/ui/Icon';
import debounce from 'lodash/debounce';
import Checkbox from './Checkbox.vue';
import FormFieldWrapper from './FormFieldWrapper.vue';

export default {
    name: 'TagsCheckbox',
    components: {
        Checkbox,
        FormFieldWrapper,
        Icon,
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: 'Add tag',
        },
        label: {
            type: String,
            default: 'Tags',
        },
        options: {
            type: Array,
            default: function () {
                return [];
            },
        },
        errors: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        infoText: {
            type: String,
            default: undefined,
        },
        maxHighlighted: {
            type: Number,
            default: 1,
        },
        nameKey: {
            type: String,
            default: 'name',
        },
        highlightKey: {
            type: String,
            default: 'isPrimary',
        },
        idKey: {
            type: String,
            default: 'slug',
        },
        categoryKey: {
            type: String,
            default: 'group',
        },
        highlightTitle: {
            type: String,
            default: 'Click to select as primary',
        },
    },
    data() {
        return {
            newTag: '',
            isFocused: false,
            showDropdown: false,
        };
    },
    computed: {
        selectedOptions() {
            return this.options
                .filter((item) => this.isSelected(item))
                .sort((item) => (this.isHighlighted(item) ? -1 : 1));
        },
        optionsByCategory() {
            const categories = [];
            this.options.forEach((option) => {
                const categoryId = option[this.categoryKey];
                const currentCategory = categories.find((cat) => cat.id === categoryId);
                if (currentCategory) {
                    currentCategory.children.push(option);
                    return;
                }
                const newCategory = {
                    id: categoryId,
                    name: categoryId,

                    children: [option],
                };
                if (categoryId) {
                    categories.push(newCategory);
                } else {
                    categories.unshift(newCategory);
                }
            });
            return categories;
        },
    },
    methods: {
        setHighlight(item) {
            let valueCopy = this.toggleInSelectedItems(this.value, item, true);

            if (!this.isHighlighted(item)) {
                const highlightedItems = valueCopy.filter((o) => o[this.highlightKey]);

                if (highlightedItems.length >= this.maxHighlighted) {
                    const index = valueCopy.findIndex(
                        (o) => o[this.idKey] === highlightedItems[0][this.idKey],
                    );
                    valueCopy[index] = { ...valueCopy[index], [this.highlightKey]: false };
                }
            }

            valueCopy = valueCopy.map((o) => ({
                ...o,
                [this.highlightKey]:
                    o[this.idKey] === item[this.idKey]
                        ? !o[this.highlightKey]
                        : o[this.highlightKey],
            }));

            this.$emit('input', valueCopy);
        },
        onRemove(id) {
            const valueCopy = this.value.slice();
            const index = valueCopy.findIndex((o) => o[this.idKey] === id);
            valueCopy.splice(index, 1);
            this.$emit('input', valueCopy);
        },
        onBlur() {
            this.isFocused = false;
            this.showDropdown = false;
        },
        onFocus() {
            if (this.disabled) {
                return;
            }
            this.isFocused = true;
            this.showDropdown = !this.showDropdown;
        },
        onChange(item, isSelected) {
            if (!this.value.length) {
                this.setHighlight(item);
            } else {
                this.$emit('input', this.toggleInSelectedItems(this.value, item, isSelected));
            }
        },
        toggleInSelectedItems(selectedItems, item, isSelected) {
            const selectedItemsCopy = selectedItems.slice();
            const index = selectedItemsCopy.findIndex((o) => o[this.idKey] === item[this.idKey]);

            if (index === -1) {
                selectedItemsCopy.push(item);
            } else if (!isSelected) {
                selectedItemsCopy.splice(index, 1);
            }

            return selectedItemsCopy;
        },
        isSelected(item) {
            return this.value.some((o) => o[this.idKey] === item[this.idKey]);
        },
        isHighlighted(item) {
            return this.value.some(
                (o) => o[this.idKey] === item[this.idKey] && o[this.highlightKey],
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.tags-wrapper {
    position: relative;
}
.tags-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 0.5rem;
    width: 100%;
    padding: 0.8rem 0.6rem 0.5rem;
    &--selected {
        display: flex;
        flex-wrap: wrap;
    }
}

.tag-selected {
    --color: #{$primary-color};
    --background-color: #edf8fa;
    border: solid 0.1rem currentColor;
    background-color: var(--background-color);
    white-space: nowrap;
    color: var(--color);
    border-radius: 0.5rem;
    font-size: 1.2rem;
    display: flex;
}
.tag-option {
    --color: #{$color-cadet-blue};
    color: var(--color);
    display: flex;
    max-width: 300px;
    .tag-icon {
        opacity: 0;
        pointer-events: none;
    }
    &:hover .tag-icon,
    &.tag-highlighted .tag-icon {
        opacity: 1;
        pointer-events: initial;
        cursor: pointer;
    }
}
.tag-highlighted {
    --color: #{$primary-active-green-color};
    font-weight: bold;
}
.tag-input {
    flex-grow: 1;
    width: 1rem;
}
.tag-placeholder {
    --color: #{$color-cadet-blue};
    color: var(--color);
    font-size: 1.4rem;
    font-weight: 300;
}

.tag-icon {
    padding: 0.125rem 0.5rem;
    transition: 0.5s ease-in;
}
.tag {
    padding: 0.2rem 0.4rem 0.25rem;
}
.tags-category-name {
    text-transform: capitalize;
    padding: 0 0.4rem;
    --color: #{$color-cadet-blue};
    color: var(--color);
}
.tags-category {
    padding: 0.75rem 0.5rem 0.75rem;
    &:first-of-type {
        padding-top: 0;
    }
}
</style>
