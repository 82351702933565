import { Attachment } from '@/../messages/src/models/attachment';
import { Buttons as ButtonMessage } from '@/../messages/src/models/buttons';
import { Rating as RatingMessage } from '@/../messages/src/models/rating';
import { Carousel as CarouselMessage } from '@/../messages/src/models/carousel';
import { CommentsRating as CommentsRatingMessage } from '@/../messages/src/models/commentsRating';
import { Message } from '@/../messages/src/models/message';
import { QuickReply as QuickReplyMessage } from '@/../messages/src/models/quickReply';
import { Reply } from '@/../messages/src/models/reply';
import { SimpleText } from '@/../messages/src/models/simpleText';
import { Video as VideoMessage } from '@/../messages/src/models/video';
import { ButtonViewLayout, ConvoMessageItemType } from '@/store/enums';
import { replaceTemplateVariablesForPreview } from '@/store/services/convoMessageItemService';
import {
    AccordionItemBase,
    Buttons,
    ButtonsAnswer,
    Carousel,
    CarouselTile,
    CommentsRating,
    ConvoPreviewMessageGroup,
    DatePicker,
    QuickReply,
    QuickReplyAnswer,
    Registration,
    TextGlobalResponse,
    TextInput,
    Video,
    Gif,
    Rating,
    RatingOption,
} from '..';
import { AttachmentUrl } from '../attachmentUrl';
import { TextStatement } from '../textStatement';

export class ConvoPreviewMessagesFactory {
    public make(accordionItems: AccordionItemBase[], avatarUrl: string) {
        const messages: any = [];

        for (const item of accordionItems) {
            let groupMessages: Message[] = [];

            switch (item.type) {
                case ConvoMessageItemType.TextStatement:
                    groupMessages = this.getStatementMessages(item as TextStatement, avatarUrl);
                    break;
                case ConvoMessageItemType.AttachmentUrl:
                    groupMessages = this.getAttachmentUrlMessages(item as AttachmentUrl, avatarUrl);
                    break;
                case ConvoMessageItemType.TextInput:
                    groupMessages = this.getTextInputMessages(item as TextInput, avatarUrl);
                    break;
                case ConvoMessageItemType.QuickReply:
                    groupMessages = this.getQuickReplyMessages(item as QuickReply, avatarUrl);
                    break;
                case ConvoMessageItemType.Rating:
                    groupMessages = this.getRatingMessages(item as Rating, avatarUrl);
                    break;
                case ConvoMessageItemType.Buttons:
                case ConvoMessageItemType.YougovWelcomeSurveyButtons:
                    groupMessages = this.getButtonMessages(item as Buttons, avatarUrl);
                    break;
                case ConvoMessageItemType.Carousel:
                    groupMessages = this.getCarouselMessages(item as Carousel, avatarUrl);
                    break;
                case ConvoMessageItemType.Registration:
                    groupMessages = this.getRegistrationMessages(item as Registration, avatarUrl);
                    break;
                case ConvoMessageItemType.DatePicker:
                    groupMessages = this.getDatePickerMessages(item as DatePicker, avatarUrl);
                    break;
                case ConvoMessageItemType.Video:
                    groupMessages = this.getVideoMessages(item as Video, avatarUrl);
                    break;
                case ConvoMessageItemType.CommentsRating:
                    groupMessages = this.getCommentsRatingMessages(item as CommentsRating);
                    break;
                case ConvoMessageItemType.Gif:
                    groupMessages = this.getGifMessages(item as Gif, avatarUrl);
                    break;
            }

            const group = new ConvoPreviewMessageGroup(groupMessages);
            messages.push(group);
        }

        this.hideAvatar(messages);
        return messages;
    }

    private getDatePickerMessages(item: DatePicker, avatarUrl: string) {
        const messageGroup = [];
        messageGroup.push(new SimpleText(item.message, avatarUrl));
        messageGroup.push(new Reply('Reply'));
        const res = item.items[0] as TextGlobalResponse;
        const globalResponse = replaceTemplateVariablesForPreview(res.message, res.sortingOrder);
        if (globalResponse) {
            messageGroup.push(new SimpleText(globalResponse, avatarUrl));
        }
        return messageGroup;
    }

    private getRegistrationMessages(item: Registration, avatarUrl: string) {
        const messageGroup = [];

        const tile = {} as any;
        tile.title = item.regTitle;
        tile.subtitle = item.regSubtitle;
        tile.imageUrl = item.image;
        tile.answers = [{ label: item.acceptButtonLabel }, { label: item.continueButtonLabel }];

        messageGroup.push(new CarouselMessage([tile], avatarUrl));
        messageGroup.push(new Reply('Reply'));
        return messageGroup;
    }

    private getStatementMessages(item: TextStatement, avatarUrl: string) {
        const messageGroup = [];
        messageGroup.push(new SimpleText(item.statement, avatarUrl));
        return messageGroup;
    }

    private getVideoMessages(item: Video, avatarUrl: string): Message[] {
        const videoMessage = new VideoMessage(avatarUrl);
        videoMessage.image = item.video?.image || '';
        videoMessage.title = item.video?.title || '';
        videoMessage.duration = item.video?.duration || '';
        return [videoMessage];
    }

    private getCommentsRatingMessages(item: CommentsRating): Message[] {
        const commentsRatingMessage = new CommentsRatingMessage();
        commentsRatingMessage.title = item.text;
        const reply = new Reply('Move On');
        return [commentsRatingMessage, reply];
    }

    private getGifMessages(item: Gif, avatarUrl: string): Message[] {
        const messageGroup = [];
        const text = item.title;
        messageGroup.push(new Attachment(text, item.gif?.images?.small.url, avatarUrl));
        return messageGroup;
    }

    private getAttachmentUrlMessages(item: AttachmentUrl, avatarUrl: string) {
        const messageGroup = [];
        const text = item.url;
        messageGroup.push(new Attachment(text, item.url, avatarUrl));
        return messageGroup;
    }

    private getTextInputMessages(item: TextInput, avatarUrl: string) {
        const messageGroup = [];
        const globalResponse = replaceTemplateVariablesForPreview(
            (item.items[0] as TextGlobalResponse).message,
            (item.items[0] as TextGlobalResponse).sortingOrder,
        );
        messageGroup.push(new SimpleText(item.input, avatarUrl));
        messageGroup.push(new Reply('Reply'));

        if (globalResponse) {
            messageGroup.push(new SimpleText(globalResponse, avatarUrl));
        }
        return messageGroup;
    }

    private getQuickReplyMessages(item: QuickReply, avatarUrl: string) {
        const messageGroup = [];
        const answers = (item.items[0].items as QuickReplyAnswer[]).map((item) => ({
            label: item.buttonLabel,
            imageUrl: item.buttonImageUrl,
        }));
        const globalResponse = replaceTemplateVariablesForPreview(
            (item.items[1].items[0] as TextGlobalResponse).message,
            (item.items[1].items[0] as TextGlobalResponse).sortingOrder,
        );

        const layout: ButtonViewLayout = (<any>ButtonViewLayout)[item.layout];

        const quickReplyMessage = new QuickReplyMessage(
            `${item.statement}`,
            answers,
            avatarUrl,
            layout,
            item.maxLimit,
            item.minLimit,
            item.isMultipleChoice,
            item.submitButtonText,
        );
        messageGroup.push(quickReplyMessage);

        messageGroup.push(new Reply('Reply'));

        if (globalResponse) {
            messageGroup.push(new SimpleText(globalResponse, avatarUrl));
        }
        return messageGroup;
    }

    private getButtonMessages(item: Buttons, avatarUrl: string) {
        const messageGroup = [];
        const answers = (item.items[0].items as ButtonsAnswer[]).map((item) => ({
            label: item.buttonLabel,
        }));

        const layout: ButtonViewLayout = (<any>ButtonViewLayout)[item.layout];

        const buttonMessage = new ButtonMessage(
            `${item.statement}`,
            answers,
            avatarUrl,
            layout,
            item.minLimit,
            item.maxLimit,
            item.isMultipleChoice,
            item.submitButtonText,
        );
        messageGroup.push(buttonMessage);

        messageGroup.push(new Reply('Reply'));

        if (item.items[1] && item.items[1].items[0]) {
            const globalResponse = replaceTemplateVariablesForPreview(
                (item.items[1].items[0] as TextGlobalResponse).message,
                (item.items[1].items[0] as TextGlobalResponse).sortingOrder,
            );

            messageGroup.push(new SimpleText(globalResponse, avatarUrl));
        }

        return messageGroup;
    }

    private getRatingMessages(rating: Rating, avatarUrl: string) {
        const messageGroup = [];
        const answers = (rating.items as RatingOption[]).map((answer) => ({
            label: answer.title,
        }));

        const ratingMessage = new RatingMessage(`${rating.statement}`, answers, avatarUrl);
        messageGroup.push(ratingMessage);

        messageGroup.push(new Reply('Reply'));

        return messageGroup;
    }

    private getCarouselMessages(item: Carousel, avatarUrl: string) {
        const messageGroup = [];

        const tiles = (item.items[0].items as CarouselTile[]).map((tile) => ({
            title: tile.tileTitle,
            subtitle: tile.subtitle,
            link: tile.link,
            imageUrl: tile.image,
            answers: (tile.items as ButtonsAnswer[]).map((tile) => ({ label: tile.buttonLabel })),
        }));

        const globalResponse = replaceTemplateVariablesForPreview(
            (item.items[1].items[0] as TextGlobalResponse).message,
            (item.items[1].items[0] as TextGlobalResponse).sortingOrder,
        );

        messageGroup.push(new CarouselMessage(tiles, avatarUrl));
        messageGroup.push(new Reply('Reply'));

        if (globalResponse) {
            messageGroup.push(new SimpleText(globalResponse, avatarUrl));
        }
        return messageGroup;
    }

    private hideAvatar(messages: ConvoPreviewMessageGroup[]) {
        let previousMessage: Message;

        messages.forEach((messageGroup: ConvoPreviewMessageGroup) => {
            messageGroup.messages.forEach((message: Message) => {
                if (
                    previousMessage &&
                    !(previousMessage instanceof Reply) &&
                    !(message instanceof QuickReplyAnswer) &&
                    !(message instanceof Reply)
                ) {
                    previousMessage.showAvatar = false;
                }

                previousMessage = message;
            });
        });
    }
}
