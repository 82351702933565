<template>
    <MessageWrapper :avatar-url="avatarUrl">
        <MessageBubble
            class="text-message"
            :show-arrow="showArrow"
            :image-url="item.url"
            @imageOnClick="imageOnClick(item.url)"
        />
    </MessageWrapper>
</template>

<script>
import messageMixin from '../mixins/message.mixin';
import MessageWrapper from './MessageWrapper';
import MessageBubble from './MessageBubble';

export default {
    name: 'Attachment',
    components: {
        MessageWrapper,
        MessageBubble,
    },
    mixins: [messageMixin],
    props: {
        item: {
            type: Object,
            default: undefined,
        },
    },
    computed: {
        avatarUrl() {
            return this.item.showAvatar && this.item.avatarUrl ? this.item.avatarUrl : undefined;
        },
        showArrow() {
            return this.avatarUrl ? true : false;
        },
    },
    methods: {
        imageOnClick(url) {
            window.open(url, '_blank');
        },
    },
};
</script>
