import { ContentDiscoveryMenuItemType } from '@/enums';
import { getErrorMessage } from '@/store/services/validationService';
import { LogicJump } from '..';
import { MenuBase } from './menuBase';

export interface IConvoMenu {
    logicJump?: LogicJump;
}

export class ConvoMenu extends MenuBase implements IConvoMenu {
    menuType: ContentDiscoveryMenuItemType;

    logicJump?: LogicJump;

    constructor() {
        super();
        this.menuType = ContentDiscoveryMenuItemType.Convo;
    }

    async validate(): Promise<any> {
        let errors = await super.validate();
        const logicJumpErrors = await this.logicJump?.validate();
        if (Object.keys(logicJumpErrors).length) {
            errors = errors || {};
            errors.logicJump = [getErrorMessage('logicJumpContent', 'convo')];
        }
        return errors;
    }
}
