import { Category } from '@/store/models/category';
import { Category as CategoryDto } from '../dtos/category.dto';

export class CategoryFactory {
    public make(data: CategoryDto): Category {
        const category = new Category();

        category.id = data.id;
        category.name = data.name;
        category.description = data.description;
        category.slug = data.slug;
        category.color = data.color;
        category.image = data.image;
        category.channelList = data.channelList;

        category.originalVersion = data.originalVersion;

        return category;
    }
}
