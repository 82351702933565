import Vuex, { StoreOptions } from 'vuex';
import Vue from 'vue';
import { broadcasts } from './modules/broadcasts';
import { convoDetails } from './modules/convo-details';
import { convoList } from './modules/convo-list';
import { channel } from './modules/channel';
import { tenant } from './modules/tenant';
import { main } from './modules/main';
import { reports } from './modules/reports';
import { category } from './modules/category';
import { audienceList } from './modules/audience/audience-list';
import { audienceDetails } from './modules/audience/audience-details';
import { logicJump } from './modules/logic-jump/module';
import { channelGroupReport } from './modules/channel-group-report';
import { tags } from './modules/tags/tags';
import { auth } from './modules/auth/auth';
import { contentDiscoveryMenu } from './modules/contentDiscoveryMenu/content-discovery-menu';
import { userComments } from './modules/user-comments';
import { yougov } from './modules/yougov';

import { IRootState } from './types/rootState';

Vue.use(Vuex);

const store: StoreOptions<IRootState> = {
    state: {
        version: '1.0.0',
    },
    modules: {
        convoDetails,
        convoList,
        broadcasts,
        channel,
        channelGroupReport,
        tenant,
        main,
        reports,
        category,
        audienceList,
        audienceDetails,
        logicJump,
        tags,
        auth,
        contentDiscoveryMenu,
        userComments,
        yougov,
    },
};
export default new Vuex.Store<IRootState>(store);
