import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ReportClient } from '../../api/report';
import { IConvoReport } from '../models/interfaces';
import { SET_REPORTS } from '../mutations.types';
import { IRootState } from '../types/rootState';
import {
    RESET_CONVO_REPORT,
    SET_TEXT_INPUT_REPORT,
    ADD_REPORT_BREAK,
    CLEAR_REPORT_BREAKS,
    SET_REPORT_BREAK_LOADING_STATE,
} from '../mutations.types';
import {
    GetUserInputRequest,
    GetReportBreakRequest,
    GetReportRequest,
} from '../models/dtos/reportRequest.dto';
import { ReportBreakFactory } from '../models/factories/reportBreak.factory';
import { ReportBreak } from '../models/reportBreak';
import { IContentItemReportResponse } from '../models/interfaces/reports.interface';

export interface IReportState {
    reports: IContentItemReportResponse[];
    reportBreaks: ReportBreak[];
    textInputReport: any;
    reportBreakLoading: boolean;
}

const state: IReportState = {
    reports: [],
    reportBreaks: [],
    textInputReport: {},
    reportBreakLoading: false,
};

const getters: GetterTree<IReportState, IRootState> = {
    textInputReport: (state) => state.textInputReport,
};

const actions: ActionTree<IReportState, any> = {
    getReport: async ({ commit }, request: GetReportRequest) => {
        const data: IConvoReport = await ReportClient.getReport(request);
        commit(SET_REPORTS, (data && data.contentItemReportResponses) || []);
    },
    getReportBreak: async ({ commit, state }, options: GetReportBreakRequest[]) => {
        commit(SET_REPORT_BREAK_LOADING_STATE, true);
        const reportBreakData = await ReportClient.getReportBreakInParallel(options);
        const reportBreak: ReportBreak = { title: '', answers: [], cells: {} };
        for (const response of reportBreakData) {
            const reportBreakResponse = ReportBreakFactory.make(response);
            if (!reportBreak.title) {
                reportBreak.title = reportBreakResponse.title || reportBreak.title;
            }
            if (!reportBreak.answers?.length) {
                reportBreak.answers = reportBreakResponse.answers || reportBreak.answers;
            }
            reportBreak.cells = { ...reportBreak.cells, ...reportBreakResponse.cells };
        }
        commit(ADD_REPORT_BREAK, reportBreak);
        commit(SET_REPORT_BREAK_LOADING_STATE, false);
    },
    getUserInputReport: async ({ commit }, options: GetUserInputRequest) => {
        const data = await ReportClient.getReportText(options);
        commit(SET_TEXT_INPUT_REPORT, data);
    },
    resetConvoReport: async ({ commit }) => {
        commit(RESET_CONVO_REPORT);
    },
    clearReportBreaks: ({ commit }) => {
        commit(CLEAR_REPORT_BREAKS);
    },
};

const mutations: MutationTree<IReportState> = {
    [SET_REPORTS](state, reports: IContentItemReportResponse[]): void {
        state.reports = reports;
    },
    [RESET_CONVO_REPORT](state: IReportState): void {
        state.reports = [];
    },
    [SET_TEXT_INPUT_REPORT](state, textReport): void {
        state.textInputReport = textReport;
    },
    [ADD_REPORT_BREAK](state: IReportState, reportBreak: ReportBreak): void {
        state.reportBreaks.push(reportBreak);
    },
    [SET_REPORT_BREAK_LOADING_STATE](state: IReportState, payload: boolean): void {
        state.reportBreakLoading = payload;
    },
    [CLEAR_REPORT_BREAKS](state: IReportState): void {
        state.reportBreaks = [];
    },
};

export const reports: Module<IReportState, IRootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
