import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum.ts';
import { ListMarker } from '@/store/enums/listMarker.enum';
import { AccordionItemBase, ButtonsAnswer } from '@/store/models';
import { getErrorMessage } from '@/store/services/validationService';
import { IsNotEmpty, Length, validate as validateClass, ValidationError } from 'class-validator';
import { v4 as uuid } from 'uuid';
import { AccordionStyle } from '../enums';
import { IConvoState } from '../modules/convo-details';

export class CarouselTile extends AccordionItemBase {
    constructor(items = []) {
        super(items);
        this.type = ConvoMessageItemType.CarouselTile;
        this.accordionStyle = AccordionStyle.Tab;
        this.tileId = uuid();
        this.isDraggable = true;
        this.isDeletable = true;
        this.markerType = ListMarker.LowercaseLetter;
        this.items = [];
        this.tileTitle = '';
        this.subtitle = '';
        this.link = '';
        this.linkOpenInNewTab = false;
        this.image = '';
        this.itemsMinLength = 1;
        this.itemsMaxLength = 3;
    }

    tileId: string;
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Title') })
    @Length(0, 100, { message: getErrorMessage('length', 'Title', { min: 0, max: 100 }) })
    tileTitle: string;
    @Length(0, 200, { message: getErrorMessage('length', 'Subtitle', { min: 0, max: 200 }) })
    subtitle: string;
    link: string;
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Image') })
    image: string;
    itemsMinLength: number;
    itemsMaxLength: number;
    linkOpenInNewTab: boolean;

    addItem(item: AccordionItemBase): void {
        this.items.push(item);
    }

    async validate(state: IConvoState, path: number[]): Promise<any> {
        const errors: ValidationError[] = await validateClass(this);
        const errorMessages: any[] = [];
        const mainLevelErrors: any = {};

        if (errors.length > 0) {
            errors.forEach((item: ValidationError) => {
                mainLevelErrors[item.property] = Object.values(item.constraints || {});
            });
        }

        // number of answers
        if (this.items.length < this.itemsMinLength || this.items.length > this.itemsMaxLength) {
            mainLevelErrors.buttonsAnswers = [];
            mainLevelErrors.buttonsAnswers.push(
                getErrorMessage('buttonsLength', 'It', {
                    min: this.itemsMinLength,
                    max: this.itemsMaxLength,
                }),
            );
        }

        if (Object.entries(mainLevelErrors).length > 0) {
            errorMessages.push({ path, errors: mainLevelErrors });
        }

        // answers
        for (let i = 0; i < this.items.length; i++) {
            const answer = this.items[i] as ButtonsAnswer;
            const chilrenErrors: any[] = await answer.validate(state, [...path, i]);

            if (Object.entries(chilrenErrors).length > 0) {
                errorMessages.push(...chilrenErrors);
            }
        }

        return errorMessages;
    }

    public hasCorrectAnswer(): boolean {
        return this.items.some((item) => (item as ButtonsAnswer).isCorrectAnswer);
    }
}
