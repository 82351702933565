import { ContentItemType, ConvoTypes } from '@/store/enums';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum';
import { OperationType } from '@/store/enums/operationType.enum';
import { IConvoState } from '@/store/modules/convo-details';
import {
    AttachmentUrl,
    Buttons,
    Carousel,
    CommentsRating,
    ContentItem,
    DatePicker,
    Gif,
    Image,
    QuickReply,
    Registration,
    Result,
    TextInput,
    TextStatement,
    Video,
    Rating,
} from '..';
import { ContentItemsRequest } from '../dtos';
import { IQuizContentItem } from '../interfaces/quizContentItem.interface';
import { IQuizOptions } from '../interfaces/quizOptions.interface';
import { YougovWelcomeSurveyButtons } from '../ygWelcomeSurvey';

export class ContentItemsRequestFactory {
    public make(state: IConvoState): ContentItemsRequest {
        const request = new ContentItemsRequest();

        let contentItems: ContentItem[] = [];

        if (
            state.convo.type === ConvoTypes.PersonalityQuiz ||
            state.convo.type === ConvoTypes.TriviaQuiz
        ) {
            const resultContentItem = this.getResultsContentItem(state);
            contentItems.push(resultContentItem);
        }

        const messageContentItems = this.getMessagesContentItems(state);
        contentItems = contentItems.concat(messageContentItems);

        request.channelId = state.convo.channelId;
        request.convoId = state.convo.id;
        request.userId = 0; // TODO: get current user id
        request.contentItems = contentItems;

        return request;
    }

    private getResultsContentItem(state: IConvoState): ContentItem {
        const resultContentItem = new ContentItem();

        const resultContentType =
            state.convo.type === ConvoTypes.PersonalityQuiz
                ? ContentItemType.PersonalityQuizResult
                : ContentItemType.TriviaQuizResult;

        const serverResultItem = state.convo.contentItems?.find(
            (o) => o.type === resultContentType,
        );

        if (serverResultItem) {
            resultContentItem.id = serverResultItem.id;
            resultContentItem.operationType = OperationType.Update;
        } else {
            resultContentItem.operationType = OperationType.Create;
        }

        resultContentItem.type = resultContentType;
        resultContentItem.answers = [];

        for (const result of state.resultsView.resultItems.filter((item) => !item.isDeleted)) {
            const answer = (result as Result).toContentItemAnswer(state);
            resultContentItem.answers.push(answer);
        }

        return resultContentItem;
    }

    private getMessagesContentItems(state: IConvoState): ContentItem[] {
        const contentItems: ContentItem[] = [];
        for (const item of state.messagesView.messageItems) {
            let message;
            switch (item.type) {
                case ConvoMessageItemType.TextStatement:
                    message = item as TextStatement;
                case ConvoMessageItemType.QuickReply:
                    message = item as QuickReply;
                case ConvoMessageItemType.TextInput:
                    message = item as TextInput;
                case ConvoMessageItemType.AttachmentUrl:
                    message = item as AttachmentUrl;
                case ConvoMessageItemType.Buttons:
                    message = item as Buttons;
                case ConvoMessageItemType.Carousel:
                    message = item as Carousel;
                case ConvoMessageItemType.Registration:
                    message = item as Registration;
                case ConvoMessageItemType.DatePicker:
                    message = item as DatePicker;
                case ConvoMessageItemType.Video:
                    message = item as Video;
                case ConvoMessageItemType.CommentsRating:
                    message = item as CommentsRating;
                case ConvoMessageItemType.Gif:
                    message = item as Gif;
                case ConvoMessageItemType.Image:
                    message = item as Image;
                case ConvoMessageItemType.Rating:
                    message = item as Rating;
                case ConvoMessageItemType.YougovWelcomeSurveyButtons:
                    message = item as YougovWelcomeSurveyButtons;
            }

            if (message) {
                const contentItem = message.toContentItem(state);
                if (message.id > 0) {
                    if (message.hasChanges) {
                        contentItem.operationType = OperationType.Update;
                        contentItems.push(contentItem);
                    }
                } else {
                    contentItem.operationType = OperationType.Create;
                    contentItems.push(contentItem);
                }
            }
        }

        const deletedMessageIds = state.messagesView.messageItems
            .filter((o) => o.type !== ContentItemType.PersonalityQuizResult && o.isDeleted)
            .map((o) => o.id);

        for (const id of deletedMessageIds) {
            const contentItem = new ContentItem();
            contentItem.id = id;
            contentItem.operationType = OperationType.Delete;
            contentItems.push(contentItem);
        }

        return contentItems;
    }
}

export const makeTriviaQuizAttr = (contentItem: IQuizContentItem, answer: IQuizOptions) => ({
    ignoreQuizAnswer: contentItem.ignoreQuizQuestion,
    isCorrectAnswer: contentItem.ignoreQuizQuestion ? false : answer.isCorrectAnswer,
});

export const makePersonalityQuizAttr = (contentItem: IQuizContentItem, answer: IQuizOptions) => ({
    quizResultItem: contentItem.ignoreQuizQuestion ? -1 : answer.selectedPersonalityQuizResult,
});
