<template>
    <div
        class="char-counter"
        :class="{
            'char-counter--error': remaining < 0,
            'char-counter--warning': warningMax && warningMax < count,
        }"
    >
        {{ remaining }} / {{ max }}
    </div>
</template>

<script>
export default {
    name: 'CharCounter',
    props: {
        count: {
            type: Number,
            required: true,
        },
        max: {
            type: Number,
            required: true,
        },
        warningMax: {
            type: Number,
            default: undefined,
        },
    },
    computed: {
        remaining() {
            return this.max - this.count;
        },
    },
};
</script>

<style scoped lang="scss">
.char-counter {
    font-family: $font-family-default;
    font-size: 10px;
    color: $alternate-text-color;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.6;

    &--error {
        color: $error-color;
    }

    &--warning {
        color: $warning-color;
    }
}
</style>
