<template functional>
    <div class="horizontal-separator">
        <slot>OR</slot>
    </div>
</template>

<script>
export default {
    name: 'HorizontalSeparator',
};
</script>

<style lang="scss" scoped>
$color-horizontal-separator-line: #d8dce9 !default;
$color-horizontal-separator-text: #241d36 !default;

.horizontal-separator {
    display: flex;
    align-items: center;
    padding: 20px 40px;
    font-family: $font-family-default;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    text-align: center;
    color: $color-horizontal-separator-text;

    &::before,
    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: $color-horizontal-separator-line;
    }

    &::before {
        margin-right: 20px;
    }

    &::after {
        margin-left: 20px;
    }
}
</style>
