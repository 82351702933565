const itemFormMixin = {
    methods: {
        onInput(key, value) {
            this.data[key] = value; //TODO: remove, don't change store here
            // maybe pass key value and do type conversion in store
            this.$emit('propertyOnChange', { path: this.path, item: this.data, key, value });
        },
        onChanged(event) {},
        generateId(name, path) {
            return `${name}-${path.join('-')}`;
        },
    },
};

export default itemFormMixin;
