<template>
    <Overlay
        v-if="inOverlay"
        :show="showOverlay"
        :show-close-button="isMediumScreen"
        disable-body-scroll
        disable-overlay-scroll
        @onClose="$emit('overlayOnClose')"
        @afterEnter="$emit('afterEnter')"
    >
        <div class="overlay-convo-preview-wrapper">
            <div v-if="!isMediumScreen" class="overlay-convo-preview-wrapper-header">
                <Button
                    class="close-button"
                    right-icon="cross-simple"
                    @click="$emit('overlayOnClose')"
                    >close</Button
                >
            </div>
            <div class="overlay-convo-preview-wrapper-body">
                <slot />
            </div>
        </div>
    </Overlay>
    <div
        v-else
        class="convo-preview-wrapper"
        :class="{ 'convo-preview-wrapper--nav-is-opened': navIsOpened }"
    >
        <div class="convo-preview-wrapper-body">
            <slot />
        </div>
    </div>
</template>

<script>
import Overlay from '@/components/ui/Overlay';
import Button from '@/components/ui/Button';
import cssVariables from '@styles/abstracts/_variables.layout.scss';

let mediaQueryList;

export default {
    name: 'ConvoPreviewWrapper',
    components: {
        Overlay,
        Button,
    },
    props: {
        inOverlay: {
            type: Boolean,
            default: false,
        },
        showOverlay: {
            type: Boolean,
            default: false,
        },
        navIsOpened: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isMediumScreen: false,
        };
    },
    created() {
        if (Object.keys(cssVariables).length) {
            const medium = cssVariables['media-queries-medium'].slice(1, -1);
            mediaQueryList = matchMedia(medium);

            if (mediaQueryList.matches) {
                this.isMediumScreen = true;
            }

            mediaQueryList.addListener(this.setIsMediumScreen);
        }
    },
    destroyed() {
        if (Object.keys(cssVariables).length) {
            mediaQueryList.removeListener(this.setIsMediumScreen);
        }
    },
    methods: {
        setIsMediumScreen(event) {
            if (event.matches) {
                this.isMediumScreen = true;
            } else {
                this.isMediumScreen = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.overlay-convo-preview-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.overlay-convo-preview-wrapper-header {
    z-index: $overlay-convo-preview-wrapper-header-z-index;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0 auto;
    height: 50px;
    background: #fff;
    box-shadow: 1px 1px 15px 0px $shadow-color-08;

    .close-button {
        .right-icon {
            font-size: 12px;
        }
    }
}

.overlay-convo-preview-wrapper-body {
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
    min-height: 0;
    max-height: 100%;
    height: 100px; // fix ios height problem
}
</style>
