import { Message } from './message';
import { MessageType } from './enums/messageType.enum';
import { CarouselMessage, ICarouselMessage } from './carouselMessage';

export class Carousel extends Message {
    messages: CarouselMessage[] = [];

    constructor(messages: ICarouselMessage[], avatarUrl: string) {
        super(MessageType.Carousel, avatarUrl);
        this.setTiles(messages);
    }

    private setTiles(messages: ICarouselMessage[]) {
        messages.forEach((message: ICarouselMessage) => {
            this.messages.push(
                new CarouselMessage(
                    message.title,
                    message.subtitle,
                    message.link,
                    message.imageUrl,
                    message.answers,
                ),
            );
        });
    }
}
