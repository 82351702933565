<template>
    <div class="error-message">
        <Icon name="alert" />
        {{ message }}
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    name: 'ErrorMessage',
    components: {
        Icon,
    },
    props: {
        message: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
.error-message {
    position: relative;
    padding-left: 15px;
    font-size: 10px;
    font-style: italic;
    color: $error-color;

    .icon {
        @include center(y);
        position: absolute;
        left: 0;
        font-size: 11px;
    }
}
</style>
