<template>
    <div>
        <EditorInput
            v-if="$options.richTextMessagesEnabled"
            :id="generateId('input', path)"
            class="m-b-2"
            label="Message"
            show-remaining-char
            :content="data.input"
            :errors="data.errors.plainText"
            :max="640"
            :menu-items="[
                $options.EditorInputMenuItem.Bold,
                $options.EditorInputMenuItem.Italic,
                $options.EditorInputMenuItem.Strike,
                $options.EditorInputMenuItem.Underline,
            ]"
            @update="onInput('input', $event)"
            @plainTextChange="onInput('plainText', $event)"
        />

        <Input
            v-else
            :id="generateId('input', path)"
            class="m-b-2"
            type="textarea"
            label="Message"
            name="input"
            :max="640"
            show-remaining-char
            :value="data.input"
            :errors="data.errors && data.errors.input"
            @input="
                (value) => {
                    onInput('input', value);
                    onInput('plainText', value);
                }
            "
        />
        <div class="m-b-2">
            <Select
                v-if="currentConvo.type === $options.convoTypes.YougovWelcomeChat"
                :id="generateId('datum-definition', path)"
                label="Datum Question"
                class="select"
                placeholder="Select question"
                show-clear-icon
                :disabled="yougovDefinitionOptions.length === 0"
                :is-loading="$wait.is($options.LoadingFlag.YougovDefinitionsGet)"
                :options="yougovDefinitionOptions"
                :value="data.datumDefinition"
                @input="onInput('datumDefinition', $event)"
            />
        </div>
        <div class="ti-options-row m-b-2">
            <Select
                v-if="messengerValidationRulesOptions.length > 0"
                :id="generateId('input-validation-rules', path)"
                label="Validation Rules"
                class="select validation-rules-selector"
                placeholder="Select any rule"
                :has-margin-bottom="false"
                :show-clear-icon="
                    data.messengerValidation.code !== $options.MessengerValidationTypes.None
                "
                :options="messengerValidationRulesOptions"
                :value="data.messengerValidation.code"
                @input="onMessengerValidationChange($event)"
            />

            <Input
                v-if="showValidationErrorField"
                id="error-message-text"
                type="text"
                class="validation-error-message"
                placeholder="Type your error message here"
                label="Error Message"
                name="error-message-text"
                :has-margin-bottom="false"
                :value="data.errorMessage"
                :errors="data.errors.errorMessage"
                @input="onInput('errorMessage', $event)"
            />
        </div>
        <div class="m-b-2">
            <Select
                :id="generateId('pii-selector', path)"
                class="select pii-selector"
                placeholder="Select a value"
                :has-margin-bottom="false"
                :options="[
                    { id: 'false', value: 'No' },
                    { id: 'true', value: 'Yes' },
                ]"
                :errors="data.errors && data.errors.isPii"
                :value="data.isPii + ''"
                @input="onInput('isPii', $event === 'true')"
            >
                <template #label>
                    <span>Can contain PII data</span>
                    <Icon
                        v-tooltip="{
                            content:
                                'Text inputs that can contain PII will not be reportable in CMS\
                            and will not be shown to other Chat members',
                            classes: 'pii-selector-info-tooltip',
                        }"
                        class="pii-selector-icon-info"
                        name="icon-info"
                    />
                </template>
            </Select>
        </div>
        <div class="ti-options-row m-b-2">
            <Toggle
                v-if="$options.textInputCorrelation"
                :id="generateId('toggle-correlation', path)"
                class="correlator-selector"
                label="Enable Comment Summary"
                :value="data.hasCorrelation"
                @input="onInput('hasCorrelation', $event)"
            />

            <Toggle
                v-if="messengerValidationRulesOptions.length > 0"
                :id="generateId('toggle-allow-skip', path)"
                label="Allow skip"
                :value="data.allowSkip"
                @input="onInput('allowSkip', $event)"
            />
        </div>
        <Input
            v-if="data.hasCorrelation"
            id="content-selector"
            type="text"
            placeholder="Search for a message"
            label="Comment Summary Message"
            info-text="IMPORTANT: select a message that precedes this text input in the convo flow."
            name="content-selector"
            has-margin-bottom
            default-icon="search"
            :value="correlationText"
            :default-icon-size="16"
            @focus="$emit('openCorrelationContent', path)"
        />
        <ContentSelector
            v-if="showCorrelator"
            :value="data.correlation"
            validation-content-type="message"
            show-content-id
            :content-types="$options.correlationContentTypes"
            @close="$emit('closeCorrelationContentSeletor')"
            @input="setCorrelation"
        />
        <Accordion
            v-if="data.items"
            :items="data.items"
            :path="path"
            :is-draggable="false"
            @headerOnClick="(event, path) => $emit('headerOnClick', event, path)"
            @onExpand="(path) => $emit('onExpand', path)"
            @onExpanded="(path) => $emit('onExpanded', path)"
            @onContract="(path) => $emit('onContract', path)"
            @onContracted="(path) => $emit('onContracted', path)"
            @deleteOnClick="
                (event, path, parentPath) => $emit('deleteOnClick', event, path, parentPath)
            "
            @onDrop="(path, value) => $emit('onDrop', path, value)"
            @messageTypeOnSelect="
                (event, path, index) => $emit('messageTypeOnSelect', event, path, index)
            "
            @propertyOnChange="$emit('propertyOnChange', $event)"
        />
    </div>
</template>

<script>
import itemFormMixin from '@/mixins/itemFormMixin';
import { Input, Toggle, EditorInput, Select } from '@/components/forms';
import { EditorInputMenuItem, ContentItemType, ConvoTypes, LoadingFlag } from '@/store/enums';
import { LogicJump } from '@/store/models';
import { YougovDatumResponseTypes, MessengerValidationTypes } from '@/enums';
import config from '@/config';
import messengerValidationRules from '@/json/validationRules';
import { ToastType } from '@/plugins/toaster';
import { ANY } from '@/constants';
import Icon from '@/components/ui/Icon.vue';

export default {
    name: 'TextInput',
    components: {
        Input,
        Toggle,
        EditorInput,
        Select,
        Icon,
        Accordion: () => import('@/components/ui/Accordion'),
        ContentSelector: () => import('@/components/ContentSelector/ContentSelector'),
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        currentChannel: {
            type: Object,
            default: undefined,
        },
        currentConvo: {
            type: Object,
            default: undefined,
        },
        yougovDefinitions: {
            type: Array,
            default: () => [],
        },
        showCorrelator: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            correlationText: '',
        };
    },
    computed: {
        yougovDefinitionOptions() {
            return this.yougovDefinitions.reduce((list, item) => {
                if (item.type === YougovDatumResponseTypes.Open) {
                    list.push({
                        ...item,
                        id: item.code,
                        value: `${item.code} - ${item.description}`,
                    });
                }
                return list;
            }, []);
        },
        messengerValidationRulesOptions() {
            return this.$options.messengerValidationRules.reduce((list, item) => {
                const isValidationAllowedForCounrty =
                    item.countries?.[0] === ANY ||
                    item.countries.some((country) => this.currentConvo.countries.includes(country));

                if (
                    item.convoTypes?.[0] === ANY ||
                    (item.convoTypes.includes(this.currentConvo.type) &&
                        isValidationAllowedForCounrty)
                ) {
                    list.push({ ...item, id: item.code, value: item.label });
                }
                return list;
            }, []);
        },
        showValidationErrorField() {
            return (
                this.messengerValidationRulesOptions.length > 0 &&
                this.data.messengerValidation.code !== MessengerValidationTypes.None
            );
        },
    },
    watch: {
        data: {
            immediate: true,
            deep: true,
            handler(data) {
                this.setCorrelationText(data.correlation);
            },
        },
    },
    created() {
        this.$options.richTextMessagesEnabled = config.featureToggle.richTextMessagesEnabled;
        this.$options.textInputCorrelation = config.featureToggle.textInputCorrelation;
        this.$options.EditorInputMenuItem = EditorInputMenuItem;
        this.$options.correlationContentTypes = [
            ContentItemType.Buttons,
            ContentItemType.QuickReply,
        ];
        this.$options.convoTypes = ConvoTypes;
        this.$options.LoadingFlag = LoadingFlag;
        this.$options.messengerValidationRules = messengerValidationRules;
        this.$options.MessengerValidationTypes = MessengerValidationTypes;
    },
    methods: {
        setCorrelationText(correlation) {
            const text = [];
            if (correlation?.channel?.text) text.push(correlation.channel.text);
            if (correlation?.convo?.text) text.push(correlation.convo.text);
            if (correlation?.message?.text) text.push(correlation.message.text);
            this.correlationText = text.join(' • ');
        },
        onMessengerValidationChange(e) {
            if (!e) {
                e = MessengerValidationTypes.None;
            }
            const { code, regex, postValidation } = this.$options.messengerValidationRules.find(
                (v) => v.code === e,
            );
            this.onInput('messengerValidation', { code, regex, postValidation });
            if (e === MessengerValidationTypes.None) {
                this.onInput('errorMessage', '');
            }
        },
        setCorrelation(content) {
            if (content && content.message && content.message.isMultipleChoice) {
                this.$toaster.add(
                    'Correlation is not supported for multiple choice buttons/quick reply',
                    {
                        type: ToastType.Error,
                    },
                );
            } else {
                this.onInput('correlation', new LogicJump(content));
            }
        },
    },
};
</script>

<style lang="scss" scoped>
$icon-info-text-color: #33415c !default;

.ti-options-row {
    & > div {
        margin-bottom: $default-vertical-space * 2;
    }

    @include media-query(medium) {
        display: flex;
        justify-content: space-between;
        align-items: top;

        & > div {
            width: calc(50% - 10px);
            margin-bottom: 0;
        }
    }
}

.pii-selector-icon-info {
    position: relative;
    display: inline-block;
    color: $icon-info-text-color;
    margin-left: 5px;
    vertical-align: top;
    cursor: pointer;
}
</style>

<style lang="scss">
.pii-selector-info-tooltip .tooltip-inner {
    font-size: $font-size-xs !important;
    max-width: 250px;
    text-align: center;
}
</style>
