<template>
    <router-link :to="to" class="nav-button" :class="{ 'nav-button--active': active }">
        <span class="nav-button__inner">
            <span class="nav-button__icon">
                <Icon :name="icon" :size="18" :color="iconColor" />
            </span>

            <span class="nav-button__title">{{ title }}</span>
        </span>
    </router-link>
</template>

<script>
import Icon from '@/components/ui/Icon';
import colors from '@styles/abstracts/_variables.colors.scss';

export default {
    name: 'NavButton',
    components: {
        Icon,
    },
    props: {
        to: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        iconColor() {
            return colors['nav-button-icon-color'];
        },
    },
};
</script>

<style lang="scss" scoped>
$nav-button-width: 50px;

.nav-button {
    margin: 4px 0;
    position: relative;
    display: block;
    min-width: $nav-button-width;
    width: 100%;
    height: $nav-button-width;

    &:hover,
    &--active {
        background-color: $nav-background-color;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;

        .nav-button__inner {
            &::before,
            &::after {
                display: block;
            }
        }
    }

    &--active::before {
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        height: 8px;
        width: 3px;
        border-radius: 8px;
        background-color: $nav-button-icon-active-color;
    }

    &__inner {
        display: flex;
        align-items: center;

        &::before,
        &::after {
            display: none;
            content: '';
            position: absolute;
            right: 0;
            width: 20px;
            height: 20px;
            background-color: transparent;
        }

        &::before {
            top: -20px;
            border-bottom-right-radius: 10px;
            box-shadow: 0 10px 0 0 $nav-background-color;
        }

        &::after {
            bottom: -20px;
            border-top-right-radius: 10px;
            box-shadow: 0 -10px 0 0 $nav-background-color;
        }
    }

    &__icon {
        width: $nav-button-width;
        height: $nav-button-width;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            transform: translateX(2px);
        }
    }

    &__title {
        position: absolute;
        left: $nav-button-width;
        font-size: 16px;
        color: $nav-button-icon-color;
        white-space: nowrap;
    }
}
</style>
