import {
    ArrayNotEmpty,
    IsNotEmpty,
    Length,
    MaxLength,
    ValidateIf,
    validate as validateClass,
} from 'class-validator';
import hash from 'object-hash';
import { ConvoOperation } from '@/enums';
import convoTypes from '@/json/convoTypes';
import { getErrorMessage, getWarningMessage } from '@/store/services/validationService';
import { sanitize } from '@/utils/sanitizer';
import { ChatGroup, ContentItemType, ConvoTypes } from '../enums';
import { ConvoEndLogic } from './convo/convoEndLogic';
import { ConvoFactory } from './factories/convo.factory';
import { IConvo, IConvoCategory, ITag } from './interfaces';
import { IContentItem } from './interfaces/contentItem.interface';

const getConvoSettings = (type: string) => {
    return convoTypes.find((o) => o.name === type);
};

export class Convo implements IConvo {
    constructor() {
        this.id = 0;
        this.createdAt = new Date();
        this.updatedAt = new Date();
        this.userId = 0;
        this.channelId = 0;
        this.segment = 0;
        this.type = ConvoTypes.Scripted;
        this.name = '';
        this.widgetsTotal = 0;
        this.broadcasted = 0;
        this.sort = 0;
        this.contentItems = [];
        this.statuses = [];
        this.results = [];
        this.broadcasts = [];
        this.redirectUrl = '';
        this.errors = {};
        this.warnings = {};
        this.published = false;
        this.version = 0;
        this.lastPublishedVersion = 0;
        this.description = '';
        this.image = '';
        this.tags = [];
        this.topics = [];
        this.pdlTagsInConvo = [];
        this.chatGroup = ChatGroup.Editorial;
        this.dailyChat = false;
        this.isGlobal = true;
        this.countries = [];
        this.categories = [];
    }
    id: number;
    createdAt: Date;
    updatedAt: Date;
    userId: number;
    channelId: number;
    segment: number;
    type: string;
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Name'), groups: ['error'] })
    @MaxLength(199, {
        message: getErrorMessage('length', 'Name', { min: 0, max: 200 }),
        groups: ['error'],
    })
    @Length(0, 99, {
        message: getWarningMessage('convoAttributeLength', 'Name', { max: 100 }),
        groups: ['warning'],
    })
    name: string;
    widgetsTotal: number;
    broadcasted: number;
    sort: number;
    contentItems: IContentItem[];
    statuses: [];
    tags: ITag[];
    topics: ITag[];
    results: [];
    broadcasts: [];
    redirectUrl: string;
    errors: any;
    warnings: any;
    published: boolean;
    version: number;
    lastPublishedVersion: number;
    showFromDate?: Date;
    hideFromDate?: Date;
    @MaxLength(199, {
        message: getErrorMessage('length', 'Name', { min: 0, max: 200 }),
        groups: ['error'],
    })
    @Length(0, 99, {
        message: getWarningMessage('convoAttributeLength', 'Description', { max: 100 }),
        groups: ['warning'],
    })
    description?: string;
    image?: string;
    originalVersion: string;
    pdlTagsInConvo: ITag[];
    endLogic?: ConvoEndLogic;
    chatGroup: ChatGroup;
    dailyChat: boolean;
    isGlobal: boolean;
    @ValidateIf((o) => o.type !== ConvoTypes.YougovWelcomeSurvey && o.isGlobal === false, {
        groups: ['error'],
    })
    @ArrayNotEmpty({ message: getErrorMessage('isNotEmpty', 'Countries'), groups: ['error'] })
    countries: string[];

    @ValidateIf((o) => !getConvoSettings(o.type)?.convoAttributes?.categories?.disabled, {
        groups: ['error'],
    })
    @ArrayNotEmpty({ message: getErrorMessage('isNotEmpty', 'Categories'), groups: ['error'] })
    categories: IConvoCategory[];

    hasChanges(): boolean {
        const factory = new ConvoFactory();
        const convo = factory.make(this);

        const version = hash(convo);

        if (this.originalVersion) {
            return version !== this.originalVersion;
        }
        return false;
    }

    async validate(operation?: ConvoOperation): Promise<any> {
        const errorMessages: any = {};
        const warningMessages: any = {};
        const errors = await validateClass(this, { groups: ['error'], strictGroups: true });
        const warnings = await validateClass(this, { groups: ['warning'], strictGroups: true });

        if (operation === ConvoOperation.Publishing) {
            const commentsRatings = this.contentItems.filter(
                (item) => item.type === ContentItemType.CommentsRating,
            );
            commentsRatings.forEach((item) => {
                if (!item.targetTextInputs?.targetInputs?.length) {
                    const errorMessage = `<strong>No target message defined for</strong> '${sanitize(
                        item.title,
                    )}.'`;

                    (
                        errorMessages['commentsRating'] || (errorMessages['commentsRating'] = [])
                    ).push(errorMessage);
                }
            });
        }

        if (
            !getConvoSettings(this.type)?.convoAttributes?.categories?.disabled &&
            !this.categories.some((item) => item.isPrimary)
        ) {
            (errorMessages['categories'] || (errorMessages['categories'] = [])).push(
                getErrorMessage('primaryCategoryIsRequired'),
            );
        }

        if (errors.length > 0) {
            errors.forEach((item) => {
                errorMessages[item.property] = Object.values(item.constraints || {});
            });
        }

        if (warnings.length > 0) {
            warnings.forEach((item) => {
                warningMessages[item.property] = Object.values(item.constraints || {});
            });
        }

        return { errorMessages, warningMessages };
    }
}
