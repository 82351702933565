<template>
    <div class="registration">
        <Input
            :id="generateId('regTitle', path)"
            type="text"
            label="Title"
            :name="generateId('regTitle', path)"
            :max="80"
            show-remaining-char
            :value="data.regTitle"
            :errors="data.errors.regTitle"
            @input="(value) => onInput('regTitle', value)"
            @changed="onChanged"
        />
        <Input
            :id="generateId('regSubtitle', path)"
            type="text"
            label="Subtitle"
            :name="generateId('regSubtitle', path)"
            :max="80"
            show-remaining-char
            :value="data.regSubtitle"
            :errors="data.errors.regSubtitle"
            @input="(value) => onInput('regSubtitle', value)"
            @changed="onChanged"
        />
        <ImageUploaderButton
            input-style="green"
            :button-label="data.image || 'Click here to attach an image'"
            :value="data.image"
            :errors="data.errors.image"
            class="image-upload"
            @input="(value) => onInput('image', value)"
            @changed="onChanged"
        />

        <div class="registration-button">
            <div class="registration-button__wrapper">
                <div class="registration-marker">1</div>
                <Input
                    :id="generateId('acceptButtonLabel', path)"
                    type="text"
                    label="Button Label"
                    :name="generateId('acceptButtonLabel', path)"
                    :value="data.acceptButtonLabel"
                    :errors="data.errors.acceptButtonLabel"
                    class="registration-button__input"
                    @input="(value) => onInput('acceptButtonLabel', value)"
                    @changed="onChanged"
                />
            </div>
            <Input
                :id="generateId('acceptButtonType', path)"
                type="text"
                label="Answer type"
                :name="generateId('acceptButtonType', path)"
                :disabled="true"
                :value="data.acceptButtonAnswerType"
                class="registration-button__answer-type"
            />
            <Input
                :id="generateId('acceptButtonLink', path)"
                type="text"
                label="Link"
                :name="generateId('acceptButtonLink', path)"
                :disabled="true"
                :value="data.acceptButtonLink"
                class="registration-button__answer-type"
            />
        </div>
        <div class="registration-button">
            <div class="registration-button__wrapper">
                <div class="registration-marker">2</div>
                <Input
                    :id="generateId('continueButtonLabel', path)"
                    type="text"
                    label="Button Label"
                    :name="generateId('continueButtonLabel', path)"
                    :value="data.continueButtonLabel"
                    :errors="data.errors.continueButtonLabel"
                    class="registration-button__input"
                    @input="(value) => onInput('continueButtonLabel', value)"
                    @changed="onChanged"
                />
            </div>
            <Input
                :id="generateId('continueButtonType', path)"
                type="text"
                label="Answer type"
                :name="generateId('continueButtonType', path)"
                :disabled="true"
                :value="data.continueButtonAnwserType"
                class="registration-button__answer-type"
            />
        </div>
    </div>
</template>

<script>
import Input from '@/components/forms/Input';
import ImageUploaderButton from '@/components/forms/ImageUploaderButton';
import itemFormMixin from '@/mixins/itemFormMixin';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum.ts';

export default {
    name: 'Registration',
    components: {
        Input,
        ImageUploaderButton,
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        prefix: {
            type: String,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {},
};
</script>
<style lang="scss" scoped>
.registration {
    &-button {
        &__wrapper {
            display: flex;
        }
        &__input {
            flex: 1 1 auto;
        }
        &__answer-type {
            margin-left: 34px;
        }
    }

    &-marker {
        width: 24px;
        height: 24px;
        margin: 10px 10px 0 0;
        border-radius: 12px;
        background-color: $button-wrapper-marker-bg-color;
        font-size: 12px;
        font-weight: $font-weight-bold;
        color: $button-wrapper-marker-text-color;
        text-align: center;
        line-height: 24px;
        white-space: nowrap;
    }

    .image-upload {
        margin-bottom: 10px;
    }
}
</style>
