import { IDictionary } from '@inconvo/types/interfaces';
import { LinkTarget, RegistrationType } from '@/store/enums';

export class UpdateTenantRequest {
    id: string;
    name: string;
    appKey?: string | null;
    appSecret?: string | null;
    allowOrigins?: string;
    channels?: string[] | null;
    applications?: UpdateTenantRequestApplication[];
}

export class UpdateTenantRequestApplication {
    id: string;
    code: string;
    name: string;
    authEnabled: boolean;
    signupType: string;
    skipAuthForKnownUsers: boolean;
    allowNoSignup: boolean;
    fingerprintTrackingEnabled: boolean;
    maxFreeConvos: number;
    tenantId: string;
    registrationType: RegistrationType;
    registrationOptions: IUpdateTenantRequestApplicationRegistrationOptions;
    linkOptions: IUpdateTenantRequestApplicationLinkOptions;
    options: IUpdateTenantRequestApplicationOptions;
}

export interface IUpdateTenantRequestApplicationRegistrationOptions {
    subscribeMessage: string;
    subscribeButtonText: string;
    conditionsText?: string | null;
    inputText?: string | null;
}

export interface IUpdateTenantRequestApplicationOptions {
    signup: IUpdateTenantRequestApplicationSignup;
    comments: IUpdateTenantRequestApplicationComments;
    [key: string]:
        | IDictionary
        | IUpdateTenantRequestApplicationSignup
        | IUpdateTenantRequestApplicationComments;
}

export interface IUpdateTenantRequestApplicationSignup {
    message: string;
    defaultSubscribeSelection: boolean;
}

export interface IUpdateTenantRequestApplicationComments {
    requireAuthenticationToLoad: boolean;
}

export interface IUpdateTenantRequestApplicationLinkOptions {
    target: LinkTarget;
}

export type UpdateTenantChannelRequest = {
    channelId: number;
    channelCode: string;
    isFeatured: boolean;
};
