import { render, staticRenderFns } from "./TextAreaInput.vue?vue&type=template&id=199de351&scoped=true&"
import script from "./TextAreaInput.vue?vue&type=script&lang=js&"
export * from "./TextAreaInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "199de351",
  null
  
)

export default component.exports