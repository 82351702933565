import axios from '@/utils/request';
import { LoadingFlag } from '@/store/enums';
import {
    GetUserInputRequest,
    InputTextReport,
    GetReportBreakRequest,
    GetReportRequest,
} from '@/store/models/dtos/reportRequest.dto';
import { ReportBreakDto } from '@/store/models/dtos/reportBreak.dto';
import { IConvoReport } from '@/store/models/interfaces';
import { router } from '@/router';

export class ReportClient {
    public static async getReport(request: GetReportRequest): Promise<IConvoReport> {
        const url = `/reports${router.currentRoute.query?.useV2 === 'true' ? '/v2' : ''}`;
        const result = await axios
            .request<any>({
                url,
                method: 'GET',
                params: request,
                errorMessage: 'Could not get convo report',
                loadingId: LoadingFlag.ReportGet,
            })
            .then((o) => o);

        return result as unknown as IConvoReport;
    }

    public static async getReportBreakInParallel(
        requestData: GetReportBreakRequest[],
    ): Promise<ReportBreakDto[]> {
        const axiosPromise = [];
        for (const data of requestData) {
            axiosPromise.push(
                axios.request<any>({
                    url: `/reports/break/content-items${
                        router.currentRoute.query?.useV2 === 'true' ? '/v2' : ''
                    }`,
                    data,
                    method: 'POST',
                    errorMessage: 'Could not get report break',
                }),
            );
        }

        const reportBreakResponse = await Promise.all(axiosPromise);
        return reportBreakResponse as unknown as ReportBreakDto[];
    }

    public static async getRawReportData(convoId: string): Promise<any> {
        const url = `/reports/raw?convoId=${convoId}`;

        const result = await axios.request<any>({
            url,
            method: 'GET',
            errorMessage: 'Could not get convo report',
            loadingId: LoadingFlag.ReportGetRaw,
        });

        return result as unknown as Promise<any>;
    }

    public static async downloadReport(objKey: string) {
        const url = `/reports/download?key=${objKey}`;
        const result = await axios.request<any>({
            url,
            method: 'GET',
            errorMessage: 'Could not get convo report',
            loadingId: LoadingFlag.ReportGetRaw,
            responseType: 'blob',
        });

        return result as unknown as Promise<any>;
    }

    public static async getReportText(options: GetUserInputRequest): Promise<InputTextReport> {
        const url = '/reports/text';

        const result = await axios.request<any>({
            url,
            method: 'GET',
            errorMessage: 'Could not get convo report',
            loadingId: LoadingFlag.ReportGetText,
            params: {
                id: options.id,
                page: options.page || 1,
                size: options.size || 100,
            },
        });

        return result as unknown as Promise<any>;
    }
}
