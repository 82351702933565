<template>
    <div v-show="isVisible" class="list">
        <div class="title">Select a variable below</div>
        <ul>
            <li
                v-for="(item, index) in itemsList"
                :key="index"
                :class="{
                    selected: selectedItemIndex === item.index,
                    'group-header': item.header === true,
                }"
                @click="onClick(item.index)"
            >
                {{ item.displayText }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'List',
    components: {},
    props: {
        data: {
            type: Array,
            required: true,
        },
        text: {
            type: String,
            default: '',
        },
        selectedIndex: {
            type: Number,
            default: 0,
        },
        isVisible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            innerValue: this.text,
            selectedItemIndex: -1,
        };
    },
    computed: {
        itemsList() {
            const items = [];

            let index = 0;

            for (const group of this.data) {
                items.push({ displayText: group.name, header: true });

                const filtered = group.items.filter((item) => {
                    return item.displayText.toLowerCase().indexOf(this.text) >= 0;
                });

                for (const item of filtered) {
                    items.push({
                        displayText: item.displayText,
                        value: item.value,
                        index: index++,
                        key: item.key,
                    });
                }
            }

            this.$emit('listChanged', items);

            return items;
        },
    },
    watch: {
        selectedIndex(newValue, oldValue) {
            this.selectedItemIndex = newValue;

            this.$nextTick(() => {
                const selectedElement = document.querySelector('.list .selected');
                if (selectedElement) {
                    const listElement = document.querySelector('.list ul');
                    const listElementRect = listElement.getBoundingClientRect();
                    const selectedElementRect = selectedElement.getBoundingClientRect();
                    const vertInView =
                        selectedElementRect.top + selectedElementRect.height <=
                            listElementRect.top + listElementRect.height &&
                        selectedElementRect.top >= listElementRect.top;
                    const elHeight = selectedElement.clientHeight;
                    const elOffset = elHeight * newValue;
                    if (!vertInView) {
                        listElement.scrollTop = elOffset;
                    }
                }
            });

            this.$emit('selectedIndexChanged', this.getSelectedElement());
        },
    },
    methods: {
        getSelectedElement() {
            return this.itemsList.find((item) => item.index === this.selectedItemIndex);
        },
        onClick(value) {
            if (value >= 0) {
                this.selectedItemIndex = value;
                this.$emit('select', this.selectedItemIndex);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.list {
    min-width: 200px;
    border-radius: 1px;
    padding: 10px;
    background-color: $select-background-color;
    border: 1px solid $select-alternate-border-color;

    .title {
        padding: 10px 15px;
        color: $default-text-color;
        font-weight: $font-weight-light;
        font-size: $font-size-md;
    }

    ul {
        max-height: 200px;
        overflow-y: scroll;

        li {
            padding: 5px 25px;
            color: $select-alternate-text-color;
            font-size: $font-size-sm;
            font-weight: $font-weight-light;
            cursor: default;

            &.selected,
            &:hover {
                background: $select-option-hover-background-color;

                &.group-header {
                    background: transparent;
                }
            }

            &.group-header {
                padding-left: 15px;
                color: $select-header-text-color;
                text-transform: uppercase;
            }
        }
    }

    .footer {
        border-top: 1px solid $select-alternate-border-color;
    }
}
</style>
