<template>
    <div class="attribute-button" :disabled="disabled" @click="$emit('click', $event)">
        <img v-if="image" :src="image" alt="" class="attribute-button__image" />

        <div class="attribute-button__body">
            <Icon v-if="icon" class="attribute-button__icon" :name="icon" />
            <slot />
        </div>

        <button
            v-if="showRemoveButton"
            class="attribute-button__remove-button"
            @click.stop="$emit('remove', $event)"
        >
            <Icon class="attribute-button__remove-icon" name="cross-simple" />
        </button>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    name: 'AttributeButton',
    components: {
        Icon,
    },
    props: {
        icon: {
            type: String,
            default: undefined,
        },
        image: {
            type: String,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showRemoveButton: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.attribute-button {
    display: inline-flex;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    height: 30px;
    border-radius: 4px;
    border: solid 1px $attribute-button-border-color;
    background-color: $attribute-button-bg-color;
    font-size: 12px;
    white-space: nowrap;
    cursor: pointer;

    &__body {
        display: flex;
        align-items: center;
        padding: 7px 30px 7px 10px;
    }

    &[disabled] {
        opacity: 0.4;
        cursor: default;
    }

    // &:not([disabled]) {
    //     &:hover {
    //     }
    // }

    &__icon {
        flex: 0 0 auto;
        margin-right: 8px;
        font-size: 15px;
        color: $attribute-button-icon-color;
    }

    &__remove-button {
        cursor: pointer;
    }

    &__remove-icon {
        position: absolute;
        @include center(y);
        right: 10px;
        font-size: 9px;
        color: $attribute-button-icon-color;
    }

    &__image {
        height: 30px;
        width: 30px;
        border-right: 1px solid $attribute-button-border-color;
        object-fit: cover;
    }
}
</style>
