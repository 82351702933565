<template>
    <DragAndDrop
        v-model="dragAndDropItems"
        :is-draggable="isDraggable"
        handle=".drag-and-drop-handle"
        @onDragStart="onDragStart"
        @onDragEnd="onDragEnd"
    >
        <slot />
    </DragAndDrop>
</template>

<script>
import DragAndDrop from '@/components/DragAndDrop';

export default {
    name: 'AccordionNew',
    components: {
        DragAndDrop,
    },
    props: {
        isDraggable: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            default: undefined,
        },
        path: {
            type: Array,
            default: () => [],
        },
        startIndex: {
            type: Number,
            default: 1,
        },
        areMessageItemsValid: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            drag: false,
        };
    },
    computed: {
        dragAndDropItems: {
            get() {
                return this.items;
            },
            set(value) {
                this.$emit('onDrop', this.path, value);
            },
        },
    },
    methods: {
        onDragStart() {
            this.drag = true;
        },
        onDragEnd() {
            setTimeout(() => {
                this.drag = false;
            }, 0);
        },
    },
};
</script>
