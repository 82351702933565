import { IContentItem } from '../interfaces';
import { AttachmentUrl, ContentItem } from '..';
import { ContentItemType } from '@/store/enums/';
import { IAnswer } from '../interfaces/answer.interface.dto';
import { Answer } from '../answer.dto';
import { IConvoState } from '@/store/modules/convo-details';

export class AttachmentUrlContentItemFactory {
    make(obj: AttachmentUrl, state: IConvoState): IContentItem {
        const contentItem = new ContentItem();
        contentItem.id = obj.id;
        contentItem.sort = obj.sort;
        contentItem.attachment = obj.url;
        contentItem.tags = obj.tags || [];
        contentItem.type = ContentItemType.AttachmentUrl;

        contentItem.answers = [];

        const answer: IAnswer = new Answer();
        answer.convoId = state.convo.id;

        answer.targetChannel = obj.logicJump?.items?.channel?.code || '';
        answer.targetConvo = obj.logicJump?.items?.convo?.id || 0;
        answer.targetContentItem = obj.logicJump?.items?.message?.id || 0;
        answer.goToBlock = !answer.targetContentItem && answer.targetConvo ? answer.targetConvo : 0;
        answer.goToWidget =
            answer.targetConvo && answer.targetContentItem ? answer.targetContentItem : 0;

        contentItem.answers.push(answer);

        return contentItem;
    }
}
