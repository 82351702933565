import _Vue from 'vue';
import ToasterComponent from './Toaster.vue';
import { ToastOptions } from './toast-options.model';

export function Toaster(Vue: typeof _Vue, options?: ToastOptions): void {
    const comp = Vue.extend({
        render: (h) => {
            return h(ToasterComponent, {
                props: {
                    options: options,
                },
                ref: 'toaster',
            });
        },
    });

    const component = new comp().$mount();

    document.body.appendChild(component.$el);

    Vue.prototype.$toaster = component.$refs.toaster;
}
