<template>
    <ImageUploaderButton
        input-style="green"
        :button-label="(data.image && data.image.url) || 'Click here to attach an image'"
        :value="data.image && data.image.url"
        :errors="data.errors && data.errors.url"
        @input-image="onInput('image', $event)"
        @changed="onChanged"
    />
</template>

<script>
import itemFormMixin from '@/mixins/itemFormMixin';
import ImageUploaderButton from '@/components/forms/ImageUploaderButton';

export default {
    name: 'ImageMessageItem',
    components: {
        ImageUploaderButton,
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        currentChannel: {
            type: Object,
            default: undefined,
        },
        currentConvo: {
            type: Object,
            default: undefined,
        },
    },
};
</script>
