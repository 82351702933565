import { AccordionStyle, ContentItemType, ContentType } from '@/store/enums';
import { IContentItem } from '@/store/models/interfaces/contentItem.interface';
import { sanitize } from '@/utils/sanitizer';
import {
    AccordionItemBase,
    AttachmentUrl,
    Buttons,
    Carousel,
    CommentsRating,
    DatePicker,
    QuickReply,
    Registration,
    Result,
    TextInput,
    TextStatement,
    Video,
    Gif,
    Rating,
    Image,
    LogicJump,
    InlineResponseTextStatement,
} from '..';
import { IContentOption } from '../interfaces';
import { IAnswer } from '../interfaces/answer.interface.dto';
import { IButton } from '../interfaces/button.interface';
import { IQuizContentItem } from '../interfaces/quizContentItem.interface';
import { IQuizOptions } from '../interfaces/quizOptions.interface';
import { YougovWelcomeSurveyButtons } from '../ygWelcomeSurvey';

export class AccordionItemsFactory {
    public make(contentItems: IContentItem[]): AccordionItemBase[] {
        const accordianItems: AccordionItemBase[] = [];

        for (const item of contentItems) {
            const map: { [key: string]: any } = {
                [ContentItemType.Statement]: TextStatement,
                [ContentItemType.QuickReply]: QuickReply,
                [ContentItemType.TextInput]: TextInput,
                [ContentItemType.AttachmentUrl]: AttachmentUrl,
                [ContentItemType.Buttons]: Buttons,
                [ContentItemType.VerticalCarousel]: Carousel,
                [ContentItemType.HorizontalCarousel]: Carousel,
                [ContentItemType.MatrixCarousel]: Carousel,
                [ContentItemType.PersonalityQuizResult]: Result,
                [ContentItemType.TriviaQuizResult]: Result,
                [ContentItemType.Registration]: Registration,
                [ContentItemType.YougovRegistration]: Registration,
                [ContentItemType.DatePicker]: DatePicker,
                [ContentItemType.Video]: Video,
                [ContentItemType.CommentsRating]: CommentsRating,
                [ContentItemType.Gif]: Gif,
                [ContentItemType.Image]: Image,
                [ContentItemType.Rating]: Rating,
                [ContentItemType.YougovWelcomeSurveyButtons]: YougovWelcomeSurveyButtons,
            };

            const messageFactory = map[item.type];
            if (messageFactory) {
                const accordionItem = new messageFactory().toAccordionItem(item);
                accordianItems.push(accordionItem);
            }
        }

        return accordianItems;
    }
}

export const makeQuizAttributes = (
    answer: IAnswer | IButton,
): Pick<IQuizContentItem, 'ignoreQuizQuestion'> => ({
    ignoreQuizQuestion: answer.ignoreQuizAnswer || answer.quizResultItem === -1,
});

export const makeQuizOptionAttributes = (answer: IAnswer | IButton): IQuizOptions => ({
    selectedPersonalityQuizResult: answer.quizResultItem !== -1 ? answer.quizResultItem : undefined,
    isCorrectAnswer: !!answer.isCorrectAnswer,
});

export const makeLogicJump = (answer: IAnswer | IButton): LogicJump => {
    let channel: IContentOption | undefined;
    let convo: IContentOption | undefined;
    let message: IContentOption | undefined;

    if (answer.targetChannel) {
        channel = {
            type: ContentType.Channel,
            code: answer.targetChannel,
            text: '',
        };
    }

    if (answer.targetConvo || (!channel && answer.goToBlock)) {
        convo = {
            type: ContentType.Convo,
            id: answer.targetConvo || answer.goToBlock,
            text: '',
        };
    }

    if (answer.targetContentItem || (!channel && !convo && answer.goToWidget)) {
        message = {
            type: ContentType.Message,
            id: answer.targetContentItem || answer.goToWidget,
            text: '',
        };
    }

    return new LogicJump({ channel, convo, message });
};

export const makeAnswerReply = (reply: string): InlineResponseTextStatement => {
    const inlineResponse = new InlineResponseTextStatement();
    inlineResponse.statement = reply;
    inlineResponse.title = 'Statement (Text)';
    inlineResponse.subtitle = sanitize(reply);
    inlineResponse.accordionStyle = AccordionStyle.Panel;
    inlineResponse.hasMarginBottom = false;
    return inlineResponse;
};
