import { IContentItem } from '../interfaces';
import { TextStatement } from '..';
import { sanitize } from '@/utils/sanitizer';
import { makeLogicJump } from './accordionItems.factory';

export class TextStatementAccordionItemFactory {
    public make(contentItem: IContentItem): TextStatement {
        const textStatmentItem = new TextStatement();
        const answer = contentItem.answers && contentItem.answers[0];
        const plainTextStatement = sanitize(contentItem.title);

        textStatmentItem.title = 'Statement (Text)';
        textStatmentItem.subtitle = plainTextStatement;
        textStatmentItem.plainText = plainTextStatement;
        textStatmentItem.statement = contentItem.title;
        textStatmentItem.id = contentItem.id || 0;
        textStatmentItem.sort = contentItem.sort;
        textStatmentItem.tags = contentItem.tags || [];
        textStatmentItem.isPdl = !!contentItem.isPdl;

        if (answer) {
            textStatmentItem.logicJump = makeLogicJump(answer);
        }

        return textStatmentItem;
    }
}
