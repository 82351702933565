<script>
import { TagClient } from '@/api/tag.ts';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'TagsMixin',
    computed: {
        ...mapGetters('convoDetails', {
            availableTags: 'availableTags',
        }),
    },
    methods: {
        ...mapActions('convoDetails', {
            setTags: 'setTags',
            setSelectedTags: 'setSelectedTags',
        }),
        async onTagsInput(tags, type) {
            let hasNewTags = false;
            const tagsToSave = [];
            const tagClient = new TagClient();
            const existingTags = this.availableTags(type);

            for (const tag of tags) {
                const existingTag = existingTags.find((t) => t.name === tag.name);
                if (existingTag) {
                    tagsToSave.push(existingTag);
                } else {
                    hasNewTags = true;
                    const createdTag = await tagClient.create(tag.name, type);
                    tagsToSave.push(createdTag);
                }
            }

            this.setSelectedTags({ tags: tagsToSave, type });

            if (hasNewTags) {
                this.setTags({ type });
            }
        },
    },
};
</script>
