<template>
    <div
        v-click-outside="clickOutside"
        class="form-group select"
        :class="[
            {
                'form-group--has-m-b': hasMarginBottom,
                'select--is-open': isOpen,
            },
        ]"
    >
        <div
            class="form-field"
            :class="{
                'form-field--is-focused': isOpen,
                'form-field--has-error': errors.length > 0,
                'form-field--has-clear-icon': showClearIcon,
                'form-field--is-disabled': disabled,
                'form-field--has-bottom-space': hasMarginBottom,
            }"
            @click="toggleOptions"
        >
            <div class="form-field-label" :class="{ 'form-field-label--hidden': hideLabel }">
                <slot name="label">
                    <span>{{ label }}</span>
                </slot>
            </div>
            <div class="form-field-input">
                <span v-if="selectedOption">
                    <span
                        v-if="hasIcon"
                        class="form-field-input__icon"
                        :class="selectedOption.iconCss"
                    >
                    </span>
                    {{ selectedOption[nameKey] }}
                </span>

                <span v-else-if="placeholder" class="form-field-placeholder">
                    {{ placeholder }}
                </span>
            </div>
            <button v-if="showClearIcon" class="form-field-icon" @click="clearValue">
                <Icon v-if="innerValue" name="cross" :size="14" />
            </button>
            <Spinner v-if="isLoading" class="spinner" />
            <Icon v-else class="arrow" name="arrow-down" :size="12" />
        </div>
        <div v-if="showAdditionalInfo" class="form-field-additional-information">
            <div class="form-field-additional-information__left">
                <div v-if="errors && errors.length > 0" class="form-field-error-message">
                    <Icon name="alert" />{{ errors[0] }}
                </div>
                <div v-if="infoText" class="form-field-info-text">{{ infoText }}</div>
            </div>
        </div>
        <div class="options" :class="{ open: isOpen }">
            <div
                v-for="option of options"
                :key="option[idKey]"
                v-tooltip.top="option.disabled ? `${option.disabledText}` : ''"
                class="option"
                :class="{ selected: option[idKey] === value, disabled: option.disabled }"
                @click="selectOption($event, option.disabled, option[idKey])"
            >
                <slot v-if="hasSlotEnabled" name="select" :option="option" />
                <span v-else>{{ option[nameKey] }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import Spinner from '@/components/ui/Spinner';

export default {
    name: 'Select',
    components: {
        Icon,
        Spinner,
    },
    props: {
        options: {
            type: Array,
            required: true,
        },
        value: {
            type: [Number, String],
            default: null,
        },
        label: {
            type: String,
            default: '',
        },
        errors: {
            type: Array,
            default: () => [],
        },
        hasMarginBottom: {
            type: Boolean,
            default: true,
        },
        showClearIcon: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
        hasIcon: {
            type: Boolean,
            default: false,
        },
        infoText: {
            type: String,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        nameKey: {
            type: String,
            default: 'value',
        },
        idKey: {
            type: String,
            default: 'id',
        },
    },
    data() {
        return {
            isOpen: false,
            innerValue: this.value,
        };
    },
    computed: {
        selectedOption() {
            return this.options.find((o) => o[this.idKey] === this.innerValue);
        },
        hasOptionSelected() {
            return this.innerValue || this.innerValue === 0 || this.innerValue > 0;
        },
        showAdditionalInfo() {
            return (this.errors && this.errors.length > 0) || this.infoText;
        },
        hasSlotEnabled() {
            return this.$scopedSlots.select;
        },
    },
    watch: {
        value(newValue) {
            this.innerValue = newValue;
            this.$nextTick(() => this.$emit('changed'));
        },
    },
    methods: {
        clickOutside() {
            if (this.isOpen) {
                this.toggleOptions();
            }
        },
        toggleOptions() {
            if (!this.disabled) {
                this.isOpen = !this.isOpen;
            }
        },
        clearValue(event) {
            this.innerValue = null;
            this.$emit('input', this.innerValue);
            event.stopPropagation();
        },
        selectOption(e, isDisabled, value) {
            if (isDisabled) {
                e.stopPropagation();
                e.preventDefault();
            } else {
                this.innerValue = value;
                this.$emit('input', value);
                this.toggleOptions();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.form-group {
    &--has-m-b {
        margin-bottom: $form-group-margin-bottom;
    }
}

.form-field--is-disabled {
    .form-field-input,
    .arrow {
        opacity: 0.5;
    }
}

.select {
    position: relative;

    .arrow,
    .spinner {
        color: $select-arrow-color;
        position: absolute;
        top: 50%;
        right: 15px;
    }

    .arrow {
        margin-top: -6px;
    }

    .spinner {
        margin-top: -7px;
        width: 15px;
        height: 15px;
    }

    .form-field-input {
        overflow: hidden;
        padding-right: 42px;
        text-overflow: ellipsis;
        white-space: nowrap;

        &__icon {
            margin-right: 9px;
        }
    }

    .form-field-icon {
        right: 35px;
    }

    &--is-open {
        .arrow {
            transform: rotate(180deg);
        }
    }
}

.options {
    font-size: $font-size-sm;
    display: none;
    position: absolute;
    top: $form-input-height;
    left: 0;
    padding: 9px 6px;
    background: $select-background-color;
    border-radius: 0 0 4px 4px;
    border: 1px solid $select-border-color;
    border-top: none;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: $layer-10;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);

    &.open {
        display: block;
    }

    .option {
        min-height: 34px;
        padding: 9px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;

        &:hover {
            background: $select-option-hover-background-color;
        }

        &.selected {
            background: $select-option-selected-background-color;
        }

        &.disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }
    }
}
</style>
