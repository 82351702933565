import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum';
import { getErrorMessage } from '@/store/services/validationService';
import { IsNotEmpty, Length, validate as validateClass, ValidationError } from 'class-validator';
import hash from 'object-hash';
import { LogicJump, RatingOption } from '.';
import { IConvoState } from '../modules/convo-details';
import { AccordionItemBase } from './accordionItemBase';
import { RatingAccordionItemFactory } from './factories/ratingAccordionItem.factory';
import { RatingContentItemFactory } from './factories/ratingContentItem.factory';
import { IContentItem, IContentItemConvertible, IRichText } from './interfaces';
import { IObservableItem } from './interfaces/observableItem.interface';
import { Attributes } from '../enums/attributes.enum';
import { IRatingTarget } from '@inconvo/types/interfaces';

const defaultRatingOptions = [
    '🚫 Not interested at all',
    '⭐ Slightly interested',
    '🌟 Very interested',
    '✨ One of my top interests!',
];

export class Rating
    extends AccordionItemBase
    implements IContentItemConvertible, IObservableItem<IConvoState>, IRichText
{
    constructor(items = []) {
        super(items);

        this.type = ConvoMessageItemType.Rating;
        this.plainText = '';
        this.statement = '';
        this.icon = 'thumb-up';
        this.maxItems = 10;
        this.ratingGap = 0;
        this.isDraggable = true;
        this.isDeletable = true;
        this.logicJump = new LogicJump();
        this.attributes = [];

        // set default rating options
        const ratingOptions: RatingOption[] = [];
        for (const [index, label] of defaultRatingOptions.entries()) {
            const ratingOption = new RatingOption();
            ratingOption.title = label;
            ratingOption.sort = index;
            ratingOption.rating = index + this.ratingGap;
            ratingOptions.push(ratingOption);
        }

        this.items = ratingOptions;
    }

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Message') })
    @Length(0, 640, { message: getErrorMessage('length', 'Message', { min: 0, max: 640 }) })
    plainText: string;
    statement: string;
    ratingTarget?: IRatingTarget;
    maxItems: number;
    ratingGap: number;
    attributes: Attributes[];

    setTitle(): void {
        this.subtitle = this.plainText;
    }

    addItem(item: AccordionItemBase): void {
        item.rating = this.items.length + this.ratingGap;
        this.items.push(item);
    }

    toContentItem(state: IConvoState): IContentItem {
        const contentItemFactory = new RatingContentItemFactory();
        return contentItemFactory.make(this, state);
    }

    itemHasChanges(state: IConvoState): boolean {
        const contentItemFactory = new RatingContentItemFactory();
        const contentItem = contentItemFactory.make(this, state);
        contentItem.version = hash(contentItem);

        if (this.originalVersion) {
            return contentItem.version !== this.originalVersion;
        }
        return false;
    }

    toAccordionItem(contentItem: IContentItem, isClone?: boolean): AccordionItemBase {
        const accordionItemFactory = new RatingAccordionItemFactory();
        return accordionItemFactory.make(contentItem, isClone);
    }

    async validate(state: IConvoState, path: number[]): Promise<any[]> {
        const errors: ValidationError[] = await validateClass(this);
        const errorMessages: any[] = [];
        const mainLevelErrors: any = {};

        if (errors.length > 0) {
            errors.forEach((item: ValidationError) => {
                mainLevelErrors[item.property] = Object.values(item.constraints || {});
            });
        }
        if (this.items.length === 0 || this.items.length > this.maxItems) {
            mainLevelErrors.ratingOptions = [];
            mainLevelErrors.ratingOptions.push(
                getErrorMessage('buttonsLength', 'Rating', { min: 1, max: this.maxItems }),
            );
        }

        if (Object.entries(mainLevelErrors).length > 0) {
            errorMessages.push({ path, errors: mainLevelErrors });
        }

        for (let i = 0; i < this.items.length; i++) {
            const option = this.items[i] as RatingOption;
            const childrenErrors: any[] = await option.validate(state, [...path, i]);

            if (Object.entries(childrenErrors).length > 0) {
                errorMessages.push(...childrenErrors);
            }
        }
        return errorMessages;
    }
}
