import { IContentItem } from '../interfaces';
import { Image } from '..';

export class ImageAccordionItemFactory {
    public make(contentItem: IContentItem): Image {
        const imageItem = new Image();

        imageItem.image = contentItem.attachment && JSON.parse(contentItem.attachment);
        imageItem.id = contentItem.id || 0;
        imageItem.sort = contentItem.sort;
        imageItem.tags = contentItem.tags;
        imageItem.title = 'Image';
        imageItem.setTitle();

        return imageItem;
    }
}
