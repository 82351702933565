import { ReportBreakDto } from '../dtos/reportBreak.dto';
import { ReportBreak } from '../reportBreak';

export class ReportBreakFactory {
    public static make(dto: ReportBreakDto): ReportBreak {
        const reportBreak = new ReportBreak();

        reportBreak.title = dto.title;
        reportBreak.answers = dto.answers;
        reportBreak.cells = dto.cells;

        return reportBreak;
    }
}
