import request from '@/utils/request';
import { LoadingFlag } from '@/store/enums';

export const getBroadcasts = async () => {
    return await request({
        url: '/messaging/broadcasts/',
        method: 'GET',
        errorMessage: 'Could not get broadcasts',
        loadingId: LoadingFlag.BroadcastGet,
    });
};
