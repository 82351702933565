import { IConvoType } from './interfaces/convoType.interface';
import { ConvoMessageItemType } from '../enums';
import { IConvoAttributes } from './interfaces';

export class ConvoType implements IConvoType {
    constructor(convoType: IConvoType) {
        this.name = convoType.name;
        this.title = convoType.title;
        this.icon = convoType.icon;
        this.availableMessageTypes = convoType.availableMessageTypes;
        this.defaultMessageTypes = convoType.defaultMessageTypes;

        this.hidden = !!convoType.hidden;
        this.availableInMessageLibrary = !!convoType.availableInMessageLibrary;
        this.isGlobal = !!convoType.isGlobal;
        this.convoAttributes = convoType.convoAttributes;
    }

    name: string;
    title: string;
    icon: string;
    availableMessageTypes?: ConvoMessageItemType[];
    defaultMessageTypes?: ConvoMessageItemType[];
    hidden: boolean = false;
    availableInMessageLibrary: boolean = false;
    isGlobal: boolean = false;
    convoAttributes?: IConvoAttributes;
}
