import messageTypes from '@/json/messageTypes';
import { AccordionItemBase } from './accordionItemBase';
import { MessageType } from './messageType';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum.ts';
import { ConvoTypes } from '../enums';
import convoTypes from '@/json/convoTypes';
import { ConvoType } from './convoType';
import { getErrorMessage } from '@/store/services/validationService';
import { IConvoState } from '../modules/convo-details';

export class SelectMessageType extends AccordionItemBase {
    constructor(convoType: ConvoTypes) {
        super([]);
        this.title = 'Select the Message Type';
        this.type = ConvoMessageItemType.SelectMessageType;
        this.isDraggable = true;
        this.isDeletable = true;
        this.setOptions(convoType);
    }

    options: MessageType[];

    setOptions(type: ConvoTypes) {
        const convoType: ConvoType | undefined = convoTypes.find((c) => c.name === type);

        if (convoType?.availableMessageTypes?.length) {
            this.options = [];

            for (const messageType of messageTypes) {
                if (
                    convoType.availableMessageTypes.includes(
                        messageType.name.toString() as ConvoMessageItemType,
                    )
                ) {
                    this.options.push(messageType);
                }
            }
        } else {
            this.options = messageTypes.map(
                (messageType: MessageType) =>
                    new MessageType({
                        name: messageType.name,
                        title: messageType.title,
                        icon: messageType.icon,
                        metadata: messageType.metadata,
                    }),
            );
        }
    }

    async validate(state: IConvoState, path: number[]): Promise<any[]> {
        return [{ path, errors: { selectMessageType: [getErrorMessage('selectMessageType')] } }];
    }
}
