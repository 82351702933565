import { IContentItem } from '../interfaces';
import { Registration } from '..';

export class RegistrationAccordianItemFactory {
    public make(contentItem: IContentItem): Registration {
        const registration = new Registration();

        const answer = contentItem.answers && contentItem.answers[0];
        const registrationType = registration.type.split('_');
        registration.prefix = registrationType.length > 1 ? registrationType[1] : 'yg';
        registration.id = contentItem.id || 0;
        registration.sort = contentItem.sort;
        registration.title = 'Registration';

        if (answer) {
            registration.regTitle = answer.title || '';
            registration.regSubtitle = answer.subtitle || '';
            registration.image = answer.image || '';
            registration.acceptButtonLabel = '';
            registration.continueButtonLabel = '';
            registration.subtitle = answer.title || '';
            if (answer.buttons && answer.buttons.length) {
                registration.acceptButtonLabel = answer.buttons[0].text || '';
                registration.continueButtonLabel = answer.buttons[1]?.text || '';
            }
        }

        return registration;
    }
}
