import { ChannelGroupReportClient as ReportClient } from '@/api/channel-group-report';
import { Module, GetterTree, ActionTree, MutationTree } from 'vuex';
import Vue from 'vue';
import {
    GetChannelGroupReportListRequest,
    GetChannelGroupReportRequest,
    IChannelGroupReportDto,
    CreateChannelGroupReportRequest,
} from '../models/dtos/channelGroupReport.dto';
import { IPaging } from '@inconvo/types/interfaces';
import {
    IChannelGroupReportList,
    IChannelReportRow,
} from '../models/interfaces/channelGroupReport.interface';
import {
    SET_CHANNEL_GROUP_REPORT_LIST,
    SET_CHANNEL_GROUP_REPORT,
    SET_CHANNEL_GROUP_REPORT_ROWS,
    RESET_CHANNEL_GROUP_REPORT_STATE,
    UPDATE_CHANNEL_GROUP_REPORT,
    CLEAR_CHANNEL_REPORT_ERRORS,
    SET_CHANNEL_REPORT_ERRORS,
    SET_REPORT_HASH,
} from '../mutations.types';
import { IRootState } from '../types/rootState';
import { ChannelGroupReport } from '../models/channelGroupReport';
import { ChannelGroupReportFactory } from '../models/factories/channelGroupReport.factory';
import hash from 'object-hash';

export interface IChannelGroupReportState {
    reportList: IPaging<IChannelGroupReportList>;
    report: ChannelGroupReport;
    reportRows: IChannelReportRow[];
    errors: object;
    originalHash: string | null;
}

const getDefaultState = () => ({
    reportList: {} as IPaging<IChannelGroupReportList>,
    report: new ChannelGroupReport(),
    reportRows: [],
    errors: {},
    originalHash: null,
});

const state: IChannelGroupReportState = getDefaultState();

const getters: GetterTree<IChannelGroupReportState, IRootState> = {
    isValidReport: (state) => Object.keys(state.errors).length === 0,
    isNewReport: (state) => state.report.id === 0,
    isChangedReport: (state) => {
        try {
            return hash(state.report) !== state.originalHash;
        } catch (error) {
            return true;
        }
    },
};

const actions: ActionTree<IChannelGroupReportState, any> = {
    setReportName: ({ dispatch }, name: string) => {
        dispatch('setReportProperty', { key: 'name', value: name });
    },
    setReportTags: ({ dispatch }, tags: { id: number; name: string }[]) => {
        const tagNames = tags.map((tag) => tag.name);
        dispatch('setReportProperty', { key: 'tagConfig', value: tagNames });
        // Generate report data when tags are updated.
        dispatch('generateReportData');
    },
    setReportProperty: ({ commit }, data: { key: string; value: any }) => {
        commit(UPDATE_CHANNEL_GROUP_REPORT, data);
    },
    getChannelGroupReports: async ({ commit }, searchOptions) => {
        const request = new GetChannelGroupReportListRequest();
        request.q = (searchOptions && searchOptions.q) || '';
        request.page = (searchOptions && searchOptions.page) || 1;
        request.size = 15;

        const result: IPaging<IChannelGroupReportList> = await ReportClient.getReportsList(request);

        if (result?.items?.length) {
            result.items = result.items.map((item: IChannelGroupReportList) => {
                return { ...item, selectedTags: item?.tagConfig?.join(' | ') };
            });
        }

        commit(SET_CHANNEL_GROUP_REPORT_LIST, result || []);
    },
    generateReportData: async ({ commit, state }) => {
        const request = new GetChannelGroupReportRequest();
        request.tags = state.report.tagConfig;

        const reportRows = await ReportClient.getReportRowsByTags(request);
        commit(SET_CHANNEL_GROUP_REPORT_ROWS, reportRows);
    },
    setReportData: async ({ commit }, id: number) => {
        const result: IChannelGroupReportDto = await ReportClient.getReport(id);

        const report = ChannelGroupReportFactory.make(result);
        commit(SET_CHANNEL_GROUP_REPORT, report);
        commit(SET_REPORT_HASH, hash(report));

        commit(SET_CHANNEL_GROUP_REPORT_ROWS, result.reportData);
    },
    clearReportData: ({ commit }) => {
        commit(RESET_CHANNEL_GROUP_REPORT_STATE);
    },
    validateReport: async ({ state, commit }) => {
        const errors = await state.report.validate();
        commit(SET_CHANNEL_REPORT_ERRORS, errors);
    },
    saveReport: async ({ dispatch, getters }) => {
        if (!getters.isChangedReport) {
            return true;
        }
        await dispatch('validateReport');
        if (!getters.isValidReport) {
            return false;
        }
        if (getters.isNewReport) {
            await dispatch('createReport');
        } else {
            await dispatch('updateReport');
        }
        return true;
    },
    createReport: async ({ state }) => {
        const request: CreateChannelGroupReportRequest = {
            name: state.report.name,
            tagConfig: state.report.tagConfig,
            reportData: state.reportRows,
        };
        await ReportClient.createReport(request);
    },
    updateReport: async ({ state }) => {
        await ReportClient.updateReport(state.report);
    },
    updateReportById: async ({ state }, id: number) => {
        await ReportClient.updateReportById(id);
    },
    deleteReport: async ({}, id: number) => {
        await ReportClient.deleteReport(id);
    },
};

const mutations: MutationTree<IChannelGroupReportState> = {
    [SET_CHANNEL_GROUP_REPORT_LIST](state, reportList: IPaging<IChannelGroupReportList>): void {
        state.reportList = reportList;
    },
    [SET_CHANNEL_GROUP_REPORT](state, data: ChannelGroupReport): void {
        state.report = data;
    },
    [SET_CHANNEL_GROUP_REPORT_ROWS](state, payload: IChannelReportRow[]): void {
        state.reportRows = payload;
    },
    [UPDATE_CHANNEL_GROUP_REPORT](state, payload: { key: string; value: any }): void {
        Vue.set(state.report, payload.key, payload.value);
    },
    [RESET_CHANNEL_GROUP_REPORT_STATE](state): void {
        Object.assign(state, getDefaultState());
    },
    [SET_CHANNEL_REPORT_ERRORS](state, errors): void {
        state.errors = errors;
    },
    [CLEAR_CHANNEL_REPORT_ERRORS](state): void {
        state.errors = {};
    },
    [SET_REPORT_HASH](state, payload: string): void {
        state.originalHash = payload;
    },
};

export const channelGroupReport: Module<IChannelGroupReportState, IRootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
