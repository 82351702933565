import romanize from 'romanize';
import { ListMarker } from '@/store/enums/listMarker.enum.ts';

export const getMarker = (
    marker: undefined | number | string,
    markerType: ListMarker,
    startIndex: number,
    index: number,
): number | string => {
    if (marker) {
        return marker;
    } else if (markerType === ListMarker.None) {
        return '';
    }

    return getListMarker(markerType, startIndex + index);
};

export const getListMarker = (style: string, index: number) => {
    const alphabet: string[] = 'abcdefghijklmnopqrstuvwxyz'.split('');
    const alphabetLength = alphabet.length;

    const get = () => {
        switch (style) {
            case ListMarker.LowercaseLetter:
                return getLetter(index);
            case ListMarker.UppercaseLetter:
                return getLetter(index).toUpperCase();
            case ListMarker.LowercaseRomanNumeral:
                return romanize(index).toLowerCase();
            case ListMarker.UppercaseRomanNumeral:
                return romanize(index);
            default:
                return index.toString();
        }
    };

    const getLetter = (currentNumber: number) => {
        let markerArray: string[] = [];

        if (currentNumber > alphabetLength) {
            const addCharacter = (resultNumber: number) => {
                let result: number = Math.floor(resultNumber / alphabetLength);
                let rest: number = resultNumber % alphabetLength;

                if (rest === 0) {
                    result = result - 1;
                    rest = alphabetLength;
                }

                markerArray.unshift(alphabet[rest - 1]);

                if (result) {
                    addCharacter(result);
                }
            };

            addCharacter(currentNumber);
        } else {
            markerArray = [alphabet[currentNumber - 1]];
        }

        return markerArray.join('');
    };

    return get();
};
