<template>
    <div class="scrollable-message-wrapper">
        <div ref="scrollable" class="message-items-wrapper">
            <div
                ref="items"
                class="message-items"
                :style="{ transform: `translateX(${elementX}px)` }"
                @wheel="handleScroll"
                @touchstart="handleTouchstart"
                @touchend="handleTouchend"
                @touchmove="handleTouchmove"
                @mousedown="handleMousedown"
                @mouseup="handleMouseup"
                @mousemove="handleMousemove"
            >
                <slot />
            </div>
        </div>
        <transition name="fade">
            <button
                v-if="showLeftArrow"
                class="arrow-button arrow-button--left"
                :class="{ 'arrow-button--highlighted-message': highlightedMessage }"
                @click="leftOnClick"
            >
                <Icon name="circle_arrow-left" />
            </button>
        </transition>
        <transition name="fade">
            <button
                v-if="showRightArrow"
                class="arrow-button arrow-button--right"
                :class="{ 'arrow-button--highlighted-message': highlightedMessage }"
                @click="rightOnClick"
            >
                <Icon name="circle_arrow-right" />
            </button>
        </transition>
    </div>
</template>

<script>
import { TweenLite } from 'gsap/TweenLite';
import Icon from '@/components/ui/Icon';

const leftOffset = 36;
const nextClosestOffset = 30;
const startStickyValue = 15;
let touchStartX;
let startElementX = 0;
let tween;

export default {
    name: 'ScrollableMesageWrapper',
    components: { Icon },
    props: {
        items: {
            type: Array,
            required: true,
        },
        highlightedMessage: {
            type: Boolean,
            default: false,
        },
        animationDuration: {
            type: Number,
            default: 0.8,
        },
    },
    data() {
        return {
            showLeftArrow: false,
            showRightArrow: false,
            elementX: 0,
        };
    },
    watch: {
        items: {
            handler(newValue, oldValue) {
                if (newValue.length !== oldValue.length) {
                    this.elementX = 0;
                    this.$nextTick(this.arrowVisibilityController);
                }
            },
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.arrowVisibilityController();
            window.addEventListener('resize', this.handleResize);
        });
    },
    beforeDestroy() {
        this.killAnimation();
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        getClosestElementIndex(checkLast = true) {
            const children = this.$refs.items.children;
            let previousItemDistance;
            let closestIndex;

            const elementXAbsValue = Math.abs(this.elementX);

            if (elementXAbsValue < startStickyValue) {
                closestIndex = 0;
            } else if (
                checkLast &&
                this.$refs.items.offsetWidth -
                    this.$refs.scrollable.offsetWidth -
                    elementXAbsValue <=
                    0
            ) {
                closestIndex = children.length - 1;
            } else {
                for (let i = 0; i < children.length; i++) {
                    const item = children[i];
                    const distance = item.offsetLeft - leftOffset - elementXAbsValue;

                    if (
                        previousItemDistance === undefined ||
                        Math.abs(distance) < Math.abs(previousItemDistance)
                    ) {
                        closestIndex = i;
                    } else {
                        break;
                    }

                    previousItemDistance = distance;
                }
            }

            return closestIndex;
        },
        getClosestLeftElementIndex() {
            const children = this.$refs.items.children;
            const elementXAbsValue = Math.abs(this.elementX);
            let previousItemDistance;
            let closestIndex;

            for (let i = 0; i < children.length; i++) {
                const item = children[i];
                const distance = item.offsetLeft - leftOffset - elementXAbsValue;

                if (
                    previousItemDistance === undefined ||
                    (distance < 0 && Math.abs(distance) < Math.abs(previousItemDistance))
                ) {
                    closestIndex = i;
                } else {
                    break;
                }

                previousItemDistance = distance;
            }

            return closestIndex;
        },
        getMaxRight() {
            const { scrollable, items } = this.$refs;
            if (!scrollable || !items) {
                return 0;
            }
            const containerWidth = scrollable.offsetWidth;
            const elementWidth = items.offsetWidth;
            return elementWidth - containerWidth;
        },
        killAnimation() {
            if (tween) {
                tween.kill();
            }
        },
        leftOnClick() {
            let closestElementIndex = this.getClosestLeftElementIndex();

            this.scrollTo(closestElementIndex);
        },
        rightOnClick() {
            const children = this.$refs.items.children;
            let closestElementIndex = this.getClosestElementIndex();

            if (closestElementIndex < children.length - 1) {
                closestElementIndex++;
            }

            this.scrollTo(closestElementIndex);
        },
        arrowVisibilityController() {
            if (this.elementX < -10) {
                this.showLeftArrow = true;
            } else {
                this.showLeftArrow = false;
            }

            if (Math.abs(this.elementX) < this.getMaxRight() - 10) {
                this.showRightArrow = true;
            } else {
                this.showRightArrow = false;
            }
        },
        scrollTo(itemIndex) {
            const container = this.$refs.scrollable;
            const element = this.$refs.items;
            const children = this.$refs.items.children;
            let animateTo;

            if (container.offsetWidth > element.offsetWidth) {
                animateTo = 0;
            } else if (
                itemIndex === children.length - 1 ||
                container.offsetWidth >
                    element.offsetWidth - children[itemIndex].offsetLeft + leftOffset
            ) {
                animateTo = this.getMaxRight() * -1;
            } else {
                animateTo = (children[itemIndex].offsetLeft - leftOffset) * -1;
            }

            const counter = { var: this.elementX };
            tween = TweenLite.to(counter, this.animationDuration, {
                var: animateTo,
                onUpdate: () => {
                    this.elementX = Math.ceil(counter.var);
                    this.arrowVisibilityController();
                },
            });
        },
        handleScroll(event) {
            this.killAnimation();
            startElementX = this.elementX;

            if (event.deltaX !== 0) {
                event.preventDefault();
                this.moveElement(event.deltaX * -1);
            }
        },
        handleTouchstart(event) {
            this.killAnimation();
            touchStartX = parseInt(event.changedTouches[0].clientX, 10);
            startElementX = this.elementX;
        },
        handleTouchend(event) {
            const distance = Math.abs(parseInt(event.changedTouches[0].clientX, 10) - touchStartX);

            if (distance > 0) {
                const closestElementIndex = this.getClosestElementIndex();
                this.scrollTo(closestElementIndex);
            }
        },
        handleTouchmove(event) {
            const distance = parseInt(event.changedTouches[0].clientX, 10) - touchStartX;
            this.moveElement(distance);

            if (event.cancelable && Math.abs(distance) > 8) {
                event.preventDefault();
            }
        },
        handleMousedown(event) {
            this.killAnimation();
            touchStartX = event.x;
            startElementX = this.elementX;
        },
        handleMouseup(event) {
            const distance = Math.abs(event.x - touchStartX);
            if (distance > 0) {
                const closestElementIndex = this.getClosestElementIndex();
                this.scrollTo(closestElementIndex);
            }
        },
        handleMousemove(event) {
            if (event.which === 1) {
                const distance = event.x - touchStartX;
                this.moveElement(distance);
            }
        },
        moveElement(distance) {
            const leftPos = startElementX + distance;
            const maxRight = this.getMaxRight();

            if (maxRight > 0) {
                if (leftPos > 0) {
                    this.elementX = 0;
                } else if (Math.abs(leftPos) >= maxRight) {
                    this.elementX = maxRight * -1;
                } else {
                    this.elementX = leftPos;
                }

                this.arrowVisibilityController();
            }
        },
        handleResize() {
            const closestElementIndex = this.getClosestElementIndex();

            this.arrowVisibilityController();
            this.scrollTo(closestElementIndex);
        },
    },
};
</script>

<style lang="scss" scoped>
.scrollable-message-wrapper {
    position: relative;
}

.message-items-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    margin-left: -10px;
    margin-right: -10px;
}

.message-items {
    display: flex;
    padding: 0 10px 0 36px;
    transform: translateX(0);
    backface-visibility: hidden;
}

.arrow-button {
    @include center(y);
    position: absolute;
    width: 26px;
    height: 100%;
    cursor: pointer;

    &::before {
        content: '';
        @include center();
        position: absolute;
        display: block;
        width: 33px;
        height: 104%;
        background: rgba(white, 0.9);
        z-index: -1;

        @include media-query(medium) {
            width: 28px;
        }
    }

    &::after {
        $size: 26px;

        content: '';
        @include center();
        position: absolute;
        width: $size;
        height: $size;
        border-radius: $size;
        background: $scrollable-message-wrapper-button-bg-color;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
        z-index: -1;

        @include media-query(medium) {
            $size: 20px;

            width: $size;
            height: $size;
            border-radius: $size;
        }
    }

    .icon {
        @include center();
        position: absolute;
        font-size: 20px;
        fill: $scrollable-message-wrapper-button-icon-color;

        @include media-query(medium) {
            font-size: 16px;
        }
    }

    &--left {
        left: -7px;
    }

    &--right {
        right: -7px;
    }

    &--highlighted-message {
        &::before {
            background: rgba($preview-message-active-bg-color, 0.9);
        }
    }
}

.fade-enter-active {
    animation: fade-in 0.2s;
}

.fade-leave-active {
    animation: fade-in 0.1s reverse;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>
