<template>
    <div :style="{ width: codeWidth }" class="code">
        <label for="code" class="code__label">{{ label }}</label>

        <div class="code__input-wrapper">
            <input
                id="code"
                ref="code"
                class="code__input"
                name="code"
                type="tel"
                autocomplete="off"
                :value="innerValue"
                :maxlength="inputLength"
                @input="innerValue = $event"
            />

            <div class="code__border" :class="{ 'code__border--has-error': false }">
                <div v-for="idx in inputLength" :key="idx"></div>
            </div>

            <div v-if="errorMessage" class="error-message">
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CodeInput',
    components: {},
    props: {
        errorMessage: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            required: true,
        },
        inputLength: {
            type: Number,
            default: 6,
        },
        label: {
            type: String,
            default: 'Code input',
        },
    },
    computed: {
        codeWidth() {
            return `${this.inputLength * (30 + 10) - 10 + 80}px`;
        },
        innerValue: {
            get() {
                return this.value.replace(/[^\d]/g, '');
            },
            set(event) {
                this.$emit('input', `${event.target.value}`);
            },
        },
    },
    methods: {
        focus() {
            this.$refs.code.focus();
        },
    },
};
</script>

<style lang="scss" scoped>
$code-input-label-color: #bcbcbc;

.code {
    width: 310px;
    margin: 0 auto 20px;
    font-family: $font-family-default;

    &__label {
        margin-bottom: 10px;
        font-size: 14px;
        color: $code-input-label-color;
    }

    &__input-wrapper {
        padding: 0 40px;
        margin: 0 auto;
    }

    &__input {
        width: 120%;
        outline: 0;
        border: none;
        border-radius: 0;
        background: transparent;
        padding: 5px 12px;
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
        font-family: $font-family-default;
        line-height: 1.5;
        letter-spacing: 30px;
    }

    &__border {
        width: 100%;
        display: flex;
        justify-content: space-between;

        > div {
            width: 30px;
            height: 1px;
            background-color: $color-cadet-blue;
            transition: background-color 0.2s;
        }

        &--has-error {
            > div {
                background: $color-error;
            }
        }
    }
}

.error-message {
    padding-top: 12px;
    font-size: 10px;
    font-weight: 500;
    color: $color-error;
}
</style>
