import VueRouter, { NavigationGuard } from 'vue-router';
import { Layouts } from './enums/layouts.enum';
import { Routes } from './enums/routes.enum';
import { UserRoles } from './store/enums';
import config from '@/config';
import store from '@/store';
import Vue from 'vue';

import Callback from './views/Callback.vue';
import Main from './views/Main.vue';
import Messages from './views/Messages.vue';
import NotFound from './views/NotFound.vue';
import ErrorView from './views/Error.vue';
import NotAllowed from './views/NotAllowed.vue';
import Logout from './views/Logout.vue';
import SignIn from './views/SignIn.vue';

const ChannelList = () => import('./views/ChannelList.vue');
const ChannelDetails = () => import('./views/ChannelDetails.vue');
const ConvoList = () => import('./views/ConvoList.vue');
const Results = () => import('./views/Results.vue');
const Publishing = () => import('./views/Publishing.vue');
const Notifications = () => import('./views/Notifications.vue');
const Reports = () => import('./views/Reports.vue');
const UserInputReport = () => import('./views/UserInputReport.vue');
const AudienceList = () => import('./views/AudienceList.vue');
const AudienceDetails = () => import('./views/AudienceDetails.vue');
const Reporting = () => import('./views/Reporting.vue');
const ReportDetails = () => import('./views/ReportDetails.vue');
const UserManagement = () => import('./views/UserManagement.vue');
const PDLList = () => import('./views/PDLList.vue');
const PDLCreate = () => import('./views/PDLCreate.vue');
const PDLMessages = () => import('./views/PDLMessages.vue');
const PDLMode = () => import('./views/PDLMode.vue');
const PDLImportYgd = () => import('./views/PDLImportYgd.vue');
const ContentLibraryList = () => import('./views/ContentLibraryList.vue');
const ContentLibraryCreate = () => import('./views/ContentLibraryCreate.vue');
const SystemNotifications = () => import('./views/SystemNotifications.vue');
const Category = () => import('./views/Category.vue');
const CategoryCreate = () => import('./views/CategoryCreate.vue');
const CategoryEdit = () => import('./views/CategoryEdit.vue');
const TenantList = () => import('./views/TenantList.vue');
const Tenant = () => import('./views/Tenant.vue');
const BroadcastList = () => import('./views/BroadcastList.vue');
const UserComments = () => import('./views/UserComments.vue');
const ConvoListTopics = () => import('./views/ConvoListTopics.vue');

const routes = [
    {
        name: 'callback',
        path: '/callback',
        component: Callback,
        meta: { public: true },
    },
    {
        name: 'logout',
        path: '/logout',
        component: Logout,
        meta: { public: true },
    },
    {
        name: '500',
        path: '/500',
        component: ErrorView,
        meta: { public: true },
    },
    {
        name: '403',
        path: '/403',
        component: NotAllowed,
        meta: { public: true },
    },
    {
        name: '404',
        path: '/404',
        component: NotFound,
        meta: { public: true },
    },
    {
        name: 'sign-in',
        path: '/sign-in',
        component: SignIn,
        meta: { public: true },
    },
    {
        name: 'main',
        path: '/',
        component: Main,
        redirect: { name: 'all-channels' },
        children: [
            {
                name: 'list-audiences',
                path: '/audiences',
                component: AudienceList,
            },
            {
                name: 'create-audience',
                path: '/audiences/create',
                component: AudienceDetails,
                meta: {
                    layout: Layouts.Boxed,
                },
            },
            {
                name: 'edit-audience',
                path: '/audiences/:audienceId/edit',
                component: AudienceDetails,
                meta: {
                    layout: Layouts.Boxed,
                },
            },
            {
                name: 'all-channels',
                path: '/channels',
                component: ChannelList,
            },
            {
                name: 'new-channel',
                path: '/channels/create',
                component: ChannelDetails,
                meta: {
                    layout: Layouts.Boxed,
                },
            },
            {
                name: 'convo-list',
                path: '/channels/:channelCode/convos',
                component: ConvoList,
            },
            {
                name: 'convo-list-topics',
                path: '/convos/topics',
                component: ConvoListTopics,
            },
            {
                name: 'channel-detail',
                path: '/channels/:channelCode',
                component: ChannelDetails,
                meta: {
                    layout: Layouts.Boxed,
                },
            },
            {
                name: 'create-convo',
                path: '/channels/:channelCode/convos/create',
                component: Messages,
                meta: {
                    group: 'edit-convo',
                    layout: Layouts.Boxed,
                },
            },
            {
                path: '/channels/:channelCode/convos/:convoId',
                redirect: { name: 'messages' },
            },
            {
                name: 'messages',
                path: '/channels/:channelCode/convos/:convoId/edit',
                component: Messages,
                meta: {
                    group: 'edit-convo',
                    layout: Layouts.Boxed,
                },
            },
            {
                name: 'results',
                path: '/channels/:channelCode/convos/:convoId/results',
                component: Results,
                meta: {
                    group: 'edit-convo',
                    layout: Layouts.Boxed,
                },
            },
            {
                name: 'publishing',
                path: '/channels/:channelCode/convos/:convoId/publishing',
                component: Publishing,
                meta: {
                    layout: Layouts.Boxed,
                },
            },
            {
                name: 'notifications',
                path: '/notifications',
                component: Notifications,
                meta: {
                    layout: Layouts.Boxed,
                },
            },
            {
                name: 'broadcasts',
                path: '/broadcasts',
                component: BroadcastList,
            },
            {
                name: 'reports',
                path: '/channels/:channelCode/convos/:convoId/report',
                component: Reports,
            },
            {
                name: 'user-input-reports',
                path: '/channels/:channelCode/convos/:convoId/report/:id',
                component: UserInputReport,
            },
            {
                name: 'tenant-list',
                path: '/tenants',
                component: TenantList,
                meta: {
                    role: UserRoles.Admin,
                },
            },
            {
                name: 'tenant',
                path: '/tenants/:tenantCode',
                component: Tenant,
                meta: {
                    role: UserRoles.Admin,
                    layout: Layouts.Boxed,
                },
            },
            {
                name: 'category',
                path: '/category',
                component: Category,
            },
            {
                name: 'add-category',
                path: '/add-category',
                component: CategoryCreate,
            },
            {
                name: 'edit-category',
                path: '/edit-category/:categoryId',
                component: CategoryEdit,
            },
            {
                name: 'users',
                path: '/users',
                component: UserManagement,
            },
            {
                name: 'user-comments',
                path: '/user-comments',
                component: UserComments,
            },
        ],
    },
] as any;

const mainRoutes = routes.find((route: any) => route.name === 'main');

mainRoutes.children.push(
    {
        name: Routes.PdlList,
        path: '/pdl',
        component: PDLList,
    },
    {
        name: Routes.PdlMode,
        path: '/pdl/select-mode',
        component: PDLMode,
    },
    {
        name: Routes.PdlCreate,
        path: '/pdl/create',
        component: PDLCreate,
        meta: {
            layout: Layouts.Boxed,
        },
    },
    {
        name: Routes.PdlImport,
        path: '/pdl/import',
        component: PDLImportYgd,
    },
    {
        name: Routes.PdlEdit,
        path: '/pdl/:convoId/edit',
        component: PDLMessages,
        meta: {
            layout: Layouts.Boxed,
        },
    },
);

mainRoutes.children.push({
    name: 'system-notifications',
    path: '/system-notifications',
    component: SystemNotifications,
});

if (mainRoutes && config.featureToggle.reporting) {
    mainRoutes.children.push({
        name: 'list-reports',
        path: '/reports',
        component: Reporting,
    });
    mainRoutes.children.push({
        name: 'create-report',
        path: '/reports/create',
        component: ReportDetails,
    });
    mainRoutes.children.push({
        name: 'edit-report',
        path: '/reports/:reportId/edit',
        component: ReportDetails,
    });
}

if (mainRoutes && config.featureToggle.contentLibraryEnabled) {
    mainRoutes.children.push(
        {
            name: Routes.ContentLibraryList,
            path: '/content-library',
            component: ContentLibraryList,
        },
        {
            name: Routes.ContentLibraryCreate,
            path: '/content-library/create',
            component: ContentLibraryCreate,
            props: true,
        },
        {
            name: Routes.ContentLibraryMessages,
            path: '/content-library/:convoId/edit',
            component: Messages,
        },
    );
}

export const router = new VueRouter({
    mode: 'history',
    routes,
});

const userRoles = [UserRoles.Admin, UserRoles.Editor, UserRoles.Yougov] as string[];

const authGuard: NavigationGuard = async (to, from, next) => {
    await store.dispatch('auth/checkAuthentication');
    const isAuthenticated = store.getters['auth/isAuthenticated'];

    if (isAuthenticated) {
        const roles: string[] = store.getters['auth/userRoles'] || [];
        const hasRouteAccess = to.matched.every((route) => {
            return route?.meta?.role === undefined || roles.includes(route.meta.role);
        });
        const hasCmsAccess = roles.some((role) => userRoles.includes(role));
        if (hasRouteAccess && hasCmsAccess) {
            next();
        } else {
            Vue.$log.error('Not allowed');
            next('/403');
        }
    } else {
        if (config.featureToggle.otpSignIn) {
            next({
                path: '/sign-in',
                query: { redirect: to.fullPath },
            });
        } else {
            // If unauth'd user tries to access protected route, try to
            // login via google to mimick the old auth behaviour.
            store.dispatch('auth/signInFederated', 'Google');
        }
    }
};

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.public)) {
        next();
    } else {
        authGuard(to, from, next);
    }
});
