import { ContentItemType } from '@/store/enums/';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum';
import { IConvoState } from '@/store/modules/convo-details';
import { ContentItem, TextInput } from '..';
import { Answer } from '../answer.dto';
import { IContentItem, IValidationRules } from '../interfaces';
import { IAnswer } from '../interfaces/answer.interface.dto';
import { IWidgetReply } from '../interfaces/widgetReply.interface.dto';
import { TextGlobalResponse } from '../textGlobalResponse';

export class TextInputContentItemFactory {
    public make(obj: TextInput, state: IConvoState): IContentItem {
        const contentItem = new ContentItem();
        contentItem.id = obj.id;
        contentItem.sort = obj.sort;
        contentItem.title = obj.input;
        contentItem.type = ContentItemType.TextInput;
        contentItem.tags = obj.tags || [];
        contentItem.externalId = obj.datumDefinition || '';
        contentItem.isPii = obj.isPii;
        contentItem.answers = [];

        contentItem.validationRules = {
            allowSkip: obj.allowSkip,
        } as IValidationRules;

        if (obj.messengerValidation?.code) {
            contentItem.validationRules = {
                ...contentItem.validationRules,
                code: obj.messengerValidation?.code,
                regex: obj.messengerValidation?.regex,
                postValidation: obj.messengerValidation?.postValidation,
                errorMessage: obj.errorMessage,
            } as IValidationRules;
        }

        const answer: IAnswer = new Answer();
        answer.convoId = state.convo.id;

        answer.targetChannel = obj.logicJump?.items?.channel?.code || '';
        answer.targetConvo = obj.logicJump?.items?.convo?.id || 0;
        answer.targetContentItem = obj.logicJump?.items?.message?.id || 0;
        answer.goToBlock = !answer.targetContentItem && answer.targetConvo ? answer.targetConvo : 0;
        answer.goToWidget =
            answer.targetConvo && answer.targetContentItem ? answer.targetContentItem : 0;

        contentItem.answers.push(answer);

        const reply = obj.items.find(
            (i) => i.type === ConvoMessageItemType.TextGlobalResponse,
        ) as TextGlobalResponse;

        contentItem.reply = {} as IWidgetReply;
        contentItem.reply.activated = !!reply.message;
        contentItem.reply.message = reply.message;
        contentItem.reply.sortingOrder = reply.sortingOrder;
        contentItem.reply.tagLine = '';
        contentItem.reply.url = '';
        contentItem.reply.imageUrl = '';

        contentItem.commentOptions = {};
        if (obj.correlation && obj.hasCorrelation) {
            contentItem.commentOptions.correlation = {
                channel: obj.correlation?.channel?.text!,
                channelCode: obj.correlation?.channel?.code!,
                convo: obj.correlation?.convo?.text!,
                convoId: obj.correlation?.convo?.id!,
                contentItemId: obj.correlation?.message?.id!,
                contentItemText: obj.correlation?.message?.text!,
            };
        }

        return contentItem;
    }
}
