import { Attributes } from '@/store/enums/attributes.enum';
import { ItemsGroup } from '..';
import { IContentItem } from '../interfaces';
import { sanitize } from '@/utils/sanitizer';
import { Rating, RatingOption } from '..';
import { makeLogicJump } from '@/store/models/factories/accordionItems.factory';

export class RatingAccordionItemFactory {
    public make(contentItem: IContentItem, isClone?: boolean): Rating {
        const ratingItem = new Rating();
        const plainTextStatement = sanitize(contentItem.title);

        ratingItem.subtitle = plainTextStatement;
        ratingItem.plainText = plainTextStatement;
        ratingItem.statement = contentItem.title;
        ratingItem.id = contentItem.id || 0;
        ratingItem.title = 'Interest Rating';
        ratingItem.ratingTarget = contentItem.ratingTarget;
        ratingItem.sort = contentItem.sort;
        ratingItem.tags = contentItem.tags;

        const answers = contentItem?.answers || [];
        const ratingOptions = [];
        for (const answer of answers) {
            const ratingOptionsItem = new RatingOption();
            ratingOptionsItem.rating = answer.rating;
            ratingOptionsItem.title = answer.text || '';
            ratingOptionsItem.ratingId =
                !isClone && answer.id ? answer.id : ratingOptionsItem.ratingId;

            ratingOptions.push(ratingOptionsItem);
        }

        if (answers[0]) {
            ratingItem.logicJump = makeLogicJump(answers[0]);

            if (Object.keys(ratingItem.logicJump.items).length) {
                ratingItem.attributes.push(Attributes.LogicJump);
            }
        }

        ratingItem.items = ratingOptions;
        return ratingItem;
    }
}
