var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isPdl)?_c('div',{staticClass:"text-statement-skip"},[_c('Checkbox',{attrs:{"id":"shouldSkipIfAnswered","label":"Should skip if already answered","value":_vm.data.shouldSkipIfAnswered},on:{"input":function (value) { return _vm.onInput('shouldSkipIfAnswered', value); }}})],1):_vm._e(),(_vm.isPdl)?_c('div',{staticClass:"text-statement-tags-header"},[_c('Tags',{attrs:{"placeholder":"","label":"Tags","value":_vm.data.tags,"disabled":true}})],1):_vm._e(),(_vm.$options.richTextMessagesEnabled)?_c('EditorInput',{attrs:{"id":_vm.generateId('statement', _vm.path),"label":"Message","show-remaining-char":"","content":_vm.data.statement,"readonly":_vm.isPdl,"errors":_vm.data.errors.plainText,"max":640,"menu-items":[
            _vm.$options.EditorInputMenuItem.Bold,
            _vm.$options.EditorInputMenuItem.Italic,
            _vm.$options.EditorInputMenuItem.Strike,
            _vm.$options.EditorInputMenuItem.Underline ]},on:{"update":function($event){return _vm.onInput('statement', $event)},"plainTextChange":function($event){return _vm.onInput('plainText', $event)}}}):_c('Input',{attrs:{"id":_vm.generateId('statement', _vm.path),"type":"textarea","label":"Message","name":"statement","max":640,"show-remaining-char":"","has-margin-bottom":false,"readonly":_vm.isPdl,"value":_vm.data.statement,"errors":_vm.data.errors.plainText},on:{"input":function (value) {
                _vm.onInput('statement', value);
                _vm.onInput('plainText', value);
            }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }