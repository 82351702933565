<template>
    <ConvoPreviewWrapper
        :in-overlay="!showPreview"
        :show-overlay="showOverlay"
        :nav-is-opened="navIsOpened"
        @overlayOnClose="$emit('overlayOnClose')"
        @afterEnter="afterEnter"
    >
        <div class="convo-preview">
            <h2 class="convo-preview-title">Preview</h2>

            <div class="convo-preview-body">
                <div v-if="messages.length === 0" class="convo-preview-no-messages-block">
                    <Spinner v-if="isLoading" />
                    <span v-else> No messages </span>
                </div>
                <div v-else ref="messageList" class="message-list">
                    <ConvoPreviewMessage
                        v-for="(message, index) in messages.filter((item) => !item.isDeleted)"
                        :id="`message-${index}`"
                        :key="index"
                        :ref="`message[${index}]`"
                        :message="message"
                        :is-active="index === activeMessageIndex"
                    />
                </div>
                <ConvoPreviewFooter :label="footerBtnLabel" />
            </div>

            <AnchorLink
                v-if="fbPageId && convoId"
                :href="`https://m.me/${fbPageId}?ref=GOTO_BLOCK_${convoId}_RESETMEMBERSESSION`"
                :disabled="messagesModelHasChanges"
                target="_blank"
                class="convo-preview-external-link"
                full-width
                right-icon="external-link"
            >
                <template v-if="messagesModelHasChanges"> Save Progress to Preview </template>
                <template v-else> Preview In Facebook Messenger </template>
            </AnchorLink>
        </div>
    </ConvoPreviewWrapper>
</template>

<script>
import SweetScroll from 'sweet-scroll';
import ConvoPreviewWrapper from '@/components/ConvoPreviewWrapper';
import ConvoPreviewMessage from '@/components/ConvoPreviewMessage';
import ConvoPreviewFooter from '@/components/ConvoPreviewFooter';
import AnchorLink from '@/components/ui/Link';
import Spinner from '@/components/ui/Spinner';
import cssVariables from '@styles/abstracts/_variables.layout.scss';
import { MessageType } from '@/../messages/src/models/enums/messageType.enum.ts';

let mediaQueryList;

export default {
    name: 'ConvoPreview',
    components: {
        ConvoPreviewWrapper,
        ConvoPreviewMessage,
        ConvoPreviewFooter,
        AnchorLink,
        Spinner,
    },
    props: {
        messages: {
            type: Array,
            default: () => [],
        },
        activeMessageIndex: {
            type: Number,
            default: undefined,
        },
        showOverlay: {
            type: Boolean,
            default: false,
        },
        navIsOpened: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        fbPageId: {
            type: String,
            default: undefined,
        },
        convoId: {
            type: Number,
            default: undefined,
        },
        messagesModelHasChanges: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showPreview: true,
        };
    },
    computed: {
        footerBtnLabel() {
            const activeMessage = this.messages[this.activeMessageIndex];
            if (activeMessage) {
                const item = activeMessage.messages.find((item) => {
                    return (
                        item.isMultipleChoice &&
                        (item.type == MessageType.QuickReply || item.type == MessageType.Buttons)
                    );
                });
                const DefaultSubmitBtnText = 'Submit';
                if (item) {
                    return item.submitButtonText ? item.submitButtonText : DefaultSubmitBtnText;
                }
            }
            return '';
        },
    },
    watch: {
        activeMessageIndex(newValue) {
            this.$nextTick(() => {
                this.scrollToActiveItem(newValue);
            });
        },
    },
    created() {
        this.$nextTick(() => {
            this.scrollToActiveItem(this.activeMessageIndex);
        });

        if (Object.keys(cssVariables).length) {
            const mediumBreakpoint = cssVariables['media-queries-medium'].slice(1, -1);
            mediaQueryList = matchMedia(mediumBreakpoint);

            if (mediaQueryList.matches) {
                this.showPreview = true;
            } else {
                this.showPreview = false;
            }

            mediaQueryList.addListener(this.setIsShowPreview);
        }
    },
    destroyed() {
        if (Object.keys(cssVariables).length) {
            mediaQueryList.removeListener(this.setIsShowPreview);
        }
    },
    methods: {
        scrollToActiveItem(activeMessageIndex, duration = 1000) {
            const activeMessageArray = this.$refs[`message[${activeMessageIndex}]`];

            if (
                activeMessageIndex !== undefined &&
                activeMessageArray &&
                activeMessageArray.length > 0
            ) {
                const activeMessage = activeMessageArray[0].$el;
                const messageList = this.$refs.messageList;
                const messageListHeight = messageList.offsetHeight;
                const activeMessageHeight = activeMessage.offsetHeight;
                const activeMessageY = activeMessage.offsetTop - messageList.offsetTop;
                const messageListBottom = messageList.scrollTop + messageListHeight;
                const activeMessageBottom = activeMessageY + activeMessageHeight;
                const scroller = new SweetScroll(
                    { complete: () => scroller.destroy(), duration },
                    messageList,
                );

                if (activeMessageY < messageList.scrollTop) {
                    scroller.to(activeMessageY);
                } else if (activeMessageBottom > messageListBottom) {
                    scroller.to(activeMessageY - messageListHeight + activeMessageHeight);
                }
            }
        },
        setIsShowPreview(event) {
            if (event.matches) {
                this.showPreview = true;
            } else {
                this.showPreview = false;
            }
        },
        afterEnter() {
            this.scrollToActiveItem(this.activeMessageIndex, 0);
        },
    },
};
</script>

<style lang="scss" scoped>
.convo-preview {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    @include media-query(medium) {
        width: auto;
        height: auto;
        align-self: center;
    }
}

.convo-preview-title {
    display: none;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    text-align: center;

    @include media-query(medium) {
        display: block;
    }

    @include media-query($show-preview-screen) {
        display: none;
    }

    @media only screen and (min-height: #{$large-height-screen}px) {
        display: block;
    }
}

.convo-preview-body {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    min-height: 0;
    max-height: 100%;

    @include media-query(medium) {
        display: flex;
        align-self: center;
        flex-direction: column;
        justify-content: flex-end;
        flex: 0 0 auto;
        width: 230px;
        max-height: none;
        height: 466px;
        margin: 0 auto 20px;
        padding: 35px 13px 11px;
    }

    &::after {
        content: '';
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent url('~@images/preview-frame.png') 0 0 no-repeat;
        background-size: cover;
        pointer-events: none;

        @include media-query(medium) {
            display: block;
        }
    }
}

.message-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    overflow-y: scroll;
    width: 100%;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    -webkit-overflow-scrolling: touch; // for ios rubber band scrolling effect

    &::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
    }

    .disable-momentum-scroll & {
        -webkit-overflow-scrolling: auto; // for ios rubber band scrolling effect
    }
}

.message-wrapper {
    &:first-child {
        margin-top: auto;
    }

    &--active {
        background: $preview-message-active-bg-color;
    }
}

.message {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $preview-message-bg-color;
    min-height: 150px;
    min-width: 40px;
    margin: #{$page-padding-on-small / 2}px #{$page-padding-on-small}px;
    padding: 5px;
    text-align: center;
    color: white;
}

.convo-preview-external-link {
    position: relative;
    text-align: center;
    min-height: 54px;
    border-top: 1px solid $preview-external-link-border-color;

    @include media-query(medium) {
        padding-left: 0;
        padding-right: 0;
        min-height: auto;
        border-top: none;
    }
}

.convo-preview-no-messages-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
</style>
