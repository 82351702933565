import { IContentItem } from '../interfaces';
import { Video } from '..';

export class VideoAccordionItemFactory {
    public make(contentItem: IContentItem): Video {
        const videoItem = new Video();
        const answer = contentItem.answers && contentItem.answers[0];

        videoItem.video = contentItem.attachment && JSON.parse(contentItem.attachment);
        videoItem.id = contentItem.id || 0;
        videoItem.sort = contentItem.sort;
        videoItem.tags = contentItem.tags;
        videoItem.title = 'Video';
        videoItem.setTitle();

        return videoItem;
    }
}
