<template>
    <div
        v-click-outside="clickOutside"
        class="datepicker"
        :class="{
            'datepicker--has-m-b': hasMarginBottom,
        }"
    >
        <Input
            :id="id"
            ref="input"
            type="text"
            :name="name"
            :label="label"
            :value="value && displayDate"
            :errors="errors"
            :placeholder="placeholder"
            :has-margin-bottom="false"
            default-icon="calendar"
            :default-icon-size="30"
            :show-clear-icon="showClearIcon"
            @focus="openPicker"
            @click-icon="openPicker"
            @onClear="$emit('input', undefined)"
        />

        <input
            v-if="isMobileDevice"
            type="date"
            class="mobile-date-selector"
            :value="value && format(value, 'yyyy-MM-dd')"
            :min="min && format(min, 'yyyy-MM-dd')"
            :max="max && format(max, 'yyyy-MM-dd')"
            @change="onMobileDateInputChange"
        />

        <VueDatepicker
            ref="vueDatepicker"
            :value="value"
            input-class="vue-datepicker-input"
            :calendar-class="[
                'vue-datepicker-calendar',
                { 'vue-datepicker-calendar--right': isRightPlaced },
            ]"
            wrapper-class="vue-datepicker-wrapper"
            :disabled-dates="{ to: min, from: max }"
            :use-utc="useUtc"
            @selected="onSelected"
        />
    </div>
</template>

<script>
import { format } from 'date-fns';
import VueDatepicker from 'vuejs-datepicker';
import Input from '@/components/forms/Input';
import { isMobileDevice } from '@/utils/mobileDetection.ts';

export default {
    name: 'Datepicker',
    components: {
        Input,
        VueDatepicker,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Date,
            default: undefined,
        },
        min: {
            type: Date,
            default: undefined,
        },
        max: {
            type: Date,
            default: undefined,
        },
        errors: {
            type: Array,
            default: () => [],
        },
        displayFormat: {
            type: String,
            default: 'dd MMMM y',
        },
        placeholder: {
            type: String,
            default: '',
        },
        hasMarginBottom: {
            type: Boolean,
            default: true,
        },
        useUtc: {
            type: Boolean,
            default: false,
        },
        showClearIcon: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isMobileDevice: isMobileDevice(),
            isRightPlaced: false,
        };
    },
    computed: {
        displayDate() {
            return format(this.value, this.displayFormat);
        },
    },
    mounted() {
        const { left } = this.$refs.input?.$refs?.input?.getBoundingClientRect();
        if (left > (2 * window.innerWidth) / 3) {
            this.isRightPlaced = true;
        }
    },
    methods: {
        openPicker() {
            this.$refs.vueDatepicker.showCalendar();
            this.$refs.input.$refs.input.blur();
        },
        clickOutside() {
            this.$refs.vueDatepicker.close();
        },
        onSelected(date) {
            this.$emit('change', date);
            this.$emit('input', date);
        },
        onMobileDateInputChange() {
            let selectedDate;

            if (event.target.value) {
                selectedDate = new Date(event.target.value);
            }

            this.$emit('change', selectedDate);
        },
        format,
    },
};
</script>

<style lang="scss" scoped>
.datepicker {
    position: relative;

    &--has-m-b {
        margin-bottom: $form-group-margin-bottom;
    }
}

.mobile-date-selector {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    width: 100%;
    height: 52px;
    opacity: 0;
}
</style>

<style lang="scss">
.vue-datepicker-input {
    display: none;
}

.vue-datepicker-calendar--right {
    right: 0;
}

.vue-datepicker-wrapper {
    .vdp-datepicker__calendar {
        border-color: $datepicker-calendar-border-color;
        z-index: $date-picker-z-index;

        .cell {
            &:not(.blank):not(.disabled):not(.selected) {
                &.day,
                &.month,
                &.year {
                    &:hover {
                        border-color: $datepicker-calendar-hover-cell-bg-color;
                        background: $datepicker-calendar-hover-cell-bg-color;
                    }
                }
            }

            &.selected {
                background: $datepicker-calendar-selected-cell-bg-color;
                color: $datepicker-calendar-selected-cell-text-color;

                &:hover {
                    border-color: darken($datepicker-calendar-selected-cell-bg-color, 6%);
                    background: darken($datepicker-calendar-selected-cell-bg-color, 6%);
                }
            }
        }
    }
}
</style>
