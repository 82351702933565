import { AnswerType, ConvoMessageItemType, ConvoTypes } from '@/store/enums';

export interface IButtonAnswerTypeOption {
    name: AnswerType;
    title: string;
    linkRequired: boolean;
    linkNewTab: boolean;
    canBeMultipleChoice: boolean;
    excludeMessageItemTypes: ConvoMessageItemType[];
    excludeConvoTypes: ConvoTypes[];
}

const options: IButtonAnswerTypeOption[] = [
    {
        name: AnswerType.Reply,
        title: 'Normal answer',
        linkRequired: false,
        linkNewTab: true,
        canBeMultipleChoice: true,
        excludeMessageItemTypes: [],
        excludeConvoTypes: [],
    },
    {
        name: AnswerType.ReplyDisposition,
        title: 'Normal answer with disposition',
        linkRequired: true,
        linkNewTab: false,
        canBeMultipleChoice: false,
        excludeMessageItemTypes: [],
        excludeConvoTypes: [],
    },
    {
        name: AnswerType.LinkNext,
        title: 'Open Link In Modal',
        linkRequired: true,
        linkNewTab: false,
        canBeMultipleChoice: false,
        excludeMessageItemTypes: [],
        excludeConvoTypes: [],
    },
    {
        name: AnswerType.LinkNextDisposition,
        title: 'Open Link In Modal with Disposition',
        linkRequired: true,
        linkNewTab: false,
        canBeMultipleChoice: false,
        excludeMessageItemTypes: [],
        excludeConvoTypes: [],
    },
    {
        name: AnswerType.Link,
        title: 'Open In Window',
        linkRequired: true,
        linkNewTab: true,
        canBeMultipleChoice: false,
        excludeMessageItemTypes: [],
        excludeConvoTypes: [],
    },
    {
        name: AnswerType.PanmanSurvey,
        title: 'Panman survey referral link',
        linkRequired: true,
        linkNewTab: false,
        canBeMultipleChoice: false,
        excludeMessageItemTypes: [
            ConvoMessageItemType.Carousel,
            ConvoMessageItemType.YougovWelcomeSurveyButtons,
        ],
        excludeConvoTypes: [],
    },
    {
        name: AnswerType.YougovWelcomeSurvey,
        title: 'Yougov Welcome Survey link',
        linkRequired: false,
        linkNewTab: false,
        canBeMultipleChoice: false,
        excludeMessageItemTypes: Object.values(ConvoMessageItemType).filter(
            (o) =>
                ![
                    ConvoMessageItemType.YougovWelcomeSurveyButtons,
                    ConvoMessageItemType.Buttons,
                ].includes(o),
        ),
        excludeConvoTypes: Object.values(ConvoTypes).filter(
            (o) => ![ConvoTypes.YougovWelcomeSurvey, ConvoTypes.YougovWelcomeChat].includes(o),
        ),
    },
];

export default options;
