import { render, staticRenderFns } from "./MessageTypeButton.vue?vue&type=template&id=697033ee&scoped=true&"
import script from "./MessageTypeButton.vue?vue&type=script&lang=js&"
export * from "./MessageTypeButton.vue?vue&type=script&lang=js&"
import style0 from "./MessageTypeButton.vue?vue&type=style&index=0&id=697033ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "697033ee",
  null
  
)

export default component.exports