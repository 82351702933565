import { Attributes } from '@/store/enums/attributes.enum';
import { IAnswer } from '@/store/models/interfaces/answer.interface.dto';
import { replaceTemplateVariablesForPreview } from '@/store/services/convoMessageItemService';
import { v4 as uuid } from 'uuid';
import { ItemsGroup, QuickReply, QuickReplyAnswer, TextGlobalResponse } from '..';
import { IContentItem } from '../interfaces';
import { sanitize } from '@/utils/sanitizer';
import {
    makeAnswerReply,
    makeLogicJump,
    makeQuizAttributes,
    makeQuizOptionAttributes,
} from './accordionItems.factory';

export class QuickReplyAccordionItemFactory {
    public make(contentItem: IContentItem, isClone?: boolean): QuickReply {
        const quickReplyItem = new QuickReply();
        const quickReplyAnswers = new ItemsGroup();
        const notDraggableItemList = new ItemsGroup();
        const globalResponse = new TextGlobalResponse();
        const plainTextStatement = sanitize(contentItem.title);

        if (contentItem.reply?.resultOptions) {
            quickReplyItem.resultOptions.order = contentItem.reply.resultOptions.order;
            quickReplyItem.resultOptions.chartType = contentItem.reply.resultOptions.chartType;
            quickReplyItem.resultOptions.showResponseCount =
                !!contentItem.reply.resultOptions.showResponseCount;
        }

        if (contentItem.reply?.message) {
            globalResponse.message = contentItem.reply.message;
            globalResponse.subtitle = replaceTemplateVariablesForPreview(
                contentItem.reply.message,
                contentItem.reply.sortingOrder,
            );
            globalResponse.sortingOrder = contentItem.reply.sortingOrder;
        }

        if (contentItem.reply && contentItem.reply.activated && !contentItem.reply.message) {
            quickReplyItem.showQuizResults = true;
        }

        globalResponse.hasMarginBottom = false;

        notDraggableItemList.items = [globalResponse];

        quickReplyItem.id = contentItem.id || 0;
        quickReplyItem.sort = contentItem.sort;
        quickReplyItem.statement = contentItem.title;
        quickReplyItem.subtitle = plainTextStatement;
        quickReplyItem.plainText = plainTextStatement;
        quickReplyItem.title = 'Quick Reply';
        quickReplyItem.areAnswersShuffled = !!contentItem.shuffleAnswers;
        quickReplyItem.pinLastAnswer = !contentItem.shuffleLast;
        quickReplyItem.isMultipleChoice = !!contentItem.multipleChoice;
        quickReplyItem.tags = contentItem.tags;
        quickReplyItem.isPdl = !!contentItem.isPdl;
        quickReplyItem.shouldSkipIfAnswered = !!contentItem.shouldSkipIfAnswered;

        if (contentItem.externalId) {
            quickReplyItem.datumDefinition = contentItem.externalId;
        }

        if (!!contentItem.multipleChoice) {
            quickReplyItem.submitButtonText = contentItem.multipleChoiceOptions.submitButtonText;
            quickReplyItem.layout = contentItem.multipleChoiceOptions.layout;
            quickReplyItem.minLimit = contentItem.multipleChoiceOptions.minLimit;
            quickReplyItem.maxLimit = contentItem.multipleChoiceOptions.maxLimit;
        }

        for (const answer of contentItem.answers as IAnswer[]) {
            const quickReplyAnswerItem = new QuickReplyAnswer();

            quickReplyAnswerItem.answerId = answer.id;
            if (!quickReplyAnswerItem.answerId || isClone) {
                quickReplyAnswerItem.answerId = uuid();
                quickReplyItem.hasChanges = true;
            }

            if (answer.externalId) {
                quickReplyAnswerItem.datumResponseCode = answer.externalId;
            }

            quickReplyAnswerItem.isExclusive = answer.isExclusive;
            quickReplyAnswerItem.buttonLabel = answer.text || '';
            quickReplyAnswerItem.buttonImageUrl = answer.image || '';

            if (quickReplyAnswerItem.buttonImageUrl) {
                quickReplyAnswerItem.attributes.push(Attributes.Media);
            }

            quickReplyAnswerItem.logicJump = makeLogicJump(answer);

            if (Object.keys(quickReplyAnswerItem.logicJump.items).length) {
                quickReplyAnswerItem.attributes.push(Attributes.LogicJump);
            }

            const quizAttr = makeQuizAttributes(answer);
            quickReplyItem.ignoreQuizQuestion = quizAttr.ignoreQuizQuestion;

            const quizOptionAttr = makeQuizOptionAttributes(answer);
            quickReplyAnswerItem.selectedPersonalityQuizResult =
                quizOptionAttr.selectedPersonalityQuizResult;
            quickReplyAnswerItem.isCorrectAnswer = quizOptionAttr.isCorrectAnswer;

            if (
                !contentItem.shuffleLast &&
                answer == contentItem.answers[contentItem.answers.length - 1]
            ) {
                quickReplyAnswerItem.isPinned = true;
            }

            if (answer.reply) {
                const inlineResponse = makeAnswerReply(answer.reply);
                quickReplyAnswerItem.items[0].items.push(inlineResponse);
                quickReplyAnswerItem.attributes.push(Attributes.InlineResponse);
            }

            quickReplyAnswers.items.push(quickReplyAnswerItem);
        }

        quickReplyItem.items = [quickReplyAnswers, notDraggableItemList];

        return quickReplyItem;
    }
}
