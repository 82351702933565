<script>
/*
Configuration:

- Add the following dialog to your component.

<Dialog
    showConfirmButton
    confirmButtonText="Continue Editing"
    closeButtonText="Cancel & Discard changes"
    :isDialogVisible="leaveDialogIsOpen"
    @onClose="leaveDialogOnClose"
    @onClickOutside="leaveDialogOnConfirm"
    @confirmButtonOnClick="leaveDialogOnConfirm"
>
    <template #header>
        There are unsaved changes made to the convo.
    </template>
    <template #body>
        Continue editing to save progress.
    </template>
</Dialog>

- Set hasUnsavedChanges computed property in your component.

- If you want to navigate across pages without showing the dialog, define a group at the router under the meta property.

    meta: {
        group: 'edit-convo',
    },
*/

export default {
    name: 'LeavingDialogMixin',
    data() {
        return {
            leaveDialogIsOpen: false,
            leaveDialogOnClose: () => {},
            leaveDialogOnConfirm: () => {},
        };
    },
    created() {
        window.addEventListener('beforeunload', this.$_leavingDialogMixin_handleBeforeunload);
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.$_leavingDialogMixin_handleBeforeunload);
    },
    methods: {
        $_leavingDialogMixin_handleBeforeunload(event) {
            if (this.hasUnsavedChanges) {
                event.preventDefault();
                event.returnValue = '';
            }
        },
        leavingDialogMixin_leaveDialog(next) {
            if (this.hasUnsavedChanges) {
                this.leaveDialogIsOpen = true;

                this.leaveDialogOnClose = () => {
                    this.leaveDialogIsOpen = false;
                    next(true);
                };

                this.leaveDialogOnConfirm = () => {
                    this.leaveDialogIsOpen = false;
                    next(false);
                };
            } else {
                this.leaveDialogIsOpen = false;
                next(true);
            }
        },
    },
    async beforeRouteLeave(to, from, next) {
        if (
            !this.$route.meta.group ||
            (to.meta.group !== this.$route.meta.group && this.hasUnsavedChanges)
        ) {
            this.leavingDialogMixin_leaveDialog(next);
        } else {
            next();
        }
    },
};
</script>
