<template>
    <div class="convo-preview-menu">
        <ul>
            <li class="menu-element">
                <button class="menu-element__button">
                    <Icon class="menu-element__icon" name="menu" />
                </button>
            </li>
            <li class="submit-element">
                <button
                    class="submit-element__button"
                    :class="{ 'submit-element__button--disabled': !label }"
                >
                    {{ labelText }}
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    name: 'ConvoPreviewFooter',
    components: {
        Icon,
    },
    props: {
        label: {
            type: String,
            default: '',
        },
    },
    data() {
        return {};
    },
    computed: {
        labelText() {
            if (this.label) {
                return this.label;
            }
            return 'Typing is disabled';
        },
    },
};
</script>

<style lang="scss" scoped>
.convo-preview-menu {
    max-height: 200px;
    margin: -3px -2px 0;
    padding: 10px 5px;
    background: $convo-preview-menu-bg-color;
    font-size: 12px;

    @include media-query(medium) {
        display: block;
    }

    ul {
        display: flex;
        align-items: center;
    }
}

.menu-element {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;

    &__button {
        width: 32px;
        height: 32px;
        border-radius: 20px;
        border: 1px solid $preview-footer-menu-border-color;
        background: $preview-footer-btn-bg-color;
        text-align: center;
        cursor: pointer;
    }

    &__icon {
        margin: auto;
        font-size: 18px;
    }
}

.submit-element {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    width: 100%;

    &__button {
        white-space: nowrap;
        width: 100%;
        height: 32px;
        padding: 4px 10px;
        border-radius: 20px;
        border: 1px solid transparent;
        background: $preview-footer-btn-bg-color;
        font-size: 12px;
        color: $preview-footer-submit-active-color;
        text-align: center;
        font-weight: $font-weight-bold;
        cursor: pointer;

        &--disabled {
            color: $preview-footer-submit-disabled-color;
            background: $preview-footer-submit-bg-color;
            text-align: left;
            font-style: italic;
            font-weight: $font-weight-regular;
            cursor: default;
        }
    }
}
</style>
