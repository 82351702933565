<template>
    <div
        class="error-message"
        :class="[
            `error-message--${size}-size`,
            {
                'error-message--shake': animating,
            },
        ]"
        @animationend="animationEnd"
    >
        <slot />
    </div>
</template>

<script>
import onlyValues from '@/utils/onlyValues.ts';

export default {
    name: 'ErrorMessage',
    props: {
        size: {
            type: String,
            default: 'medium',
            validator: onlyValues(['small', 'medium', 'large']),
        },
    },
    data() {
        return {
            animating: false,
        };
    },
    mounted() {
        this.animate();
    },
    methods: {
        animate() {
            this.animating = true;
        },
        animationEnd() {
            this.animating = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.error-message {
    color: $color-error;
    font-size: $font-size-xs;
    font-weight: $font-weight-light;
    font-style: italic;
    line-height: 1.6rem;
    padding: 10px 0;

    &--small-size {
        font-size: 10px;
    }

    &--large-size {
        font-size: $font-size-base;
    }

    &--shake {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
</style>
