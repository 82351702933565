import {
    validate as validateClass,
    IsNotEmpty,
    ValidationError,
    ValidateIf,
    IsOptional,
    Validate,
} from 'class-validator';
import { getErrorMessage } from '@/store/services/validationService';
import { IsAfterConstraint, IsBeforeConstraint } from '@/services/validationConstraints';

export class Publishing {
    constructor() {
        this.publish = undefined;
        this.errors = {};
        this.isPollingEmailCampaign = false;
    }

    @IsNotEmpty({ message: getErrorMessage('publish') })
    publish?: boolean;

    errors: object;

    @ValidateIf((p: Publishing) => !!p.hideFromDate)
    @IsOptional()
    @Validate(IsBeforeConstraint, ['hideFromDate'])
    showFromDate?: Date;

    @ValidateIf((p: Publishing) => !!p.showFromDate)
    @IsOptional()
    @Validate(IsAfterConstraint, ['showFromDate'])
    hideFromDate?: Date;

    isPollingEmailCampaign: boolean;

    async validate(): Promise<any> {
        const errors: ValidationError[] = await validateClass(this);
        const errorMessages: any = {};

        if (errors.length > 0) {
            errors.forEach((item: ValidationError) => {
                errorMessages[item.property] = Object.values(item.constraints || {});
            });
        }

        return errorMessages;
    }
}
