<template>
    <button
        v-tooltip.right="disabled ? `${disabledText}` : ''"
        class="dropdown-item"
        :class="disabled ? 'disabled' : ''"
        @click="onClick($event)"
    >
        <Icon v-if="icon" class="dropdown-item__icon" :name="icon" />
        <slot />
    </button>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    name: 'DropDownItem',
    components: {
        Icon,
    },
    props: {
        icon: {
            type: String,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disabledText: {
            type: String,
            default: '',
        },
    },
    methods: {
        onClick(e) {
            if (this.disabled) {
                e.stopPropagation();
                e.preventDefault();
            } else {
                this.$emit('click', e);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.dropdown-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 12px;
    background-color: $drop-down-item-bg-color;
    color: $color-oxford-blue;
    font-size: $font-size-xs;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;

    &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }

    &:not(.disabled) {
        &:hover {
            background-color: $drop-down-item-bg-hover-color;
        }
    }

    &__icon {
        flex: 0 0 auto;
        margin-right: 6px;
        font-size: 18px;
        color: $drop-down-item-icon-color;
    }
}
</style>
