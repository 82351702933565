import { ToastType } from '.';

export class ToastOptions {
    constructor(init?: Partial<ToastOptions>) {
        Object.assign(this, init);
    }

    timeout: number = 3000;
    progressBar: boolean = true;
    type: ToastType = ToastType.Error;
}
