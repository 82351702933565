import { validate as validateClass, IsNotEmpty } from 'class-validator';
import { VideoAccordionItemFactory } from './factories/videoAccordionItem.factory';
import { VideoContentItemFactory } from './factories/videoContentItem.factory';
import truncateStringWithEllipsis from '@/utils/truncateStringWithEllipsis';
import { IObservableItem } from './interfaces/observableItem.interface';
import { IContentItemConvertible, IContentItem } from './interfaces';
import { IConvoState } from '../modules/convo-details';
import { ConvoMessageItemType } from '../enums';
import { AccordionItemBase } from '.';
import hash from 'object-hash';
import { IVideo } from '@/services/videoService';

export class Video
    extends AccordionItemBase
    implements IContentItemConvertible, IObservableItem<IConvoState>
{
    constructor() {
        super([]);
        this.icon = 'video';
        this.type = ConvoMessageItemType.Video;
        this.isDraggable = true;
        this.isDeletable = true;
    }

    @IsNotEmpty({ message: 'Must include a video' })
    video: IVideo;

    setTitle() {
        const subtitle = this?.video?.title || '--';
        this.subtitle = truncateStringWithEllipsis(subtitle, 50);
    }

    itemHasChanges(state: IConvoState): boolean {
        const contentItemFactory = new VideoContentItemFactory();
        const contentItem = contentItemFactory.make(this, state);
        contentItem.version = hash(contentItem);
        if (this.originalVersion) {
            return contentItem.version !== this.originalVersion;
        }
        return false;
    }

    toContentItem(state: IConvoState): IContentItem {
        const contentItemFactory = new VideoContentItemFactory();
        return contentItemFactory.make(this, state);
    }

    toAccordionItem(contentItem: IContentItem, isClone?: boolean): AccordionItemBase {
        const accordionItemFactory = new VideoAccordionItemFactory();
        return accordionItemFactory.make(contentItem);
    }

    async validate(state: IConvoState, path: number[]): Promise<any[]> {
        const errors = await validateClass(this);
        const errorMessages: any[] = [];

        if (errors.length > 0) {
            errors.forEach((item) => {
                errorMessages.push({
                    path,
                    errors: { [item.property]: Object.values(item.constraints || {}) },
                });
            });
        }

        return errorMessages;
    }
}
