export interface IReportBreakAnswer {
    title: string;
    id: string;
}

export interface IReportBreak {
    title: string;
    answers: IReportBreakAnswer[];
    cells: {
        [key: string]: number;
    };
}

export class ReportBreak implements IReportBreak {
    title: string;
    answers: IReportBreakAnswer[];
    cells: {
        [key: string]: number;
    };
}
