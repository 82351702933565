import { ContentClient } from '@/api/content';
import { IPaging } from '@inconvo/types/interfaces';
import { GetConvosRequest } from '../models/dtos';
import { IConvo } from '../models/interfaces';

export const getConvos = async ({
    query,
    channelCode,
    channelId,
    page,
    pageSize,
    convoTypes = [],
    status,
    chatGroup,
    includeTopics,
    excludeTopics,
}: {
    query: string;
    channelCode?: string;
    channelId?: number;
    page: number;
    pageSize?: number;
    convoTypes?: string[];
    status?: number;
    chatGroup?: number;
    includeTopics?: string;
    excludeTopics?: string;
}) => {
    const request = new GetConvosRequest();
    if (channelCode) {
        request.channelCode = channelCode;
    }
    if (channelId) {
        request.channelId = channelId;
    }
    request.q = query;
    request.page = page || 1;
    request.size = pageSize || 10;
    request.includeGenerated = false;
    request.type = convoTypes.join(',');
    request.status = status;
    request.chatGroup = chatGroup;
    request.includeTopics = includeTopics;
    request.excludeTopics = excludeTopics;

    return (await ContentClient.getConvos(request)) as IPaging<IConvo>;
};
