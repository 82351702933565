import { Message } from './message';
import { MessageType } from './enums/messageType.enum';

export class Attachment extends Message {
    text: string;
    url: string;

    constructor(text: string, url: string, avatarUrl: string) {
        super(MessageType.Attachment, avatarUrl);

        this.text = text;
        this.url = url;
    }
}
