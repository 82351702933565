import { Message } from './message';
import { MessageType } from './enums/messageType.enum';

export class Video extends Message {
    icon: string;
    image: string;
    title: string;
    duration: string;

    constructor(avatarUrl: string) {
        super(MessageType.Video, avatarUrl);
        this.icon = 'video';
        this.image = '';
        this.title = '';
        this.duration = '';
    }
}
