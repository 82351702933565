interface IApiErrorMessages {
    [key: string]: string;
}

const apiErrorMessages: IApiErrorMessages = {
    E10001: 'Another channel exists with same slug.',
    E10002: 'Slug already in use by another channel through Redirections.',
    E10003: 'Channel could not be associated with multiple tenants.',
    E10004: 'Tag already in use by another channel.',
};

export default apiErrorMessages;
