<template>
    <div v-click-outside="clickOutside" class="timepicker">
        <Input
            :id="id"
            ref="input"
            type="text"
            :name="name"
            :label="label"
            :value="displayTime"
            :errors="errors"
            :has-margin-bottom="false"
            :placeholder="placeholder"
            default-icon="clock"
            :default-icon-size="30"
            @focus="openPicker"
            @click-icon="openPicker"
        />
        <input
            v-if="isMobileDevice"
            type="time"
            class="mobile-date-selector"
            :value="mobileInputValue"
            @change="onMobileDateInputChange"
        />
        <div v-show="showSelector" class="timepicker-select">
            <div ref="hoursScroll" class="timepicker-select__column">
                <button
                    v-for="i in 12"
                    :key="`hours-${i}`"
                    class="timepicker-select-button"
                    :class="{ 'timepicker-select-button--active': time.hours === i }"
                    @click="setHours(i)"
                >
                    {{ setLeadingZero(i) }}
                </button>
            </div>
            <div ref="minutesScroll" class="timepicker-select__column">
                <button
                    v-for="i in range(60)"
                    :key="`minute-${i}`"
                    class="timepicker-select-button"
                    :class="{ 'timepicker-select-button--active': time.minutes === i }"
                    @click="setMinutes(i)"
                >
                    {{ setLeadingZero(i) }}
                </button>
            </div>
            <div class="timepicker-select__column">
                <button
                    class="timepicker-select-button"
                    :class="{ 'timepicker-select-button--active': time.amOrPm === 'am' }"
                    @click="setAmOrPm('am')"
                >
                    am
                </button>
                <button
                    class="timepicker-select-button"
                    :class="{ 'timepicker-select-button--active': time.amOrPm === 'pm' }"
                    @click="setAmOrPm('pm')"
                >
                    pm
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Input from '@/components/forms/Input';
import { isMobileDevice } from '@/utils/mobileDetection.ts';
import { range, addLeadingZero } from '@/utils';

export default {
    name: 'Timepicker',
    components: {
        Input,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: String, // Time value format hh:mm:ss
            default: undefined,
        },
        placeholder: {
            type: String,
            default: 'Select time',
        },
        errors: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isMobileDevice: isMobileDevice(),
            showSelector: false,
            time: {
                hours: null,
                minutes: null,
                seconds: null,
                amOrPm: null,
            },
        };
    },
    computed: {
        displayTime() {
            return this.display12HoursFormat(this.time);
        },
        mobileInputValue() {
            return this.display24HoursFormat(this.convert12HoursObjectTo24HoursObject(this.time));
        },
    },
    watch: {
        value(newValue) {
            this.setTime(newValue);
        },
    },
    created() {
        this.setTime(this.value);
    },
    methods: {
        range,
        setLeadingZero: addLeadingZero,
        openPicker() {
            this.showSelector = !this.showSelector;
            this.$refs.input.$refs.input.blur();

            if (this.showSelector) {
                this.$nextTick(() => {
                    // scroll to the active element
                    if (this.time.hours !== null && this.time.minutes !== null) {
                        this.$refs.hoursScroll.scrollTop = this.$refs.hoursScroll.querySelector(
                            '.timepicker-select-button--active',
                        ).offsetTop;
                        this.$refs.minutesScroll.scrollTop = this.$refs.minutesScroll.querySelector(
                            '.timepicker-select-button--active',
                        ).offsetTop;
                    } else {
                        this.$refs.hoursScroll.scrollTop = 0;
                        this.$refs.minutesScroll.scrollTop = 0;
                    }
                });
            }
        },
        clickOutside() {
            this.showSelector = false;
        },
        onSelected() {
            if (
                this.time.hours === null ||
                this.time.minutes === null ||
                this.time.amOrPm === null
            ) {
                this.$emit('change', '');
            } else {
                this.$emit(
                    'change',
                    this.display24HoursFormat({
                        ...this.convert12HoursObjectTo24HoursObject(this.time),
                        seconds: 0,
                    }),
                );
            }
        },
        onMobileDateInputChange() {
            if (!event.target.value || event.target.value === '') {
                this.$emit('change', '');
            } else {
                this.$emit(
                    'change',
                    this.display24HoursFormat({
                        ...this.get24HoursFormatAsObject(event.target.value),
                        seconds: 0,
                    }),
                );
            }
        },
        setHours(hours) {
            this.time.hours = hours;
            this.onSelected();
        },
        setMinutes(minutes) {
            this.time.minutes = minutes;
            this.onSelected();
        },
        setAmOrPm(amOrPm) {
            this.time.amOrPm = amOrPm;
            this.onSelected();
        },
        get24HoursFormatAsObject(time) {
            const timeArray = time.split(':');
            const minutes = parseInt(timeArray[1], 10);
            const seconds = parseInt(timeArray[2], 10);
            let hours = parseInt(timeArray[0], 10);

            hours = hours !== null && hours > 23 ? 0 : hours;

            return {
                hours,
                minutes,
                seconds,
            };
        },
        convert24HoursObjectTo12HoursObject(timeObject) {
            const amOrPm = timeObject.hours >= 12 ? 'pm' : 'am';
            const hours = timeObject.hours % 12 || 12;

            return {
                hours,
                minutes: timeObject.minutes,
                seconds: timeObject.seconds,
                amOrPm,
            };
        },
        convert12HoursObjectTo24HoursObject(timeObject) {
            let hours = timeObject.hours;

            if (timeObject.amOrPm === 'pm') {
                hours = hours + 12;

                if (hours >= 24) {
                    hours = 12;
                }
            } else if (hours >= 12) {
                hours = 0;
            }

            return {
                hours,
                minutes: timeObject.minutes,
                seconds: timeObject.seconds,
            };
        },
        display24HoursFormat(timeObject) {
            if (timeObject.hours === null || timeObject.minutes === null) {
                return '';
            }

            return `${this.setLeadingZero(timeObject.hours)}:${this.setLeadingZero(
                timeObject.minutes,
            )}:${this.setLeadingZero(timeObject.seconds)}`;
        },
        display12HoursFormat(timeObject) {
            if (
                timeObject.hours === null ||
                timeObject.minutes === null ||
                timeObject.amOrPm === null
            ) {
                return '';
            }

            return `${this.setLeadingZero(timeObject.hours)}:${this.setLeadingZero(
                timeObject.minutes,
            )} ${timeObject.amOrPm}`;
        },
        setTime(value) {
            if (!value || value === '') {
                this.time = {
                    hours: null,
                    minutes: null,
                    seconds: 0,
                    amOrPm: null,
                };
            } else {
                this.time = this.convert24HoursObjectTo12HoursObject(
                    this.get24HoursFormatAsObject(value),
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.timepicker {
    position: relative;
    min-width: 112px;
}

.mobile-date-selector {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    width: 100%;
    height: 35px;
    opacity: 0;
}

.timepicker-select {
    z-index: $timepicker-select-z-index;
    display: flex;
    position: absolute;
    top: 36px;
    left: 0;
    width: 140px;
    height: 100px;
    border: 1px solid $timepicker-select-border-color;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: $timepicker-select-bg-color;

    &__column {
        width: 33.333%;
        overflow: hidden;
        overflow-y: auto;
    }
}

.timepicker-select-button {
    width: 100%;
    display: block;
    padding: 5px;
    text-align: center;
    cursor: pointer;

    &:hover {
        background: $timepicker-select-button-hover-bg-color;
    }

    &--active {
        &,
        &:hover {
            background: $timepicker-select-button-selected-bg-color;
            color: $timepicker-select-button-selected-text-color;
        }
    }
}
</style>
