// based on https://github.com/ichord/Caret.js; see also https://www.slatejs.org/#/mentions
export default {
    data() {
        return {
            contentEditorElement: null,
        };
    },
    methods: {
        getCarretPosition() {
            let clonedRange, pos;
            const range = this.range();
            if (range) {
                clonedRange = range.cloneRange();
                clonedRange.selectNodeContents(this.contentEditorElement);
                clonedRange.setEnd(range.endContainer, range.endOffset);
                pos = clonedRange.toString().length;
                clonedRange.detach();
            }
            return Number(pos);
        },

        getCareyXYPosition() {
            const offset = this.getOffset();
            if (offset) {
                offset.left -= this.contentEditorElement.offsetLeft;
                offset.top -= this.contentEditorElement.offsetTop;
            }

            return offset;
        },

        setPos(pos) {
            let found, offset;
            const sel = window.getSelection();
            if (sel) {
                offset = 0;
                found = false;
                const fn = (pos, parent) => {
                    let node, range, ref, results;
                    ref = parent.childNodes;
                    results = [];
                    for (let i = 0, len = ref.length; i < len; i++) {
                        node = ref[i];
                        if (found) {
                            break;
                        }
                        if (node.nodeType === 3) {
                            if (offset + node.length >= pos) {
                                found = true;
                                range = document.createRange();
                                range.setStart(node, pos - offset);
                                sel.removeAllRanges();
                                sel.addRange(range);
                                break;
                            } else {
                                results.push((offset += node.length));
                            }
                        } else {
                            results.push(fn(pos, node));
                        }
                    }
                    return results;
                };

                fn(pos, this.contentEditorElement);
            }
            return this.contentEditorElement;
        },

        getOffset() {
            let clonedRange, offset, rect, shadowCaret;
            const range = this.range();
            if (window.getSelection && range) {
                if (range.endOffset - 1 > 0 && range.endContainer !== this.contentEditorElement) {
                    clonedRange = range.cloneRange();
                    clonedRange.setStart(range.endContainer, range.endOffset - 1);
                    clonedRange.setEnd(range.endContainer, range.endOffset);
                    rect = clonedRange.getBoundingClientRect();
                    offset = {
                        height: rect.height,
                        left: rect.left + rect.width,
                        top: rect.top,
                    };
                    clonedRange.detach();
                }
                if (!offset || (offset != null ? offset.height : void 0) === 0) {
                    clonedRange = range.cloneRange();
                    shadowCaret = document.createTextNode('|');
                    clonedRange.insertNode(shadowCaret);
                    clonedRange.selectNode(shadowCaret);
                    rect = clonedRange.getBoundingClientRect();
                    offset = {
                        height: rect.height,
                        left: rect.left,
                        top: rect.top,
                    };
                    shadowCaret.remove();
                    clonedRange.detach();
                }
            }

            if (offset) {
                offset.top += document.body.scrollTop;
                offset.left += document.body.scrollLeft;
            }
            return offset;
        },

        getCurrentWord(char) {
            const text = String(this.contentEditorElement.innerText.replace(/\n/g, ''));
            const currentPosition = this.getCarretPosition();

            let leftRegex = /\S+$/,
                rightRegex = /\s/;

            const previousCharIsSpace = text.slice(currentPosition - 1, currentPosition) === ' ';

            if (previousCharIsSpace) {
                return '';
            }

            const textBeforeCarret = text.slice(0, currentPosition);
            const textAfterCarret = text.slice(currentPosition - 1);

            let left = textBeforeCarret.search(leftRegex);
            let nextSpaceAfterCarret = textAfterCarret.search(rightRegex);

            let right = nextSpaceAfterCarret;

            if (char) {
                left = textBeforeCarret.lastIndexOf(char);
                const nextCharIndex = textAfterCarret.slice(1).indexOf(char);
                const charCount = (textAfterCarret.match(new RegExp('@', 'g')) || []).length;
                if (nextCharIndex < nextSpaceAfterCarret && nextCharIndex >= 0 && charCount > 1) {
                    right = nextCharIndex;
                }
            }

            if (left < 0) {
                return {};
            }

            const nextSpaceAfterWordStart = text.slice(left).search(rightRegex);

            if (nextSpaceAfterCarret < 0) {
                if (nextSpaceAfterWordStart >= 0) {
                    return {
                        start: left,
                        end: nextSpaceAfterWordStart - 1,
                        text: text.slice(left, nextSpaceAfterWordStart - 1),
                    };
                } else {
                    return {
                        start: left,
                        end: right,
                        text: text.slice(left),
                    };
                }
            }

            right += currentPosition;

            return {
                start: left,
                end: right,
                text: text.slice(left, right).replace(/\s/g, ''),
            };
        },

        replaceContent(char, replace) {
            const currentWord = this.getCurrentWord(char);
            const sel = window.getSelection();
            if (sel) {
                const range = sel.getRangeAt(0).cloneRange();

                if (range.endContainer.nodeValue) {
                    const wordStart = range.endContainer.nodeValue.lastIndexOf(currentWord.text);
                    var wordEnd = wordStart + currentWord.text.length;

                    range.setStart(range.endContainer, wordStart);
                    range.setEnd(range.endContainer, wordEnd);
                    range.deleteContents();

                    if (replace) {
                        range.insertNode(document.createTextNode(' '));
                        range.insertNode(replace);
                    }

                    this.setPos(currentWord.start + range.toString().length);
                }
            }
        },

        range() {
            if (!window.getSelection) {
                return;
            }
            const sel = window.getSelection();
            if (sel && sel.rangeCount > 0) {
                return sel.getRangeAt(0);
            } else {
                return;
            }
        },
    },
};
