import { RuleMap, IRuleSet, IRule } from '@/store/models/interfaces/audience.interface';
import { AudienceRequest } from '@/store/models/dtos/audienceRequests.dto';
import { IAudience } from '@/store/models/interfaces';

export class AudienceRequestFactory {
    public static make(data: IAudience, rules: RuleMap): AudienceRequest {
        const rulesCopy = JSON.parse(JSON.stringify(Object.values(rules)));
        const request = new AudienceRequest();

        request.id = data.id;
        request.name = data.name;
        request.tenantCode = data.tenantCode;
        request.exportTo = data.exportTo;
        request.exportPlatform = data.exportPlatform;
        request.rules = rulesCopy
            .filter((ruleItem: IRule | IRuleSet) => ruleItem.ruleType !== undefined)
            .map((ruleItem: IRule | IRuleSet) => {
                if (ruleItem.ruleType === 'rule') {
                    delete ruleItem.content;
                }
                return ruleItem;
            });

        return request;
    }
}
