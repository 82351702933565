import { getErrorMessage } from '../services/validationService';
import { IChannelGroupReport, IChannelReportRow } from './interfaces/channelGroupReport.interface';
import { IValidatable } from './interfaces/validatable.interface';
import { ArrayNotEmpty, IsNotEmpty, validate as validator } from 'class-validator';
export class ChannelGroupReport implements IChannelGroupReport, IValidatable<IChannelGroupReport> {
    constructor() {
        this.id = 0;
        this.name = '';
        this.tagConfig = [];
    }

    id: number;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Name') })
    name: string;

    @ArrayNotEmpty({ message: getErrorMessage('isNotEmpty', 'Tags') })
    tagConfig: string[];

    createdAt: string;

    updatedAt: string;

    reportData?: IChannelReportRow;

    originalVersion: string;

    async validate() {
        const errors = await validator(this);
        return errors.reduce((acc, cur) => {
            acc[cur.property] = Object.values(cur.constraints || {});
            return acc;
        }, <any>{});
    }
}
