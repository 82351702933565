import { ContentType } from '@/store/enums';

export const AUTOCOMPLETE_PLACEHOLDERS = {
    [ContentType.Any]: 'Search for channels, convos, menu, messages',
    [ContentType.Channel]: 'Search for channels',
    [ContentType.Convo]: 'Search for convos',
    [ContentType.Menu]: 'Search for menu',
    [ContentType.Message]: 'Search for messages',
};

export const CONTENT_COUNT_MAP = {
    [ContentType.Channel]: 1,
    [ContentType.Convo]: 2,
    [ContentType.Menu]: 2,
    [ContentType.Message]: 3,
    [ContentType.Any]: 3,
};

export const DEFAULTS = {
    title: 'Content Selector',
    description: 'Type to search Channels, Convos, Menu or Messages',
    confirmButtonText: 'Select',
    clearButtonText: 'Clear all',
    listHeaderText: 'Selected content item...',
    validationContentType: ContentType.Any,
    includeInactive: false,
    fixedChannel: false,
    fixedConvo: false,
    contentTypes: () => [],
};
