import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum';
import { ListMarker } from '@/store/enums/listMarker.enum';
import { AccordionItemBase, InlineResponse, LogicJump, QuickReply } from '@/store/models';
import { getErrorMessage } from '@/store/services/validationService';
import { IsNotEmpty, Length, validate, ValidationError } from 'class-validator';
import { v4 as uuid } from 'uuid';
import { ConvoTypes } from '../enums';
import { Attributes } from '../enums/attributes.enum';
import { IConvoState } from '../modules/convo-details';
import { IButtonAttributes, IDatumContentItemAnswer } from './interfaces';
import { IQuizOptions } from './interfaces/quizOptions.interface';

export class QuickReplyAnswer
    extends AccordionItemBase
    implements IQuizOptions, IButtonAttributes, IDatumContentItemAnswer
{
    constructor(items = []) {
        super(items);
        this.type = ConvoMessageItemType.QuickReplyAnswer;
        this.answerId = uuid();
        this.buttonLabel = '';
        this.buttonImageUrl = '';
        this.isDraggable = true;
        this.isDeletable = true;
        this.markerType = ListMarker.Numeral;
        this.items = [new InlineResponse()];
        this.logicJump = new LogicJump();
        this.isCorrectAnswer = false;
        this.attributes = [];
        this.datumResponseCode = '';
    }

    answerId: string;
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Button Label') })
    @Length(0, 100, { message: getErrorMessage('length', 'Button Label', { min: 0, max: 100 }) })
    buttonLabel: string;
    buttonImageUrl: string;
    isCorrectAnswer: boolean;
    isExclusive?: boolean;
    selectedPersonalityQuizResult: number | undefined;
    logicJump: LogicJump;
    datumResponseCode: string;

    attributes: Attributes[];

    async validate(state: IConvoState, path: number[]): Promise<any> {
        const errors: ValidationError[] = await validate(this);
        const errorMessages: any[] = [];
        if (errors.length > 0) {
            errors.forEach((item: ValidationError) => {
                errorMessages.push({
                    path,
                    errors: { [item.property]: Object.values(item.constraints || {}) },
                });
            });
        }

        // Inline response
        if (this.items[0].items[0]) {
            const inlineResponseErrorMessages = await this.items[0].items[0].validate(state, [
                ...path,
                0,
                0,
            ]);
            errorMessages.push(...inlineResponseErrorMessages);
        }

        // Personality quiz
        const quickReply = state.messagesView.messageItems[path[0]] as QuickReply;

        if (
            state.convo.type === ConvoTypes.PersonalityQuiz &&
            (this.selectedPersonalityQuizResult === undefined ||
                this.selectedPersonalityQuizResult === null) &&
            !quickReply.ignoreQuizQuestion
        ) {
            errorMessages.push({
                path,
                errors: {
                    selectedPersonalityQuizResult: [
                        getErrorMessage('selectOption', 'Personality Quiz Result'),
                    ],
                },
            });
        }

        return errorMessages;
    }

    clearDatumAnswer(): void {
        this.datumResponseCode = '';
    }
}
