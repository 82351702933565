<template>
    <div
        class="default-accordion-item-wrapper"
        :class="[
            {
                opened: open,
                'has-m-b': hasMarginBottom,
                'default-accordion-item-wrapper--deleted': isDeleted,
            },
            `default-accordion-item-wrapper--${accordionStyle}`,
        ]"
    >
        <div class="drag-and-drop-handle-wrapper">
            <button v-if="isDraggable" class="drag-and-drop-handle">
                <Icon name="drag-handle" />
            </button>
        </div>
        <AccordionItem
            class="default-accordion-item"
            :open="open"
            :is-openable="isOpenable"
            :is-pdl="isPdl"
            :is-external-pdl="isExternalPdl"
            :class="{ 'is-openable': isOpenable }"
            :duration="duration"
            :animation-disabled="animationDisabled"
            @headerOnClick="!isDeleted && $emit('headerOnClick', $event)"
            @onExpand="$emit('onExpand')"
            @onExpanded="$emit('onExpanded')"
            @onContract="$emit('onContract')"
            @onContracted="$emit('onContracted')"
        >
            <template #header>
                <div
                    class="default-accordion-item-header"
                    :class="{
                        opened: open,
                        'animation-enabled': !animationDisabled,
                        incomplete: isValid === false && !isDeleted,
                        'is-pdl': isPdl,
                    }"
                >
                    <slot name="header" />

                    <div class="badges">
                        <span v-if="isNew" class="badge">new</span>
                        <span v-if="isPdl" class="badge">PDL</span>
                    </div>

                    <Icon
                        v-if="isOpenable && !isDeleted"
                        name="arrow-down"
                        class="accordion-arrow"
                    />
                </div>
                <button
                    v-if="isDeleted"
                    class="undo-delete-label"
                    @click.stop="$emit('undoDeletedItemOnClick')"
                >
                    Undo delete
                    <Icon name="undo" />
                </button>
            </template>
            <template>
                <div
                    class="default-accordion-item-body"
                    :class="{ 'default-accordion-item-body--large-padding': bodyHasLargePadding }"
                >
                    <slot />
                </div>
            </template>
        </AccordionItem>
        <div class="accordion-item-buttons">
            <Menu v-if="!isDeleted && isDeletable" button-icon="settings">
                <DropDownItem icon="delete-full" @click="$emit('deleteOnClick', $event)"
                    >Delete item</DropDownItem
                >
                <DropDownItem
                    v-if="cloneEnabled"
                    icon="clone-convo"
                    @click="$emit('cloneOnClick', $event)"
                    >Clone</DropDownItem
                >
            </Menu>
        </div>
    </div>
</template>

<script>
import AccordionItem from '@/components/ui/Accordion/AccordionItemBase';
import Icon from '@/components/ui/Icon';
import Menu from '@/components/ui/Menu';
import DropDownItem from '@/components/ui/DropDown/DropDownItem';
import { AccordionStyle as accordionStyles } from '@/store/enums/accordionStyle.enum.ts';

export default {
    name: 'DefaultAccordionItem',
    components: {
        AccordionItem,
        Icon,
        Menu,
        DropDownItem,
    },
    props: {
        open: {
            type: Boolean,
            required: true,
        },
        isDraggable: {
            type: Boolean,
            required: true,
        },
        isDeletable: {
            type: Boolean,
            required: true,
        },
        isOpenable: {
            type: Boolean,
            default: true,
        },
        marker: {
            type: [String, Number],
            default: undefined,
        },
        duration: {
            type: Number,
            default: 300,
        },
        animationDisabled: {
            type: Boolean,
            default: false,
        },
        hasMarginBottom: {
            type: Boolean,
            default: true,
        },
        isValid: {
            type: Boolean,
            default: true,
        },
        isDeleted: {
            type: Boolean,
            default: false,
        },
        isNew: {
            type: Boolean,
            default: false,
        },
        isPdl: {
            type: Boolean,
            default: false,
        },
        isExternalPdl: {
            type: Boolean,
            default: false,
        },
        accordionStyle: {
            type: String,
            default: accordionStyles.Default,
        },
        bodyHasLargePadding: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        cloneEnabled: function () {
            return !this.$router.currentRoute.path.includes('/pdl/') && !this.isPdl;
        },
    },
};
</script>

<style lang="scss" scoped>
$header-height: 52px;

.default-accordion-item-wrapper {
    position: relative;

    &.has-m-b {
        margin-bottom: 15px;
    }

    &--secondLevel {
        .default-accordion-item-header,
        .default-accordion-item-body {
            background: $container-level2-main-color;
        }
    }

    &--result {
        .default-accordion-item-header,
        .default-accordion-item-body {
            background: $container-result-main-color;
        }
    }

    &--deleted {
        .default-accordion-item-header {
            box-shadow: none;
            background: rgba($container-default-main-color, 0.6);
            border-color: rgba($container-default-border-color, 0.6);
        }

        .drag-and-drop-handle {
            color: $container-default-deleted-drag-and-drop-handle-color;
        }
    }
}

.default-accordion-item {
    position: relative;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;

    &.is-openable {
        .default-accordion-item-header {
            padding-right: 42px;
            cursor: pointer;
        }
    }
}

.default-accordion-item-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: $header-height;
    padding: 7px 10px;
    border-radius: 4px;
    background: $container-default-main-color;
    font-size: 14px;
    overflow: hidden;
    align-items: center;
    border: solid 1px $container-default-border-color;
    box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.03);

    &.opened {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        > .arrow {
            transform: rotate(180deg);
        }
    }

    &.incomplete {
        &:after {
            content: '';
            background-color: $error-color;
            left: -7px;
            top: -7px;
            height: 14px;
            width: 14px;
            position: absolute;
            transform: rotate(-45deg);
        }
    }

    &.is-pdl {
        background-color: $container-level2-main-color;
    }

    .badges {
        display: flex;
    }

    .badge {
        margin-left: 4px;
        display: flex;
        align-items: center;
        height: 15px;
        padding: 0 5px;
        font-size: $font-size-xxs;
        text-transform: uppercase;
        color: $container-default-main-color;
        background-color: $container-default-second-color;
        border-radius: 3px;
    }

    .accordion-arrow {
        @include center(y);
        position: absolute;
        right: 15px;
        color: $container-default-second-color;
        font-size: 10px;
    }

    &.animation-enabled {
        .arrow {
            transition: transform 0.3s;
        }
    }
}

.default-accordion-item-body {
    padding: 22px 20px 16px;
    background: $container-default-main-color;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px $container-default-border-color;
    border-top: none;

    &--large-padding {
        padding: 22px 30px;
    }
}

.accordion-item-buttons {
    position: absolute;
    top: 0;
    right: $message-editor-right-padding * -1;
    display: flex;
    align-items: center;
    height: $header-height;
}

.drag-and-drop-handle-wrapper {
    position: absolute;
    top: 0;
    left: $message-editor-left-padding * -1;
    display: flex;
    align-items: center;
    height: $header-height;
    padding-right: 10px;
}

.drag-and-drop-handle {
    color: $container-default-second-color;
    font-size: 11px;
    cursor: ns-resize;
}

.sortable-ghost {
    opacity: 0.5;
}

.undo-delete-label {
    display: flex;
    align-items: center;
    position: absolute;
    top: 1px;
    right: 1px;
    height: $header-height - 2px;
    padding: 8px;
    border-radius: 0 4px 4px 0;
    background: rgba($container-default-main-color, 0.6);
    font-family: $font-family-button;
    font-size: $font-size-xxs;
    color: $container-default-undo-label-text-color;
    text-transform: uppercase;
    cursor: pointer;

    .icon {
        margin-left: 4px;
        font-size: 22px;
    }
}
</style>
