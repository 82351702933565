import { IWidgetReply } from '@/store/models/interfaces/widgetReply.interface.dto';
import { IContentItem, IResultOption } from '@/store/models/interfaces';
import { ContentItemType, ConvoMessageItemType, ConvoTypes } from '@/store/enums/';
import { ContentItem, DatePicker, TextGlobalResponse } from '@/store/models';
import { IConvoState } from '@/store/modules/convo-details';
import {
    DateRangeType,
    DateType,
    IValidationRules,
} from '@/store/models/interfaces/validationRules.interface';
import { Answer } from '@/store/models/answer.dto';

export class DatePickerContentItemFactory {
    make(datePicker: DatePicker, state: IConvoState): IContentItem {
        const contentItem = new ContentItem();
        contentItem.id = datePicker.id;
        contentItem.sort = datePicker.sort;
        contentItem.title = datePicker.message;
        contentItem.type = ContentItemType.DatePicker;
        contentItem.answers = this.getMockAnswers(state.convo.id);
        contentItem.reply = this.buildReply(datePicker);
        contentItem.validationRules = this.buildValidationRules(datePicker);
        contentItem.tags = datePicker.tags || [];
        contentItem.isPdl = datePicker.isPdl;
        contentItem.shouldSkipIfAnswered = datePicker.shouldSkipIfAnswered;

        if (state.convo.type === ConvoTypes.YougovWelcomeChat) {
            contentItem.externalId = datePicker.datumDefinition || '';
        } else {
            contentItem.externalId = datePicker.externalId || '';
        }

        return contentItem;
    }

    private buildValidationRules(datePicker: DatePicker) {
        const rules = {} as IValidationRules;
        rules.submitButtonText = datePicker.submitButtonText;
        rules.dateType = datePicker.dateType as DateType;
        rules.submitButtonText = datePicker.submitButtonText;
        if (datePicker.dateType === DateType.Any) {
            rules.dateRangeType = undefined;
            rules.dateRangeValue = undefined;
            rules.dateRangeValueOffset = undefined;
        } else {
            rules.dateRangeType = datePicker.dateRangeType as DateRangeType;
            rules.dateRangeValue = datePicker.dateRangeValue;
            rules.dateRangeValueOffset = datePicker.dateRangeValueOffset;
        }
        return rules;
    }

    private buildReply(datePicker: DatePicker) {
        const globalResponse = datePicker.items.find(
            (i) => i.type === ConvoMessageItemType.TextGlobalResponse,
        ) as TextGlobalResponse;
        const reply = {} as IWidgetReply;
        reply.activated = !!globalResponse.message;
        reply.message = globalResponse.message;
        reply.sortingOrder = globalResponse.sortingOrder;
        reply.resultOptions = {} as IResultOption;
        reply.tagLine = '';
        reply.url = '';
        reply.imageUrl = '';
        return reply;
    }

    private getMockAnswers(convoId: number) {
        // pass along a mock answer object
        // in order to force the convo engine
        // to create a choice state
        const answer = new Answer();
        answer.convoId = convoId;
        answer.targetChannel = '';
        answer.targetConvo = 0;
        answer.targetContentItem = 0;
        answer.goToBlock = 0;
        answer.goToWidget = 0;
        return [answer];
    }
}
