import { IUsedDefinitions } from '@/interfaces';
import errorMessages from '@/json/errorMessages';
import templateVariables from '@/json/templateVariables';
import warningMessage from '@/json/warningMessages';
import { DatumContentItem, DatumContentItemAnswer } from '@/store/models/types';
import { ChartType, ConvoMessageItemType, SortingOrder } from '../enums';
import { AccordionItemBase, TextGlobalResponse } from '../models';
import { IResultOption } from '../models/interfaces';
import { IYougovDefinitionResponse, YougovDefinition } from '../models/yougovDefinition';

const getErrorMessage = (key: string, name?: string, variables?: object): string => {
    return errorMessages[key]({ name, ...variables });
};

const getWarningMessage = (key: string, name?: string, variables?: object): string => {
    return warningMessage[key]({ name, ...variables });
};

const validateFile = (
    file: File,
    { maxFileSize, extensions }: { maxFileSize?: number; extensions?: string[] },
    name?: string,
) => {
    if (!file) {
        return getErrorMessage('isNotEmpty', name);
    }

    if (file.name && extensions && extensions.indexOf(getFileExtension(file.name)) === -1) {
        return getErrorMessage('fileExtension', name, { extensions });
    }

    if (maxFileSize && file.size > maxFileSize) {
        return getErrorMessage('fileSize', name, { fileSize: file.size, maxFileSize });
    }

    return null;
};

const getFileExtension = (fileName: string): string => {
    const result = /[.]/.exec(fileName) ? /[^.]+$/.exec(fileName) : undefined;

    if (result && result.length > 0) {
        return result[0].toLowerCase();
    }

    return '';
};

const validateResultOptions = (
    resultOption: IResultOption,
    textGlobalResponse: TextGlobalResponse,
    globalResponseErrors: any,
): void => {
    if (!(resultOption.chartType == ChartType.None && resultOption.order == SortingOrder.None)) {
        if (textGlobalResponse.message.replace(/\&nbsp;/g, '').trim() === '') {
            globalResponseErrors.message = [];
            globalResponseErrors.message.push(
                getErrorMessage('isNotEmptyConditional', 'Message', { condition: 'Result Option' }),
            );
        } else {
            const templates = templateVariables.find((obj) => obj.name === 'Templates');
            const templateValues = new Set<string>();
            if (templates) {
                templates.items.forEach((item: { value: string }) =>
                    templateValues.add(item.value),
                );
            }
            templateValues.forEach((value: string) => {
                if (textGlobalResponse.message.includes(value)) {
                    globalResponseErrors.message = [];
                    globalResponseErrors.message.push(getErrorMessage('resultOptionTemplate'));
                    return false;
                }
            });
        }
    }
};

const validateDatumResponses = (
    messageItems: AccordionItemBase[],
    definitions: YougovDefinition[],
): string[] => {
    const errors: string[] = [];
    const usedDefinitions: IUsedDefinitions = {};

    messageItems.forEach((messageItem) => {
        if (messageItem.isDeleted) {
            return;
        }

        const definitionCode = (messageItem as DatumContentItem).datumDefinition;
        if (
            definitionCode &&
            [ConvoMessageItemType.QuickReply, ConvoMessageItemType.Buttons].includes(
                messageItem?.type as ConvoMessageItemType,
            )
        ) {
            const definition = definitions.find(
                (item: YougovDefinition) => item.code === definitionCode,
            );
            if (!definition?.isCompletelyFetched) {
                return;
            }

            if (!usedDefinitions[definitionCode]) {
                usedDefinitions[definitionCode] = [];
            }

            (messageItem?.items[0]?.items as unknown as DatumContentItemAnswer[]).forEach(
                (item: DatumContentItemAnswer) => {
                    if (
                        item.datumResponseCode &&
                        !usedDefinitions[definitionCode].includes(item.datumResponseCode)
                    ) {
                        usedDefinitions[definitionCode].push(item.datumResponseCode);
                    }
                },
            );
        }
    });

    const existingErrors = checkForUsedDefinitionErrors(usedDefinitions, definitions);

    if (existingErrors.length) {
        errors.push(...existingErrors);
    }

    return errors;
};

const checkForUsedDefinitionErrors = (
    usedDefinitions: IUsedDefinitions,
    definitions: YougovDefinition[],
): string[] => {
    const errors: string[] = [];
    Object.entries(usedDefinitions).forEach(([code, selectedResponses]: [string, string[]]) => {
        const definition = definitions.find((item: YougovDefinition) => item.code === code);

        const valid = definition?.responses?.every((response: IYougovDefinitionResponse) =>
            selectedResponses.includes(response.code),
        );

        if (!valid) {
            errors.push(`Some responses for ${definition?.code} are not defined.`);
        }
    });

    return errors;
};

export {
    getErrorMessage,
    validateFile,
    validateResultOptions,
    getWarningMessage,
    validateDatumResponses,
};
