import { AccordionItemBase } from '../models/accordionItemBase';

export const traversal = (
    items: AccordionItemBase[],
    callback: (item: AccordionItemBase) => void,
): void => {
    if (items && items.length > 0) {
        for (let i = 0; i < items.length; i++) {
            callback(items[i]);
            traversal(items[i].items, callback);
        }
    }
};

export const getLevel = (items: AccordionItemBase[], path: number[]): AccordionItemBase[] => {
    let level = items;
    const pathCopy: number[] = [...path];
    pathCopy.pop();

    for (let i = 0; i < pathCopy.length; i++) {
        if (level[pathCopy[i]].items) {
            level = level[pathCopy[i]].items;
        } else {
            level = [];
        }
    }

    return level;
};

export const getAccordionItem = (
    items: AccordionItemBase[],
    path: number[],
): AccordionItemBase | undefined => {
    const level = getLevel(items, path);

    if (level) {
        return level[path[path.length - 1]];
    }

    return undefined;
};
