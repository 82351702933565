<template>
    <div class="button-wrapper">
        <div class="button-wrapper__element">
            <button v-if="isDraggable && !isPinned" class="drag-and-drop-handle">
                <Icon name="drag-handle" />
            </button>
            <Icon v-else-if="isPinned" name="pin" class="pin-icon" />
            <div v-if="marker" class="marker">{{ marker }}</div>
        </div>
        <div class="button-wrapper__center">
            <slot name="header" />
            <div
                class="button-wrapper__body"
                :class="{ 'button-wrapper__body--has-attributes': hasAttributes }"
            >
                <Icon v-if="hasAttributes" name="hierarchy" class="attributes-icon" />
                <slot name="attributes" />
                <slot />
                <slot name="responses" />
            </div>
        </div>
        <div class="button-wrapper__element">
            <template v-if="disableButtonMenu">
                <slot name="responseButtons" />
            </template>
            <template v-else>
                <Menu button-icon="plus-2" class="add-menu">
                    <slot name="addAttributeButtons" />
                </Menu>
                <Menu v-if="isDeletable" button-icon="settings">
                    <DropDownItem icon="delete-full" @click="$emit('deleteOnClick', $event)">
                        Delete item
                    </DropDownItem>
                </Menu>
            </template>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import Menu from '@/components/ui/Menu';
import DropDownItem from '@/components/ui/DropDown/DropDownItem';

export default {
    name: 'ButtonWrapper',
    components: {
        Icon,
        Menu,
        DropDownItem,
    },
    props: {
        isDraggable: {
            type: Boolean,
            required: true,
        },
        isDeletable: {
            type: Boolean,
            required: true,
        },
        marker: {
            type: [String, Number],
            default: undefined,
        },
        isPinned: {
            type: Boolean,
            default: false,
        },
        hasAttributes: {
            type: Boolean,
            default: false,
        },
        disableButtonMenu: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.button-wrapper {
    position: relative;
    display: flex;

    &__element {
        position: relative;
        display: flex;
        align-items: center;
        height: 46px;
        padding-top: 10px;
    }

    &__center {
        flex: 1 1 auto;
        min-width: 0;
        margin-right: 8px;
    }

    &__body {
        position: relative;

        &--has-attributes {
            padding-left: 40px;
        }
    }
}

.marker {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 12px;
    background-color: $button-wrapper-marker-bg-color;
    font-size: 12px;
    font-weight: $font-weight-bold;
    color: $button-wrapper-marker-text-color;
    text-align: center;
    line-height: 24px;
    white-space: nowrap;
}

.drag-and-drop-handle {
    position: absolute;
    left: -20px;
    cursor: ns-resize;

    .icon {
        color: $button-wrapper-icon-color;
        font-size: 11px;
    }
}

.pin-icon {
    position: absolute;
    left: -20px;
    color: $button-wrapper-icon-color;
    font-size: 16px;
}

.sortable-ghost {
    opacity: 0.5;
}

.add-menu {
    margin-right: 6px;
}

.attributes-icon {
    position: absolute;
    top: 3px;
    left: 8px;
    font-size: 20px;
    color: $button-wrapper-attributes-icon-color;
}
</style>
