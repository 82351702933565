import { ITenantChannel } from './interfaces/tenant.interface';
import { Application } from './application';

export class Tenant {
    id: string;
    code: string;
    name: string;
    appKey?: string | null;
    appSecret?: string | null;
    allowOrigins?: string[];
    maxFreeConvos: number;
    channels?: ITenantChannel[];
    applications?: Application[];
}
