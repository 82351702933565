import { AudienceClient } from './audience';
import { BroadcastClient } from './broadcast';
import { ChannelClient } from './channel';
import { ContentClient } from './content';
import { EmailAutomationClient } from './email-automation';
import { NotificationsClient } from './notification';
import { ReportClient } from './report';
import { SystemNotificationsClient } from './system-notification';
import { TagClient } from './tag';
import { TenantClient } from './tenant';
import { UploadClient } from './upload';
import { UserClient } from './users';
import { ContentDiscoveryMenuClient } from './content-discovery-menu';
import { UserCommentClient } from './user-comment';
import { TopicClient } from './topic';

const clients = {
    audience: AudienceClient,
    broadcast: BroadcastClient,
    channel: ChannelClient,
    content: ContentClient,
    emailAutomation: EmailAutomationClient,
    notification: NotificationsClient,
    report: ReportClient,
    systemNotification: SystemNotificationsClient,
    tag: new TagClient(),
    tenant: TenantClient,
    upload: UploadClient,
    user: UserClient,
    contentDiscoveryMenu: ContentDiscoveryMenuClient,
    userComment: UserCommentClient,
    topic: new TopicClient(),
};

export default clients;
