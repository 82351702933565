<template>
    <div>
        <ErrorMessage v-if="data.errors && data.errors.selectMessageType" ref="error">
            {{ data.errors && data.errors.selectMessageType[0] }}
        </ErrorMessage>

        <div class="message-type-buttons" :class="{ 'two-columns': messageTypes.length <= 2 }">
            <MessageTypeButton
                v-for="(messageType, index) in messageTypes"
                :key="index"
                :icon="messageType.icon"
                :is-active="messageType.isActive"
                class="button"
                :button-style="accordionStyle"
                @click="onClick($event, index)"
            >
                {{ messageType.title }}
            </MessageTypeButton>
        </div>
    </div>
</template>

<script>
import MessageTypeButton from '@/components/ui/MessageTypeButton';
import ErrorMessage from '@/components/ui/ErrorMessage';
import onlyValues from '@/utils/onlyValues.ts';
import { AccordionStyle } from '@/store/enums/accordionStyle.enum.ts';

export default {
    name: 'SelectMessageType',
    components: {
        ErrorMessage,
        MessageTypeButton,
    },
    props: {
        messageTypes: {
            type: Array,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        accordionStyle: {
            type: String,
            default: AccordionStyle.Default,
            validator: onlyValues(Object.values(AccordionStyle)),
        },
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {
        onClick(event, index) {
            this.$emit('messageTypeOnSelect', event, this.path, index);
        },
    },
};
</script>

<style lang="scss" scoped>
.message-type-buttons {
    @include media-query(medium) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
    }

    &.two-columns {
        @include media-query(medium) {
            grid-template-columns: 1fr 1fr;
        }
    }

    .button {
        width: 100%;

        @include media-query(medium) {
            width: auto;
        }

        &:not(:last-child) {
            margin-bottom: 16px;

            @include media-query(medium) {
                margin-bottom: 0;
            }
        }
    }
}
</style>
