<template>
    <div v-click-outside="clickOutside" class="form-group inline-select">
        <div @click="toggleOptions">
            <span v-if="selectedOption">
                {{ selectedOption.value }}
            </span>
        </div>
        <div class="options" :class="{ open: isOpen }">
            <div
                v-for="option of options"
                :key="option.id"
                class="option"
                :class="{ selected: option.id === value, disabled: option.disabled }"
                @click.prevent.stop="selectOption(option.id)"
            >
                <span>{{ option.value }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InlineSelect',
    props: {
        options: {
            type: Array,
            required: true,
        },
        value: {
            type: [Number, String],
            default: null,
        },
    },
    data() {
        return {
            isOpen: false,
            innerValue: this.value,
        };
    },
    computed: {
        selectedOption() {
            return this.options.find((o) => o.id === this.value);
        },
        hasOptionSelected() {
            return this.value || this.value === 0 || this.value > 0;
        },
    },
    watch: {
        value(newValue) {
            this.innerValue = newValue;
            this.$nextTick(() => this.$emit('changed'));
        },
    },
    methods: {
        clickOutside() {
            if (this.isOpen) {
                this.toggleOptions();
            }
        },
        toggleOptions() {
            this.isOpen = !this.isOpen;
        },
        selectOption(value) {
            this.innerValue = value;
            this.$emit('input', value);
            this.toggleOptions();
        },
    },
};
</script>

<style lang="scss" scoped>
.inline-select {
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-decoration: underline dotted;
}

.options {
    display: none;
    position: absolute;
    top: 16px;
    left: 0;
    padding-top: 5px;
    overflow: hidden;
    z-index: $layer-10;

    &.open {
        display: block;
    }
}

.option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}
</style>
