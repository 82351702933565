<template>
    <div class="panel" :class="[`panel--${panelStyle}`, { 'panel--disabled': disabled }]">
        <div class="panel-header">
            <Icon v-if="icon" class="panel-icon" :name="icon" />
            <h2 class="panel-title">{{ title }}</h2>
        </div>
        <slot />
    </div>
</template>

<script>
import Icon from './Icon';
import onlyValues from '@/utils/onlyValues.ts';

export default {
    name: 'Panel',
    components: {
        Icon,
    },
    props: {
        title: {
            type: String,
            default: undefined,
        },
        panelStyle: {
            type: String,
            default: 'default',
            validator: onlyValues(['default', 'highlight']),
        },
        icon: {
            type: String,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.panel {
    position: relative;
    padding: 20px;
    border-radius: 18px;
    min-width: 0;

    &--default {
        padding-top: 26px;
        border: solid 1px $panel-default-border-color;
        background-color: $panel-default-bg-color;

        .panel-header {
            position: absolute;
            z-index: 101;
            top: -16px;
            left: 15px;
            padding: 0 5px;

            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                bottom: 10px;
                width: 100%;
                height: 1px;
                background-color: $panel-default-bg-color;
            }
        }

        .panel-title {
            position: relative;
            font-size: 20px;
            font-weight: $font-weight-bold;
        }
    }

    &--highlight {
        background-color: $panel-highlight-bg-color;

        .panel-header {
            margin-bottom: 12px;
        }

        .panel-title {
            display: inline-block;
            font-size: 18px;
            text-transform: uppercase;
        }
    }

    &--disabled {
        &::after {
            content: '';
            overflow: hidden;
            z-index: $panel-z-index;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 18px;
            background-color: rgba(white, 0.6);
            cursor: not-allowed;
        }
    }
}

.panel-header {
    display: flex;
    align-items: center;
}

.panel-icon {
    position: relative;
    flex: 0 0 auto;
    display: inline-block;
    margin-right: 12px;
    font-size: 25px;
    color: $panel-icon-color;
}
</style>
