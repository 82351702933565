export enum AccordionStyle {
    Default = 'default',
    Result = 'result',
    ItemsGroup = 'itemsGroup',
    SecondLevel = 'secondLevel',
    ThirdLevel = 'thirdLevel',
    Panel = 'panel',
    Tabs = 'tabs',
    Tab = 'tab',
    Button = 'button',
}
