<template>
    <MessageWrapper class="button-message" :avatar-url="avatarUrl">
        <MessageBubble class="text-message" :show-arrow="showArrow">
            <div class="simple-text" @click="onClick(item)" v-html="item.text"></div>
            <button
                v-for="(answer, index) in item.answers"
                :key="index"
                :ref="`button[${index}]`"
                class="message-button"
                @click="$emit('answerOnClick', index, answer)"
            >
                {{ answer.label }}
            </button>
        </MessageBubble>
    </MessageWrapper>
</template>

<script>
import messageMixin from '../mixins/message.mixin';
import MessageWrapper from './MessageWrapper';
import MessageBubble from './MessageBubble';

export default {
    name: 'Buttons',
    components: {
        MessageWrapper,
        MessageBubble,
    },
    mixins: [messageMixin],
    props: {
        item: {
            type: Object,
            default: undefined,
        },
    },
    computed: {
        avatarUrl() {
            return this.item.showAvatar && this.item.avatarUrl ? this.item.avatarUrl : undefined;
        },
        showArrow() {
            return this.avatarUrl ? true : false;
        },
    },
};
</script>

<style lang="scss" scoped>
.button-message {
    .simple-text {
        margin-bottom: 8px;
        font-family: $font-family-default;
        font-size: $font-size-xs;
        font-weight: $font-weight-default;
        line-height: 1.37;
        text-align: left;
        color: $default-text-color;

        @include media-query(medium) {
            margin-bottom: 5px;
            font-size: $font-size-xxs;
        }
    }
    .message-button {
        display: block;
        min-width: 126px;
        width: 100%;
        white-space: nowrap;
        padding: 10px;
        background: $bg-color;
        font-size: $font-size-xs;
        color: $quick-reply-answer-button-text-color;
        text-align: center;
        cursor: pointer;

        @include media-query(medium) {
            padding: 5px;
            font-size: 10px;
        }

        &:not(:last-child) {
            margin-bottom: 8px;

            @include media-query(medium) {
                margin-bottom: 4px;
            }
        }
    }
}
</style>
