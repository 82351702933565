<template>
    <span class="badge" :class="`badge--${type}`"><slot /></span>
</template>

<script>
import { BadgeType } from '@/store/enums';

export default {
    name: 'Badge',
    props: {
        type: {
            type: String,
            required: true,
            validator: (value) => Object.values(BadgeType).includes(value),
        },
    },
};
</script>

<style lang="scss" scoped>
.badge {
    font-size: 9px;
    font-weight: bold;
    color: $default-light-color;
    border-radius: 4px;
    padding: 2px 4px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    &--other-channels,
    &--channel {
        background-color: $badge-salmon;
    }

    &--convo {
        color: $color-oxford-blue;
        background-color: $badge-orange;
    }

    &--menu {
        background-color: $color-polo-blue;
    }

    &--message {
        background-color: $badge-purple;
    }

    &--success {
        background-color: $badge-success;
    }

    &--neutral {
        background-color: $badge-neutral;
    }
    &--progress {
        background-color: $badge-progress;
    }
    &--error {
        background-color: $badge-error;
    }

    &--info {
        background-color: $badge-info;
    }
}
</style>
