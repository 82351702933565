<template>
    <div class="breadcrumbs">
        <h2 v-if="items.length === 0" class="breadcrumbs-item">&nbsp;</h2>
        <h2 v-for="(item, index) in items" v-else :key="index" class="breadcrumbs-item">
            <router-link v-if="item.link" :to="item.link">
                {{ item.text }}
            </router-link>
            <span v-else>{{ item.text }}</span>
            <span v-if="index < items.length - 1" class="breadcrumbs-separator">&#x25CF;</span>
        </h2>
    </div>
</template>

<script>
export default {
    name: 'Breadcrumbs',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
    font-size: 12px;
    color: $alternate-text-color;
    text-transform: uppercase;
}

.breadcrumbs-item {
    display: inline-block;
}

.breadcrumbs-separator {
    padding: 0 5px;
}
</style>
