<template>
    <div class="save" :class="{ 'nav-is-opened': navIsOpened, 'save--vertical': verticalStyle }">
        <div class="info">
            <SaveStatus v-if="infoIsVisible" :save-time="time" :is-saved="isSaved" />
        </div>
        <div v-if="cancelButtonIsVisible || confirmButtonIsVisible" class="buttons">
            <Button
                v-if="cancelButtonIsVisible"
                class="cancel-button"
                size="small"
                :disabled="cancelButtonIsDisabled"
                button-style="secondary"
                @click="$emit('cancelButtonOnClick', $event)"
            >
                {{ cancelButtonText }}
            </Button>
            <Button
                v-if="confirmButtonIsVisible"
                class="confirm-button"
                has-border
                size="small"
                :is-loading="isLoading"
                :disabled="confirmButtonIsDisabled"
                @click="$emit('confirmButtonOnClick', $event)"
            >
                {{ confirmButtonText }}
            </Button>
        </div>
    </div>
</template>

<script>
import { format } from 'date-fns';
import { ButtonStyle } from '@/store/enums/buttonStyle.enum.ts';
import Button from '@/components/ui/Button';
import SaveStatus from '@/components/ui/SaveStatus';

export default {
    name: 'Save',
    components: {
        Button,
        SaveStatus,
    },
    props: {
        navIsOpened: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        cancelButtonText: {
            type: String,
            default: 'Discard',
        },
        cancelButtonIsDisabled: {
            type: Boolean,
            default: false,
        },
        confirmButtonIsVisible: {
            type: Boolean,
            default: true,
        },
        confirmButtonText: {
            type: String,
            default: 'Save changes',
        },
        confirmButtonIsDisabled: {
            type: Boolean,
            default: false,
        },
        cancelButtonIsVisible: {
            type: Boolean,
            default: true,
        },
        infoIsVisible: {
            type: Boolean,
            default: true,
        },
        isSaved: {
            type: Boolean,
            default: false,
        },
        statusTitleIsVisible: {
            type: Boolean,
            default: true,
        },
        time: {
            type: Date,
            default: undefined,
        },
        verticalStyle: {
            type: Boolean,
            default: false,
        },
        info: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            ButtonStyle,
        };
    },
    computed: {
        timeText() {
            return `${format(this.time, 'dd/MM/yyyy')} at ${format(this.time, 'HH:mm')}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.save {
    width: 100%;
    padding: 16px;
    border-top: 1px solid $save-border-top-color;

    &--vertical {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0;
        padding-right: 0;

        .buttons {
            margin-top: 0;
        }
    }
}

.info {
    position: relative;
    padding-left: 16px;
}

.buttons {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}
</style>
