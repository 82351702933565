<template>
    <div class="accordion-title" :class="{ 'accordion-title--deleted': isDeleted }">
        <div v-if="icon" class="accordion-title__icon">
            <Icon :name="icon" />
        </div>
        <div class="accordion-title__right">
            <span class="accordion-title__type">{{ title }}</span>
            <div v-if="showSubtitle && subtitle" class="accordion-title__subtitle">
                {{ subtitle }}
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    name: 'AccordionItemHeader',
    components: {
        Icon,
    },
    props: {
        title: {
            type: String,
            default: undefined,
        },
        showSubtitle: {
            type: Boolean,
            default: true,
        },
        subtitle: {
            type: String,
            default: undefined,
        },
        icon: {
            type: String,
            default: undefined,
        },
        isDeleted: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.accordion-title {
    $element: &;

    display: flex;
    align-items: center;
    min-width: 0;
    max-width: 100%;

    &__icon {
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        margin-right: 10px;
        border-radius: 36px;
        background-color: $accordion-title-icon-bg-color;
        font-size: 28px;
        color: $accordion-title-icon-text-color;
    }

    &__right {
        flex: 1 1 auto;
        min-width: 0;
        max-width: 100%;
    }

    &__type {
        font-size: 10px;
        color: $accordion-title-type-text-color;
        text-transform: uppercase;
    }

    &__subtitle {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
    }

    &--deleted {
        #{$element}__icon {
            background: $accordion-title-icon-bg-deleted-color;
        }

        #{$element}__type,
        #{$element}__subtitle {
            color: $accordion-title-text-deleted-color;
        }
    }
}
</style>
