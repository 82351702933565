import resultOptions from '@/json/resultOptions';
import { ChartType } from '@/store/enums';
import config from '@/config';

const resultOptionsMixin = {
    data() {
        return {
            resultOptions,
        };
    },
    computed: {
        getResultOption() {
            const resultOption = resultOptions.find(
                (option) =>
                    option.chartType === this.data.resultOptions.chartType &&
                    option.order === this.data.resultOptions.order,
            );
            return resultOption.id;
        },
        showResponseCountToggle() {
            return (
                config.featureToggle.showPollCount &&
                this.data.resultOptions.chartType !== ChartType.None
            );
        },
    },
    methods: {
        handleResultOptions(id) {
            const resultOption = resultOptions.find((option) => option.id === id);
            this.onInput('resultOptions', {
                chartType: resultOption.chartType,
                order: resultOption.order,
                showResponseCount:
                    config.featureToggle.showPollCount && this.data.resultOptions.showResponseCount,
            });
        },
    },
};

export default resultOptionsMixin;
