import { IContentItem } from '../interfaces';
import { Gif, ContentItem } from '..';
import { ContentItemType } from '@/store/enums/';
import { IAnswer } from '../interfaces/answer.interface.dto';
import { Answer } from '../answer.dto';
import { IConvoState } from '@/store/modules/convo-details';

export class GifContentItemFactory {
    make(obj: Gif, state: IConvoState): IContentItem {
        const contentItem = new ContentItem();
        contentItem.id = obj.id;
        contentItem.sort = obj.sort;
        contentItem.attachment = JSON.stringify(obj.gif);
        contentItem.tags = obj.tags || [];
        contentItem.type = ContentItemType.Gif;

        contentItem.answers = [];
        const answer: IAnswer = new Answer();
        answer.convoId = state.convo.id;
        contentItem.answers.push(answer);

        return contentItem;
    }
}
