import buttonsMessageTypeAnswerOptions from '@/json/buttonsMessageTypeAnswerOptions';
import { LinkTarget } from '@/store/enums';
import { Attributes } from '@/store/enums/attributes.enum';
import { IAnswer } from '@/store/models/interfaces/answer.interface.dto';
import { replaceTemplateVariablesForPreview } from '@/store/services/convoMessageItemService';
import { v4 as uuid } from 'uuid';
import { Buttons, ButtonsAnswer, ItemsGroup, TextGlobalResponse } from '..';
import { IContentItem } from '../interfaces';
import { sanitize } from '@/utils/sanitizer';
import {
    makeAnswerReply,
    makeLogicJump,
    makeQuizAttributes,
    makeQuizOptionAttributes,
} from './accordionItems.factory';

export class ButtonsAccordionItemFactory {
    public make(contentItem: IContentItem, isClone?: boolean): Buttons {
        const buttonsItem = new Buttons();
        const buttonsAnswers = new ItemsGroup();
        const notDraggableItemList = new ItemsGroup();
        const globalResponse = new TextGlobalResponse();
        const plainTextStatement = sanitize(contentItem.title);

        if (contentItem.reply?.resultOptions) {
            buttonsItem.resultOptions.order = contentItem.reply.resultOptions.order;
            buttonsItem.resultOptions.chartType = contentItem.reply.resultOptions.chartType;
            buttonsItem.resultOptions.showResponseCount =
                !!contentItem.reply.resultOptions.showResponseCount;
        }

        if (contentItem.reply?.message) {
            globalResponse.message = contentItem.reply.message;
            globalResponse.subtitle = replaceTemplateVariablesForPreview(
                contentItem.reply.message,
                contentItem.reply.sortingOrder,
            );
            globalResponse.sortingOrder = contentItem.reply.sortingOrder;

            if (contentItem.reply.activated && !contentItem.reply.message) {
                buttonsItem.showQuizResults = true;
            }
        }

        if (contentItem.reply && contentItem.reply.activated && !contentItem.reply.message) {
            buttonsItem.showQuizResults = true;
        }

        globalResponse.hasMarginBottom = false;
        buttonsItem.subtitle = plainTextStatement;
        buttonsItem.plainText = plainTextStatement;
        buttonsItem.statement = contentItem.title;
        buttonsItem.id = contentItem.id || 0;
        buttonsItem.sort = contentItem.sort;
        buttonsItem.title = 'Buttons';
        buttonsItem.tags = contentItem.tags;
        buttonsItem.isPdl = !!contentItem.isPdl;
        buttonsItem.shouldSkipIfAnswered = !!contentItem.shouldSkipIfAnswered;

        if (contentItem.externalId) {
            buttonsItem.externalId = contentItem.externalId;
            buttonsItem.datumDefinition = contentItem.externalId;
        }

        notDraggableItemList.items = [globalResponse];

        buttonsItem.isMultipleChoice = !!contentItem.multipleChoice;

        if (!!contentItem.multipleChoice) {
            buttonsItem.submitButtonText = contentItem.multipleChoiceOptions.submitButtonText;
            buttonsItem.layout = contentItem.multipleChoiceOptions.layout;
            buttonsItem.minLimit = contentItem.multipleChoiceOptions.minLimit;
            buttonsItem.maxLimit = contentItem.multipleChoiceOptions.maxLimit;
        }

        for (const answer of contentItem.answers as IAnswer[]) {
            const buttonAnswerItem = new ButtonsAnswer();
            const selectedAnswerType = buttonsMessageTypeAnswerOptions.find(
                (item) => item.name === answer.type,
            );

            buttonAnswerItem.buttonId = answer.id;
            if (!buttonAnswerItem.buttonId || isClone) {
                buttonAnswerItem.buttonId = uuid();
                buttonsItem.hasChanges = true;
            }

            buttonAnswerItem.isExclusive = answer.isExclusive;
            buttonAnswerItem.buttonLabel = answer.text || '';

            if (answer.externalId) {
                buttonAnswerItem.externalId = answer.externalId;
                buttonAnswerItem.datumResponseCode = answer.externalId;
            }

            if (selectedAnswerType) {
                buttonAnswerItem.answerType.name = selectedAnswerType.name;
                buttonAnswerItem.answerType.title = selectedAnswerType.title;
                buttonAnswerItem.answerType.linkRequired = selectedAnswerType.linkRequired;
                buttonAnswerItem.answerType.canBeMultipleChoice =
                    selectedAnswerType.canBeMultipleChoice;
            }
            buttonAnswerItem.answerTypeOptions = buttonsMessageTypeAnswerOptions;
            if (answer.link) {
                buttonAnswerItem.link = answer.link;
                buttonAnswerItem.linkOpenInNewTab = answer.linkTarget === LinkTarget.Blank;
                buttonAnswerItem.successDispositionLink = answer.successDispositionLink || '';
                buttonAnswerItem.failureDispositionLink = answer.failureDispositionLink || '';
            }

            buttonAnswerItem.logicJump = makeLogicJump(answer);

            if (Object.keys(buttonAnswerItem.logicJump.items).length) {
                buttonAnswerItem.attributes.push(Attributes.LogicJump);
            }

            const quizAttr = makeQuizAttributes(answer);
            buttonsItem.ignoreQuizQuestion = quizAttr.ignoreQuizQuestion;

            const quizOptionAttr = makeQuizOptionAttributes(answer);
            buttonAnswerItem.selectedPersonalityQuizResult =
                quizOptionAttr.selectedPersonalityQuizResult;
            buttonAnswerItem.isCorrectAnswer = quizOptionAttr.isCorrectAnswer;

            if (answer.reply) {
                const inlineResponse = makeAnswerReply(answer.reply);
                buttonAnswerItem.items[0].items.push(inlineResponse);
                buttonAnswerItem.attributes.push(Attributes.InlineResponse);
            }

            buttonsAnswers.items.push(buttonAnswerItem);
        }

        buttonsItem.items = [buttonsAnswers, notDraggableItemList];
        return buttonsItem;
    }
}
