import buttonsMessageTypeAnswerOptions from '@/json/buttonsMessageTypeAnswerOptions';
import { AccordionStyle, ContentItemType, LinkTarget } from '@/store/enums';
import { Attributes } from '@/store/enums/attributes.enum';
import { replaceTemplateVariablesForPreview } from '@/store/services/convoMessageItemService';
import { v4 as uuid } from 'uuid';
import { ButtonsAnswer, Carousel, CarouselTile, ItemsGroup, TextGlobalResponse } from '..';
import { IContentItem } from '../interfaces';
import { IAnswer } from '../interfaces/answer.interface.dto';
import { IButton } from '../interfaces/button.interface';
import {
    makeAnswerReply,
    makeLogicJump,
    makeQuizAttributes,
    makeQuizOptionAttributes,
} from './accordionItems.factory';
import { sanitize } from '@/utils/sanitizer';

const carouselTypes: string[] = [
    ContentItemType.VerticalCarousel,
    ContentItemType.HorizontalCarousel,
    ContentItemType.MatrixCarousel,
];

export class CarouselAccordionItemFactory {
    public make(contentItem: IContentItem, isClone?: boolean): Carousel {
        let carouselItem = new Carousel();

        if (contentItem.children && contentItem.children?.length > 0) {
            carouselItem.children = contentItem.children;

            for (const item of contentItem.children) {
                if (item.type === ContentItemType.Statement) {
                    carouselItem.statement = item.title;
                }

                if (carouselTypes.includes(item.type)) {
                    carouselItem = this.createCarouselContentItem(
                        carouselItem,
                        contentItem,
                        isClone,
                    );
                }
            }
        } else {
            carouselItem = this.createCarouselContentItem(carouselItem, contentItem, isClone);
        }

        const plainTextStatement = sanitize(carouselItem.statement);
        carouselItem.plainText = plainTextStatement;
        carouselItem.setTitle();

        return carouselItem;
    }

    private createCarouselContentItem(
        carouselItem: Carousel,
        contentItem: IContentItem,
        isClone?: boolean,
    ): Carousel {
        const tiles = new ItemsGroup();
        const notDraggableItemList = new ItemsGroup();
        const globalResponse = new TextGlobalResponse();

        globalResponse.hasMarginBottom = false;

        if (contentItem.reply?.resultOptions) {
            carouselItem.resultOptions.order = contentItem.reply.resultOptions.order;
            carouselItem.resultOptions.chartType = contentItem.reply.resultOptions.chartType;
            carouselItem.resultOptions.showResponseCount =
                !!contentItem.reply.resultOptions.showResponseCount;
        }

        if (contentItem.reply?.message) {
            globalResponse.message = contentItem.reply.message;
            globalResponse.subtitle = replaceTemplateVariablesForPreview(
                contentItem.reply.message,
                contentItem.reply.sortingOrder,
            );
            globalResponse.sortingOrder = contentItem.reply.sortingOrder;
        }

        if (contentItem.reply && contentItem.reply.activated && !contentItem.reply.message) {
            carouselItem.showQuizResults = true;
        }

        notDraggableItemList.items = [globalResponse];

        carouselItem.title = 'Carousel';
        carouselItem.areTilesShuffled = !!contentItem.shuffleAnswers;
        carouselItem.id = contentItem.id || 0;
        carouselItem.sort = contentItem.sort;
        carouselItem.tags = contentItem.tags;
        tiles.accordionStyle = AccordionStyle.Tabs;

        for (const answer of contentItem.answers as IAnswer[]) {
            const carouselTileItem = new CarouselTile();
            carouselTileItem.tileId = answer.id;
            if (!carouselTileItem.tileId || isClone) {
                carouselTileItem.tileId = uuid();
                carouselItem.hasChanges = true;
            }
            carouselTileItem.tileTitle = answer.title || '';
            carouselTileItem.subtitle = answer.subtitle || '';
            carouselTileItem.link = answer.link || '';
            carouselTileItem.image = answer.image || '';
            carouselTileItem.items = [] as ButtonsAnswer[];
            carouselTileItem.items = this.createButtonAnswers(answer, carouselItem, isClone);

            if (
                !contentItem.shuffleLast &&
                answer == contentItem.answers[contentItem.answers.length - 1]
            ) {
                carouselTileItem.isPinned = true;
            }

            tiles.items.push(carouselTileItem);
        }

        carouselItem.items = [tiles, notDraggableItemList];
        return carouselItem;
    }

    private createButtonAnswers(answer: IAnswer, carouselItem: Carousel, isClone?: boolean) {
        const buttonAnswers: ButtonsAnswer[] = [];

        if (answer.buttons) {
            for (const button of answer.buttons as IButton[]) {
                const buttonAnswerItem = new ButtonsAnswer();

                buttonAnswerItem.buttonId = button.id;
                if (!buttonAnswerItem.buttonId || isClone) {
                    buttonAnswerItem.buttonId = uuid();
                    carouselItem.hasChanges = true;
                }

                const selectedAnswerType = buttonsMessageTypeAnswerOptions.find(
                    (item) => item.name === button.type,
                );
                if (selectedAnswerType) {
                    buttonAnswerItem.answerType.name = selectedAnswerType.name;
                    buttonAnswerItem.answerType.title = selectedAnswerType.title;
                    buttonAnswerItem.answerType.linkRequired = selectedAnswerType.linkRequired;
                }
                buttonAnswerItem.answerTypeOptions = buttonsMessageTypeAnswerOptions;
                buttonAnswerItem.buttonLabel = button.text;

                if (button.link) {
                    buttonAnswerItem.link = button.link;
                    buttonAnswerItem.linkOpenInNewTab = button.linkTarget === LinkTarget.Blank;
                }

                buttonAnswerItem.logicJump = makeLogicJump(button);

                if (Object.keys(buttonAnswerItem.logicJump.items).length) {
                    buttonAnswerItem.attributes.push(Attributes.LogicJump);
                }

                const quizAttr = makeQuizAttributes(button);
                carouselItem.ignoreQuizQuestion = quizAttr.ignoreQuizQuestion;

                const quizOptionAttr = makeQuizOptionAttributes(button);
                buttonAnswerItem.selectedPersonalityQuizResult =
                    quizOptionAttr.selectedPersonalityQuizResult;
                buttonAnswerItem.isCorrectAnswer = quizOptionAttr.isCorrectAnswer;

                if (button.reply) {
                    const inlineResponse = makeAnswerReply(button.reply);
                    buttonAnswerItem.items[0].items.push(inlineResponse);
                    buttonAnswerItem.attributes.push(Attributes.InlineResponse);
                }
                buttonAnswers.push(buttonAnswerItem);
            }
        }

        return buttonAnswers;
    }
}
