<template>
    <DynamicHtmlTag
        ref="element"
        :tag="tag"
        class="title underlined-title"
        :class="[`title--${type}`, { animate: startAnimation || animate }]"
    >
        <span
            v-for="(word, index) in wordsArray"
            :key="index"
            class="underlined-title__text"
            :style="{ '--delay': `${getDelay(index) + 0.3}s` }"
        >
            <span :style="{ transitionDelay: `${getDelay(index)}s` }">{{ word }}</span>
        </span>
    </DynamicHtmlTag>
</template>

<script lang="js">
import DynamicHtmlTag from './DynamicHtmlTag.vue';

export default {
    name: 'UnderlinedTitle',
    components: {
        DynamicHtmlTag,
    },
    props: {
        type: {
            type: String,
            default: 'h1',
        },
        animated: {
            type: Boolean,
            default: false,
        },
        triggeredByScroll: {
            type: Boolean,
            default: false,
        },
        animatePerWord: {
            type: Boolean,
            default: false,
        },
        animateImmediately: {
            type: Boolean,
            default: false,
        },
        animate: {
            type: Boolean,
            default: false,
        },
        delay: {
            type: Number,
            default: 100,
        },
        scrollOffset: {
            type: Number,
            default: 100,
        },
        text: {
            type: String,
            default: undefined,
        },
    },
    data: function data() {
        return {
            startAnimation: false,
        };
    },
    computed: {
        wordsArray: function wordsArray() {
            const trimmedText = this.text || this.$slots.default[0].text.trim();
            return trimmedText.split(' ');
        },
        tag: function tag() {
            if (this.type === 'main') {
                return 'h1';
            }

            return this.type;
        },
    },
    mounted: function mounted() {
        if (!this.animated) {
            this.startAnimation = true;
        } else if (this.animateImmediately) {
            setTimeout(() => {
                this.startAnimation = true;
            }, this.delay);
        }

        if (this.triggeredByScroll) {
            setTimeout(() => {
                this.onScroll();
            }, 500);
        }
    },
    created: function created() {
        if (this.triggeredByScroll) {
            window.addEventListener('scroll', this.onScroll);
        }
    },
    destroyed: function destroyed() {
        if (this.triggeredByScroll) {
            window.removeEventListener('scroll', this.onScroll);
        }
    },
    methods: {
        getDelay: function getDelay(index) {
            if (!this.animatePerWord) {
                return 0;
            }

            return 0.2 * index;
        },
        onScroll: function onScroll() {
            const element = this.$refs.element.$el;
            const top = window.pageYOffset
            || document.documentElement.scrollTop
            || document.body.scrollTop;
            const bottom = top + window.innerHeight - this.scrollOffset;
            const elementY = element.offsetTop;

            if (elementY > top && elementY < bottom) {
                this.startAnimation = true;
                window.removeEventListener('scroll', this.textIsOnScreen);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.underlined-title {
    &__text {
        display: inline-block;
        position: relative;
        padding: 0 5px;
        line-height: 1.1em;
        overflow: hidden;
        padding-bottom: 5px;

        span {
            display: inline-block;
            transform: translateY(110%);
            transition: transform 0.5s;
        }

        &::before {
            content: '';
            z-index: -1;
            position: absolute;
            left: -10px;
            width: calc(100% + 20px);
            opacity: 0;
            transition: opacity 0.5s;
            transition-delay: 0.4s;
            transition-delay: var(--delay);
        }
    }

    &.title--h1 {
        .underlined-title__text {
            &::before {
                bottom: 3px;
                height: 10px;
                background: $text-highlight-color;
            }
        }
    }

    &.animate {
        .underlined-title__text {
            &::before {
                opacity: 1;
            }

            span {
                display: inline-block;
                transform: translateY(0%);
            }
        }
    }
}
</style>
