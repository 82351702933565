<template>
    <div class="reply">
        <div class="reply-message">
            {{ item.text }}
        </div>
    </div>
</template>

<script>
import messageMixin from '../mixins/message.mixin';

export default {
    name: 'Reply',
    mixins: [messageMixin],
    props: {
        item: {
            type: Object,
            default: undefined,
        },
    },
};
</script>

<style lang="scss" scoped>
.reply {
    padding: 5px 0;
    padding-left: 60px;

    @include media-query(medium) {
        padding-left: 40px;
    }
}

.reply-message {
    padding: 8px 15px;
    border-radius: 2px;
    background-color: $preview-message-bg-color;
    color: $default-light-color;
    font-size: $font-size-xs;
    line-height: 1.37;
    text-align: left;

    @include media-query(medium) {
        padding: 6px 15px;
        font-size: $font-size-xxs;
    }
}
</style>
