<template>
    <div id="app">
        <router-view />
        <portal-target name="overlay-portal" multiple />
    </div>
</template>

<script>
export default {
    name: 'App',
};
</script>

<style lang="scss" scoped>
#app {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
</style>
