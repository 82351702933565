<template>
    <div class="nav-user">
        <div class="nav-user__icon">{{ initials }}</div>

        <div class="nav-user__info">
            <div>
                <p class="nav-user__username">{{ username }}</p>
                <p class="nav-user__details">Account details</p>
            </div>

            <IconButton icon="log-out" class="nav-user__logout" @click="$emit('logout')" />
        </div>
    </div>
</template>

<script>
import IconButton from '@/components/ui/IconButton';
export default {
    name: 'NavUser',
    components: {
        IconButton,
    },
    props: {
        username: {
            type: String,
            required: true,
        },
    },
    computed: {
        initials() {
            return this.username.slice(0, 2).toUpperCase();
        },
    },
};
</script>

<style lang="scss" scoped>
.nav-user {
    padding-left: 8px;
    display: flex;
    position: relative;
    width: 50px;

    &__icon {
        width: 35px;
        height: 35px;
        background-color: $bg-color;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 900;
    }

    &__info {
        position: absolute;
        left: 50px;
        display: flex;
        align-items: center;
    }

    &__username {
        color: $default-text-color;
        width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__details {
        color: $alternate-text-color;
        font-size: 12px;
    }

    &__logout {
        margin-left: 15px;
    }
}
</style>
