import { RuleDto } from '@/store/models/dtos/audience.dto';
import { Rule } from '@/store/models/audience/rule';
import { TenantClient } from '@/api/tenant';

export class MessageRule extends Rule {
    responseItems: { text: string; id: number }[];
    content: any;

    constructor(data: RuleDto) {
        super(data);

        if (data.responseItems) {
            this.responseItems = data.responseItems;
        }
    }

    async getContent(): Promise<any> {
        const [logicJump, messageResponses] = await Promise.all([
            TenantClient.getLogicJumpData({
                targetChannel: '',
                targetContentItem: Number(this.value),
                targetConvo: 0,
            }),
            TenantClient.getMessageResponse(this.value),
        ]);
        return { ...logicJump, messageResponses };
    }

    getSummaryTitle(): string {
        const title = ['channelName', 'convoName', 'contentItemName']
            .filter((prop) => this.content[prop] !== undefined)
            .map((prop) => this.content[prop]);
        if (this.responseItems.length) {
            this.responseItems.forEach((e) => title.push(e.text));
        }
        return title.join(' • ');
    }
}
