import { ISystemNotificationDto } from '@/store/models/dtos/systemNotification.dto';
import { LoadingFlag } from '@/store/enums';
import axios from '@/utils/request';

export class SystemNotificationsClient {
    public static async getSystemNotifications(request?: {
        entityType: string;
        entityId: string;
    }): Promise<ISystemNotificationDto[]> {
        let url = '/system-notifications';

        if (request) {
            url = `${url}?entityType=${request.entityType}&entityId=${request.entityId}`;
        }

        const result = await axios.request<any>({
            url,
            method: 'GET',
            errorMessage: 'Could not get system notifications',
            loadingId: LoadingFlag.SystemNotificationsGet,
        });
        return result as unknown as Promise<ISystemNotificationDto[]>;
    }
}
