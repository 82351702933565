<template>
    <EditorInput
        v-if="$options.richTextMessagesEnabled"
        :id="generateId('statement', path)"
        label="Message"
        show-remaining-char
        :content="data.statement"
        :errors="data.errors.plainText"
        :max="640"
        :menu-items="[
            $options.EditorInputMenuItem.Bold,
            $options.EditorInputMenuItem.Italic,
            $options.EditorInputMenuItem.Strike,
            $options.EditorInputMenuItem.Underline,
        ]"
        @update="onInput('statement', $event)"
        @plainTextChange="onInput('plainText', $event)"
    />
    <Input
        v-else
        :id="generateId('statement', path)"
        type="textarea"
        label="Message"
        name="statement"
        show-remaining-char
        :max="640"
        :has-margin-bottom="false"
        :value="data.statement"
        :errors="data.errors.statement"
        @input="
            (value) => {
                onInput('statement', value);
                onInput('plainText', value);
            }
        "
        @changed="onChanged"
    />
</template>

<script>
import Input from '@/components/forms/Input';
import EditorInput from '@/components/forms/EditorInput';
import itemFormMixin from '@/mixins/itemFormMixin';
import { EditorInputMenuItem } from '@/store/enums';
import config from '@/config';

export default {
    name: 'InlineResponseTextStatement',
    components: {
        Input,
        EditorInput,
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
    },
    created() {
        this.$options.richTextMessagesEnabled = config.featureToggle.richTextMessagesEnabled;
        this.$options.EditorInputMenuItem = EditorInputMenuItem;
    },
};
</script>
