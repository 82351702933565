<template>
    <button
        class="button"
        :type="type"
        :class="classArray"
        :disabled="disabled"
        v-bind="$attrs"
        @click="onClick"
    >
        <div class="button__left-icon">
            <Icon
                v-if="buttonStyle === SocialButtonStyle.Google"
                class="google-icon"
                name="signup-google"
                :size="iconSize"
            />
            <Icon
                v-if="buttonStyle === SocialButtonStyle.YouGovSSO"
                name="signup-yougov"
                :size="iconSize"
            />
        </div>
        <span class="button__text">
            <slot></slot>
        </span>
    </button>
</template>

<script>
import Icon from '@/components/ui/Icon';
import { SocialButtonStyle } from '@/store/enums';

export default {
    name: 'Button',
    components: {
        Icon,
    },
    props: {
        type: {
            type: String,
            default: 'button',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        buttonStyle: {
            type: String,
            required: true,
            validator: (value) => Object.values(SocialButtonStyle).includes(value),
        },
        iconSize: {
            type: Number,
            default: 22,
        },
    },
    data() {
        return {
            SocialButtonStyle,
        };
    },
    computed: {
        classArray() {
            return [
                this.class,
                'button',
                `button--${this.buttonStyle}`,
                {
                    'button--full-width': this.fullWidth,
                    'button--disabled': this.disabled,
                },
            ];
        },
    },
    methods: {
        onClick(event) {
            if (this.disabled) {
                return;
            }
            this.$emit('click', event);
        },
    },
};
</script>

<style scoped lang="scss">
$color-facebook: #3b5998;
$color-google: #eef1fa;
$color-google-text: #241d36;

.button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 14px 16px;
    outline: 0;
    border: none;
    border-radius: 20px;
    background-color: transparent;
    font-family: $font-family-default;
    font-weight: $font-weight-bold;
    font-size: $font-size-xs;
    letter-spacing: 0.19px;
    line-height: 1;
    color: $color-light;
    text-transform: uppercase;
    transition: background-color 200ms ease-in-out;
    cursor: pointer;

    &__left-icon {
        margin-right: 25px;
    }

    &--disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.4;
    }

    &--full-width {
        width: 100%;
    }

    &--facebook {
        background-color: $color-facebook;

        &:hover {
            background-color: darken($color-facebook, 2%);
        }
    }

    &--google,
    &--yougov {
        padding: 7px 14px;
        background-color: $color-light;
        color: $color-google-text;
        border: solid 2px $color-google;

        &:hover {
            background-color: darken($color-light, 2%);
        }
    }
}

.facebook-icon {
    margin-top: -5px;
    margin-right: -11px;
}
</style>
