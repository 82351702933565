<template>
    <div class="buttons">
        <div v-if="isPdl" class="buttons-skip">
            <Checkbox
                id="shouldSkipIfAnswered"
                label="Should skip if already answered"
                :value="data.shouldSkipIfAnswered"
                @input="(value) => onInput('shouldSkipIfAnswered', value)"
            />
        </div>

        <div v-if="isPdl" class="buttons-answer-tags-header">
            <Tags placeholder="" label="Tags" :value="data.tags" :disabled="true" />
        </div>

        <EditorInput
            v-if="$options.richTextMessagesEnabled"
            :id="generateId('statement', path)"
            class="m-b-2"
            label="Message"
            show-remaining-char
            :content="data.statement"
            :disabled="isDisabled"
            :errors="data.errors.plainText"
            :max="640"
            :menu-items="[
                $options.EditorInputMenuItem.Bold,
                $options.EditorInputMenuItem.Italic,
                $options.EditorInputMenuItem.Strike,
                $options.EditorInputMenuItem.Underline,
            ]"
            @update="onInput('statement', $event)"
            @plainTextChange="onInput('plainText', $event)"
        />
        <Input
            v-else
            :id="generateId('statement', path)"
            class="m-b-2"
            type="textarea"
            label="Message"
            name="statement"
            :max="640"
            show-remaining-char
            :has-margin-bottom="false"
            :value="data.statement"
            :errors="data.errors.statement"
            :disabled="isDisabled"
            @input="
                (value) => {
                    onInput('statement', value);
                    onInput('plainText', value);
                }
            "
            @changed="onChanged"
        />

        <Input
            v-if="data.externalId && $options.convoTypes.YougovWelcomeChat !== currentConvo.type"
            :id="generateId('externalId', path)"
            type="text"
            label="External ID"
            class="m-b-2"
            :readonly="true"
            :has-margin-bottom="false"
            :name="generateId('externalId', path)"
            :value="data.externalId"
            :errors="data.errors.externalId"
        />

        <Select
            v-if="currentConvo.type === $options.convoTypes.YougovWelcomeChat"
            label="Datum Question"
            class="select"
            placeholder="Select question"
            show-clear-icon
            :disabled="yougovDefinitions.length === 0"
            :is-loading="$wait.is($options.LoadingFlag.YougovDefinitionsGet)"
            :options="yougovDefinitionOptions"
            :value="data.datumDefinition"
            @input="onDefinitionChange($event)"
        />

        <QuizOptions
            v-if="isQuiz"
            class="m-b-2"
            :ignore-quiz-question="data.ignoreQuizQuestion"
            :show-quiz-results="data.showQuizResults"
            :disabled="isDisabled"
            :path="path"
            @change="onChanged"
            @input-show-results="onShowQuizResultsInput"
            @input-ignore-question="onIgnoreQuestionInput"
        />

        <div class="choice-toggle m-b-2">
            <ToggleText
                id="choice-toggle"
                name="choice_toggle"
                :value="data.isMultipleChoice"
                :disabled="isToggleDisabled || isExternalPdl"
                :font-size="10"
                :toggle-width="300"
                :toggle-height="44"
                text-on="Multiple Choice"
                text-off="Single Answer"
                show-icons
                icon-on="checkbox"
                icon-off="radio-button"
                @input="handleChoiceToggle"
            />
        </div>

        <div v-if="showMultipleChoiceOptions" class="flex-container m-b-2">
            <Select
                label="Button View"
                class="select"
                placeholder="Select layout"
                :options="$options.multipleChoiceOptions"
                :disabled="$options.convoTypes.YougovWelcomeChat !== currentConvo.type"
                :value="data.layout"
                @input="handleLayout"
            />

            <NumberInput
                :value="data.minLimit"
                class="number-input"
                label="Minimum Value"
                :disabled="isToggleDisabled || isExternalPdl"
                placeholder="Enter a value"
                @input="(value) => onInput('minLimit', parseInt(value))"
                @changed="onChanged"
            />

            <NumberInput
                :value="data.maxLimit"
                class="number-input"
                label="Maximum Value"
                :disabled="isToggleDisabled || isExternalPdl"
                placeholder="Enter a value"
                @input="(value) => onInput('maxLimit', parseInt(value))"
                @changed="onChanged"
            />
        </div>
        <AccordionNew
            v-if="data.items[0].items"
            :items="data.items[0].items"
            :is-draggable="isDisabled === false"
            :path="[...path, 0]"
            @onDrop="(path, value) => $emit('onDrop', path, value)"
        >
            <ButtonsAnswerComponent
                v-for="(item, index) in data.items[0].items"
                :key="index"
                :data="item"
                :path="[...path, 0, index]"
                :index="index"
                :marker="getMarker(item.marker, item.markerType, 1, index)"
                :is-draggable="isDisabled === false"
                :animation-disabled="false"
                :ignore-quiz-question="data.ignoreQuizQuestion"
                :current-channel="currentChannel"
                :current-convo="currentConvo"
                :is-multiple-choice="data.isMultipleChoice"
                :is-disabled="isDisabled"
                :is-read-only="isExternalPdl"
                :convo-type="currentConvo.type"
                :yougov-definition-responses="yougovDefinitionRespones"
                @openLogicJump="$emit('openLogicJump', $event)"
                @headerOnClick="(event, path) => $emit('headerOnClick', event, path)"
                @onExpand="(path) => $emit('onExpand', path)"
                @onExpanded="(path) => $emit('onExpanded', path)"
                @onContract="(path) => $emit('onContract', path)"
                @onContracted="(path) => $emit('onContracted', path)"
                @deleteOnClick="
                    (event, path, parentPath) => $emit('deleteOnClick', event, path, parentPath)
                "
                @onDrop="(path, value) => $emit('onDrop', path, value)"
                @messageTypeOnSelect="
                    (event, path, index) => $emit('messageTypeOnSelect', event, path, index)
                "
                @addItemOnClick="(event, path, type) => $emit('addItemOnClick', event, path, type)"
                @propertyOnChange="$emit('propertyOnChange', $event)"
                @addAttribute="(payload) => $emit('addAttribute', payload)"
                @removeAttribute="(payload) => $emit('removeAttribute', payload)"
            />
        </AccordionNew>

        <ErrorMessage v-if="data.errors.buttonsAnswers && data.errors.buttonsAnswers.length > 0">
            {{ data.errors.buttonsAnswers[0] }}
        </ErrorMessage>

        <div class="align-center m-b-2">
            <TextButton
                v-if="!(isDisabled || isExternalPdl) && data.items[0].items.length < data.maxItems"
                icon="plus-2"
                @click="addAnswerOnClick"
            >
                Add button
            </TextButton>
        </div>

        <div v-if="showMultipleChoiceOptions" class="m-b-2">
            <Input
                :id="generateId('submitButtonText', path)"
                type="text"
                label="Submit Button Text"
                :name="generateId('submitButtonText', path)"
                :max="40"
                show-remaining-char
                :has-margin-bottom="false"
                :value="data.submitButtonText"
                :errors="data.errors.submitButtonText"
                :disabled="isDisabled"
                @input="(value) => onInput('submitButtonText', value)"
                @changed="onChanged"
            />
        </div>

        <Accordion
            v-if="data.items[1] && data.items[1].items"
            :items="data.items[1].items"
            :path="[...path, 1]"
            :is-draggable="false"
            @headerOnClick="(event, path) => $emit('headerOnClick', event, path)"
            @onExpand="(path) => $emit('onExpand', path)"
            @onExpanded="(path) => $emit('onExpanded', path)"
            @onContract="(path) => $emit('onContract', path)"
            @onContracted="(path) => $emit('onContracted', path)"
            @deleteOnClick="
                (event, path, parentPath) => $emit('deleteOnClick', event, path, parentPath)
            "
            @cloneOnClick="
                (event, path, parentPath) => $emit('cloneOnClick', event, path, parentPath)
            "
            @onDrop="(path, value) => $emit('onDrop', path, value)"
            @messageTypeOnSelect="
                (event, path, index) => $emit('messageTypeOnSelect', event, path, index)
            "
            @propertyOnChange="$emit('propertyOnChange', $event)"
        />

        <div v-if="data.resultOptions" class="result-options-container">
            <Select
                label="Result Options"
                class="result-options"
                placeholder="Select results display option"
                :options="resultOptions"
                :value="getResultOption"
                :disabled="isDisabled"
                @input="handleResultOptions"
            />

            <Toggle
                v-if="showResponseCountToggle"
                :id="generateId('show-poll-count', path)"
                v-model="data.resultOptions.showResponseCount"
                :name="generateId('show-poll-count', path)"
                class="show-poll-count"
                label="Show Response Count"
            />
        </div>
    </div>
</template>

<script>
import { EditorInputMenuItem, ConvoMessageItemType, ConvoTypes, LoadingFlag } from '@/store/enums';
import { getMarker } from '@/utils/listMarkers.ts';
import {
    Input,
    ToggleText,
    Checkbox,
    EditorInput,
    Toggle,
    Tags,
    Select,
    NumberInput,
} from '@/components/forms';
import AccordionNew from '@/components/ui/Accordion/indexNew';
import ButtonsAnswerComponent from '@/components/ButtonsAnswer';
import { ButtonsAnswer } from '@/store/models';
import config from '@/config';
import ErrorMessage from '@/components/ui/ErrorMessage';
import itemFormMixin from '@/mixins/itemFormMixin';
import QuizOptionsMixin from '@/mixins/QuizOptionsMixin';
import multipleChoiceOptions from '@/json/multipleChoiceLayoutOptions';
import resultOptionsMixin from '@/mixins/resultOptionsMixin';
import TextButton from '@/components/ui/TextButton';
import QuizOptions from '@/components/QuizOptions';
import { cloneClassInstance } from '@/utils';
import { YougovDatumResponseTypes } from '@/enums';

export default {
    name: 'Buttons',
    components: {
        Accordion: () => import('@/components/ui/Accordion'),
        AccordionNew,
        ButtonsAnswerComponent,
        Checkbox,
        EditorInput,
        ErrorMessage,
        Input,
        NumberInput,
        Select,
        Tags,
        TextButton,
        Toggle,
        ToggleText,
        QuizOptions,
    },
    mixins: [itemFormMixin, resultOptionsMixin, QuizOptionsMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        currentChannel: {
            type: Object,
            default: undefined,
        },
        currentConvo: {
            type: Object,
            default: undefined,
        },
        isPdl: {
            type: Boolean,
            default: false,
        },
        isExternalPdl: {
            type: Boolean,
            default: false,
        },
        yougovDefinitions: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        isToggleDisabled() {
            if (this.isDisabled) {
                return true;
            }
            if (this.currentConvo.type === ConvoTypes.YougovWelcomeChat) {
                return !this.isDatumDefinitionMultipleType;
            }
            if (
                this.currentConvo.type === ConvoTypes.Scripted ||
                this.currentConvo.type === ConvoTypes.PDLConvo
            ) {
                return false;
            }
            return true;
        },
        showMultipleChoiceOptions() {
            return this.data.isMultipleChoice;
        },
        yougovDefinition() {
            return this.yougovDefinitions.find((def) => def.code === this.data.datumDefinition);
        },
        yougovDefinitionOptions() {
            return this.yougovDefinitions.reduce((list, item) => {
                if (
                    [YougovDatumResponseTypes.Single, YougovDatumResponseTypes.Multiple].includes(
                        item.type,
                    )
                ) {
                    list.push({
                        ...item,
                        id: item.code,
                        value: `${item.code} - ${item.description}`,
                    });
                }
                return list;
            }, []);
        },
        yougovDefinitionRespones() {
            return this.yougovDefinition?.responses;
        },
        isDatumDefinitionMultipleType() {
            return this.yougovDefinition?.type === YougovDatumResponseTypes.Multiple;
        },
    },
    watch: {
        isDatumDefinitionMultipleType(e) {
            if (this.currentConvo.type === ConvoTypes.YougovWelcomeChat) {
                this.onInput('isMultipleChoice', e);
            }
        },
    },
    created() {
        this.$options.richTextMessagesEnabled = config.featureToggle.richTextMessagesEnabled;
        this.$options.EditorInputMenuItem = EditorInputMenuItem;
        this.$options.multipleChoiceOptions = multipleChoiceOptions;
        this.$options.convoTypes = ConvoTypes;
        this.$options.LoadingFlag = LoadingFlag;
    },
    methods: {
        getMarker,
        addAnswerOnClick(event) {
            this.$emit('addItemOnClick', event, this.path, ConvoMessageItemType.ButtonsAnswer);
        },
        handleChoiceToggle(isToggled) {
            this.onInput('isMultipleChoice', isToggled);
        },
        handleLayout(value) {
            this.onInput('layout', value);
        },
        onDefinitionChange(value) {
            this.data.items[0].items.forEach((item, index) => {
                const answerBtnClone = cloneClassInstance(item);
                answerBtnClone.datumResponseCode = '';
                this.$emit('propertyOnChange', {
                    path: [...this.path, 0, index],
                    item: answerBtnClone,
                    key: 'datumResponseCode',
                    value: '',
                });
            });
            this.onInput('datumDefinition', value);
        },
    },
};
</script>

<style lang="scss" scoped>
.choice-toggle {
    display: flex;
    justify-content: center;
}

.buttons-statement {
    margin-bottom: 10px;
}

.buttons-skip {
    border-bottom: 1px solid $container-default-border-color;
    margin-bottom: 25px;
}

.suffle-toggle {
    margin-bottom: 30px;
}

.error-message {
    color: $error-color;
    font-size: 12px;
    font-weight: $font-weight-light;
    font-style: italic;
    padding: 10px 0;
}

.flex-container {
    display: flex;
    height: 50px;
    justify-content: space-evenly;
}

.result-options-container {
    display: flex;
}

.result-options {
    order: 0;
    width: 60%;
    margin-top: 15px;
}

.show-poll-count {
    order: 1;
    width: 40%;
    margin-top: 25px;
    padding: 0 10px;
}

.number-input {
    width: 20%;
    padding-right: 10px;
}
</style>
