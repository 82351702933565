<template>
    <div class="input-wrapper" :class="{ 'form-group--has-m-b': hasMarginBottom }">
        <input
            :id="id"
            v-model="innerChecked"
            type="radio"
            v-bind="$attrs"
            :disabled="disabled"
            :value="inputValue"
        />
        <label :for="id">
            {{ label }}
        </label>
        <div v-if="errors && errors.length > 0" class="error-message">{{ errors[0] }}</div>
    </div>
</template>

<script>
export default {
    name: 'RadioButton',
    props: {
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        inputValue: {
            type: String,
            default: undefined,
        },
        value: {
            type: String,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hasMarginBottom: {
            type: Boolean,
            default: true,
        },
        errors: {
            type: Array,
            default: undefined,
        },
    },
    computed: {
        innerChecked: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
$form-group-margin-bottom: 20px !default;

.form-group {
    &--has-m-b {
        margin-bottom: $form-group-margin-bottom;
    }
    &--is-active {
        position: relative;
        z-index: 1;
    }
}

input[type='radio'] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    + label {
        display: inline-block;
        position: relative;
        padding-left: 28px;
        font-size: $font-size-sm;
        cursor: pointer;

        &::before {
            @include center(y);
            content: '';
            display: block;
            position: absolute;
            left: 0;
            width: 18px;
            height: 18px;
            border: 1px solid $color-athens-gray;
            border-radius: 20px;
            background-color: $color-light;
            transition: border-color 0.2s;
        }

        &::after {
            @include center(y);
            content: '';
            display: none;
            position: absolute;
            left: 4px;
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background: $color-primary;
        }
    }

    &:checked {
        + label {
            &::after {
                display: block;
            }
        }
    }

    &:focus {
        + label {
            &::before {
                border-color: darken($color-athens-gray, 5%);
            }
        }
    }

    &:disabled {
        + label {
            cursor: default;

            &::before,
            &::after {
                opacity: 0.6;
            }
        }
    }
}

.error-message {
    padding-left: 35px;
    font-size: 12px;
    font-style: italic;
    color: $error-color;
}
</style>
