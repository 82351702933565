import { Message } from './message';
import { QuickReplyAnswer, IQuickReplyAnswer } from './quickReplyAnswer';
import { MessageType } from './enums/messageType.enum';
import { ButtonViewLayout } from '../../../src/store/enums/buttonViewLayout.enum';

export class QuickReply extends Message {
    text: string;
    answers: IQuickReplyAnswer[];
    avatarUrl: string;
    layout: ButtonViewLayout;
    maximum: number;
    minimum: number;
    isMultipleChoice: boolean;
    submitButtonText: string;

    constructor(
        text: string = '',
        answers: IQuickReplyAnswer[],
        avatarUrl: string,
        layout: ButtonViewLayout = ButtonViewLayout.horizontal,
        maximum: number = 0,
        minimum: number = 0,
        isMultipleChoice: boolean = false,
        submitButtonText: string = '',
    ) {
        super(MessageType.QuickReply, avatarUrl);
        this.text = text;
        this.answers = answers.map((o) => new QuickReplyAnswer(o.label, o.imageUrl));
        this.layout = layout;
        this.maximum = maximum;
        this.minimum = minimum;
        this.isMultipleChoice = isMultipleChoice;
        this.submitButtonText = submitButtonText;
    }
}
