<template>
    <button class="text-button" @click="$emit('click', $event)">
        <Icon v-if="icon" :name="icon" />
        <slot />
    </button>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    name: 'TextButton',
    components: { Icon },
    props: {
        icon: {
            type: String,
            default: undefined,
        },
    },
};
</script>

<style lang="scss" scoped>
.text-button {
    display: flex;
    align-items: center;
    padding: 0 4px;
    border-radius: 7.5px;
    font-family: $font-family-button;
    font-size: 10px;
    font-weight: $font-weight-bold;
    letter-spacing: 0.16em;
    color: $primary-text-button-text-color;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.3s;

    .icon {
        font-size: 1.1em;
        margin-right: 0.5em;
    }

    &:hover {
        background: $primary-text-button-bg-hover-color;
    }
}
</style>
