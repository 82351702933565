import { IMessage } from './interfaces/message.interface';
import { MessageType } from './enums/messageType.enum';

export class Message implements IMessage {
    constructor(type: MessageType, avatarUrl?: string) {
        this.type = type;
        this.avatarUrl = avatarUrl;
    }

    type: MessageType;
    avatarUrl?: string;
    showAvatar?: boolean = true;
}
