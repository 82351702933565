import axios from '@/utils/request';
import { LoadingFlag } from '@/store/enums';

export class UploadClient {
    public static async uploadImageFromFile(formData: any): Promise<any> {
        const url = '/content/upload/from-file';
        const result = await axios.request<any>({
            url,
            method: 'POST',
            data: formData,
            errorMessage: 'Could not upload file',
            loadingId: LoadingFlag.Uploading,
        });

        return result as unknown as Promise<any>;
    }

    public static async uploadImageFromUrl(data: any): Promise<any> {
        const url = '/content/upload/from-url';
        const result = await axios.request<any>({
            url,
            method: 'POST',
            data: data,
            errorMessage: 'Could not upload from url',
            loadingId: LoadingFlag.Uploading,
        });

        return result as unknown as Promise<any>;
    }
}
