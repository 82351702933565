<template>
    <div class="date-picker">
        <div v-if="isPdl" class="date-picker__skip">
            <Checkbox
                id="shouldSkipIfAnswered"
                label="Should skip if already answered"
                :value="data.shouldSkipIfAnswered"
                @input="(value) => onInput('shouldSkipIfAnswered', value)"
            />
        </div>

        <div v-if="isPdl" class="date-picker__tags-header">
            <Tags placeholder="" label="Tags" :value="data.tags" :disabled="true" />
        </div>

        <EditorInput
            v-if="$options.richTextMessagesEnabled"
            :id="generateId('message', path)"
            class="m-b-2"
            label="Message"
            show-remaining-char
            :content="data.message"
            :errors="data.errors.plainText"
            :max="640"
            :disabled="isDisabled"
            :menu-items="[
                $options.EditorInputMenuItem.Bold,
                $options.EditorInputMenuItem.Italic,
                $options.EditorInputMenuItem.Strike,
                $options.EditorInputMenuItem.Underline,
            ]"
            @update="onInput('message', $event)"
            @plainTextChange="onInput('plainText', $event)"
        />
        <Input
            v-else
            :id="generateId('message', path)"
            class="m-b-2"
            type="textarea"
            label="Message"
            name="message"
            :max="640"
            show-remaining-char
            :has-margin-bottom="false"
            :value="data.message"
            :errors="data.errors.message"
            :disabled="isDisabled"
            @input="
                (value) => {
                    onInput('message', value);
                    onInput('plainText', value);
                }
            "
        />

        <Input
            v-if="data.externalId && currentConvo.type !== $options.convoTypes.YougovWelcomeChat"
            :id="generateId('externalId', path)"
            type="text"
            label="External ID"
            class="m-b-2"
            :readonly="true"
            :has-margin-bottom="false"
            :name="generateId('externalId', path)"
            :value="data.externalId"
            :errors="data.errors.externalId"
        />

        <Select
            v-if="currentConvo.type === $options.convoTypes.YougovWelcomeChat"
            label="Datum Question"
            class="select"
            placeholder="Select question"
            show-clear-icon
            :disabled="yougovDefinitionOptions.length === 0"
            :is-loading="$wait.is($options.LoadingFlag.YougovDefinitionsGet)"
            :options="yougovDefinitionOptions"
            :value="data.datumDefinition"
            @input="definitionOnChange"
        />

        <div
            v-if="yougovDefinition && currentConvo.type === $options.convoTypes.YougovWelcomeChat"
            class="date-picker__date-message"
        >
            The date limits are between {{ yougovDefinition.min }} and {{ yougovDefinition.max }}.
            It's configured automatically based on the Datum definition configuration.
        </div>

        <div
            v-else-if="currentConvo.type === $options.convoTypes.YougovWelcomeChat"
            class="date-picker__date-message"
        >
            No question selected
        </div>

        <div v-if="currentConvo.type !== $options.convoTypes.YougovWelcomeChat">
            <p class="date-picker__label">Date validation rules</p>
            <div class="date-picker__date">
                <Select
                    :options="$options.dateTypes"
                    label=""
                    placeholder="Select date type"
                    :has-margin-bottom="false"
                    :errors="data.errors.dateType"
                    class="m-r-2 date-picker__date-select"
                    :value="data.dateType"
                    :disabled="isDisabled || isExternalPdl"
                    @input="(value) => onInput('dateType', value)"
                />

                <component
                    :is="dateComponent"
                    :data="data"
                    :disabled="isDisabled || isExternalPdl"
                    @input="({ key, value }) => onInput(key, value)"
                />
            </div>
        </div>

        <Input
            :id="generateId('submitButtonText', path)"
            class="m-b-2"
            type="text"
            label="Button Text"
            placeholder="Enter submit button text"
            name="submitButtonText"
            :max="40"
            show-remaining-char
            :has-margin-bottom="false"
            :value="data.submitButtonText"
            :errors="data.errors.submitButtonText"
            :disabled="isDisabled"
            @input="(value) => onInput('submitButtonText', value)"
        />

        <Accordion
            v-if="data.items"
            :items="data.items"
            :path="path"
            :is-draggable="false"
            @headerOnClick="(event, path) => $emit('headerOnClick', event, path)"
            @onExpand="(path) => $emit('onExpand', path)"
            @onExpanded="(path) => $emit('onExpanded', path)"
            @onContract="(path) => $emit('onContract', path)"
            @onContracted="(path) => $emit('onContracted', path)"
            @deleteOnClick="
                (event, path, parentPath) => $emit('deleteOnClick', event, path, parentPath)
            "
            @onDrop="(path, value) => $emit('onDrop', path, value)"
            @messageTypeOnSelect="
                (event, path, index) => $emit('messageTypeOnSelect', event, path, index)
            "
            @propertyOnChange="$emit('propertyOnChange', $event)"
        />
    </div>
</template>

<script>
import { DateType, DateRangeType } from '@/store/models/interfaces/validationRules.interface';
import DatePickerOffset from './DatePickerOffset';
import { Input, Select, Checkbox, Tags, EditorInput } from '@/components/forms';
import itemFormMixin from '@/mixins/itemFormMixin';
import { dateTypes } from './config';
import { EditorInputMenuItem, ConvoTypes, LoadingFlag } from '@/store/enums';
import { YougovDatumResponseTypes } from '@/enums';
import config from '@/config';

export default {
    name: 'DatePicker',
    components: {
        Tags,
        Input,
        Select,
        Checkbox,
        DatePickerOffset,
        EditorInput,
        Accordion: () => import('@/components/ui/Accordion'),
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        isPdl: {
            type: Boolean,
            default: false,
        },
        isExternalPdl: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        currentConvo: {
            type: Object,
            default: undefined,
        },
        yougovDefinitions: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        dateComponent() {
            const { dateType } = this.data;
            if (dateType === DateType.Any) {
                return null;
            }

            return DatePickerOffset;
        },
        yougovDefinitionOptions() {
            return this.yougovDefinitions.reduce((list, item) => {
                if (item.type === YougovDatumResponseTypes.Date) {
                    list.push({
                        ...item,
                        id: item.code,
                        value: `${item.code} - ${item.description}`,
                    });
                }
                return list;
            }, []);
        },
        yougovDefinition() {
            return this.yougovDefinitions.find((def) => def.code === this.data.datumDefinition);
        },
    },
    watch: {
        yougovDefinitions: {
            handler(newValue, oldValue) {
                const newDefinition = newValue.find(
                    (def) => def.code === this.data.datumDefinition,
                );
                const oldDefinition = oldValue.find(
                    (def) => def.code === this.data.datumDefinition,
                );

                if (newDefinition?.isCompletelyFetched && !oldDefinition?.isCompletelyFetched) {
                    this.calculateDateRangeValues();
                }
            },
            deep: true,
        },
    },
    created() {
        this.$options.richTextMessagesEnabled = config.featureToggle.richTextMessagesEnabled;
        this.$options.EditorInputMenuItem = EditorInputMenuItem;
        this.$options.dateTypes = dateTypes;
        this.$options.convoTypes = ConvoTypes;
        this.$options.LoadingFlag = LoadingFlag;
        this.calculateDateRangeValues(this.data.datumDefinition);
    },
    methods: {
        definitionOnChange(value) {
            this.onInput('datumDefinition', value);
            this.calculateDateRangeValues();
        },
        calculateDateRangeValues() {
            if (this.yougovDefinition?.isCompletelyFetched) {
                const currentYear = new Date().getFullYear();
                const recalculatedDateRangeValue =
                    currentYear - parseInt(this.yougovDefinition.min);
                const recalculatedDateRangeValueOffset =
                    currentYear - parseInt(this.yougovDefinition.max);

                this.onInput('dateRangeValue', recalculatedDateRangeValue);
                this.onInput('dateRangeValueOffset', recalculatedDateRangeValueOffset);
                this.onInput('dateRangeType', DateRangeType.Years);
                this.onInput('dateType', DateType.PastRange);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.date-picker {
    &__skip {
        border-bottom: 1px solid $container-default-border-color;
        margin-bottom: 25px;
    }

    &__label {
        margin-bottom: 4px;
        font-size: 14px;
    }

    &__date {
        margin-bottom: 32px;
        display: flex;
    }

    &__date-select {
        width: 175px;
    }

    &__date-message {
        margin-bottom: 30px;
        margin-top: 20px;
        font-size: 14px;
        font-weight: $font-weight-bold;
    }
}
</style>
