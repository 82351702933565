













import Vue from 'vue';
import Input from '@/components/forms/Input.vue';

export default Vue.extend({
    name: 'CopyText',
    components: {
        Input,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            default: '',
        },
    },
    data(): { textarea: HTMLInputElement | null } {
        return {
            textarea: null,
        };
    },
    mounted() {
        const input = this.$refs.input as Vue;
        this.textarea = input.$refs.textarea as HTMLInputElement;
        if (this.textarea) {
            this.$nextTick(this.setTextareaHeight);
            this.textarea.addEventListener('click', this.select, false);
        }
    },
    methods: {
        select(): void {
            if (this.textarea) {
                this.textarea.select();
            }
        },
        setTextareaHeight(): void {
            this.textarea!.style.height = `${this.textarea!.scrollHeight}px`;
        },
    },
});
