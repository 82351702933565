export enum LoadingFlag {
    ChannelCreate = 'channel-create',
    ChannelGet = 'channel-get',
    ChannelGetOne = 'channel-get-one',
    ChannelSave = 'channel-save',
    ChannelSetupFacebook = 'channel-setup-facebook',
    ChannelChangeStatus = 'channel-change-status',
    Uploading = 'uploading',
    MessagesGet = 'messages-get',
    MessagesSave = 'messages-save',
    ConvoCreate = 'convo-create',
    ConvoDelete = 'convo-delete',
    ConvoGet = 'convo-get',
    ConvoGetOne = 'convo-get-one',
    ConvoCategories = 'convo-categories',
    UserGetRoles = 'user-get-roles',
    UserGet = 'user-get',
    UserGetOne = 'user-get-one',
    UserSaveGroups = 'user-save-groups',
    BroadcastGet = 'broadcast-get',
    BroadcastsGet = 'broadcasts-get',
    BroadcastGetOne = 'broadcast-get-one',
    BroadcastCampaignGetOne = 'broadcast-campaign-get-one',
    EmailBroadcastSend = 'email-broadcast-send',
    CategoryGet = 'category-get',
    PlatformGet = 'platform-get',
    TagGet = 'tag-get',
    TagCreate = 'tag-create',
    ContentExists = 'content-exists',
    ContentGet = 'content-get',
    ContentGetBatch = 'content-get-batch',
    ContentGetOne = 'content-get-one',
    ConvoPublish = 'convo-publish',
    ConvoClone = 'convo-clone',
    SendNotification = 'send-notification',
    ChannelGroupReportGet = 'channel-group-report-get',
    ChannelGroupReportGetOne = 'channel-group-report-get-one',
    ChannelGroupGenerateReport = 'channel-group-generate-report',
    ReportGet = 'report-get',
    ReportGetRaw = 'report-get-raw',
    ReportGetText = 'report-get-text',
    ReportGetBreak = 'report-get-break',
    TenantsGet = 'tenants-get',
    TenantGet = 'tenant-get',
    TenantSave = 'tenant-save',
    CategorySave = 'category-save',
    CategoryGetOne = 'category-get-one',
    MessageResponseGet = 'message-response-get',
    AudienceGet = 'audience-get',
    AudienceGetOne = 'audience-get-one',
    AudienceSave = 'audience-save',
    AudienceEstimateRuleMember = 'audience-estimate-rule-members',
    UpdateConvoAttributes = 'update-convo-attributes',
    YGDProfileQuestionsGet = 'ygd-profile-questions-get',
    SystemNotificationsGet = 'system-notifications-get',
    ConvoScheduleCreate = 'convo-schedule-create',
    ConvoScheduleDelete = 'convo-schedule-delete',
    ReportSave = 'channel-group-report-save',
    ContentDiscoveryMenuCreate = 'content-discovery-menu-create',
    ContentDiscoveryMenuDelete = 'content-discovery-menu-delete',
    ContentDiscoveryMenuUpdate = 'content-discovery-menu-update',
    ContentDiscoveryMenuGet = 'content-discovery-menu-get',
    ContentDiscoveryMenuGetOne = 'content-discovery-menu-get-one',
    UserCommentsGet = 'user-comments-get',
    UserCommentsGetStats = 'user-comments-get-stats',
    UserCommentUpdate = 'user-comment-update',
    YougovDefinitionsGet = 'yougov-definitons-get',
    YougovDefinitionDetailsGet = 'yougov-definiton-details-get',
    TopicGet = 'topic-get',
    UpdateConvoTopics = 'update-convo-topics',
}
