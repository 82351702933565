<template>
    <div>
        <ContentEditableWithVariables
            :id="generateId('title', path)"
            class="m-b-2"
            name="title"
            label="Title"
            :variable-list="$options.templateVariables"
            :value="data.title"
            :errors="data.errors && data.errors.title"
            @input="onInput('title', $event)"
        />

        <ContentEditableWithVariables
            :id="generateId('subtitle', path)"
            class="m-b-2"
            name="Subtitle"
            label="Subtitle"
            :variable-list="$options.templateVariables"
            :value="data.subtitle"
            :errors="data.errors && data.errors.subtitle"
            @input="onInput('subtitle', $event)"
        />

        <ImageUploaderButton
            label="Image"
            class="m-b-3"
            :button-label="data.url || 'Click to Browse and Upload Image…'"
            :value="data.imageUrl"
            :errors="data.errors && data.errors.imageUrl"
            @input="onInput('imageUrl', $event)"
        />

        <ContentEditableWithVariables
            :id="generateId('share-title', path)"
            name="sharing-title"
            class="m-b-2"
            label="Share Title"
            :variable-list="$options.templateVariables"
            :value="data.sharingTitle"
            :errors="data.errors.sharingTitle"
            @input="onInput('sharingTitle', $event)"
        />

        <ContentEditableWithVariables
            :id="generateId('share-subtitle', path)"
            name="sharing-subtitle"
            label="Share Subtitle"
            :variable-list="$options.templateVariables"
            :value="data.sharingSubtitle"
            :errors="data.errors.sharingSubtitle"
            @input="onInput('sharingSubtitle', $event)"
        />
    </div>
</template>

<script>
import ImageUploaderButton from '@/components/forms/ImageUploaderButton';
import ContentEditableWithVariables from '@/components/forms/ContentEditableWithVariables';
import itemFormMixin from '@/mixins/itemFormMixin';
import templateVariables from '@/json/templateVariables.ts';

export default {
    name: 'Result',
    components: {
        ImageUploaderButton,
        ContentEditableWithVariables,
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
    },
    created() {
        this.$options.templateVariables = templateVariables;
    },
};
</script>
