import { RegistrationType } from '../enums';
import {
    IApplication,
    IApplicationLinkOptions,
    IApplicationOptions,
    IApplicationRegistrationOptions,
    IApplicationSignupOptions,
} from './interfaces/application.interface';

export class Application implements IApplication {
    id: string;
    code: string;
    name: string;
    signupType: string;
    skipAuthForKnownUsers: boolean;
    allowNoSignup: boolean;
    fingerprintTrackingEnabled: boolean;
    maxFreeConvos: number;
    tenantId: string;
    registrationType: RegistrationType;
    registrationOptions: IApplicationRegistrationOptions;
    linkOptions: IApplicationLinkOptions;
    signupOptions: IApplicationSignupOptions;
    options: IApplicationOptions;
}
