<template>
    <div class="content-editable-container">
        <div
            class="form-field content-editable"
            :class="{
                'content-editable--input': type === 'input',
                'content-editable--textarea': type === 'textarea',
                'form-field--is-focused': isFocused,
                'form-field--has-error': errors.length > 0,
                'form-field--is-disabled': disabled,
            }"
        >
            <div
                class="form-field-label content-editable__label"
                :class="{ 'form-field-label--hidden': false }"
                @click="setFocus"
            >
                <span>{{ label }}</span>
            </div>

            <div
                v-once
                id="content-id"
                ref="input"
                class="form-field-input content-editable__input plain-text-only"
                contenteditable="true"
                :data-placeholder="placeholder"
                @focus="onFocus"
                @blur="onBlur"
                @input="onInput"
                @keydown="onKeydown"
                @keyup="onKeyup"
                @keypress="onKeypress"
                @mouseup="onMouseup"
                v-html="value"
            ></div>
        </div>

        <div v-if="showAdditionalInfo" class="form-field-additional-information">
            <div class="form-field-additional-information__left">
                <div
                    v-if="errors.length > 0"
                    class="form-field-error-message content-editable__error"
                >
                    <Icon name="alert" />{{ errors[0] }}
                </div>

                <div v-if="infoText" class="form-field-info-text">{{ infoText }}</div>
            </div>

            <CharCounter v-if="showRemainingChars" :count="value.length" :max="maxChars" />
        </div>
    </div>
</template>

<script>
import CharCounter from '@/components/forms/partials/CharCounter';
import Icon from '@/components/ui/Icon';

const MAX_CHARS_ALLOWED_KEYS = [8, 16, 37, 39, 91];

export default {
    name: 'ContentEditable',
    components: {
        CharCounter,
        Icon,
    },
    props: {
        type: {
            type: String,
            default: 'input',
            validator: (value) => ['input', 'textarea'].includes(value),
        },
        value: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        infoText: {
            type: String,
            default: undefined,
        },
        errors: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showRemainingChars: {
            type: Boolean,
            default: false,
        },
        maxChars: {
            type: Number,
            default: undefined,
        },
    },
    data() {
        return {
            isFocused: false,
        };
    },
    computed: {
        showAdditionalInfo() {
            return this.errors.length > 0 || this.infoText || this.showRemainingChars;
        },
    },
    methods: {
        allowInput(event) {
            const maxCharCondition =
                this.maxChars === undefined || event.target.innerHTML.length < this.maxChars;
            const allowedKeysCondition = MAX_CHARS_ALLOWED_KEYS.includes(event.keyCode);
            return maxCharCondition || allowedKeysCondition;
        },
        setFocus() {
            this.$refs.input.focus();
        },
        onInput(event) {
            this.$emit('input', event.target.innerHTML);
        },
        onFocus(event) {
            this.isFocused = true;
            this.$emit('focus', event);
        },
        onBlur(event) {
            this.isFocused = false;
            this.$emit('blur', event);
        },
        onKeydown(event) {
            if (!this.allowInput(event)) {
                event.preventDefault();
            }
            this.$emit('keydown', event);
        },
        onKeyup(event) {
            this.$emit('keyup', event);
        },
        onKeypress(event) {
            this.$emit('keypress', event);
        },
        onMouseup(event) {
            this.$emit('mouseup', event);
        },
    },
};
</script>

<style scoped lang="scss">
.content-editable {
    position: relative;
}

.content-editable__input {
    width: 100%;
    white-space: pre-wrap;
    display: inline-block;
    height: auto;

    &:empty:before {
        content: attr(data-placeholder);
        position: absolute;
        color: $input-placeholder-text-color;
        background-color: transparent;
        cursor: text;
    }

    .content-editable--input & {
        min-height: $form-input-height - 2px;
    }

    .content-editable--textarea & {
        min-height: $form-textarea-height;
    }
}
</style>

<style lang="scss">
.plain-text-only {
    a {
        color: inherit !important;
    }
    strong {
        font-weight: $font-weight-regular;
    }
    em {
        font-style: normal;
    }
    s,
    u {
        text-decoration: none;
    }
}
</style>
