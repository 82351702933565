import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState } from '@/store/types/rootState';
import yougovClient from '@/api/yougov';
import { YougovDefinitionFactory } from '@/store/models/factories/yougovDefinition.factory';
import { YougovDefinition } from '@/store/models/yougovDefinition';
import { SET_DEFINITIONS } from '@/store/mutations.types';
import { IYougovDefinitionDto } from '@/store/models/dtos';

export interface IYougovState {
    definitions: YougovDefinition[];
}

const state: IYougovState = {
    definitions: [],
};

const getters: GetterTree<IYougovState, IRootState> = {};

const actions: ActionTree<IYougovState, any> = {
    setDefinitions: async ({ commit }, country: string) => {
        if (!country) {
            commit(SET_DEFINITIONS, []);
            return;
        }

        const yougovDefinitionFactory = new YougovDefinitionFactory();
        const result = await yougovClient.getDefinitions({ country: country?.toLowerCase() });
        const definitions: YougovDefinition[] = result.map(yougovDefinitionFactory.make);
        commit(SET_DEFINITIONS, definitions);
    },

    getDefinitionDetails: async (
        { commit, state },
        { definition, country }: { definition: string; country: string },
    ) => {
        if (!definition || !country) {
            return;
        }

        const yougovDefinitionFactory = new YougovDefinitionFactory();
        const definitionFromState = state.definitions.find((def) => def.code === definition);
        const result = !definitionFromState?.isCompletelyFetched
            ? await yougovClient.getDefinitionDetails({
                  definition,
                  country: country?.toLowerCase(),
              })
            : definitionFromState;

        const definitions: YougovDefinition[] = state.definitions.map((def: YougovDefinition) => {
            if (def.code === result.code) {
                const definitionDetail = yougovDefinitionFactory.make({
                    ...def,
                    ...result,
                } as IYougovDefinitionDto);
                definitionDetail.isCompletelyFetched = true;
                return definitionDetail;
            }

            return def;
        });

        commit(SET_DEFINITIONS, definitions);
    },
};

const mutations: MutationTree<IYougovState> = {
    [SET_DEFINITIONS](state: IYougovState, definitions: YougovDefinition[]): void {
        state.definitions = definitions;
    },
};

export const yougov: Module<IYougovState, IRootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
