import { IAudience, IRule, IRuleSet } from './interfaces/audience.interface';
import { IsNotEmpty, validate as validator } from 'class-validator';
import { IValidatable } from './interfaces/validatable.interface';
import { getErrorMessage } from '../services/validationService';
import { AudienceExportStatus } from '../enums/audienceExport.enum';

export class Audience implements IAudience, IValidatable<IAudience> {
    id: number = 0;

    // disabling till all backend changes are completed for tenant
    //@IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Tenant') })
    tenantCode: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Export To') })
    exportTo: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Name') })
    name: string;

    rules: (IRuleSet | IRule)[];

    exportPlatform: string;

    updatedAt: string;

    createdAt: string;

    exported: AudienceExportStatus;

    constructor() {
        this.name = '';
    }

    async validate() {
        const errors = await validator(this);
        return errors.reduce((acc, cur) => {
            acc[cur.property] = Object.values(cur.constraints || {});
            return acc;
        }, <any>{});
    }
}
