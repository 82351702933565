import { ConvoType } from '@/store/models/convoType';
import { ConvoMessageItemType, ConvoTypes } from '@/store/enums';

export default [
    new ConvoType({
        name: 'interests',
        title: 'Interests',
        icon: 'yes-no',
        availableMessageTypes: [
            ConvoMessageItemType.QuickReply,
            ConvoMessageItemType.Buttons,
            ConvoMessageItemType.TextStatement,
            ConvoMessageItemType.TextInput,
            ConvoMessageItemType.Rating,
        ],
        convoAttributes: {
            categories: {
                disabled: true,
            },
        },
    }),
    new ConvoType({
        name: 'quiz',
        title: 'Trivia Quiz',
        icon: 'quiz_trivia',
        availableMessageTypes: [
            ConvoMessageItemType.QuickReply,
            ConvoMessageItemType.Buttons,
            ConvoMessageItemType.Carousel,
            ConvoMessageItemType.TextStatement,
            ConvoMessageItemType.Registration,
        ],
    }),
    new ConvoType({
        name: 'personality_quiz',
        title: 'Personality Quiz',
        icon: 'quiz_personality',
        availableMessageTypes: [
            ConvoMessageItemType.QuickReply,
            ConvoMessageItemType.Buttons,
            ConvoMessageItemType.Carousel,
            ConvoMessageItemType.TextStatement,
            ConvoMessageItemType.Registration,
        ],
    }),
    new ConvoType({
        name: 'chat',
        title: 'Scripted',
        icon: 'scripted',
    }),
    new ConvoType({
        name: 'pdl',
        title: 'PDL Message Library',
        icon: 'msg-library',
        availableMessageTypes: [
            ConvoMessageItemType.QuickReply,
            ConvoMessageItemType.Buttons,
            ConvoMessageItemType.TextStatement,
        ],
        hidden: true,
        convoAttributes: {
            categories: {
                disabled: true,
            },
        },
    }),
    new ConvoType({
        name: ConvoTypes.YougovWelcomeSurvey,
        title: 'Yougov welcome survey',
        icon: 'bubble-question',
        availableMessageTypes: [
            ConvoMessageItemType.QuickReply,
            ConvoMessageItemType.Buttons,
            ConvoMessageItemType.Carousel,
            ConvoMessageItemType.TextStatement,
        ],
        hidden: true,
        availableInMessageLibrary: true,
        isGlobal: true,
        convoAttributes: {
            categories: {
                disabled: true,
            },
        },
    }),
    new ConvoType({
        name: ConvoTypes.YougovWelcomeChat,
        title: 'Yougov Welcome Chat',
        icon: 'msg-library',
        availableMessageTypes: [
            ConvoMessageItemType.Buttons,
            ConvoMessageItemType.QuickReply,
            ConvoMessageItemType.TextStatement,
            ConvoMessageItemType.DatePicker,
            ConvoMessageItemType.TextInput,
        ],
        hidden: true,
        availableInMessageLibrary: true,
        isGlobal: false,
        convoAttributes: {
            categories: {
                disabled: true,
            },
            countries: {
                max: 1,
            },
        },
    }),
];
