<template>
    <div>
        <div class="buttons-answer-header">
            <Input
                :id="generateId('tileTitle', path)"
                type="text"
                label="Title"
                :name="generateId('tileTitle', path)"
                :max="100"
                show-remaining-char
                :value="data.tileTitle"
                :errors="data.errors.tileTitle"
                @input="(value) => onInput('tileTitle', value)"
                @changed="onChanged"
            />
            <Input
                :id="generateId('subtitle', path)"
                type="text"
                label="Subtitle"
                :name="generateId('subtitle', path)"
                :max="200"
                show-remaining-char
                :value="data.subtitle"
                :errors="data.errors.subtitle"
                @input="(value) => onInput('subtitle', value)"
                @changed="onChanged"
            />
            <ImageUploaderButton
                input-style="green"
                :button-label="data.image || 'Click here to attach an image'"
                :value="data.image"
                :errors="data.errors.image"
                @input="(value) => onInput('image', value)"
                @changed="onChanged"
            />
        </div>
        <ErrorMessage v-if="data.errors.buttonsAnswers && data.errors.buttonsAnswers.length > 0">
            {{ data.errors['buttonsAnswers'][0] }}
        </ErrorMessage>
        <AccordionNew
            v-if="data.items"
            :items="data.items"
            :is-draggable="true"
            :path="path"
            @onDrop="(path, value) => $emit('onDrop', path, value)"
        >
            <ButtonsAnswer
                v-for="(item, index) in buttons"
                :key="index"
                :data="item"
                :path="[...path, index]"
                :index="index"
                :marker="getMarker(item.marker, item.markerType, startIndex, index)"
                :is-draggable="true"
                :animation-disabled="false"
                :ignore-quiz-question="ignoreQuizQuestion"
                :current-channel="currentChannel"
                :current-convo="currentConvo"
                @openLogicJump="$emit('openLogicJump', $event)"
                @headerOnClick="(event, path) => $emit('headerOnClick', event, path)"
                @onExpand="(path) => $emit('onExpand', path)"
                @onExpanded="(path) => $emit('onExpanded', path)"
                @onContract="(path) => $emit('onContract', path)"
                @onContracted="(path) => $emit('onContracted', path)"
                @deleteOnClick="buttonDeleteOnClick"
                @onDrop="(path, value) => $emit('onDrop', path, value)"
                @messageTypeOnSelect="
                    (event, path, index) => $emit('messageTypeOnSelect', event, path, index)
                "
                @addItemOnClick="(event, path, type) => $emit('addItemOnClick', event, path, type)"
                @propertyOnChange="buttonPropertyOnChange"
                @addAttribute="(payload) => $emit('addAttribute', payload)"
                @removeAttribute="(payload) => $emit('removeAttribute', payload)"
            />
        </AccordionNew>
        <div class="align-center">
            <TextButton
                v-if="data.items.length < data.itemsMaxLength"
                class="m-b-1"
                icon="plus-2"
                @click="addTileButtonOnClick"
            >
                Add button
            </TextButton>
        </div>
    </div>
</template>

<script>
import Input from '@/components/forms/Input';
import AccordionNew from '@/components/ui/Accordion/indexNew';
import ErrorMessage from '@/components/ui/ErrorMessage';
import ImageUploaderButton from '@/components/forms/ImageUploaderButton';
import TextButton from '@/components/ui/TextButton';
import ButtonsAnswer from '@/components/ButtonsAnswer';
import itemFormMixin from '@/mixins/itemFormMixin';
import { AccordionStyle } from '@/store/enums/accordionStyle.enum.ts';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum.ts';
import { ButtonStyle } from '@/store/enums/buttonStyle.enum.ts';
import { getMarker } from '@/utils/listMarkers.ts';

export default {
    name: 'CarouselTile',
    components: {
        Input,
        ImageUploaderButton,
        TextButton,
        ButtonsAnswer,
        AccordionNew,
        ErrorMessage,
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        startIndex: {
            type: Number,
            default: 1,
        },
        index: {
            type: Number,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        marker: {
            type: String,
            required: true,
        },
        areMessageItemsValid: {
            type: Boolean,
            default: true,
        },
        ignoreQuizQuestion: {
            type: Boolean,
            required: true,
        },
        currentChannel: {
            type: Object,
            default: undefined,
        },
        currentConvo: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            AccordionStyle,
            ButtonStyle,
            ConvoMessageItemType,
        };
    },
    computed: {
        typeOptions() {
            return this.data.answerTypeOptions.map((item) => ({
                id: item.name,
                value: item.title,
            }));
        },
        buttons() {
            return this.data.items.filter((o) => o.type === ConvoMessageItemType.ButtonsAnswer);
        },
    },
    methods: {
        getMarker,
        async addTileButtonOnClick() {
            this.$emit('addItemOnClick', event, this.path, ConvoMessageItemType.ButtonsAnswer);
            this.setTileLink();
        },
        buttonDeleteOnClick(event, path, parentPath) {
            this.$emit('deleteOnClick', event, path, parentPath);
            this.setTileLink();
        },
        buttonPropertyOnChange(payload) {
            this.$emit('propertyOnChange', payload);
            this.setTileLink();
        },
        setTileLink() {
            if (this.buttons.length === 1 && this.buttons[0].link) {
                this.onInput('link', this.buttons[0].link);
            } else {
                this.onInput('link', '');
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.buttons-answer-header {
    flex: 1 1 auto;
    padding: 10px 0;
}

.closed-header {
    overflow: hidden;
}

.label {
    padding-right: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.answer-type-select {
    position: relative;
    z-index: 1;
}
</style>
