import { ChannelGroupReport } from '../channelGroupReport';
import { IChannelGroupReportDto } from '../dtos/channelGroupReport.dto';
import { IChannelGroupReport } from '../interfaces/channelGroupReport.interface';

export class ChannelGroupReportFactory {
    public static make(channelReportDto: IChannelGroupReportDto): IChannelGroupReport {
        const channelReportData = new ChannelGroupReport();
        channelReportData.name = channelReportDto.name;
        channelReportData.id = channelReportDto.id;
        channelReportData.createdAt = channelReportDto.createdAt;
        channelReportData.updatedAt = channelReportDto.updatedAt;
        channelReportData.tagConfig = channelReportDto.tagConfig;
        return channelReportData;
    }
}
