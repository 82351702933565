<template>
    <div>
        <MessageWrapper :avatar-url="avatarUrl">
            <MessageBubble class="text-message" :show-arrow="showArrow">
                <div class="simple-text" @click="onClick(item)" v-html="item.text"></div>
            </MessageBubble>
        </MessageWrapper>

        <AnswersWrapper
            class="message-buttons"
            :items="item.answers"
            :highlighted-message="highlightedMessage"
            :is-multiple-choice="item.isMultipleChoice"
            :layout="item.layout"
        >
            <button
                v-for="(answer, index) in item.answers"
                :key="index"
                :ref="`button[${index}]`"
                class="message-button"
                :class="{ 'message-button--has-image': answer.imageUrl }"
                @click="$emit('answerOnClick', index, answer)"
            >
                <img
                    v-if="answer.imageUrl"
                    class="message-button-image"
                    :src="answer.imageUrl"
                    alt="Quick reply image"
                />
                {{ answer.label }}
            </button>
        </AnswersWrapper>
    </div>
</template>

<script>
import MessageWrapper from './MessageWrapper';
import MessageBubble from './MessageBubble';
import AnswersWrapper from './AnswersWrapper';
import messageMixin from '../mixins/message.mixin';

export default {
    name: 'QuickReply',
    components: {
        MessageWrapper,
        MessageBubble,
        AnswersWrapper,
    },
    mixins: [messageMixin],
    props: {
        item: {
            type: Object,
            default: undefined,
        },
        highlightedMessage: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        avatarUrl() {
            return this.item.showAvatar && this.item.avatarUrl ? this.item.avatarUrl : undefined;
        },
        showArrow() {
            return this.avatarUrl ? true : false;
        },
    },
};
</script>

<style lang="scss" scoped>
.text-message {
    .simple-text {
        font-family: $font-family-default;
        font-size: $font-size-xs;
        font-weight: $font-weight-default;
        line-height: 1.37;
        text-align: left;
        color: $default-text-color;

        @include media-query(medium) {
            font-size: $font-size-xxs;
        }
    }
}

.message-buttons {
    margin-top: 8px;
}

.message-button {
    position: relative;
    white-space: nowrap;
    min-width: 80px;
    height: 34px;
    padding: 4px 10px;
    border-radius: 4px;
    border: 2px solid $quick-reply-answer-button-border-color;
    background: $quick-reply-answer-button-bg-color;
    font-size: 12px;
    color: $quick-reply-answer-button-text-color;
    text-align: center;
    font-weight: $font-weight-bold;
    cursor: pointer;

    &:not(:last-child) {
        margin-right: 5px;
    }

    @include media-query(medium) {
        height: 28px;
        padding: 0px 5px;
        font-size: 10px;
    }

    &--has-image {
        padding-left: 32px;

        @include media-query(medium) {
            padding-left: 23px;
        }
    }
}

.message-button-image {
    @include center(y);
    position: absolute;
    left: 3px;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    object-fit: cover;

    @include media-query(medium) {
        width: 18px;
        height: 18px;
        border-radius: 18px;
    }

    @include media-query(medium) {
        font-size: 10px;
    }
}
</style>
