export enum MessageType {
    SimpleText = 'simple-text',
    Reply = 'reply',
    Attachment = 'attachment',
    QuickReply = 'quick-reply',
    Buttons = 'buttons',
    Carousel = 'carousel',
    Video = 'video',
    CommentsRating = 'comments-rating',
    Rating = 'rating',
}
