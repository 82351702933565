export default {
    data() {
        return {};
    },
    methods: {
        onClick(item) {
            this.$emit('click', item);
        },
    },
};
