import config from '@/config';
import { MessageType } from '@/store/models';

const messageTypes = [
    new MessageType({
        name: 'quickReply',
        title: 'Quick Reply',
        icon: 'quick-reply',
    }),
    new MessageType({
        name: 'buttons',
        title: 'Buttons',
        icon: 'button',
    }),
    new MessageType({
        name: 'textStatement',
        title: 'Statement (Text)',
        icon: 'statement-text',
    }),
    new MessageType({
        name: config.featureToggle.imageMessageType ? 'image' : 'attachmentUrl',
        title: 'Image',
        icon: 'image',
    }),
    new MessageType({
        name: 'video',
        title: 'Video',
        icon: 'video',
    }),
    new MessageType({
        name: 'carousel',
        title: 'Carousel',
        icon: 'carousel-horizontal',
    }),
    new MessageType({
        name: 'textInput',
        title: 'Text Input',
        icon: 'text-input',
    }),
    new MessageType({
        name: 'datePicker',
        title: 'Date Picker',
        icon: 'calendar',
    }),
    new MessageType({
        name: 'rating',
        title: 'Rating',
        icon: 'thumb-up',
    }),
];

if (config.featureToggle.giphyMessageType) {
    messageTypes.splice(
        4,
        0,
        new MessageType({
            name: 'gif',
            title: 'GIF',
            icon: 'giphy',
        }),
    );
}

if (config.featureToggle.commentsRatingMessageType) {
    messageTypes.splice(
        8,
        0,
        new MessageType({
            name: 'commentsRating',
            title: 'Comments Rating',
            icon: 'comments-rating',
        }),
    );
}

export default messageTypes;
