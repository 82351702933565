import { LoadingFlag } from '@/store/enums';
import { SearchContentRequest, UpdateTenantRequest } from '@/store/models/dtos';
import { UpdateTenantChannelRequest } from '@/store/models/dtos/tenantRequests.dto';
import {
    ICategory,
    IPlatform,
    ISearchResponse,
    ITag,
    ITenantResponse,
} from '@/store/models/interfaces';
import { IPaging } from '@inconvo/types/interfaces';
import axios from '@/utils/request';

export class TenantClient {
    public static async getCategories(): Promise<ICategory[]> {
        const url = '/category';
        const result = await axios.request({
            url,
            method: 'GET',
            errorMessage: 'Could not get categories',
            loadingId: LoadingFlag.CategoryGet,
        });
        return result as unknown as Promise<ICategory[]>;
    }
    public static async getPlatforms(): Promise<IPlatform[]> {
        const url = '/inconvo/platforms';
        const result = await axios.request({
            url,
            method: 'GET',
            errorMessage: 'Could not get Platforms',
            loadingId: LoadingFlag.PlatformGet,
        });
        return result as unknown as Promise<IPlatform[]>;
    }
    public static async getTags(filter: string, type: string): Promise<ITag[]> {
        const url = `/inconvo/tags?q=${filter}&type=${type}`;
        const result = await axios.request({
            url,
            method: 'GET',
            errorMessage: 'Could not get tags',
            loadingId: LoadingFlag.TagGet,
        });
        return result as unknown as Promise<ITag[]>;
    }
    public static async getAnyContentType(
        params: SearchContentRequest,
    ): Promise<IPaging<ISearchResponse>> {
        const url = `/content/search`;

        const result = await axios.request<SearchContentRequest>({
            url,
            method: 'GET',
            errorMessage: 'Could not get content',
            loadingId: LoadingFlag.ContentGet,
            params,
        });
        return result as unknown as Promise<IPaging<ISearchResponse>>;
    }
    public static async getLogicJumpData({
        targetChannel,
        targetConvo,
        targetContentItem,
    }: {
        targetChannel: string;
        targetConvo: number;
        targetContentItem: number;
    }): Promise<ISearchResponse> {
        const url = `/content`;
        const result = await axios.request({
            url,
            method: 'GET',
            errorMessage: 'Could not get logic jump data',
            loadingId: LoadingFlag.ContentGetOne,
            params: {
                targetChannel: !targetContentItem && !targetConvo ? targetChannel : undefined,
                goToBlock: !targetContentItem ? targetConvo : undefined,
                goToWidget: targetContentItem,
            },
        });

        return result as unknown as Promise<ISearchResponse>;
    }

    public static async getTenants(): Promise<ITenantResponse[]> {
        const url = `/tenants`;

        const result = await axios.request({
            url: url,
            method: 'GET',
            errorMessage: 'Could not get tenants.',
            loadingId: LoadingFlag.TenantsGet,
        });

        return result as unknown as Promise<ITenantResponse[]>;
    }

    public static async getTenant(code: string): Promise<ITenantResponse> {
        const url = `/tenants/${code}`;

        const result = await axios.request({
            url: url,
            method: 'GET',
            errorMessage: 'Could not get tenant.',
            loadingId: LoadingFlag.TenantGet,
        });

        return result as unknown as Promise<ITenantResponse>;
    }

    public static async updateTenant(
        code: string,
        data: UpdateTenantRequest,
    ): Promise<ITenantResponse> {
        const url = `/tenants/${code}`;

        const result = await axios.request<UpdateTenantRequest>({
            data: data,
            url: url,
            method: 'PUT',
            errorMessage: 'Could not save tenant.',
            loadingId: LoadingFlag.TenantSave,
        });

        return result as unknown as Promise<ITenantResponse>;
    }

    public static async getMessageResponse(messageId: string): Promise<string[]> {
        const url = `/content/content-items/${messageId}/responses`;
        const result = await axios.request({
            url: url,
            method: 'GET',
            errorMessage: 'Could not get responses for the given message id',
            loadingId: LoadingFlag.MessageResponseGet,
        });
        return result as unknown as Promise<string[]>;
    }

    public static async updateTenantOnChannelModification(
        payload: UpdateTenantChannelRequest,
    ): Promise<void> {
        const url = '/tenants/update-tenant-channel';
        await axios.request({
            url,
            method: 'POST',
            data: payload,
            errorMessage: 'Could not update tenant-channel',
        });
    }
}
