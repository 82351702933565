import { IContentItem } from '../interfaces';
import { Image, ContentItem } from '..';
import { ContentItemType } from '@/store/enums/';
import { IAnswer } from '../interfaces/answer.interface.dto';
import { Answer } from '../answer.dto';
import { IConvoState } from '@/store/modules/convo-details';

export class ImageContentItemFactory {
    make(obj: Image, state: IConvoState): IContentItem {
        const contentItem = new ContentItem();
        contentItem.id = obj.id;
        contentItem.sort = obj.sort;
        contentItem.tags = obj.tags || [];
        contentItem.type = ContentItemType.Image;

        if (obj.image) {
            contentItem.attachment = JSON.stringify({
                url: obj.image.url,
                width: obj.image.width,
                height: obj.image.height,
            });
        } else {
            contentItem.attachment = JSON.stringify({});
        }

        contentItem.answers = [];
        const answer: IAnswer = new Answer();
        answer.convoId = state.convo.id;
        contentItem.answers.push(answer);

        return contentItem;
    }
}
