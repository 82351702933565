<template>
    <div class="standard-share-message">
        <Input
            id="message"
            type="text"
            name="message"
            label="Message"
            show-remaining-char
            :max="80"
            :value="data.message"
            :errors="data.errors && data.errors.message"
            @input="(value) => onInput('message', value)"
            @changed="onChanged"
        />
        <Input
            id="tagline"
            type="text"
            name="tagline"
            label="Tagline"
            show-remaining-char
            :max="80"
            :value="data.tagline"
            :errors="data.errors && data.errors.tagline"
            @input="(value) => onInput('tagline', value)"
            @changed="onChanged"
        />
        <Input
            id="invite-message"
            type="text"
            name="invite-message"
            label="Invite Message"
            show-remaining-char
            :max="45"
            :value="data.inviteMessage"
            :errors="data.errors && data.errors.inviteMessage"
            @input="(value) => onInput('inviteMessage', value)"
            @changed="onChanged"
        />
        <Input
            id="invite-tagline"
            type="text"
            name="invite-tagline"
            label="Invite Tagline"
            show-remaining-char
            :max="48"
            :value="data.inviteTagline"
            :errors="data.errors && data.errors.inviteTagline"
            @input="(value) => onInput('inviteTagline', value)"
            @changed="onChanged"
        />
        <Input
            id="link"
            type="url"
            name="link"
            label="Link"
            :value="data.link"
            :errors="data.errors && data.errors.link"
            @input="(value) => onInput('link', value)"
            @changed="onChanged"
        />
        <ImageUploaderButton
            class="m-b-2"
            title="Upload Image"
            :button-label="data.imageUrl || undefined"
            :value="data.imageUrl"
            :errors="data.errors && data.errors.imageUrl"
            @input="(value) => onInput('imageUrl', value)"
            @changed="onChanged"
        />
        <Input
            id="button-text"
            type="text"
            name="button-text"
            label="Button Text"
            show-remaining-char
            :max="49"
            :value="data.buttonText"
            :errors="data.errors && data.errors.buttonText"
            @input="(value) => onInput('buttonText', value)"
            @changed="onChanged"
        />
    </div>
</template>

<script>
import Input from '@/components/forms/Input';
import ImageUploaderButton from '@/components/forms/ImageUploaderButton';
import itemFormMixin from '@/mixins/itemFormMixin';

export default {
    name: 'StandardShareMessage',
    components: {
        Input,
        ImageUploaderButton,
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
    },
};
</script>
