import { IContentItem } from '../interfaces';
import { Gif } from '..';

export class GifAccordionItemFactory {
    public make(contentItem: IContentItem): Gif {
        const giphyItem = new Gif();

        giphyItem.gif = contentItem.attachment && JSON.parse(contentItem.attachment);
        giphyItem.id = contentItem.id || 0;
        giphyItem.sort = contentItem.sort;
        giphyItem.tags = contentItem.tags;
        giphyItem.title = 'Gif';
        giphyItem.setTitle();

        return giphyItem;
    }
}
