var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"default-accordion-item-wrapper",class:[
        {
            opened: _vm.open,
            'has-m-b': _vm.hasMarginBottom,
            'default-accordion-item-wrapper--deleted': _vm.isDeleted,
        },
        ("default-accordion-item-wrapper--" + _vm.accordionStyle) ]},[_c('div',{staticClass:"drag-and-drop-handle-wrapper"},[(_vm.isDraggable)?_c('button',{staticClass:"drag-and-drop-handle"},[_c('Icon',{attrs:{"name":"drag-handle"}})],1):_vm._e()]),_c('AccordionItem',{staticClass:"default-accordion-item",class:{ 'is-openable': _vm.isOpenable },attrs:{"open":_vm.open,"is-openable":_vm.isOpenable,"is-pdl":_vm.isPdl,"is-external-pdl":_vm.isExternalPdl,"duration":_vm.duration,"animation-disabled":_vm.animationDisabled},on:{"headerOnClick":function($event){!_vm.isDeleted && _vm.$emit('headerOnClick', $event)},"onExpand":function($event){return _vm.$emit('onExpand')},"onExpanded":function($event){return _vm.$emit('onExpanded')},"onContract":function($event){return _vm.$emit('onContract')},"onContracted":function($event){return _vm.$emit('onContracted')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"default-accordion-item-header",class:{
                    opened: _vm.open,
                    'animation-enabled': !_vm.animationDisabled,
                    incomplete: _vm.isValid === false && !_vm.isDeleted,
                    'is-pdl': _vm.isPdl,
                }},[_vm._t("header"),_c('div',{staticClass:"badges"},[(_vm.isNew)?_c('span',{staticClass:"badge"},[_vm._v("new")]):_vm._e(),(_vm.isPdl)?_c('span',{staticClass:"badge"},[_vm._v("PDL")]):_vm._e()]),(_vm.isOpenable && !_vm.isDeleted)?_c('Icon',{staticClass:"accordion-arrow",attrs:{"name":"arrow-down"}}):_vm._e()],2),(_vm.isDeleted)?_c('button',{staticClass:"undo-delete-label",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('undoDeletedItemOnClick')}}},[_vm._v(" Undo delete "),_c('Icon',{attrs:{"name":"undo"}})],1):_vm._e()]},proxy:true}],null,true)},[[_c('div',{staticClass:"default-accordion-item-body",class:{ 'default-accordion-item-body--large-padding': _vm.bodyHasLargePadding }},[_vm._t("default")],2)]],2),_c('div',{staticClass:"accordion-item-buttons"},[(!_vm.isDeleted && _vm.isDeletable)?_c('Menu',{attrs:{"button-icon":"settings"}},[_c('DropDownItem',{attrs:{"icon":"delete-full"},on:{"click":function($event){return _vm.$emit('deleteOnClick', $event)}}},[_vm._v("Delete item")]),(_vm.cloneEnabled)?_c('DropDownItem',{attrs:{"icon":"clone-convo"},on:{"click":function($event){return _vm.$emit('cloneOnClick', $event)}}},[_vm._v("Clone")]):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }