import { LoadingFlag } from '@/store/enums';
import { ChannelGroupReport } from '@/store/models/channelGroupReport';
import {
    GetChannelGroupReportListRequest,
    IChannelGroupReportDto,
    GetChannelGroupReportRequest,
    CreateChannelGroupReportRequest,
} from '@/store/models/dtos/channelGroupReport.dto';
import { IPaging } from '@inconvo/types/interfaces';
import {
    IChannelGroupReportList,
    IChannelReportRow,
} from '@/store/models/interfaces/channelGroupReport.interface';
import axios from '@/utils/request';

export class ChannelGroupReportClient {
    public static async getReportsList(
        options: GetChannelGroupReportListRequest,
    ): Promise<IPaging<IChannelGroupReportList>> {
        let url = `reports/channel-group?page=${options.page}&size=${options.size}`;

        if (options.q) {
            url = `${url}&q=${options.q}`;
        }

        const result = await axios.request({
            url,
            method: 'GET',
            errorMessage: 'Could not get report list',
            loadingId: LoadingFlag.ChannelGroupReportGet,
        });
        return result as unknown as IPaging<IChannelGroupReportList>;
    }

    public static async getReport(reportId: number) {
        const url = `reports/channel-group/${reportId}`;

        const result = await axios.request({
            url,
            method: 'GET',
            errorMessage: 'Could not get report',
            loadingId: LoadingFlag.ChannelGroupReportGetOne,
        });
        return result as unknown as IChannelGroupReportDto;
    }

    public static async getReportRowsByTags(
        body: GetChannelGroupReportRequest,
    ): Promise<IChannelReportRow[]> {
        const url = 'reports/group-tags';

        const result = await axios.request({
            url,
            data: body,
            method: 'POST',
            errorMessage: 'Could not load report rows for given tag config',
            loadingId: LoadingFlag.ChannelGroupGenerateReport,
        });
        return result as unknown as IChannelReportRow[];
    }

    public static async createReport(body: CreateChannelGroupReportRequest): Promise<void> {
        const url = 'reports/channel-group';
        await axios.request({
            url,
            data: body,
            method: 'POST',
            errorMessage: 'Could not create report',
            loadingId: LoadingFlag.ReportSave,
        });
    }

    public static async updateReport(report: ChannelGroupReport): Promise<void> {
        const url = `reports/channel-group/${report.id}`;
        await axios.request({
            url,
            data: report,
            method: 'PUT',
            errorMessage: 'Could not update report',
            loadingId: LoadingFlag.ReportSave,
        });
    }

    public static async updateReportById(id: number): Promise<void> {
        const url = `reports/channel-group/${id}`;
        await axios.request({
            url,
            method: 'PUT',
            errorMessage: 'Could not update report',
            loadingId: LoadingFlag.ReportSave,
        });
    }

    public static async deleteReport(id: number): Promise<void> {
        const url = `reports/channel-group/${id}`;
        await axios.request({
            url,
            method: 'DELETE',
            errorMessage: 'Could not delete report',
            loadingId: LoadingFlag.ReportSave,
        });
    }
}
