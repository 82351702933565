export interface IRatingOption {
    label: string;
}

export class RatingOption implements IRatingOption {
    label: string;
    constructor(label: string) {
        this.label = label;
    }
}
