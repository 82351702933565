type Observer<T> = (data: T) => void;

interface IObservable<T> {
    subscribe(Observer: Observer<T>): void;
    unsubscribe(Observer: Observer<T>): void;
    notify(data: T): void;
}

export class Observable<T> implements IObservable<T> {
    private observers: Observer<T>[] = [];

    public subscribe(observer: Observer<T>) {
        this.observers.push(observer);
    }

    public unsubscribe(observerToRemove: Observer<T>) {
        this.observers = this.observers.filter((observer) => observer !== observerToRemove);
    }

    public notify(data: T) {
        this.observers.forEach((item) => item(data));
    }
}
