var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"toggle-text"},[_c('div',{staticClass:"toggle-text__inner",style:({ '--font-size': (_vm.fontSize + "px") })},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerChecked),expression:"innerChecked"}],staticClass:"toggle-text__input",attrs:{"id":_vm.id,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.innerChecked)?_vm._i(_vm.innerChecked,null)>-1:(_vm.innerChecked)},on:{"change":function($event){var $$a=_vm.innerChecked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.innerChecked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.innerChecked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.innerChecked=$$c}}}}),_c('label',{staticClass:"toggle-text__label",class:{
                'disabled-toggle': _vm.disabled,
            },style:({
                '--toggle-height': (_vm.toggleHeight + "px"),
                '--toggle-width': (_vm.toggleWidth + "px"),
            }),attrs:{"for":_vm.id}},[_c('div',{staticClass:"toggle-text__switch",class:{
                    'has-icon': _vm.showIcons,
                }},[_c('div',{class:{
                        'switch-off': _vm.innerChecked,
                        'switch-on': !_vm.innerChecked,
                        'switch-content': !_vm.iconAside,
                        'switch-content-aside': _vm.iconAside,
                    }},[(_vm.showIcons && _vm.iconOff !== '')?_c('Icon',{staticClass:"switch-icon",attrs:{"size":_vm.iconSize,"name":_vm.iconOff}}):_vm._e(),_c('div',{staticClass:"switch-text",class:{ 'only-text': !_vm.showIcons }},[_vm._v(" "+_vm._s(_vm.textOff)+" ")])],1),_c('div',{class:{
                        'switch-on': _vm.innerChecked,
                        'switch-off': !_vm.innerChecked,
                        'switch-content': !_vm.iconAside,
                        'switch-content-aside': _vm.iconAside,
                    }},[(_vm.showIcons && _vm.iconOn !== '')?_c('Icon',{staticClass:"switch-icon",attrs:{"size":_vm.iconSize,"name":_vm.iconOn}}):_vm._e(),_c('div',{staticClass:"switch-text",class:{ 'only-text': !_vm.showIcons }},[_vm._v(" "+_vm._s(_vm.textOn)+" ")])],1)])])]),(_vm.errors && _vm.errors.length > 0)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.errors[0]))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }