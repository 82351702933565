import { Channel } from '.';

export class CategoryRequest {
    name: string;
    description: string;
    color: string;
    slug: string;
    image: string;
    channelList: Channel[];
    version: string;
}

export class MoveCategoryRequest {
    categoryId?: number;
    channelList: number[];
    originalCategory: number;
}
