<template>
    <div>
        <AutoComplete
            :id="name"
            ref="autocomplete"
            class="mb-2"
            :name="title"
            :label="label"
            :placeholder="`Search for a ${displayName.toLowerCase()}`"
            :items="items"
            :disabled-items="selectedItems"
            item-key="name"
            :is-async="true"
            :show-clear-icon="true"
            :open-on-focus="false"
            :is-disabled="!autocompleteEnabled"
            :errors="errors"
            :show-error-messages="showErrorMessages"
            :show-warning-messages="false"
            @query="$emit('query', $event)"
            @input="onInput"
        >
            <template v-slot:default="{ result }">
                <Badge
                    v-if="getBadge(result)"
                    :type="getBadge(result).type"
                    class="autocomplete-item-badged"
                >
                    {{ getBadge(result).label }}
                </Badge>
                {{ result.name }}
            </template>
        </AutoComplete>
        <div v-if="!selectedItems.length && selectPlaceholder" class="m-1 text-sm opacity-50">
            {{ selectPlaceholder }}
        </div>
        <ol v-if="selectedItems.length" class="text-xs autocomplete-list-items m-1">
            <li
                v-for="item in selectedItems"
                :key="item.id"
                class="flex items-center autocomplete-selected-item"
                data-cy="selected-item"
            >
                <Badge
                    v-if="getBadge(item)"
                    :type="getBadge(item).type"
                    class="autocomplete-item-badged"
                >
                    {{ getBadge(item).label }}
                </Badge>
                {{ item.name }}

                <span class="flex-shrink-0 ml-auto pl-2 flex items-center">
                    <slot name="selected-right" :item="item"> </slot>
                    <IconButton
                        v-tooltip.right="'Delete'"
                        class="autocomplete-list-button-delete"
                        icon="cross-simple"
                        data-cy="selected-item-remove"
                        @click="updateSelected(item)"
                    />
                </span>
            </li>
        </ol>
    </div>
</template>

<script>
import AutoComplete from '@/components/forms/AutoComplete';
import Badge from '@/components/ui/Badge.vue';

import IconButton from '@/components/ui/IconButton';
export default {
    name: 'AutoCompleteList',
    components: {
        AutoComplete,
        IconButton,
        Badge,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            default: () => [],
        },
        keyRef: {
            type: String,
            default: 'id',
        },
        selectPlaceholder: {
            type: String,
            default: undefined,
        },
        selectedItems: {
            type: Array,
            default: () => [],
        },
        maxOptions: {
            type: Number,
            default: 5,
        },
        badgeKey: {
            type: String,
            default: 'type',
        },
        getBadge: {
            type: Function,
            default(item) {
                // expected return
                // return {
                //   label: 'badge label'
                //   type:  enums BadgeType
                // }
                return null;
            },
        },
        showErrorMessages: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        displayName() {
            return this.title || this.label || this.name;
        },
        autocompleteEnabled() {
            return this.selectedItems.length < this.maxOptions;
        },
    },

    methods: {
        isActiveItem(item) {
            return !!this.selectedItems.find(
                (selected) => selected[this.keyRef] === item[this.keyRef],
            );
        },
        onInput(item) {
            this.updateSelected(item);
            this.$nextTick(() => {
                this.$refs.autocomplete.onClear();
            });
        },
        updateSelected(item) {
            const isAlreadySelected = this.isActiveItem(item);

            if (isAlreadySelected) {
                const selectedOptions = this.selectedItems.filter(
                    (selected) => selected[this.keyRef] !== item[this.keyRef],
                );
                this.$emit('update', selectedOptions);
            } else {
                this.$emit('update', [...this.selectedItems, item]);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.autocomplete-list-button-delete {
    font-size: $font-size-xxs;
    color: #aeb5c8;
}
.autocomplete-item-badged {
    margin-right: 0.5rem;
}

.autocomplete-selected-item {
    max-width: 500px;
    border-radius: 4px;
    padding: 0.3rem 0.6rem;
    background-color: #f3f4f7;
}
</style>
<style scoped>
.autocomplete-list-items {
    max-width: clamp(60%, 850px, 100%);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 0.6rem;
}
</style>
