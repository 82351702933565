<template>
    <div
        class="image-uploader-view"
        :class="{ embedded, dragging }"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @dragover.prevent
        @drop="onDrop"
    >
        <div v-if="dragging" class="dragzone">Drag image or URL here</div>

        <UnderlinedTitle type="h1">Add an Image</UnderlinedTitle>
        <p class="paragraph">Search, browse or upload an image</p>

        <Tabs :active-tab="activeTab" :disabled="isCropping" @onChange="activeTab = $event">
            <Tab name="Image URL">
                <div class="tab-content">
                    <div class="search-form">
                        <div class="image-field-wrapper">
                            <Input
                                id="image-text"
                                ref="imageInput"
                                v-model="imageUrl"
                                type="text"
                                name="name"
                                label="Image URL"
                                placeholder="Type or paste an image URL here"
                                :disabled="isCropping || $wait.is(LoadingFlag.Uploading)"
                                :show-remaining-char="false"
                                :hide-label="true"
                                :show-clear-icon="true"
                                @input="onImageUrlInput"
                                @onClear="onClearImageUrl"
                            />
                        </div>
                    </div>
                    <div v-if="urlUploadError" class="form-error error-message">
                        {{ urlUploadError }}
                    </div>
                </div>
            </Tab>
            <Tab name="Image Upload">
                <div class="tab-content">
                    <FileUpload
                        show-clear-icon
                        :error="fileUploadError"
                        :drag-enabled="false"
                        :file-name="selectedFileName"
                        :disabled="$wait.is(LoadingFlag.Uploading) || isCropping"
                        @changed="onFileChanged"
                        @onClearImage="onClearImageFile"
                    />
                </div>
            </Tab>
        </Tabs>
        <div v-if="imgSrc" class="crop-image">
            <vue-cropper
                ref="cropper"
                alt="Source Image"
                drag-mode="crop"
                :guides="true"
                :view-mode="2"
                :background="true"
                :rotatable="true"
                :src="imgSrc"
                :aspect-ratio="aspectRatio"
                :check-orientation="false"
                :ready="cropperOnReady"
            ></vue-cropper>
        </div>
        <Stepper
            show-cancel-button
            show-add-button
            :is-loading="$wait.is(LoadingFlag.Uploading)"
            :add-button-is-disabled="addButtonIsDisabled || $wait.is(LoadingFlag.Uploading)"
            @addButtonOnClick="uploadCroppedImage($event)"
            @cancelButtonOnClick="$emit('cancelButtonOnClick', $event)"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import FileUpload from '@/components/ui/FileUpload';
import UnderlinedTitle from '@/components/ui/UnderlinedTitle';
import Tabs from '@/components/ui/Tabs';
import Tab from '@/components/ui/Tab';
import Input from '@/components/forms/Input';
import Stepper from '@/components/ui/Stepper';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import VueCropper from 'vue-cropperjs';

export default {
    name: 'ImageUploadOverlay',
    components: {
        FileUpload,
        UnderlinedTitle,
        Tabs,
        Tab,
        Input,
        Stepper,
        VueCropper,
    },
    props: {
        embedded: {
            type: Boolean,
            default: false,
        },
        aspectRatio: {
            type: Number,
            default: null,
        },
        imageNamePrefix: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            LoadingFlag,
            imageUrl: '',
            imgSrc: '',
            imgKey: '',
            isCropping: false,
            cropImg: '',
            addButtonIsDisabled: true,
            urlUploadError: '',
            fileUploadError: '',
            selectedFileName: '',
            imageFile: {},
            dragging: false,
            dragEnabled: true,
            activeTab: 0,
        };
    },
    methods: {
        ...mapActions('main', {
            uploadImage: 'uploadImage',
            uploadImageFromUrl: 'uploadImageFromUrl',
        }),
        toDataURL(url) {
            return fetch(url)
                .then((response) => {
                    return response.blob();
                })
                .then((blob) => {
                    return URL.createObjectURL(blob);
                });
        },
        async onImageUrlInput() {
            const urlRegex =
                /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

            if (this.imageUrl.length > 0) {
                if (this.imageUrl.match(urlRegex)) {
                    const data = {
                        url: this.imageUrl,
                        prefix: this.imageNamePrefix,
                    };

                    try {
                        const response = await this.uploadImageFromUrl(data);

                        if (response) {
                            this.imgSrc = `${response.url}?noOptimize`;
                            this.imgKey = response.key;

                            this.isCropping = true;
                            this.dragEnabled = false;
                            this.urlUploadError = '';
                        } else {
                            this.urlUploadError = 'Invalid image URL';
                        }
                    } catch (error) {
                        this.urlUploadError = 'Could not upload file';
                    }
                } else {
                    this.addButtonIsDisabled = true;
                    this.urlUploadError = 'Invalid url';
                }
            } else {
                this.addButtonIsDisabled = true;
                this.urlUploadError = '';
            }
        },
        async onFileChanged(imageFile) {
            this.imageFile = imageFile;

            const formData = new FormData();
            formData.append('files', this.imageFile);

            const response = await this.uploadImage({ formData: formData });

            if (response) {
                this.imgSrc = `${response.url}?noOptimize`;
                this.imgKey = response.key;
                this.isCropping = true;
                this.dragEnabled = false;
                this.fileUploadError = '';
            } else {
                this.fileUploadError = 'There was an error uploading the file';
            }
        },
        uploadCroppedImage(e) {
            const croppedCanvas = this.$refs.cropper.getCroppedCanvas();

            try {
                this.cropImg = croppedCanvas.toDataURL();

                const blob = this.dataUrlToBlob(this.cropImg);

                const formData = new FormData();
                formData.append('files', blob, this.imgSrc);
                formData.append('objectKey', this.imgKey);
                this.upload(formData);
            } catch (error) {
                this.imgSrc = '';
                this.addButtonIsDisabled = true;
                this.urlUploadError = 'Must be a valid image file';
            }
        },
        dataUrlToBlob(dataUrl) {
            const byteString = atob(dataUrl.split(',')[1]);

            const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];

            const ab = new ArrayBuffer(byteString.length);
            const dw = new DataView(ab);

            for (let i = 0; i < byteString.length; i++) {
                dw.setUint8(i, byteString.charCodeAt(i));
            }

            return new Blob([ab], { type: mimeString });
        },
        async upload(formData) {
            try {
                const response = await this.uploadImage({ formData: formData });
                this.$emit('imageUploaded', response);
            } catch (error) {
                Vue.$log.error('renew', error);
            }
        },
        onClearImageUrl() {
            this.addButtonIsDisabled = true;
            this.imgSrc = '';
            this.imageUrl = '';
            this.isCropping = false;
            this.dragEnabled = true;
            this.urlUploadError = '';
        },
        onClearImageFile() {
            this.addButtonIsDisabled = true;
            this.imgSrc = '';
            this.imageUrl = '';
            this.isCropping = false;
            this.dragEnabled = true;
            this.fileUploadError = '';
        },
        onDragEnter(e) {
            e.preventDefault();
            if (this.dragEnabled) {
                this.dragging = true;
            }
        },
        onDragLeave(e) {
            e.preventDefault();
            if (this.dragEnabled) {
                this.dragging = false;
            }
        },
        onDrop(e) {
            e.preventDefault();
            e.stopPropagation();

            if (this.dragEnabled) {
                const url = e.dataTransfer.getData('URL');
                if (url) {
                    this.activeTab = 'Search';
                    this.imageUrl = url;
                } else if (e.dataTransfer.files && e.dataTransfer.files.length) {
                    this.activeTab = 'Upload';
                    this.selectedFileName = e.dataTransfer.files[0].name;
                    this.onFileChanged(e.dataTransfer.files[0]);
                }

                this.dragging = false;
            }
        },
        cropperOnReady() {
            this.addButtonIsDisabled = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.image-uploader-view {
    width: 100%;
    height: 100%;

    &.dragging {
        * {
            pointer-events: none;
        }
    }

    .dragzone {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: $layer-200;
        border: 5px dashed $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary-color;
        font-size: $font-size-base + 20;
        @include alpha-background-color($default-light-color, 0.5);
    }

    &.embedded {
        max-width: 1400px;
        margin: 0 auto;
        padding: 60px #{$page-padding-on-small}px 130px;

        @include media-query(medium) {
            padding: 60px 115px 130px;
        }

        .stepper {
            bottom: 0;
        }
    }
}

.tab-content {
    position: relative;
    padding-top: 32px;

    .input-wrapper {
        &.input {
            height: 20px;
        }
    }
}

.image-field-wrapper {
    flex: 1 1 auto;
    position: relative;
}

.search-form {
    display: flex;
    @include media-query(only-small) {
        display: inline;
    }
}

.search {
    margin-left: 10px;
    @include media-query(only-small) {
        margin-left: 0;
        margin-bottom: 10px;
    }
}

.crop-image {
    width: 75%;
    position: relative;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: $stepper-height-on-large + 30px;
}

.cross-icon {
    position: absolute;
    top: 13px;
    right: 7px;
    color: $input-clear-icon-color;
}

.form-error {
    margin-top: -15px;
    font-size: 12px;
    font-style: italic;
    color: $error-color;
    @include media-query(only-small) {
        margin-top: -79px;
    }
}
</style>

<style lang="scss">
.input#image-text {
    height: 38px;
    width: 75%;
    padding: 0px 20px 3px 7px;
}

.input#image-text::placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 30px;
    font-family: $font-family-default;
    font-size: 15px;
    font-weight: $font-weight-light;
    color: $alternate-text-color;
}

.tab-content {
    .input-wrapper--has-error {
        .error-message {
            top: 38px;
            left: 0px;
        }
    }
}

.image-upload-sucess {
    .file-upload {
        &__filename {
            position: absolute;
            top: -2px;
        }
        .file-upload-input-icon {
            display: none;
        }
    }
}

.image-upload-error {
    .file-upload {
        &__filename {
            position: absolute;
            top: -2px;
        }
        .file-upload-input-icon {
            display: none;
        }
        .error-info {
            color: $error-color;
        }
    }
}
</style>
