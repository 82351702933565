export const UPDATE_MENU_PROPERTY = 'updateProperty';
export const UPDATE_MENU_ITEM_PROPERTY = 'updateMenuItemProperty';
export const ADD_MENU_ITEM = 'addMenuItem';
export const SET_MENU_ITEM = 'setMenuItem';
export const DELETE_MENU_ITEM = 'deleteMenuItem';
export const SET_MENU_LIST = 'setMenuList';
export const SET_MENU = 'setMenu';
export const SET_MENU_ITEMS = 'setMenuItems';
export const SET_ERRORS = 'setErrors';
export const SET_VERSION_HASH = 'setVersionHash';
