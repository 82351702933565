import { IContentItem } from '../interfaces';
import { TextStatement, ContentItem } from '..';
import { ContentItemType } from '@/store/enums/';
import { IAnswer } from '../interfaces/answer.interface.dto';
import { IWidgetReply } from '../interfaces/widgetReply.interface.dto';
import { Answer } from '../answer.dto';
import { IConvoState } from '@/store/modules/convo-details';

export class TextStatementContentItemFactory {
    public make(obj: TextStatement, state: IConvoState): IContentItem {
        const contentItem = new ContentItem();
        contentItem.id = obj.id;
        contentItem.sort = obj.sort;
        contentItem.title = obj.statement;
        contentItem.type = ContentItemType.Statement;

        contentItem.answers = [];

        contentItem.tags = obj.tags || [];
        contentItem.isPdl = obj.isPdl;

        const answer: IAnswer = new Answer();
        answer.convoId = state.convo.id;

        answer.targetChannel = obj.logicJump?.items?.channel?.code || '';
        answer.targetConvo = obj.logicJump?.items?.convo?.id || 0;
        answer.targetContentItem = obj.logicJump?.items?.message?.id || 0;
        answer.goToBlock = !answer.targetContentItem && answer.targetConvo ? answer.targetConvo : 0;
        answer.goToWidget =
            answer.targetConvo && answer.targetContentItem ? answer.targetContentItem : 0;

        contentItem.answers.push(answer);

        contentItem.reply = {} as IWidgetReply;
        contentItem.reply.activated = false;
        contentItem.reply.message = '';
        contentItem.reply.tagLine = '';
        contentItem.reply.url = '';
        contentItem.reply.imageUrl = '';

        return contentItem;
    }
}
