<template>
    <div>
        <Select
            v-model="type"
            class="cel-select"
            label="Convo End Logic"
            placeholder="Select option"
            :options="$options.endLogicOptions"
            :has-margin-bottom="false"
        />

        <div v-if="showLogicJump" class="cel-logic-jump">
            <Icon name="hierarchy" class="cel-hierarchy-icon" />

            <AttributeButton
                class=""
                icon="logic-jump"
                :show-remove-button="false"
                @click="showContentSelector = true"
            >
                Logic jump
            </AttributeButton>
        </div>

        <ContentSelector
            v-if="showContentSelector"
            v-model="content"
            title="Logic Jump"
            list-header-text="Jump to..."
            :show-content-id="true"
            @close="showContentSelector = false"
        />
    </div>
</template>

<script>
import { ConvoEndLogicType } from '@/store/enums/convoEndLogicType.enum';
import AttributeButton from '@/components/ui/AttributeButton';
import ContentSelector from '@/components/ContentSelector/ContentSelector';
import { Select } from '@/components/forms';
import Icon from '@/components/ui/Icon';

const END_LOGIC_OPTIONS = [
    { id: ConvoEndLogicType.None, value: 'Do Nothing' },
    { id: ConvoEndLogicType.DiscoveryMenu, value: 'Serve Discovery Menu' },
    { id: ConvoEndLogicType.LogicJump, value: 'Logic Jump' },
];

export default {
    name: 'ConvoEndLogic',
    components: {
        AttributeButton,
        ContentSelector,
        Select,
        Icon,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        showContentSelector: false,
    }),
    computed: {
        type: {
            get() {
                return this.value.type;
            },
            set(type) {
                this.handleInput(this.value.setType(type));
            },
        },
        content: {
            get() {
                return this.value.content;
            },
            set(content) {
                this.handleInput(this.value.setContent(content));
            },
        },
        showLogicJump() {
            return this.value.type === ConvoEndLogicType.LogicJump;
        },
    },
    created() {
        this.$options.endLogicOptions = END_LOGIC_OPTIONS;
    },
    methods: {
        handleInput(data) {
            this.$emit('input', data);
        },
    },
};
</script>

<style scoped lang="scss">
.cel-select {
    margin-bottom: 10px;
}
.cel-logic-jump {
    display: flex;
    align-items: center;
}
.cel-hierarchy-icon {
    margin: 0 8px;
}
</style>
