<template>
    <div
        class="form-info-box-wrapper m-b-2"
        :class="{ 'form-info-box-wrapper--has-error': errors.length > 0 }"
    >
        <slot />

        <div class="form-info-box">
            <p class="m-b-1">{{ message }}</p>

            <TextButton
                class="form-info-box-button"
                button-style="secondary"
                @click="$emit('click')"
            >
                {{ buttonText }}
            </TextButton>
        </div>

        <ErrorMessage
            v-if="errors && errors.length > 0"
            class="form-info-box-error"
            :message="errors[0]"
        />
        <WarningMessage
            v-if="warnings && warnings.length > 0"
            class="form-info-box-warning"
            :message="warnings[0]"
        ></WarningMessage>
    </div>
</template>

<script>
import TextButton from '@/components/ui/TextButton';
import ErrorMessage from '@/components/forms/partials/ErrorMessage';
import WarningMessage from '@/components/forms/partials/WarningMessage';

export default {
    name: 'FormInfoBox',
    components: {
        TextButton,
        ErrorMessage,
        WarningMessage,
    },
    props: {
        buttonText: {
            type: String,
            default: 'Edit',
        },
        message: {
            type: String,
            default: 'Info',
        },
        errors: {
            type: Array,
            default: () => [],
        },
        warnings: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss">
.form-info-box-wrapper--has-error {
    .form-field {
        border-color: $color-error;
    }
    .form-field-label {
        color: $color-error;
    }
}
</style>

<style scoped lang="scss">
.form-info-box {
    margin-top: -3px;
    padding: 13px 20px 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 1px solid;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: $input-border-color;
    background-color: $color-near-white;
    font-size: $font-size-xs;
    color: $color-regent-gray;

    .form-info-box-wrapper--has-error & {
        border-color: $color-error;
    }
}

.form-info-box-button {
    margin: 0 auto;
}

.form-info-box-error {
    margin-top: 5px;
}
.form-info-box-warning {
    margin-top: 5px;
    font-size: $font-size-xs;
}
</style>
