var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$options.richTextMessagesEnabled && _vm.type === 'textarea')?_c('EditorInput',{attrs:{"id":_vm.id,"name":_vm.name || _vm.id,"label":_vm.label,"show-remaining-char":_vm.showRemainingChar,"content":_vm.value,"readonly":_vm.isPdl,"max":_vm.max,"menu-items":[
        _vm.$options.EditorInputMenuItem.Bold,
        _vm.$options.EditorInputMenuItem.Italic,
        _vm.$options.EditorInputMenuItem.Strike,
        _vm.$options.EditorInputMenuItem.Underline ],"errors":_vm.errors},on:{"update":function($event){return _vm.$emit('input', $event)},"plainTextChange":function($event){return _vm.$emit('plainText', $event)}}}):_c('Input',{attrs:{"id":_vm.id,"label":_vm.label,"name":_vm.name || _vm.id,"type":_vm.type,"max":_vm.max,"show-remaining-char":_vm.showRemainingChar,"has-margin-bottom":false,"readonly":_vm.isPdl,"value":_vm.value,"errors":_vm.errors},on:{"input":function (value) {
            _vm.$emit('input', value);
            _vm.$emit('plainText', value);
        }}})}
var staticRenderFns = []

export { render, staticRenderFns }