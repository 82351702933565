<template>
    <ModalBaseAuth>
        <form @submit.prevent="onSubmit">
            <template v-if="showSocialLogin">
                <div class="text-center">
                    <SocialButton class="mt-2" button-style="yougov" @click="$emit('signInYouGov')">
                        Login with YouGov
                    </SocialButton>
                </div>

                <HorizontalSeparator />
            </template>

            <Input
                id="email"
                v-model="formData.email"
                name="email"
                :label="'Email'"
                type="email"
                class="mt-2"
                placeholder="Enter your email"
                rules="required"
                :disabled="false"
                :errors="errors"
            />

            <Button
                id="sign-in-modal-submit-button"
                type="submit"
                class="m-t-2"
                has-border
                :disabled="disableSubmit"
                :full-width="true"
                :is-loading="loading"
            >
                {{ submitButtonText }}
            </Button>
        </form>
    </ModalBaseAuth>
</template>

<script>
import ModalBaseAuth from '@/components/Modal/ModalBaseAuth';
import HorizontalSeparator from '@/components/ui/HorizontalSeparator';
import { LoadingFlag } from '@/store/enums/loadingIds.enum';
import { Input } from '@/components/forms';
import Button from '@/components/ui/Button';
import SocialButton from '@/components/ui/SocialButton';
import { isEmail } from 'class-validator';

export default {
    name: 'ModalSignIn',
    components: {
        Input,
        Button,
        ModalBaseAuth,
        SocialButton,
        HorizontalSeparator,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        showSocialLogin: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            formData: {
                email: '',
            },
            errors: [],
            LoadingFlag,
        };
    },
    computed: {
        submitButtonText() {
            return this.loading ? 'Logging in' : 'Login';
        },
        disableSubmit() {
            return this.formData.email.length === 0 || !this.isValid;
        },
        isValid() {
            return this.errors.length === 0;
        },
    },
    watch: {
        formData: {
            deep: true,
            handler() {
                this.validate();
            },
        },
    },
    methods: {
        validate() {
            this.errors = [];
            if (!isEmail(this.formData.email)) {
                this.errors.push('Please enter valid email address');
            }
        },
        onSubmit() {
            if (this.isValid) {
                this.$emit('signInEmail', this.formData.email);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-error {
    font-family: $font-family-default;
    font-size: 14px;
    color: $error-color;
    text-align: center;
}
</style>
