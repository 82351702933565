export const ADD_AUDIENCE_RULE = 'addAudienceRule';
export const DELETE_AUDIENCE_RULE = 'deleteAudienceRule';
export const UPDATE_AUDIENCE_RULE = 'updateAudienceRule';
export const SET_ACTIVE_RULE_ID = 'setActiveRuleId';
export const SET_SUMMARY_ESTIMATE = 'setSummaryEstimate';
export const SET_SUMMARY_OBJECT = 'setSummaryObject';
export const SET_SUMMARY_LOADING = 'setSummaryLoading';
export const SET_AUDIENCE_RULES = 'setAudienceRules';
export const SET_AUDIENCE_RULE = 'setAudienceRule';
export const SET_DEFAULT_AUDIENCE_STATE = 'setDefaultAudienceState';
export const SET_AUDIENCE = 'setAudience';
export const SET_AUDIENCES = 'setAudiences';
export const SET_AUDIENCE_ERRORS = 'setAudienceErrors';
export const SET_MESSAGE_RESPONSES = 'setMessageResponses';
export const ADD_RESPONSE_TO_SELECTION = 'addResponseToSelection';
export const REMOVE_RESPONSE_FROM_SELECTION = 'removeResponseFromSelection';
export const SET_AUDIENCE_HASH = 'setAudienceHash';
export const SET_EXPORT_OPTIONS = 'setExportOptions';
