var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$options.richTextMessagesEnabled)?_c('EditorInput',{staticClass:"m-b-2",attrs:{"id":_vm.generateId('text', _vm.path),"label":"Message","show-remaining-char":"","content":_vm.data.text,"errors":_vm.data.errors.plainText,"max":640,"menu-items":[
            _vm.$options.EditorInputMenuItem.Bold,
            _vm.$options.EditorInputMenuItem.Italic,
            _vm.$options.EditorInputMenuItem.Strike,
            _vm.$options.EditorInputMenuItem.Underline ]},on:{"update":function($event){return _vm.onInput('text', $event)},"plainTextChange":function($event){return _vm.onInput('plainText', $event)}}}):_c('Input',{staticClass:"m-b-2",attrs:{"id":_vm.generateId('text', _vm.path),"type":"textarea","label":"Message","name":"text","max":640,"show-remaining-char":"","value":_vm.data.text,"errors":_vm.data.errors && _vm.data.errors.input},on:{"input":function (value) {
                _vm.onInput('text', value);
                _vm.onInput('plainText', value);
            },"changed":_vm.onChanged}}),_c('EditorInput',{staticClass:"m-b-2",attrs:{"id":_vm.generateId('commenting-on', _vm.path),"label":"Commenting On Description","show-remaining-char":"","content":_vm.data.commentingOnText,"errors":_vm.data.errors.commentingOnText,"max":640,"menu-items":[
            _vm.$options.EditorInputMenuItem.Bold,
            _vm.$options.EditorInputMenuItem.Italic,
            _vm.$options.EditorInputMenuItem.Strike,
            _vm.$options.EditorInputMenuItem.Underline ]},on:{"update":function($event){return _vm.onInput('commentingOnText', $event)}}}),(_vm.data.items)?_c('AccordionNew',{attrs:{"items":_vm.data.items,"is-draggable":false,"path":_vm.path.concat( [0])},on:{"onDrop":function (path, value) { return _vm.$emit('onDrop', path, value); }}},_vm._l((_vm.data.items),function(item,index){return _c('CommentsRatingCard',{key:index,attrs:{"data":item,"logic-jump-data":_vm.contentItems,"text":_vm.targetText(item),"path":_vm.path.concat( [index]),"index":index,"is-draggable":false,"is-deletable":_vm.data.isDeletable,"marker":_vm.getMarker(item.marker, item.markerType, 1, index),"errors":item.errors},on:{"propertyOnChange":function($event){return _vm.$emit('propertyOnChange', $event)},"deleteOnClick":function (event, path, parentPath) { return _vm.$emit('deleteOnClick', event, path, parentPath); },"openContentSelector":_vm.onOpenContentSelector,"closeContentSelectorx":function($event){_vm.showContentSelector = false}}})}),1):_vm._e(),_c('TextButton',{staticClass:"comments-rating-add-button",attrs:{"button-style":"primary","size":"small"},on:{"click":_vm.addTargetMessageButtonOnClick}},[_vm._v(" + Add target message ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }