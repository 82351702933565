import { ICategory } from './interfaces/category.interface';
import { IValidatable } from './interfaces/validatable.interface';
import { IObservableItem } from './interfaces/observableItem.interface';
import { ICategoryState } from '../modules/category';
import { IsNotEmpty, validate as validator } from 'class-validator';
import { getErrorMessage } from '../services/validationService';
import { CategoryRequestFactory } from './factories/categoryRequest.factory';
import hash from 'object-hash';

export class Category
    implements ICategory, IValidatable<ICategory>, IObservableItem<ICategoryState>
{
    constructor() {
        this.id = 0;
        this.name = '';
        this.description = '';
        this.color = '';
        this.slug = '';
        this.image = '';
        this.channelList = [];
    }

    id: number;
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Name') })
    name: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Description') })
    description: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Color') })
    color: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Slug') })
    slug: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Image') })
    image: string;

    channelList: any[];

    originalVersion: string;

    itemHasChanges(state: ICategoryState) {
        const categoryRequestFactory = new CategoryRequestFactory();
        const categoryRequest = categoryRequestFactory.make(this);

        categoryRequest.version = hash(categoryRequest);

        if (this.originalVersion) {
            return categoryRequest.version !== this.originalVersion;
        }
        return true;
    }

    async validate() {
        const errors = await validator(this);

        return errors.reduce((acc, cur) => {
            acc[cur.property] = Object.values(cur.constraints || {});
            return acc;
        }, <any>{});
    }
}
