export class Broadcast {
    id: number;
    createdAt: string;
    status: string;
    updatedAt: string;
    segmentId: string;
    segmentFile: string;
    appId: string;
    campaignId: string;
    convoId: number;
    convoName?: string;
    convoStarts: number;
    channelCode: string;
    segmentReach: number;
    subId: string;
    audienceId: number;
    isScheduled: number;
    type: string;
    triggerMetadata: string;
}
