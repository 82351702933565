<template>
    <div class="search">
        <Input
            id="search"
            class="search-input"
            name="query"
            :placeholder="placeholder"
            type="text"
            :label="label"
            autocomplete="off"
            :has-margin-bottom="false"
        />

        <Button has-border size="small" type="submit" :disabled="isLoading"> Search </Button>
    </div>
</template>

<script>
import Button from '@/components/ui/Button';
import { Input } from '@/components/forms';

export default {
    name: 'Search',
    components: {
        Button,
        Input,
    },
    props: {
        label: {
            type: String,
            default: 'Search',
        },
        placeholder: {
            type: String,
            default: 'Enter keyword to search',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<style lang="scss" scoped>
.search {
    display: flex;
    align-items: center;
}

.search-input {
    flex: 1;
    margin-right: 1rem;
}
</style>
