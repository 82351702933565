import { AudienceRuleSetFactory } from '@/store/models/factories/audienceRuleSet.factory';
import { IRuleSet, IRule, RuleMap } from '@/store/models/interfaces/audience.interface';
import { AudienceRuleFactory } from '@/store/models/factories/audienceRule.factory';
import { RuleDto, RuleSetDto } from '@/store/models/dtos/audience.dto';

export const buildRuleMap = (list: (RuleDto | RuleSetDto)[]) => {
    return list.reduce((obj, item) => {
        obj[item.id] =
            item.ruleType === 'rule'
                ? AudienceRuleFactory.make(item as RuleDto)
                : AudienceRuleSetFactory.make(item as RuleSetDto);
        return obj;
    }, <RuleMap>{});
};

export const buildRuleTree = (list: (IRuleSet | IRule)[]) => {
    if (!list.length) {
        return {};
    }
    const newList: any[] = [];
    const map = <any>{};
    const roots = [];
    let node;
    let i;
    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i;
        newList[i] = { id: list[i].id, parentId: list[i].parentId };
        newList[i].children = [];
    }
    for (i = 0; i < newList.length; i += 1) {
        node = newList[i];
        if (node.parentId !== null) {
            newList[map[node.parentId]].children.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots[0];
};
