import filesize from 'filesize';

interface IErrorMessages {
    [key: string]: Function;
}

const errorMessages: IErrorMessages = {
    isUrl: ({ name }: { name: string }) => `${name} must be a valid URL address.`,
    arrayNotEmpty: ({ name }: { name: string }) => `At least 1 ${name} is required.`,
    isNotEmpty: ({ name }: { name: string }) => `${name} is required.`,
    max: ({ name, max }: { name: string; max: number }) => `${name} cannot be greater than ${max}.`,
    logicJumpContent: ({ name }: { name: string }) => `A ${name} must be selected.`,
    length: ({ name, min, max }: { name: string; min: number; max: number }) =>
        `Character limit reached. ${name} exceeds ${max} characters.`,
    selectOption: ({ name }: { name: string }) => `A ${name} should be selected.`,
    buttonsLength: ({ name, min, max }: { name: string; min: number; max: number }) =>
        `${name} should have a minimum of ${min} button${
            min > 1 ? 's' : ''
        } and a maximum of ${max} buttons.`,
    tilesLength: ({ name, min, max }: { name: string; min: number; max: number }) =>
        `${name} should have a minimum of ${min} tile${
            min > 1 ? 's' : ''
        } and a maximum of ${max} tiles.`,
    triviaQuizCorrectAnswer: () => `Must include a minimum of one correct answer.`,
    emptyAttachment: ({ name }: { name: string }) => `Must include an ${name}.`,
    selectMessageType: () => `Select a message type or delete this message.`,
    noMessageItems: () => `Must include a minimum of one message.`,
    noResultItems: () => `Must include a minimum of one result.`,
    fileExtension: ({ name, extensions }: { name: string; extensions: string[] }) =>
        `Incorrect file type. Please upload a supported file format: ${extensions.join(', ')}.`,
    fileSize: ({ fileSize, maxFileSize }: { fileSize: number; maxFileSize: number }) =>
        `File size is too large (${filesize(fileSize, {
            exponent: 2,
        })}). Maximum file size: ${filesize(maxFileSize, {
            exponent: 2,
        })}.`,
    publish: () => `Select to publish or unpublish.`,
    isNotEmptyConditional: ({ name, condition }: { name: string; condition: string }) =>
        `${name} is required when ${condition} is selected.`,
    resultOptionTemplate: () => `Cannot contain result option and template at the same time.`,
    pdlConvoOnePostback: () =>
        `A PDL Convo must have a single postback message (e.g. Buttons, QuickReply)`,
    commentsRatingTarget: () => 'Message must be set for this comments rating target',
    primaryCategoryIsRequired: () => 'One category should be marked as "primary"',
    answerRatingCannotBeRepeated: () => 'Cannot have repeated ratings',
};

export default errorMessages;
