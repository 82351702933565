import { validate, IsNotEmpty, Length } from 'class-validator';
import hash from 'object-hash';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum';
import { AccordionItemBase } from './accordionItemBase';
import { IContentItem, IContentItemConvertible, ITag, IRichText } from './interfaces';
import { TextStatementContentItemFactory } from './factories/textStatementContentItem.factory';
import { TextStatementAccordionItemFactory } from './factories/textStatementAccordianItem.factory';
import { IConvoState } from '../modules/convo-details';
import { IObservableItem } from './interfaces/observableItem.interface';
import { getErrorMessage } from '@/store/services/validationService';

export class TextStatement
    extends AccordionItemBase
    implements IContentItemConvertible, IObservableItem<IConvoState>, IRichText
{
    constructor(items = []) {
        super(items);
        this.type = ConvoMessageItemType.TextStatement;
        this.icon = 'statement-text';
        this.statement = '';
        this.plainText = '';
        this.isDraggable = true;
        this.isDeletable = true;
        this.tags = [];
    }

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Message') })
    @Length(0, 640, { message: getErrorMessage('length', 'Message', { min: 0, max: 640 }) })
    plainText: string;
    statement: string;
    tags?: ITag[];

    setTitle(): void {
        this.subtitle = this.plainText;
    }

    itemHasChanges(state: IConvoState): boolean {
        const contentItemFactory = new TextStatementContentItemFactory();
        const contentItem = contentItemFactory.make(this, state);

        contentItem.version = hash(contentItem);

        if (this.originalVersion) {
            return contentItem.version !== this.originalVersion;
        }
        return false;
    }

    toContentItem(state: IConvoState): IContentItem {
        const contentItemFactory = new TextStatementContentItemFactory();
        return contentItemFactory.make(this, state);
    }

    toAccordionItem(contentItem: IContentItem, isClone?: boolean): AccordionItemBase {
        const accordionItemFactory = new TextStatementAccordionItemFactory();
        return accordionItemFactory.make(contentItem);
    }

    async validate(state: IConvoState, path: number[]): Promise<any[]> {
        const errors = await validate(this);
        const errorMessages: any[] = [];

        if (errors.length > 0) {
            errors.forEach((item) => {
                errorMessages.push({
                    path,
                    errors: { [item.property]: Object.values(item.constraints || {}) },
                });
            });
        }

        return errorMessages;
    }
}
