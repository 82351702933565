import { ButtonAnswer, IButtonAnswer } from './buttonAnswer';

export interface ICarouselMessage {
    title: string;
    subtitle: string;
    link: string;
    imageUrl: string;
    answers: IButtonAnswer[];
}

export class CarouselMessage implements ICarouselMessage {
    title: string;
    subtitle: string;
    link: string;
    imageUrl: string;
    answers: IButtonAnswer[] = [];

    constructor(
        title: string,
        subtitle: string,
        link: string,
        imageUrl: string,
        answers: IButtonAnswer[],
    ) {
        this.title = title;
        this.subtitle = subtitle;
        this.link = link;
        this.imageUrl = imageUrl;
        this.setAnswers(answers);
    }

    private setAnswers(answers: IButtonAnswer[]) {
        answers.forEach((answer) => {
            this.answers.push(new ButtonAnswer(answer.label));
        });
    }
}
