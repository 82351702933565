import { ContentDiscoveryMenuItemType as MenuType } from '@/enums';
import { chatGroupOptions, convoTypeOptions } from '@/json';
import { ContentType } from '@/store/enums';
import { formOptionMapper } from '@/utils/formOption';
import { ChannelMenu } from '../contentDiscoveryMenu/channelMenu';
import { ContentDiscoveryMenu } from '../contentDiscoveryMenu/contentDiscoveryMenu';
import { ConvoMenu } from '../contentDiscoveryMenu/convoMenu';
import { ConvoStreamMenu } from '../contentDiscoveryMenu/convoStreamMenu';
import { CustomMenu } from '../contentDiscoveryMenu/customMenu';
import { MenuBase } from '../contentDiscoveryMenu/menuBase';
import {
    IContentDiscoveryMenuDto,
    IContentDiscoveryRuleSetDto,
    IConvoStreamMenuDto,
    ICustomMenuDto,
} from '../dtos';
import { LogicJump } from '../logicJump';

export class ContentDiscoveryMenuFactory {
    public static makeMenu(data: IContentDiscoveryMenuDto) {
        const contentDiscoveryMenu = new ContentDiscoveryMenu();
        contentDiscoveryMenu.name = data.name;
        contentDiscoveryMenu.statement = data.statement || '';
        contentDiscoveryMenu.default = data.default;
        contentDiscoveryMenu.id = data.id!;
        return contentDiscoveryMenu;
    }

    public static makeMenuItem(item: IContentDiscoveryRuleSetDto) {
        const menuItemMap: Record<string, any> = {
            [MenuType.ConvoStream]: ContentDiscoveryMenuFactory.makeConvoStream,
            [MenuType.Convo]: ContentDiscoveryMenuFactory.makeConvo,
            [MenuType.Channel]: ContentDiscoveryMenuFactory.makeChannel,
            [MenuType.Custom]: ContentDiscoveryMenuFactory.makeCustom,
        };
        return menuItemMap[item.type](item);
    }

    public static makeConvoStream(item?: IContentDiscoveryRuleSetDto): ConvoStreamMenu {
        const menuItem = new ConvoStreamMenu();
        const rules = (item?.rules as IConvoStreamMenuDto) || {};
        menuItem.sortOrder = rules.sort_order || 'newest';
        menuItem.convoLength = rules.convo_length || 1;
        menuItem.chatGroup = rules.chat_group || chatGroupOptions.map((group) => group.id);
        menuItem.convoTypes = rules.convo_types || convoTypeOptions.map((type) => type.id);
        menuItem.convoTags = formOptionMapper(rules.convo_tags || []);
        menuItem.channels = formOptionMapper(rules.channels || []);
        return menuItem;
    }

    public static makeCustom(item?: IContentDiscoveryRuleSetDto) {
        const menuItem = new CustomMenu();
        const rules = (item?.rules as ICustomMenuDto) || {};
        menuItem.image = rules.image || '';
        menuItem.title = rules.title || '';
        menuItem.type = rules.type || 'link';
        menuItem.linkUrl = rules.link_url || '';
        menuItem.linkTarget = rules.link_target || 'internal';

        const logicJump = new LogicJump({ contentType: ContentType.Convo });
        if (rules.logic_jump) {
            logicJump.setItems(rules.logic_jump);
        }
        menuItem.logicJump = logicJump;

        return menuItem;
    }

    public static makeConvo(item?: IContentDiscoveryRuleSetDto) {
        const menuItem = new ConvoMenu();
        const rules = (item?.rules as ICustomMenuDto) || {};
        const logicJump = new LogicJump({ contentType: ContentType.Convo });
        if (rules.logic_jump) {
            logicJump.setItems(rules.logic_jump);
        }
        menuItem.logicJump = logicJump;
        return menuItem;
    }

    public static makeChannel(item?: IContentDiscoveryRuleSetDto) {
        const menuItem = new ChannelMenu();
        const rules = (item?.rules as ICustomMenuDto) || {};
        const logicJump = new LogicJump({ contentType: ContentType.Channel });
        if (rules.logic_jump) {
            logicJump.setItems(rules.logic_jump);
        }
        menuItem.logicJump = logicJump;
        return menuItem;
    }

    public static makeMenuBase(): MenuBase {
        return new MenuBase();
    }
}
