import { Auth } from 'aws-amplify';

export const cognitoUserExists = async (email: string): Promise<boolean> => {
    try {
        const result = await Auth.signIn(email, 'password');
        return true;
    } catch (error) {
        switch (error.code) {
            case 'UserNotFoundException':
                return false;
            case 'NotAuthorizedException':
                return true;
            case 'PasswordResetRequiredException':
                return true;
            case 'UserNotConfirmedException':
                return true;
            default:
                return false;
        }
    }
};
