<template>
    <Overlay
        disable-body-scroll
        :show="showOverlay"
        :show-close-button="true"
        @onClose="$emit('close')"
        @afterEnter="$emit('afterEnter')"
    >
        <FullPageLoadingSpinner :show="isLoading" />

        <div class="form-overlay" :class="`form-overlay--${size}`">
            <div class="form-overlay__body">
                <div class="form-overlay__body-inner">
                    <PageHeading :title="title" />

                    <p v-if="description" class="paragraph--medium">{{ description }}</p>

                    <slot />
                </div>
            </div>
            <div class="form-overlay__footer">
                <Save
                    :confirm-button-text="confirmButtonText"
                    :confirm-button-is-disabled="confirmButtonDisabled"
                    :cancel-button-is-visible="false"
                    :is-saved="false"
                    :status-title-is-visible="false"
                    :info-is-visible="false"
                    :vertical-style="true"
                    @cancelButtonOnClick="$emit('close')"
                    @confirmButtonOnClick="onConfirm"
                />
            </div>
        </div>
    </Overlay>
</template>

<script>
import Overlay from '@/components/ui/Overlay';
import PageHeading from '@/components/ui/PageHeading';
import Save from '@/components/ui/Save';
import FullPageLoadingSpinner from '@/components/ui/FullPageLoadingSpinner';

export default {
    name: 'FormOverlay',
    components: {
        Overlay,
        PageHeading,
        Save,
        FullPageLoadingSpinner,
    },
    props: {
        showOverlay: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: undefined,
        },
        description: {
            type: String,
            default: undefined,
        },
        confirmButtonText: {
            type: String,
            default: 'Confirm',
        },
        confirmButtonDisabled: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'default',
            validator: (value) => ['default', 'wide'].includes(value),
        },
    },
    methods: {
        onConfirm() {
            this.$emit('confirm');
        },
    },
};
</script>

<style lang="scss" scoped>
$overlay-width-default: 540px;
$overlay-width-wide: 740px;

.form-overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__body {
        padding-top: 50px;
        height: 100%;
        overflow-y: auto;
    }

    &__body-inner {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 50px;
    }

    &__footer {
        width: 100%;
        margin: 0 auto;
    }

    &__body-inner,
    &__footer {
        .form-overlay--default & {
            max-width: $overlay-width-default;
        }

        .form-overlay--wide & {
            max-width: $overlay-width-wide;
        }
    }
}
</style>
