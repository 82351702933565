import { YougovDatumResponseTypes } from '@/enums';

export class YougovDefinition {
    code: string;
    path: string;
    description: string;
    type?: YougovDatumResponseTypes;
    responses?: IYougovDefinitionResponse[];
    min?: string;
    max?: string;
    isCompletelyFetched?: boolean;
}

export interface IYougovDefinitionResponse {
    code: string;
    description: string;
}
