import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum.ts';
import { getErrorMessage } from '@/store/services/validationService';
import { IsNotEmpty, Length, validate as validateClass, ValidationError } from 'class-validator';
import hash from 'object-hash';
import { IConvoState } from '../modules/convo-details';
import { AccordionItemBase } from './accordionItemBase';
import { CommentsRatingAccordianItemFactory } from './factories/commentsRatingAccordianItem.factory';
import { IContentItem, IContentItemConvertible, IRichText } from './interfaces';
import { IObservableItem } from './interfaces/observableItem.interface';
import { CommentsRatingContentItemFactory } from './factories/commentsRatingContentItem.factory';
import { ItemsGroup, CommentsRatingTargetInput } from '.';

export class CommentsRating
    extends AccordionItemBase
    implements IContentItemConvertible, IObservableItem<IConvoState>, IRichText
{
    constructor(items = []) {
        super(items);
        this.type = ConvoMessageItemType.CommentsRating;
        this.plainText = '';
        this.text = '';
        this.commentingOnText = '';
        this.icon = 'comments-rating';
        this.isDraggable = true;
        this.isDeletable = true;
        const targetTextInputs = new ItemsGroup();
        targetTextInputs.items = [];
        this.items = [];
    }

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Message') })
    @Length(0, 640, { message: getErrorMessage('length', 'Message', { min: 0, max: 640 }) })
    plainText: string;
    text: string;
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Commenting On Description') })
    commentingOnText: string;

    setTitle(): void {
        this.subtitle = this.plainText;
    }

    itemHasChanges(state: IConvoState): boolean {
        const contentItemFactory = new CommentsRatingContentItemFactory();
        const contentItem = contentItemFactory.make(this, state);

        contentItem.version = hash(contentItem);

        if (this.originalVersion) {
            return contentItem.version !== this.originalVersion;
        }
        return false;
    }

    toContentItem(state: IConvoState): IContentItem {
        const contentItemFactory = new CommentsRatingContentItemFactory();
        return contentItemFactory.make(this, state);
    }

    toAccordionItem(contentItem: IContentItem, isClone?: boolean): AccordionItemBase {
        const accordianItemFactory = new CommentsRatingAccordianItemFactory();
        return accordianItemFactory.make(contentItem);
    }

    async validate(state: IConvoState, path: number[]): Promise<any[]> {
        const errors: ValidationError[] = await validateClass(this);
        const errorMessages: any[] = [];

        if (errors.length > 0) {
            errors.forEach((item: ValidationError) => {
                errorMessages.push({
                    path,
                    errors: { [item.property]: Object.values(item.constraints || {}) },
                });
            });
        }

        for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i] as CommentsRatingTargetInput;
            const chilrenErrors: any[] = await item.validate(state, [...path, i]);

            if (Object.entries(chilrenErrors).length > 0) {
                errorMessages.push(...chilrenErrors);
            }
        }

        return errorMessages;
    }
}
