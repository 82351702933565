<template>
    <div class="input-wrapper">
        <input
            :id="id"
            v-model="innerChecked"
            type="checkbox"
            v-bind="$attrs"
            :disabled="disabled"
            :value="inputValue"
            @change="$emit('change', $event)"
        />
        <label :for="id">{{ label }}</label>
        <div v-if="errors && errors.length > 0" class="error-message">{{ errors[0] }}</div>
    </div>
</template>

<script>
export default {
    name: 'Toggle',
    props: {
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        inputValue: {
            type: String,
            default: undefined,
        },
        value: {
            type: [Boolean, Array],
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        innerChecked: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    watch: {
        value() {
            this.$emit('changed');
        },
    },
};
</script>

<style lang="scss" scoped>
input[type='checkbox'] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    + label {
        display: inline-flex;
        align-items: center;
        position: relative;
        min-height: 24px;
        padding-left: 48px;
        cursor: pointer;
        font-size: $font-size-sm;

        &::before,
        &::after {
            @include center(y);
            content: '';
            display: block;
            position: absolute;
        }

        &::before {
            left: 0;
            width: 40px;
            height: 24px;
            border-radius: 30px;
            border: 1px solid $toggle-focus-border-color;
            background-color: $toggle-background-color;
            transition: border-color 0.2s;
        }

        &::after {
            left: 4px;
            width: 16px;
            height: 16px;
            border-radius: 16px;
            background-color: $toggle-circle-disabled-color;
            transition: transform 0.2s, background-color 0.2s;
        }
    }

    &:checked {
        + label {
            &::after {
                transform: translate(16px, -50%);
                background-color: $toggle-checked-label-color;
            }
        }
    }

    &:focus {
        + label {
            &::before {
                border-color: darken($toggle-focus-border-color, 5%);
            }
        }
    }

    &:disabled {
        + label {
            cursor: default;

            &::before,
            &::after {
                opacity: 0.6;
            }
        }
    }
}

.error-message {
    padding-left: 60px;
    font-size: 12px;
    font-style: italic;
    color: $error-color;
}
</style>
