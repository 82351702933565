import { ITenantResponse } from '../interfaces';
import { Tenant } from '..';

export class TenantsResponseFactory {
    private tenants: Tenant[] = [];

    public make(tenantsResponse: ITenantResponse[]) {
        this.tenants = [];

        for (const tenantResponse of tenantsResponse) {
            const tenant = new Tenant();

            tenant.id = tenantResponse.id;
            tenant.code = tenantResponse.code;
            tenant.name = tenantResponse.name;

            this.tenants.push(tenant);
        }

        return this.tenants;
    }
}
