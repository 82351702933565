import { AccordionItemBase } from '../models/accordionItemBase';
import { getAccordionItem, getLevel, traversal } from '../services/accordionService';

export const open = (items: AccordionItemBase[], path: number[]): void => {
    const item = getAccordionItem(items, path);

    if (item) {
        item.open();
    }
};

export const close = (items: AccordionItemBase[], path: number[]): void => {
    const item = getAccordionItem(items, path);

    if (item) {
        item.close();
    }
};

export const toggle = (items: AccordionItemBase[], path: number[]): void => {
    const item = getAccordionItem(items, path);

    if (item) {
        item.toggle();
    }
};

export const closeChildren = (items: AccordionItemBase[], path: number[]): void => {
    const item = getAccordionItem(items, path);

    if (item) {
        traversal(item.items, (item: AccordionItemBase): void => {
            item.close();
        });
    }
};

export const closeSiblings = (items: AccordionItemBase[], path: number[]): void => {
    const currentIndex = path[path.length - 1];
    const level = getLevel(items, path);

    level.forEach((item, index): void => {
        if (index !== currentIndex) {
            item.close();
        }
    });
};

export const openAll = (items: AccordionItemBase[]): void => {
    traversal(items, (item: AccordionItemBase): void => {
        item.open();
    });
};

export const closeAll = (items: AccordionItemBase[]): void => {
    traversal(items, (item: AccordionItemBase): void => {
        item.close();
    });
};

export const deleteItem = (items: AccordionItemBase[], path: number[]): void => {
    const currentIndex = path[path.length - 1];
    const level = getLevel(items, path);
    level.splice(currentIndex, 1);
};

export const replaceItem = (
    items: AccordionItemBase[],
    path: number[],
    item: AccordionItemBase,
) => {
    const currentIndex = path[path.length - 1];
    const level = getLevel(items, path);
    level.splice(currentIndex, 1, item);
};
