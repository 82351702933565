export const SET_BROADCASTS = 'setBroadcasts';
export const SET_CHANNELS = 'setChannels';
export const SET_WEBAUTH = 'setWebAuth';
export const SET_ACCESS_TOKEN = 'setAccessToken';
export const SET_USER_PROFILE = 'setUserProfile';
export const SET_EXPIRES_AT = 'setExpiresAt';
export const SET_ID_TOKEN = 'setIdToken';
export const SET_ISLOADING = 'setIsLoading';
export const SET_ERROR = 'setError';
export const SET_ERROR_MESSAGE = 'setErrorMessage';
export const SET_USER_ROLE = 'setUserRole';
export const TOGGLE_MESSAGE_ITEMS_ACCORDION_ITEM = 'toggleMessageItemsAccordionItem';
export const CLOSE_MESSAGE_ITEMS_ACCORDION_CHILDREN = 'closeMessageItemsAccordionChildren';
export const CLOSE_MESSAGE_ITEMS_ACCORDION_SIBLINGS = 'closeMessageItemsAccordionSiblings';
export const TOGGLE_RESULT_ITEMS_ACCORDION_ITEM = 'toggleResultItemsAccordionItem';
export const CLOSE_RESULT_ITEMS_ACCORDION_CHILDREN = 'closeResultItemsAccordionChildren';
export const CLOSE_RESULT_ITEMS_ACCORDION_SIBLINGS = 'closeResultItemsAccordionSiblings';
export const OPEN_ALL_MESSAGE_ITEMS_ACCORDION_ITEMS = 'openAllMessageItemsAccordionItems';
export const CLOSE_ALL_MESSAGE_ITEMS_ACCORDION_ITEMS = 'closeAllMessageItemsAccordionItems';
export const SET_MESSAGE_ITEMS_ACCORDION_ITEMS = 'setMessageItemsAccordionItems';
export const CLEAR_MESSAGE_ITEM_DATUM_DEFINITION = 'clearMessageItemDatumDefinition';
export const ADD_MESSAGE_ITEM = 'addMessageItem';
export const ADD_RESULT_ITEM = 'addResultItem';
export const DELETE_MESSAGE_ITEM = 'deleteMessage';
export const CLONE_MESSAGE_ITEM = 'cloneMessage';
export const DELETE_RESULT_ITEM = 'deleteResult';
export const SET_MESSAGE_TYPE = 'setMessageType';
export const SET_USER_SUB = 'setUserSubId';
export const SET_CONVO_LIST = 'setConvoList';
export const SET_CONVO = 'setConvo';
export const SET_CONVO_TYPE = 'setConvoType';
export const SET_CONVO_NAME = 'setConvoName';
export const SET_SELECTED_TAGS = 'setSelectedTags';
export const SET_MESSAGE_ITEM = 'setMessageItem';
export const SET_MESSAGE_ITEM_BY_KEY = 'setMessageItemByKey';
export const SET_MESSAGE_ITEMS_TOP_LEVEL_ORDER = 'setMessageItemsTopLevelOrder';
export const SET_MESSAGE_ITEM_HAS_CHANGES = 'setMessageItemHasChanges';
export const SET_CONVO_MESSAGES_ERRORS = 'setConvoMessagesErrors';
export const SET_DEFAULT_STATE = 'setDefaultState';
export const SET_PERSONALITY_QUIZ_STATE = 'setPersonalityQuizState';
export const SET_TRIVIA_QUIZ_STATE = 'setTriviaQuizState';
export const SET_YOUGOV_WELCOME_SURVEY_STATE = 'setYougovWelcomeSurveyState';
export const SET_SUCCESS = 'setSuccess';
export const SET_TEXT_INPUT = 'setTextInput';
export const PIN_MESSAGE_ITEM = 'pinMessageItem';
export const PIN_MESSAGE_ANSWER = 'pinMessageAnswer';
export const SET_RESULT_ITEM = 'setResultItem';
export const IGNORE_QUIZ_QUESTION = 'ignoreQuizQuestion';
export const SET_CONVO_MESSAGE_ITEMS = 'setConvoMessageItems';
export const ADD_CONVO_MESSAGE_ITEMS = 'addConvoMessageItems';
export const SET_CONVO_RESULT_ITEMS = 'setConvoResultItems';
export const UNDO_DELETED_MESSAGE_ITEM = 'undoDeletedMessageItem';
export const UNDO_DELETED_RESULT_ITEM = 'undoDeletedResultItem';
export const SET_CHANNEL = 'setChannel';
export const CLEAR_CHANNEL = 'clearChannel';
export const RESET_CONVO_DETAILS_STORE = 'resetConvoDetailsStore';
export const CLEAR_CONVO_MESSAGES_ERRORS = 'clearConvoMessagesErrors';
export const SET_CHANNEL_ERRORS = 'setChannelErrors';
export const SET_CHANNEL_PROPERTY = 'setChannelProperty';
export const CLEAR_ERRORS = 'clearErrors';
export const SET_VALIDATED = 'setValidated';
export const SET_CATEGORIES = 'setCategories';
export const SET_PLATFORMS = 'setPlatforms';
export const SET_CONVO_CATEGORIES = 'setConvoCategories';
export const SET_TAGS = 'setTags';
export const SET_RESULT_ITEMS_ERRORS = 'setResultItemsErrors';
export const CLEAR_RESULT_ITEMS_ERRORS = 'clearResultItemsErrors';
export const SET_CONVO_ERRORS = 'setConvoErrors';
export const SET_CONVO_WARNINGS = 'setConvoWarnings';
export const SET_RESULT_ERRORS = 'setResultErrors';
export const CLEAR_CONVO_MESSAGES_VIEW_ERRORS = 'clearConvoMessagesViewErrors';
export const SET_CONVO_MESSAGES_VIEW_ERRORS = 'setConvoMessagesViewErrors';
export const SET_SEARCH_CONTENT_OPTIONS = 'setSearchContentOptions';
export const SET_SEARCHING_LOADING_STATE = 'setSearchingLoadingState';
export const RESET_CONVO_DETAILS_VALIDATION_STATE = 'resetConvoDetailsValidationState';
export const SET_PUBLISHING_PROPERTY = 'setPublishingProperty';
export const SET_CONVO_PROPERTY = 'setConvoProperty';
export const ADD_ATTRIBUTE = 'addAttribute';
export const REMOVE_ATTRIBUTE = 'removeAttribute';
export const SET_REPORTS = 'setReports';
export const SET_TEXT_INPUT_REPORT = 'setTextInputReport';
export const RESET_CONVO_REPORT = 'resetConvoReport';
export const SET_TENANTS = 'setTenants';
export const SET_TENANT = 'setTenant';
export const SET_CATEGORY_ERRORS = 'setCategoryErrors';
export const SET_CATEGORY = 'setCategory';
export const SET_CONVO_TAGS_ATTRIBUTE = 'setConvoTagsAttribute';
export const CLEAR_CATEGORY_ERRORS = 'clearCategoryErrors';
export const RESET_CATEGORY_STORE = 'resetCategoryStore';
export const SET_COLORS_IN_USE = 'setColorsInUse';
export const SET_MESSAGE_RESPONSES = 'setMessageResponses';
export const SET_ACTIVE_MESSAGE_PATH = 'setActiveMessagePath';
export const ADD_REPORT_BREAK = 'addReportBreak';
export const CLEAR_REPORT_BREAKS = 'clearReportBreaks';
export const UPDATE_LANDING_PAGE_PROPERTY = 'updateLandingPageProperty';
export const UPDATE_YOUGOV_PREFERENCES_PROPERTY = 'updateYougovPreferencesProperty';
export const SET_REPORT_BREAK_LOADING_STATE = 'setReportBreakLoadingState';
export const SET_CONVO_EMAIL_CAMPAIGN_POLLING_STATE = 'setConvoEmailCampaignPollingState';
export const SET_EMAIL_LOCALE = 'setEmailLocale';
export const SET_TIMEZONE_IDENTIFIER = 'setTimezoneIdentifier';
export const SET_CHANNEL_GROUP_REPORT_LIST = 'setChannelGroupReportList';
export const SET_CHANNEL_GROUP_REPORT = 'setChannelGroupReport';
export const UPDATE_CHANNEL_GROUP_REPORT = 'updateChannelGroupReport';
export const SET_CHANNEL_GROUP_REPORT_RESPONSE = 'setChannelGroupReportResponse';
export const SET_CHANNEL_GROUP_REPORT_ROWS = 'setChannelGroupReportRows';
export const RESET_CHANNEL_GROUP_REPORT_STATE = 'resetChannelGroupReportState';
export const CLEAR_CHANNEL_REPORT_ERRORS = 'clearChannelReportErrors';
export const SET_CHANNEL_REPORT_ERRORS = 'setChannelReportErrors';
export const SET_REPORT_HASH = 'setReportHash';
export const SET_CHANNEL_DETAILS_BROADCASTS = 'setChannelDetailsBroadcasts';
export const SET_BROADCAST_CAMPAIGN_METRIC = 'setBroadcastCampaignMetric';
export const SET_USER_COMMENTS = 'setUserComments';
export const SET_USER_COMMENTS_STATS = 'setUserCommentsStats';
export const UPDATE_USER_COMMENT = 'updateUserComment';
export const SET_CONVO_SCHEDULE = 'setConvoSchedule';
export const SET_WELCOME_FLOWS = 'setWelcomeFlows';
export const SET_WELCOME_SURVEY_CHANNELS = 'setWelcomeSurveyChannels';
export const SET_CHANNEL_TENANT_DATA = 'setChannelTenantData';
export const SET_DEFINITIONS = 'setDefinitions';
export const SET_CONVO_TOPICS = 'setConvoTopics';
export const SET_CONVO_TOPIC_CHANGED = 'setConvoTopicsChanged';
export const RESET_CONVO_TOPIC_CHANGED = 'resetConvoTopicChanged';
export const SET_CHANNEL_DETAILS = 'setChannelDetails';
