<template>
    <div>
        <div
            class="header"
            tabindex="0"
            @click="isOpenable ? $emit('headerOnClick', $event) : null"
        >
            <slot name="header" />
        </div>
        <transition
            name="accordion"
            enter-class
            enter-active-class
            enter-to-class
            leave-class
            leave-active-class
            leave-to-class
            @before-enter="beforeEnter"
            @enter="enter"
            @after-enter="afterEnter"
            @before-leave="beforeLeave"
            @leave="leave"
            @after-leave="afterLeave"
        >
            <div
                v-if="open"
                ref="body"
                class="body"
                :class="{ 'has-animation': !animationDisabled }"
                :style="`--duration: ${duration}ms`"
            >
                <slot />
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'AccordionItemBase',
    props: {
        open: {
            type: Boolean,
            required: true,
        },
        isOpenable: {
            type: Boolean,
            default: true,
        },
        isPdl: {
            type: Boolean,
            default: false,
        },
        duration: {
            type: Number,
            default: 300,
        },
        animationDisabled: {
            type: Boolean,
            required: true,
        },
    },
    watch: {
        open: function (newVal) {
            const el = this.$refs.body;

            if (!newVal) {
                el.style.height = el.scrollHeight + 'px';
            }
        },
    },
    mounted() {
        if (this.open) {
            this.$refs.body.style.overflow = 'visible';
        }
    },
    methods: {
        beforeEnter: function (el) {
            el.style.height = '0';
            this.$emit('onExpand');
        },
        enter: function (el) {
            el.style.height = el.scrollHeight + 'px';
        },
        afterEnter: function (el) {
            this.$emit('onExpanded');
            el.style.height = 'auto';
            el.style.overflow = 'visible';
        },
        beforeLeave: function (el) {
            el.style.height = el.scrollHeight + 'px';
            el.style.overflow = 'hidden';
            this.$emit('onContract');
        },
        leave: function (el) {
            el.style.height = '0';
        },
        afterLeave() {
            this.$emit('onContracted');
        },
    },
};
</script>

<style lang="scss" scoped>
.body {
    overflow: hidden;
    transition: height 0ms;

    &.has-animation {
        transition-duration: 300ms;
        transition-duration: var(--duration);
    }
}
</style>
