<template>
    <div class="w-100">
        <CommentsRatingCardWrapper
            class="m-b-1"
            :marker="marker"
            @deleteOnClick="$emit('deleteOnClick', $event, path)"
        >
            <FormInfoBox
                class="comments-rating-card-selector"
                button-text="Edit Search"
                :message="text"
                :errors="errors.logicJump"
                @click="$emit('openContentSelector', path)"
            >
                <Input
                    :id="`comments-rating-card-message-${index}`"
                    type="text"
                    placeholder="Search for a text input message"
                    label="Text input message"
                    :name="`comments-rating-card-message-${index}`"
                    default-icon="search"
                    :default-icon-size="16"
                    :has-margin-bottom="false"
                    @focus="$emit('openContentSelector', path)"
                />
            </FormInfoBox>

            <ContentSelector
                v-if="showContentSelector"
                :value="logicJumpData"
                validation-content-type="message"
                :show-content-id="true"
                :content-types="[ContentItemType.TextInput]"
                @input="onChangeContentSelector"
                @close="$emit('closeContentSelector')"
            />
        </CommentsRatingCardWrapper>
    </div>
</template>

<script>
import { Input, FormInfoBox } from '@/components/forms';
import CommentsRatingCardWrapper from '@/components/CommentsRating/CommentsRatingCardWrapper';
import ContentSelector from '@/components/ContentSelector/ContentSelector';
import itemFormMixin from '@/mixins/itemFormMixin';
import { ContentItemType } from '@/store/enums/contentItemType.enum';
export default {
    name: 'CommentsRatingCard',
    components: {
        Input,
        FormInfoBox,
        ContentSelector,
        CommentsRatingCardWrapper,
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        logicJumpData: {
            type: Object,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        marker: {
            type: String,
            required: true,
        },
        showContentSelector: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: 0,
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            ContentItemType,
        };
    },
    methods: {
        onChangeContentSelector(value) {
            const target = {
                channel: {
                    text: value.channel.text,
                },
                convo: {
                    text: value.convo.text,
                },
                message: {
                    id: value.message.id,
                    text: value.message.text,
                },
            };
            this.onInput('logicJump', target);
        },
    },
};
</script>

<style lang="scss" scoped>
.comments-rating-card {
    display: flex;
    justify-content: space-around;
    align-items: stretch;

    &-number {
        margin: 0 10px;
        position: relative;

        div {
            width: 28px;
            height: 28px;
            border-radius: 16px;
            background: $icon-button-bg-hover-color;
            color: $primary-color;
            font-size: $font-size-xs;
            line-height: 2.1;
        }
    }

    &-selector {
        flex: 1 1 auto;
    }

    &-remove {
        margin: 0 10px;
        cursor: pointer;
        color: $primary-color;
    }
}
</style>
