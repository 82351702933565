import { IContentItem } from '../interfaces';
import { Rating, RatingOption, ContentItem } from '..';
import { ContentItemType } from '@/store/enums/';
import { IConvoState } from '@/store/modules/convo-details';
import { Answer } from '../answer.dto';

export class RatingContentItemFactory {
    public make(obj: Rating, state: IConvoState): IContentItem {
        const contentItem = new ContentItem();

        contentItem.id = obj.id;
        contentItem.title = obj.statement;
        contentItem.sort = obj.sort;
        contentItem.type = ContentItemType.Rating;
        contentItem.tags = obj.tags || [];
        contentItem.answers = [];
        contentItem.ratingTarget = obj.ratingTarget;

        for (const ratingOption of obj.items as RatingOption[]) {
            const answer = new Answer();
            answer.convoId = state.convo.id;
            answer.id = ratingOption.ratingId;
            answer.type = ratingOption.type;
            answer.rating = ratingOption.rating;
            answer.text = ratingOption.title;

            answer.targetChannel = obj.logicJump?.items?.channel?.code || '';
            answer.targetConvo = obj.logicJump?.items?.convo?.id || 0;
            answer.targetContentItem = obj.logicJump?.items?.message?.id || 0;

            contentItem.answers.push(answer);
        }

        return contentItem;
    }
}
