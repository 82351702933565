import { Message } from './message';
import { MessageType } from './enums/messageType.enum';

export class SimpleText extends Message {
    text: string;

    constructor(text: string, avatarUrl: string) {
        super(MessageType.SimpleText, avatarUrl);

        this.text = text;
    }
}
