<template>
    <div class="answers-wrapper">
        <ScrollableMessageWrapper
            v-if="showScrollableView"
            :items="items"
            :highlighted-message="highlightedMessage"
        >
            <slot />
        </ScrollableMessageWrapper>
        <div v-else class="answer-items-wrapper">
            <div
                class="answer-items"
                :class="{
                    'answer-items--stacked': layout === ButtonViewLayout.vertical,
                    'answer-items--freeform': layout == ButtonViewLayout.freeform,
                }"
            >
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import ScrollableMessageWrapper from './ScrollableMessageWrapper';
import { ButtonViewLayout } from '@/store/enums';

export default {
    name: 'AnswersWrapper',
    components: { ScrollableMessageWrapper },
    props: {
        items: {
            type: Array,
            required: true,
        },
        highlightedMessage: {
            type: Boolean,
            default: false,
        },
        isMultipleChoice: {
            type: Boolean,
            default: false,
        },
        layout: {
            type: String,
            default: ButtonViewLayout.horizontal,
        },
    },
    data() {
        return {
            ButtonViewLayout,
        };
    },
    computed: {
        showScrollableView() {
            if (this.isMultipleChoice) {
                return this.layout === ButtonViewLayout.horizontal;
            }
            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
.answers-wrapper {
    position: relative;
}

.answer-items-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    margin-left: -10px;
    margin-right: -10px;
}

.answer-items {
    display: flex;
    padding: 0 10px 0 36px;
    transform: translateX(0);
    backface-visibility: hidden;

    &--stacked {
        flex-direction: column;

        > button:not(:last-child) {
            margin-right: 0px;
            margin-bottom: 4px;
        }
    }

    &--freeform {
        flex-wrap: wrap;

        > button:not(:last-child) {
            margin-right: 2px;
            margin-bottom: 4px;
        }
    }
}
</style>
