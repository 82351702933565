export enum ContentItemType {
    Statement = 'statement',
    Rating = 'rating',
    AttachmentUrl = 'attachment',
    Buttons = 'pick1',
    QuickReply = 'quick',
    VerticalCarousel = 'carousel1',
    HorizontalCarousel = 'carousel2',
    MatrixCarousel = 'carousel3',
    Location = 'location',
    TextInput = 'input',
    Number = 'number',
    Entity = 'entity',
    PersonalityQuizResult = 'personality_quiz_result',
    TriviaQuizResult = 'trivia_quiz_result',
    Registration = 'registration',
    YougovRegistration = 'yg_registration',
    DatePicker = 'date_picker',
    Video = 'video',
    CommentsRating = 'comments_rating',
    Gif = 'gif',
    Image = 'image',
    YougovWelcomeSurveyButtons = 'yougov_welcome_survey_buttons',
}
