import Vue from 'vue';
import { TenantClient } from '@/api/tenant';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ICategory, IPlatform, ITag, IContentSearchResponse } from '../models/interfaces';
import {
    SET_CATEGORIES,
    SET_PLATFORMS,
    SET_TAGS,
    SET_SEARCH_CONTENT_OPTIONS,
    SET_SEARCHING_LOADING_STATE,
    SET_TENANTS,
    SET_TENANT,
} from '../mutations.types';
import { IRootState } from '../types/rootState';
import {
    searchChannels,
    searchConvos,
    searchMessages,
    searchContent,
} from '@/store/services/contentService';
import { Tenant } from '../models';
import { TenantsResponseFactory } from '../models/factories/tenantsResponse.factory';
import { TenantRequestFactory } from '../models/factories/tenantRequest.factory';
import { TenantResponseFactory } from '../models/factories/tenantResponse.factory';
import { ToastType } from '@/plugins/toaster';
import { UpdateTenantChannelRequest } from '../models/dtos/tenantRequests.dto';

export interface ITenant {
    categories: ICategory[];
    platforms: IPlatform[];
    tags: ITag[];
    contentOptions: IContentSearchResponse[];
    searchingIsLoading: boolean;
    tenantList: Tenant[];
    tenant: Tenant | null;
}

const state: ITenant = {
    categories: [],
    platforms: [],
    tags: [],
    contentOptions: [],
    searchingIsLoading: false,
    tenantList: [],
    tenant: null,
};

const getters: GetterTree<ITenant, IRootState> = {
    categories: (state) => {
        return state.categories;
    },
    platforms: (state) => {
        return state.platforms;
    },
    tags: (state) => {
        return state.tags;
    },
    contentOptions: (state) => {
        return state.contentOptions;
    },
    searchingIsLoading: (state) => {
        return state.searchingIsLoading;
    },
};

const actions: ActionTree<ITenant, any> = {
    getCategories: async ({ commit }) => {
        const categories = await TenantClient.getCategories();
        commit(SET_CATEGORIES, categories);
    },
    getPlatforms: async ({ commit }) => {
        const platforms = await TenantClient.getPlatforms();
        commit(SET_PLATFORMS, platforms);
    },
    getTags: async ({ commit }, { value, type }) => {
        const tags = await TenantClient.getTags(value, type);
        commit(SET_TAGS, tags);
    },
    searchContent: async (
        { commit },
        { type, query, channelCode, convoId, includeInactive, page },
    ) => {
        let options;

        commit(SET_SEARCHING_LOADING_STATE, true);

        switch (type) {
            case 'channels': {
                options = await searchChannels({ query, includeInactive, page });
                break;
            }
            case 'convos': {
                options = await searchConvos({ query, channelCode, page });
                break;
            }
            case 'messages': {
                options = await searchMessages({ query, convoId });
                break;
            }
            case 'any': {
                options = await searchContent({ query });
                break;
            }
        }
        commit(SET_SEARCH_CONTENT_OPTIONS, options);
        commit(SET_SEARCHING_LOADING_STATE, false);
    },
    clearSearchResultOptions: async ({ commit }) => {
        commit(SET_SEARCH_CONTENT_OPTIONS, []);
    },
    getTenants: async ({ commit }) => {
        const tenantsResponseFactory = new TenantsResponseFactory();
        const tenantsResponse = await TenantClient.getTenants();
        const tenants = tenantsResponseFactory.make(tenantsResponse);
        commit(SET_TENANTS, tenants);
    },
    getTenant: async ({ commit }, id: string) => {
        const tenantResponseFactory = new TenantResponseFactory();
        const tenantResponse = await TenantClient.getTenant(id);
        const tenant = tenantResponseFactory.make(tenantResponse);
        commit(SET_TENANT, tenant);
    },
    saveTenant: async ({ commit }, data: Tenant) => {
        const tenantRequestFactory = new TenantRequestFactory();
        const tenantRequestData = tenantRequestFactory.make(data);
        const result = await TenantClient.updateTenant(data.code, tenantRequestData);
        const tenantResponseFactory = new TenantResponseFactory();
        const tenantResponse = tenantResponseFactory.make(result);
        commit(SET_TENANT, tenantResponse);
        Vue.prototype.$toaster.add(
            `The "${tenantResponse.name}" tenant has been saved successfully.`,
            {
                type: ToastType.Success,
            },
        );
    },
    updateTenantOnChannelModification: async ({}, data: UpdateTenantChannelRequest) => {
        await TenantClient.updateTenantOnChannelModification(data);
    },
    clearTenants: async ({ commit }) => {
        commit(SET_TENANTS, []);
    },
    clearTenant: async ({ commit }) => {
        commit(SET_TENANT, null);
    },
};

const mutations: MutationTree<ITenant> = {
    [SET_CATEGORIES](state, categories) {
        state.categories = categories;
    },
    [SET_PLATFORMS](state, platforms) {
        state.platforms = platforms;
    },
    [SET_TAGS](state, tags) {
        state.tags = tags;
    },
    [SET_SEARCH_CONTENT_OPTIONS](state, options) {
        state.contentOptions = options;
    },
    [SET_SEARCHING_LOADING_STATE](state, loadingState) {
        state.searchingIsLoading = loadingState;
    },
    [SET_TENANTS](state, tenants) {
        state.tenantList = tenants;
    },
    [SET_TENANT](state, tenant) {
        state.tenant = tenant;
    },
};

export const tenant: Module<ITenant, IRootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
