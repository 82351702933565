import { ContentItemType } from '@/store/enums/';
import { IConvoState } from '@/store/modules/convo-details';
import { CommentsRating, ContentItem, CommentsRatingTargetInput } from '..';
import { Answer } from '../answer.dto';
import { IContentItem } from '../interfaces';
import { IAnswer } from '../interfaces/answer.interface.dto';

export class CommentsRatingContentItemFactory {
    public make(obj: CommentsRating, state: IConvoState): IContentItem {
        const contentItem = new ContentItem();
        contentItem.id = obj.id;
        contentItem.sort = obj.sort;
        contentItem.title = obj.text;
        contentItem.type = ContentItemType.CommentsRating;
        contentItem.tags = obj.tags || [];
        contentItem.targetTextInputs = {
            text: obj.commentingOnText,
            targetInputs: [],
        };

        for (const item of obj.items) {
            const targetInput = item as CommentsRatingTargetInput;
            contentItem.targetTextInputs.targetInputs.push({
                channel: targetInput.logicJump?.channel?.text!,
                convo: targetInput.logicJump?.convo?.text!,
                contentItemId: targetInput.logicJump?.message?.id!,
                contentItemText: targetInput.logicJump?.message?.text!,
            });
        }

        const answer: IAnswer = new Answer();
        answer.convoId = state.convo.id;

        contentItem.answers = [answer];

        return contentItem;
    }
}
