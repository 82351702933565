<template>
    <div class="toaster-container">
        <div
            v-for="(toast, index) of toasts"
            :key="`toaster-${index}`"
            class="toaster"
            :class="{
                'toaster--error': toast.options.type === ToastType.Error,
                'toaster--success': toast.options.type === ToastType.Success,
                'toaster--warning': toast.options.type === ToastType.Warning,
                'toaster--info': toast.options.type === ToastType.Info,
            }"
        >
            <div class="message-container">
                <div class="message">{{ toast.text }}</div>
                <div class="close" @click="remove(index)">
                    <Icon name="cross" />
                </div>
            </div>
            <div
                v-if="toast.options.progressBar"
                class="progress-bar"
                :style="{ width: `${toast.progressBarWidth}%` }"
            ></div>
        </div>
    </div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import { ToastOptions, ToastType } from './';
import Icon from '@/components/ui/Icon';

export default {
    name: 'Toaster',
    components: {
        Icon,
    },
    props: {
        options: {
            type: Object,
            default: () => new ToastOptions(),
        },
    },
    data() {
        return {
            ToastType,
            toasts: [],
            text: '',
            timeout: null,
        };
    },
    methods: {
        add(message, options) {
            const toastOptions = Object.assign({}, this.options, options);

            const id = uuid();

            const toast = {
                id,
                text: message,
                progressBarWidth: 100,
                options: toastOptions,
            };

            toast.timeout = setTimeout(() => {
                const toast = this.toasts.find((t) => t.id === id);
                if (toast) {
                    clearInterval(toast.interval);
                }
                this.toasts = this.toasts.filter((t) => t.id != id);
            }, toastOptions.timeout);

            const frequency = toastOptions.timeout / 100;
            toast.interval = setInterval(() => {
                const index = this.toasts.findIndex((t) => t.id === id);
                if (index >= 0) {
                    const toast = this.toasts[index];
                    toast.progressBarWidth = toast.progressBarWidth - 1;
                    this.toasts.splice(index, 1, toast);
                }
            }, frequency);

            this.toasts.push(toast);
        },
        remove(index) {
            clearTimeout(this.toasts[index].timeout);
            clearInterval(this.toasts[index].timeout);
            this.toasts.splice(index, 1);
        },
    },
};
</script>

<style lang="scss">
$toaster-border-radius: 4px;
$toaster-max-width: 320px;

.toaster-container {
    z-index: 10000;
    position: fixed;
    right: 1em;
    top: 1em;

    .toaster {
        position: relative;
        width: $toaster-max-width;
        margin-bottom: 1em;
        border-radius: $toaster-border-radius;
        overflow: hidden;

        .progress-bar {
            position: absolute;
            bottom: 0;
            height: 3px;
            width: 100%;
            background: green;
        }

        &--error {
            background: $toaster-background-color-error;
            color: $toaster-foreground-color-error;

            .progress-bar {
                background-color: darken($toaster-background-color-error, 20%);
            }
        }

        &--success {
            background: $toaster-background-color-success;
            color: $toaster-foreground-color-success;

            .progress-bar {
                background-color: darken($toaster-background-color-success, 20%);
            }
        }

        &--warning {
            background: $toaster-background-color-warning;
            color: $toaster-foreground-color-warning;

            .progress-bar {
                background-color: darken($toaster-background-color-warning, 10%);
            }
        }

        &--info {
            background: $toaster-background-color-info;
            color: $toaster-foreground-color-info;

            .progress-bar {
                background-color: lighten($toaster-background-color-info, 45%);
            }
        }

        .message-container {
            display: flex;
            align-items: center;
            font-size: $font-size-sm;

            .message {
                padding: 1em;
                flex-grow: 1;
            }

            .close {
                cursor: pointer;
                padding-right: 1em;
                align-self: center;
                justify-self: flex-end;
            }
        }
    }
}
</style>
