import { IContentItem } from '../interfaces';
import { TextInput, TextGlobalResponse, LogicJump } from '..';
import { replaceTemplateVariablesForPreview } from '@/store/services/convoMessageItemService';
import { sanitize } from '@/utils/sanitizer';
import { makeLogicJump } from './accordionItems.factory';
import { ContentType } from '@/store/enums';
import messengerValidationRules from '@/json/validationRules';
import { IMessengerValidationRule } from '../interfaces/messengerValidatableRules.interface';

export class TextInputAccordionItemFactory {
    public make(contentItem: IContentItem): TextInput {
        const textInputItem = new TextInput();
        const globalResponse = new TextGlobalResponse();
        const answer = contentItem.answers && contentItem.answers[0];
        const plainTextStatement = sanitize(contentItem.title);
        textInputItem.title = 'Text Input';
        textInputItem.subtitle = plainTextStatement;
        textInputItem.plainText = plainTextStatement;
        textInputItem.input = contentItem.title;
        textInputItem.id = contentItem.id || 0;
        textInputItem.sort = contentItem.sort;
        textInputItem.tags = contentItem.tags;
        textInputItem.hasCorrelation = contentItem.commentOptions?.correlation !== undefined;
        textInputItem.isPii = contentItem.isPii;

        if (contentItem.validationRules) {
            textInputItem.messengerValidation = messengerValidationRules.find(
                (v) => v.code === contentItem.validationRules?.code,
            ) as IMessengerValidationRule;
            textInputItem.errorMessage = contentItem.validationRules.errorMessage || '';
            textInputItem.allowSkip = contentItem.validationRules.allowSkip as boolean;
        } else {
            textInputItem.allowSkip = true;
        }

        if (contentItem.externalId) {
            textInputItem.datumDefinition = contentItem.externalId;
        }

        if (contentItem.commentOptions?.correlation) {
            const correlation = contentItem.commentOptions.correlation;
            textInputItem.correlation = new LogicJump({
                channel: {
                    type: ContentType.Channel,
                    text: correlation.channel,
                    code: correlation.channelCode,
                },
                convo: {
                    type: ContentType.Convo,
                    text: correlation.convo,
                    id: correlation.convoId,
                },
                message: {
                    type: ContentType.Message,
                    id: correlation.contentItemId,
                    text: correlation.contentItemText,
                },
            });
        }

        if (answer) {
            textInputItem.logicJump = makeLogicJump(answer);
        }

        if (contentItem.reply?.message) {
            globalResponse.message = contentItem.reply.message;
            globalResponse.subtitle = replaceTemplateVariablesForPreview(
                contentItem.reply.message,
                contentItem.reply.sortingOrder,
            );
            globalResponse.sortingOrder = contentItem.reply.sortingOrder;
        }

        textInputItem.items = [globalResponse];

        return textInputItem;
    }
}
