import Vue from 'vue';
import VueLogger from 'vuejs-logger';
import VueRouter from 'vue-router';
import PortalVue from 'portal-vue';
import VueWait from 'vue-wait';
import { router } from './router';
import store from './store';
import App from './App.vue';
import '@styles/main.scss';
import { ClickOutsideDirective } from './directives/clickOutside.directive';
import { Toaster } from './plugins/toaster/';
import Amplify from 'aws-amplify';
import VTooltip from 'v-tooltip';
import api from './api';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
    Sentry.init({
        Vue: Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.VUE_APP_TARGET_ENV,
        logErrors: true,
        release: process.env.VUE_APP_SENTRY_RELEASE,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        tracingOptions: {
            trackComponents: true,
            hooks: ['mount', 'update'],
            timeout: 2000,
        },
    });
}

Vue.use(VueRouter);
Vue.use(PortalVue);
Vue.use(VueWait);
Vue.use(Toaster);
Vue.use(VTooltip);
Vue.use(VueLogger, {
    levels: ['debug', 'info', 'warn', 'error', 'fatal'],
    logLevel: isProduction ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true,
});

Amplify.configure({
    Auth: {
        identityPoolId: process.env.VUE_APP_CMS_IDENTITY_POOL_ID,
        userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
        userPoolId: process.env.VUE_APP_CMS_USER_POOL_ID,
        region: 'eu-west-1',
        oauth: {
            redirectSignIn: process.env.VUE_APP_COGNITO_REDIRECT_URI,
            redirectSignOut: process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT,
            domain: process.env.VUE_APP_COGNITO_APP_DOMAIN,
            scope: ['email', 'openid'],
            responseType: 'code',
            options: {
                code_challenge_method: '',
            },
        },
    },
});

Vue.prototype.$api = api;
Vue.$api = api;
Vue.config.productionTip = false;

Vue.directive('click-outside', ClickOutsideDirective);

if (process.env.NODE_ENV === 'development' || process.env.VUE_APP_TARGET_ENV === 'staging') {
    Vue.config.devtools = true;
    // Vue.config.errorHandler = (err) => {
    //     console.error('Exception: ', err);
    // };
}

const wait = new VueWait({
    useVuex: true,
    vuexModuleName: 'wait',
    registerComponent: true,
    componentName: 'v-wait',
    registerDirective: true,
    directiveName: 'wait',
});

new Vue({
    router,
    wait,
    store,
    render: (h) => h(App),
}).$mount('#app');
