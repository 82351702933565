<template>
    <div class="tabs">
        <div class="tabs-header">
            <div
                class="tab-list-scroll-wrapper"
                :class="{
                    'tab-list-scroll-wrapper--has-left-shadow': showLeftShadow,
                    'tab-list-scroll-wrapper--has-right-shadow': showRightShadow,
                }"
            >
                <div ref="tabListScroll" class="tab-list-scroll" @scroll="listOnScroll">
                    <DragAndDrop
                        ref="tabList"
                        v-model="draggableTabs"
                        :is-draggable="isDraggable"
                        tag="ul"
                        draggable=".draggable-item"
                        class="tab-list"
                    >
                        <li
                            v-for="(tab, index) in draggableTabs"
                            :key="index"
                            :ref="`tab-${tab.index}`"
                            class="draggable-item"
                            :class="{
                                'is-active': tab.index === innerActiveTab,
                                'is-disabled': disabled,
                            }"
                        >
                            <a href="#" @click.prevent="selectTab(tab.index, true)">
                                {{ tab.name }}
                                <Icon v-if="tab.isPinned" name="pin" class="pin-icon" />
                            </a>
                        </li>
                        <li
                            v-for="(tab, index) in pinnedTabs"
                            :key="`pinned-${index}`"
                            :ref="`tab-${tab.index}`"
                            :class="{ 'is-active': tab.index === innerActiveTab }"
                        >
                            <a href="#" @click.prevent="selectTab(tab.index, true)">
                                {{ tab.name }}
                                <Icon v-if="tab.isPinned" name="pin" class="pin-icon" />
                            </a>
                        </li>
                    </DragAndDrop>
                </div>
            </div>
            <Button
                v-if="showAddButton"
                class="add-button"
                :button-style="ButtonStyle.Secondary"
                @click="$emit('addButtonOnClick')"
                v-html="addButtonText"
            />
        </div>
        <div class="tabs-details">
            <Vnode
                v-for="(slot, index) in $slots.default"
                :key="index"
                :vnode="slot"
                :is-active="index === innerActiveTab"
            />
        </div>
    </div>
</template>

<script>
import DragAndDrop from '@/components/DragAndDrop';
import Button from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';
import { ButtonStyle } from '@/store/enums/buttonStyle.enum.ts';

export default {
    name: 'Tabs',
    components: {
        DragAndDrop,
        Button,
        Icon,
        Vnode: {
            props: {
                vnode: {
                    type: Object,
                    default: undefined,
                },
                isActive: {
                    type: Boolean,
                    default: false,
                },
            },
            render() {
                if (this.isActive) {
                    return this.vnode;
                }
                return null;
            },
        },
    },
    props: {
        activeTab: {
            type: Number,
            default: undefined,
        },
        isDraggable: {
            type: Boolean,
            default: false,
        },
        showAddButton: {
            type: Boolean,
            default: false,
        },
        addButtonText: {
            type: [String, Object],
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tabs: [],
            innerActiveTab: undefined,
            ButtonStyle,
            showLeftShadow: false,
            showRightShadow: false,
        };
    },
    computed: {
        draggableTabs: {
            get() {
                return this.tabs.filter((item) => !item.isPinned);
            },
            set(value) {
                this.$emit('onDragEnd', [
                    ...value.map((item) => item.index),
                    ...this.pinnedTabs.map((item) => item.index),
                ]);
            },
        },
        pinnedTabs() {
            return this.tabs.filter((item) => item.isPinned);
        },
    },
    watch: {
        activeTab(newValue) {
            this.selectTab(newValue);

            this.$nextTick(() => {
                const ref = this.$refs[`tab-${newValue}`];

                if (ref) {
                    const element = ref[0];

                    if (element) {
                        this.$refs.tabListScroll.scrollLeft =
                            element.offsetLeft - element.offsetWidth;
                    }
                }
            });
        },
    },
    created() {
        this.setTabs();
        this.selectTab(this.activeTab);

        this.$nextTick(() => {
            this.listOnScroll();
            window.addEventListener('resize', this.listOnScroll);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.listOnScroll);
    },
    beforeUpdate() {
        this.setTabs();
    },
    methods: {
        selectTab(selectedTab, emitEvent = false) {
            if (this.disabled) {
                return;
            }

            this.innerActiveTab = selectedTab;

            if (emitEvent) {
                this.$emit('onChange', this.innerActiveTab);
            }
        },
        setTabs() {
            if (this.$slots.default && this.$slots.default.length > 0) {
                this.tabs = this.$slots.default
                    .filter((item) => item.componentOptions)
                    .map((item, index) => ({ ...item.componentOptions.propsData, index }));
            } else {
                this.tabs = [];
            }
        },
        listOnScroll() {
            if (this.$refs.tabListScroll) {
                const wrapperWidth = this.$refs.tabListScroll.offsetWidth;
                const scrollLeft = this.$refs.tabListScroll.scrollLeft;
                let tabsWidth = 0;

                this.$refs.tabList.$el.childNodes.forEach((element) => {
                    if (element instanceof HTMLElement) {
                        const style = element.currentStyle || window.getComputedStyle(element);
                        tabsWidth +=
                            element.offsetWidth +
                            parseInt(style.marginLeft, 10) +
                            parseInt(style.marginRight, 10);
                    }
                });

                const scrollRight = tabsWidth - scrollLeft;

                if (scrollLeft > 0) {
                    this.showLeftShadow = true;
                } else {
                    this.showLeftShadow = false;
                }

                if (scrollRight > wrapperWidth) {
                    this.showRightShadow = true;
                } else {
                    this.showRightShadow = false;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.tabs-header {
    position: relative;
    display: flex;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
        background: $tabs-link-background-color;
    }
}

.tab-list-scroll-wrapper {
    position: relative;
    flex: 1 1 auto;
    min-width: 0;

    &::before,
    &::after {
        content: '';
        z-index: 1;
        display: block;
        position: absolute;
        top: 0;
        width: 20px;
        height: 100%;
        opacity: 0;
        transition: opacity 0.5s;
        pointer-events: none;
    }

    &--has-left-shadow:before,
    &--has-right-shadow:after {
        opacity: 1;
    }

    &--has-left-shadow {
        &::before {
            left: 0;

            background: linear-gradient(
                to right,
                rgba($color-java, 0.6) 0%,
                rgba(255, 255, 255, 0) 100%
            );
        }
    }

    &--has-right-shadow {
        &::after {
            right: 0;
            background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0) 0%,
                rgba($color-java, 0.6) 100%
            );
        }
    }
}

.tab-list-scroll {
    overflow: hidden;
    overflow-x: auto;
    position: relative;
}

.tab-list {
    display: flex;
    flex: 1 1 auto;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 48px;
        min-width: 50px;
        padding: 10px 8px 8px;
        color: $color-java;
        text-transform: uppercase;
        font-family: $font-family-button;
        font-weight: $font-weight-regular;
        font-size: 12px;
        letter-spacing: 2px;
        text-align: center;
    }

    li {
        flex: 1 1 auto;
        margin: 0 5px;
        border-top: 2px solid transparent;
        border-left: 1px solid transparent;
        border-right: 2px solid transparent;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &.is-active {
            background-color: $tabs-link-active-background-color;
            border-top: 3px solid $color-java;
            border-left: 1px solid $tabs-link-background-color;
            border-right: 1px solid $tabs-link-background-color;

            a {
                color: $tabs-link-active-color;
                cursor: pointer;
            }
        }

        &.is-disabled {
            a {
                color: $link-disabled-color;
                cursor: default;
            }
        }
    }
}

.sortable-drag {
    background: $tabs-background-color;
}

.add-button {
    flex: 0 0 auto;
    min-width: 70px;
    margin-top: 2px;
    margin-left: 10px;
    white-space: nowrap;

    @include media-query(medium) {
        margin-left: 20px;
    }
}

.pin-icon {
    font-size: 14px;
    color: $tabs-pin-icon-color;
}
</style>
