<template>
    <MessageWrapper>
        <MessageBubble>
            <p v-if="item.title" class="comments-rating-message-title" v-html="item.title"></p>
            <button class="comments-rating-message-button">&gt; Rate Comments</button>
        </MessageBubble>
    </MessageWrapper>
</template>

<script>
import messageMixin from '../mixins/message.mixin';
import MessageWrapper from './MessageWrapper';
import MessageBubble from './MessageBubble';

export default {
    name: 'Video',
    components: {
        MessageWrapper,
        MessageBubble,
    },
    mixins: [messageMixin],
    props: {
        item: {
            type: Object,
            default: undefined,
        },
    },
};
</script>

<style lang="scss" scoped>
.comments-rating-message-title {
    padding-top: 0.4rem;
    line-height: 1.37;
    font-size: $font-size-xxs;
}
.comments-rating-message-button {
    position: relative;
    width: 100%;
    background: $color-white;
    border: none;
    padding: 2px 10px 2px 5px;
    margin-top: 10px;
    font-size: $font-size-xxs;
    font-weight: bold;
    text-align: left;
}
</style>
