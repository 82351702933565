import { buildRuleTree, buildRuleMap } from '@/store/services/audienceService';
import { AudienceRequestFactory } from '@/store/models/factories/audienceRequest.factory';
import { AudienceExportStatus } from '@/store/enums/audienceExport.enum';
import {
    IAudience,
    RuleMap,
    IRule,
    EstimateMap,
    IRuleSet,
    ExportOption,
} from '@/store/models/interfaces/audience.interface';
import { AudienceFactory } from '@/store/models/factories/audience.factory';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { AudienceDto } from '@/store/models/dtos';
import { IRootState } from '@/store/types/rootState';
import { AudienceClient } from '@/api/audience';
import { TenantClient } from '@/api/tenant';
import { RuleAttributeType } from '@/store/enums';
import { Audience } from '@/store/models';
import hash from 'object-hash';
import { ToastType } from '@/plugins/toaster';
import { RuleDto, RuleSetDto, EstimateDto } from '@/store/models/dtos/audience.dto';
import { AudienceRuleFactory } from '@/store/models/factories/audienceRule.factory';
import { AudienceRuleSetFactory } from '@/store/models/factories/audienceRuleSet.factory';
import { AudienceRuleType } from '@/store/enums/audienceRuleType.enum';
import { ChannelRule } from '@/store/models/audience/channelRule';
import Vue from 'vue';

import {
    SET_DEFAULT_AUDIENCE_STATE,
    ADD_AUDIENCE_RULE,
    DELETE_AUDIENCE_RULE,
    UPDATE_AUDIENCE_RULE,
    SET_AUDIENCE_RULE,
    SET_AUDIENCE,
    SET_ACTIVE_RULE_ID,
    SET_AUDIENCE_RULES,
    SET_AUDIENCE_ERRORS,
    SET_SUMMARY_ESTIMATE,
    SET_SUMMARY_LOADING,
    SET_SUMMARY_OBJECT,
    SET_AUDIENCE_HASH,
    SET_EXPORT_OPTIONS,
} from '@/store/modules/audience/mutations.types';

import { CLEAR_ERRORS, SET_VALIDATED } from '@/store/mutations.types';
import { ruleTypeAttributes } from '@/components/Audience/ruleTypeAttributes';
import { Poller } from '@/utils/poller';

export interface IAudienceDetailsState {
    audience: Audience;
    activeRuleId: number | null;
    summaryLoading: boolean;
    summaryObject: object;
    estimate: EstimateMap;
    rules: RuleMap;
    rulesList: number[];
    errors: object;
    validated: boolean;
    originalHash: string | null;
    exportOptions: ExportOption[];
}

const getDefaultState = (): IAudienceDetailsState => {
    return {
        audience: new Audience(),
        activeRuleId: null,
        summaryLoading: false,
        summaryObject: {},
        rules: <RuleMap>{
            0: AudienceRuleSetFactory.make(<RuleSetDto>{ id: 0, parentId: null, operator: 'or' }),
        },
        rulesList: [0],
        errors: {},
        validated: false,
        originalHash: null,
        estimate: {},
        exportOptions: [],
    };
};

const state: IAudienceDetailsState = getDefaultState();

const getters: GetterTree<IAudienceDetailsState, IRootState> = {
    ruleTree: (state) => buildRuleTree(Object.values(state.rules)),
    ruleModel: (state) => (id: number) => state.rules[id],
    rulePointer: (state) => (state.rulesList.length ? Math.max(...state.rulesList) + 1 : 0),
    ruleEstimate: (state) => (id: number) => state.estimate[id],
    isValidAudience: (state) => Object.keys(state.errors).length === 0,
    isChangedAudience: (state) => {
        try {
            const request = AudienceRequestFactory.make(state.audience, state.rules);
            return hash(request) !== state.originalHash;
        } catch (error) {
            return true;
        }
    },
    excludeMatchesCount: (state): number => {
        return Object.values(state.rules).filter((rule: IRule | IRuleSet) => {
            return rule.ruleType === AudienceRuleType.Rule && rule.excludeMatches === true;
        }).length;
    },
};

const actions: ActionTree<IAudienceDetailsState, any> = {
    setDefaultState: async ({ commit }) => {
        commit(SET_DEFAULT_AUDIENCE_STATE);
    },
    addAudienceRule: ({ commit, getters }, data: { parentId: number }) => {
        commit(ADD_AUDIENCE_RULE, { id: getters.rulePointer, parentId: data.parentId });
    },
    updateRuleAttribute: (
        { commit, getters },
        payload: { id: number; attribute: RuleAttributeType },
    ) => {
        const ruleData = getters.ruleModel(payload.id) as RuleDto;
        ruleData.attribute = payload.attribute;
        ruleData.value = '';
        const rule = AudienceRuleFactory.make(ruleData);
        commit(SET_AUDIENCE_RULE, rule);
    },
    updateAudienceRule: ({ commit }, payload: { id: number; key: string; value: any }): void => {
        commit(UPDATE_AUDIENCE_RULE, payload);
    },
    deleteAudienceRule: ({ commit }, ruleId: number) => {
        commit(DELETE_AUDIENCE_RULE, ruleId);
    },
    clearAudienceRule: ({ commit, getters }, ruleId: number) => {
        const rule: IRule = getters.ruleModel(ruleId);
        commit(SET_AUDIENCE_RULE, { id: ruleId, parentId: rule.parentId });
    },
    async setAudience({ commit, state, dispatch }, id: number) {
        try {
            const audienceData: AudienceDto = await AudienceClient.getAudience(id);
            // Create model instances for rule(set)s
            commit(SET_AUDIENCE_RULES, buildRuleMap(audienceData.rules));
            // Set audience
            const audience = AudienceFactory.make({ id, ...audienceData });
            commit(SET_AUDIENCE, audience);
            // Get content for rules to hydrate UI
            const promises = Object.values(state.rules)
                .filter((rule) => rule.ruleType === AudienceRuleType.Rule)
                .map((rule) => dispatch('hydrateRule', rule));
            await Promise.all(promises);
            // Generate hash
            const request = AudienceRequestFactory.make(state.audience, state.rules);
            commit(SET_AUDIENCE_HASH, hash(request));
        } catch (error) {
            console.error(error);
            // TODO: do something with the error.summaryMapper
        }
    },
    hydrateRule: async ({ commit }, rule: IRule) => {
        const value = await rule.getContent();
        commit(UPDATE_AUDIENCE_RULE, { id: rule.id, key: 'content', value });
    },
    updateAudience: ({ commit, state }, payload: { key: keyof IAudience; value: string }) => {
        const audience = AudienceFactory.make({
            ...state.audience,
            rules: [],
            [payload.key]: payload.value,
        });
        commit(SET_AUDIENCE, audience);
    },
    saveAudience: async ({ state }) => {
        const audienceRequest = AudienceRequestFactory.make(state.audience, state.rules);
        let response: AudienceDto;
        if (audienceRequest.id === 0) {
            response = await AudienceClient.createAudience(audienceRequest);
            return response;
        }
        response = await AudienceClient.updateAudience(audienceRequest);
        return response;
    },
    validateAudience: async ({ state, commit, dispatch }) => {
        const errors = await state.audience.validate();
        const ruleErrors = await dispatch('validateRules');
        if (ruleErrors && ruleErrors.flat().length) {
            errors['rules'] = ruleErrors;
        }
        // If no errors found by validator and state has prior errors, clear the errors.
        if (!Object.keys(errors).length && Object.keys(state.errors).length > 0) {
            commit(CLEAR_ERRORS);
        }
        // If errors found by validator, commit to state.
        if (Object.keys(errors).length) {
            commit(SET_AUDIENCE_ERRORS, errors);
        }
        // If channel is validated for first time, set flag.
        if (!state.validated) {
            commit(SET_VALIDATED, true);
        }
    },
    validateRules: ({ state }) => {
        const rules = Object.values(state.rules).filter(
            (rule) => rule.ruleType === AudienceRuleType.Rule,
        );
        const errors = (rules as IRule[]).map((rule) => rule.validate());
        return Promise.all(errors);
    },
    setActiveRuleId: ({ commit }, id: number) => {
        commit(SET_ACTIVE_RULE_ID, id);
    },
    clearActiveRuleId: ({ commit }) => {
        commit(SET_ACTIVE_RULE_ID, null);
    },
    updateSummary: async ({ commit, state, getters }, useAthena: boolean) => {
        commit(SET_SUMMARY_LOADING, true);
        const audienceRequest = AudienceRequestFactory.make(state.audience, state.rules);
        const estimateDto: EstimateDto[] = await AudienceClient.estimateAll(
            audienceRequest,
            useAthena,
        );
        const estimate = estimateDto.reduce((obj, estimate) => {
            obj[estimate.id] = estimate;
            return obj;
        }, <EstimateMap>{});
        commit(SET_SUMMARY_ESTIMATE, estimate);
        // Create summary object
        const ruleSet = Object.values(state.rules).find(
            (item) => item.parentId === null,
        ) as IRuleSet;
        const summaryObject = {
            ruleType: ruleSet.ruleType,
            operator: ruleSet.operator,
            estimate: getters.ruleEstimate(ruleSet.id)?.count || 0,
            children: Object.values(state.rules)
                .filter((rule) => rule.ruleType === AudienceRuleType.Rule)
                .map((item) => {
                    const rule = item as IRule;
                    const type = ruleTypeAttributes.find((attr) => attr.name === rule.attribute);
                    return {
                        ruleType: rule.ruleType,
                        title: rule.getSummaryTitle(),
                        type: type?.text || '',
                        estimate: getters.ruleEstimate(rule.id)?.count || 0,
                        exclude: rule.excludeMatches,
                    };
                }),
        };
        commit(SET_SUMMARY_OBJECT, summaryObject);
        commit(SET_SUMMARY_LOADING, false);
    },
    getMessageResponses: async ({ dispatch }, payload: { messageId: number; ruleId: number }) => {
        if (!payload.messageId) {
            return;
        }
        const responses: string[] = await TenantClient.getMessageResponse(
            payload.messageId.toString(),
        );
        const data = { messageId: payload.messageId, messageResponses: responses };
        dispatch('updateAudienceRule', { key: 'metadata', id: payload.ruleId, data });
    },
    pollAudienceExportStatus: async ({ dispatch }, audienceId: number) => {
        const poller = new Poller(30000, 60000 * 5);
        poller.poll(async (done: Function) => {
            const response = await AudienceClient.getExportStatus(audienceId);
            if (response.status === AudienceExportStatus.exported) {
                done();
                Vue.prototype.$toaster.add(`Audience export completed`, {
                    type: ToastType.Success,
                });
                dispatch('audienceList/getAudiences', null, { root: true });
            }
            if (response.status === AudienceExportStatus.error) {
                done();
                Vue.prototype.$toaster.add(`Audience export failed`);
                dispatch('audienceList/getAudiences', null, { root: true });
            }
        });
    },
    getExportOptions: async ({ commit }) => {
        const options: ExportOption[] = await AudienceClient.getExportOptions();
        commit(SET_EXPORT_OPTIONS, options);
    },
};

const mutations: MutationTree<IAudienceDetailsState> = {
    [SET_DEFAULT_AUDIENCE_STATE](state: IAudienceDetailsState): void {
        Object.assign(state, getDefaultState());
    },
    [SET_AUDIENCE](state: IAudienceDetailsState, payload: Audience): void {
        state.audience = payload;
    },
    [SET_AUDIENCE_HASH](state: IAudienceDetailsState, payload: string): void {
        state.originalHash = payload;
    },
    [SET_ACTIVE_RULE_ID](state: IAudienceDetailsState, payload: number): void {
        state.activeRuleId = payload;
    },
    [SET_SUMMARY_ESTIMATE](state: IAudienceDetailsState, payload: EstimateMap) {
        state.estimate = payload;
    },
    [SET_SUMMARY_OBJECT](state: IAudienceDetailsState, payload: object) {
        state.summaryObject = payload;
    },
    [SET_SUMMARY_LOADING](state: IAudienceDetailsState, payload: boolean): void {
        state.summaryLoading = payload;
    },
    [SET_AUDIENCE_RULES](state: IAudienceDetailsState, rules: RuleMap) {
        state.rulesList = Object.values(rules).map((ruleItem: any) => ruleItem.id);
        state.rules = rules;
    },
    [SET_AUDIENCE_RULE](state: IAudienceDetailsState, payload: IRule) {
        Vue.set(state.rules, payload.id, payload);
    },
    [ADD_AUDIENCE_RULE](
        state: IAudienceDetailsState,
        payload: { id: number; parentId: number },
    ): void {
        state.rulesList.push(payload.id);
        Vue.set(state.rules, payload.id, payload);
    },
    [DELETE_AUDIENCE_RULE](state: IAudienceDetailsState, id: number): void {
        state.rulesList.splice(state.rulesList.indexOf(id), 1);
        Vue.delete(state.rules, id);
    },
    [UPDATE_AUDIENCE_RULE](
        state: IAudienceDetailsState,
        payload: { id: number; key: string; value: any },
    ): void {
        Vue.set(state.rules[payload.id], payload.key, payload.value);
    },
    [SET_AUDIENCE_ERRORS](state: IAudienceDetailsState, errors): void {
        state.errors = errors;
    },
    [CLEAR_ERRORS](state: IAudienceDetailsState): void {
        state.errors = {};
    },
    [SET_VALIDATED](state: IAudienceDetailsState, value: boolean): void {
        state.validated = value;
    },
    [SET_EXPORT_OPTIONS](state: IAudienceDetailsState, options: ExportOption[]) {
        state.exportOptions = options;
    },
};

export const audienceDetails: Module<IAudienceDetailsState, IRootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
