<template>
    <div
        class="input-wrapper"
        :class="{ 'form-group--has-m-b': hasMarginBottom, 'form-group--has-m-t': hasMarginTop }"
    >
        <input
            :id="id"
            v-model="innerChecked"
            type="checkbox"
            v-bind="$attrs"
            :disabled="disabled"
            :value="inputValue"
            @change="$emit('change', $event)"
        />
        <label :for="id">
            <template>
                <Icon :name="customIcon ? customIcon : 'check'" class="checkmark" />
            </template>
            {{ label }}
        </label>
        <div v-if="errors && errors.length > 0" class="error-message">{{ errors[0] }}</div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    name: 'Checkbox',
    components: {
        Icon,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        inputValue: {
            type: String,
            default: undefined,
        },
        value: {
            type: [Boolean, Array],
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hasMarginBottom: {
            type: Boolean,
            default: true,
        },
        hasMarginTop: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Array,
            default: () => [],
        },
        customIcon: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        innerChecked: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    watch: {
        value() {
            this.$emit('changed');
        },
    },
};
</script>

<style lang="scss" scoped>
$form-group-margin-bottom: 20px !default;
$form-group-margin-top: 20px !default;

.form-group {
    &--has-m-b {
        margin-bottom: $form-group-margin-bottom;
    }
    &--has-m-t {
        margin-top: $form-group-margin-top;
    }
    &--is-active {
        position: relative;
        z-index: 1;
    }
}

input[type='checkbox'] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    + label {
        display: inline-block;
        position: relative;
        padding-left: 25px;
        font-size: 14px;
        color: $input-text-color;
        cursor: pointer;

        @include media-query(medium) {
            font-size: $font-size-sm;
        }

        &::before {
            @include center(y);
            content: '';
            display: block;
            position: absolute;
            left: 0;
            width: 17px;
            height: 17px;
            border: 1px solid $checkbox-border-color;
            background: $checkbox-background-color;
            transition: border-color 0.2s;
        }

        .checkmark {
            @include center(y);
            display: none;
            position: absolute;
            left: 4px;
            font-size: 10px;
            fill: $primary-color;
        }
    }

    &:checked {
        + label {
            .checkmark {
                display: block;
            }
        }
    }

    &:focus {
        + label {
            &::before {
                border-color: darken($checkbox-border-color, 5%);
            }
        }
    }

    &:disabled {
        + label {
            cursor: default;
            color: rgba($input-text-color, 0.6);

            &::before,
            &::after {
                opacity: 0.6;
            }
        }
    }
}

.error-message {
    padding-left: 35px;
    font-size: 12px;
    font-style: italic;
    color: $error-color;
}
</style>
