import { format, isBefore, parseISO } from 'date-fns';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { getBroadcasts } from '../../api/messaging';
import { IBroadcastItem } from '../models/interfaces';
import { SET_BROADCASTS } from '../mutations.types';
import { IRootState } from '../types/rootState';

export interface IBroadcast {
    name: string;
    items: IBroadcastItem[];
}

export interface IBroadcastState {
    broadcasts: IBroadcast;
}

const state: IBroadcastState = {
    broadcasts: {
        name: 'broadcasts',
        items: [],
    },
};

const getters: GetterTree<IBroadcastState, IRootState> = {
    broadcasts: () => state.broadcasts,
};

const actions: ActionTree<IBroadcastState, any> = {
    getBroadcasts: async ({ commit }) => {
        const data: IBroadcastItem[] = (await getBroadcasts()) as unknown as IBroadcastItem[];

        if (data) {
            data.map((data) => {
                data.sentAtFormatted = format(parseISO(data.sentAt), 'dddd Do, MMM YYYY HH:mm');
                data.disable =
                    isBefore(parseISO(data.sentAt), new Date()) ||
                    data.status === 'FINISHED' ||
                    data.status === 'CANCELLED';
                return data;
            });
        }
        // Vue.$log.info('loaded broadcasts', data);
        commit(SET_BROADCASTS, data || []);
    },
};

const mutations: MutationTree<IBroadcastState> = {
    [SET_BROADCASTS](state, broadcasts: IBroadcastItem[]): void {
        state.broadcasts.items = broadcasts;
    },
};

export const broadcasts: Module<IBroadcastState, IRootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
