<script>
import { ConvoTypes } from '@/store/enums';
import { mapGetters } from 'vuex';

export default {
    name: 'QuizOptionsMixin',
    props: {
        path: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapGetters('convoDetails', ['convoDetailsView']),
        isQuiz() {
            return (
                this.convoDetailsView.convo.type === ConvoTypes.PersonalityQuiz ||
                this.convoDetailsView.convo.type === ConvoTypes.TriviaQuiz
            );
        },
    },
    methods: {
        onShowQuizResultsInput(isToggled) {
            if (isToggled) {
                this.onInput('ignoreQuizQuestion', isToggled);
                this.$emit('ignoreQuizQuestion', this.path, isToggled);
            }
            this.onInput('showQuizResults', isToggled);
            this.$emit('showQuizResults', this.path, isToggled);
        },
        onIgnoreQuestionInput(isToggled) {
            this.onInput('ignoreQuizQuestion', isToggled);
            this.$emit('ignoreQuizQuestion', this.path, isToggled);
        },
    },
};
</script>
