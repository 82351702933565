var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormFieldWrapper',{attrs:{"label":_vm.label,"errors":_vm.errors,"is-focused":_vm.isFocused,"is-disabled":_vm.disabled,"info-text":_vm.infoText},on:{"blur":_vm.onBlur}},[_c('ol',{staticClass:"tags-group tags-group--selected",on:{"click":_vm.onFocus}},[_vm._l((_vm.selectedOptions),function(item){return _c('li',{key:item[_vm.idKey],staticClass:"tag tag-selected",class:{
                'tag-highlighted': _vm.isHighlighted(item),
            }},[(_vm.isHighlighted(item))?_c('Icon',{attrs:{"name":"pin","size":14}}):_vm._e(),_vm._v(" "+_vm._s(item[_vm.nameKey])+" "),_c('button',{staticClass:"tag-icon",on:{"click":function($event){$event.stopPropagation();return _vm.onRemove(item[_vm.idKey])}}},[_c('Icon',{attrs:{"name":"cross-simple","size":8}})],1)],1)}),(!(_vm.value && _vm.value.length))?_c('li',{staticClass:"tag tag-placeholder"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e()],2),(_vm.showDropdown)?_c('div',{staticClass:"dropdown"},_vm._l((_vm.optionsByCategory),function(ref){
            var id = ref.id;
            var name = ref.name;
            var children = ref.children;
return _c('div',{key:id,staticClass:"tags-category"},[(name)?_c('div',{staticClass:"tags-category-name"},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e(),_c('ul',{staticClass:"tags-group"},_vm._l((children),function(item){return _c('li',{key:item[_vm.idKey],staticClass:"tag-option",class:{
                        'tag-highlighted': _vm.isHighlighted(item),
                    }},[_c('Checkbox',{attrs:{"id":item[_vm.idKey],"value":_vm.isSelected(item),"label":item[_vm.nameKey],"has-margin-bottom":false},on:{"input":function($event){return _vm.onChange(item, $event)}}}),_c('button',{staticClass:"tag-icon",attrs:{"title":_vm.highlightTitle},on:{"click":function($event){return _vm.setHighlight(item)}}},[_c('Icon',{attrs:{"name":"pin"}})],1)],1)}),0)])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }