import { ContentDiscoveryMenuItemType } from '@/enums';
import { getErrorMessage } from '@/store/services/validationService';
import { IsNotEmpty, IsUrl, ValidateIf } from 'class-validator';
import { LogicJump } from '..';
import { MenuBase } from './menuBase';

export interface ICustomMenu {
    title: string;
    type: 'link' | 'logicJump';
    image: string;
    linkUrl?: string;
    linkTarget?: 'internal' | 'blank';
    logicJump?: LogicJump;
}

export class CustomMenu extends MenuBase implements ICustomMenu {
    menuType: ContentDiscoveryMenuItemType;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Link Type') })
    type: 'link' | 'logicJump';

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Image') })
    image: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Title') })
    title: string;

    @ValidateIf((o) => o.type === 'link')
    @IsUrl({}, { message: getErrorMessage('isUrl', 'External Link') })
    linkUrl?: string;

    @ValidateIf((o) => o.type === 'link')
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Link Behaviour') })
    linkTarget?: 'internal' | 'blank';

    logicJump?: LogicJump;

    constructor() {
        super();
        this.menuType = ContentDiscoveryMenuItemType.Custom;
    }

    async validate(): Promise<any> {
        let errors = await super.validate();
        if (this.type === 'logicJump') {
            const logicJumpErrors = await this.logicJump?.validate();
            if (Object.keys(logicJumpErrors).length) {
                errors = errors || {};
                errors.logicJump = [getErrorMessage('logicJumpContent', 'channel or convo')];
            }
        }
        return errors;
    }
}
