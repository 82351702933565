<template>
    <div>
        <div
            class="form-field"
            :class="{
                'form-field--is-focused': isFocused,
                'form-field--has-error': errors.length > 0,
                'form-field--has-icon': showDefaultIcon,
                'form-field--is-disabled': disabled,
                'form-field--has-bottom-space': hasMarginBottom,
                'form-field--has-top-space': hasMarginTop,
            }"
        >
            <label
                :for="id"
                class="form-field-label"
                :class="{ 'form-field-label--hidden': hideLabel }"
            >
                <span>{{ label }}</span>
            </label>
            <div class="input-number-wrapper">
                <IconButton
                    icon="numeric-minus"
                    class="spin-controls spin-controls--minus"
                    :disabled="disabled"
                    @click="onValueDecrease"
                />
                <input
                    :id="id"
                    ref="input"
                    v-model="innerValue"
                    type="number"
                    :max="max"
                    :min="min"
                    :step="step"
                    class="form-field-input number-input__input"
                    :name="name"
                    v-bind="$attrs"
                    :maxlength="max"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    @focus="onFocus"
                    @blur="onBlur"
                    @change="$emit('change', $event)"
                />
                <IconButton
                    icon="numeric-plus"
                    class="spin-controls"
                    :disabled="disabled"
                    @click="onValueIncrease"
                />
            </div>
            <div v-if="showDefaultIcon" class="form-field-icon">
                <Icon :name="defaultIcon" :size="defaultIconSize" />
            </div>
        </div>
        <div v-if="showAdditionalInfo" class="form-field-additional-information">
            <div class="form-field-additional-information__left">
                <div v-if="errors && errors.length > 0" class="form-field-error-message">
                    <Icon name="alert" />
                    {{ errors[0] }}
                </div>
                <div v-if="infoText" class="form-field-info-text">{{ infoText }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import onlyValues from '@/utils/onlyValues.ts';
import Icon from '@/components/ui/Icon';
import IconButton from '@/components/ui/IconButton';

export default {
    name: 'NumberInput',
    components: {
        Icon,
        IconButton,
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            required: false,
            default: 10000,
        },
        min: {
            type: Number,
            required: false,
            default: 0,
        },
        step: {
            type: Number,
            required: false,
            default: 1,
        },
        id: {
            type: String,
            required: false,
            default: () => {
                return 'NumericInputID' + Date.now().toString();
            },
        },
        name: {
            type: String,
            required: false,
            default: () => {
                return 'NumericInputName' + Date.now().toString();
            },
        },
        label: {
            type: String,
            required: true,
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        defaultIcon: {
            type: String,
            default: '',
        },
        defaultIconSize: {
            type: Number,
            default: 25,
        },
        hasMarginBottom: {
            type: Boolean,
            default: true,
        },
        hasMarginTop: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        infoText: {
            type: String,
            default: undefined,
        },
    },
    data: function data() {
        return {
            isFocused: false,
            innerValue: this.value,
        };
    },
    computed: {
        showDefaultIcon() {
            return this.defaultIcon && !this.innerValue;
        },
        showAdditionalInfo() {
            return (this.errors && this.errors.length > 0) || this.infoText;
        },
    },
    watch: {
        value(value) {
            this.innerValue = value;
            this.$emit('changed');
        },
        innerValue(value) {
            this.$emit('input', parseInt(value, 10));
        },
    },
    methods: {
        onFocus() {
            this.isFocused = true;
            this.$emit('focus');
        },
        onBlur() {
            this.isFocused = false;
            this.$emit('blur');
        },
        onValueIncrease() {
            if (this.innerValue + this.step <= this.max) {
                this.innerValue += this.step;
            }
        },
        onValueDecrease() {
            if (this.innerValue - this.step >= this.min) {
                this.innerValue -= this.step;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.form-group {
    &--has-m-b {
        margin-bottom: $form-group-margin-bottom;
    }
    &--has-m-t {
        margin-top: $form-group-margin-top;
    }
}

.form-field {
    &--has-icon {
        padding-right: 31px;
    }

    &--is-disabled {
        .form-field-input {
            opacity: 0.5;
        }
    }
}

/* Chrome, Safari, Edge, Opera */
.number-input {
    &__input {
        width: 100%;
        text-align: center;
        min-width: 0%;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        &[type='number'] {
            -moz-appearance: textfield;
        }
    }
}

.input-number-wrapper {
    display: flex;
}

.spin-controls {
    position: relative;
    align-self: center;
    width: 8px;
    height: 8px;
    margin: 8px;
    &--minus {
        color: $color-cadet-blue;
    }
}
</style>
