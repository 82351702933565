<template>
    <div class="rating-option">
        <div v-if="marker" class="rating-option-marker">{{ marker }}</div>
        <Input
            :id="generateId('title', path)"
            type="text"
            label="Label"
            :name="generateId('title', path)"
            :max="100"
            show-remaining-char
            :has-margin-bottom="false"
            :value="data.title"
            :errors="data.errors.title"
            :disabled="isDisabled"
            class="rating-option-label"
            @input="(value) => onInput('title', value)"
        />
        <NumberInput
            :id="generateId('category-rating', path)"
            class="rating-option-score"
            :has-margin-bottom="false"
            :value="data.rating"
            label="Rating"
            placeholder="Enter a value"
            :disabled="isDisabled"
            :errors="data.errors.rating"
            @input="onInput('rating', $event)"
        />
    </div>
</template>

<script>
import { getMarker } from '@/utils/listMarkers.ts';
import itemFormMixin from '@/mixins/itemFormMixin';
import { Input, NumberInput } from '@/components/forms';
export default {
    name: 'RatingOption',
    components: {
        NumberInput,
        Input,
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        marker() {
            const { marker, markerType } = this.data;
            return marker || getMarker(marker, markerType, 1, this.index);
        },
    },
};
</script>

<style lang="scss" scoped>
.rating-option {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 3rem 0;
    &-label {
        flex-grow: 5;
    }
    &-score {
        flex: 1 1 100px;
    }
    &-marker {
        margin-top: 0.75rem;
        flex: 0 0 24px;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background-color: $button-wrapper-marker-bg-color;
        font-size: 12px;
        font-weight: $font-weight-bold;
        color: $button-wrapper-marker-text-color;
        text-align: center;
        line-height: 24px;
        white-space: nowrap;
    }
}
</style>
