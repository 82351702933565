import { IYougovDefinitionResponse, YougovDefinition } from '@/store/models/yougovDefinition';
import { IYougovDefinitionDto } from '@/store/models/dtos';
import { YougovDatumResponseTypes } from '@/enums';

export class YougovDefinitionFactory {
    public make(obj: IYougovDefinitionDto): YougovDefinition {
        const yougovDefinition = new YougovDefinition();
        yougovDefinition.code = obj.code;
        yougovDefinition.path = obj.path;
        yougovDefinition.description = obj.description;

        if (obj?.type) {
            yougovDefinition.type = obj.type;
        }

        if (obj?.responses) {
            yougovDefinition.isCompletelyFetched = true;
            yougovDefinition.responses = obj.responses.map(
                (response: IYougovDefinitionResponse) => ({
                    description: response.description,
                    code: response.code,
                }),
            );
        }

        if (obj.type === YougovDatumResponseTypes.Date) {
            yougovDefinition.min = obj.min;
            yougovDefinition.max = obj.max;
        }
        return yougovDefinition;
    }
}
