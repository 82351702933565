<template>
    <div>
        <div v-if="isPdl" class="text-statement-skip">
            <Checkbox
                id="shouldSkipIfAnswered"
                label="Should skip if already answered"
                :value="data.shouldSkipIfAnswered"
                @input="(value) => onInput('shouldSkipIfAnswered', value)"
            />
        </div>
        <div v-if="isPdl" class="text-statement-tags-header">
            <Tags placeholder="" label="Tags" :value="data.tags" :disabled="true" />
        </div>
        <EditorInput
            v-if="$options.richTextMessagesEnabled"
            :id="generateId('statement', path)"
            label="Message"
            show-remaining-char
            :content="data.statement"
            :readonly="isPdl"
            :errors="data.errors.plainText"
            :max="640"
            :menu-items="[
                $options.EditorInputMenuItem.Bold,
                $options.EditorInputMenuItem.Italic,
                $options.EditorInputMenuItem.Strike,
                $options.EditorInputMenuItem.Underline,
            ]"
            @update="onInput('statement', $event)"
            @plainTextChange="onInput('plainText', $event)"
        />
        <Input
            v-else
            :id="generateId('statement', path)"
            type="textarea"
            label="Message"
            name="statement"
            :max="640"
            show-remaining-char
            :has-margin-bottom="false"
            :readonly="isPdl"
            :value="data.statement"
            :errors="data.errors.plainText"
            @input="
                (value) => {
                    onInput('statement', value);
                    onInput('plainText', value);
                }
            "
        />
    </div>
</template>

<script>
import { Input, Tags, Checkbox, EditorInput } from '@/components/forms';
import itemFormMixin from '@/mixins/itemFormMixin';
import { AccordionStyle, EditorInputMenuItem } from '@/store/enums';
import config from '@/config';

export default {
    name: 'TextStatement',
    components: {
        Checkbox,
        Input,
        Tags,
        EditorInput,
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        currentChannel: {
            type: Object,
            default: undefined,
        },
        currentConvo: {
            type: Object,
            default: undefined,
        },
        isPdl: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            AccordionStyle,
        };
    },
    created() {
        this.$options.richTextMessagesEnabled = config.featureToggle.richTextMessagesEnabled;
        this.$options.EditorInputMenuItem = EditorInputMenuItem;
    },
    methods: {},
};
</script>
<style lang="scss" scoped>
.text-statement-skip {
    border-bottom: 1px solid $container-default-border-color;
    margin-bottom: 25px;
}
</style>
