<template>
    <div class="quiz-options">
        <Toggle
            :id="generateId('show-results', path)"
            class="m-r-2"
            label="Show quiz results"
            :value="showQuizResults"
            :disabled="disabled"
            @input="$emit('input-show-results', $event)"
            @changed="$emit('change', $event)"
        />

        <Toggle
            :id="generateId('ignore-question', path)"
            label="This question doesn't count towards result"
            :disabled="showQuizResults || disabled"
            :value="ignoreQuizQuestion"
            @input="$emit('input-ignore-question', $event)"
            @changed="$emit('change', $event)"
        />
    </div>
</template>

<script>
import itemFormMixin from '@/mixins/itemFormMixin';
import { Toggle } from '@/components/forms';

export default {
    name: 'QuizOptions',
    components: {
        Toggle,
    },
    mixins: [itemFormMixin],
    props: {
        path: {
            type: Array,
            required: true,
        },
        ignoreQuizQuestion: {
            type: Boolean,
            required: true,
        },
        showQuizResults: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
@include media-query(medium) {
    .quiz-options {
        display: flex;
    }
}
</style>
