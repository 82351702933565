<template>
    <div
        class="form-group"
        :class="[
            {
                'form-group--has-m-b': hasMarginBottom,
                'form-group--has-m-t': hasMarginTop,
            },
        ]"
    >
        <div
            class="form-field"
            :class="{
                'form-field--is-focused': isFocused,
                'form-field--has-error': errors.length > 0,
                'form-field--has-warning': warnings.length > 0 && errors.length === 0,
                'form-field--is-textarea': type === 'textarea',
                'form-field--has-prefix': hasPrefix,
                'form-field--has-icon': showClearIcon || showDefaultIcon,
                'form-field--is-disabled': disabled && !readonly,
                'form-field--is-readonly': readonly,
                'form-field--has-top-space': hasMarginTop,
            }"
        >
            <label
                :for="id"
                class="form-field-label"
                :class="{ 'form-field-label--hidden': hideLabel }"
            >
                <span>{{ label }}</span>
            </label>
            <span v-if="hasPrefix && (value !== '' || isFocused)" class="prefix">
                {{ prefixText }}
            </span>
            <textarea
                v-if="type === 'textarea'"
                :id="id"
                ref="textarea"
                v-model="innerValue"
                class="form-field-input"
                :name="name"
                v-bind="$attrs"
                :maxlength="max"
                :disabled="disabled"
                :readonly="readonly"
                @focus="onFocus"
                @blur="onBlur"
                @change="$emit('change', $event)"
            ></textarea>
            <input
                v-else
                :id="id"
                ref="input"
                v-model="innerValue"
                :type="type"
                class="form-field-input"
                :name="name"
                v-bind="$attrs"
                :maxlength="max"
                :placeholder="placeholder"
                :disabled="disabled"
                :readonly="readonly"
                @focus="onFocus"
                @blur="onBlur"
                @change="$emit('change', $event)"
            />
            <button
                v-if="showClearIcon"
                class="form-field-icon"
                type="reset"
                @click="onClear($event)"
            >
                <Icon v-if="innerValue" name="cross" :size="14" />
            </button>
            <div v-if="showDefaultIcon" class="form-field-icon" @click="$emit('click-icon')">
                <Icon :name="defaultIcon" :size="defaultIconSize" />
            </div>
        </div>
        <div v-if="showAdditionalInfo" class="form-field-additional-information">
            <div class="form-field-additional-information__left">
                <div v-if="errors && errors.length > 0">
                    <ErrorMessage :message="errors[0]" />
                </div>
                <div v-else-if="warnings && warnings.length > 0">
                    <WarningMessage :message="warnings[0]" />
                </div>
                <div v-if="infoText" class="form-field-info-text">{{ infoText }}</div>
            </div>

            <CharCounter
                v-if="showRemainingChar"
                :max="max"
                :warning-max="warningMax"
                :count="innerValue.length"
            />
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import WarningMessage from '@/components/forms/partials/WarningMessage';
import ErrorMessage from '@/components/forms/partials/ErrorMessage';
import CharCounter from '@/components/forms/partials/CharCounter';
import debounce from 'lodash/debounce';

export default {
    name: 'Input',
    components: {
        Icon,
        CharCounter,
        ErrorMessage,
        WarningMessage,
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        max: {
            type: Number,
            default: undefined,
        },
        warningMax: {
            type: Number,
            default: undefined,
        },
        showRemainingChar: {
            type: Boolean,
            default: false,
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        defaultIcon: {
            type: String,
            default: '',
        },
        defaultIconSize: {
            type: Number,
            default: 25,
        },
        showClearIcon: {
            type: Boolean,
            default: false,
        },
        hasMarginBottom: {
            type: Boolean,
            default: true,
        },
        hasMarginTop: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Array,
            default: () => [],
        },
        warnings: {
            type: Array,
            default: () => [],
        },
        prefixText: {
            type: String,
            default: 'prefix',
        },
        hasPrefix: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        infoText: {
            type: String,
            default: undefined,
        },
        debounceDelay: {
            type: Number,
            default: 250,
        },
    },
    data: function data() {
        return {
            isFocused: false,
            innerValue: this.value,
        };
    },
    computed: {
        showDefaultIcon() {
            return (
                this.defaultIcon &&
                (!this.showClearIcon || (this.showClearIcon && !this.innerValue))
            );
        },
        showAdditionalInfo() {
            return (
                (this.errors && this.errors.length > 0) ||
                this.infoText ||
                this.showRemainingChar ||
                (this.warnings && this.warnings.length > 0)
            );
        },
    },
    watch: {
        value(value) {
            this.innerValue = value;
            this.$emit('changed');
        },
        innerValue(value) {
            this.$options.delayedInput(value);
        },
    },
    mounted() {
        this.$options.delayedInput = debounce((value) => {
            this.$emit('input', value);
        }, this.debounceDelay);
    },
    methods: {
        onFocus() {
            this.isFocused = true;
            this.$emit('focus');
        },
        onBlur() {
            this.isFocused = false;
            this.$emit('blur');
        },
        onClear() {
            this.innerValue = '';
            this.$emit('onClear', this.innerValue);
        },
    },
};
</script>

<style lang="scss" scoped>
.form-group {
    &--has-m-b {
        margin-bottom: $form-group-margin-bottom;
    }
    &--has-m-t {
        margin-top: $form-group-margin-top;
    }
}

.form-field {
    width: 100%;
    &--has-icon {
        padding-right: 31px;
    }

    &--is-disabled {
        .form-field-input {
            opacity: 0.5;
        }
    }
}

.prefix {
    padding-left: 10px;
    font-size: 14px;
    color: $input-prefix-text-color;
}

textarea.form-field-input {
    display: block;
    height: auto;
    min-height: 104px;
}
</style>
