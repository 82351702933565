<template>
    <MessageWrapper :avatar-url="avatarUrl">
        <MessageBubble>
            <div class="video-message">
                <img v-if="item.image" :src="item.image" alt="" class="video-message-image" />

                <div class="video-message-icon">
                    <Icon name="video" :size="40" />
                </div>

                <div v-if="item.duration" class="video-message-duration">{{ item.duration }}</div>
            </div>

            <p v-if="item.title" class="video-message-title">{{ item.title }}</p>
        </MessageBubble>
    </MessageWrapper>
</template>

<script>
import messageMixin from '../mixins/message.mixin';
import MessageWrapper from './MessageWrapper';
import MessageBubble from './MessageBubble';
import Icon from '@/components/ui/Icon';

export default {
    name: 'Video',
    components: {
        MessageWrapper,
        MessageBubble,
        Icon,
    },
    mixins: [messageMixin],
    props: {
        item: {
            type: Object,
            default: undefined,
        },
    },
    computed: {
        avatarUrl() {
            return this.item.showAvatar && this.item.avatarUrl ? this.item.avatarUrl : undefined;
        },
    },
};
</script>

<style lang="scss" scoped>
.video-message {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    background-color: $color-oxford-blue;
}

.video-message-icon,
.video-message-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-message-image {
    z-index: 1;
    object-fit: cover;
}

.video-message-icon {
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-light;
}

.video-message-duration {
    position: absolute;
    color: $color-light;
    bottom: 3px;
    right: 3px;
    z-index: 3;
    line-height: 1;
    font-size: $font-size-xxs;
    font-weight: $font-weight-bold;
}

.video-message-title {
    padding-top: 0.4rem;
    line-height: 1.37;
    font-size: $font-size-xxs;
}
</style>
