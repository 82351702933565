export enum ConvoTypes {
    None = '',
    Interests = 'interests',
    Scripted = 'chat',
    TriviaQuiz = 'quiz',
    PersonalityQuiz = 'personality_quiz',
    PDLConvo = 'pdl',
    YougovWelcomeSurvey = 'yougov_welcome_survey',
    YougovWelcomeChat = 'yougov_welcome_chat',
}
