import { AudienceDto, GetAudienceRequest } from '@/store/models/dtos';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState } from '@/store/types/rootState';
import { IPaging } from '@inconvo/types/interfaces';
import { AudienceClient } from '@/api/audience';
import { Audience } from '@/store/models/audience';

import {
    SET_DEFAULT_AUDIENCE_STATE,
    SET_AUDIENCES,
} from '@/store/modules/audience/mutations.types';
import { AudienceFactory } from '@/store/models/factories/audience.factory';

export interface IAudienceListState {
    audienceList: IPaging<Audience>;
}

const getDefaultState = (): IAudienceListState => {
    return {
        audienceList: { rules: [] } as unknown as IPaging<Audience>,
    };
};

const state: IAudienceListState = getDefaultState();

const getters: GetterTree<IAudienceListState, IRootState> = {};

const actions: ActionTree<IAudienceListState, any> = {
    setDefaultState: async ({ commit }) => {
        commit(SET_DEFAULT_AUDIENCE_STATE);
    },
    getAudiences: async ({ commit }, searchOptions) => {
        const request = new GetAudienceRequest();
        request.q = (searchOptions && searchOptions.q) || '';
        request.page = (searchOptions && searchOptions.page) || 1;
        request.size = 15;

        let result: IPaging<AudienceDto> = await AudienceClient.getAudiences(request);
        if (!result) {
            result = {
                page: 0,
                size: 0,
                total: 0,
                items: [],
            };
        }

        const audiences: IPaging<Audience> = {
            page: result.page,
            size: result.size,
            total: result.total,
            items: result.items.map((audience) => {
                return AudienceFactory.make(audience);
            }),
        };

        commit(SET_AUDIENCES, audiences || result);
    },
    deleteAudience: async ({}, id: number) => {
        await AudienceClient.deleteAudience(id);
        return;
    },
    exportAudience: async ({}, id: number) => {
        await AudienceClient.exportAudience(id);
    },
};

const mutations: MutationTree<IAudienceListState> = {
    [SET_DEFAULT_AUDIENCE_STATE](state: IAudienceListState): void {
        Object.assign(state, getDefaultState());
    },
    [SET_AUDIENCES](state: IAudienceListState, payload: IPaging<Audience>): void {
        state.audienceList = payload;
    },
};

export const audienceList: Module<IAudienceListState, IRootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
