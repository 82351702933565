<template>
    <transition name="fade">
        <div v-if="isOpen" class="dropdown" @click="$emit('click', $event)">
            <slot />
        </div>
    </transition>
</template>

<script>
export default {
    name: 'DropDown',
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.dropdown {
    padding: 4px 0;
    box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px $drop-down-border-color;
    background-color: $drop-down-bg-color;
}

.fade-enter-active {
    animation: fade-in 0.2s;
}

.fade-leave-active {
    animation: fade-in 0.2s reverse;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>
