<template>
    <div class="form-group">
        <div
            class="form-field"
            :class="{
                'form-field--is-focused': isFocused,
                'form-field--has-error': errors.length > 0,
                'form-field--is-disabled': disabled && !readonly,
                'form-field--is-readonly': readonly,
            }"
        >
            <label class="form-field-label">
                <span>{{ label }}</span>
            </label>
            <div class="editor">
                <EditorMenuBar v-slot="{ commands, isActive, getMarkAttrs }" :editor="editor">
                    <div class="menubar p-t-1 p-b-1">
                        <button
                            v-if="menuItems.includes(EditorInputMenuItem.Bold)"
                            class="menubar__button m-l-1"
                            :class="{ 'is-active': isActive.bold() }"
                            @click="commands.bold"
                        >
                            <Icon name="bold-icon" :size="14" />
                        </button>

                        <button
                            v-if="menuItems.includes(EditorInputMenuItem.Italic)"
                            class="menubar__button"
                            :class="{ 'is-active': isActive.italic() }"
                            @click="commands.italic"
                        >
                            <Icon name="italics-icon" :size="14" />
                        </button>

                        <button
                            v-if="menuItems.includes(EditorInputMenuItem.Strike)"
                            class="menubar__button"
                            :class="{ 'is-active': isActive.strike() }"
                            @click="commands.strike"
                        >
                            <Icon name="strikethrough-icon" :size="14" />
                        </button>

                        <button
                            v-if="menuItems.includes(EditorInputMenuItem.Underline)"
                            class="menubar__button"
                            :class="{ 'is-active': isActive.underline() }"
                            @click="commands.underline"
                        >
                            <Icon name="underline-icon" :size="14" />
                        </button>

                        <button
                            v-if="menuItems.includes(EditorInputMenuItem.BulletList)"
                            class="menubar__button"
                            :class="{ 'is-active': isActive.bullet_list() }"
                            @click="commands.bullet_list"
                        >
                            <Icon name="bulleted-list-icon" :size="14" />
                        </button>

                        <Dialog
                            show-confirm-button
                            confirm-button-text="Save"
                            close-button-text="Cancel"
                            :is-dialog-visible="linkMenuIsActive"
                            @onClose="setLinkUrl(commands.link, null)"
                            @onClickOutside="linkMenuIsActive = false"
                            @closeOnEscapeEvent="linkMenuIsActive = false"
                            @confirmButtonOnClick="setLinkUrl(commands.link, linkUrl)"
                        >
                            <template #header>Add Link</template>
                            <template #body>
                                <Input
                                    id="link-text"
                                    has-margin-top
                                    :has-margin-bottom="false"
                                    type="text"
                                    name="link-text"
                                    label="Link"
                                    :value="linkUrl"
                                    placeholder="https://"
                                    @input="onInput"
                                />
                            </template>
                        </Dialog>

                        <button
                            v-if="menuItems.includes(EditorInputMenuItem.Link)"
                            class="menubar__button"
                            :class="{ 'is-active': isActive.link() }"
                            @click="showLinkMenu(getMarkAttrs('link'))"
                        >
                            <Icon
                                name="add-link-icon-hover"
                                :size="14"
                                class="menubar__link-icon"
                            />
                        </button>
                    </div>
                </EditorMenuBar>

                <EditorContent class="editor__content form-field-input" :editor="editor" />
            </div>
        </div>
        <div v-if="showAdditionalInfo" class="form-field-additional-information">
            <div class="form-field-additional-information__left">
                <ErrorMessage v-if="errors && errors.length > 0" :message="errors[0]" />
                <div v-if="infoText" class="form-field-info-text">{{ infoText }}</div>
            </div>
            <CharCounter v-if="showRemainingChar" :count="characterCount" :max="max" />
        </div>
    </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
    Bold,
    Italic,
    Strike,
    Underline,
    HardBreak,
    BulletList,
    ListItem,
    History,
} from 'tiptap-extensions';
import debounce from 'lodash.debounce';
import Icon from '@/components/ui/Icon';
import Dialog from '@/components/ui/Dialog';
import Input from '@/components/forms/Input';
import CharCounter from '@/components/forms/partials/CharCounter';
import ErrorMessage from '@/components/forms/partials/ErrorMessage';
import CustomLink from '@/utils/tiptap/customLink.js';
import PlainText from '@/utils/tiptap/plainText.js';
import { EditorInputMenuItem } from '@/store/enums';

export default {
    name: 'EditorInput',
    components: {
        EditorContent,
        EditorMenuBar,
        Icon,
        Dialog,
        Input,
        CharCounter,
        ErrorMessage,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        max: {
            type: Number,
            default: undefined,
        },
        showRemainingChar: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        infoText: {
            type: String,
            default: undefined,
        },
        errors: {
            type: Array,
            default: () => [],
        },
        content: {
            type: String,
            default: '',
        },
        menuItems: {
            type: Array,
            default: () => [
                EditorInputMenuItem.Bold,
                EditorInputMenuItem.Italic,
                EditorInputMenuItem.Strike,
                EditorInputMenuItem.Underline,
                EditorInputMenuItem.BulletList,
                EditorInputMenuItem.Link,
            ],
        },
    },
    data() {
        return {
            editor: new Editor({
                editable: !this.disabled && !this.readonly,
                extensions: [
                    new CustomLink(),
                    new Bold(),
                    new HardBreak(),
                    new Italic(),
                    new Strike(),
                    new Underline(),
                    new BulletList(),
                    new ListItem(),
                    new History(),
                    new PlainText({
                        onChange: debounce(this.plainTextOnChange, 250),
                    }),
                ],
                onUpdate: this.onUpdate.bind(this),
                onFocus: () => {
                    this.isFocused = true;
                },
                onBlur: () => {
                    this.isFocused = false;
                },
            }),
            linkUrl: null,
            linkMenuIsActive: false,
            characterCount: 0,
            isFocused: false,
        };
    },
    computed: {
        showAdditionalInfo() {
            return (
                (this.errors && this.errors.length > 0) || this.infoText || this.showRemainingChar
            );
        },
    },
    created() {
        this.EditorInputMenuItem = EditorInputMenuItem;
    },
    beforeDestroy() {
        this.editor.destroy();
    },
    mounted() {
        this.setContent();
    },
    methods: {
        setContent() {
            this.editor.setContent(this.content);
            this.editor.focus();
        },
        showLinkMenu(attrs) {
            this.linkUrl = attrs.href;
            this.linkMenuIsActive = true;
        },
        hideLinkMenu() {
            this.linkUrl = null;
            this.linkMenuIsActive = false;
        },
        setLinkUrl(command, url) {
            command({ href: url });
            this.hideLinkMenu();
        },
        onInput(value) {
            this.linkUrl = value;
        },
        onUpdate: debounce(function ({ getJSON, getHTML }) {
            const json = getJSON().content;
            let content = getHTML();

            if (Array.isArray(json) && json.length === 1 && !json[0].hasOwnProperty('content')) {
                content = '';
            }

            this.$emit('update', content);
        }, 250),
        plainTextOnChange(plainText) {
            this.characterCount = plainText.length;
            this.$emit('plainTextChange', plainText);
        },
    },
};
</script>

<style lang="scss" scoped>
.menubar {
    border-bottom: solid 1px $color-athens-gray;
    transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;

    &.is-hidden {
        visibility: hidden;
        opacity: 0;
    }

    &.is-focused {
        visibility: visible;
        opacity: 1;
        transition: visibility 0.2s, opacity 0.2s;
    }

    &__button {
        font-weight: bold;
        display: inline-flex;
        background: transparent;
        border: 0;
        color: $default-dark-color;
        padding: 5px 10px;
        border-radius: 3px;
        margin-right: 5px;
        cursor: pointer;

        &:hover {
            background-color: rgba($default-dark-color, 0.05);
        }

        &.is-active {
            background-color: rgba($default-dark-color, 0.1);
        }
    }
}
.editor {
    &__content {
        display: block;
        height: auto;
        min-height: 104px;
    }
}
</style>

<style lang="scss">
$editor-link-color: #34c5de;

.editor {
    strong {
        font-weight: bold;
    }
    em {
        font-style: italic;
    }
    .ProseMirror {
        display: block;
        height: auto;
        min-height: 104px;
    }
    a {
        color: $editor-link-color;
        &:hover {
            position: relative;
        }
        &:hover:after {
            background: $default-dark-color;
            border-radius: 5px;
            top: 22px;
            color: $default-light-color;
            content: attr(title);
            left: 20%;
            padding: 5px 15px;
            position: absolute;
            z-index: 98;
            width: 220px;
        }

        &:hover:before {
            transform: rotate(180deg);
            border: solid;
            border-color: $default-dark-color transparent;
            border-width: 6px 6px 0 6px;
            top: 16px;
            content: '';
            left: 50%;
            position: absolute;
            z-index: 99;
        }
    }
    ul {
        list-style-type: disc;
        padding-inline-start: 18px;
    }
}
</style>
