<template>
    <div class="mobile-navigation" :class="{ 'mobile-navigation--opened': isOpened }">
        <div class="mobile-navigation__top">
            <ul>
                <li v-for="item in activeMenus" :key="item.id" class="link-item">
                    <router-link
                        :to="item.to"
                        class="link"
                        @click.native="$emit('mobileNavigationItemOnClick', $event)"
                        >{{ item.title }}</router-link
                    >
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MobileNavigation',
    props: {
        isOpened: {
            type: Boolean,
            required: true,
        },
        menuItems: {
            type: Array,
            required: true,
        },
    },
    computed: {
        activeMenus() {
            const roles = this.$store.getters['auth/userRoles'];

            if (roles) {
                return this.menuItems.filter(function (menuItem) {
                    return roles.indexOf(menuItem.role) > -1;
                });
            } else {
                return [];
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.mobile-navigation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: $mobile-navigation-z-index;
    position: fixed;
    top: 60px;
    left: 0;
    height: 100%;
    width: 100%;
    background: $mobile-nav-background-color;
    transform: translateY(-120%);
    transition: transform 0.5s;

    @include media-query(medium) {
        display: none;
    }

    &__top {
        flex-grow: 0;
        overflow-y: auto;
        text-align: center;
    }

    &--opened {
        transform: translateY(0);
    }
}

.link {
    display: block;
    padding: 25px;
    font-family: $font-family-button;
    font-size: 20px;
    text-transform: uppercase;
}
</style>
