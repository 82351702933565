import { validate as validateClass, Length, ValidationError, ValidateIf } from 'class-validator';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum.ts';
import { AccordionItemBase } from './accordionItemBase';
import { ListMarker } from '../enums/listMarker.enum';
import { AccordionStyle } from '../enums/accordionStyle.enum';
import { getErrorMessage } from '@/store/services/validationService';
import { replaceTemplateVariablesForPreview } from '../services/convoMessageItemService';
import { SortingOrder } from '../enums';

export class TextGlobalResponse extends AccordionItemBase {
    constructor(items = []) {
        super(items);
        this.accordionStyle = AccordionStyle.SecondLevel;
        this.type = ConvoMessageItemType.TextGlobalResponse;
        this.title = 'GLOBAL RESPONSE';
        this.icon = 'global-response';
        this.markerType = ListMarker.None;
        this.message = '';
        this.isDraggable = false;
        this.isDeletable = false;
        this.sortingOrder = SortingOrder.None;
    }

    sortingOrder: SortingOrder;

    @ValidateIf((o) => o.message && o.message.length > 0)
    @Length(0, 640, { message: getErrorMessage('length', 'Global response', { min: 0, max: 640 }) })
    message: string;

    setTitle(): void {
        this.subtitle = replaceTemplateVariablesForPreview(this.message, this.sortingOrder);
    }

    async validate(): Promise<any> {
        const errors: ValidationError[] = await validateClass(this);
        const errorMessages: any = {};

        if (errors.length > 0) {
            errors.forEach((item: ValidationError) => {
                errorMessages[item.property] = Object.values(item.constraints || {});
            });
        }

        return errorMessages;
    }
}
