var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],staticClass:"form-group select",class:[
        {
            'form-group--has-m-b': _vm.hasMarginBottom,
            'select--is-open': _vm.isOpen,
        } ]},[_c('div',{staticClass:"form-field",class:{
            'form-field--is-focused': _vm.isOpen,
            'form-field--has-error': _vm.errors.length > 0,
            'form-field--has-clear-icon': _vm.showClearIcon,
            'form-field--is-disabled': _vm.disabled,
            'form-field--has-bottom-space': _vm.hasMarginBottom,
        },on:{"click":_vm.toggleOptions}},[_c('div',{staticClass:"form-field-label",class:{ 'form-field-label--hidden': _vm.hideLabel }},[_vm._t("label",function(){return [_c('span',[_vm._v(_vm._s(_vm.label))])]})],2),_c('div',{staticClass:"form-field-input"},[(_vm.selectedOption)?_c('span',[(_vm.hasIcon)?_c('span',{staticClass:"form-field-input__icon",class:_vm.selectedOption.iconCss}):_vm._e(),_vm._v(" "+_vm._s(_vm.selectedOption[_vm.nameKey])+" ")]):(_vm.placeholder)?_c('span',{staticClass:"form-field-placeholder"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e()]),(_vm.showClearIcon)?_c('button',{staticClass:"form-field-icon",on:{"click":_vm.clearValue}},[(_vm.innerValue)?_c('Icon',{attrs:{"name":"cross","size":14}}):_vm._e()],1):_vm._e(),(_vm.isLoading)?_c('Spinner',{staticClass:"spinner"}):_c('Icon',{staticClass:"arrow",attrs:{"name":"arrow-down","size":12}})],1),(_vm.showAdditionalInfo)?_c('div',{staticClass:"form-field-additional-information"},[_c('div',{staticClass:"form-field-additional-information__left"},[(_vm.errors && _vm.errors.length > 0)?_c('div',{staticClass:"form-field-error-message"},[_c('Icon',{attrs:{"name":"alert"}}),_vm._v(_vm._s(_vm.errors[0])+" ")],1):_vm._e(),(_vm.infoText)?_c('div',{staticClass:"form-field-info-text"},[_vm._v(_vm._s(_vm.infoText))]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"options",class:{ open: _vm.isOpen }},_vm._l((_vm.options),function(option){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(option.disabled ? ("" + (option.disabledText)) : ''),expression:"option.disabled ? `${option.disabledText}` : ''",modifiers:{"top":true}}],key:option[_vm.idKey],staticClass:"option",class:{ selected: option[_vm.idKey] === _vm.value, disabled: option.disabled },on:{"click":function($event){return _vm.selectOption($event, option.disabled, option[_vm.idKey])}}},[(_vm.hasSlotEnabled)?_vm._t("select",null,{"option":option}):_c('span',[_vm._v(_vm._s(option[_vm.nameKey]))])],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }