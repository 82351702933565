<template>
    <router-link v-if="to" :to="to" :class="classArray" v-bind="$attrs" @click.native="onClick">
        <div
            v-if="linkStyle === linkStyles.DarkAccordionHeader && leftIcon"
            class="dark-accordion-header__icon"
        >
            <Icon :name="leftIcon" class="left-icon" />
        </div>
        <Icon v-else-if="leftIcon" :name="leftIcon" class="left-icon" />

        <span class="link-text">
            <slot />
        </span>
        <Icon v-if="rightIcon" :name="rightIcon" class="right-icon" />
        <Icon v-if="hasRightArrow" name="arrow-right" class="arrow-right-icon" />
    </router-link>
    <a v-else-if="href" :href="href" :class="classArray" v-bind="$attrs" @click="onClick">
        <Icon v-if="leftIcon" :name="leftIcon" class="left-icon" />
        <span class="link-text">
            <slot />
        </span>
        <Icon v-if="rightIcon" :name="rightIcon" class="right-icon" />
        <Icon v-if="hasRightArrow" name="arrow-right" class="arrow-right-icon" />
    </a>
</template>

<script>
import onlyValues from '@/utils/onlyValues.ts';
import Icon from '@/components/ui/Icon';
import { LinkStyle as linkStyles } from '@/store/enums/linkStyle.enum.ts';

export default {
    name: 'Link',
    components: {
        Icon,
    },
    props: {
        linkStyle: {
            type: String,
            default: linkStyles.Primary,
            validator: onlyValues(Object.values(linkStyles)),
        },
        to: {
            type: [String, Object],
            default: undefined,
        },
        href: {
            type: String,
            default: undefined,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        leftIcon: {
            type: String,
            default: undefined,
        },
        rightIcon: {
            type: String,
            default: undefined,
        },
        hasRightArrow: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            linkStyles,
        };
    },
    computed: {
        classArray() {
            return [
                this.class,
                'link',
                `link--style-${this.linkStyle}`,
                {
                    'link--full-width': this.fullWidth,
                    'link--disabled': this.disabled,
                    'link--has-right-arrow': this.hasRightArrow,
                },
            ];
        },
    },
    methods: {
        onClick: function (event) {
            if (!this.disabled) {
                this.$emit('click', event);
            } else {
                event.preventDefault();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.link {
    &--full-width {
        width: 100%;
    }

    &--disabled {
        opacity: 0.25;
        cursor: default;
        pointer-events: none;
    }

    &--style-primary {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 40px;
        min-width: 120px;
        padding: 1px 16px 0;
        font-family: $font-family-button;
        font-size: 12px;
        font-weight: $font-weight-bold;
        text-transform: uppercase;

        .left-icon,
        .right-icon {
            font-size: 16px;
        }
    }

    &--style-dark-accordion-header {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        height: 52px;
        min-width: 120px;
        padding: 1px 16px 0;
        border-radius: 4px;
        background: $dark-accordion-header-button-background-color;
        font-family: $font-family-default;
        font-size: 14px;
        font-weight: $font-weight-default;
        color: $dark-accordion-header-button-text-color;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        line-height: 1.2em;
        letter-spacing: normal;
        cursor: pointer;
        transition: background 0.3s, border-color 0.3s, color 0.3s;

        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.03);
        border: solid 1px $dark-accordion-header-button-border-color;

        &:hover {
            background: $dark-accordion-header-button-hover-background-color;
        }
    }

    &--has-right-arrow {
        padding-right: 40px;
    }
}

.left-icon {
    flex: 0 0 auto;
    font-size: 20px;
    margin-right: 9px;
}

.right-icon {
    flex: 0 0 auto;
    font-size: 20px;
    margin-left: 9px;
}

.arrow-right-icon {
    position: absolute;
    right: 16px;
    font-size: 10px;
    margin-left: 13px;
}

.dark-accordion-header__icon {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    margin-right: 10px;
    border-radius: 36px;
    background: $container-default-second-color;
    font-size: 28px;
    color: $dark-accordion-header-button-icon-color;

    .left-icon {
        margin-right: 0;
    }
}
</style>
