<template>
    <Draggable
        v-if="isDraggable"
        v-model="dragAndDropItems"
        :tag="tag"
        :animation="animation"
        v-bind="$attrs"
        @start="$emit('onDragStart')"
        @end="$emit('onDragEnd')"
    >
        <slot />
        <slot slot="header" name="header" />
        <slot slot="footer" name="footer" />
    </Draggable>
    <DynamicHtmlTag v-else :tag="tag">
        <slot />
    </DynamicHtmlTag>
</template>

<script>
import Draggable from 'vuedraggable';
import DynamicHtmlTag from '@/components/ui/DynamicHtmlTag';

export default {
    name: 'DragAndDrop',
    components: {
        Draggable,
        DynamicHtmlTag,
    },
    props: {
        isDraggable: {
            type: Boolean,
            default: true,
        },
        tag: {
            type: String,
            default: 'div',
        },
        animation: {
            type: Number,
            default: 200,
        },
        value: {
            type: Array,
            required: true,
        },
    },
    computed: {
        dragAndDropItems: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>
