<template>
    <button
        type="button"
        class="icon-button"
        :disabled="disabled"
        @click.stop="$emit('click', $event)"
    >
        <Icon v-if="icon" :name="icon" />
    </button>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    name: 'IconButton',
    components: { Icon },
    props: {
        icon: {
            type: String,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.icon-button {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: $icon-button-text-color;
    cursor: pointer;
    transition: background-color 200ms, color 200ms;

    &:hover:not([disabled]) {
        color: $icon-button-text-hover-color;
        background: $icon-button-bg-hover-color;
    }

    &:disabled {
        opacity: 0.5;
        cursor: initial;
    }
}
</style>
