<template>
    <div class="toggle-text">
        <div class="toggle-text__inner" :style="{ '--font-size': `${fontSize}px` }">
            <input :id="id" v-model="innerChecked" type="checkbox" class="toggle-text__input" />

            <label
                :for="id"
                class="toggle-text__label"
                :class="{
                    'disabled-toggle': disabled,
                }"
                :style="{
                    '--toggle-height': `${toggleHeight}px`,
                    '--toggle-width': `${toggleWidth}px`,
                }"
            >
                <div
                    class="toggle-text__switch"
                    :class="{
                        'has-icon': showIcons,
                    }"
                >
                    <div
                        :class="{
                            'switch-off': innerChecked,
                            'switch-on': !innerChecked,
                            'switch-content': !iconAside,
                            'switch-content-aside': iconAside,
                        }"
                    >
                        <Icon
                            v-if="showIcons && iconOff !== ''"
                            class="switch-icon"
                            :size="iconSize"
                            :name="iconOff"
                        />
                        <div class="switch-text" :class="{ 'only-text': !showIcons }">
                            {{ textOff }}
                        </div>
                    </div>
                    <div
                        :class="{
                            'switch-on': innerChecked,
                            'switch-off': !innerChecked,
                            'switch-content': !iconAside,
                            'switch-content-aside': iconAside,
                        }"
                    >
                        <Icon
                            v-if="showIcons && iconOn !== ''"
                            class="switch-icon"
                            :size="iconSize"
                            :name="iconOn"
                        />
                        <div class="switch-text" :class="{ 'only-text': !showIcons }">
                            {{ textOn }}
                        </div>
                    </div>
                </div>
            </label>
        </div>

        <div v-if="errors && errors.length > 0" class="error-message">{{ errors[0] }}</div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
export default {
    name: 'ToggleText',
    components: {
        Icon,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        value: {
            type: Boolean,
            required: true,
            default: false,
        },
        errors: {
            type: Array,
            default: () => [],
        },
        textOn: {
            type: String,
            default: 'On',
        },
        textOff: {
            type: String,
            default: 'Off',
        },
        showIcons: {
            type: Boolean,
            default: false,
        },
        iconOn: {
            type: String,
            default: '',
        },
        iconSize: {
            type: Number,
            default: 16,
        },
        iconOff: {
            type: String,
            default: '',
        },
        fontSize: {
            type: Number,
            default: 10,
        },
        iconAside: {
            type: Boolean,
            default: false,
        },
        toggleWidth: {
            type: Number,
            default: 200,
        },
        toggleHeight: {
            type: Number,
            default: 34,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            checkedValue: this.value,
        };
    },
    computed: {
        innerChecked: {
            get() {
                return this.checkedValue;
            },
            set(value) {
                this.checkedValue = value;
                this.$emit('input', value);
            },
        },
    },
    watch: {
        checkedValue(data) {
            this.$emit('changed', data);
        },
        value(data) {
            this.checkedValue = data;
        },
    },
};
</script>

<style lang="scss" scoped>
$toggle-text-icon-color: #24b2c3 !default;
$toggle-text-background-color: #edeff5;
$toggle-text-inactive-color: $alternate-text-color;
$toggle-text-switch-font-size: var(--font-size);
$toggle-text-border-radius: 60px;
$toggle-text-height: var(--toggle-height);
$toggle-text-offset: 2px;

.toggle-text {
    &__inner {
        position: relative;
    }

    &__label {
        font-size: $toggle-text-switch-font-size;
        display: block;
        cursor: pointer;
        width: var(--toggle-width);
    }

    &__input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;

        &:checked ~ .toggle-text__label .toggle-text__switch {
            .switch-off {
                left: 0;
                .switch-text {
                    padding-bottom: $toggle-text-offset;
                }
            }

            .switch-on {
                left: calc(var(--toggle-width) - calc(var(--toggle-width) / 2));
            }
        }
    }

    &__switch {
        border-radius: $toggle-text-border-radius;
        height: $toggle-text-height;
        flex: 0 0 var(--toggle-width);
        position: relative;
        background-color: $toggle-text-background-color;

        .switch-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .switch-icon {
                flex: 0 0 55%;
            }
        }
        .switch-content-aside {
            height: 95%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .switch-icon {
                flex: 0 0 20%;
            }
        }
        .switch-off {
            color: $toggle-text-inactive-color;
            left: calc(var(--toggle-width) / 2);
            font-size: $toggle-text-switch-font-size;
            width: calc(var(--toggle-width) / 2);
            padding: 0 12px;
            position: absolute;
            top: 0;
            text-transform: uppercase;
            text-align: center;
            .switch-text {
                padding-bottom: $toggle-text-offset;
            }
        }

        .switch-on {
            position: absolute;
            z-index: 5;
            top: $toggle-text-offset;
            left: $toggle-text-offset;
            border-radius: $toggle-text-border-radius/2;
            width: calc(calc(var(--toggle-width) / 2) - 2px);
            font-size: $toggle-text-switch-font-size;
            text-transform: uppercase;
            text-align: center;
            background-color: #ffffff;
            height: calc(var(--toggle-height) - 4px);
            .switch-icon {
                color: $toggle-text-icon-color;
            }
            .switch-text {
                color: $default-text-color;
            }
        }
    }
    .switch-on .only-text {
        color: $select-alternate-text-color;
    }

    .error-message {
        padding-left: 60px;
        font-size: 12px;
        font-style: italic;
        color: $error-color;
    }
    .disabled-toggle {
        pointer-events: none;
        cursor: none;
    }
}
</style>
