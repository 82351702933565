import { replaceTemplateVariablesForPreview } from '@/store/services/convoMessageItemService';
import { IContentItem, DateRangeType, DateType } from '@/store/models/interfaces';
import { DatePicker, TextGlobalResponse } from '@/store/models';
import { sanitize } from '@/utils/sanitizer';

export class DatePickerAccordionItemFactory {
    public make(contentItem: IContentItem): DatePicker {
        const datePicker = new DatePicker();
        datePicker.subtitle = contentItem.title;
        datePicker.message = contentItem.title;
        datePicker.id = contentItem.id || 0;
        datePicker.sort = contentItem.sort;
        datePicker.title = 'DatePicker';

        const plainTextStatement = sanitize(contentItem.title);

        datePicker.subtitle = plainTextStatement;
        datePicker.plainText = plainTextStatement;

        if (contentItem.validationRules) {
            datePicker.submitButtonText = contentItem.validationRules.submitButtonText as string;
            datePicker.dateType = contentItem.validationRules.dateType as DateType;
            datePicker.dateRangeType =
                contentItem.validationRules.dateRangeType || DateRangeType.Days;
            datePicker.dateRangeValue = contentItem.validationRules.dateRangeValue || 0;
            datePicker.dateRangeValueOffset = contentItem.validationRules.dateRangeValueOffset || 0;
        }

        if (contentItem.externalId) {
            datePicker.externalId = contentItem.externalId;
            datePicker.datumDefinition = contentItem.externalId;
        }

        const globalResponse = new TextGlobalResponse();
        globalResponse.hasMarginBottom = false;
        if (contentItem.reply?.message) {
            globalResponse.message = contentItem.reply.message;
            globalResponse.subtitle = replaceTemplateVariablesForPreview(
                contentItem.reply.message,
                contentItem.reply.sortingOrder,
            );
            globalResponse.sortingOrder = contentItem.reply.sortingOrder;
        }
        datePicker.items = [globalResponse];
        datePicker.tags = contentItem.tags;
        datePicker.isPdl = !!contentItem.isPdl;
        datePicker.shouldSkipIfAnswered = !!contentItem.shouldSkipIfAnswered;

        return datePicker;
    }
}
