<template>
    <div
        class="convo-preview-message-wrapper"
        :class="{ 'convo-preview-message-wrapper--active': isActive }"
    >
        <div
            v-for="(msg, index) in message.messages"
            :key="index"
            class="convo-preview-message"
            :class="{ 'convo-preview-message--has-reply': msg.type === MessageType.Reply }"
        >
            <SimpleText v-if="msg.type === MessageType.SimpleText" :item="msg" />
            <QuickReply
                v-else-if="msg.type === MessageType.QuickReply"
                :item="msg"
                :highlighted-message="isActive"
            />
            <Attachment v-else-if="msg.type === MessageType.Attachment" :item="msg" />
            <Video v-else-if="msg.type === MessageType.Video" :item="msg" />
            <Buttons v-else-if="msg.type === MessageType.Buttons" :item="msg" />
            <Buttons v-else-if="msg.type === MessageType.Rating" :item="msg" />
            <Carousel
                v-else-if="msg.type === MessageType.Carousel"
                :messages="msg.messages"
                :show-avatar="msg.avatarUrl ? true : false"
                :avatar-url="msg.avatarUrl"
                :highlighted-message="isActive"
            />
            <Reply v-else-if="msg.type === MessageType.Reply" :item="msg" />
            <CommentsRating v-else-if="msg.type === MessageType.CommentsRating" :item="msg" />
            <div v-else>{{ msg.text }}</div>
        </div>
    </div>
</template>

<script>
import SimpleText from '@/../messages/src/components/SimpleText';
import QuickReply from '@/../messages/src/components/QuickReply';
import Attachment from '@/../messages/src/components/Attachment';
import Video from '@/../messages/src/components/Video';
import Buttons from '@/../messages/src/components/Buttons';
import Reply from '@/../messages/src/components/Reply';
import Carousel from '@/../messages/src/components/Carousel';
import CommentsRating from '@/../messages/src/components/CommentsRating';
import { MessageType } from '@/../messages/src/models/enums/messageType.enum.ts';

export default {
    name: 'ConvoPreviewMessage',
    components: {
        SimpleText,
        QuickReply,
        Attachment,
        Buttons,
        Reply,
        Carousel,
        Video,
        CommentsRating,
    },
    props: {
        message: {
            type: Object,
            required: true,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            MessageType,
        };
    },
};
</script>

<style lang="scss" scoped>
.convo-preview-message-wrapper {
    &:first-child {
        margin-top: auto;
        padding-top: 8px;
    }

    &:last-child {
        padding-bottom: 8px;
    }

    &--active {
        background: $preview-message-active-bg-color;
    }
}

.convo-preview-message {
    padding: 5px 10px;

    @include media-query(medium) {
        padding-left: 6px;
        padding-right: 6px;
    }

    &--has-reply {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>
