<template>
    <div>
        <ButtonWrapper
            class="m-b-1"
            :is-draggable="isDraggable"
            :is-deletable="data.isDeletable"
            :is-pinned="!isDisabled && data.isPinned"
            :disable-button-menu="isDisabled"
            :marker="data.marker || marker"
            :has-attributes="hasAttributes"
            @deleteOnClick="$emit('deleteOnClick', $event, path)"
        >
            <template #header>
                <div class="quick-reply-answer-header">
                    <Select
                        v-if="$options.convoTypes.YougovWelcomeChat === currentConvo.type"
                        :id="generateId('datum-answer', path)"
                        class="answer-type-select m-b-2"
                        label="Datum Answer"
                        show-clear-icon
                        :options="yougovDefinitionOptions"
                        :value="data.datumResponseCode"
                        :has-margin-bottom="false"
                        :disabled="!yougovDefinitionOptions.length"
                        :is-loading="$wait.is($options.LoadingFlag.YougovDefinitionDetailsGet)"
                        @input="(value) => onInput('datumResponseCode', value)"
                        @changed="onChanged"
                    />
                    <Input
                        :id="generateId('buttonLabel', path)"
                        type="text"
                        label="Button Label"
                        show-remaining-char
                        :name="generateId('buttonLabel', path)"
                        :max="100"
                        :has-margin-bottom="false"
                        :value="data.buttonLabel"
                        :errors="data.errors.buttonLabel"
                        :disabled="isDisabled"
                        @input="onButtonLabelInput"
                        @changed="onChanged"
                    />
                </div>
            </template>
            <template #addAttributeButtons>
                <DropDownItem
                    icon="image"
                    :disabled="data.attributes.indexOf(Attributes.Media) > -1"
                    @click="addAttributeOnClick(Attributes.Media)"
                >
                    Add Media
                </DropDownItem>
                <DropDownItem
                    icon="logic-jump"
                    :disabled="isOptionDisabled(Attributes.LogicJump)"
                    :disabled-text="isMultipleChoice ? 'Not compatible with Multiple Choice' : ''"
                    @click="addAttributeOnClick(Attributes.LogicJump)"
                >
                    Logic jump
                </DropDownItem>
                <DropDownItem
                    icon="inline-response"
                    :disabled="isOptionDisabled(Attributes.InlineResponse)"
                    :disabled-text="isMultipleChoice ? 'Not compatible with Multiple Choice' : ''"
                    @click="addAttributeOnClick(Attributes.InlineResponse)"
                >
                    Inline response
                </DropDownItem>
            </template>
            <template #attributes>
                <div
                    v-if="data.attributes && data.attributes.length"
                    class="quick-reply-answer__attribute-buttons"
                >
                    <AttributeButton
                        v-if="data.attributes.indexOf(Attributes.Media) > -1"
                        class="quick-reply-answer__attribute-button"
                        :icon="!data.buttonImageUrl ? 'image' : undefined"
                        :image="data.buttonImageUrl"
                        @click="showImageUploader = true"
                        @remove="attributeOnRemove(Attributes.Media)"
                    >
                        Image
                    </AttributeButton>
                    <AttributeButton
                        v-if="data.attributes.indexOf(Attributes.LogicJump) > -1"
                        class="quick-reply-answer__attribute-button"
                        icon="logic-jump"
                        @click="$emit('openLogicJump', path)"
                        @remove="attributeOnRemove(Attributes.LogicJump)"
                    >
                        Logic jump
                    </AttributeButton>
                    <AttributeButton
                        v-if="data.attributes.indexOf(Attributes.InlineResponse) > -1"
                        class="quick-reply-answer__attribute-button"
                        icon="inline-response"
                        @click="showInlineResponse = true"
                        @remove="attributeOnRemove(Attributes.InlineResponse)"
                    >
                        Inline response
                    </AttributeButton>
                </div>
            </template>
            <template>
                <Checkbox
                    v-if="isTriviaQuiz"
                    :id="generateId('is-correct', path)"
                    has-margin-bottom
                    label="This is a correct answer"
                    :value="data.isCorrectAnswer"
                    :disabled="ignoreQuizQuestion || isDisabled"
                    @input="(value) => onInput('isCorrectAnswer', value)"
                    @changed="onChanged"
                />
                <div v-if="isMultipleChoice">
                    <Checkbox
                        :id="generateId('is-exclusive', path)"
                        :value="data.isExclusive"
                        class="checkbox"
                        label="Exclusive"
                        :disabled="isToggleDisabled || isExternalPdl"
                        @input="(value) => onInput('isExclusive', value)"
                        @changed="onChanged"
                    />
                </div>
                <div v-if="isPersonalityQuiz" class="associate-result">
                    <Select
                        v-model="data.selectedPersonalityQuizResult"
                        class="associate-result-select"
                        label="Personality Quiz Result"
                        :value="data.selectedPersonalityQuizResult"
                        :options="personalityQuizResults"
                        :disabled="!isAssociateResultEnabled || isDisabled"
                        :errors="data.errors.selectedPersonalityQuizResult"
                        @input="onPersonalityQuizResultInput"
                        @changed="onChanged"
                    />
                    <IconButton
                        icon="award"
                        class="associate-result__button"
                        :disabled="isDisabled"
                        @click="personalityQuizResultsButtonOnClick"
                    />
                </div>
            </template>
        </ButtonWrapper>

        <Overlay
            disable-body-scroll
            show-close-button
            :show="showImageUploader"
            @onClose="showImageUploader = false"
        >
            <ImageUploadOverlay
                embedded
                image-name-prefix="image"
                @imageUploaded="onImageUploaded"
                @cancelButtonOnClick="showImageUploader = false"
            />
        </Overlay>

        <InlineResponse
            :show="showInlineResponse"
            :data="data.items[0]"
            :path="[...path, 0]"
            @close="showInlineResponse = false"
            @propertyOnChange="$emit('propertyOnChange', $event)"
            @addItemOnClick="(event, path, type) => $emit('addItemOnClick', event, path, type)"
            @deleteOnClick="
                (event, path, parentPath) => $emit('deleteOnClick', event, path, parentPath)
            "
        />
    </div>
</template>

<script>
// TODO: no access to store here
import { mapGetters } from 'vuex';
import ImageUploadOverlay from '@/views/ImageUploadOverlay';
import InlineResponse from '@/components/InlineResponse';
import { Input, NumberInput, Checkbox, Select } from '@/components/forms';
import ButtonWrapper from '@/components/ui/ButtonWrapper';
import IconButton from '@/components/ui/IconButton';
import DropDownItem from '@/components/ui/DropDown/DropDownItem';
import AttributeButton from '@/components/ui/AttributeButton';
import Overlay from '@/components/ui/Overlay';
import itemFormMixin from '@/mixins/itemFormMixin';
import { ConvoTypes, Attributes, LoadingFlag } from '@/store/enums';

export default {
    name: 'QuickReplyAnswer',
    components: {
        ImageUploadOverlay,
        InlineResponse,
        ButtonWrapper,
        Input,
        Select,
        Checkbox,
        IconButton,
        DropDownItem,
        AttributeButton,
        Overlay,
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        isDraggable: {
            type: Boolean,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        marker: {
            type: String,
            required: true,
        },
        ignoreQuizQuestion: {
            type: Boolean,
            default: false,
        },
        currentChannel: {
            type: Object,
            default: undefined,
        },
        currentConvo: {
            type: Object,
            default: () => ({}),
        },
        isMultipleChoice: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        yougovDefinitionResponses: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            Attributes,
            showImageUploader: false,
            showInlineResponse: false,
        };
    },
    computed: {
        ...mapGetters('convoDetails', {
            convoDetailsView: 'convoDetailsView',
            personalityQuizResults: 'personalityQuizResults',
        }),
        isPersonalityQuiz() {
            return this.convoDetailsView.convo.type === ConvoTypes.PersonalityQuiz;
        },
        isTriviaQuiz() {
            return this.convoDetailsView.convo.type === ConvoTypes.TriviaQuiz;
        },
        isAssociateResultEnabled() {
            return (
                this.personalityQuizResults &&
                this.personalityQuizResults.length > 0 &&
                this.ignoreQuizQuestion === false
            );
        },
        hasAttributes() {
            return this.data.attributes && this.data.attributes.length ? true : false;
        },
        yougovDefinitionOptions() {
            return this.yougovDefinitionResponses.map((item) => ({
                ...item,
                id: item.code,
                value: `${item.code} - ${item.description}`,
            }));
        },
    },
    created() {
        this.$options.convoTypes = ConvoTypes;
        this.$options.LoadingFlag = LoadingFlag;
    },
    methods: {
        onButtonLabelInput(value) {
            this.onInput('buttonLabel', value);
        },
        onImageUploaded(response) {
            this.onInput('buttonImageUrl', response.url);
            this.showImageUploader = false;
        },
        onPersonalityQuizResultInput(value) {
            this.onInput('selectedPersonalityQuizResult', value);
        },
        attributeOnRemove(value) {
            this.$emit('removeAttribute', { path: this.path, value });
        },
        addAttributeOnClick(value) {
            this.$emit('addAttribute', { path: this.path, value });
        },
        personalityQuizResultsButtonOnClick() {
            this.$router.push({
                name: 'results',
                params: { channelCode: this.currentChannel.code, convoId: this.currentConvo.id },
            });
        },
        isOptionDisabled(attribute) {
            return this.isMultipleChoice || this.data.attributes.indexOf(attribute) > -1;
        },
    },
};
</script>
<style lang="scss" scoped>
.quick-reply-answer-header {
    flex: 1 1 auto;
    padding: 10px 0;
}

.quick-reply-answer {
    &__attribute-buttons {
        position: relative;
        top: -5px;
        margin-left: -5px;
        margin-bottom: 10px;
    }

    &__attribute-button {
        margin: 5px;
    }
}

.associate-result {
    position: relative;

    &__button {
        position: absolute;
        top: (36px - 22px) / 2; // (input height - button height) / 2
        right: -30px;
    }
}
</style>
