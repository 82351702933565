import { MessengerValidationTypes } from '@/enums';
import { PostValidationType } from '@/store/enums';

export enum DateRangeType {
    Days = 'date_range_day',
    Months = 'date_range_month',
    Years = 'date_range_year',
}

export enum DateType {
    Any = 'date_any',
    Past = 'date_past',
    Future = 'date_future',
    PastRange = 'date_past_range',
    FutureRange = 'date_future_range',
}

export interface IValidationRulesRegex {
    base: string;
    modifiers: string[];
}

export interface IValidationRules {
    submitButtonText?: string;
    dateType?: DateType;
    dateRangeType?: DateRangeType;
    dateRangeValue?: number;
    dateRangeValueOffset?: number;
    code?: MessengerValidationTypes;
    regex?: IValidationRulesRegex;
    regexModifier?: string;
    errorMessage?: string;
    allowSkip?: boolean;
    postValidation?: PostValidationType;
}
