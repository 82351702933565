var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-field",class:{
            'form-field--is-focused': _vm.isFocused,
            'form-field--has-error': _vm.errors.length > 0,
            'form-field--has-icon': _vm.showDefaultIcon,
            'form-field--is-disabled': _vm.disabled,
            'form-field--has-bottom-space': _vm.hasMarginBottom,
            'form-field--has-top-space': _vm.hasMarginTop,
        }},[_c('label',{staticClass:"form-field-label",class:{ 'form-field-label--hidden': _vm.hideLabel },attrs:{"for":_vm.id}},[_c('span',[_vm._v(_vm._s(_vm.label))])]),_c('div',{staticClass:"input-number-wrapper"},[_c('IconButton',{staticClass:"spin-controls spin-controls--minus",attrs:{"icon":"numeric-minus","disabled":_vm.disabled},on:{"click":_vm.onValueDecrease}}),_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],ref:"input",staticClass:"form-field-input number-input__input",attrs:{"id":_vm.id,"type":"number","max":_vm.max,"min":_vm.min,"step":_vm.step,"name":_vm.name,"maxlength":_vm.max,"placeholder":_vm.placeholder,"disabled":_vm.disabled},domProps:{"value":(_vm.innerValue)},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"change":function($event){return _vm.$emit('change', $event)},"input":function($event){if($event.target.composing){ return; }_vm.innerValue=$event.target.value}}},'input',_vm.$attrs,false)),_c('IconButton',{staticClass:"spin-controls",attrs:{"icon":"numeric-plus","disabled":_vm.disabled},on:{"click":_vm.onValueIncrease}})],1),(_vm.showDefaultIcon)?_c('div',{staticClass:"form-field-icon"},[_c('Icon',{attrs:{"name":_vm.defaultIcon,"size":_vm.defaultIconSize}})],1):_vm._e()]),(_vm.showAdditionalInfo)?_c('div',{staticClass:"form-field-additional-information"},[_c('div',{staticClass:"form-field-additional-information__left"},[(_vm.errors && _vm.errors.length > 0)?_c('div',{staticClass:"form-field-error-message"},[_c('Icon',{attrs:{"name":"alert"}}),_vm._v(" "+_vm._s(_vm.errors[0])+" ")],1):_vm._e(),(_vm.infoText)?_c('div',{staticClass:"form-field-info-text"},[_vm._v(_vm._s(_vm.infoText))]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }