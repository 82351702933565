import { LoadingFlag } from '@/store/enums';
import axios from '@/utils/request';
import { ConvoScheduleRequest } from '@/store/models/dtos/convoScheduleRequests.dto';
import { IConvoScheduleDto } from '@/store/models/interfaces/convoSchedule.interface';

export class EmailAutomationClient {
    public static async createConvoSchedule(
        channelCode: string,
        data: ConvoScheduleRequest,
    ): Promise<IConvoScheduleDto> {
        const result = await axios.request<any>({
            url: `/email-automation/${channelCode}`,
            method: 'POST',
            data,
            errorMessage: 'Could not create convo schedule',
            loadingId: LoadingFlag.ConvoScheduleCreate,
        });
        return result as unknown as Promise<IConvoScheduleDto>;
    }

    public static async deleteConvoSchedule(channelCode: string): Promise<void> {
        const result = await axios.request<any>({
            url: `/email-automation/${channelCode}`,
            method: 'DELETE',
            errorMessage: 'Could not delete convo schedule',
            loadingId: LoadingFlag.ConvoScheduleDelete,
        });
        return result as unknown as Promise<void>;
    }
}
