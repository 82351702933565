import { IContentItem, IMultipleChoiceOption, IResultOption } from '../interfaces';
import {
    Buttons,
    ContentItem,
    ButtonsAnswer,
    TextGlobalResponse,
    InlineResponseTextStatement,
} from '..';
import {
    ContentItemType,
    LinkTarget,
    ChartType,
    SortingOrder,
    AnswerType,
    ConvoTypes,
} from '@/store/enums/';
import { IAnswer } from '../interfaces/answer.interface.dto';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum';
import { IWidgetReply } from '../interfaces/widgetReply.interface.dto';
import { Answer } from '../answer.dto';
import { IConvoState } from '@/store/modules/convo-details';
import { makePersonalityQuizAttr, makeTriviaQuizAttr } from './contentItemsRequest.factory';

export class ButtonsContentItemFactory {
    public make(obj: Buttons, state: IConvoState): IContentItem {
        const contentItem = new ContentItem();
        contentItem.id = obj.id;
        contentItem.sort = obj.sort;
        contentItem.title = obj.statement;
        contentItem.type = ContentItemType.Buttons;
        contentItem.multipleChoice = obj.isMultipleChoice;
        contentItem.tags = obj.tags || [];
        contentItem.isPdl = obj.isPdl;
        contentItem.shouldSkipIfAnswered = obj.shouldSkipIfAnswered;
        contentItem.answers = [];

        if (state.convo.type === ConvoTypes.YougovWelcomeChat) {
            contentItem.externalId = obj.datumDefinition || '';
        } else {
            contentItem.externalId = obj.externalId || '';
        }

        // TODO: think about a better solution instead of accessing first item of the array
        const answers = obj.items[0].items.filter(
            (i) => i.type === ConvoMessageItemType.ButtonsAnswer,
        );

        for (const buttonsAnswer of answers as ButtonsAnswer[]) {
            const answer: IAnswer = new Answer();
            answer.convoId = state.convo.id;
            answer.id = buttonsAnswer.buttonId;
            answer.type = buttonsAnswer.answerType.name;
            answer.text = buttonsAnswer.buttonLabel;
            answer.isExclusive = buttonsAnswer.isExclusive;
            if (state.convo.type === ConvoTypes.YougovWelcomeChat) {
                answer.externalId = buttonsAnswer.datumResponseCode || '';
            } else {
                answer.externalId = buttonsAnswer.externalId || '';
            }

            answer.link = buttonsAnswer.answerType.linkRequired ? buttonsAnswer.link : '';
            answer.successDispositionLink =
                buttonsAnswer.answerType.linkRequired &&
                buttonsAnswer.answerType.name === AnswerType.LinkNextDisposition
                    ? buttonsAnswer.successDispositionLink
                    : '';
            answer.failureDispositionLink =
                buttonsAnswer.answerType.linkRequired &&
                buttonsAnswer.answerType.name === AnswerType.LinkNextDisposition
                    ? buttonsAnswer.failureDispositionLink
                    : '';

            if (buttonsAnswer.answerType.linkRequired) {
                answer.linkTarget = this.getLinkButtonTarget(buttonsAnswer);
            }

            // Logic jump
            answer.targetChannel = buttonsAnswer.logicJump?.items?.channel?.code || '';
            answer.targetConvo = buttonsAnswer.logicJump?.items?.convo?.id || 0;
            answer.targetContentItem = buttonsAnswer.logicJump?.items?.message?.id || 0;
            answer.goToBlock =
                !answer.targetContentItem && answer.targetConvo ? answer.targetConvo : 0;
            answer.goToWidget =
                answer.targetConvo && answer.targetContentItem ? answer.targetContentItem : 0;

            if (state.convo.type === ConvoTypes.PersonalityQuiz) {
                const personalityAttr = makePersonalityQuizAttr(obj, buttonsAnswer);
                answer.quizResultItem = personalityAttr.quizResultItem;
            }

            if (state.convo.type === ConvoTypes.TriviaQuiz) {
                answer.quizResultItem = undefined;

                const triviaAttr = makeTriviaQuizAttr(obj, buttonsAnswer);
                answer.ignoreQuizAnswer = triviaAttr.ignoreQuizAnswer;
                answer.isCorrectAnswer = triviaAttr.isCorrectAnswer;
            }

            // TODO: if we handle more response than one it will need a modification
            if (
                buttonsAnswer.items &&
                buttonsAnswer.items.length > 0 &&
                buttonsAnswer.items[0].items &&
                buttonsAnswer.items[0].items.length > 0
            ) {
                switch (buttonsAnswer.items[0].items[0].type) {
                    case ConvoMessageItemType.InlineResponseTextStatement:
                        answer.reply = (
                            buttonsAnswer.items[0].items[0] as InlineResponseTextStatement
                        ).statement;
                        break;
                }
            }

            contentItem.answers.push(answer);
        }

        const reply = obj.items[1].items.find(
            (i) => i.type === ConvoMessageItemType.TextGlobalResponse,
        ) as TextGlobalResponse;

        if (obj.isMultipleChoice) {
            contentItem.multipleChoiceOptions = {} as IMultipleChoiceOption;
            contentItem.multipleChoiceOptions.submitButtonText = obj.submitButtonText;
            contentItem.multipleChoiceOptions.layout = obj.layout;
            contentItem.multipleChoiceOptions.minLimit = obj.minLimit;
            contentItem.multipleChoiceOptions.maxLimit = obj.maxLimit;
        }

        contentItem.reply = {} as IWidgetReply;
        contentItem.reply.activated = !!reply.message || obj.showQuizResults;
        contentItem.reply.message = obj.showQuizResults ? '' : reply.message;
        contentItem.reply.sortingOrder =
            obj.resultOptions.chartType === ChartType.None ? reply.sortingOrder : SortingOrder.None;
        contentItem.reply.resultOptions = {} as IResultOption;
        contentItem.reply.resultOptions.chartType = obj.resultOptions.chartType;
        contentItem.reply.resultOptions.order = obj.resultOptions.order;
        contentItem.reply.resultOptions.showResponseCount =
            obj.resultOptions.chartType !== ChartType.None && obj.resultOptions.showResponseCount;
        contentItem.reply.tagLine = '';
        contentItem.reply.url = '';
        contentItem.reply.imageUrl = '';

        return contentItem;
    }

    private getLinkButtonTarget(buttonsAnswer: ButtonsAnswer): string {
        if (buttonsAnswer.linkOpenInNewTab) {
            return LinkTarget.Blank;
        }
        if (buttonsAnswer.answerType.name === AnswerType.Link) {
            return LinkTarget.Internal;
        }
        if (buttonsAnswer.answerType.name === AnswerType.ReplyDisposition) {
            return LinkTarget.Silent;
        }
        return LinkTarget.Modal;
    }
}
