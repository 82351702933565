import { IBroadcastDto } from '../dtos/broadcast.dto';
import { Broadcast } from '@/store/models/broadcast';

export class BroadcastFactory {
    public static make(data: IBroadcastDto): Broadcast {
        const broadcast = new Broadcast();

        broadcast.id = data.id;
        broadcast.createdAt = data.created_at;
        broadcast.status = data.status;
        broadcast.updatedAt = data.updated_at;
        broadcast.segmentId = data.segment_id;
        broadcast.segmentFile = data.segment_file;
        broadcast.appId = data.app_id;
        broadcast.campaignId = data.campaign_id;
        broadcast.convoId = data.convo_id;
        broadcast.convoName = data.convo_name;
        broadcast.convoStarts = data.convo_starts;
        broadcast.channelCode = data.channel_code;
        broadcast.segmentReach = data.segment_reach;
        broadcast.subId = data.sub_id;
        broadcast.audienceId = data.audience_id;
        broadcast.isScheduled = data.is_scheduled;
        broadcast.type = data.type;
        broadcast.triggerMetadata = data.trigger_metadata;

        return broadcast;
    }
}
