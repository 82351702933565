<template>
    <button
        :disabled="disabled || isLoading"
        :class="classArray"
        v-bind="$attrs"
        @click="$emit('click', $event)"
    >
        <Icon v-if="leftIcon" :name="leftIcon" class="left-icon" :size="iconSize" />
        <span class="button-text">
            <slot />
        </span>
        <Icon v-if="rightIcon" :name="rightIcon" class="right-icon" :size="iconSize" />
        <Spinner v-show="isLoading" class="button-spinner" />
    </button>
</template>

<script>
import onlyValues from '@/utils/onlyValues.ts';
import Icon from '@/components/ui/Icon';
import Spinner from '@/components/ui/Spinner';
import { ButtonStyle } from '@/store/enums/buttonStyle.enum.ts';

export default {
    name: 'Button',
    components: {
        Icon,
        Spinner,
    },
    props: {
        hasBorder: {
            type: Boolean,
            default: false,
        },
        buttonStyle: {
            type: String,
            default: ButtonStyle.Primary,
            validator: onlyValues(Object.values(ButtonStyle)),
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'default',
            validator: onlyValues(['default', 'small']),
        },
        isNarrow: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        leftIcon: {
            type: String,
            default: undefined,
        },
        rightIcon: {
            type: String,
            default: undefined,
        },
        iconSize: {
            type: Number,
            default: undefined,
        },
        hasMarginBottom: {
            type: Boolean,
            default: false,
        },
        leftAlignedText: {
            type: Boolean,
            default: false,
        },
        rightAlignedText: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ButtonStyle,
        };
    },
    computed: {
        classArray() {
            return [
                this.class,
                'button',
                `button--style-${this.buttonStyle}`,
                `button--size-${this.size}`,
                {
                    'button--with-border': this.hasBorder,
                    'button--without-border': !this.hasBorder,
                    'button--full-width': this.fullWidth,
                    'button--disabled': this.disabled || this.isLoading,
                    'button--left-aligned-text': this.leftAlignedText,
                    'button--right-aligned-text': this.rightAlignedText,
                    'button--narrow': this.isNarrow,
                    'form-group--has-m-b': this.hasMarginBottom,
                },
            ];
        },
    },
    methods: {
        onClick(event) {
            if (!this.disabled) {
                this.$emit('click', event);
            } else {
                event.preventDefault();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
$form-group-margin-bottom: 20px !default;

.form-group {
    &--has-m-b {
        margin-bottom: $form-group-margin-bottom;
    }
}

.button {
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 40px;
    min-width: 120px;
    padding: 1px 16px 0;
    font-size: 12px;
    font-family: $font-family-button;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1.9px;
    line-height: 1.2em;
    cursor: pointer;
    transition: background 0.3s, border-color 0.3s, color 0.3s;

    &--full-width {
        width: 100%;
    }

    &--disabled {
        opacity: 0.25;
        cursor: default;
    }

    &--without-border {
        .button-text {
            position: relative;

            &::after {
                display: block;
                content: '';
                position: absolute;
                bottom: -10px;
                left: 0;
                height: 2px;
                width: 0%;
                transition: width 0.3s;
            }
        }
    }

    &--with-border {
        border-width: 2px;
        border-style: solid;
    }

    &--narrow {
        min-width: auto;
        padding-left: 5px;
        padding-right: 5px;
    }

    &--left-aligned-text {
        padding-left: 0;
        justify-content: flex-start;
    }

    &--right-aligned-text {
        padding-right: 0;
        justify-content: flex-end;
    }

    &--size-small {
        border-radius: 15px;
        min-width: 80px;
        height: 30px;
    }
}

.left-icon {
    flex: 0 0 auto;
    font-size: 20px;
    margin-right: 9px;
}

.right-icon {
    flex: 0 0 auto;
    font-size: 20px;
    margin-left: 9px;
}

.button-spinner {
    margin-top: -1px;
    margin-left: 10px;
}

@each $key, $item in $buttons {
    $defaultState: map-get($item, 'defaultState');
    $hoverState: map-get($item, 'hoverState');

    .button--style-#{$key} {
        border-color: map-get($defaultState, 'borderColor');
        color: map-get($defaultState, 'textColor');

        &.button--with-border {
            background: map-get($defaultState, 'backgroundColor');

            &:not(.button--disabled):hover {
                background: map-get($hoverState, 'backgroundColor');
                border-color: map-get($hoverState, 'borderColor');
                color: map-get($hoverState, 'textColor');
            }
        }

        &.button--without-border {
            .button-text {
                &::after {
                    background: map-get($hoverState, 'backgroundColor');
                }
            }

            &:not(.button--disabled):hover {
                color: map-get($hoverState, 'backgroundColor');

                .button-text {
                    &::after {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>
