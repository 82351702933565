export enum Routes {
    ConvoList = 'convo-list',
    Publishing = 'publishing',
    PdlCreate = 'pdl-create',
    PdlEdit = 'pdl-edit',
    PdlList = 'pdl-list',
    PdlMode = 'pdl-mode',
    PdlImport = 'pdl-import',
    ContentLibraryList = 'content-library-list',
    ContentLibraryCreate = 'content-library-create',
    ContentLibraryMessages = 'content-library-messages',
}
