var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$options.richTextMessagesEnabled)?_c('EditorInput',{attrs:{"id":_vm.generateId('statement', _vm.path),"label":"Message","show-remaining-char":"","content":_vm.data.statement,"errors":_vm.data.errors.plainText,"max":640,"menu-items":[
        _vm.$options.EditorInputMenuItem.Bold,
        _vm.$options.EditorInputMenuItem.Italic,
        _vm.$options.EditorInputMenuItem.Strike,
        _vm.$options.EditorInputMenuItem.Underline ]},on:{"update":function($event){return _vm.onInput('statement', $event)},"plainTextChange":function($event){return _vm.onInput('plainText', $event)}}}):_c('Input',{attrs:{"id":_vm.generateId('statement', _vm.path),"type":"textarea","label":"Message","name":"statement","show-remaining-char":"","max":640,"has-margin-bottom":false,"value":_vm.data.statement,"errors":_vm.data.errors.statement},on:{"input":function (value) {
            _vm.onInput('statement', value);
            _vm.onInput('plainText', value);
        },"changed":_vm.onChanged}})}
var staticRenderFns = []

export { render, staticRenderFns }