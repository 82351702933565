import { Tenant } from '..';
import { Application } from '../application';
import { UpdateTenantRequest } from '../dtos';
import {
    UpdateTenantRequestApplication,
    IUpdateTenantRequestApplicationRegistrationOptions,
} from '../dtos/tenantRequests.dto';
import { RegistrationType, TenantSignupTrigger } from '@/store/enums';

export class TenantRequestFactory {
    private tenantRequest: UpdateTenantRequest;

    public make(tenant: Tenant) {
        this.tenantRequest = new UpdateTenantRequest();

        this.tenantRequest.id = tenant.id;
        this.tenantRequest.name = tenant.name;
        this.tenantRequest.appKey = tenant.appKey;
        this.tenantRequest.appSecret = tenant.appSecret;
        this.tenantRequest.allowOrigins = JSON.stringify(tenant.allowOrigins);
        this.tenantRequest.channels =
            (tenant.channels && tenant.channels.map((item) => item.code)) || [];
        this.tenantRequest.applications = [];

        if (tenant.applications?.length) {
            for (const application of tenant.applications) {
                const appRequest = new UpdateTenantRequestApplication();
                appRequest.id = application.id;
                appRequest.code = application.code;
                appRequest.name = application.name;
                appRequest.authEnabled = [
                    TenantSignupTrigger.SignupChatStart,
                    TenantSignupTrigger.SubscribeChatStart,
                ].includes(application.signupOptions.trigger);
                appRequest.signupType = application.signupType;
                appRequest.skipAuthForKnownUsers = application.skipAuthForKnownUsers;
                appRequest.allowNoSignup = !!application.allowNoSignup;
                appRequest.fingerprintTrackingEnabled = !!application.fingerprintTrackingEnabled;
                appRequest.maxFreeConvos = application.maxFreeConvos || 0;
                appRequest.tenantId = application.tenantId;
                appRequest.registrationType = this.getRegistrationType(application);
                appRequest.registrationOptions = <
                    IUpdateTenantRequestApplicationRegistrationOptions
                >{
                    subscribeMessage: application.registrationOptions.subscribeMessage,
                    subscribeButtonText: application.registrationOptions.subscribeButtonText,
                    conditionsText: application.registrationOptions.conditionsText,
                    inputText: application.registrationOptions.inputText,
                };
                appRequest.linkOptions = {
                    target: application.linkOptions.target,
                };
                appRequest.options = {
                    ...application.options,
                    signup: {
                        ...application.options?.signup,
                        message: application.signupOptions.message,
                        defaultSubscribeSelection:
                            !!application.signupOptions.defaultSubscribeSelection,
                    },
                    comments: {
                        ...application.options?.comments,
                        requireAuthenticationToLoad:
                            application.signupOptions.trigger ===
                            TenantSignupTrigger.SignupLoadComments,
                    },
                };

                this.tenantRequest.applications.push(appRequest);
            }
        }

        return this.tenantRequest;
    }

    private getRegistrationType(application: Application): RegistrationType {
        const map: { [key: string]: RegistrationType } = {
            [TenantSignupTrigger.SignupChatStart]: RegistrationType.Signup,
            [TenantSignupTrigger.SignupLoadComments]: RegistrationType.Signup,
            [TenantSignupTrigger.SubscribeChatStart]: RegistrationType.Subscribe,
        };

        return map[application.signupOptions.trigger];
    }
}
