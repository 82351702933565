import { Channel } from '@/store/models/channel';
import { ListChannel } from '@/store/models/listChannel';
import { IChannelYougovPreferences, IYougovWelcomeFlow } from '@inconvo/types/interfaces';
import { Channel as ChannelDto } from '../dtos/channel.dto';
import { ListChannel as ListChannelDto } from '../dtos/listChannel.dto';

export class ChannelFactory {
    public make(data: ChannelDto): Channel {
        const channel = new Channel();

        channel.id = data.id;
        channel.code = data.code;
        channel.name = data.name;
        channel.slug = data.slug;
        channel.portraitImage = data.portraitImage;
        channel.landscapeImage = data.landscapeImage;
        channel.squareImage = data.squareImage;
        channel.description = data.description;
        channel.category = data.category;
        channel.categoryId = data.category && data.category.id;
        channel.color = data.color;
        channel.tags = data.tags;
        channel.topics = data.topics;
        channel.platforms = data.platforms;
        channel.tagLine = data.tagLine;
        channel.sharingTitle = data.sharingTitle;
        channel.sharingDescription = data.sharingDescription;
        channel.showMemberCount = data.showMemberCount;
        channel.isFeatured = data.isFeatured;
        channel.isStandalone = data.isStandalone;
        channel.status = data.status;

        channel.welcomeMessage = (data.welcomeMessage && data.welcomeMessage.message) || '';

        const defaultLocale = 'en';
        if (data.landingPage && data.landingPage[defaultLocale]) {
            channel.landingPage.title = data.landingPage[defaultLocale].title;
            channel.landingPage.channelTitle = data.landingPage[defaultLocale].channelTitle;
            channel.landingPage.description = data.landingPage[defaultLocale].description;
        }

        channel.convoSchedule.schedule = data.convoSchedule.schedule;
        channel.convoSchedule.availableConvos = data.convoSchedule.availableConvos;
        channel.convoSchedule.selectedConvos = data.convoSchedule.selectedConvos;
        channel.convoSchedule.scheduledConfig = data.convoSchedule.scheduledConfig;

        channel.fbPageId = data.fbPageId;
        channel.fbAppId = data.fbAppId;
        channel.fbVerifyToken = data.fbVerifyToken;
        channel.fbAccessToken = data.fbAccessToken;
        channel.style = data.style;

        channel.redirectUrl = data.redirectUrl;
        channel.originalVersion = data.originalVersion;

        channel.createdAt = data.createdAt;
        channel.createdBy = data.createdBy;
        channel.updatedAt = data.updatedAt;
        channel.updatedBy = data.updatedBy;

        channel.emailLocale = data.emailLocale;

        channel.timezoneIdentifier = data.timezoneIdentifier;

        channel.yougovPreferences = {} as IChannelYougovPreferences;

        if (data.yougovPreferences.welcomeFlows?.length) {
            channel.yougovPreferences.welcomeFlows = data.yougovPreferences.welcomeFlows.map(
                (item): IYougovWelcomeFlow => ({
                    type: item.type,
                    id: item.id,
                    name: item.name,
                    panel: {
                        name: item.panel?.name,
                        language: item.panel?.language,
                    },
                    weight: item.weight,
                }),
            );
        }

        return channel;
    }

    public makeListChannel(data: ListChannelDto): ListChannel {
        const channel = new ListChannel();
        channel.id = data.id;
        channel.code = data.code;
        channel.slug = data.slug;
        channel.name = data.name;
        channel.description = data.description;
        channel.image = data.image;
        channel.isFeatured = data.isFeatured;
        channel.status = data.status;
        channel.channelId = data.channelId;
        return channel;
    }
}
