interface IVariableList {
    name: string;
    items: IVariable[];
}

interface IVariable {
    key: string;
    displayText: string;
    value: string;
    sortingOrder?: string;
    sampleText?: string;
}

const variables: IVariableList[] = [
    {
        name: 'Templates',
        items: [
            {
                key: 'template-results',
                displayText: 'Answer Choice with Percentage',
                value: '{{#each choices}}{{this.text}} - {{this.percentage}}% {{newLine}}{{/each}}',
            },
        ],
    },
    {
        name: 'Variables',
        items: [
            {
                key: 'var-answer-text',
                displayText: 'Answer text',
                value: '{{answer.text}}',
            },
            {
                key: 'var-quiz-score',
                displayText: 'Quiz score',
                value: '{{quiz.score}}',
            },
            {
                key: 'var-answer-percentage',
                displayText: 'Answer percentage',
                value: '{{answer.percentage}}',
            },
            {
                key: 'var-newline',
                displayText: 'New line',
                value: '{{newLine}}',
            },
        ],
    },
];

export default variables;
