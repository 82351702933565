import { Message } from './message';
import { ButtonAnswer, IButtonAnswer } from './buttonAnswer';
import { MessageType } from './enums/messageType.enum';
import { ButtonViewLayout } from '../../../src/store/enums/buttonViewLayout.enum';

export class Buttons extends Message {
    text: string;
    answers: IButtonAnswer[];
    avatarUrl: string;
    layout: ButtonViewLayout;
    maximum: number;
    minimum: number;
    isMultipleChoice: boolean;
    submitButtonText: string;

    constructor(
        text: string,
        answers: IButtonAnswer[],
        avatarUrl: string,
        layout: ButtonViewLayout = ButtonViewLayout.horizontal,
        maximum: number = 0,
        minimum: number = 0,
        isMultipleChoice: boolean = false,
        submitButtonText: string = '',
    ) {
        super(MessageType.Buttons, avatarUrl);
        this.text = text;
        this.answers = answers.map((o) => new ButtonAnswer(o.label));
        this.layout = layout;
        this.maximum = maximum;
        this.minimum = minimum;
        this.isMultipleChoice = isMultipleChoice;
        this.submitButtonText = submitButtonText;
    }
}
