import Vue from 'vue';
import { ChannelClient } from '@/api/channel';
import { router } from '@/router';
import { Channel } from '@/store/models/channel';
import { ChannelFactory } from '@/store/models/factories/channel.factory';
import hash from 'object-hash';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import {
    Channel as ChannelDto,
    ListChannel as ListChannelDto,
    GetChannelsRequest,
} from '../models/dtos';
import { IPaging, IYougovWelcomeFlow } from '@inconvo/types/interfaces';
import {
    CLEAR_ERRORS,
    SET_CHANNEL,
    SET_CHANNEL_PROPERTY,
    CLEAR_CHANNEL,
    SET_CHANNELS,
    SET_DEFAULT_STATE,
    SET_CHANNEL_ERRORS,
    SET_VALIDATED,
    UPDATE_LANDING_PAGE_PROPERTY,
    UPDATE_YOUGOV_PREFERENCES_PROPERTY,
    SET_EMAIL_LOCALE,
    SET_TIMEZONE_IDENTIFIER,
    SET_CHANNEL_DETAILS_BROADCASTS,
    SET_BROADCAST_CAMPAIGN_METRIC,
    SET_CONVO_SCHEDULE,
    SET_CHANNEL_TENANT_DATA,
    SET_CHANNEL_DETAILS,
} from '../mutations.types';
import { IRootState } from '../types/rootState';
import { ChannelRequestFactory } from '../models/factories/channelRequest.factory';
import { IObservableItem } from '../models/interfaces/observableItem.interface';
import { ChannelFacebookRequest } from '../models/dtos/channelRequests.dto';
import { IChannelStyle } from '../models/dtos/channel.dto';
import { UpdateTenantChannelRequest } from '../models/dtos/tenantRequests.dto';
import { IListChannel } from '../models/interfaces/listChannel.interface';
import {
    IBroadcastCampaignMetricDto as CampaignMetric,
    IBroadcastDto,
} from '../models/dtos/broadcast.dto';
import { Broadcast } from '../models/broadcast';
import { BroadcastFactory } from '../models/factories/broadcast.factory';
import { GetBroadcastsRequest } from '../models/dtos/broadcastRequests.dto';
import { calculatePercentage } from '@/utils';
import { IConvoSchedule } from '../models/interfaces/convoSchedule.interface';

export interface IChannelListView {
    redirectPath: string;
    channelList: IPaging<IListChannel>;
}

export interface IChannelDetailsView {
    channel: Channel;
    tenantData: Partial<{
        appCode: string;
        appSecret: string;
        appKey: string;
    }>;
    errors: object;
    validated: boolean;
    broadcasts: IPaging<any>;
}

export interface IChannelState {
    channelListView: IChannelListView;
    channelDetails: IChannelDetailsView;
}

const getDefaultState = (): IChannelState => {
    return {
        channelListView: {
            redirectPath: 'convo-list',
            channelList: {} as IPaging<IListChannel>,
        },
        channelDetails: {
            channel: new Channel(),
            tenantData: {},
            errors: {},
            validated: false,
            broadcasts: {} as IPaging<any>,
        },
    };
};

const getters: GetterTree<IChannelState, IRootState> = {
    channelListView: (state) => state.channelListView,
    channelDetails: (state) => state.channelDetails,
    channelModelHasChanges: (state): boolean => {
        const observableItem = state.channelDetails
            .channel as unknown as IObservableItem<IChannelState>;
        const itemHasChanges =
            observableItem && observableItem.itemHasChanges && observableItem.itemHasChanges(state);

        if (itemHasChanges || !state.channelDetails.channel.id) {
            return true;
        }

        return false;
    },
    channelIsValid: (state): boolean => {
        return Object.keys(state.channelDetails.errors).length === 0;
    },
};

const actions: ActionTree<IChannelState, any> = {
    setDefaultState: async ({ commit }) => {
        commit(SET_DEFAULT_STATE);
    },
    getChannels: async ({ state, commit }, searchOptions) => {
        const request = new GetChannelsRequest();
        request.q = (searchOptions && searchOptions.q) || '';
        request.includeInactive = searchOptions && searchOptions.includeInactive;
        request.page = (searchOptions && searchOptions.page) || 1;
        request.size = 15;

        let result: IPaging<ListChannelDto> = await ChannelClient.getChannels(request);

        if (result) {
            for (const channelItem of result.items) {
                const route = router.resolve({
                    name: state.channelListView.redirectPath,
                    params: { channelCode: `${channelItem.code}` },
                });
                channelItem.redirectUrl = (route && route.href) || '';
            }
        } else {
            result = {
                page: 0,
                size: 0,
                total: 0,
                items: [],
            };
        }

        const channels: IPaging<IListChannel> = {
            page: result.page,
            size: result.size,
            total: result.total,
            items: result.items.map((o: ListChannelDto) => new ChannelFactory().makeListChannel(o)),
        };

        commit(SET_CHANNELS, channels || []);
    },
    setChannelDetails: async ({ commit }) => {
        commit(CLEAR_CHANNEL);
        const channelCode = router.currentRoute.params.channelCode;
        const channelDto: ChannelDto = await ChannelClient.getChannel(channelCode);
        if (!channelDto) {
            throw new Error('could not load channel');
        }
        const channelModel = new ChannelFactory().make(channelDto);
        const channelRequest = new ChannelRequestFactory().make(channelModel);
        channelModel.originalVersion = hash(channelRequest);
        commit(SET_CHANNEL, channelModel);
    },
    updateChannelProperty: ({ state, commit, dispatch }, data: { key: string; value: string }) => {
        if (state.channelDetails.channel.id === 0 && data.key === 'name') {
            commit(SET_CHANNEL_PROPERTY, { key: 'slug', value: data.value });
        }
        commit(SET_CHANNEL_PROPERTY, data);
        if (state.channelDetails.validated) {
            dispatch('validateChannel');
        }
    },
    saveChannel: async ({ commit, state, dispatch }) => {
        const requestFactory = new ChannelRequestFactory();
        const request = requestFactory.make(state.channelDetails.channel);

        let channel: ChannelDto;
        if (state.channelDetails.channel.id === 0) {
            channel = await ChannelClient.createChannel(request);
        } else {
            channel = await ChannelClient.updateChannel(state.channelDetails.channel.code, request);
        }

        const channelModel = new ChannelFactory().make(channel);
        const channelRequest = new ChannelRequestFactory().make(channelModel);
        channelModel.originalVersion = hash(channelRequest);
        const updateTenantChannelPayload: UpdateTenantChannelRequest = {
            channelId: channel.id,
            channelCode: channel.code,
            isFeatured: channel.isFeatured,
        };
        dispatch('tenant/updateTenantOnChannelModification', updateTenantChannelPayload, {
            root: true,
        });

        commit(SET_CHANNEL, channelModel);
    },
    setupFacebook: async ({ commit, state }) => {
        const request = new ChannelFacebookRequest();
        request.fbAppId = state.channelDetails.channel.fbAppId;
        request.fbPageId = state.channelDetails.channel.fbPageId;
        request.fbAccessToken = state.channelDetails.channel.fbAccessToken;
        request.style = state.channelDetails.channel.style || ({} as IChannelStyle);

        await ChannelClient.setupFacebook(state.channelDetails.channel.code, request);
    },
    validateChannel: async ({ commit, state }) => {
        // Validate the channel.
        const errors = await state.channelDetails.channel.validate();
        // If no errors found by validator and state has prior errors, clear the errors.
        if (!Object.keys(errors).length && Object.keys(state.channelDetails.errors).length > 0) {
            commit(CLEAR_ERRORS);
        }
        // If errors found by validator, commit to state.
        if (Object.keys(errors).length) {
            commit(SET_CHANNEL_ERRORS, errors);
        }
        // If channel is validated for first time, set flag.
        if (!state.channelDetails.validated) {
            commit(SET_VALIDATED, true);
        }
    },
    updateLandingPageProperty: async ({ commit }, payload) => {
        commit(UPDATE_LANDING_PAGE_PROPERTY, payload);
    },
    updateYougovPreferencesProperty: async ({ commit }, payload) => {
        commit(UPDATE_YOUGOV_PREFERENCES_PROPERTY, payload);
    },
    setEmailLocale({ commit }, locale: string) {
        commit(SET_EMAIL_LOCALE, locale);
    },
    setTimezone({ commit }, timezone: string) {
        commit(SET_TIMEZONE_IDENTIFIER, timezone);
    },
    setConvoSchedule({ commit }, convoSchedule: IConvoSchedule) {
        commit(SET_CONVO_SCHEDULE, convoSchedule);
    },
    async getBroadcasts({ commit }, { size, page, channelCode }: GetBroadcastsRequest) {
        const result: IPaging<IBroadcastDto> = await Vue.$api.broadcast.getBroadcasts({
            size,
            page,
            channelCode,
        });

        const broadcasts: IPaging<Broadcast> = {
            page: result.page,
            size: result.size,
            total: result.total,
            items: result.items.map((item: IBroadcastDto) => BroadcastFactory.make(item)),
        };

        commit(SET_CHANNEL_DETAILS_BROADCASTS, broadcasts);
    },
    async getCampaignMetric({ commit }, { appId, broadcastId, campaignId }) {
        const result: CampaignMetric = await Vue.$api.broadcast.getCampaignMetric({
            appId,
            broadcastId,
            campaignId,
        });
        if (result) {
            commit(SET_BROADCAST_CAMPAIGN_METRIC, result);
        }
    },
    getChannelTenant: async ({ commit }, channelId: number) => {
        try {
            commit(SET_CHANNEL_TENANT_DATA, {});
            const result = await ChannelClient.getChannelTenant(channelId);
            if (Object.keys(result).length) {
                commit(SET_CHANNEL_TENANT_DATA, {
                    appCode: result.appCode,
                    appSecret: result.appSecret,
                    appKey: result.appKey,
                });
            }
        } catch (error) {
            Vue.$log.error(error);
        }
    },
    resetChannelDetails: ({ commit }) => {
        commit(SET_CHANNEL_DETAILS, getDefaultState().channelDetails);
    },
};

const mutations: MutationTree<IChannelState> = {
    [SET_EMAIL_LOCALE](state, locale: string): void {
        Vue.set(state.channelDetails.channel, 'emailLocale', locale);
    },
    [SET_TIMEZONE_IDENTIFIER](state, timezone: string): void {
        Vue.set(state.channelDetails.channel, 'timezoneIdentifier', timezone);
    },
    [SET_CONVO_SCHEDULE](state, convoSchedule: IConvoSchedule): void {
        state.channelDetails.channel.convoSchedule = convoSchedule;
    },
    [SET_CHANNELS](state, channels: IPaging<IListChannel>): void {
        state.channelListView.channelList = channels;
    },
    [SET_CHANNEL](state, channel: Channel): void {
        state.channelDetails.channel = channel;
    },
    [SET_CHANNEL_PROPERTY](state, data: { key: string; value: string }): void {
        Vue.set(state.channelDetails.channel, data.key, data.value);
    },
    [CLEAR_CHANNEL](state): void {
        state.channelDetails.channel = new Channel();
    },
    [SET_DEFAULT_STATE](state: IChannelState): void {
        Object.assign(state, getDefaultState());
    },
    [SET_CHANNEL_ERRORS](state: IChannelState, errors): void {
        state.channelDetails.errors = errors;
    },
    [CLEAR_ERRORS](state: IChannelState): void {
        state.channelDetails.errors = {};
    },
    [SET_VALIDATED](state: IChannelState, value: boolean): void {
        state.channelDetails.validated = value;
    },
    [SET_CHANNEL_TENANT_DATA](state: IChannelState, tenantData): void {
        state.channelDetails.tenantData = tenantData;
    },
    [UPDATE_LANDING_PAGE_PROPERTY](
        state: IChannelState,
        { name, value }: { name: string; value: string },
    ): void {
        state.channelDetails.channel.landingPage[name] = value;
    },
    [UPDATE_YOUGOV_PREFERENCES_PROPERTY](
        state: IChannelState,
        welcomeFlows: IYougovWelcomeFlow[] = [],
    ): void {
        state.channelDetails.channel.yougovPreferences = {
            ...state.channelDetails.channel.yougovPreferences,
            welcomeFlows,
        };
    },
    [SET_CHANNEL_DETAILS_BROADCASTS](state: IChannelState, broadcasts: IPaging<Broadcast>): void {
        state.channelDetails.broadcasts = broadcasts;
    },
    [SET_BROADCAST_CAMPAIGN_METRIC](state: IChannelState, result: CampaignMetric): void {
        if (state.channelDetails.broadcasts.items?.length) {
            const broadcastIndex = state.channelDetails.broadcasts.items.findIndex(
                (b: any) => b.id == result.broadcastId,
            );

            if (broadcastIndex === -1) {
                return;
            }
            const broadcastItem = state.channelDetails.broadcasts.items[
                broadcastIndex
            ] as Broadcast;

            const convoStartPercentage = calculatePercentage(
                broadcastItem.convoStarts,
                result.emailDeliveryCount,
            );

            Vue.set(broadcastItem, 'openRecipients', result.openRecipients);
            Vue.set(broadcastItem, 'openRecipientsPercentage', result.openRecipientsPercentage);
            Vue.set(broadcastItem, 'emailDeliveryCount', result.emailDeliveryCount);
            Vue.set(broadcastItem, 'convoStartsPercentage', convoStartPercentage);
        }
    },
    [SET_CHANNEL_DETAILS](state: IChannelState, channelDetails: IChannelDetailsView): void {
        state.channelDetails = channelDetails;
    },
};

export const channel: Module<IChannelState, IRootState> = {
    namespaced: true,
    state: getDefaultState(),
    getters,
    actions,
    mutations,
};
