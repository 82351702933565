<template>
    <div class="logo">
        <div class="logo-image">
            <div class="spinner-image">
                <Spinner name="branded" color="red" />
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from '@/components/ui/Spinner';
import config from '@/config';

export default {
    name: 'AuthLogout',
    components: {
        Spinner,
    },
    mounted() {
        setTimeout(
            () => this.$router.push(config.featureToggle.otpSignIn ? { name: 'sign-in' } : '/'),
            2000,
        );
    },
};
</script>

<style lang="scss" scoped>
.logo {
    position: absolute;
    display: block;
    width: 350px;
    width: 180px;
    height: 28px;

    @include center();

    @include media-query(medium) {
        width: 290px;
        height: 42px;
    }

    .logo-image {
        position: relative;

        .spinner-image {
            display: block;
            position: absolute;
            margin-left: -8px;
            margin-top: 1px;

            @include center();

            @include media-query(medium) {
                margin-top: 0px;
                margin-left: -14px;
            }

            svg {
                font-size: 28px !important;

                @include media-query(medium) {
                    font-size: 46px !important;
                }
            }
        }
    }
}
</style>
