import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum';
import { getErrorMessage, validateResultOptions } from '@/store/services/validationService';
import { IsNotEmpty, Length, validate as validateClass, ValidationError } from 'class-validator';
import hash from 'object-hash';
import { ButtonsAnswer } from '.';
import { ButtonViewLayout, ChartType, ConvoTypes, SortingOrder } from '../enums';
import { IConvoState } from '../modules/convo-details';
import { AccordionItemBase } from './accordionItemBase';
import { ButtonsAccordionItemFactory } from './factories/buttonsAccordionItem.factory';
import { ButtonsContentItemFactory } from './factories/buttonsContentItem.factory';
import {
    IContentItem,
    IContentItemConvertible,
    IDatumContentItem,
    IResultOption,
    IRichText,
    ITag,
} from './interfaces';
import { IObservableItem } from './interfaces/observableItem.interface';
import { IQuizContentItem } from './interfaces/quizContentItem.interface';
import { ItemsGroup } from './itemsGroup';
import { TextGlobalResponse } from './textGlobalResponse';

export class Buttons
    extends AccordionItemBase
    implements
        IQuizContentItem,
        IContentItemConvertible,
        IObservableItem<IConvoState>,
        IRichText,
        IDatumContentItem
{
    constructor(items = []) {
        super(items);

        const buttonsAnswers = new ItemsGroup();
        const notDraggableItemList = new ItemsGroup();
        const globalResponse = new TextGlobalResponse();

        this.type = ConvoMessageItemType.Buttons;
        this.plainText = '';
        this.statement = '';
        this.allowMultipleChoice = true;
        this.isMultipleChoice = false;
        this.submitButtonText = '';
        this.layout = ButtonViewLayout.vertical;
        this.minLimit = 0;
        this.maxLimit = 0;
        this.resultOptions = {
            order: SortingOrder.None,
            chartType: ChartType.None,
            showResponseCount: false,
        } as IResultOption;
        this.isDraggable = true;
        this.isDeletable = true;
        this.icon = 'button';
        this.maxItems = 30;
        globalResponse.hasMarginBottom = false;
        notDraggableItemList.items = [globalResponse];
        this.items = [buttonsAnswers, notDraggableItemList];
        this.ignoreQuizQuestion = false;
        this.showQuizResults = false;
        this.tags = [];
        this.datumDefinition = '';
    }

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Message') })
    @Length(0, 640, { message: getErrorMessage('length', 'Message', { min: 0, max: 640 }) })
    plainText: string;
    statement: string;
    submitButtonText: string;
    maxItems: number;
    allowMultipleChoice: boolean;
    isMultipleChoice: boolean;
    layout: string;
    minLimit: number;
    maxLimit: number;
    resultOptions: IResultOption;
    ignoreQuizQuestion: boolean;
    showQuizResults: boolean;
    tags?: ITag[];
    datumDefinition: string;

    setTitle(): void {
        this.subtitle = this.plainText;
    }

    addItem(item: AccordionItemBase): void {
        this.items[0].items.push(item);
    }

    toContentItem(state: IConvoState): IContentItem {
        const contentItemFactory = new ButtonsContentItemFactory();
        return contentItemFactory.make(this, state);
    }

    itemHasChanges(state: IConvoState): boolean {
        const contentItemFactory = new ButtonsContentItemFactory();
        const contentItem = contentItemFactory.make(this, state);

        contentItem.version = hash(contentItem);

        if (this.originalVersion) {
            return contentItem.version !== this.originalVersion;
        }
        return false;
    }

    toAccordionItem(contentItem: IContentItem, isClone?: boolean): AccordionItemBase {
        const accordionItemFactory = new ButtonsAccordionItemFactory();
        return accordionItemFactory.make(contentItem, isClone);
    }

    handleIgnoreQuiz(convoType: ConvoTypes) {
        for (const subItem of this.items[0].items) {
            const answer = subItem as ButtonsAnswer;
            if (convoType === ConvoTypes.PersonalityQuiz) {
                answer.selectedPersonalityQuizResult = undefined;
            } else if (convoType === ConvoTypes.TriviaQuiz) {
                answer.isCorrectAnswer = false;
            }
        }
    }

    async validate(state: IConvoState, path: number[]): Promise<any[]> {
        const errors: ValidationError[] = await validateClass(this);
        const errorMessages: any[] = [];
        const mainLevelErrors: any = {};

        if (errors.length > 0) {
            errors.forEach((item: ValidationError) => {
                mainLevelErrors[item.property] = Object.values(item.constraints || {});
            });
        }

        if (this.items[0].items.length === 0 || this.items[0].items.length > this.maxItems) {
            mainLevelErrors.buttonsAnswers = [];
            mainLevelErrors.buttonsAnswers.push(
                getErrorMessage('buttonsLength', 'Buttons', { min: 1, max: this.maxItems }),
            );
        }

        // trivia quiz - number of correct answers
        if (!this.ignoreQuizQuestion && state.convo.type === ConvoTypes.TriviaQuiz) {
            if (!this.items[0].items.some((item) => (item as ButtonsAnswer).isCorrectAnswer)) {
                if (!mainLevelErrors.buttonsAnswers) {
                    mainLevelErrors.buttonsAnswers = [];
                }

                mainLevelErrors.buttonsAnswers.push(getErrorMessage('triviaQuizCorrectAnswer'));
            }
        }

        if (Object.entries(mainLevelErrors).length > 0) {
            errorMessages.push({ path, errors: mainLevelErrors });
        }

        for (let i = 0; i < this.items[0].items.length; i++) {
            const answer = this.items[0].items[i] as ButtonsAnswer;
            const chilrenErrors: any[] = await answer.validate(state, [...path, 0, i]);

            if (Object.entries(chilrenErrors).length > 0) {
                errorMessages.push(...chilrenErrors);
            }
        }

        const globalResponseErrors: any = await this.items[1].items[0].validate(state, path);

        validateResultOptions(
            this.resultOptions,
            this.items[1].items[0] as TextGlobalResponse,
            globalResponseErrors,
        );

        if (Object.entries(globalResponseErrors).length > 0) {
            errorMessages.push({ path: [...path, 1, 0], errors: globalResponseErrors });
        }

        return errorMessages;
    }

    clearDatumDefinition(): void {
        this.datumDefinition = '';
        this.items[0].items.forEach((item) => {
            (item as ButtonsAnswer).clearDatumAnswer();
        });
    }
}
