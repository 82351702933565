export enum ConvoMessageItemType {
    Rating = 'rating',
    RatingOption = 'ratingOption',
    Buttons = 'buttons',
    ButtonsAnswer = 'buttonsAnswer',
    TextStatement = 'textStatement',
    ImageStatement = 'imageStatement',
    QuickReply = 'quickReply',
    QuickReplyAnswer = 'quickReplyAnswer',
    SelectMessageType = 'selectMessageType',
    StandardShareMessage = 'standardShareMessage',
    ItemsGroup = 'itemsGroup',
    Rules = 'rules',
    InlineResponse = 'inlineResponse',
    SelectInlineResponseType = 'selectInlineResponseType',
    TextInput = 'textInput',
    TextGlobalResponse = 'textGlobalResponse',
    AttachmentUrl = 'attachmentUrl',
    Carousel = 'carousel',
    CarouselTile = 'carouselTile',
    InlineResponseTextStatement = 'inlineResponseTextStatement',
    Result = 'result',
    Registration = 'registration',
    DatePicker = 'datePicker',
    Video = 'video',
    CommentsRating = 'commentsRating',
    CommentsRatingTargetInput = 'commentsRatingTargetInput',
    Gif = 'gif',
    Image = 'image',
    YougovWelcomeSurveyButtons = 'yougovWelcomeSurveyButtons',
}
