<template>
    <FormOverlay
        :title="title"
        :description="description"
        :confirm-button-text="confirmButtonText"
        :confirm-button-disabled="!hasValidContent"
        :show-overlay="true"
        @close="$emit('close')"
        @confirm="onConfirm"
    >
        <ErrorMessage v-if="errors.length">
            <p v-for="(error, idx) in errors" :key="`error-${idx}`">
                {{ error }}
            </p>
        </ErrorMessage>

        <AutoComplete
            ref="autocomplete"
            :value="autocompleteModel"
            class="m-b-2"
            label="Attribute"
            :is-disabled="autocompleteDisabled"
            :open-on-focus="false"
            :items="autocompleteItems"
            :placeholder="autocompletePlaceholder"
            @input="onSelectedAutocomplete"
            @query="onSearch"
        >
            <template v-slot="{ resultIndex }">
                <AutoCompleteResult
                    v-if="autocompleteResults[resultIndex]"
                    :result="getAutoCompleteResult(resultIndex)"
                    :show-content-id="showContentId"
                />
            </template>
        </AutoComplete>

        <ContentSelectorList
            v-if="showContentList"
            :content="computedContent"
            :clear-button-text="clearButtonText"
            :list-header-text="listHeaderText"
            :fixed-channel="fixedChannel"
            :fixed-convo="fixedConvo"
            @removeItem="onRemoveItem"
        />
    </FormOverlay>
</template>

<script>
import { DEFAULTS, AUTOCOMPLETE_PLACEHOLDERS, CONTENT_COUNT_MAP } from './config';
import AutoComplete from '@/components/forms/AutoComplete';
import AutoCompleteResult from '@/components/forms/partials/AutoCompleteResult';
import ErrorMessage from '@/components/ui/ErrorMessage';
import ContentSelectorList from './ContentSelectorList';
import service from '@/store/services/contentService';
import FormOverlay from '@/components/ui/FormOverlay';
import { ContentType } from '@/store/enums';

export default {
    name: 'ContentSelector',
    components: {
        ContentSelectorList,
        AutoComplete,
        ErrorMessage,
        FormOverlay,
        AutoCompleteResult,
    },
    props: {
        title: {
            type: String,
            default: DEFAULTS.title,
        },
        description: {
            type: String,
            default: DEFAULTS.description,
        },
        confirmButtonText: {
            type: String,
            default: DEFAULTS.confirmButtonText,
        },
        clearButtonText: {
            type: String,
            default: DEFAULTS.clearButtonText,
        },
        listHeaderText: {
            type: String,
            default: DEFAULTS.listHeaderText,
        },
        validationContentType: {
            type: String,
            default: DEFAULTS.validationContentType,
        },
        includeInactive: {
            type: Boolean,
            default: DEFAULTS.includeInactive,
        },
        fixedChannel: {
            type: Boolean,
            default: DEFAULTS.fixedChannel,
        },
        fixedConvo: {
            type: Boolean,
            default: DEFAULTS.fixedConvo,
        },
        contentTypes: {
            type: Array,
            default: DEFAULTS.contentTypes,
        },
        value: {
            type: Object,
            default: undefined,
        },
        showContentId: {
            type: Boolean,
            default: false,
        },
        convoTypes: {
            type: Array,
            default: () => [],
        },
        requireChannel: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            autocompleteModel: '',
            autocompleteResults: [],
            content: this.value,
            errors: [],
        };
    },
    computed: {
        autocompleteItems() {
            return this.autocompleteResults.map((result, index) => {
                if (result.message) {
                    return result.message.text;
                }
                if (result.convo) {
                    return result.convo.text;
                }
                return result.channel.text;
            });
        },
        autocompletePlaceholder() {
            return AUTOCOMPLETE_PLACEHOLDERS[this.autocompleteContentType];
        },
        autocompleteContentType() {
            if (!this.selectedContentItem) {
                return ContentType.Any;
            }
            if (this.selectedContentItem.type === ContentType.Channel) {
                return ContentType.Convo;
            }
            return ContentType.Message;
        },
        selectedContentItem() {
            const types = [ContentType.Message, ContentType.Convo, ContentType.Channel];
            for (const type of types) {
                if (this.content && this.content[type]) {
                    return this.content[type];
                }
            }
            return null;
        },
        autocompleteDisabled() {
            if (!this.content) {
                return false;
            }
            return this.contentCount === CONTENT_COUNT_MAP[this.validationContentType];
        },
        hasValidContent() {
            if (!this.content) {
                return false;
            }
            if (this.validationContentType === ContentType.Any) {
                return true;
            }
            return this.contentCount === CONTENT_COUNT_MAP[this.validationContentType];
        },
        showContentList() {
            return this.content && this.contentCount;
        },
        contentCount() {
            if (!this.content) {
                return 0;
            }
            return Object.keys(this.content).length;
        },
        computedContent() {
            if (!this.content.channel?.code) {
                const content = { ...this.content };
                const channel = { ...content.channel };
                content.channel = channel;
                channel.text = 'GLOBAL';
                return content;
            }

            return this.content;
        },
    },
    methods: {
        async onSearch(query) {
            this.autocompleteModel = query;
            if (query.length === 0) {
                this.autocompleteResults = [];
                return;
            }
            const results = await this.getContent(query);
            this.autocompleteResults = results || [];
        },
        async getContent(query) {
            const pageSize = 20;
            const map = {
                [ContentType.Channel]: async () => {
                    const results = await service.searchChannels({
                        query,
                        includeInactive: this.includeInactive,
                        page: 1,
                        pageSize,
                    });
                    return results.map((channel) => ({ channel }));
                },
                [ContentType.Convo]: async () => {
                    const options = {
                        query,
                        channelCode: this.selectedContentItem.code,
                        page: 1,
                        pageSize,
                    };
                    if (!this.selectedContentItem.code) {
                        options.channelId = this.selectedContentItem.id;
                    }

                    if (this.selectedContentItem.convoType) {
                        options.convoType = this.selectedContentItem.convoType;
                    }
                    const results = await service.searchConvos(options);
                    return results.map((convo) => ({ convo }));
                },
                [ContentType.Message]: async () => {
                    const results = await service.searchMessages({
                        query,
                        convoId: this.selectedContentItem.id,
                        contentTypes: this.contentTypes,
                    });
                    return results.map((message) => ({ message }));
                },
                [ContentType.Any]: () =>
                    service.searchContent({
                        query,
                        contentTypes: this.contentTypes,
                        convoTypes: this.convoTypes,
                        requireChannel: this.requireChannel,
                    }),
            };
            return map[this.autocompleteContentType]();
        },
        onSelectedAutocomplete(item, index, optionIndex) {
            const result = this.autocompleteResults[optionIndex];

            if (this.autocompleteContentType === ContentType.Any) {
                this.content = result;
            } else {
                this.content = this.transformSingleResult(result[this.autocompleteContentType]);
            }
            this.autocompleteModel = '';
        },
        onRemoveItem(type) {
            const map = {
                [ContentType.Channel]: () => null,
                [ContentType.Convo]: () => ({ channel: this.content.channel }),
                [ContentType.Message]: () => ({
                    channel: this.content.channel,
                    convo: this.content.convo,
                }),
            };
            this.content = map[type]();
        },
        onConfirm() {
            this.validate();
            if (this.errors.length) {
                return;
            }
            this.$emit('input', this.content);
            this.$emit('selected', this.selectedContentItem);
            this.$emit('close');
        },
        transformSingleResult(result, index) {
            const map = {
                [ContentType.Channel]: (channel) => ({ channel }),
                [ContentType.Convo]: (convo) => ({ ...this.content, convo }),
                [ContentType.Message]: (message) => ({ ...this.content, message }),
            };
            return map[result.type](result);
        },
        validate() {
            this.errors = [];
            if (!this.hasValidContent) {
                this.errors.push(`Please select a ${this.validationContentType}`);
            }
        },
        getAutoCompleteResult(index) {
            const content = this.autocompleteResults[index];
            if (content.message) {
                return content.message;
            }
            if (content.convo) {
                return content.convo;
            }
            return content.channel;
        },
    },
};
</script>
