import { IMessageType } from './interfaces/messageType.interface';

export class MessageType {
    constructor({ name, title, icon, metadata }: IMessageType) {
        this.name = name;
        this.title = title;
        this.icon = icon;
        if (metadata) {
            this.metadata = metadata;
        }
    }

    name: string;
    title: string;
    icon: string;
    metadata?: any;
}
