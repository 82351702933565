import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum';
import { AccordionItemBase } from '@/store/models';
import { ListMarker } from '@/store/enums/listMarker.enum';
import { getErrorMessage } from '@/store/services/validationService';
import {
    IsNotEmpty,
    IsNumber,
    Length,
    validate as validateClassInstance,
    ValidationError,
} from 'class-validator';
import { v4 as uuid } from 'uuid';
import { IConvoState } from '../modules/convo-details';

export class RatingOption extends AccordionItemBase {
    constructor(items = []) {
        super(items);
        this.ratingId = uuid();
        this.type = ConvoMessageItemType.RatingOption;
        this.markerType = ListMarker.Numeral;
    }

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Rating Label') })
    @Length(0, 100, { message: getErrorMessage('length', 'Rating Label', { min: 0, max: 100 }) })
    title: string;
    @IsNumber({}, { message: getErrorMessage('isNotEmpty', 'Rating') })
    rating?: number;
    ratingId: string;

    async validate(state: IConvoState, path: number[]): Promise<any> {
        const errors: ValidationError[] = await validateClassInstance(this);
        const errorMessages: any[] = [];

        if (errors.length > 0) {
            errors.forEach((item: ValidationError) => {
                errorMessages.push({
                    path,
                    errors: { [item.property]: Object.values(item.constraints || {}) },
                });
            });
        }

        const duplicatedRatings = state.messagesView.messageItems[path[0]].items.filter(
            (ratingOption) => ratingOption.rating === this.rating,
        );
        if (duplicatedRatings.length > 1) {
            errorMessages.push({
                path,
                errors: {
                    rating: [getErrorMessage('answerRatingCannotBeRepeated')],
                },
            });
        }

        return errorMessages;
    }
}
