import { IContentItem } from '../interfaces';
import { ContentItem, Registration } from '..';
import { ContentItemType } from '@/store/enums/';
import { IAnswer } from '../interfaces/answer.interface.dto';
import { Answer } from '../answer.dto';
import { IConvoState } from '@/store/modules/convo-details';
import { IButton } from '../interfaces/button.interface';

export class RegistrationContentItemFactory {
    make(obj: Registration, state: IConvoState): IContentItem {
        const contentItem = new ContentItem();
        contentItem.id = obj.id;
        contentItem.sort = obj.sort;
        const prefix = obj.prefix || 'yg';
        contentItem.type = prefix + '_' + ContentItemType.Registration;

        contentItem.answers = [];

        const answer: IAnswer = new Answer();
        answer.convoId = state.convo.id;
        answer.image = obj.image;
        answer.title = obj.regTitle;
        answer.subtitle = obj.regSubtitle;

        const acceptButton: IButton = {} as IButton;
        acceptButton.convoId = state.convo.id;
        acceptButton.type = obj.acceptButtonAnswerType;
        acceptButton.link = obj.acceptButtonLink;
        acceptButton.text = obj.acceptButtonLabel;

        const continueButton: IButton = {} as IButton;
        continueButton.convoId = state.convo.id;
        continueButton.type = obj.continueButtonAnwserType;
        continueButton.text = obj.continueButtonLabel;

        answer.buttons = [] as IButton[];
        answer.buttons.push(acceptButton);
        answer.buttons.push(continueButton);

        contentItem.answers.push(answer);

        return contentItem;
    }
}
