import axios from '@/utils/request';
import { LoadingFlag } from '@/store/enums';
import { GetNotificationTokenCountRequest } from '@/store/models/dtos/notificationRequests.dto';
import { channel } from '@/store/modules/channel';

export class NotificationsClient {
    public static async sendNotification(
        title: string,
        body: string,
        messageUrl: string,
        image: string,
        channelCode: string,
    ): Promise<any> {
        const url = `/notifications`;
        const data: any = { title, body, url: messageUrl };

        if (image) {
            data.image = image;
        }

        if (channelCode !== '') {
            data.channelCode = channelCode;
        }

        const result = await axios
            .request<any>({
                url,
                method: 'PUT',
                data,
                errorMessage: 'Could not send notifications',
                loadingId: LoadingFlag.SendNotification,
            })
            .then((o) => o);

        return result as unknown as Promise<any>;
    }

    public static async getNotificationTokenCount(
        data: GetNotificationTokenCountRequest,
    ): Promise<any> {
        let url = `/notifications/count`;
        if (data.channelCode) {
            url = `${url}?channelCode=${data.channelCode}`;
        }

        const result = await axios.request<any>({
            url,
            method: 'GET',
            errorMessage: 'Could not get notifications token count',
        });

        return result as unknown as Promise<any>;
    }
}
