import {
    CommentsRatingStatus,
    CommentsRatingStatusReason,
    ClassificationMode,
    RetrainClassification,
} from '@inconvo/types/enums';
import { CommentToxicity, UserCommentRating, UserCommentReport } from '@inconvo/types/interfaces';

export class UserComment {
    id: string;
    channel: string;
    channelCode: string;
    convo: string;
    contentItem: string;
    contentItemId: number;
    comment: string;
    userId: string;
    status: CommentsRatingStatus;
    statusReason?: CommentsRatingStatusReason;
    retrain: boolean;
    retrainClassification: RetrainClassification;
    rating: UserCommentRating;
    reports: UserCommentReport;
    confidence?: number;
    createdAt: Date;
    updatedAt?: Date;
    toxicity?: CommentToxicity;
    classificationMode: ClassificationMode;
}

export class UserCommentStats {
    total: number;
    status: { [key in CommentsRatingStatus]: number };
    retrain: {
        total: number;
        [RetrainClassification.neutral]: number;
        [RetrainClassification.offensive]: number;
        [RetrainClassification.profanity]: number;
    };
}
