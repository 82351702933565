<template>
    <Icon v-if="name === 'small'" name="spinner" class="spinner" :size="size" :color="color" />
    <Icon
        v-else-if="name === 'branded'"
        name="spinner-branded"
        class="spinner"
        :size="size"
        :color="color"
    />
</template>

<script>
import Icon from './Icon';

export default {
    name: 'Spinner',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: 'small',
        },
        size: {
            type: Number,
            default: 20,
        },
        color: {
            type: String,
            default: undefined,
        },
    },
};
</script>

<style lang="scss" scoped>
.spinner {
    transform: rotate(0deg);
    animation-name: rotate;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
