export enum BadgeType {
    OtherChannel = 'other-channels',
    Channel = 'channel',
    Convo = 'convo',
    Menu = 'menu',
    Message = 'message',
    Progress = 'progress',
    Success = 'success',
    Neutral = 'neutral',
    Error = 'error',
    Info = 'info',
}
