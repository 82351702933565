import { UserRoles } from '@/store/enums';
import config from '@/config';

export default [
    {
        id: 2,
        to: '/channels',
        icon: 'channels',
        role: UserRoles.Editor,
        title: 'All channels',
    },
    {
        id: 10,
        to: '/pdl',
        icon: 'msg-library',
        role: UserRoles.Editor,
        title: 'PDL',
    },
    {
        id: 15,
        to: '/content-library',
        icon: 'books',
        role: UserRoles.Editor,
        title: 'Content Library',
        disabled: !config.featureToggle.contentLibraryEnabled,
    },
    {
        id: 6,
        to: '/tenants',
        icon: 'tenant-management',
        role: UserRoles.Admin,
        title: 'Tenants',
    },
    {
        id: 8,
        to: '/audiences',
        icon: 'audience-builder',
        role: UserRoles.Editor,
        title: 'Audience Builder',
    },
    {
        id: 9,
        to: '/users',
        icon: 'manage-users',
        role: UserRoles.Editor,
        title: 'User Management',
    },
    {
        id: 11,
        to: '/system-notifications',
        icon: 'menu',
        role: UserRoles.Editor,
        title: 'System Notifications',
    },
    {
        id: 12,
        to: '/reports',
        icon: 'reporting',
        role: UserRoles.Editor,
        title: 'Reports',
        disabled: !config.featureToggle.reporting,
    },
    {
        id: 16,
        to: '/convos/topics',
        icon: 'categories-nav',
        role: UserRoles.Editor,
        title: 'Topics',
    },
    {
        id: 13,
        to: '/broadcasts',
        icon: 'broadcast',
        role: UserRoles.Editor,
        title: 'Broadcasts',
    },
    {
        id: 14,
        to: '/user-comments',
        icon: 'user-comments',
        role: UserRoles.Editor,
        title: 'User comments',
        disabled: !config.featureToggle.userComments,
    },
];
