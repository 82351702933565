<template>
    <TemplateVariables
        :sorting-order="data.sortingOrder"
        :variable-list="$options.variables"
        :value="data.message"
        @sortingOrder="setSortingOrder"
        @input="onContentEditableInput"
        @clear="onClear"
    >
        <template v-slot:default="{ inputEvents, htmlValue }">
            <ContentEditable
                :id="generateId('message', path)"
                type="textarea"
                label="Message"
                :value="htmlValue"
                :errors="data.errors && data.errors.message"
                @mouseup="inputEvents.mouseup"
                @keypress="inputEvents.keypress"
                @keyup="inputEvents.keyup"
                @input="inputEvents.input"
                @focus="inputEvents.focus"
                @blur="inputEvents.blur"
            />
        </template>
    </TemplateVariables>
</template>

<script>
import ContentEditable from '@/components/forms/ContentEditable';
import TemplateVariables from '@/components/TemplateVariables';
import itemFormMixin from '@/mixins/itemFormMixin';
import templateVariables from '@/json/templateVariables.ts';

export default {
    name: 'TextGlobalResponse',
    components: {
        TemplateVariables,
        ContentEditable,
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
    },
    created() {
        this.$options.variables = templateVariables;
    },
    methods: {
        setSortingOrder(value) {
            this.data.sortingOrder = value;
            this.onInput('sortingOrder', value);
        },
        onContentEditableInput(value) {
            this.data.message = value;
            this.onInput('message', value);
        },
        onClear() {
            this.data.message = '';
        },
    },
};
</script>
