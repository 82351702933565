<template>
    <div
        class="message-wrapper"
        :class="{
            'message-wrapper--has-avatar': avatarUrl,
            'message-wrapper--has-left-padding': hasLeftPadding,
            'message-wrapper--has-right-padding': hasRightPadding,
        }"
    >
        <div class="message">
            <slot />
        </div>
        <Avatar v-if="avatarUrl" :image-url="avatarUrl" class="message-wrapper-avatar" />
    </div>
</template>

<script>
import Avatar from './Avatar';

export default {
    name: 'MessageWrapper',
    components: {
        Avatar,
    },
    props: {
        avatarUrl: {
            type: String,
            default: undefined,
        },
        imageUrl: {
            type: String,
            default: undefined,
        },
        showArrow: {
            type: Boolean,
            default: true,
        },
        hasLeftPadding: {
            type: Boolean,
            default: true,
        },
        hasRightPadding: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.message-wrapper {
    position: relative;
    margin-bottom: -5px;

    &--has-left-padding {
        padding-left: 26px;
    }

    &--has-right-padding {
        padding-right: 40px;

        @include media-query(medium) {
            padding-right: 30px;
        }
    }

    &--has-avatar {
        margin-bottom: 0px;
    }
}

.message-wrapper-avatar {
    position: absolute;
    bottom: 4px;
    left: 0;
}
</style>
