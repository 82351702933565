import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { cognitoUserExists } from './utils';
import { CLEAR_USER, SET_USER, SET_USER_SESSION } from './mutations.types';
import { IRootState } from '../../types/rootState';
import { Auth } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import Vue from 'vue';

export interface IAuthState {
    user: null | CognitoUser;
}

const state: IAuthState = {
    user: null,
};

const getters: GetterTree<IAuthState, IRootState> = {
    userRoles(state, getters): string[] {
        return getters.idToken ? getters.idToken['cognito:groups'] : [];
    },
    userName(state, getters): string {
        const token = getters.idToken;
        if (!token) {
            return '';
        }
        return token['email'] ? token['email'] : token['cognito:username'];
    },
    isAuthenticated(state): boolean {
        return state.user !== null;
    },
    idToken(state, getters) {
        const session = state?.user?.getSignInUserSession();
        return session?.getIdToken().decodePayload();
    },
    accessToken(state, getters): string | undefined {
        const session = state?.user?.getSignInUserSession();
        return session?.getAccessToken().getJwtToken();
    },
};

const actions: ActionTree<IAuthState, any> = {
    async checkAuthentication({ commit, state }) {
        try {
            if (state.user) {
                return;
            }
            const user: CognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
            commit(SET_USER, user);
        } catch (error) {
            commit(CLEAR_USER);
        }
    },
    async refreshSession({ commit }) {
        const session = await Auth.currentSession();
        commit(SET_USER_SESSION, session);
    },
    async signInFederated({ commit }, provider) {
        Auth.federatedSignIn({ provider });
    },
    async signIn({ commit }, email) {
        try {
            const user: CognitoUser = await Auth.signIn(email);
            Vue.$log.debug(user);
            commit(SET_USER, user);
        } catch (error) {
            Vue.$log.debug(error);
            commit(CLEAR_USER);
            throw 'UserNotFoundException';
        }
    },
    async signUp({}, admin: any) {
        const email = admin.email && admin.email.toLocaleLowerCase();
        const exists = await cognitoUserExists(email);
        if (exists) {
            throw 'UserExistsException';
        }
        await Auth.signUp({
            username: email,
            password: uuid(),
            attributes: {
                email: email,
                name: admin?.name,
            },
        });
    },
    async signOut({ commit }) {
        await Auth.signOut();
        commit(CLEAR_USER);
    },
    async answerAuthChallenge({ commit, state }, code: string) {
        try {
            const user: CognitoUser = await Auth.sendCustomChallengeAnswer(state.user, code);
            commit(SET_USER, user);
        } catch (error) {
            Vue.$log.debug('User entered the wrong code 3 times', error);
            commit(CLEAR_USER);
            throw 'InvalidAuthSession';
        }

        try {
            await Auth.currentSession();
        } catch (error) {
            Vue.$log.debug('User did not enter the right code', error);
            throw 'InvalidAuthCode';
        }
    },
};

const mutations: MutationTree<IAuthState> = {
    [SET_USER](state, payload: CognitoUser) {
        state.user = payload;
    },
    [SET_USER_SESSION](state, payload: CognitoUserSession) {
        state.user?.setSignInUserSession(payload);
    },
    [CLEAR_USER](state) {
        state.user = null;
    },
};

export const auth: Module<IAuthState, IRootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
