import { Message } from './message';
import { RatingOption, IRatingOption } from './ratingOption';
import { MessageType } from './enums/messageType.enum';

export class Rating extends Message {
    text: string;
    answers: IRatingOption[];
    avatarUrl: string;

    constructor(text: string, answers: IRatingOption[], avatarUrl: string) {
        super(MessageType.Rating, avatarUrl);
        this.text = text;
        this.answers = answers.map((a) => new RatingOption(a.label));
    }
}
