import { IAnswer } from './interfaces/answer.interface.dto';
import { IButton } from './interfaces/button.interface';

export class Answer implements IAnswer {
    constructor() {
        this.convoId = 0;
        this.type = '';
        this.text = '';
        this.title = '';
        this.subtitle = '';
        this.link = '';
        this.successDispositionLink = '';
        this.failureDispositionLink = '';
        this.linkTarget = '';
        this.targetChannel = '';
        this.targetConvo = 0;
        this.goToBlock = 0;
        this.targetContentItem = 0;
        this.goToWidget = 0;
        this.goToRecruit = 0;
        this.entity = '';
        this.image = '';
        this.buttons = [];
        this.reply = '';
        this.ignoreQuizAnswer = false;
        this.isCorrectAnswer = false;
        this.quizResultItem = -1;
        this.message = '';
        this.tagline = '';
        this.inviteMessage = '';
        this.inviteTagline = '';
        this.url = '';
        this.imageUrl = '';
        this.buttonText = '';
    }
    id: string;
    convoId: number;
    type?: string;
    text?: string;
    title?: string;
    subtitle?: string;
    link?: string;
    successDispositionLink: string;
    failureDispositionLink: string;
    linkTarget?: string;
    targetChannel?: string;
    targetConvo?: number;
    goToBlock?: number;
    targetContentItem?: number;
    goToWidget?: number;
    goToRecruit?: number;
    entity: string;
    image: string;
    buttons?: IButton[];
    reply?: string;
    ignoreQuizAnswer: boolean;
    isCorrectAnswer: boolean;
    quizResultItem: number;
    message: string;
    tagline: string;
    inviteMessage: string;
    inviteTagline: string;
    url: string;
    imageUrl: string;
    buttonText: string;
    externalId?: string;
    isExclusive?: boolean;
    rating?: number;
}
