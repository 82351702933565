import {
    IContentItem,
    IConvo,
    IMultipleChoiceOption,
    ITag,
    IValidationRules,
    ICommentsRatingTarget,
} from './interfaces';
import { IGraphOptions, IRatingTarget } from './interfaces';
import { IWidgetReply } from './interfaces/widgetReply.interface.dto';
import { IAnswer } from './interfaces/answer.interface.dto';
import { OperationType } from '../enums/operationType.enum';
import { ICommentOptions } from '@inconvo/types/interfaces';

export class ContentItem implements IContentItem {
    operationType: OperationType;
    id?: number;
    createdAt?: Date;
    updatedAt?: Date;
    userId: number;
    botId: number;
    convoId: number;
    convo?: IConvo;
    ratingTarget?: IRatingTarget;
    type: string;
    rating?: number;
    title: string;
    alias: number = 0;
    attachment: string;
    graphOptions?: IGraphOptions;
    graphAttachment: string;
    graphTakenAtCount: number;
    sendGraph: boolean = false;
    rules: string;
    answers: IAnswer[];
    lastAnswer: boolean;
    respondants: number;
    resultsType?: string;
    resultsCache: string;
    reply: IWidgetReply;
    shuffleAnswers: boolean = false;
    shuffleLast: boolean = false;
    sort: number;
    isSegmentRule: boolean = false;
    isPdl: boolean = false;
    shouldSkipIfAnswered: boolean = true;
    archived: boolean = false;
    version: string = '';
    multipleChoice: boolean = false;
    multipleChoiceOptions: IMultipleChoiceOption;
    tags?: ITag[];
    externalId?: string;
    validationRules?: IValidationRules;
    targetTextInputs?: ICommentsRatingTarget;
    children?: IContentItem[];
    commentOptions?: ICommentOptions;
    datumDefinition?: string;
    isPii: boolean | null;
}
