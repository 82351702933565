import xss from 'xss';

export const sanitize = (text: string, optionsParam?: XSS.IFilterXSSOptions) => {
    const options = {
        whiteList: {},
        stripIgnoreTag: true,
        stripIgnoreTagBody: ['script'],
        ...optionsParam,
    };

    return xss(text, options);
};
