<template>
    <header class="page-heading">
        <Breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" class="page-heading__breadcrumbs" />

        <h1 class="page-heading__title">{{ title }}</h1>
    </header>
</template>

<script>
import Breadcrumbs from '@/components/ui/Breadcrumbs';

export default {
    name: 'PageHeading',
    components: {
        Breadcrumbs,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        breadcrumbs: {
            type: Array,
            default: undefined,
        },
    },
};
</script>

<style scoped lang="scss">
.page-heading {
    margin-bottom: 24px;

    &__breadcrumbs {
        margin-bottom: 8px;
    }

    &__title {
        font-size: 26px;
        font-weight: $font-weight-black;
        color: $title-text-color;
    }
}
</style>
