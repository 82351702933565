import Vue from 'vue';
import { GetChannelsRequest, RuleDto, ListChannel as ChannelDto } from '@/store/models/dtos';
import { IPaging } from '@inconvo/types/interfaces';
import { Rule } from '@/store/models/audience/rule';
import { ChannelClient } from '@/api/channel';

interface IChannelObject {
    id: string;
    text: string;
}

interface IGetContentOptions {
    code?: string;
    query?: string;
}
export class ChannelRule extends Rule {
    content: IChannelObject[];

    constructor(data: RuleDto) {
        super(data);
    }

    async getContent(options: IGetContentOptions): Promise<any> {
        let channels: IChannelObject[] = [];

        try {
            const code = options?.code || this.value;

            if (options?.query) {
                channels = await this.getChannels(options.query);
            } else if (code) {
                const channel = await this.getChannel(code);
                channels = [channel];
            }
        } catch (error) {
            Vue.$log.error(error);
        }
        this.content = channels;
        return channels;
    }

    getSummaryTitle(): string {
        const channelList = this.content || [];
        const channel = channelList.find((channel: IChannelObject) => channel.id === this.value);
        if (channel) {
            return channel.text;
        }
        return this.value;
    }

    private async getChannel(code: string): Promise<IChannelObject> {
        const channel = await ChannelClient.getChannel(code);
        return {
            id: channel.code,
            text: channel.name,
        };
    }

    private async getChannels(query: string) {
        const request = new GetChannelsRequest();
        request.page = 1;
        request.size = 10;
        request.q = query;
        const result: IPaging<ChannelDto> = await ChannelClient.getChannels(request);
        const channels: IChannelObject[] = result.items.map((channel) => ({
            id: channel.code,
            text: channel.name,
        }));
        return channels;
    }
}
