import axios from '@/utils/request';
import { LoadingFlag } from '@/store/enums/loadingIds.enum';
import { ITag } from '@/store/models/interfaces';
import { TagType } from '@/store/enums';

export interface IGetTagsRequest {
    query?: string;
    type?: TagType;
}
export class TagClient {
    async fetch(params: IGetTagsRequest) {
        return await axios.request<IGetTagsRequest, ITag[]>({
            url: '/tags',
            method: 'GET',
            params,
            errorMessage: 'Could not load tags',
            loadingId: LoadingFlag.TagGet,
        });
    }

    async create(name: string, type: TagType): Promise<ITag> {
        const url = `tags`;
        return await axios.request<{ name: string; type: TagType }, ITag>({
            data: {
                name: name.toLowerCase(),
                type,
            },
            url: url,
            method: 'POST',
            loadingId: LoadingFlag.TagCreate,
        });
    }
}
