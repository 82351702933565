<template>
    <div class="tab">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Tab',
    props: {
        name: {
            type: String,
            required: true,
        },
        isPinned: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.tab {
    padding-top: 20px;
    background: $tabs-background-color;
}
</style>
