import { validate, IsNotEmpty, Length, ValidationError, IsUrl } from 'class-validator';
import { AccordionItemBase } from './accordionItemBase';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum.ts';
import { ListMarker } from '@/store/enums/listMarker.enum';
import { getErrorMessage } from '@/store/services/validationService';

export class StandardShareMessage extends AccordionItemBase {
    constructor(items = []) {
        super(items);
        this.type = ConvoMessageItemType.StandardShareMessage;
        this.isDraggable = false;
        this.isDeletable = false;
        this.isOpen = false;
        this.markerType = ListMarker.None;
    }

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Message') })
    @Length(0, 80, { message: getErrorMessage('length', 'Message', { min: 0, max: 80 }) })
    message: string = '';
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Tagline') })
    @Length(0, 80, { message: getErrorMessage('length', 'Tagline', { min: 0, max: 80 }) })
    tagline: string = '';
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Invite Message') })
    @Length(0, 45, { message: getErrorMessage('length', 'Invite Message', { min: 0, max: 80 }) })
    inviteMessage: string = '';
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Invite Tagline') })
    @Length(0, 48, { message: getErrorMessage('length', 'Invite Tagline', { min: 0, max: 80 }) })
    inviteTagline: string = '';
    @IsUrl({}, { message: getErrorMessage('isUrl', 'Link') })
    link: string = '';
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Image') })
    imageUrl: string = '';
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Button Text') })
    @Length(0, 49, { message: getErrorMessage('length', 'Button Text', { min: 0, max: 80 }) })
    buttonText: string = '';

    async validate(): Promise<any> {
        const errors: ValidationError[] = await validate(this);
        const errorMessages: any = {};

        if (errors.length > 0) {
            errors.forEach((item: ValidationError) => {
                errorMessages[item.property] = Object.values(item.constraints || {});
            });
        }

        return errorMessages;
    }
}
