import { AnswerType, ContentItemType, LinkTarget } from '@/store/enums/';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum';
import { IConvoState } from '@/store/modules/convo-details';
import { ButtonsAnswer, ContentItem, InlineResponseTextStatement } from '..';
import { Answer } from '../answer.dto';
import { IAnswer, IWidgetReply, IContentItem } from '../interfaces';
import { YougovWelcomeSurveyButtons } from '../ygWelcomeSurvey';

export class YougovWelcomeSurveyButtonsContentItemFactory {
    public make(obj: YougovWelcomeSurveyButtons, state: IConvoState): IContentItem {
        const contentItem = new ContentItem();
        contentItem.id = obj.id;
        contentItem.sort = obj.sort;
        contentItem.title = obj.statement;
        contentItem.type = ContentItemType.YougovWelcomeSurveyButtons;
        contentItem.tags = obj.tags || [];

        contentItem.externalId = obj.externalId || '';
        contentItem.answers = [];

        const answers = obj.items[0].items.filter(
            (i) => i.type === ConvoMessageItemType.ButtonsAnswer,
        );

        for (const buttonsAnswer of answers as ButtonsAnswer[]) {
            const answer: IAnswer = new Answer();
            answer.convoId = state.convo.id;
            answer.id = buttonsAnswer.buttonId;
            answer.type = buttonsAnswer.answerType.name;
            answer.text = buttonsAnswer.buttonLabel;
            answer.externalId = buttonsAnswer.externalId || '';
            answer.link = buttonsAnswer.answerType.linkRequired ? buttonsAnswer.link : '';
            answer.successDispositionLink =
                buttonsAnswer.answerType.linkRequired &&
                buttonsAnswer.answerType.name === AnswerType.LinkNextDisposition
                    ? buttonsAnswer.successDispositionLink
                    : '';
            answer.failureDispositionLink =
                buttonsAnswer.answerType.linkRequired &&
                buttonsAnswer.answerType.name === AnswerType.LinkNextDisposition
                    ? buttonsAnswer.failureDispositionLink
                    : '';

            if (buttonsAnswer.answerType.linkRequired) {
                answer.linkTarget = this.getLinkButtonTarget(buttonsAnswer);
            }

            answer.targetChannel = buttonsAnswer.logicJump?.items?.channel?.code || '';
            answer.targetConvo = buttonsAnswer.logicJump?.items?.convo?.id || 0;
            answer.targetContentItem = buttonsAnswer.logicJump?.items?.message?.id || 0;
            answer.goToBlock =
                !answer.targetContentItem && answer.targetConvo ? answer.targetConvo : 0;
            answer.goToWidget =
                answer.targetConvo && answer.targetContentItem ? answer.targetContentItem : 0;

            if (
                buttonsAnswer.items?.length > 0 &&
                buttonsAnswer.items[0].items?.length > 0 &&
                buttonsAnswer.items[0].items[0].type ===
                    ConvoMessageItemType.InlineResponseTextStatement
            ) {
                answer.reply = (
                    buttonsAnswer.items[0].items[0] as InlineResponseTextStatement
                ).statement;
            }

            contentItem.answers.push(answer);
        }

        contentItem.reply = {} as IWidgetReply;
        contentItem.reply.activated = false;
        contentItem.reply.message = '';

        return contentItem;
    }

    private getLinkButtonTarget(buttonsAnswer: ButtonsAnswer): string {
        if (buttonsAnswer.linkOpenInNewTab) {
            return LinkTarget.Blank;
        }
        if (buttonsAnswer.answerType.name === AnswerType.Link) {
            return LinkTarget.Internal;
        }
        if (buttonsAnswer.answerType.name === AnswerType.ReplyDisposition) {
            return LinkTarget.Silent;
        }
        return LinkTarget.Modal;
    }
}
