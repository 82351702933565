<template>
    <ModalBaseAuth>
        <form @submit.prevent="onSubmit">
            <p class="modal-text m-b-3">
                Please enter the 6-digit verification code below from the email we just sent you.
            </p>

            <CodeInput
                ref="codeInput"
                v-model="code"
                label="Verification code"
                :input-length="codeLength"
            />

            <Button
                id="sign-in-modal-submit-button"
                type="submit"
                class="m-t-2"
                has-border
                :disabled="disableSubmit"
                :full-width="true"
                :is-loading="loading"
                data-cy="auth-code-submit"
            >
                Continue
            </Button>
        </form>
    </ModalBaseAuth>
</template>

<script>
import ModalBaseAuth from '@/components/Modal/ModalBaseAuth';
import { CodeInput } from '@/components/forms';
import Button from '@/components/ui/Button';

export default {
    name: 'ModalAuthChallenge',
    components: {
        ModalBaseAuth,
        CodeInput,
        Button,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            code: '',
            codeLength: 6,
        };
    },
    computed: {
        disableSubmit() {
            return this.code.length < this.codeLength;
        },
    },
    mounted() {
        this.$refs.codeInput.focus();
    },
    methods: {
        onSubmit() {
            this.$emit('answerAuthChallenge', this.code);
            this.code = '';
            this.$refs.codeInput.focus();
        },
    },
};
</script>

<style scoped lang="scss">
.modal-text {
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
    font-size: $font-size-sm;
}
</style>
