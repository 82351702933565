import { Themes } from '@/store/enums';

export default {
    baseUrl: process.env.VUE_APP_INCONVO_CHAT_URL,
    cdnUrl: process.env.VUE_APP_CDN_URL as string,
    dateFormat: 'EEE do, MMM yyyy | HH:mm',
    dateTimeFormat: 'dd/MM/yyyy | HH:mm',
    featureToggle: {
        convoEndLogic: process.env.VUE_APP_FEATURE_CONVO_END_LOGIC === 'true',
        reporting: process.env.VUE_APP_FEATURE_REPORTING === 'true',
        editableSlug: process.env.VUE_APP_EDITABLE_SLUG === 'true',
        customReminderEmail: process.env.VUE_APP_CUSTOM_REMINDER_EMAIL === 'true',
        otpSignIn: process.env.VUE_APP_FEATURE_OTP_SIGN_IN === 'true',
        inviteAdmin: process.env.VUE_APP_FEATURE_INVITE_ADMIN === 'true',
        contentDiscoveryMultipleMenus:
            process.env.VUE_APP_FEATURE_CONTENT_DISCOVERY_MENU_MULTIPLE === 'true',
        richTextMessagesEnabled: process.env.VUE_APP_FEATURE_RICH_TEXT_MESSAGES_ENABLED === 'true',
        commentsRatingMessageType:
            process.env.VUE_APP_FEATURE_COMMENTS_RATING_MESSAGE_TYPE === 'true',
        userComments: process.env.VUE_APP_FEATURE_USER_COMMENTS === 'true',
        giphyMessageType: process.env.VUE_APP_FEATURE_GIF_MESSAGE_TYPE === 'true',
        imageMessageType: process.env.VUE_APP_FEATURE_IMAGE_MESSAGE_TYPE === 'true',
        showPollCount: process.env.VUE_APP_FEATURE_SHOW_POLL_COUNT === 'true',
        enableAdvanceEmailAutomation:
            process.env.VUE_APP_FEATURE_ADVANCE_EMAIL_AUTOMATION === 'true',
        carouselStatement: process.env.VUE_APP_FEATURE_CAROUSEL_STATEMENT === 'true',
        contentLibraryEnabled: process.env.VUE_APP_FEATURE_CONTENT_LIBRARY_ENABLED === 'true',
        channelYougovPreferencesEnabled:
            process.env.VUE_APP_FEATURE_CHANNEL_YOUGOV_PREFERENCES_ENABLED === 'true',
        audienceAthenaQuery: process.env.VUE_APP_FEATURE_AUDIENCE_ATHENA_QUERY === 'true',
        widgetSnippet: process.env.VUE_APP_FEATURE_WIDGET_SNIPPET === 'true',
        showDailyChat: process.env.VUE_APP_FEATURE_SHOW_DAILY_CHAT === 'true',
        channelWelcomeChatPreferenceEnabled:
            process.env.VUE_APP_FEATURE_CHANNEL_WELCOME_CHAT_PREFERENCE_ENABLED === 'true',
        textInputCorrelation: process.env.VUE_APP_FEATURE_TEXT_INPUT_CORRELATION === 'true',
        tenantLinkTargetEnabled: process.env.VUE_APP_FEATURE_TENANT_LINK_TARGET_ENABLED === 'true',
    },
    comments: {
        confidenceScoreThreshold: 80,
    },
    theme: process.env.VUE_APP_THEME || Themes.defaultTheme,
};
