<template>
    <div class="content-list">
        <div class="content-list__header">
            <p>{{ listHeaderText }}</p>

            <button class="content-list__clear" @click="onClear">
                {{ clearButtonText }}
            </button>
        </div>

        <div class="content-list__items">
            <ContentSelectorItem
                v-if="content.channel"
                class="content-list-item"
                :is-top-level="true"
                type="channel"
                label="Channel"
                :item="content.channel"
                :fixed="fixedChannel"
                @remove="() => onRemove('channel')"
            />

            <ContentSelectorItem
                v-if="content.convo"
                class="content-list-item"
                :label="convoLabel"
                :type="content.convo.type"
                :item="content.convo"
                :fixed="fixedConvo"
                @remove="() => onRemove('convo')"
            />

            <ContentSelectorItem
                v-if="content.message"
                class="content-list-item"
                type="message"
                label="Message"
                :item="content.message"
                @remove="() => onRemove('message')"
            />
        </div>
    </div>
</template>

<script>
import ContentSelectorItem from './ContentSelectorItem';
import { ContentType } from '@/store/enums';

export default {
    name: 'ContentSelectorList',
    components: {
        ContentSelectorItem,
    },
    props: {
        content: {
            type: Object,
            required: true,
        },
        clearButtonText: {
            type: String,
            required: true,
        },
        listHeaderText: {
            type: String,
            required: true,
        },
        fixedChannel: {
            type: Boolean,
            required: true,
        },
        fixedConvo: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        convoLabel() {
            return this.content.convo.type === ContentType.Menu ? 'Menu' : 'Convo';
        },
    },
    methods: {
        onRemove(contentKey) {
            this.$emit('removeItem', contentKey);
        },
        onClear() {
            let key = ContentType.Channel;
            if (this.fixedChannel) {
                key = ContentType.Convo;
            }
            if (this.fixedConvo) {
                key = ContentType.Message;
            }
            this.onRemove(key);
        },
    },
};
</script>

<style lang="scss" scoped>
.content-list-item {
    &:not(:last-child) {
        margin-bottom: 10px;
    }

    &:nth-child(2) {
        margin-left: 40px;
    }

    &:nth-child(3) {
        margin-left: 80px;
    }
}

.content-list {
    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
        font-size: 12px;
    }

    &__clear {
        padding: 4px 0;
        font-size: 10px;
        color: #aeb5c8;
        text-transform: uppercase;
        cursor: pointer;
    }
}

.logic-jump-items-loading {
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
