import { ChannelStatus } from '@/store/enums/channelStatus.enum';
import { ConvoSchedule } from '../convoSchedule';
import { ICategory, IPlatform, ITag } from '../interfaces';
import { ChannelLandingPageByLocale } from './channelLandingPageByLocale.dto';
import { YougovWelcomeFlowType } from '@inconvo/types/enums';

export class Channel {
    id: number;
    code: string;
    slug: string;
    name: string;
    description: string;
    portraitImage: string;
    landscapeImage: string;
    squareImage: string;
    tagLine: string;
    sharingTitle: string;
    sharingDescription: string;
    showMemberCount: boolean;
    status: ChannelStatus;
    tags: ITag[];
    topics: ITag[];
    categoryId?: number;
    color: string;
    category: ICategory;
    platforms: IPlatform[];
    welcomeMessage: WelcomeMessage;
    fbPageId: string;
    fbAppId: string;
    fbVerifyToken: string;
    fbAccessToken: string;
    style?: IChannelStyle;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    redirectUrl?: string;
    originalVersion: string;
    isFeatured: boolean;
    isStandalone: boolean;
    landingPage: ChannelLandingPageByLocale;
    convoSchedule: ConvoSchedule;
    emailVariables: Record<string, string>;
    emailLocale: string;
    timezoneIdentifier: string;
    yougovPreferences: IChannelYougovPreferencesDto;
}

export class WelcomeMessage {
    message: string;
    contentDiscoveryItem: number;
}

export class IChannelStyle {
    constructor(style?: any) {
        this.description = style?.description || '';
        this.color = style?.color || '';
        this.link = style?.link || '';
        this.background = style?.background || '';
        this.bgImage = style?.bgImage || '';
        this.logo = style?.logo || '';
        this.linkColor = style?.linkColor || '';
        this.background = style?.background || '';
        this.logoImage = style?.logoImage || '';
        this.textColor = style?.textColor || '';
        this.backgroundImage = style?.backgroundImage || '';
        this.wideLogoImage = style?.wideLogoImage || '';
        this.chartTitleColor = style?.chartTitleColor || '';
        this.chartTextColor = style?.chartTextColor || '';
        this.chartColorA = style?.chartColorA || '';
        this.chartColorB = style?.chartColorB || '';
        this.hasMenu = style?.hasMenu || '';
        this.noInput = style?.noInput || '';
        this.menuItems = style?.menuItems || [];
        this.quickEmbedBlock = style?.quickEmbedBlock || 0;
        this.memberContactPrefs = style?.memberContactPrefs || {};
    }

    description: string;
    color: string;
    link: string;
    background: string;
    bgImage: string;
    logo: string;
    linkColor: string;
    backgroundColor: string;
    logoImage: string;
    textColor: string;
    backgroundImage: string;
    wideLogoImage: string;
    chartTitleColor: string;
    chartTextColor: string;
    chartColorA: string;
    chartColorB: string;
    hasMenu: boolean;
    noInput: boolean;
    menuItems: ChannelMenuItem[];
    quickEmbedBlock: number;
    memberContactPrefs: ChannelContactPreferences;
}

export class ChannelMenuItem {
    title: string;
    type: string;
    menuItems: ChannelMenuItem[];
    link: string;
}

export class ChannelContactPreferences {
    disableMessenger: boolean;
    disableEmail: boolean;
    promptText: string;
}

export interface IChannelYougovPreferencesPanelDto {
    name: string;
    language: string;
}

export interface IChannelYougovPreferencesWelcomeFlowDto {
    type: YougovWelcomeFlowType;
    id: number;
    name: string;
    panel: IChannelYougovPreferencesPanelDto;
    weight: number;
}
export interface IChannelYougovPreferencesDto {
    welcomeFlows: IChannelYougovPreferencesWelcomeFlowDto[];
}
