<template>
    <QuickReplyAnswer
        v-if="item.type === ConvoMessageItemType.QuickReplyAnswer"
        :data="item"
        :path="[...path, index]"
        :index="index"
        :marker="getMarker(item.marker, item.markerType, startIndex, index)"
        :is-draggable="isDraggable"
        :animation-disabled="drag"
        :current-channel="currentChannel"
        :current-convo="currentConvo"
        @headerOnClick="(event, path) => $emit('headerOnClick', event, path)"
        @onExpand="(path) => $emit('onExpand', path)"
        @onExpanded="(path) => $emit('onExpanded', path)"
        @onContract="(path) => $emit('onContract', path)"
        @onContracted="(path) => $emit('onContracted', path)"
        @deleteOnClick="
            (event, path, parentPath) => $emit('deleteOnClick', event, path, parentPath)
        "
        @cloneOnClick="(event, path, parentPath) => $emit('cloneOnClick', event, path, parentPath)"
        @onDrop="(path, value) => $emit('onDrop', path, value)"
        @messageTypeOnSelect="
            (event, path, index) => $emit('messageTypeOnSelect', event, path, index)
        "
        @addItemOnClick="(event, path, type) => $emit('addItemOnClick', event, path, type)"
        @propertyOnChange="$emit('propertyOnChange', $event)"
    />
    <CarouselTile
        v-else-if="item.type === ConvoMessageItemType.CarouselTile"
        :data="item"
        :path="[...path, index]"
        :index="index"
        :marker="getMarker(item.marker, item.markerType, startIndex, index)"
        :is-draggable="isDraggable"
        :animation-disabled="drag"
        :current-channel="currentChannel"
        :current-convo="currentConvo"
        @openLogicJump="$emit('openLogicJump', $event)"
        @headerOnClick="subItemHeaderOnClick"
        @onExpand="subItemOnExpand"
        @onExpanded="subItemOnExpanded"
        @onContract="subItemOnContract"
        @onContracted="subItemOnContracted"
        @deleteOnClick="deleteOnClick"
        @cloneOnClick="cloneOnClick"
        @onDrop="onDrop"
        @messageTypeOnSelect="messageTypeOnSelect"
        @propertyOnChange="$emit('propertyOnChange', $event)"
        @addItemOnClick="(event, path, type) => $emit('addItemOnClick', event, path, type)"
        @addAttribute="(payload) => $emit('addAttribute', payload)"
        @removeAttribute="(payload) => $emit('removeAttribute', payload)"
    />
    <AccordionItem
        v-else
        :open="item.isOpen"
        :is-draggable="isDraggable"
        :is-deletable="item.isDeletable"
        :is-openable="item.isOpenable"
        :is-pdl="item.isPdl"
        :marker="getMarker(item.marker, item.markerType, startIndex, index)"
        :is-valid="item.isValid"
        :icon="item.icon"
        :animation-disabled="drag"
        :accordion-style="item.accordionStyle"
        :has-margin-bottom="item.hasMarginBottom"
        :is-deleted="item.isDeleted"
        :is-new="item.isNew"
        :body-has-large-padding="item.type !== ConvoMessageItemType.Result && path.length === 0"
        @headerOnClick="$emit('headerOnClick', $event, [...path, index])"
        @onExpand="$emit('onExpand', [...path, index])"
        @onExpanded="$emit('onExpanded', [...path, index])"
        @onContract="$emit('onContract', [...path, index])"
        @onContracted="$emit('onContracted', [...path, index])"
        @deleteOnClick="$emit('deleteOnClick', $event, [...path, index])"
        @cloneOnClick="$emit('cloneOnClick', $event, [...path, index])"
        @undoDeletedItemOnClick="$emit('undoDeletedItemOnClick', $event, [...path, index])"
    >
        <template #header>
            <AccordionItemHeader
                v-if="item.type === ConvoMessageItemType.Result"
                :title="`Result ${getMarker(item.marker, item.markerType, startIndex, index)}`"
                :subtitle="item.title"
                :icon="item.icon"
                :is-deleted="item.isDeleted"
            />
            <AccordionItemHeader
                v-else
                :title="item.title"
                :subtitle="item.subtitle"
                :icon="item.icon"
                :is-deleted="item.isDeleted"
            />
        </template>

        <template>
            <SelectMessageType
                v-if="item.type === ConvoMessageItemType.SelectMessageType"
                :data="item"
                :message-types="item.options"
                :accordion-style="item.accordionStyle"
                :path="[...path, index]"
                @messageTypeOnSelect="messageTypeOnSelect"
            />
            <TextStatement
                v-else-if="item.type === ConvoMessageItemType.TextStatement"
                :data="item"
                :path="[...path, index]"
                :current-channel="currentChannel"
                :current-convo="currentConvo"
                :is-pdl="item.isPdl"
                @propertyOnChange="$emit('propertyOnChange', $event)"
            />
            <QuickReply
                v-else-if="item.type === ConvoMessageItemType.QuickReply"
                :data="item"
                :path="[...path, index]"
                :current-channel="currentChannel"
                :current-convo="currentConvo"
                :is-disabled="item.isPdl"
                :is-pdl="item.isPdl"
                :is-external-pdl="item.isExternalPdl"
                :yougov-definitions="yougovDefinitions"
                @headerOnClick="subItemHeaderOnClick"
                @onExpand="subItemOnExpand"
                @onExpanded="subItemOnExpanded"
                @onContract="subItemOnContract"
                @onContracted="subItemOnContracted"
                @deleteOnClick="deleteOnClick"
                @cloneOnClick="cloneOnClick"
                @openLogicJump="$emit('openLogicJump', $event)"
                @onDrop="onDrop"
                @messageTypeOnSelect="messageTypeOnSelect"
                @propertyOnChange="$emit('propertyOnChange', $event)"
                @addItemOnClick="(event, path, type) => $emit('addItemOnClick', event, path, type)"
                @pinAnswer="(path, isPinned) => $emit('pinAnswer', path, isPinned)"
                @ignoreQuizQuestion="
                    (path, isPinned) => $emit('ignoreQuizQuestion', path, isPinned)
                "
                @addAttribute="(payload) => $emit('addAttribute', payload)"
                @removeAttribute="(payload) => $emit('removeAttribute', payload)"
            />
            <Buttons
                v-else-if="
                    item.type === ConvoMessageItemType.Buttons ||
                    item.type === ConvoMessageItemType.YougovWelcomeSurveyButtons
                "
                :data="item"
                :path="[...path, index]"
                :current-channel="currentChannel"
                :current-convo="currentConvo"
                :is-disabled="item.isPdl"
                :is-pdl="item.isPdl"
                :is-external-pdl="item.isExternalPdl"
                :yougov-definitions="yougovDefinitions"
                @openLogicJump="$emit('openLogicJump', $event)"
                @headerOnClick="subItemHeaderOnClick"
                @onExpand="subItemOnExpand"
                @onExpanded="subItemOnExpanded"
                @onContract="subItemOnContract"
                @onContracted="subItemOnContracted"
                @deleteOnClick="deleteOnClick"
                @cloneOnClick="cloneOnClick"
                @onDrop="onDrop"
                @messageTypeOnSelect="messageTypeOnSelect"
                @propertyOnChange="$emit('propertyOnChange', $event)"
                @addItemOnClick="(event, path, type) => $emit('addItemOnClick', event, path, type)"
                @ignoreQuizQuestion="
                    (path, isPinned) => $emit('ignoreQuizQuestion', path, isPinned)
                "
                @addAttribute="(payload) => $emit('addAttribute', payload)"
                @removeAttribute="(payload) => $emit('removeAttribute', payload)"
            />
            <StandardShareMessage
                v-else-if="item.type === ConvoMessageItemType.StandardShareMessage"
                :data="item"
                :path="[...path, index]"
                @propertyOnChange="$emit('propertyOnChange', $event)"
            />
            <TextInput
                v-else-if="item.type === ConvoMessageItemType.TextInput"
                :data="item"
                :path="[...path, index]"
                :current-channel="currentChannel"
                :current-convo="currentConvo"
                :yougov-definitions="yougovDefinitions"
                :show-correlator="showCorrelator"
                @propertyOnChange="$emit('propertyOnChange', $event)"
                @headerOnClick="subItemHeaderOnClick"
                @onExpand="subItemOnExpand"
                @onExpanded="subItemOnExpanded"
                @onContract="subItemOnContract"
                @onContracted="subItemOnContracted"
                @deleteOnClick="deleteOnClick"
                @cloneOnClick="cloneOnClick"
                @onDrop="onDrop"
                @messageTypeOnSelect="messageTypeOnSelect"
                @openCorrelationContent="$emit('openCorrelationContent', $event)"
                @closeCorrelationContentSeletor="$emit('closeCorrelationContentSeletor')"
            />
            <CommentsRating
                v-else-if="item.type === ConvoMessageItemType.CommentsRating"
                :data="item"
                :path="[...path, index]"
                :current-channel="currentChannel"
                :current-convo="currentConvo"
                @propertyOnChange="$emit('propertyOnChange', $event)"
                @addItemOnClick="(event, path, type) => $emit('addItemOnClick', event, path, type)"
                @headerOnClick="subItemHeaderOnClick"
                @onExpand="subItemOnExpand"
                @onExpanded="subItemOnExpanded"
                @onContract="subItemOnContract"
                @onContracted="subItemOnContracted"
                @deleteOnClick="deleteOnClick"
                @cloneOnClick="cloneOnClick"
                @onDrop="onDrop"
                @openLogicJump="$emit('openLogicJump', $event)"
                @messageTypeOnSelect="messageTypeOnSelect"
            />
            <TextGlobalResponse
                v-else-if="item.type === ConvoMessageItemType.TextGlobalResponse"
                :data="item"
                :path="[...path, index]"
                @propertyOnChange="$emit('propertyOnChange', $event)"
            />
            <InlineResponse
                v-else-if="item.type === ConvoMessageItemType.InlineResponse"
                :data="item"
                :path="[...path, index]"
                @propertyOnChange="$emit('propertyOnChange', $event)"
                @addItemOnClick="(event, path, type) => $emit('addItemOnClick', event, path, type)"
                @deleteOnClick="deleteOnClick"
            />
            <AttachmentUrl
                v-else-if="item.type === ConvoMessageItemType.AttachmentUrl"
                :data="item"
                :path="[...path, index]"
                :current-channel="currentChannel"
                :current-convo="currentConvo"
                @propertyOnChange="$emit('propertyOnChange', $event)"
            />
            <ImageMessageItem
                v-else-if="item.type === ConvoMessageItemType.Image"
                :data="item"
                :path="[...path, index]"
                :current-channel="currentChannel"
                :current-convo="currentConvo"
                @propertyOnChange="$emit('propertyOnChange', $event)"
            />
            <Video
                v-else-if="item.type === ConvoMessageItemType.Video"
                :data="item"
                :path="[...path, index]"
                :current-channel="currentChannel"
                :current-convo="currentConvo"
                @propertyOnChange="$emit('propertyOnChange', $event)"
            />
            <Gif
                v-else-if="item.type === ConvoMessageItemType.Gif"
                :data="item"
                :path="[...path, index]"
                :current-channel="currentChannel"
                :current-convo="currentConvo"
                @propertyOnChange="$emit('propertyOnChange', $event)"
            />
            <Carousel
                v-else-if="item.type === ConvoMessageItemType.Carousel"
                :data="item"
                :path="[...path, index]"
                :are-message-items-valid="areMessageItemsValid"
                :current-channel="currentChannel"
                :current-convo="currentConvo"
                @openLogicJump="$emit('openLogicJump', $event)"
                @headerOnClick="subItemHeaderOnClick"
                @onExpand="subItemOnExpand"
                @onExpanded="subItemOnExpanded"
                @onContract="subItemOnContract"
                @onContracted="subItemOnContracted"
                @deleteOnClick="deleteOnClick"
                @cloneOnClick="cloneOnClick"
                @onDrop="onDrop"
                @messageTypeOnSelect="messageTypeOnSelect"
                @propertyOnChange="$emit('propertyOnChange', $event)"
                @pinItem="(path) => $emit('pinItem', path)"
                @addItemOnClick="(event, path, type) => $emit('addItemOnClick', event, path, type)"
                @ignoreQuizQuestion="
                    (path, isPinned) => $emit('ignoreQuizQuestion', path, isPinned)
                "
                @addAttribute="(payload) => $emit('addAttribute', payload)"
                @removeAttribute="(payload) => $emit('removeAttribute', payload)"
            />
            <Registration
                v-else-if="item.type === ConvoMessageItemType.Registration"
                :data="item"
                :path="[...path, index]"
                :prefix="item.prefix"
                @propertyOnChange="$emit('propertyOnChange', $event)"
            />
            <DatePicker
                v-else-if="item.type === ConvoMessageItemType.DatePicker"
                :data="item"
                :path="[...path, index]"
                :is-disabled="item.isPdl"
                :is-pdl="item.isPdl"
                :is-external-pdl="item.isExternalPdl"
                :current-convo="currentConvo"
                :yougov-definitions="yougovDefinitions"
                @propertyOnChange="$emit('propertyOnChange', $event)"
                @headerOnClick="subItemHeaderOnClick"
                @onExpand="subItemOnExpand"
                @onExpanded="subItemOnExpanded"
                @onContract="subItemOnContract"
                @onContracted="subItemOnContracted"
                @deleteOnClick="deleteOnClick"
                @cloneOnClick="cloneOnClick"
                @onDrop="onDrop"
                @messageTypeOnSelect="messageTypeOnSelect"
            />
            <InlineResponseTextStatement
                v-else-if="item.type === ConvoMessageItemType.InlineResponseTextStatement"
                :data="item"
                :path="[...path, index]"
                @propertyOnChange="$emit('propertyOnChange', $event)"
            />
            <Result
                v-else-if="item.type === ConvoMessageItemType.Result"
                :marker="getMarker(item.marker, item.markerType, startIndex, index)"
                :data="item"
                :path="[...path, index]"
                @propertyOnChange="$emit('propertyOnChange', $event)"
                @deleteOnClick="deleteOnClick"
            />
            <Rating
                v-else-if="item.type === ConvoMessageItemType.Rating"
                :data="item"
                :path="[...path, index]"
                :convo-categories="convoCategories"
                :is-deletable="item.isDeletable"
                @onDrop="onDrop"
                @deleteOnClick="deleteOnClick"
                @addItemOnClick="(event, path, type) => $emit('addItemOnClick', event, path, type)"
                @propertyOnChange="$emit('propertyOnChange', $event)"
                @openLogicJump="$emit('openLogicJump', $event)"
                @removeAttribute="$emit('removeAttribute', $event)"
                @addAttribute="$emit('addAttribute', $event)"
            />
            <Accordion
                v-else-if="item.items"
                :items="item.items"
                :path="[...path, index]"
                :is-draggable="!item.areAnswersShuffled"
                :current-channel="currentChannel"
                :current-convo="currentConvo"
                @headerOnClick="subItemHeaderOnClick"
                @onExpand="subItemOnExpand"
                @onExpanded="subItemOnExpanded"
                @onContract="subItemOnContract"
                @onContracted="subItemOnContracted"
                @deleteOnClick="deleteOnClick"
                @cloneOnClick="cloneOnClick"
                @onDrop="onDrop"
                @messageTypeOnSelect="messageTypeOnSelect"
                @addItemOnClick="(event, path, type) => $emit('addItemOnClick', event, path, type)"
            />
        </template>
    </AccordionItem>
</template>

<script>
import AccordionItem from '@/components/ui/Accordion/AccordionItem';
import AccordionItemHeader from '@/components/ui/Accordion/AccordionItemHeader';
import SelectMessageType from '@/components/SelectMessageType';
import TextStatement from '@/components/TextStatement';
import QuickReply from '@/components/QuickReply';
import QuickReplyAnswer from '@/components/QuickReplyAnswer';
import StandardShareMessage from '@/components/StandardShareMessage';
import TextGlobalResponse from '@/components/TextGlobalResponse';
import { getMarker } from '@/utils/listMarkers.ts';
import TextInput from '@/components/TextInput';
import InlineResponse from '@/components/InlineResponse';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum.ts';
import AttachmentUrl from '@/components/AttachmentUrl';
import ImageMessageItem from '@/components/ImageMessageItem';
import Rating from '@/components/Rating.vue';
import Buttons from '@/components/Buttons';
import Carousel from '@/components/Carousel';
import CarouselTile from '@/components/CarouselTile';
import InlineResponseTextStatement from '@/components/InlineResponseTextStatement';
import Result from '@/components/Result';
import Registration from '@/components/Registration';
import DatePicker from '@/components/DatePicker/index';
import Video from '@/components/Video';
import CommentsRating from '@/components/CommentsRating/CommentsRating';
import Gif from '@/components/Gif';

export default {
    name: 'AccordionItemWithContent',
    components: {
        Accordion: () => import('@/components/ui/Accordion'),
        AccordionItem,
        SelectMessageType,
        TextStatement,
        QuickReply,
        QuickReplyAnswer,
        StandardShareMessage,
        TextInput,
        TextGlobalResponse,
        InlineResponse,
        AttachmentUrl,
        Buttons,
        Carousel,
        AccordionItemHeader,
        CarouselTile,
        InlineResponseTextStatement,
        Result,
        Registration,
        DatePicker,
        Video,
        CommentsRating,
        Gif,
        ImageMessageItem,
        Rating,
    },
    props: {
        item: {
            type: Object,
            default: undefined,
        },
        path: {
            type: Array,
            default: () => [],
        },
        startIndex: {
            type: Number,
            default: 1,
        },
        index: {
            type: Number,
            default: 0,
        },
        drag: {
            type: Boolean,
            default: false,
        },
        isDraggable: {
            type: Boolean,
            default: false,
        },
        areMessageItemsValid: {
            type: Boolean,
            default: true,
        },
        currentChannel: {
            type: Object,
            default: undefined,
        },
        currentConvo: {
            type: Object,
            default: undefined,
        },
        convoCategories: {
            type: Array,
            default: () => [],
        },

        yougovDefinitions: {
            type: Array,
            default: () => [],
        },
        showCorrelator: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ConvoMessageItemType,
        };
    },
    methods: {
        subItemHeaderOnClick($event, path) {
            this.$emit('headerOnClick', $event, path);
        },
        subItemOnExpand(path) {
            this.$emit('onExpand', path);
        },
        subItemOnExpanded(path) {
            this.$emit('onExpanded', path);
        },
        subItemOnContract(path) {
            this.$emit('onContract', path);
        },
        subItemOnContracted(path) {
            this.$emit('onContracted', path);
        },
        deleteOnClick($event, path, parentPath) {
            this.$emit('deleteOnClick', $event, path, parentPath);
        },
        cloneOnClick($event, path, parentPath) {
            this.$emit('cloneOnClick', $event, path, parentPath);
        },
        onDrop(path, value) {
            this.$emit('onDrop', path, value);
        },
        messageTypeOnSelect(event, path, index) {
            this.$emit('messageTypeOnSelect', event, path, index);
        },
        getMarker,
    },
};
</script>
