import { Message } from './message';
import { MessageType } from './enums/messageType.enum';

export class CommentsRating extends Message {
    title: string;

    constructor() {
        super(MessageType.CommentsRating);
        this.title = '';
    }
}
