import { IChannelLandingPage } from './interfaces/channelLandingPage.interface';

export class ChannelLandingPage implements IChannelLandingPage {
    constructor() {
        this.title = '';
        this.channelTitle = '';
        this.description = '';
    }

    title: string;
    description: string;
    channelTitle: string;
    [key: string]: string;
}
