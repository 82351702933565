import ValidatableRules from '@/json/validationRules';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum';
import { getErrorMessage } from '@/store/services/validationService';
import {
    IsBoolean,
    IsNotEmpty,
    Length,
    validate as validateClass,
    ValidateIf,
    ValidationError,
} from 'class-validator';
import hash from 'object-hash';
import { LogicJump } from '.';
import { IConvoState } from '../modules/convo-details';
import { AccordionItemBase } from './accordionItemBase';
import { TextInputAccordionItemFactory } from './factories/textInputAccordionItem.factory';
import { TextInputContentItemFactory } from './factories/textInputContentItem.factory';
import { IContentItem, IContentItemConvertible, IDatumContentItem, IRichText } from './interfaces';
import { IObservableItem } from './interfaces/observableItem.interface';
import {
    IMessengerValidationRule,
    IMessengerValidationRules,
} from './interfaces/messengerValidatableRules.interface';
import { TextGlobalResponse } from './textGlobalResponse';
import { MessengerValidationTypes } from '@/enums';

export class TextInput
    extends AccordionItemBase
    implements
        IContentItemConvertible,
        IObservableItem<IConvoState>,
        IRichText,
        IMessengerValidationRules,
        IDatumContentItem
{
    constructor(items = []) {
        super(items);
        this.type = ConvoMessageItemType.TextInput;
        this.plainText = '';
        this.input = '';
        this.icon = 'text-input';
        this.isDraggable = true;
        this.isDeletable = true;
        this.hasCorrelation = false;
        const globalResponse = new TextGlobalResponse();
        globalResponse.hasMarginBottom = false;
        this.items = [globalResponse];
        this.datumDefinition = '';
        this.messengerValidation = (ValidatableRules as IMessengerValidationRule[]).find(
            (item) => item.code === MessengerValidationTypes.None,
        );
        this.errorMessage = '';
        this.allowSkip = false;
    }

    @IsBoolean()
    hasCorrelation: boolean;
    correlation: LogicJump;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Message') })
    @Length(0, 640, { message: getErrorMessage('length', 'Message', { min: 0, max: 640 }) })
    plainText: string;
    input: string;
    datumDefinition: string;
    messengerValidation?: IMessengerValidationRule;

    @ValidateIf((o) => o.messengerValidation.code !== MessengerValidationTypes.None)
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Validation message') })
    errorMessage: string;
    allowSkip: boolean;
    @IsBoolean({ message: getErrorMessage('isNotEmpty', 'PII selection') })
    isPii: boolean | null;

    setTitle(): void {
        this.subtitle = this.plainText;
    }

    itemHasChanges(state: IConvoState): boolean {
        const contentItemFactory = new TextInputContentItemFactory();
        const contentItem = contentItemFactory.make(this, state);

        contentItem.version = hash(contentItem);

        if (this.originalVersion) {
            return contentItem.version !== this.originalVersion;
        }
        return false;
    }

    toContentItem(state: IConvoState): IContentItem {
        const contentItemFactory = new TextInputContentItemFactory();
        return contentItemFactory.make(this, state);
    }

    toAccordionItem(contentItem: IContentItem, isClone?: boolean): AccordionItemBase {
        const accordionItemFactory = new TextInputAccordionItemFactory();
        return accordionItemFactory.make(contentItem);
    }

    async validate(state: IConvoState, path: number[]): Promise<any[]> {
        const errors: ValidationError[] = await validateClass(this);
        const errorMessages: any[] = [];

        if (errors.length > 0) {
            errors.forEach((item: ValidationError) => {
                errorMessages.push({
                    path,
                    errors: { [item.property]: Object.values(item.constraints || {}) },
                });
            });
        }

        const globalResponseErrors: any[] = await this.items[0].validate(state);

        if (Object.entries(globalResponseErrors).length > 0) {
            errorMessages.push({ path: [...path, 0], errors: globalResponseErrors });
        }

        return errorMessages;
    }

    clearDatumDefinition(): void {
        this.datumDefinition = '';
        this.messengerValidation = ValidatableRules[0] as IMessengerValidationRule;
        this.errorMessage = '';
        this.allowSkip = false;
        this.isPii = null;
    }
}
