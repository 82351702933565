import { MessageRule, LocationRule, ConvoRule, ChannelRule } from '@/store/models/audience/index';
import { RuleDto } from '@/store/models/dtos/audience.dto';
import { RuleAttributeType } from '@/store/enums';
import { IRule } from '@/store/models/interfaces';

const ruleMap = {
    [RuleAttributeType.StartedConvo]: ConvoRule,
    [RuleAttributeType.CompletedConvo]: ConvoRule,
    [RuleAttributeType.RespondedToQuestion]: MessageRule,
    [RuleAttributeType.UserLocation]: LocationRule,
    [RuleAttributeType.JoinedChannel]: ChannelRule,
};

export class AudienceRuleFactory {
    public static make(data: RuleDto): IRule {
        if (!ruleMap[data.attribute]) {
            throw new Error(`could not find model for rule type ${data.attribute}`);
        }
        return new ruleMap[data.attribute](data);
    }
}
