<template>
    <div class="date-picker-relative">
        <p class="m-r-2 date-picker-relative__indicator">{{ timeIndicator }}</p>

        <NumberInput
            label=""
            :has-margin-bottom="false"
            class="m-r-2"
            :value="innerDateRangeValue"
            :errors="data.errors.dateRangeValue"
            :disabled="disabled"
            @input="(value) => handleInput('dateRangeValue', value)"
        />

        <Select
            label=""
            :options="dateRanges"
            placeholder="Select range type"
            class="m-r-2"
            :has-margin-bottom="false"
            :errors="data.errors.dateRangeType"
            :value="innerDateRangeType"
            :disabled="disabled"
            @input="(value) => handleInput('dateRangeType', value)"
        />
    </div>
</template>

<script>
import { DateRangeType, DateType } from '@/store/models/interfaces/validationRules.interface';
import { Select, NumberInput } from '@/components/forms';
import { dateRanges } from './config';
import { capitalize } from '@/utils/capitalize';

export default {
    name: 'DatePickerOffset',
    components: {
        Select,
        NumberInput,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            innerDateRangeType: this.data.dateRangeType,
            innerDateRangeValue: this.data.dateRangeValue,
        };
    },
    computed: {
        timeIndicator() {
            const map = {
                [DateType.FutureRange]: 'from now, up to',
                [DateType.PastRange]: 'up to now, from',
                [DateType.Past]: 'over',
                [DateType.Future]: 'from',
            };
            if (map[this.data.dateType]) {
                return map[this.data.dateType];
            }
            return '';
        },
        dateRanges() {
            const map = {
                [DateType.FutureRange]: 'from now',
                [DateType.Future]: 'from now',
                [DateType.PastRange]: 'ago',
                [DateType.Past]: 'ago',
            };
            return dateRanges.map((range) => ({
                id: range.id,
                value: `${range.value.toLowerCase()} ${map[this.data.dateType]}`,
            }));
        },
    },
    methods: {
        handleInput(key, value) {
            this[`inner${capitalize(key)}`] = value;
            this.$emit('input', { key, value });
        },
    },
};
</script>

<style scoped lang="scss">
.date-picker-relative {
    display: flex;
    align-items: center;

    &__indicator {
        font-size: 14px;
    }
}
</style>
