<template>
    <div class="comments-rating-card-wrapper">
        <div class="comments-rating-card-wrapper__element">
            <div v-if="marker" class="marker">{{ marker }}</div>
        </div>
        <div class="comments-rating-card-wrapper__center">
            <div class="comments-rating-card-wrapper__body">
                <slot />
            </div>
        </div>
        <div class="comments-rating-card-wrapper__element">
            <template>
                <Menu button-icon="settings">
                    <DropDownItem icon="delete-full" @click="$emit('deleteOnClick', $event)">
                        Delete item
                    </DropDownItem>
                </Menu>
            </template>
        </div>
    </div>
</template>

<script>
import Menu from '@/components/ui/Menu';
import DropDownItem from '@/components/ui/DropDown/DropDownItem';

export default {
    name: 'CommentsRatingCardWrapper',
    components: {
        Menu,
        DropDownItem,
    },
    props: {
        marker: {
            type: [String, Number],
            default: undefined,
        },
    },
};
</script>

<style lang="scss" scoped>
.comments-rating-card-wrapper {
    position: relative;
    display: flex;

    &__element {
        position: relative;
        display: flex;
        align-items: center;
        height: 38px;
    }

    &__center {
        flex: 1 1 auto;
        margin-right: 8px;
    }

    &__body {
        position: relative;

        &--has-attributes {
            padding-left: 40px;
        }
    }
}

.marker {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 12px;
    background-color: $button-wrapper-marker-bg-color;
    font-size: 12px;
    font-weight: $font-weight-bold;
    color: $button-wrapper-marker-text-color;
    text-align: center;
    line-height: 24px;
    white-space: nowrap;
}
</style>
