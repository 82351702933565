import { ContentItemType } from '@/store/enums';
import { LoadingFlag } from '@/store/enums/loadingIds.enum';
import {
    ContentItemsRequest,
    CreateConvoRequest,
    GetConvosRequest,
    UpdateConvoTopicsRequest,
} from '@/store/models/dtos';
import {
    CloneConvoRequest,
    UpdateConvoAttributesRequest,
} from '@/store/models/dtos/convoRequests.dto';
import { ProfileQuestionsResponse } from '@/store/models/dtos/ygdProfile.dto';
import { IContentItem, IConvo, IPublishRequest } from '@/store/models/interfaces';
import axios from '@/utils/request';
import { IPaging } from '@inconvo/types/interfaces';

export class ContentClient {
    public static async getConvo(id: number, channelCode?: string): Promise<IConvo> {
        const url = `/content/convos/${id}`;

        const result = await axios.request({
            url,
            method: 'GET',
            params: { channelCode },
            errorMessage: 'Could not get convo',
            loadingId: LoadingFlag.ConvoGetOne,
        });

        return result as unknown as Promise<IConvo>;
    }

    public static async updateConvoAttributes(data: UpdateConvoAttributesRequest) {
        const url = `content/convos/${data.id}/attributes`;

        await axios.request<UpdateConvoAttributesRequest>({
            data: data,
            url: url,
            method: 'PUT',
            loadingId: LoadingFlag.UpdateConvoAttributes,
        });
    }

    public static async getConvos(data: GetConvosRequest): Promise<IPaging<IConvo>> {
        let url = `/content/convos?page=${data.page}&size=${data.size}`;
        if (data.channelCode) {
            url = `${url}&channelCode=${data.channelCode}`;
        }
        if (data.channelId) {
            url = `${url}&channelId=${data.channelId}`;
        }
        if (data.q) {
            url = `${url}&q=${data.q}`;
        }
        if (data.type && parseInt(data.type) != -1) {
            url = `${url}&type=${data.type}`;
        }
        if (data.status !== undefined && data.status >= 0) {
            url = `${url}&status=${data.status}`;
        }
        if (data.chatGroup !== undefined && data.chatGroup >= 0) {
            url = `${url}&chatGroup=${data.chatGroup}`;
        }
        if (data.includeGenerated) {
            url = `${url}&includeGenerated=${data.includeGenerated}`;
        }
        if (data.excludeTopics) {
            url = `${url}&excludeTopics=${data.excludeTopics}`;
        }
        if (data.includeTopics) {
            url = `${url}&includeTopics=${data.includeTopics}`;
        }
        const result = await axios.request<GetConvosRequest>({
            url,
            method: 'GET',
            data,
            errorMessage: 'Could not get convos',
            loadingId: LoadingFlag.ConvoGet,
        });

        return result as unknown as Promise<IPaging<IConvo>>;
    }

    public static async createConvo(data: CreateConvoRequest): Promise<IConvo> {
        const url = '/content/convos';
        const result = await axios.request<CreateConvoRequest>({
            url,
            method: 'POST',
            data,
            errorMessage: 'Could not create convo',
            loadingId: LoadingFlag.ConvoCreate,
        });

        return result as unknown as Promise<IConvo>;
    }

    public static async deleteConvo(convoId: number): Promise<void> {
        const result = await axios.request<CreateConvoRequest>({
            url: `/content/convos/${convoId}`,
            method: 'DELETE',
            errorMessage: 'Could not delete convo',
            loadingId: LoadingFlag.ConvoDelete,
        });
        return result as unknown as Promise<void>;
    }

    public static async saveContentItems(data: ContentItemsRequest): Promise<IContentItem[]> {
        const url = `content/convos/${data.convoId}/content-items`;
        const result = await axios.request<IContentItem[]>({
            url,
            method: 'POST',
            data,
            errorMessage: 'Could not save content items',
            loadingId: LoadingFlag.MessagesSave,
        });

        return result as unknown as Promise<IContentItem[]>;
    }

    public static async existsContentItem(externalId: string): Promise<boolean> {
        const result = await axios.request<boolean[]>({
            url: `content/content-items/${encodeURIComponent(externalId)}`,
            method: 'GET',
            loadingId: LoadingFlag.ContentExists,
        });
        return result as unknown as Promise<boolean>;
    }

    public static async getContentItems(
        data: ContentItemsRequest,
        query?: string,
        contentTypes: ContentItemType[] = [],
    ): Promise<IContentItem[]> {
        const url = `content/convos/${data.convoId}/content-items`;

        const params: any = {
            q: query,
        };

        if (contentTypes?.length) {
            params.contentTypes = contentTypes.join(',');
        }

        const result = await axios.request<ContentItemsRequest>({
            url,
            method: 'GET',
            data,
            params,
            errorMessage: 'Could not get content items',
            loadingId: LoadingFlag.MessagesGet,
        });

        return result as unknown as Promise<IContentItem[]>;
    }

    public static async publish(data: IPublishRequest): Promise<void> {
        const url = `content/convos/${data.convoId}/publish`;

        await axios.request<IContentItem[]>({
            url,
            timeout: 60000,
            method: 'POST',
            data,
            errorMessage: 'Could not publish the convo',
            loadingId: LoadingFlag.ConvoPublish,
        });
    }

    public static async updateConvosTopics(data: UpdateConvoTopicsRequest): Promise<void> {
        const url = `content/convos/topics`;

        await axios.request<IContentItem[]>({
            url,
            timeout: 60000,
            method: 'PUT',
            data,
            errorMessage: 'Could not update convo topics',
            loadingId: LoadingFlag.UpdateConvoTopics,
        });
    }

    public static async cloneConvo(convoId: string, data: CloneConvoRequest): Promise<IConvo> {
        const url = `content/convos/${convoId}/clone`;

        const result = await axios.request<IConvo[]>({
            url,
            method: 'POST',
            data,
            errorMessage: 'Could not clone the convo',
            loadingId: LoadingFlag.ConvoClone,
        });

        return result as unknown as Promise<IConvo>;
    }

    public static async getYouGovDirectPanelCountries() {
        const url = `content/ygd-profile-questions/countries`;
        const result = await axios.request({
            url,
            method: 'GET',
            errorMessage: 'Could not get YGD panel countries',
            loadingId: LoadingFlag.YGDProfileQuestionsGet,
        });
        return result as unknown as Promise<string[]>;
    }

    public static async getYouGovDirectProfileQuestions(
        countryCode: string,
    ): Promise<ProfileQuestionsResponse> {
        const url = `content/ygd-profile-questions/countries/${countryCode}`;
        const result = await axios.request({
            url,
            method: 'GET',
            errorMessage: 'Could not get YGD profile questions',
            loadingId: LoadingFlag.YGDProfileQuestionsGet,
        });
        return result as unknown as Promise<ProfileQuestionsResponse>;
    }

    public static async getContentBatch(params: {
        channel_codes?: string[];
        convo_ids?: string[];
        content_item_ids?: string[];
        comment_text?: string[];
    }) {
        const result = await axios.request({
            url: 'content/all',
            method: 'GET',
            errorMessage: 'Could not get content batch',
            loadingId: LoadingFlag.ContentGetBatch,
            params,
        });
        return result as unknown as Promise<any>;
    }
}
