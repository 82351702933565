<template>
    <nav :class="{ 'is-opened': isOpened }" class="nav">
        <div class="nav__inner">
            <div class="nav__logo">
                <router-link to="/">
                    <slot name="logo">
                        <Icon name="inconvo-logo" :size="32" />
                    </slot>
                </router-link>
            </div>

            <NavButton
                v-for="item in activeMenus"
                :key="item.id"
                :to="item.to"
                :icon="item.icon"
                :title="item.title"
                :active="isSelected(item.to)"
            />

            <div class="nav__footer">
                <NavButton
                    to="/"
                    icon="settings"
                    title="Settings"
                    :active="$route.path === '/settings'"
                />

                <NavUser
                    v-if="$store.getters['auth/isAuthenticated']"
                    :username="$store.getters['auth/userName']"
                    @logout="onLogout"
                />
            </div>
        </div>

        <button class="nav__toggle" @click="$emit('toggleOnClick', $event)">
            <Icon name="arrow-right" :size="10" />
        </button>
    </nav>
</template>

<script>
import Icon from '@/components/ui/Icon';
import NavButton from './NavButton';
import NavUser from './NavUser';
import config from '@/config';

export default {
    name: 'Navigation',
    components: {
        NavButton,
        NavUser,
        Icon,
    },
    props: {
        isOpened: {
            type: Boolean,
            required: true,
        },
        matchedRoutePath: {
            type: String,
            required: true,
        },
        menuItems: {
            type: Array,
            required: true,
        },
    },
    computed: {
        activeMenus() {
            const roles = this.$store.getters['auth/userRoles'];
            if (!roles) {
                return [];
            }
            return this.menuItems.filter((menuItem) => {
                return roles.includes(menuItem.role) && !menuItem.disabled;
            });
        },
    },
    methods: {
        isSelected(path) {
            if (this.matchedRoutePath.startsWith(path)) {
                return true;
            }
            return false;
        },
        async onLogout() {
            await this.$store.dispatch('auth/signOut');
            if (config.featureToggle.otpSignIn) {
                this.$router.push({ name: 'sign-in' });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
$nav-width-collapsed: 50px;
$nav-width-opened: 240px;

.nav {
    z-index: $navbar-z-index;
    position: fixed;
    top: 0;
    left: 0;
    width: $nav-width-collapsed;
    height: 100%;
    color: $color-shuttle-gray;
    background-color: $color-athens-gray;
    transition: width 0.5s;

    &.is-opened {
        width: $nav-width-opened;
    }

    &__inner {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: -10px;
        bottom: 122px;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background-color: $color-athens-gray;
        transition: transform 0.5s;
        cursor: pointer;

        .is-opened & {
            transform: rotate(180deg);
        }
    }

    &__logo {
        margin-top: 30px;
        margin-bottom: 50px;
        width: $nav-width-collapsed;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__footer {
        position: relative;
        margin-top: auto;
        padding: 20px 0;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 10px;
            width: 30px;
            height: 1px;
            background-color: #dfe1e9;
        }
    }
}
</style>
