<template>
    <div v-click-outside="close" class="menu">
        <slot name="button" :toggle="toggle">
            <Button
                v-if="buttonText"
                class="link-button"
                size="small"
                button-style="secondary"
                @click.prevent="isOpen = !isOpen"
            >
                <Badge v-if="badgeType" :type="badgeType"> {{ buttonText }} </Badge>
                <template v-else> {{ buttonText }}</template>
            </Button>
            <IconButton
                v-else
                :icon="buttonIcon"
                class="menu-button"
                :disabled="disabled"
                @click.prevent="isOpen = !isOpen"
            />
        </slot>

        <DropDown
            class="menu-dropdown"
            :class="{ 'custom-menu-dropdown': buttonText }"
            :is-open="isOpen"
            @click="close"
        >
            <slot />
        </DropDown>
    </div>
</template>

<script>
import IconButton from '@/components/ui/IconButton';
import DropDown from '@/components/ui/DropDown/DropDown';
import Button from '@/components/ui/Button.vue';
import Badge from '@/components/ui/Badge.vue';

export default {
    name: 'Menu',
    components: {
        IconButton,
        DropDown,
        Button,
        Badge,
    },
    props: {
        buttonIcon: {
            type: String,
            default: 'actions-menu',
        },
        buttonText: {
            type: String,
            default: undefined,
        },
        badgeType: {
            type: String,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        close($event) {
            this.isOpen = false;
        },
        toggle() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>

<style lang="scss" scoped>
.menu {
    position: relative;
    display: inline-block;
}

.menu-button {
    font-size: 16px;
    color: currentColor;
}

.menu-dropdown {
    z-index: 1000;
    position: absolute;
    margin-top: 2px;
    right: 0;
}

.link-button {
    font-size: 1.4rem;
    right: 15px;
    letter-spacing: 0px;
    font-family: $font-family-default;
    font-weight: $font-weight-default;
}

.custom-menu-dropdown {
    left: 0;
    right: auto;
}
</style>
