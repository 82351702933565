import { IContentItem, IMultipleChoiceOption, IResultOption } from '../interfaces';
import {
    QuickReply,
    ContentItem,
    QuickReplyAnswer,
    TextGlobalResponse,
    InlineResponseTextStatement,
} from '..';
import { ContentItemType, AnswerType, ConvoTypes, ChartType, SortingOrder } from '@/store/enums/';
import { IAnswer } from '../interfaces/answer.interface.dto';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum';
import { IWidgetReply } from '../interfaces/widgetReply.interface.dto';
import { IConvoState } from '@/store/modules/convo-details';
import { Answer } from '../answer.dto';
import { makePersonalityQuizAttr, makeTriviaQuizAttr } from './contentItemsRequest.factory';

export class QuickReplyContentItemFactory {
    public make(quickReply: QuickReply, state: IConvoState): IContentItem {
        const contentItem = new ContentItem();
        contentItem.id = quickReply.id;
        contentItem.sort = quickReply.sort;
        contentItem.title = quickReply.statement;
        contentItem.type = ContentItemType.QuickReply;
        contentItem.shuffleAnswers = quickReply.areAnswersShuffled;
        contentItem.shuffleLast = !quickReply.pinLastAnswer;
        contentItem.multipleChoice = quickReply.isMultipleChoice;
        contentItem.tags = quickReply.tags || [];
        contentItem.isPdl = quickReply.isPdl;
        contentItem.shouldSkipIfAnswered = quickReply.shouldSkipIfAnswered;
        contentItem.externalId = quickReply.datumDefinition || '';
        contentItem.answers = [];

        // TODO: think about a better solution instead of accessing first item of the array
        const answers = quickReply.items[0].items.filter(
            (i) => i.type === ConvoMessageItemType.QuickReplyAnswer,
        );
        for (const quickReplyAnswer of answers as QuickReplyAnswer[]) {
            const answer: IAnswer = new Answer();

            answer.convoId = state.convo.id;
            answer.type = AnswerType.Reply;
            answer.id = quickReplyAnswer.answerId;
            answer.text = quickReplyAnswer.buttonLabel;
            answer.image = quickReplyAnswer.buttonImageUrl;
            answer.isExclusive = quickReplyAnswer.isExclusive;
            answer.externalId = quickReplyAnswer.datumResponseCode || '';

            // Logic jump
            answer.targetChannel = quickReplyAnswer.logicJump?.items?.channel?.code || '';
            answer.targetConvo = quickReplyAnswer.logicJump?.items?.convo?.id || 0;
            answer.targetContentItem = quickReplyAnswer.logicJump?.items?.message?.id || 0;
            answer.goToBlock =
                !answer.targetContentItem && answer.targetConvo ? answer.targetConvo : 0;
            answer.goToWidget =
                answer.targetConvo && answer.targetContentItem ? answer.targetContentItem : 0;

            if (state.convo.type === ConvoTypes.PersonalityQuiz) {
                const personalityAttr = makePersonalityQuizAttr(quickReply, quickReplyAnswer);
                answer.quizResultItem = personalityAttr.quizResultItem;
            }

            if (state.convo.type === ConvoTypes.TriviaQuiz) {
                answer.quizResultItem = undefined;
                const triviaAttr = makeTriviaQuizAttr(quickReply, quickReplyAnswer);
                answer.ignoreQuizAnswer = triviaAttr.ignoreQuizAnswer;
                answer.isCorrectAnswer = triviaAttr.isCorrectAnswer;
            }

            // TODO: if we handle more response than one it will need a modification
            if (
                quickReplyAnswer.items &&
                quickReplyAnswer.items.length > 0 &&
                quickReplyAnswer.items[0].items &&
                quickReplyAnswer.items[0].items.length > 0
            ) {
                switch (quickReplyAnswer.items[0].items[0].type) {
                    case ConvoMessageItemType.InlineResponseTextStatement:
                        answer.reply = (
                            quickReplyAnswer.items[0].items[0] as InlineResponseTextStatement
                        ).statement;
                        break;
                }
            }

            contentItem.answers.push(answer);
        }

        const reply = quickReply.items[1].items.find(
            (i) => i.type === ConvoMessageItemType.TextGlobalResponse,
        ) as TextGlobalResponse;

        if (quickReply.isMultipleChoice) {
            contentItem.multipleChoiceOptions = {} as IMultipleChoiceOption;
            contentItem.multipleChoiceOptions.submitButtonText = quickReply.submitButtonText;
            contentItem.multipleChoiceOptions.layout = quickReply.layout;
            contentItem.multipleChoiceOptions.minLimit = quickReply.minLimit;
            contentItem.multipleChoiceOptions.maxLimit = quickReply.maxLimit;
        }

        contentItem.reply = {} as IWidgetReply;
        contentItem.reply.activated = !!reply.message || quickReply.showQuizResults;
        contentItem.reply.message = quickReply.showQuizResults ? '' : reply.message;
        contentItem.reply.sortingOrder =
            quickReply.resultOptions.chartType === ChartType.None
                ? reply.sortingOrder
                : SortingOrder.None;
        contentItem.reply.resultOptions = {} as IResultOption;
        contentItem.reply.resultOptions.chartType = quickReply.resultOptions.chartType;
        contentItem.reply.resultOptions.order = quickReply.resultOptions.order;
        contentItem.reply.resultOptions.showResponseCount =
            quickReply.resultOptions.chartType !== ChartType.None &&
            quickReply.resultOptions.showResponseCount;
        contentItem.reply.tagLine = '';
        contentItem.reply.url = '';
        contentItem.reply.imageUrl = '';
        return contentItem;
    }
}
