import { RuleDto } from '@/store/models/dtos/audience.dto';
import { Rule } from '@/store/models/audience/rule';
import { TenantClient } from '@/api/tenant';

export class ConvoRule extends Rule {
    content: any;

    constructor(data: RuleDto) {
        super(data);
    }

    async getContent(): Promise<any> {
        return TenantClient.getLogicJumpData({
            targetChannel: '',
            targetContentItem: 0,
            targetConvo: Number(this.value),
        });
    }

    getSummaryTitle(): string {
        return ['channelName', 'convoName']
            .filter((prop) => this.content[prop] !== undefined)
            .map((prop) => this.content[prop])
            .join(' • ');
    }
}
