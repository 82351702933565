import { IRootState } from '../types/rootState';
import { IPaging } from '@inconvo/types/interfaces';
import { Module, GetterTree, ActionTree, MutationTree } from 'vuex';
import { Category } from '../models/category';
import { IObservableItem } from '../models/interfaces/observableItem.interface';
import {
    SET_CATEGORY_ERRORS,
    SET_CATEGORY,
    CLEAR_CATEGORY_ERRORS,
    SET_CATEGORIES,
    RESET_CATEGORY_STORE,
    SET_COLORS_IN_USE,
} from '../mutations.types';
import { CategoryRequestFactory } from '../models/factories/categoryRequest.factory';
import { Category as CategoryDto } from '../models/dtos/category.dto';
import { CategoryFactory } from '../models/factories/category.factory';
import hash from 'object-hash';
import { CategoryClient } from '@/api/category';

export interface ICategoryDetailsView {
    category: Category;
    errors: object;
    validated: boolean;
}

export interface ICategoryState {
    categoryList: Category[];
    categoryColorsAlreadyInUse: string[];
    categoryDetails: ICategoryDetailsView;
}

const getDefaultState = (): ICategoryState => {
    return {
        categoryList: [],
        categoryColorsAlreadyInUse: [],
        categoryDetails: {
            category: new Category(),
            errors: {},
            validated: false,
        },
    };
};
const state: ICategoryState = getDefaultState();

const getters: GetterTree<ICategoryState, IRootState> = {
    categoryDetails: (state) => state.categoryDetails,
    categoryModelHasChanges: (state): boolean => {
        const observableItem = state.categoryDetails
            .category as unknown as IObservableItem<ICategoryState>;
        const itemHasChanges =
            observableItem && observableItem.itemHasChanges && observableItem.itemHasChanges(state);

        if (itemHasChanges || !state.categoryDetails.category.id) {
            return true;
        }

        return false;
    },
    categoryIsValid: (state): boolean => {
        return Object.keys(state.categoryDetails.errors).length === 0;
    },
    categories: (state) => state.categoryList,
    getColorsInUse: (state) => state.categoryColorsAlreadyInUse,
    channelList: (state) => state.categoryDetails.category.channelList,
};

const actions: ActionTree<ICategoryState, any> = {
    validateCategory: async ({ commit, state, dispatch }) => {
        const errors = await state.categoryDetails.category.validate();

        if (!Object.keys(errors).length) {
            commit(CLEAR_CATEGORY_ERRORS);
        }

        if (Object.keys(errors).length) {
            commit(SET_CATEGORY_ERRORS, errors);
        }
    },

    saveCategory: async ({ commit, state }) => {
        const requestFactory = new CategoryRequestFactory();
        const request = requestFactory.make(state.categoryDetails.category);

        let category: CategoryDto;
        if (state.categoryDetails.category.id === 0) {
            category = await CategoryClient.createCategory(request);
        } else {
            category = await CategoryClient.updateCategory(
                request,
                state.categoryDetails.category.id,
            );
        }

        const categoryModel = new CategoryFactory().make(category);
        const categoryRequest = new CategoryRequestFactory().make(categoryModel);
        categoryModel.originalVersion = hash(categoryRequest);

        commit(SET_CATEGORY, categoryModel);
    },
    getCategories: async ({ commit, dispatch }) => {
        const result: IPaging<CategoryDto> = await CategoryClient.getCategories();
        const categories =
            result.items?.map((category) => new CategoryFactory().make(category)) || [];

        commit(SET_CATEGORIES, categories || []);
        dispatch('setColorsInUse');
    },
    getCategory: async ({ commit }, id) => {
        const result: CategoryDto = await CategoryClient.getCategory(id);
        const category = new CategoryFactory().make(result);
        const categoryRequest = new CategoryRequestFactory().make(category);
        category.originalVersion = hash(categoryRequest);
        commit(SET_CATEGORY, category);
    },
    resetCategoryDetailsState: async ({ commit }) => {
        commit(RESET_CATEGORY_STORE);
    },
    setColorsInUse: async ({ commit }) => {
        const colors: string[] = state.categoryList.map((category) => category.color);
        commit(SET_COLORS_IN_USE, colors);
    },
    deleteCategory: async ({ commit }, categoryId) => {
        const result = await CategoryClient.deleteCategory(categoryId);
    },
    updateChannelsInCategory: async ({ commit }, { categoryId, channelList, originalCategory }) => {
        const category = await CategoryClient.moveChannelsToCategory({
            categoryId,
            channelList,
            originalCategory,
        });

        const categoryModel = new CategoryFactory().make(category);
        const categoryRequest = new CategoryRequestFactory().make(categoryModel);
        categoryModel.originalVersion = hash(categoryRequest);

        commit(SET_CATEGORY, categoryModel);
    },
};

const mutations: MutationTree<ICategoryState> = {
    [SET_CATEGORY_ERRORS](state: ICategoryState, errors): void {
        state.categoryDetails.errors = errors;
    },
    [SET_CATEGORY](state, category: Category): void {
        state.categoryDetails.category = category;
    },
    [SET_CATEGORIES](state, categories: Category[]): void {
        state.categoryList = categories;
    },
    [CLEAR_CATEGORY_ERRORS](state: ICategoryState): void {
        state.categoryDetails.errors = {};
    },
    [RESET_CATEGORY_STORE](state: ICategoryState): void {
        Object.assign(state.categoryDetails, getDefaultState().categoryDetails);
    },
    [SET_COLORS_IN_USE](state: ICategoryState, colors: string[]): void {
        state.categoryColorsAlreadyInUse = colors;
    },
};

export const category: Module<ICategoryState, IRootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
