<template>
    <div ref="modalWrapper" class="modal-wrapper" tabindex="-1" @keydown.esc="close">
        <div
            class="modal"
            :class="{
                'modal--closable': closable,
                'modal--full-height': isFullHeight,
            }"
        >
            <button v-if="closable" type="button" class="modal-button" @click="close">
                <Icon name="cross" title="close" :width="34" :height="34" />
            </button>

            <div v-if="image && loading" class="modal-placeholder-image"></div>
            <img
                v-else-if="image && image.url"
                :src="image.url"
                :alt="image.alt"
                class="modal-image"
            />

            <div class="modal-header">
                <slot name="logo">
                    <Icon name="logo-header" class="modal-logo" />
                </slot>

                <p v-if="tagline" class="modal-tagline m-t-1">
                    {{ tagline }}
                </p>
            </div>

            <div class="modal-content">
                <slot :close="close"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    name: 'ModalBase',
    components: {
        Icon,
    },
    props: {
        tagline: {
            type: String,
            default: '',
        },
        closable: {
            type: Boolean,
            default: false,
        },
        isFullHeight: {
            type: Boolean,
            default: false,
        },
        image: {
            type: Object,
            default: undefined,
        },
        loading: {
            type: Boolean,
            default: true,
        },
    },
    mounted() {
        this.focus();
    },
    methods: {
        close(e) {
            if (this.closable) {
                this.$parent.$emit('close');
            }
        },
        focus() {
            const backdrop = document.getElementById('backdrop');
            let backdropScrollTop;

            if (backdrop) {
                backdropScrollTop = backdrop.scrollTop;
            }

            this.$refs.modalWrapper.focus();

            if (backdrop) {
                backdrop.scrollTo(0, backdropScrollTop);
            }
        },
    },
};
</script>

<style scoped lang="scss">
$modal-image-size: 140px;
$modal-image-size-on-small: 120px;

.modal-wrapper {
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    min-height: 100%;
    outline: 0;

    @include media-query(medium) {
        display: block;
        top: auto;
        left: auto;
        width: auto;
        margin: 20px auto;
    }

    &--has-image {
        margin-top: 125px;

        @include media-query(medium) {
            margin-top: 150px;
        }

        &::before {
            $border-size-on-small: $modal-image-size-on-small + 8px;

            @include center(x);
            content: '';
            position: absolute;
            top: $border-size-on-small / 2 * -1;
            width: $border-size-on-small;
            height: $border-size-on-small;
            border-radius: $border-size-on-small;
            box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
            background-color: $default-light-color;

            @include media-query(medium) {
                $border-size: $modal-image-size + 10px;

                top: $border-size / 2 * -1;
                width: $border-size;
                height: $border-size;
                margin-bottom: 20px;
                border-radius: $border-size;
            }
        }
    }
}

.modal {
    border-radius: 20px;
    margin: 0 auto;
    padding: 40px 20px 40px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    flex: 1 1 auto;
    background-color: $default-light-color;
    min-width: 400px;

    &--full-height {
        display: flex;
    }

    @include media-query(medium) {
        padding: 50px 60px;
    }

    .modal-wrapper--has-image & {
        padding-top: ($modal-image-size-on-small / 2 + 20px);

        @include media-query(medium) {
            padding-top: ($modal-image-size / 2 + 30px);
        }
    }
}

.modal-header {
    color: $color-oxford-blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    .modal-wrapper--has-image & {
        position: absolute;
        left: 50%;
        top: 0;
        margin: 0;
        transform: translate(-50%, calc(-100% - #{$modal-image-size-on-small / 2 + 5px + 20px}));

        @include media-query(medium) {
            transform: translate(-50%, calc(-100% - #{$modal-image-size / 2 + 5px + 30px}));
        }
    }
}

.modal-tagline {
    font-size: $font-size-sm;
    color: $color-cadet-blue;
}

.modal-button {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 10px;
    border: none;
    outline: 0;
    background-color: transparent;
    opacity: 0.4;
    transition: 100ms ease;

    &:hover {
        opacity: 0.8;
    }
}

.modal-content {
    color: $default-text-color;
    line-height: normal;
    margin-left: auto;
    margin-right: auto;
}

.modal-image,
.modal-placeholder-image {
    @include center(x);
    position: absolute;
    top: $modal-image-size-on-small / 2 * -1;
    width: $modal-image-size-on-small;
    height: $modal-image-size-on-small;
    border-radius: $modal-image-size-on-small;
    background: $color-near-white;

    @include media-query(medium) {
        top: $modal-image-size / 2 * -1;
        width: $modal-image-size;
        height: $modal-image-size;
        border-radius: $modal-image-size;
    }
}
</style>
