import { IsNotEmpty, IsNumber, IsBoolean, validate as validator } from 'class-validator';
import { RuleDto } from '@/store/models/dtos/audience.dto';
import { RuleAttributeType } from '@/store/enums';
import { IRule } from '@/store/models/interfaces';

export abstract class Rule implements IRule {
    id: number;

    parentId: number | null;

    ruleType: 'rule' = 'rule';

    @IsNotEmpty({ message: 'Attribute cannot be empty' })
    attribute: RuleAttributeType;

    @IsNotEmpty({ message: 'Rule cannot be empty' })
    value: string;

    @IsBoolean({ message: 'Exclude must be a boolean' })
    excludeMatches: boolean;

    constructor(data: RuleDto) {
        this.id = data.id;
        this.parentId = data.parentId;
        this.attribute = data.attribute;
        this.value = data.value || '';
        this.excludeMatches = data.excludeMatches || false;
    }

    async validate() {
        const errors = await validator(this);
        if (!errors.length) {
            return [];
        }
        return errors.map((error) => Object.values(error.constraints || {})).flat();
    }

    abstract getSummaryTitle(): string;

    abstract getContent(options?: any): Promise<any>;
}
