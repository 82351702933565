import buttonsMessageTypeAnswerOptions from '@/json/buttonsMessageTypeAnswerOptions';
import { AnswerType, LinkTarget } from '@/store/enums';
import { Attributes } from '@/store/enums/attributes.enum';
import { sanitize } from '@/utils/sanitizer';
import { v4 as uuid } from 'uuid';
import { ButtonsAnswer, ItemsGroup } from '..';
import { IAnswer, IContentItem } from '../interfaces';
import { YougovWelcomeSurveyButtons } from '../ygWelcomeSurvey';
import { makeAnswerReply, makeLogicJump } from './accordionItems.factory';

export class YougovWelcomeSurveyButtonsAccordionItemFactory {
    public make(contentItem: IContentItem, isClone?: boolean): YougovWelcomeSurveyButtons {
        const welcomeSurvey = new YougovWelcomeSurveyButtons();
        const buttonsAnswers = new ItemsGroup();
        const plainTextStatement = sanitize(contentItem.title);

        welcomeSurvey.subtitle = plainTextStatement;
        welcomeSurvey.plainText = plainTextStatement;
        welcomeSurvey.statement = contentItem.title;
        welcomeSurvey.id = contentItem.id || 0;
        welcomeSurvey.sort = contentItem.sort;
        welcomeSurvey.title = 'Yougov Welcome Survey Buttons';
        welcomeSurvey.tags = contentItem.tags;

        if (contentItem.externalId) {
            welcomeSurvey.externalId = contentItem.externalId;
        }

        for (const answer of contentItem.answers as IAnswer[]) {
            const buttonAnswerItem = new ButtonsAnswer();
            const selectedAnswerType = buttonsMessageTypeAnswerOptions.find(
                (item) => item.name === answer.type,
            );

            buttonAnswerItem.answerTypeOptions = buttonsMessageTypeAnswerOptions;

            if (answer.type === AnswerType.YougovWelcomeSurvey) {
                buttonAnswerItem.isDeletable = false;
                buttonAnswerItem.isDraggable = false;
                buttonAnswerItem.canChangeAnswerType = false;
                buttonAnswerItem.canHaveAttributes = false;
            }

            buttonAnswerItem.buttonId = answer.id;
            if (!buttonAnswerItem.buttonId || isClone) {
                buttonAnswerItem.buttonId = uuid();
                welcomeSurvey.hasChanges = true;
            }

            buttonAnswerItem.buttonLabel = answer.text || '';

            if (selectedAnswerType) {
                buttonAnswerItem.answerType.name = selectedAnswerType.name;
                buttonAnswerItem.answerType.title = selectedAnswerType.title;
                buttonAnswerItem.answerType.linkRequired = selectedAnswerType.linkRequired;
            }

            if (answer.link) {
                buttonAnswerItem.link = answer.link;
                buttonAnswerItem.linkOpenInNewTab = answer.linkTarget === LinkTarget.Blank;
                buttonAnswerItem.successDispositionLink = answer.successDispositionLink || '';
                buttonAnswerItem.failureDispositionLink = answer.failureDispositionLink || '';
            }

            buttonAnswerItem.logicJump = makeLogicJump(answer);

            if (Object.keys(buttonAnswerItem.logicJump.items).length) {
                buttonAnswerItem.attributes.push(Attributes.LogicJump);
            }

            if (answer.reply && answer.type !== AnswerType.YougovWelcomeSurvey) {
                const inlineResponse = makeAnswerReply(answer.reply);
                buttonAnswerItem.items[0].items.push(inlineResponse);
                buttonAnswerItem.attributes.push(Attributes.InlineResponse);
            }

            buttonsAnswers.items.push(buttonAnswerItem);
        }

        welcomeSurvey.items = [buttonsAnswers];

        return welcomeSurvey;
    }
}
