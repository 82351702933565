<template>
    <MessageWrapper :avatar-url="avatarUrl">
        <MessageBubble class="text-message" :show-arrow="showArrow">
            <div class="simple-text" @click="onClick(item)" v-html="item.text"></div>
        </MessageBubble>
    </MessageWrapper>
</template>

<script>
import MessageWrapper from './MessageWrapper';
import MessageBubble from './MessageBubble';
import messageMixin from '../mixins/message.mixin';

export default {
    name: 'SimpleText',
    components: {
        MessageWrapper,
        MessageBubble,
    },
    mixins: [messageMixin],
    props: {
        item: {
            type: Object,
            default: undefined,
        },
    },
    computed: {
        avatarUrl() {
            return this.item.showAvatar && this.item.avatarUrl ? this.item.avatarUrl : undefined;
        },
        showArrow() {
            return this.avatarUrl ? true : false;
        },
    },
    created() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.text-message {
    .simple-text {
        font-family: $font-family-default;
        font-size: $font-size-xs;
        font-weight: $font-weight-default;
        line-height: 1.37;
        text-align: left;
        color: $default-text-color;

        @include media-query(medium) {
            font-size: $font-size-xxs;
        }
    }
}
</style>
