import { UserComment } from '@/store/models';
import { UserCommentDto } from '@/store/models/dtos';
import {
    CommentsRatingStatus,
    CommentsRatingStatusReason,
    RetrainClassification,
    ClassificationMode,
    ToxicityType,
} from '@inconvo/types/enums';
import { CommentToxicity } from '@inconvo/types/interfaces';

export class UserCommentFactory {
    public static make(data: UserCommentDto): UserComment {
        const userComment = new UserComment();
        userComment.id = data.comment_rating_id;
        userComment.channel = data.channel;
        userComment.channelCode = data.channel_code;
        userComment.convo = data.convo;
        userComment.contentItem = data.content_item;
        userComment.contentItemId = data.content_item_id;
        userComment.comment = data.comment;
        userComment.userId = data.user_id;
        userComment.status = data.status as CommentsRatingStatus;
        userComment.statusReason = data.status_reason as CommentsRatingStatusReason;
        userComment.retrain = data.retrain || false;
        userComment.retrainClassification = data.retrain_classification as RetrainClassification;
        userComment.rating = data.rating;
        userComment.reports = data.reports;
        userComment.confidence = data.confidence;

        userComment.createdAt = new Date(data.created_at);
        userComment.updatedAt = data.updated_at ? new Date(data.updated_at) : undefined;

        userComment.toxicity = data.toxicity;
        userComment.classificationMode =
            data.classification_mode || ClassificationMode.classifierV1;

        return userComment;
    }
}
