import { ICategory } from '../interfaces';
import { CategoryRequest } from '../dtos/categoryRequest.dto';

export class CategoryRequestFactory {
    public make(data: ICategory): CategoryRequest {
        const categoryRequest = new CategoryRequest();

        categoryRequest.name = data.name;
        categoryRequest.description = data.description;
        categoryRequest.slug = data.slug;
        categoryRequest.image = data.image;
        categoryRequest.color = data.color;

        return categoryRequest;
    }
}
