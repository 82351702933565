<template>
    <div
        class="autocomplete"
        :class="{ 'autocomplete--open': isOpen, 'autocomplete--disabled': isDisabled }"
    >
        <FormFieldWrapper
            class="autocomplete__input-wrapper"
            :for-id="id"
            :label="label"
            :errors="errors"
            :is-disabled="isDisabled"
            :is-focused="isFocused || isOpen"
            :show-error-messages="showErrorMessages"
            :show-warning-messages="showWarningMessages"
        >
            <input
                :id="id"
                ref="input"
                v-model="query"
                class="autocomplete__input form-field-input"
                type="text"
                :placeholder="placeholder"
                autocomplete="off"
                :disabled="isDisabled"
                @input="onInput"
                @focus="onFocus"
                @blur="isFocused = false"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
                @keydown.enter="onEnter"
                @keydown.esc="onEscape"
            />

            <div class="form-field-icon">
                <div class="autocomplete__icon default-icon">
                    <Icon name="search" :color="iconColor" />
                </div>
                <button v-if="showClearIcon" class="autocomplete__icon" @click="onClear">
                    <Icon v-if="query" name="cross" :size="14" />
                </button>
            </div>
        </FormFieldWrapper>

        <ul
            v-show="isOpen"
            id="autocomplete-results"
            ref="results"
            class="autocomplete__results"
            :class="{ 'autocomplete__results--error': errors.length > 0 }"
        >
            <li v-if="isLoading" class="autocomplete__loading">Loading results...</li>
            <li
                v-for="(result, i) in results"
                v-else-if="!isLoading && results.length"
                :key="i"
                class="autocomplete__result"
                :class="[
                    { 'is-active': i === arrowCounter },
                    { 'is-disabled': isDisabledResult(result) },
                ]"
                @click="setResult(result, i)"
            >
                <span class="autocomplete__result-inner">
                    <slot :result="result" :result-index="i" :item-index="getItemIndex(result)">
                        {{ result }}
                    </slot>
                </span>
            </li>
            <li v-else class="autocomplete__result">No results</li>
        </ul>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import Icon from '@/components/ui/Icon';
import colors from '@styles/abstracts/_variables.colors.scss';

export default {
    name: 'AutoComplete',
    components: {
        Icon,
        FormFieldWrapper: () => import('@/components/forms/FormFieldWrapper.vue'),
    },
    props: {
        id: {
            type: String,
            default: 'autocomplete',
        },
        items: {
            type: Array,
            default: () => [],
        },
        disabledItems: {
            type: Array,
            default: () => [],
        },
        itemKey: {
            type: String,
            default: undefined,
        },
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: 'Enter your query',
        },
        isAsync: {
            type: Boolean,
            default: false,
        },
        openOnFocus: {
            type: Boolean,
            default: true,
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: 'Autocomplete',
        },
        errors: {
            type: Array,
            default: () => [],
        },
        infoText: {
            type: String,
            default: '',
        },
        iconColor: {
            type: String,
            default: colors['color-oxford-blue'],
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        showClearIcon: {
            type: Boolean,
            default: false,
        },
        showErrorMessages: {
            type: Boolean,
            default: true,
        },
        showWarningMessages: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isFocused: false,
            isOpen: false,
            results: [],
            query: this.value,
            isLoading: false,
            arrowCounter: 0,
        };
    },
    computed: {
        showAdditionalInfo() {
            return (this.errors && this.errors.length > 0) || this.infoText !== '';
        },
    },
    watch: {
        value(query) {
            this.query = query;
        },
        items: {
            immediate: true,
            handler(val, oldValue) {
                this.results = val;
                this.isLoading = false;
            },
        },
        async arrowCounter(val) {
            if (val < 0) {
                return;
            }
            this.setItemScrollPosition();
        },
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    destroyed() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        async setItemScrollPosition() {
            await this.$nextTick();
            const el = this.$refs.results.querySelector('.autocomplete__result.is-active');
            if (!el) {
                return;
            }
            const elOffset = el.offsetTop;
            const elHeight = el.offsetHeight;
            const containerHeight = this.$refs.results.clientHeight;
            this.$refs.results.scrollTop = elOffset - containerHeight + elHeight;
        },
        isDisabledResult(result) {
            return !!this.disabledItems.find((item) => item[this.itemKey] === result[this.itemKey]);
        },
        onEscape() {
            this.isOpen = false;
        },
        onFocus() {
            this.isFocused = true;

            if (!this.openOnFocus) {
                return;
            }
            this.arrowCounter = this.getArrowCounter();
            this.isOpen = true;
            this.$emit('focus');
        },
        onInput: debounce(function () {
            this.$emit('query', this.query);
            this.arrowCounter = 0;
            if (this.isAsync) {
                this.isOpen = !!this.query;
                this.isLoading = true;
            } else {
                this.isOpen = true;
                this.filterResults();
            }
        }, 200),
        onClear() {
            this.results = [];
            this.query = '';
            this.$emit('clear');
        },
        filterResults() {
            this.results = this.items.filter((item) => {
                return item.toLowerCase().includes(this.query.toLowerCase());
            });
        },
        getArrowCounter() {
            return this.items.findIndex((item) => item === this.query);
        },
        getItemIndex(result) {
            return this.items.findIndex((item) => item === result);
        },
        setResult(result, index) {
            if (!result || this.isDisabledResult(result)) {
                return;
            }
            this.query = result[this.itemKey] || result;
            this.isOpen = false;
            this.$emit('input', result, this.getItemIndex(result), index);
        },
        onArrowDown(evt) {
            if (this.arrowCounter < this.results.length) {
                this.arrowCounter = this.arrowCounter + 1;
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
            }
        },
        onEnter() {
            const result = this.results[this.arrowCounter];
            if (this.isDisabledResult(result)) {
                return;
            }
            this.setResult(result, this.arrowCounter);

            this.arrowCounter = -1;
        },
        handleClickOutside(evt) {
            if (!this.$el.contains(evt.target)) {
                this.isOpen = false;
                this.arrowCounter = -1;
            }
        },
        focus() {
            this.$refs.input.focus();
        },
    },
};
</script>

<style lang="scss" scoped>
.autocomplete {
    position: relative;
}

.autocomplete__input-wrapper {
    position: relative;
}

.autocomplete__input {
    padding-right: 38px;
}

.autocomplete__icon {
    float: right;
    padding-left: 5px;
}

.autocomplete__results {
    padding-top: 3px;
    position: absolute;
    z-index: $auto-complete-results;
    margin-top: -3px;
    border: 1px solid $input-focus-border-color;
    border-top-color: $color-athens-gray;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 200px;
    overflow: auto;
    width: 100%;
    background-color: $color-white;

    &--error {
        border-color: $error-color;
        border-top-color: $color-athens-gray;
    }
}

.autocomplete__result,
.autocomplete__loading {
    padding: 2px 11px;
    font-size: $font-size-sm;
    color: $color-oxford-blue;

    &:first-child {
        margin-top: 11px;
    }

    &:last-child {
        margin-bottom: 11px;
    }
}

.autocomplete__result {
    list-style: none;
    text-align: left;
    cursor: pointer;

    &-inner {
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 8px 10px;
    }

    &.is-active,
    &:hover {
        .autocomplete__result-inner {
            background-color: $color-athens-gray-light;
        }
    }
    &.is-disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.5;
    }
}
</style>
