<template>
    <div id="error">Oops...</div>
</template>

<script>
export default {
    name: 'Error',
    components: {},
    data() {
        return {};
    },
    computed: {
        // ...mapGetters('status', ['getFbStatus']),
    },
    methods: {},
};
</script>
