import { IContentItem } from '../interfaces';
import { Result, AccordionItemBase } from '..';
import { IAnswer } from '../interfaces/answer.interface.dto';
import { ContentItemType } from '@/store/enums';

export class ResultAccordianItemFactory {
    public make(contentItem: IContentItem): AccordionItemBase {
        const result = new Result();

        for (const answer of contentItem.answers as IAnswer[]) {
            const resultItem = new Result();
            resultItem.title = answer.message;
            resultItem.subtitle = answer.tagline;
            resultItem.imageUrl = answer.imageUrl;
            resultItem.sharingTitle = answer.inviteMessage;
            resultItem.sharingSubtitle = answer.inviteTagline;

            if (contentItem.type === ContentItemType.TriviaQuizResult) {
                resultItem.isDeletable = false;
            }

            result.items.push(resultItem);
        }

        return result;
    }
}
