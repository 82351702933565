<template>
    <div class="content-wrapper" :class="{ 'nav-is-opened': navIsOpened }">
        <MobileNavigation
            :is-opened="navIsOpened"
            :menu-items="menuItems"
            :matched-route-path="matchedRoutePath"
            @mobileNavigationItemOnClick="mobileNavigationItemOnClick"
        />

        <Navigation
            :is-opened="navIsOpened"
            :menu-items="menuItems"
            :matched-route-path="matchedRoutePath"
            @toggleOnClick="navToggleOnClick"
        />

        <main class="content" :class="{ 'content--boxed': boxedView }">
            <router-view :nav-is-opened="navIsOpened" />
        </main>
    </div>
</template>

<script>
import Navigation from '@/components/ui/Navigation';
import MobileNavigation from '@/components/ui/MobileNavigation';
import menuItems from '@/json/menu.ts';
import Cookies from 'js-cookie';

export default {
    name: 'Main',
    components: {
        Navigation,
        MobileNavigation,
    },
    data: function () {
        return {
            menuItems,
            navIsOpened: false,
            navCookieName: 'side_nav',
        };
    },
    computed: {
        matchedRoutePath() {
            if (this.$route.matched.length > 1) {
                return this.$route.matched[1].path;
            }
            return '';
        },
        boxedView() {
            return this.$route?.meta?.layout === 'boxed';
        },
    },
    async created() {
        const cookieValue = Cookies.get(this.navCookieName);
        this.navIsOpened = Number(cookieValue) === 1 ? true : false;
    },
    methods: {
        navToggleOnClick() {
            this.navIsOpened = !this.navIsOpened;
            Cookies.set(this.navCookieName, this.navIsOpened ? 1 : 0);
        },
        mobileNavigationItemOnClick() {
            this.navIsOpened = false;
        },
    },
};
</script>

<style lang="scss" scoped>
$nav-width-collapsed: 50px;
$nav-width-opened: 240px;

.content-wrapper {
    display: flex;
    min-height: 100vh;
    padding-left: $nav-width-collapsed;
    transition: padding-left 500ms;

    &.nav-is-opened {
        padding-left: $nav-width-opened;
    }
}

.content {
    width: 100%;
    padding: 40px;
    min-width: 840px;

    @include media-query(large) {
        padding: $layout-top-space 120px $layout-bottom-space 45px;
    }

    &--boxed {
        max-width: 1440px;
        margin: 0 auto;
    }
}
</style>
