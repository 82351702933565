<template>
    <div class="content-item-wrapper">
        <Icon v-if="!isTopLevel" class="hierarchy-icon" name="hierarchy" />

        <div class="content-item" :class="`content-item--${type}`">
            <Badge :type="type">{{ label }}</Badge>

            <span class="content-item__title">{{ item.text }}</span>

            <span v-if="item.info" class="content-item__info">({{ item.info }})</span>

            <button v-if="!fixed" class="content-item__remove" @click="$emit('remove')">
                <Icon name="cross-simple" />
            </button>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import Badge from '@/components/ui/Badge';

export default {
    name: 'ContentSelectorItem',
    components: {
        Icon,
        Badge,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        isTopLevel: {
            type: Boolean,
            default: false,
        },
        fixed: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.content-item-wrapper {
    position: relative;

    .hierarchy-icon {
        @include center(y);
        position: absolute;
        left: -27px;
        margin-top: -2px;
        font-size: 17px;
        color: #aeb5c8;
    }
}

.content-item {
    $self: &;

    position: relative;
    padding: 8px 30px 8px 10px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    font-size: 12px;

    &__title {
        margin-left: 5px;
    }

    &__info {
        margin-left: 5px;
        color: #aeb5c8;
    }

    &__remove {
        @include center(y);
        position: absolute;
        right: 10px;
        cursor: pointer;
        font-size: 10px;
    }

    &--channel {
        border-color: $logic-jump-channel-color;
        background-color: $logic-jump-item-channel-background-color;

        #{$self}__remove {
            color: $logic-jump-channel-color;
        }
    }

    &--convo {
        border-color: $logic-jump-convo-color;
        background-color: $logic-jump-item-convo-background-color;

        #{$self}__remove {
            color: $logic-jump-convo-color;
        }
    }

    &--menu {
        border-color: $color-polo-blue;
        background-color: rgba($color-polo-blue, 0.1);

        #{$self}__remove {
            color: $color-polo-blue;
        }
    }

    &--message {
        border-color: $logic-jump-message-color;
        background-color: $logic-jump-item-message-background-color;

        #{$self}__remove {
            color: $logic-jump-message-color;
        }
    }
}
</style>
