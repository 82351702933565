import { validate as validateClass, IsNotEmpty, Length } from 'class-validator';
import hash from 'object-hash';
import { ConvoMessageItemType } from '@/store/enums';
import { AccordionItemBase } from './accordionItemBase';
import { IContentItem, IContentItemConvertible } from './interfaces';
import { IConvoState } from '../modules/convo-details';
import { IObservableItem } from './interfaces/observableItem.interface';
import { IValidatable } from './interfaces/validatable.interface';
import { getErrorMessage } from '@/store/services/validationService';
import buttonsMessageTypeAnswerOptions from '@/json/buttonsMessageTypeAnswerOptions';
import { ButtonsAnswerType } from './buttonsAnswerType';
import { RegistrationContentItemFactory } from './factories/registrationContentItem.factory';
import { RegistrationAccordianItemFactory } from './factories/registrationAccordianItem.factory';

export class Registration
    extends AccordionItemBase
    implements IContentItemConvertible, IObservableItem<IConvoState>, IValidatable<IConvoState>
{
    constructor(items = []) {
        super(items);
        this.type = ConvoMessageItemType.Registration;
        this.icon = 'registration';
        this.isDraggable = false;
        this.isDeletable = true;
        this.regTitle = '';
        this.regSubtitle = '';

        const acceptButtonType = buttonsMessageTypeAnswerOptions.find((e) => e.name === 'linkNext');
        this.acceptButtonLabel = '';
        this.acceptButtonType = acceptButtonType;
        this.acceptButtonLink = 'Link would be auto generated';
        this.acceptButtonAnswerType = acceptButtonType?.title || '';

        const replyButtonType = buttonsMessageTypeAnswerOptions.find((e) => e.name === 'reply');
        this.continueButtonLabel = '';
        this.continueButtonType = replyButtonType;
        this.continueButtonAnwserType = replyButtonType?.title || '';
    }

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Title') })
    @Length(0, 80, { message: getErrorMessage('length', 'Title', { min: 0, max: 80 }) })
    regTitle: string;
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Title') })
    @Length(0, 80, { message: getErrorMessage('length', 'Subtitle', { min: 0, max: 80 }) })
    regSubtitle: string;
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Image') })
    image: string;
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Button Label') })
    acceptButtonLabel: string;
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Button Label') })
    continueButtonLabel: string;

    prefix: string;
    acceptButtonType: ButtonsAnswerType | undefined;
    acceptButtonLink: string;
    continueButtonType: ButtonsAnswerType | undefined;
    acceptButtonAnswerType: string;
    continueButtonAnwserType: string;

    setTitle() {
        this.subtitle = this.regTitle;
    }

    itemHasChanges(state: IConvoState): boolean {
        const contentItemFactory = new RegistrationContentItemFactory();
        const contentItem = contentItemFactory.make(this, state);

        contentItem.version = hash(contentItem);

        if (this.originalVersion) {
            return contentItem.version !== this.originalVersion;
        }
        return false;
    }

    toContentItem(state: IConvoState): IContentItem {
        const contentItemFactory = new RegistrationContentItemFactory();
        return contentItemFactory.make(this, state);
    }

    toAccordionItem(contentItem: IContentItem, isClone?: boolean): AccordionItemBase {
        const accordianItemFactory = new RegistrationAccordianItemFactory();
        return accordianItemFactory.make(contentItem);
    }

    async validate(state: IConvoState, path: number[]): Promise<any[]> {
        const errors = await validateClass(this);
        const errorMessages: any[] = [];

        if (errors.length > 0) {
            errors.forEach((item) => {
                errorMessages.push({
                    path,
                    errors: { [item.property]: Object.values(item.constraints || {}) },
                });
            });
        }

        return errorMessages;
    }
}
