import { IChannelReportRow } from '../interfaces/channelGroupReport.interface';

export class GetChannelGroupReportListRequest {
    q?: string;
    page: number;
    size: number;
}
export class GetChannelGroupReportRequest {
    tags: string[];
}

export class CreateChannelGroupReportRequest {
    name: string;
    tagConfig: string[];
    reportData: IChannelReportRow[];
}
export class IChannelGroupReportDto {
    id: number;
    name: string;
    tagConfig: string[];
    createdAt: string;
    updatedAt: string;
    reportData: IChannelReportRow[];
}
