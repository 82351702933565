import i18n from '@/i18n';
import { ChannelLandingPage } from '../channelLandingPage';
import { WelcomeMessage } from '../dtos/channel.dto';
import { ChannelLandingPageByLocale } from '../dtos/channelLandingPageByLocale.dto';
import {
    ChannelFacebookRequest,
    ChannelRequest,
    IChannelYougovPreferencesRequest,
} from '../dtos/channelRequests.dto';
import { IChannelItem, IChannelStyle } from '../interfaces/channel.interface';
import { Tag } from '../tag';
import { Topic } from '../topic';

export class ChannelRequestFactory {
    public make(data: IChannelItem): ChannelRequest {
        const channelRequest = new ChannelRequest();

        channelRequest.slug = data.slug;
        channelRequest.color = data.color;
        channelRequest.name = data.name;
        channelRequest.description = data.description;

        if (data.tags) {
            channelRequest.tags = data.tags.map((o) => {
                const tag = new Tag();
                tag.id = o.id || 0;
                tag.name = o.name;
                return tag;
            });
        }

        if (data.topics) {
            channelRequest.topics = data.topics.map((o) => {
                const topic = new Topic();
                topic.id = o.id || 0;
                topic.name = o.name;
                topic.externalId = o.externalId;
                return topic;
            });
        }

        channelRequest.platforms = data.platforms && data.platforms.map((o) => o.code.toString());
        channelRequest.portraitImage = data.portraitImage;
        channelRequest.landscapeImage = data.landscapeImage;
        channelRequest.squareImage = data.squareImage;
        channelRequest.tagLine = data.tagLine;
        channelRequest.sharingTitle = data.sharingTitle;
        channelRequest.sharingDescription = data.sharingDescription;
        channelRequest.showMemberCount = data.showMemberCount;
        channelRequest.status = data.status;
        channelRequest.isFeatured = data.isFeatured;
        channelRequest.isStandalone = data.isStandalone;

        channelRequest.welcomeMessage = new WelcomeMessage();
        channelRequest.welcomeMessage.message = data.welcomeMessage;
        channelRequest.welcomeMessage.contentDiscoveryItem = 0;

        channelRequest.facebookData = new ChannelFacebookRequest();
        channelRequest.facebookData.fbPageId = data.fbPageId;
        channelRequest.facebookData.fbAppId = data.fbAppId;
        channelRequest.facebookData.fbAccessToken = data.fbAccessToken;
        channelRequest.facebookData.style = data.style as IChannelStyle;
        channelRequest.landingPage = {} as ChannelLandingPageByLocale;
        channelRequest.emailLocale = data.emailLocale;
        channelRequest.emailVariables = data.emailLocale
            ? (i18n as any)[data.emailLocale].emailVariables
            : {};
        channelRequest.timezoneIdentifier = data.timezoneIdentifier;

        channelRequest.yougovPreferences = {} as IChannelYougovPreferencesRequest;

        if (data.yougovPreferences.welcomeFlows?.length) {
            const weight = Math.round((1 / data.yougovPreferences.welcomeFlows.length) * 100);

            channelRequest.yougovPreferences.welcomeFlows = data.yougovPreferences.welcomeFlows.map(
                (item) => ({
                    type: item.type,
                    id: item.id,
                    name: item.name,
                    weight,
                }),
            );
        }

        if (
            data.landingPage.channelTitle ||
            data.landingPage.title ||
            data.landingPage.description
        ) {
            const defaultLocale = 'en';
            const landingPageEn = new ChannelLandingPage();
            landingPageEn.title = data.landingPage.title;
            landingPageEn.channelTitle = data.landingPage.channelTitle;
            landingPageEn.description = data.landingPage.description;
            channelRequest.landingPage[defaultLocale] = landingPageEn;
        }

        if (data.convoSchedule.schedule) {
            channelRequest.convoSchedule = {
                convos: data.convoSchedule.selectedConvos,
                schedule: data.convoSchedule.schedule,
                scheduledConfig: data.convoSchedule.scheduledConfig,
            };
        }

        return channelRequest;
    }
}
