import { LoadingFlag } from '@/store/enums';
import { IBroadcastCampaignMetricDto, IBroadcastDto } from '@/store/models/dtos/broadcast.dto';
import {
    GetBroadcastCampaignMetricRequest,
    GetBroadcastRequest,
    GetBroadcastsRequest,
} from '@/store/models/dtos/broadcastRequests.dto';
import axios from '@/utils/request';
import { IPaging } from '@inconvo/types/interfaces';

export class BroadcastClient {
    public static async getByConvoId({
        convoId,
        type,
    }: GetBroadcastRequest): Promise<IPaging<IBroadcastDto>> {
        const result = await axios.request<any>({
            url: `/broadcasts`,
            params: { convoId, type, page: 1, size: 1 },
            method: 'GET',
            errorMessage: 'Could not get broadcast',
            loadingId: LoadingFlag.BroadcastGetOne,
        });

        return result as unknown as Promise<IPaging<IBroadcastDto>>;
    }

    public static async getBroadcasts({
        page,
        size,
        channelCode,
        convoId,
        type,
    }: GetBroadcastsRequest): Promise<IPaging<IBroadcastDto>> {
        const result = await axios.request<any>({
            url: `/broadcasts`,
            params: { channelCode, convoId, type, page, size },
            method: 'GET',
            errorMessage: 'Could not get broadcasts',
            loadingId: LoadingFlag.BroadcastsGet,
        });
        return result as unknown as Promise<IPaging<IBroadcastDto>>;
    }
    public static async getCampaignMetric({
        broadcastId,
        campaignId,
        appId,
    }: GetBroadcastCampaignMetricRequest): Promise<IBroadcastCampaignMetricDto> {
        const result = await axios.request<any>({
            url: `/broadcasts/campaign-metric`,
            params: { appId, campaignId, broadcastId },
            method: 'GET',
            errorMessage: 'Could not get campaign metric for given broadcast',
            loadingId: LoadingFlag.BroadcastCampaignGetOne,
        });
        return result as unknown as Promise<IBroadcastCampaignMetricDto>;
    }
}
