export default [
    {
        id: 'horizontal',
        value: 'Horizontal Scroll (default)',
    },
    {
        id: 'vertical',
        value: 'Vertical - Stacked',
    },
    {
        id: 'freeform',
        value: 'Vertical - Freeform',
    },
];
