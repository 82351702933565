<template>
    <div class="tab-actions">
        <div class="move-tab">
            <div class="move-tab__buttons">
                <button
                    class="move-tab-button"
                    :disabled="isPinned || index === 0"
                    @click="$emit('moveTab', 'left', index)"
                >
                    <Icon name="arrow-left" />
                </button>
                <button
                    class="move-tab-button"
                    :disabled="isPinned || index === tabsLength - 1 || nextIsPinned"
                    @click="$emit('moveTab', 'right', index)"
                >
                    <Icon name="arrow-right" />
                </button>
            </div>
            <div class="move-tab__title">Reorder {{ tabItemName }}</div>
        </div>
        <div class="tab-action-buttons">
            <button v-if="showPinAction" @click="$emit('pin', index)">
                <Icon v-if="!isPinned" name="pin" />
                <Icon v-else name="unpin" />
                <span v-if="!isPinned">
                    Pin
                    <span class="hide-for-small-only">as last</span> {{ tabItemName }}
                </span>
                <span v-else>
                    Unpin
                    <span class="hide-for-small-only">as last</span> {{ tabItemName }}
                </span>
            </button>
            <button :disabled="tabsLength <= tabsMinLength" @click="$emit('delete', index)">
                <Icon name="delete" />
                <span>
                    Delete
                    <span class="hide-for-small-only">{{ tabItemName }}</span>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    name: 'TabActions',
    components: {
        Icon,
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        tabsLength: {
            type: Number,
            required: true,
        },
        tabsMinLength: {
            type: Number,
            default: 1,
        },
        tabItemName: {
            type: String,
            default: 'tile',
        },
        showPinAction: {
            type: Boolean,
            default: true,
        },
        isPinned: {
            type: Boolean,
            default: false,
        },
        nextIsPinned: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.tab-actions {
    display: flex;
    justify-content: space-between;
    margin: 7px 0 14px;
}

.tab-action-buttons {
    display: flex;
    text-align: center;

    button {
        margin-left: 15px;
        margin-bottom: 2px;
        font-size: 12px;
        color: $color-java;
        text-transform: uppercase;
        cursor: pointer;

        @include media-query(medium) {
            display: flex;
            align-items: center;
            margin-left: 28px;
        }

        &:disabled {
            color: rgba($color-java, 0.5);
        }

        &:not(:disabled):hover {
            text-decoration: underline;
        }

        .icon {
            margin: 0 auto 2px;
            font-size: 18px;

            @include media-query(medium) {
                margin-right: 2px;
            }
        }
    }
}

.move-tab {
    margin-top: 1px;
    text-align: center;

    @include media-query(medium) {
        display: flex;
        align-items: center;
    }

    &__buttons {
        display: flex;
        justify-content: space-around;

        @include media-query(medium) {
            margin-right: 20px;
        }
    }

    &__title {
        margin-top: 7px;
        font-size: 12px;
        color: $color-java;
        text-transform: uppercase;
        line-height: 1.3em;

        @include media-query(medium) {
            margin-top: 0;
        }
    }

    button {
        font-size: 12px;
        color: $color-java;

        &:first-child {
            margin-right: 30px;
        }

        &:not(:disabled) {
            cursor: pointer;
        }

        &:disabled {
            opacity: 0.4;
        }
    }
}
</style>
