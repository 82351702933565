import { getErrorMessage } from '@/store/services/validationService';
import { LogicJump } from '.';
import { ConvoMessageItemType } from '../enums';
import { AccordionStyle } from '../enums/accordionStyle.enum';
import { ListMarker } from '../enums/listMarker.enum';
import { IConvoState } from '../modules/convo-details';
import { AccordionItemBase } from './accordionItemBase';

export class CommentsRatingTargetInput extends AccordionItemBase {
    constructor(items = []) {
        super(items);
        this.accordionStyle = AccordionStyle.SecondLevel;
        this.type = ConvoMessageItemType.CommentsRatingTargetInput;
        this.markerType = ListMarker.Numeral;
        this.isDraggable = false;
        this.isDeletable = true;
        this.logicJump = new LogicJump();
    }

    async validate(state: IConvoState, path: number[]): Promise<any> {
        const errorMessages: any[] = [];
        if (
            !this.logicJump?.items.channel ||
            !this.logicJump?.items.convo ||
            !this.logicJump?.items.message
        ) {
            errorMessages.push({
                path,
                errors: {
                    logicJump: [getErrorMessage('commentsRatingTarget')],
                },
            });
        }

        return errorMessages;
    }
}
