import { validate as validateClass, Length, ValidationError } from 'class-validator';
import { ConvoMessageItemType } from '@/store/enums';
import { AccordionItemBase } from './accordionItemBase';
import { IConvoState } from '../modules/convo-details';
import { getErrorMessage } from '@/store/services/validationService';
import { IRichText } from './interfaces';

export class InlineResponseTextStatement extends AccordionItemBase implements IRichText {
    constructor(items = []) {
        super(items);
        this.type = ConvoMessageItemType.InlineResponseTextStatement;
        this.icon = 'statement-text';
        this.plainText = '';
        this.statement = '';
        this.isDraggable = true;
        this.isDeletable = true;
    }

    @Length(0, 640, { message: getErrorMessage('length', 'Message', { min: 0, max: 640 }) })
    plainText: string;
    statement: string;

    setTitle(): void {
        this.subtitle = this.plainText;
    }

    async validate(state: IConvoState, path: number[]): Promise<any[]> {
        const errors: ValidationError[] = await validateClass(this);
        const errorMessages: any[] = [];

        if (errors.length > 0) {
            errors.forEach((item: ValidationError) => {
                errorMessages.push({
                    path,
                    errors: { [item.property]: Object.values(item.constraints || {}) },
                });
            });
        }

        return errorMessages;
    }
}
