import deAT from './de-AT.json';
import deCH from './de-CH.json';
import de from './de.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import frCH from './fr-CH.json';
import itCH from './it-CH.json';
import it from './it.json';
import ms from './ms.json';
import pl from './pl.json';
import pt from './pt.json';
import th from './th.json';
import tr from './tr.json';
import zh from './zh.json';

export default {
    'de-AT': deAT,
    'de-CH': deCH,
    de,
    en,
    es,
    fr,
    'fr-CH': frCH,
    it,
    'it-CH': itCH,
    ms,
    pl,
    pt,
    th,
    tr,
    zh,
};
