import { SortingOrder, ChartType } from '@/store/enums';

export default [
    {
        id: 'none',
        value: 'None',
        chartType: ChartType.None,
        order: SortingOrder.None,
    },
    {
        id: 'horizontal_default',
        value: 'Horizontal - default',
        chartType: ChartType.Horizontal,
        order: SortingOrder.None,
    },
    {
        id: 'horizontal_descending',
        value: 'Horizontal - descending',
        chartType: ChartType.Horizontal,
        order: SortingOrder.Descending,
    },
    {
        id: 'horizontal_ascending',
        value: 'Horizontal - ascending',
        chartType: ChartType.Horizontal,
        order: SortingOrder.Ascending,
    },
];
