import { RuleAttributeType } from '@/store/enums/ruleAttributeType.enum';

export const ruleTypeAttributes = [
    {
        text: 'Convo: Started a convo',
        name: RuleAttributeType.StartedConvo,
    },
    {
        text: 'Convo: Completed a convo',
        name: RuleAttributeType.CompletedConvo,
    },
    {
        text: 'Convo: Response to a specific question',
        name: RuleAttributeType.RespondedToQuestion,
    },
    {
        text: 'User: User Location',
        name: RuleAttributeType.UserLocation,
    },
    {
        text: 'User: Joined channel',
        name: RuleAttributeType.JoinedChannel,
    },
];
