import { Message } from './message';
import { MessageType } from './enums/messageType.enum';

export class Reply extends Message {
    text: string;

    constructor(text: string) {
        super(MessageType.Reply);

        this.text = text;
    }
}
