<template>
    <button
        class="message-type-button"
        :class="[buttonStyleClass, { active: isActive }]"
        @click="$emit('click', $event)"
    >
        <Icon class="icon" :name="icon" :size="40" :color="isActive ? '#fff' : defaultColor" />
        <slot />
    </button>
</template>

<script>
import Icon from '@/components/ui/Icon';
import onlyValues from '@/utils/onlyValues.ts';
import { AccordionStyle } from '@/store/enums/accordionStyle.enum.ts';
import colors from '@styles/abstracts/_variables.colors.scss';

export default {
    name: 'MessageTypeButton',
    components: {
        Icon,
    },
    props: {
        icon: {
            type: String,
            required: true,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        buttonStyle: {
            type: String,
            default: AccordionStyle.Default,
            validator: onlyValues(Object.values(AccordionStyle)),
        },
    },
    data: function () {
        return {
            defaultColor: colors['first-primary-color'],
        };
    },
    computed: {
        buttonStyleClass() {
            if (this.buttonStyle === AccordionStyle.ThirdLevel) {
                return 'style-third-level';
            }

            return '';
        },
    },
};
</script>

<style lang="scss" scoped>
.message-type-button {
    display: flex;
    align-items: center;
    padding: 0 15px 0 7px;
    background-color: $button-secondary-color;
    border-radius: 2px;
    font-size: 14px;
    color: $button-main-color;
    cursor: pointer;

    .icon {
        margin-right: 3px;
        flex: 0 0 1;
    }

    &.active {
        background: $button-main-color;
        color: $button-secondary-color;
    }
}

.style-third-level {
    background: $button-third-level-color;
}
</style>
