<template>
    <div v-if="show" class="loading-spinner">
        <div>
            <Spinner name="branded" :size="35" />
        </div>
    </div>
</template>

<script>
import Spinner from '@/components/ui/Spinner';

export default {
    name: 'FullPageLoadingSpinner',
    components: {
        Spinner,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.loading-spinner {
    background: linear-gradient(
        45deg,
        transparent,
        transparent 50%,
        $full-page-loading-spinner-dot-color 50%,
        $full-page-loading-spinner-dot-color
    );
    background-size: 2px 2px;

    position: absolute;
    z-index: $layer-10;
    width: 100%;
    text-align: center;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
