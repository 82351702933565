<template>
    <transition name="fade">
        <div v-if="isDialogVisible" class="dialog-overlay" role="dialog">
            <div
                ref="dialog"
                v-click-outside="clickOutside"
                class="dialog"
                :class="[{ 'dialog--with-footer': showFooter }]"
            >
                <div class="dialog-content">
                    <header class="dialog-header">
                        <slot name="header"></slot>
                    </header>

                    <section class="dialog-body">
                        <slot name="body"></slot>
                    </section>
                </div>

                <footer v-if="showFooter" class="dialog-footer">
                    <slot name="footer">
                        <Button
                            class="dialog-footer-button dialog-footer-close-button"
                            button-style="secondary"
                            size="small"
                            left-aligned-text
                            :disabled="isLoading"
                            @click="onClose"
                        >
                            {{ closeButtonText }}
                        </Button>
                        <Button
                            v-if="showConfirmButton"
                            class="dialog-footer-button"
                            has-border
                            size="small"
                            :is-loading="isLoading"
                            :disabled="isLoading"
                            @click.once="$emit('confirmButtonOnClick', $event)"
                        >
                            {{ confirmButtonText }}
                        </Button>
                    </slot>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
import Button from '@/components/ui/Button';

export default {
    name: 'Dialog',
    components: {
        Button,
    },
    props: {
        isDialogVisible: {
            type: Boolean,
            default: false,
        },
        confirmButtonText: {
            type: String,
            default: 'Yes, save',
        },
        closeButtonText: {
            type: String,
            default: 'No, cancel',
        },
        showConfirmButton: {
            type: Boolean,
            default: false,
        },
        showFooter: {
            type: Boolean,
            default: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        closeOnEscape: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            closeOnEscapeEvent: null,
        };
    },
    watch: {
        isDialogVisible(newValue) {
            this.toggleDialog(newValue);
        },
    },
    created() {
        if (this.closeOnEscape) {
            this.closeOnEscapeEvent = (event) => {
                if (event.keyCode === 27) {
                    this.$emit('closeOnEscapeEvent');
                    this.$emit('close');
                }
            };
        }

        this.toggleDialog(this.isDialogVisible);
    },
    destroyed() {
        this.toggleDialog(false);
    },
    methods: {
        onClose(event) {
            this.$emit('onClose', event);
            this.$emit('close');
        },
        clickOutside(a, b) {
            if (this.closeOnEscape) {
                this.$emit('onClickOutside');
                this.$emit('close');
            }
        },
        toggleDialog(disable) {
            if (disable) {
                document.body.classList.add('dialog-opened');
                if (this.closeOnEscape) {
                    document.body.addEventListener('keyup', this.closeOnEscapeEvent);
                }
            } else {
                document.body.classList.remove('dialog-opened');
                if (this.closeOnEscape) {
                    document.body.removeEventListener('keyup', this.closeOnEscapeEvent);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.dialog-overlay {
    position: fixed;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color-light, 0.8);
    justify-content: center;
    align-items: center;
    z-index: $dialog-z-index;
}

.dialog {
    border-radius: 4px;
    border: 1px solid $color-athens-gray;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 90%;
    font-size: $font-size-base;
    background-color: $color-light;
    overflow-x: auto;
    max-height: calc(100vh - 2rem);
    @include media-query(small) {
        min-width: $dialog-min-width - 70px;
        max-width: $dialog-min-width - 140px;
    }

    @include media-query(medium) {
        min-width: $dialog-min-width;
    }
    &--with-footer {
        padding-bottom: 0;
    }
}

.dialog-header {
    margin-bottom: 1.5rem;
    font-size: $font-size-lg;
}

.dialog-body {
    font-size: $font-size-sm;
}

.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    padding: 2rem 0;
    text-align: right;
    position: sticky;
    bottom: 0;
    background-color: rgba($color-light, 0.8);
}

.fade-enter-active {
    animation: fade-in 0.2s;
}

.fade-leave-active {
    animation: fade-in 0.1s reverse;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>
