<template>
    <ImageUploaderButton
        input-style="green"
        :button-label="data.url || 'Click here to attach an image'"
        :value="data.url"
        :errors="data.errors && data.errors.url"
        @input="(value) => onInput('url', value)"
        @changed="onChanged"
    />
</template>

<script>
import itemFormMixin from '@/mixins/itemFormMixin';
import ImageUploaderButton from '@/components/forms/ImageUploaderButton';

export default {
    name: 'AttachmentUrl',
    components: {
        ImageUploaderButton,
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        currentChannel: {
            type: Object,
            default: undefined,
        },
        currentConvo: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {};
    },
    methods: {},
};
</script>
