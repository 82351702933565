import { CommentSortingOptions } from '@/store/enums';
import {
    CommentsRatingStatus,
    CommentsRatingStatusReason,
    RetrainClassification,
    CommentsReportType,
    ToxicityType,
} from '@inconvo/types/enums';

export class GetUserCommentsRequest {
    channel_codes: string[];
    convo_ids: string[];
    text_input_item_ids: string[];
    comments_rating_item_ids: string[];
    comment_text: string[];
    page: number;
    size: number;
    status?: CommentsRatingStatus;
    status_reason?: CommentsRatingStatusReason;
    toxicity_type?: ToxicityType;
    report_type?: CommentsReportType;
    confidence_gte?: string;
    confidence_lt?: string;
    sort?: CommentSortingOptions;
    user_identifier?: string;
    from_date?: Date;
    to_date?: Date;
}

export class UpdateUserCommentRequest {
    channel_code: string;
    status: CommentsRatingStatus;
    status_reason?: CommentsRatingStatusReason;
    retrain?: boolean;
    retrain_classification?: RetrainClassification;
}

export class GetCommentsStatsRequest {
    from_date: Date;
}
