import { LoadingFlag } from '@/store/enums/loadingIds.enum';
import {
    GetUserCommentsRequest,
    UpdateUserCommentRequest,
    UserCommentDto,
} from '@/store/models/dtos';
import { CommentsStatsDto } from '@/store/models/dtos/userComment.dto';
import { GetCommentsStatsRequest } from '@/store/models/dtos/userCommentsRequests.dto';
import axios from '@/utils/request';
import { IPaging } from '@inconvo/types/interfaces';

export interface IUserCommentClient {
    getUserComments: (data: GetUserCommentsRequest) => Promise<IPaging<UserCommentDto>>;
    updateUserComment: (
        commentId: string,
        data: UpdateUserCommentRequest,
    ) => Promise<UserCommentDto>;
}

export class UserCommentClient implements IUserCommentClient {
    public async getUserComments(params: GetUserCommentsRequest): Promise<IPaging<UserCommentDto>> {
        const result = await axios.request<GetUserCommentsRequest>({
            url: '/v2/comments',
            method: 'GET',
            params,
            errorMessage: 'Could not load user comments',
            loadingId: LoadingFlag.UserCommentsGet,
        });

        return result as unknown as Promise<IPaging<UserCommentDto>>;
    }

    public async getUserCommentsStats(params: GetCommentsStatsRequest): Promise<CommentsStatsDto> {
        const result = await axios.request<GetCommentsStatsRequest>({
            url: '/v2/comments/stats',
            method: 'GET',
            params,
            errorMessage: 'Could not load user comments stats',
            loadingId: LoadingFlag.UserCommentsGetStats,
        });

        return result as unknown as Promise<CommentsStatsDto>;
    }

    public async updateUserComment(
        commentId: string,
        data: UpdateUserCommentRequest,
    ): Promise<UserCommentDto> {
        const url = `/comments/${encodeURIComponent(commentId)}`;
        const result = await axios.request<UpdateUserCommentRequest>({
            url,
            method: 'PUT',
            data,
            errorMessage: 'Could not update user comment',
            loadingId: `${LoadingFlag.UserCommentUpdate}-${commentId}`,
        });
        return result as unknown as Promise<UserCommentDto>;
    }
}
