interface IWarningMessages {
    [key: string]: Function;
}

const warningMessage: IWarningMessages = {
    convoAttributeLength: ({ name, max }: { name: string; max: number }) =>
        `Convo with a ${name} larger than ${max} characters cannot be broadcast via email`,
};

export default warningMessage;
