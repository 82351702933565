import { IsNotEmpty, ValidateIf, validate as validator } from 'class-validator';
import { getErrorMessage } from '@/store/services/validationService';
import { ContentType } from '@/store/enums/contentType.enum';
import { IContentOption } from '@/store/models/interfaces';
import { ContentOption } from '@/store/models';

export interface ILogicJumpItems {
    channel?: ContentOption;
    convo?: ContentOption;
    message?: ContentOption;
}

export interface ILogicJump {
    channel?: IContentOption;
    convo?: IContentOption;
    message?: IContentOption;
    originalIsSet?: boolean;
    isLoading?: boolean;
    contentType?: ContentType;
}

export class LogicJump {
    items: ILogicJumpItems;
    originalIsSet: boolean;
    isLoading: boolean;
    contentType: ContentType;
    validateContentType: boolean;

    @ValidateIf(
        (o) =>
            o.contentType === ContentType.Channel ||
            o.contentType === ContentType.Convo ||
            o.contentType === ContentType.Message,
    )
    @IsNotEmpty({ message: getErrorMessage('logicJumpContent', 'channel') })
    channel?: ContentOption;

    @ValidateIf((o) => o.contentType === ContentType.Convo || o.contentType === ContentType.Message)
    @IsNotEmpty({ message: getErrorMessage('logicJumpContent', 'convo') })
    convo?: ContentOption;

    @ValidateIf((o) => o.contentType === ContentType.Message)
    @IsNotEmpty({ message: getErrorMessage('logicJumpContent', 'message') })
    message?: ContentOption;

    constructor({
        channel,
        convo,
        message,
        originalIsSet,
        isLoading,
        contentType,
    }: ILogicJump = {}) {
        this.setItems({ channel, convo, message });
        this.originalIsSet = originalIsSet || false;
        this.isLoading = isLoading || false;

        if (contentType) {
            this.setContentType(contentType);
        }
    }

    public setContentType(type: ContentType) {
        this.contentType = type;
    }

    public setItems({ channel, convo, message }: ILogicJump) {
        this.items = {};
        delete this.channel;
        delete this.convo;
        delete this.message;

        if (channel) {
            const channelContent = new ContentOption(channel);
            this.items.channel = channelContent;
            this.channel = channelContent;
        }
        if (convo) {
            const convoContent = new ContentOption(convo);
            this.items.convo = convoContent;
            this.convo = convoContent;

            if (!channel) {
                const channelContent = new ContentOption({
                    type: ContentType.Channel,
                } as IContentOption);
                this.items.channel = channelContent;
                this.channel = channelContent;
            }
        }
        if (message) {
            const messageContent = new ContentOption(message);
            this.items.message = messageContent;
            this.message = messageContent;
        }
    }

    public async validate() {
        const errors = await validator(this);
        return errors.reduce((acc, cur) => {
            acc[cur.property] = Object.values(cur.constraints || {});
            return acc;
        }, <any>{});
    }
}
