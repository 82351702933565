import {
    IConvoSchedule,
    IScheduledConfig,
    IScheduledConvo,
} from './interfaces/convoSchedule.interface';

export class ConvoSchedule implements IConvoSchedule {
    schedule: string;
    availableConvos: IScheduledConvo[];
    selectedConvos: IScheduledConvo[];
    scheduledConfig: IScheduledConfig[];

    constructor() {
        this.schedule = '';
        this.availableConvos = [];
        this.selectedConvos = [];
        this.scheduledConfig = [];
    }
}
