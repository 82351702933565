import Vue from 'vue';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ContentDiscoveryMenu } from '@/store/models/contentDiscoveryMenu/contentDiscoveryMenu';
import {
    UPDATE_MENU_PROPERTY,
    UPDATE_MENU_ITEM_PROPERTY,
    ADD_MENU_ITEM,
    SET_MENU_ITEM,
    DELETE_MENU_ITEM,
    SET_MENU,
    SET_MENU_ITEMS,
    SET_MENU_LIST,
    SET_ERRORS,
    SET_VERSION_HASH,
} from './mutations.types';
import { IRootState } from '@/store/types/rootState';
import { ContentDiscoveryMenuClient } from '@/api/content-discovery-menu';
import { ContentDiscoveryMenuRequestFactory as MenuRequestFactory } from '@/store/models/factories/contentDiscoveryMenuRequest.factory';
import {
    ContentDiscoveryMenuFactory,
    ContentDiscoveryMenuFactory as MenuFactory,
} from '@/store/models/factories/contentDiscoveryMenu.factory';
import { IContentDiscoveryMenuItem } from '@/store/models/contentDiscoveryMenu/contentDiscoveryMenu.interface';
import hash from 'object-hash';
import { IContentDiscoveryMenuDto } from '@/store/models/dtos';

interface IMenuPayload {
    key: string;
    value: any;
}

interface IMenuItemPayload {
    key: string;
    value: any;
    index: number;
    validate?: boolean;
}

interface IMenuErrors {
    items?: { [key: string]: string }[];
    [key: string]: string | object[] | undefined;
}

const getRequestFactoryPayload = (state: IContentDiscoveryMenuState, channelId: number = 0) => {
    return {
        channelId,
        ...state.menu,
        menuItems: state.menuItems,
    };
};

export interface IContentDiscoveryMenuState {
    menu: ContentDiscoveryMenu;
    menuItems: IContentDiscoveryMenuItem[];
    menuList: Partial<ContentDiscoveryMenu>[];
    errors: IMenuErrors;
    versionHash: string;
}

const getDefaultState = (): IContentDiscoveryMenuState => {
    const menuBase = ContentDiscoveryMenuFactory.makeMenuBase();

    return {
        menu: new ContentDiscoveryMenu(),
        menuItems: [menuBase],
        menuList: [],
        errors: {},
        versionHash: '',
    };
};

const getters: GetterTree<IContentDiscoveryMenuState, IRootState> = {
    isValid: (state) => Object.keys(state.errors).length === 0,
    hasChanges: (state) => {
        const request = MenuRequestFactory.make(getRequestFactoryPayload(state));
        return hash(request) !== state.versionHash;
    },
};

const actions: ActionTree<IContentDiscoveryMenuState, any> = {
    setMenuType: async ({ commit, dispatch }, payload: { menuType: string; index: number }) => {
        dispatch('clearErrors');
        const menuItem = ContentDiscoveryMenuFactory.makeMenuItem({ type: payload.menuType });
        commit(SET_MENU_ITEM, { index: payload.index, menuItem });
    },
    clearMenu: ({ commit, dispatch }) => {
        dispatch('clearErrors');
        const defaultState = getDefaultState();
        commit(SET_MENU, defaultState.menu);
        commit(SET_MENU_ITEMS, defaultState.menuItems);
    },
    loadMenu: async ({ dispatch }, id: number) => {
        dispatch('clearErrors');
        const dto = await ContentDiscoveryMenuClient.getMenu(id);
        dispatch('processMenuDto', dto);
    },
    saveMenu: async ({ state }, channelId: number) => {
        const menuRequest = MenuRequestFactory.make(getRequestFactoryPayload(state, channelId));
        if (state.menu.id === 0) {
            await ContentDiscoveryMenuClient.createMenu(menuRequest);
        } else {
            await ContentDiscoveryMenuClient.updateMenu(state.menu.id, menuRequest);
        }
    },
    updateMenuProperty: async ({ commit }, payload) => {
        commit(UPDATE_MENU_PROPERTY, payload);
    },
    updateMenuItemProperty: async ({ commit, dispatch }, payload: IMenuItemPayload) => {
        commit(UPDATE_MENU_ITEM_PROPERTY, payload);
        if (payload.validate) {
            dispatch('validate');
        }
    },
    addMenuItem: ({ commit }) => {
        const defaultMenuItem = ContentDiscoveryMenuFactory.makeMenuBase();
        commit(ADD_MENU_ITEM, defaultMenuItem);
    },
    deleteMenu: async ({}, payload: { menuId: number; channelId: number }) => {
        await ContentDiscoveryMenuClient.deleteMenu(payload);
    },
    deleteMenuItem: async ({ commit }, index: number) => {
        commit(DELETE_MENU_ITEM, index);
    },
    reorderItems: ({ commit, state, getters, dispatch }, idxOrder: number[]) => {
        const reorderedItems = idxOrder.map((idx) => state.menuItems[idx]);
        commit(SET_MENU_ITEMS, reorderedItems);
        if (!getters.isValid) {
            dispatch('validate');
        }
    },
    setDefaultMenu: async ({ dispatch }, payload: { menuId: number; channelId: number }) => {
        await ContentDiscoveryMenuClient.setDefaultMenu(payload);
        dispatch('getMenuList', payload.channelId);
    },
    getMenuList: async ({ commit }, channelId?: number) => {
        const list = await ContentDiscoveryMenuClient.getMenuList(channelId);
        commit(SET_MENU_LIST, list);
    },
    processMenuDto: ({ commit }, dto: IContentDiscoveryMenuDto) => {
        const menu = MenuFactory.makeMenu(dto);
        commit(SET_MENU, menu);

        const menuItems = dto.metadata.map(MenuFactory.makeMenuItem);
        commit(SET_MENU_ITEMS, menuItems);

        const menuRequest = MenuRequestFactory.make(getRequestFactoryPayload(state));
        const versionHash = hash(menuRequest);
        commit(SET_VERSION_HASH, versionHash);
    },
    validate: async ({ state, commit, dispatch }) => {
        dispatch('clearErrors');
        const [errors, itemsErrors] = await Promise.all([
            state.menu.validate(),
            Promise.all(state.menuItems.map((item) => item.validate())),
        ]);
        const hasItemsErrors = itemsErrors.some((error) => error);
        if (hasItemsErrors) {
            errors.items = itemsErrors;
        }
        commit(SET_ERRORS, errors);
    },
    clearErrors: ({ commit }) => {
        commit(SET_ERRORS, {});
    },
};

const mutations: MutationTree<IContentDiscoveryMenuState> = {
    [SET_MENU_LIST](
        state: IContentDiscoveryMenuState,
        payload: Partial<ContentDiscoveryMenu>[],
    ): void {
        state.menuList = payload;
    },
    [SET_MENU](state: IContentDiscoveryMenuState, payload: ContentDiscoveryMenu): void {
        state.menu = payload;
    },
    [SET_MENU_ITEMS](
        state: IContentDiscoveryMenuState,
        payload: IContentDiscoveryMenuItem[],
    ): void {
        state.menuItems = payload;
    },
    [SET_MENU_ITEM](
        state: IContentDiscoveryMenuState,
        { index, menuItem }: { index: number; menuItem: IContentDiscoveryMenuItem },
    ): void {
        state.menuItems.splice(index, 1, menuItem);
    },
    [ADD_MENU_ITEM](state: IContentDiscoveryMenuState, item: IContentDiscoveryMenuItem): void {
        state.menuItems.push(item);
    },
    [UPDATE_MENU_PROPERTY](state: IContentDiscoveryMenuState, payload: IMenuPayload): void {
        Vue.set(state.menu, payload.key, payload.value);
    },
    [UPDATE_MENU_ITEM_PROPERTY](
        state: IContentDiscoveryMenuState,
        payload: IMenuItemPayload,
    ): void {
        Vue.set(state.menuItems[payload.index], payload.key, payload.value);
    },
    [DELETE_MENU_ITEM](state: IContentDiscoveryMenuState, index: number): void {
        state.menuItems.splice(index, 1);
    },
    [SET_ERRORS](state: IContentDiscoveryMenuState, errors: IMenuErrors): void {
        state.errors = errors;
    },
    [SET_VERSION_HASH](state: IContentDiscoveryMenuState, payload: string): void {
        state.versionHash = payload;
    },
};

const state: IContentDiscoveryMenuState = getDefaultState();

export const contentDiscoveryMenu: Module<IContentDiscoveryMenuState, IRootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
