import { IContentDiscoveryMenuItem as IMenuItem } from './contentDiscoveryMenu.interface';
import { IsNotEmpty, validate as validator } from 'class-validator';
import { IValidatable } from '@/store/models/interfaces/validatable.interface';
import { ContentDiscoveryMenuItemType } from '@/enums';

export class MenuBase implements IMenuItem, IValidatable<IMenuItem> {
    @IsNotEmpty()
    menuType: ContentDiscoveryMenuItemType;

    async validate(): Promise<any> {
        const errors = await validator(this);
        const result = errors.reduce((acc, cur) => {
            acc[cur.property] = Object.values(cur.constraints || {});
            return acc;
        }, <any>{});
        return errors.length ? result : undefined;
    }
}
