<template>
    <div class="view-unauthorised">
        <ModalBaseAuth class="text-center">
            <p>You are not allowed to access this page.</p>
            <p class="mt-2">Please ask an administrator to check if you have the right roles.</p>
            <Button id="unauthorised-logout" class="m-t-2" has-border @click="logout">
                Back to sign in
            </Button>
        </ModalBaseAuth>
    </div>
</template>

<script>
import ModalBaseAuth from '@/components/Modal/ModalBaseAuth';
import Button from '@/components/ui/Button';
import config from '@/config';

export default {
    name: 'NotAllowed',
    components: {
        Button,
        ModalBaseAuth,
    },
    data() {
        return {};
    },
    computed: {
        // ...mapGetters('status', ['getFbStatus']),
    },
    methods: {
        async logout() {
            await this.$store.dispatch('auth/signOut');
            this.$router.push({ name: 'sign-in' });
        },
    },
};
</script>

<style lang="scss" scoped>
.view-unauthorised {
    background-color: $color-athens-gray;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
