import { LinkTarget, RegistrationType } from '@/store/enums';
import { Tenant } from '..';
import { Application } from '../application';
import { ITenantResponse } from '../interfaces';
import { TenantSignupTrigger } from '@/store/enums/tenantSignupTrigger.enum';
import { IApplication } from '../interfaces/application.interface';

export class TenantResponseFactory {
    private tenant: Tenant;

    public make(tenantResponse: ITenantResponse) {
        this.tenant = new Tenant();

        this.tenant.id = tenantResponse.id;
        this.tenant.code = tenantResponse.code;
        this.tenant.name = tenantResponse.name;
        this.tenant.appKey = tenantResponse.appKey;
        this.tenant.appSecret = tenantResponse.appSecret;
        this.tenant.allowOrigins = tenantResponse.allowOrigins
            ? JSON.parse(tenantResponse.allowOrigins)
            : [];
        this.tenant.channels = tenantResponse.channels;
        this.tenant.applications = [];

        if (tenantResponse.applications?.length) {
            for (const applicationResponse of tenantResponse.applications) {
                const app = new Application();
                app.id = applicationResponse.id;
                app.code = applicationResponse.code;
                app.name = applicationResponse.name;
                app.signupType = applicationResponse.signupType;
                app.skipAuthForKnownUsers = applicationResponse.skipAuthForKnownUsers;
                app.allowNoSignup = applicationResponse.allowNoSignup;
                app.fingerprintTrackingEnabled = applicationResponse.fingerprintTrackingEnabled;
                app.maxFreeConvos = applicationResponse.maxFreeConvos;
                app.tenantId = applicationResponse.tenantId;
                app.registrationType = applicationResponse.registrationType;
                app.registrationOptions = {
                    subscribeMessage: applicationResponse.registrationOptions.subscribeMessage,
                    subscribeButtonText:
                        applicationResponse.registrationOptions.subscribeButtonText,
                    conditionsText: applicationResponse.registrationOptions.conditionsText,
                    inputText: applicationResponse.registrationOptions.inputText,
                };
                app.linkOptions = {
                    target: applicationResponse.linkOptions?.target || LinkTarget.Blank,
                };

                app.signupOptions = {
                    trigger: this.getSignupTrigger(applicationResponse),
                    message: applicationResponse.options?.signup?.message?.toString(),
                    defaultSubscribeSelection:
                        !!applicationResponse.options?.signup?.defaultSubscribeSelection,
                };
                app.options = applicationResponse.options;

                this.tenant.applications.push(app);
            }
        }

        return this.tenant;
    }

    private getSignupTrigger(applicationResponse: IApplication): TenantSignupTrigger {
        if (applicationResponse.authEnabled) {
            if (applicationResponse.registrationType === RegistrationType.Signup) {
                return TenantSignupTrigger.SignupChatStart;
            } else if (applicationResponse.registrationType === RegistrationType.Subscribe) {
                return TenantSignupTrigger.SubscribeChatStart;
            }
        } else if (applicationResponse.options?.comments?.requireAuthenticationToLoad) {
            return TenantSignupTrigger.SignupLoadComments;
        }

        return TenantSignupTrigger.Disabled;
    }
}
