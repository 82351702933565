import { getErrorMessage } from '@/store/services/validationService';
import { ContentDiscoveryMenuItemType } from '@/enums';
import { ArrayNotEmpty, Max } from 'class-validator';
import { IFormOption } from '@/interfaces';
import { MenuBase } from './menuBase';

export interface IConvoStreamMenu {
    channels: IFormOption[];
    sortOrder: string;
    convoTypes: string[];
    convoTags: IFormOption[];
    convoLength: number;
    chatGroup: string[];
}

export class ConvoStreamMenu extends MenuBase implements IConvoStreamMenu {
    @ArrayNotEmpty({ message: getErrorMessage('arrayNotEmpty', 'channel') })
    channels: IFormOption[];

    @Max(10, { message: getErrorMessage('max', 'Number of convos', { max: 10 }) })
    convoLength: number;

    menuType: ContentDiscoveryMenuItemType;
    sortOrder: string;
    convoTypes: string[];
    convoTags: IFormOption[];
    chatGroup: string[];

    constructor() {
        super();
        this.menuType = ContentDiscoveryMenuItemType.ConvoStream;
    }
}
