import { ICountryList } from '@/interfaces/countryList.interface';

const allCountries = [
    ['Afghanistan (‫افغانستان‬‎)', 'af', '🇦🇫'],
    ['Albania (Shqipëri)', 'al', '🇦🇱'],
    ['Algeria (‫الجزائر‬‎)', 'dz', '🇩🇿'],
    ['American Samoa', 'as', '🇦🇸'],
    ['Andorra', 'ad', '🇦🇩'],
    ['Angola', 'ao', '🇦🇴'],
    ['Anguilla', 'ai', '🇦🇮'],
    ['Antigua and Barbuda', 'ag', '🇦🇬'],
    ['Argentina', 'ar', '🇦🇷', 'yougovCountry'],
    ['Armenia (Հայաստան)', 'am', '🇦🇲'],
    ['Aruba', 'aw', '🇦🇼'],
    ['Australia', 'au', '🇦🇺', 'yougovCountry'],
    ['Austria (Österreich)', 'at', '🇦🇹', 'yougovCountry'],
    ['Azerbaijan (Azərbaycan)', 'az', '🇦🇿'],
    ['Bahamas', 'bs', '🇧🇸'],
    ['Bahrain (‫البحرين‬‎)', 'bh', '🇧🇭'],
    ['Bangladesh (বাংলাদেশ)', 'bd', '🇧🇩'],
    ['Barbados', 'bb', '🇧🇧'],
    ['Belarus (Беларусь)', 'by', '🇧🇾'],
    ['Belgium (België)', 'be', '🇧🇪', 'yougovCountry'],
    ['Belize', 'bz', '🇧🇿'],
    ['Benin (Bénin)', 'bj', '🇧🇯'],
    ['Bermuda', 'bm', '🇧🇲'],
    ['Bhutan (འབྲུག)', 'bt', '🇧🇹'],
    ['Bolivia', 'bo', '🇧🇴'],
    ['Bosnia and Herzegovina (Босна и Херцеговина)', 'ba', '🇧🇦'],
    ['Botswana', 'bw', '🇧🇼'],
    ['Brazil (Brasil)', 'br', '🇧🇷', 'yougovCountry'],
    ['British Indian Ocean Territory', 'io', '🇮🇴'],
    ['British Virgin Islands', 'vg', '🇻🇬'],
    ['Brunei', 'bn', '🇧🇳'],
    ['Bulgaria (България)', 'bg', '🇧🇬', 'yougovCountry'],
    ['Burkina Faso', 'bf', '🇧🇫'],
    ['Burundi (Uburundi)', 'bi', '🇧🇮'],
    ['Cambodia (កម្ពុជា)', 'kh', '🇰🇭'],
    ['Cameroon (Cameroun)', 'cm', '🇨🇲'],
    ['Canada', 'ca', '🇨🇦', 'yougovCountry'],
    ['Cape Verde (Kabu Verdi)', 'cv', '🇨🇻'],
    ['Caribbean Netherlands', 'bq', '🇧🇶'],
    ['Cayman Islands', 'ky', '🇰🇾'],
    ['Central African Republic (République centrafricaine)', 'cf', '🇨🇫'],
    ['Chad (Tchad)', 'td', '🇹🇩'],
    ['Chile', 'cl', '🇨🇱'],
    ['China (中国)', 'cn', '🇨🇳', 'yougovCountry'],
    ['Christmas Island', 'cx', '🇨🇽'],
    ['Cocos (Keeling) Islands', 'cc', '🇨🇨'],
    ['Colombia', 'co', '🇨🇴', 'yougovCountry'],
    ['Comoros (‫جزر القمر‬‎)', 'km', '🇰🇲'],
    ['Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', 'cd', '🇨🇩'],
    ['Congo (Republic) (Congo-Brazzaville)', 'cg', '🇨🇬'],
    ['Cook Islands', 'ck', '🇨🇰'],
    ['Costa Rica', 'cr', '🇨🇷'],
    ['Côte d’Ivoire', 'ci', '🇨🇮'],
    ['Croatia (Hrvatska)', 'hr', '🇭🇷', 'yougovCountry'],
    ['Cuba', 'cu', '🇨🇺'],
    ['Curaçao', 'cw', '🇨🇼'],
    ['Cyprus (Κύπρος)', 'cy', '🇨🇾'],
    ['Czech Republic (Česká republika)', 'cz', '🇨🇿', 'yougovCountry'],
    ['Denmark (Danmark)', 'dk', '🇩🇰', 'yougovCountry'],
    ['Djibouti', 'dj', '🇩🇯'],
    ['Dominica', 'dm', '🇩🇲'],
    ['Dominican Republic (República Dominicana)', 'do', '🇩🇴'],
    ['Ecuador', 'ec', '🇪🇨'],
    ['Egypt (‫مصر‬‎)', 'eg', '🇪🇬', 'yougovCountry'],
    ['El Salvador', 'sv', '🇸🇻'],
    ['Equatorial Guinea (Guinea Ecuatorial)', 'gq', '🇬🇶'],
    ['Eritrea', 'er', '🇪🇷'],
    ['Estonia (Eesti)', 'ee', '🇪🇪'],
    ['Ethiopia', 'et', '🇪🇹'],
    ['Falkland Islands (Islas Malvinas)', 'fk', '🇫🇰'],
    ['Faroe Islands (Føroyar)', 'fo', '🇫🇴'],
    ['Fiji', 'fj', '🇫🇯'],
    ['Finland (Suomi)', 'fi', '🇫🇮', 'yougovCountry'],
    ['France', 'fr', '🇫🇷', 'yougovCountry'],
    ['French Guiana (Guyane française)', 'gf', '🇬🇫'],
    ['French Polynesia (Polynésie française)', 'pf', '🇵🇫'],
    ['Gabon', 'ga', '🇬🇦'],
    ['Gambia', 'gm', '🇬🇲'],
    ['Georgia (საქართველო)', 'ge', '🇬🇪'],
    ['Germany (Deutschland)', 'de', '🇩🇪', 'yougovCountry'],
    ['Ghana (Gaana)', 'gh', '🇬🇭'],
    ['Gibraltar', 'gi', '🇬🇮'],
    ['Greece (Ελλάδα)', 'gr', '🇬🇷', 'yougovCountry'],
    ['Greenland (Kalaallit Nunaat)', 'gl', '🇬🇱'],
    ['Grenada', 'gd', '🇬🇩'],
    ['Guadeloupe', 'gp', '🇬🇵'],
    ['Guam', 'gu', '🇬🇺'],
    ['Guatemala', 'gt', '🇬🇹'],
    ['Guernsey', 'gg', '🇬🇬'],
    ['Guinea (Guinée)', 'gn', '🇬🇳'],
    ['Guinea-Bissau (Guiné Bissau)', 'gw', '🇬🇼'],
    ['Guyana', 'gy', '🇬🇾'],
    ['Haiti', 'ht', '🇭🇹'],
    ['Honduras', 'hn', '🇭🇳'],
    ['Hong Kong (香港)', 'hk', '🇭🇰', 'yougovCountry'],
    ['Hungary (Magyarország)', 'hu', '🇭🇺', 'yougovCountry'],
    ['Iceland (Ísland)', 'is', '🇮🇸'],
    ['India (भारत)', 'in', '🇮🇳', 'yougovCountry'],
    ['Indonesia', 'id', '🇮🇩', 'yougovCountry'],
    ['Iran (‫ایران‬‎)', 'ir', '🇮🇷'],
    ['Iraq (‫العراق‬‎)', 'iq', '🇮🇶'],
    ['Ireland', 'ie', '🇮🇪', 'yougovCountry'],
    ['Isle of Man', 'im', '🇮🇲'],
    ['Israel (‫ישראל‬‎)', 'il', '🇮🇱'],
    ['Italy (Italia)', 'it', '🇮🇹', 'yougovCountry'],
    ['Jamaica', 'jm', '🇯🇲'],
    ['Japan (日本)', 'jp', '🇯🇵'],
    ['Jersey', 'je', '🇯🇪'],
    ['Jordan (‫الأردن‬‎)', 'jo', '🇯🇴'],
    ['Kazakhstan (Казахстан)', 'kz', '🇰🇿'],
    ['Kenya', 'ke', '🇰🇪'],
    ['Kiribati', 'ki', '🇰🇮'],
    ['Kosovo', 'xk', '🇽🇰'],
    ['Kuwait (‫الكويت‬‎)', 'kw', '🇰🇼'],
    ['Kyrgyzstan (Кыргызстан)', 'kg', '🇰🇬'],
    ['Laos (ລາວ)', 'la', '🇱🇦'],
    ['Latvia (Latvija)', 'lv', '🇱🇻'],
    ['Lebanon (‫لبنان‬‎)', 'lb', '🇱🇧', 'yougovCountry'],
    ['Lesotho', 'ls', '🇱🇸'],
    ['Liberia', 'lr', '🇱🇷'],
    ['Libya (‫ليبيا‬‎)', 'ly', '🇱🇾'],
    ['Liechtenstein', 'li', '🇱🇮'],
    ['Lithuania (Lietuva)', 'lt', '🇱🇹'],
    ['Luxembourg', 'lu', '🇱🇺'],
    ['Macau (澳門)', 'mo', '🇲🇴'],
    ['Macedonia (FYROM) (Македонија)', 'mk', '🇲🇰'],
    ['Madagascar (Madagasikara)', 'mg', '🇲🇬'],
    ['Malawi', 'mw', '🇲🇼'],
    ['Malaysia', 'my', '🇲🇾', 'yougovCountry'],
    ['Maldives', 'mv', '🇲🇻'],
    ['Mali', 'ml', '🇲🇱'],
    ['Malta', 'mt', '🇲🇹'],
    ['Marshall Islands', 'mh', '🇲🇭'],
    ['Martinique', 'mq', '🇲🇶'],
    ['Mauritania (‫موريتانيا‬‎)', 'mr', '🇲🇷'],
    ['Mauritius (Moris)', 'mu', '🇲🇺'],
    ['Mayotte', 'yt', '🇾🇹'],
    ['Mexico (México)', 'mx', '🇲🇽', 'yougovCountry'],
    ['Micronesia', 'fm', '🇫🇲'],
    ['Moldova (Republica Moldova)', 'md', '🇲🇩'],
    ['Monaco', 'mc', '🇲🇨'],
    ['Mongolia (Монгол)', 'mn', '🇲🇳'],
    ['Montenegro (Crna Gora)', 'me', '🇲🇪'],
    ['Montserrat', 'ms', '🇲🇸'],
    ['Morocco (‫المغرب‬‎)', 'ma', '🇲🇦', 'yougovCountry'],
    ['Mozambique (Moçambique)', 'mz', '🇲🇿'],
    ['Myanmar (Burma) (မြန်မာ)', 'mm', '🇲🇲'],
    ['Namibia (Namibië)', 'na', '🇳🇦'],
    ['Nauru', 'nr', '🇳🇷'],
    ['Nepal (नेपाल)', 'np', '🇳🇵'],
    ['Netherlands (Nederland)', 'nl', '🇳🇱', 'yougovCountry'],
    ['New Caledonia (Nouvelle-Calédonie)', 'nc', '🇳🇨'],
    ['New Zealand', 'nz', '🇳🇿'],
    ['Nicaragua', 'ni', '🇳🇮'],
    ['Niger (Nijar)', 'ne', '🇳🇪'],
    ['Nigeria', 'ng', '🇳🇬'],
    ['Niue', 'nu', '🇳🇺'],
    ['Norfolk Island', 'nf', '🇳🇫'],
    ['North Korea (조선 민주주의 인민 공화국)', 'kp', '🇰🇵'],
    ['Northern Mariana Islands', 'mp', '🇲🇵'],
    ['Norway (Norge)', 'no', '🇳🇴', 'yougovCountry'],
    ['Oman (‫عُمان‬‎)', 'om', '🇴🇲'],
    ['Pakistan (‫پاکستان‬‎)', 'pk', '🇵🇰', 'yougovCountry'],
    ['Palau', 'pw', '🇵🇼'],
    ['Palestine (‫فلسطين‬‎)', 'ps', '🇵🇸'],
    ['Panama (Panamá)', 'pa', '🇵🇦'],
    ['Papua New Guinea', 'pg', '🇵🇬'],
    ['Paraguay', 'py', '🇵🇾'],
    ['Peru (Perú)', 'pe', '🇵🇪'],
    ['Philippines', 'ph', '🇵🇭', 'yougovCountry'],
    ['Poland (Polska)', 'pl', '🇵🇱', 'yougovCountry'],
    ['Portugal', 'pt', '🇵🇹', 'yougovCountry'],
    ['Puerto Rico', 'pr', '🇵🇷'],
    ['Qatar (‫قطر‬‎)', 'qa', '🇶🇦'],
    ['Réunion (La Réunion)', 're', '🇷🇪'],
    ['Romania (România)', 'ro', '🇷🇴', 'yougovCountry'],
    ['Russia (Россия)', 'ru', '🇷🇺'],
    ['Rwanda', 'rw', '🇷🇼'],
    ['Saint Barthélemy', 'bl', '🇧🇱'],
    ['Saint Helena', 'sh', '🇸🇭'],
    ['Saint Kitts and Nevis', 'kn', '🇰🇳'],
    ['Saint Lucia', 'lc', '🇱🇨'],
    ['Saint Martin (Saint-Martin (partie française))', 'mf', '🇲🇫'],
    ['Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', 'pm', '🇵🇲'],
    ['Saint Vincent and the Grenadines', 'vc', '🇻🇨'],
    ['Samoa', 'ws', '🇼🇸'],
    ['San Marino', 'sm', '🇸🇲'],
    ['São Tomé and Príncipe (São Tomé e Príncipe)', 'st', '🇸🇹'],
    ['Saudi Arabia (‫المملكة العربية السعودية‬‎)', 'sa', '🇸🇦', 'yougovCountry'],
    ['Senegal (Sénégal)', 'sn', '🇸🇳'],
    ['Serbia (Србија)', 'rs', '🇷🇸'],
    ['Seychelles', 'sc', '🇸🇨'],
    ['Sierra Leone', 'sl', '🇸🇱'],
    ['Singapore', 'sg', '🇸🇬', 'yougovCountry'],
    ['Sint Maarten', 'sx', '🇸🇽'],
    ['Slovakia (Slovensko)', 'sk', '🇸🇰', 'yougovCountry'],
    ['Slovenia (Slovenija)', 'si', '🇸🇮'],
    ['Solomon Islands', 'sb', '🇸🇧'],
    ['Somalia (Soomaaliya)', 'so', '🇸🇴'],
    ['South Africa', 'za', '🇿🇦'],
    ['South Korea (대한민국)', 'kr', '🇰🇷'],
    ['South Sudan (‫جنوب السودان‬‎)', 'ss', '🇸🇸'],
    ['Spain (España)', 'es', '🇪🇸', 'yougovCountry'],
    ['Sri Lanka (ශ්‍රී ලංකාව)', 'lk', '🇱🇰'],
    ['Sudan (‫السودان‬‎)', 'sd', '🇸🇩'],
    ['Suriname', 'sr', '🇸🇷'],
    ['Svalbard and Jan Mayen', 'sj', '🇸🇯'],
    ['Swaziland', 'sz', '🇸🇿'],
    ['Sweden (Sverige)', 'se', '🇸🇪', 'yougovCountry'],
    ['Switzerland (Schweiz)', 'ch', '🇨🇭', 'yougovCountry'],
    ['Syria (‫سوريا‬‎)', 'sy', '🇸🇾'],
    ['Taiwan (台灣)', 'tw', '🇹🇼', 'yougovCountry'],
    ['Tajikistan', 'tj', '🇹🇯'],
    ['Tanzania', 'tz', '🇹🇿'],
    ['Thailand (ไทย)', 'th', '🇹🇭', 'yougovCountry'],
    ['Timor-Leste', 'tl', '🇹🇱'],
    ['Togo', 'tg', '🇹🇬'],
    ['Tokelau', 'tk', '🇹🇰'],
    ['Tonga', 'to', '🇹🇴'],
    ['Trinidad and Tobago', 'tt', '🇹🇹'],
    ['Tunisia (‫تونس‬‎)', 'tn', '🇹🇳'],
    ['Turkey (Türkiye)', 'tr', '🇹🇷', 'yougovCountry'],
    ['Turkmenistan', 'tm', '🇹🇲'],
    ['Turks and Caicos Islands', 'tc', '🇹🇨'],
    ['Tuvalu', 'tv', '🇹🇻'],
    ['U.S. Virgin Islands', 'vi', '🇻🇮'],
    ['Uganda', 'ug', '🇺🇬'],
    ['Ukraine (Україна)', 'ua', '🇺🇦'],
    ['United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', 'ae', '🇦🇪', 'yougovCountry'],
    ['United Kingdom', 'gb', '🇬🇧', 'yougovCountry'],
    ['United States', 'us', '🇺🇸', 'yougovCountry'],
    ['Uruguay', 'uy', '🇺🇾'],
    ['Uzbekistan (Oʻzbekiston)', 'uz', '🇺🇿'],
    ['Vanuatu', 'vu', '🇻🇺'],
    ['Vatican City (Città del Vaticano)', 'va', '🇻🇦'],
    ['Venezuela', 've', '🇻🇪'],
    ['Vietnam (Việt Nam)', 'vn', '🇻🇳', 'yougovCountry'],
    ['Wallis and Futuna (Wallis-et-Futuna)', 'wf', '🇼🇫'],
    ['Western Sahara (‫الصحراء الغربية‬‎)', 'eh', '🇪🇭'],
    ['Yemen (‫اليمن‬‎)', 'ye', '🇾🇪'],
    ['Zambia', 'zm', '🇿🇲'],
    ['Zimbabwe', 'zw', '🇿🇼'],
    ['Åland Islands', 'ax', '🇦🇽'],
];

export default allCountries.map((country) => ({
    name: country[0],
    iso2: country[1].toString().toUpperCase(),
    flag: country[2],
    yougovCountry: !!country[3],
})) as ICountryList[];
