import { ContentDiscoveryMenuItemType as MenuType } from '@/enums';
import { formOptionMapper } from '@/utils/formOption';
import { IChannelMenu } from '../contentDiscoveryMenu/channelMenu';
import { IContentDiscoveryMenuItem } from '../contentDiscoveryMenu/contentDiscoveryMenu.interface';
import { IConvoMenu } from '../contentDiscoveryMenu/convoMenu';
import { IConvoStreamMenu } from '../contentDiscoveryMenu/convoStreamMenu';
import { ICustomMenu } from '../contentDiscoveryMenu/customMenu';
import {
    IChannelMenuDto,
    IContentDiscoveryMenuDto,
    IContentDiscoveryRuleSetDto,
    IConvoMenuDto,
    IConvoStreamMenuDto,
    ICustomMenuDto,
} from '../dtos';

const mapConvoStreamItems = (item: IConvoStreamMenu): IConvoStreamMenuDto => ({
    channels: formOptionMapper(item.channels),
    convo_tags: formOptionMapper(item.convoTags),
    sort_order: item.sortOrder,
    convo_types: item.convoTypes,
    convo_length: item.convoLength,
    chat_group: item.chatGroup,
});

const mapConvoItems = (item: IConvoMenu): IConvoMenuDto => ({
    logic_jump: item.logicJump?.items,
});

const mapChannelItems = (item: IChannelMenu): IChannelMenuDto => ({
    logic_jump: item.logicJump?.items,
});

const mapCustomItems = (item: ICustomMenu): ICustomMenuDto => {
    const dto: ICustomMenuDto = {
        title: item.title,
        type: item.type,
        image: item.image,
    };
    if (item.type === 'link') {
        dto.link_url = item.linkUrl;
        dto.link_target = item.linkTarget;
    }
    if (item.type === 'logicJump') {
        dto.logic_jump = item.logicJump?.items;
    }
    return dto;
};

const rulesMap = <Record<MenuType, any>>{
    [MenuType.ConvoStream]: mapConvoStreamItems,
    [MenuType.Convo]: mapConvoItems,
    [MenuType.Channel]: mapChannelItems,
    [MenuType.Custom]: mapCustomItems,
};

const mapItems = (item: IContentDiscoveryMenuItem): IContentDiscoveryRuleSetDto => {
    const ruleSet: IContentDiscoveryRuleSetDto = { type: item.menuType };
    if (rulesMap[item.menuType]) {
        ruleSet.rules = rulesMap[item.menuType](item);
    }
    return ruleSet;
};

export class ContentDiscoveryMenuRequestFactory {
    public static make(data: any): IContentDiscoveryMenuDto {
        return {
            channel_id: data.channelId,
            name: data.name,
            statement: data.statement,
            default: data.default,
            metadata: data?.menuItems?.length ? data.menuItems.map(mapItems) : [],
        };
    }
}
