export enum BroadcastStatus {
    Created = 'created',
    InProgress = 'in_progress',
    Success = 'success',
    Cancelled = 'cancelled',
    Error = 'error',
}

export enum BroadcastType {
    AudienceExport = 'audience_export',
    ConvoServiceEmail = 'convo_service_email',
    ConvoTestEmail = 'convo_test_email',
    ConvoTestReminderEmail = 'convo_test_reminder_email',
    ConvoReminderEmail = 'convo_reminder_email',
    EmailAutomation = 'email_automation',
}
