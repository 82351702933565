import axios from 'axios';
import Vue from 'vue';
import Vuex, { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { IRootState } from '../types/rootState';
import { SET_ISLOADING, SET_ERROR, SET_ERROR_MESSAGE, SET_SUCCESS } from '../mutations.types';
import { UploadClient } from '@/api/upload';
import { url } from 'inspector';

export interface IMainState {
    error: boolean;
    errorMessage: string;
    isLoading: boolean;
    success: boolean;
}

const state: IMainState = {
    error: false,
    errorMessage: '',
    isLoading: false,
    success: false,
};

const getters: GetterTree<IMainState, IRootState> = {
    getError: (state) => {
        return state.error;
    },
    getErrorMessage: (state) => {
        return state.errorMessage;
    },
    isLoading: (state) => {
        return state.isLoading;
    },
    getSuccess: (state) => {
        return state.success;
    },
};

const actions: ActionTree<IMainState, any> = {
    initStatus: async () => {},
    uploadImageFromUrl: async ({ commit }, { url, prefix }) => {
        const request = {
            url,
            metadata: {
                prefix,
            },
        };
        return await UploadClient.uploadImageFromUrl(request);
    },
    uploadImage: async ({ commit }, { formData }) => {
        return await UploadClient.uploadImageFromFile(formData);
    },
    setIsLoading: ({ commit }, value) => {
        commit(SET_ISLOADING, value);
    },
    setError: ({ commit }, value) => {
        commit(SET_ERROR, value);
    },
    setErrorMessage: ({ commit }, error) => {
        commit(SET_ERROR_MESSAGE, error);
    },
    setSuccess: ({ commit }, value) => {
        commit(SET_SUCCESS, value);
    },
};

const mutations: MutationTree<IMainState> = {
    [SET_ISLOADING](state, isLoading) {
        state.isLoading = isLoading;
    },
    [SET_ERROR](state, error) {
        state.error = error;
    },
    [SET_ERROR_MESSAGE](state, errorMessage) {
        state.errorMessage = errorMessage;
    },
    [SET_SUCCESS](state, success) {
        state.success = success;
    },
};

export const main: Module<IMainState, IRootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
