import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { LogicJump, ILogicJumpItems } from '@/store/models/logicJump';
import { IRootState } from '@/store/types/rootState';
import { getLogicJumpData } from '@/store/services/logicJumpService';
import { ContentType } from '@/store/enums';

import {
    CLEAR_LOGIC_JUMP,
    SHOW_LOGIC_JUMP,
    SET_LOGIC_JUMP_ITEMS,
    SET_LOGIC_JUMP_CONTENT_TYPE,
    SET_LOGIC_JUMP_ERRORS,
    SET_LOGIC_JUMP_VALIDATED,
    SET_LOGIC_JUMP_LOADING,
} from '@/store/modules/logic-jump/mutations.types';

export interface ILogicJumpState {
    logicJump: LogicJump;
    isOpen: boolean;
    isLoading: boolean;
    errors: object;
    validated: boolean;
}

export interface ILogicJumpRequest {
    targetChannel: string;
    targetConvo: number;
    targetContentItem: number;
}

export const state: ILogicJumpState = {
    logicJump: new LogicJump(),
    isOpen: false,
    isLoading: false,
    errors: {},
    validated: false,
};

export const getters: GetterTree<ILogicJumpState, IRootState> = {
    items: (state) => {
        return state.logicJump.items || {};
    },
    isValid: (state) => Object.keys(state.errors).length === 0,
};

export const actions: ActionTree<ILogicJumpState, any> = {
    getData: async ({ commit, dispatch }, data: ILogicJumpRequest) => {
        commit(SET_LOGIC_JUMP_LOADING, true);
        const { channel, convo, message } = await getLogicJumpData({
            targetChannel: data.targetChannel,
            targetConvo: data.targetConvo,
            targetContentItem: data.targetContentItem,
        });
        dispatch('setItems', { channel, convo, message });
        commit(SET_LOGIC_JUMP_LOADING, false);
    },
    open: ({ commit, dispatch }, payload: ILogicJumpRequest) => {
        dispatch('clear');
        let contentType = ContentType.Channel;
        if (payload.targetConvo !== 0) {
            contentType = ContentType.Convo;
        }
        if (payload.targetContentItem !== 0) {
            contentType = ContentType.Message;
        }
        dispatch('setContentType', contentType);
        if (payload?.targetChannel || payload?.targetContentItem || payload?.targetConvo) {
            dispatch('getData', payload);
        }
        commit(SHOW_LOGIC_JUMP, true);
    },
    close: ({ commit }) => {
        commit(SHOW_LOGIC_JUMP, false);
    },
    show: ({ commit }) => {
        commit(SHOW_LOGIC_JUMP, true);
    },
    clear: ({ commit }) => {
        commit(CLEAR_LOGIC_JUMP);
        commit(SET_LOGIC_JUMP_ERRORS, {});
        commit(SET_LOGIC_JUMP_VALIDATED, false);
    },
    validate: async ({ state, commit }) => {
        const errors = await state.logicJump.validate();
        // If no errors found by validator and state has prior errors, clear the errors.
        if (!Object.keys(errors).length && Object.keys(state.errors).length > 0) {
            commit(SET_LOGIC_JUMP_ERRORS, {});
        }
        // If errors found by validator, commit to state.
        if (Object.keys(errors).length) {
            commit(SET_LOGIC_JUMP_ERRORS, errors);
        }
        // If channel is validated for first time, set flag.
        if (!state.validated) {
            commit(SET_LOGIC_JUMP_VALIDATED, true);
        }
    },
    setItems: ({ commit }, items: ILogicJumpItems) => {
        commit(SET_LOGIC_JUMP_ITEMS, items);
    },
    setContentType: ({ commit, state }, type: ContentType) => {
        commit(SET_LOGIC_JUMP_CONTENT_TYPE, type);
    },
};

export const mutations: MutationTree<ILogicJumpState> = {
    [CLEAR_LOGIC_JUMP](state: ILogicJumpState): void {
        state.logicJump = new LogicJump();
    },
    [SHOW_LOGIC_JUMP](state: ILogicJumpState, payload: boolean): void {
        state.isOpen = payload;
    },
    [SET_LOGIC_JUMP_ITEMS](state: ILogicJumpState, payload: ILogicJumpItems): void {
        state.logicJump.setItems(payload);
    },
    [SET_LOGIC_JUMP_CONTENT_TYPE](state: ILogicJumpState, payload: ContentType): void {
        state.logicJump.setContentType(payload);
    },
    [SET_LOGIC_JUMP_ERRORS](state: ILogicJumpState, payload): void {
        state.errors = payload;
    },
    [SET_LOGIC_JUMP_VALIDATED](state: ILogicJumpState, payload: boolean): void {
        state.validated = payload;
    },
    [SET_LOGIC_JUMP_LOADING](state: ILogicJumpState, payload: boolean): void {
        state.isLoading = payload;
    },
};

export const logicJump: Module<ILogicJumpState, IRootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
