<template>
    <Overlay disable-body-scroll :show="show" :show-close-button="true" @onClose="$emit('close')">
        <section class="inline-response">
            <div class="inline-response__body">
                <div class="inline-response__body-inner">
                    <UnderlinedTitle type="h1">Inline Response</UnderlinedTitle>
                    <p class="m-b-3">Enter the inline response message</p>
                    <InlineResponseTextStatement
                        :data="data.items[0]"
                        :path="[...path, 0]"
                        @propertyOnChange="$emit('propertyOnChange', $event)"
                    />
                </div>
            </div>
            <div class="inline-response__footer">
                <Save
                    confirm-button-text="Save & Close"
                    :cancel-button-is-visible="false"
                    :is-saved="false"
                    :status-title-is-visible="false"
                    :info-is-visible="false"
                    :vertical-style="true"
                    @cancelButtonOnClick="$emit('close')"
                    @confirmButtonOnClick="$emit('close')"
                />
            </div>
        </section>
    </Overlay>
</template>

<script>
import Overlay from '@/components/ui/Overlay';
import Save from '@/components/ui/Save';
import UnderlinedTitle from '@/components/ui/UnderlinedTitle';
import InlineResponseTextStatement from '@/components/InlineResponseTextStatement';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum.ts';

export default {
    name: 'InlineResponse',
    components: {
        Overlay,
        Save,
        UnderlinedTitle,
        InlineResponseTextStatement,
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            ConvoMessageItemType,
        };
    },
    methods: {
        messageTypeOnSelect(event, path, index) {
            this.$emit(
                'addItemOnClick',
                event,
                path,
                this.data.inlineResponseSelector.options[index].name,
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.inline-response {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__body {
        padding-top: 50px;
        height: 100%;
        overflow-y: auto;
    }

    &__body-inner {
        width: 100%;
        max-width: 540px;
        margin: 0 auto;
        padding-bottom: 50px;
    }

    &__footer {
        width: 100%;
        max-width: 540px;
        margin: 0 auto;
    }
}
</style>
