import {
    IGetDefinitionDetailsRequest,
    IGetDefinitionsRequest,
} from '@/api/interfaces/yougov.interface';
import { LoadingFlag } from '@/store/enums';
import { IYougovDefinitionDto } from '@/store/models/dtos';
import axios from '@/utils/request';

class YougovClient {
    public async getDefinitions(payload: IGetDefinitionsRequest): Promise<IYougovDefinitionDto[]> {
        const result = await axios.request<IGetDefinitionsRequest>({
            url: '/yougov/datum/definitions',
            method: 'GET',
            params: payload,
            errorMessage: 'Could not get definitions.',
            loadingId: LoadingFlag.YougovDefinitionsGet,
        });

        return result as unknown as Promise<IYougovDefinitionDto[]>;
    }

    public async getDefinitionDetails(
        payload: IGetDefinitionDetailsRequest,
    ): Promise<IYougovDefinitionDto> {
        const result = await axios.request<IGetDefinitionsRequest>({
            url: `/yougov/datum/definitions/${encodeURIComponent(payload.definition)}`,
            method: 'GET',
            params: {
                country: payload.country,
            },
            errorMessage: 'Could not get definition details.',
            loadingId: LoadingFlag.YougovDefinitionDetailsGet,
        });

        return result as unknown as Promise<IYougovDefinitionDto>;
    }
}

export default new YougovClient();
