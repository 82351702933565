import { ConvoEndLogicType } from '@/store/enums/convoEndLogicType.enum';
import { ILogicJumpItems } from '@/store/models/logicJump';

export class ConvoEndLogic {
    type: ConvoEndLogicType = ConvoEndLogicType.None;
    content?: ILogicJumpItems;

    setType(type: ConvoEndLogicType) {
        this.type = type;
        delete this.content;
        return this;
    }

    setContent(content: ILogicJumpItems) {
        this.content = content;
        return this;
    }
}
