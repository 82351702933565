<template>
    <MessageWrapper
        class="carousel"
        :avatar-url="showAvatar && avatarUrl"
        :has-left-padding="false"
        :has-right-padding="false"
    >
        <ScrollableMessageWrapper
            class="carousel-messages"
            :items="messages"
            :highlighted-message="highlightedMessage"
        >
            <MessageBubble
                v-for="(item, index) in messages"
                :key="index"
                class="carousel-message"
                crop-image
                :show-arrow="showArrow && index === 0"
                :image-url="item.imageUrl"
                @imageOnClick="imageOnClick($event, item.link)"
            >
                <div class="carousel-message-inner-wrapper">
                    <div class="simple-text" @click="onClick(item)">{{ item.title }}</div>
                    <div v-if="item.subtitle" class="simple-text" @click="onClick(item)">
                        {{ item.subtitle }}
                    </div>
                    <div class="carousel-buttons">
                        <button
                            v-for="(answer, index) in item.answers"
                            :key="index"
                            :ref="`button[${index}]`"
                            class="carousel-button"
                            @click="$emit('answerOnClick', index, answer)"
                        >
                            {{ answer.label }}
                        </button>
                    </div>
                </div>
            </MessageBubble>
        </ScrollableMessageWrapper>
    </MessageWrapper>
</template>

<script>
import messageMixin from '../mixins/message.mixin';
import ScrollableMessageWrapper from './ScrollableMessageWrapper';
import MessageWrapper from './MessageWrapper';
import MessageBubble from './MessageBubble';

export default {
    name: 'Carousel',
    components: {
        ScrollableMessageWrapper,
        MessageWrapper,
        MessageBubble,
    },
    mixins: [messageMixin],
    props: {
        messages: {
            type: Array,
            default: undefined,
        },
        showAvatar: {
            type: Boolean,
            required: true,
        },
        avatarUrl: {
            type: String,
            default: undefined,
        },
        highlightedMessage: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        showArrow() {
            return this.showAvatar && this.avatarUrl ? true : false;
        },
    },
    methods: {
        imageOnClick(event, url) {
            if (url) {
                window.open(url, '_blank');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.carousel-message {
    &:not(:last-child) {
        margin-right: 10px;
    }
}

.carousel-message-inner-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
}

.carousel-buttons {
    min-width: 160px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: flex-end;

    @include media-query(medium) {
        min-width: 126px;
    }
}

.simple-text {
    margin-bottom: 8px;
    font-family: $font-family-default;
    font-size: $font-size-xs;
    font-weight: $font-weight-default;
    line-height: 1.37;
    text-align: left;
    color: $default-text-color;

    @include media-query(medium) {
        margin-bottom: 5px;
        font-size: $font-size-xxs;
    }
}

.carousel-button {
    display: block;
    white-space: nowrap;
    padding: 10px;
    background: $bg-color;
    font-size: $font-size-xs;
    color: $quick-reply-answer-button-text-color;
    text-align: center;
    cursor: pointer;

    @include media-query(medium) {
        padding: 5px;
        font-size: 10px;
    }

    &:not(:last-child) {
        margin-bottom: 8px;

        @include media-query(medium) {
            margin-bottom: 4px;
        }
    }
}
</style>
