import { IConvoCategory, ITag } from '../interfaces';

export class GetConvosRequest {
    channelId?: number;
    channelCode?: string;
    type: string;
    q: string;
    page: number;
    size: number;
    status?: number;
    chatGroup?: number;
    includeGenerated?: boolean;
    includeTopics?: string;
    excludeTopics?: string;
}

export class CreateConvoRequest {
    userId: number;
    channelCode: string;
    type: string;
    name: string;
    description: string;
    isPdl: boolean = false;
    isContentLibrary: boolean = false;
    ygdCountry?: string;
    isGlobal: boolean;
    countries: string[];
    dailyChat: boolean;
    tags?: ITag[];
    topics: ITag[];
    categories: IConvoCategory[];
}

export class UpdateConvoAttributesRequest {
    id: number;
    name: string;
    description?: string;
    image?: string;
    tags?: ITag[];
    topics?: ITag[];
    isGlobal: boolean;
    countries: string[];
    dailyChat: boolean;
}

export class CloneConvoRequest {
    channelCode: string;
}
