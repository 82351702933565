<template>
    <div>
        <div class="status" :class="{ 'status--saved': isSaved, 'status--unsaved': !isSaved }">
            <strong v-if="statusTitleIsVisible" class="status-title">
                <span v-if="isSaved">Saved</span>
                <span v-else>Draft</span>
            </strong>

            <span v-if="isSaved">Changes have been saved.</span>
            <span v-else>You have unsaved changes.</span>
        </div>

        <div v-if="saveTime" class="status-time">
            Last saved on <time>{{ timeText }}</time>
        </div>

        <div v-if="info" class="status-info">{{ info }}</div>
    </div>
</template>

<script>
import { format } from 'date-fns';

export default {
    name: 'SaveStatus',
    props: {
        saveTime: {
            type: Date,
            default: undefined,
        },
        isSaved: {
            type: Boolean,
            default: false,
        },
        info: {
            type: String,
            default: '',
        },
        statusTitleIsVisible: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        timeText() {
            return `${format(this.saveTime, 'dd/MM/yyyy')} at ${format(this.saveTime, 'HH:mm')}`;
        },
    },
};
</script>

<style scoped lang="scss">
.status {
    margin-left: 16px;
    position: relative;
    font-size: 14px;

    &--saved,
    &--unsaved {
        &::before {
            @include center(y);
            content: '';
            display: block;
            position: absolute;
            left: -16px;
            width: 8px;
            height: 8px;
            border-radius: 8px;
        }
    }

    &--saved {
        &::before {
            background-color: $save-saved-status-icon-color;
        }
    }

    &--unsaved {
        &::before {
            background-color: $save-unsaved-status-icon-color;
        }
    }
}

.status-title {
    font-family: $font-family-button;
    text-transform: uppercase;

    &::after {
        position: relative;
        display: inline-block;
        padding-left: 6px;
        padding-right: 6px;
        top: -1px;
        content: '|';
    }
}

.status-time {
    margin-top: 2px;
    font-weight: $font-weight-light;
    font-size: 12px;
    color: $save-time-text-color;
}

.status-info {
    margin-top: 2px;
    font-weight: $font-weight-light;
    font-size: 12px;
    color: $save-time-text-color;
}
</style>
