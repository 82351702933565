import { validate as validateClass, IsNotEmpty } from 'class-validator';
import hash from 'object-hash';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum';
import { AccordionItemBase } from './accordionItemBase';
import { IContentItem, IContentItemConvertible } from './interfaces';
import { AttachmentUrlContentItemFactory } from './factories/attachmentUrlContentItem.factory';
import { AttachmentUrlAccordionItemFactory } from './factories/attachmentUrlAccordianItem.factory';
import { IConvoState } from '../modules/convo-details';
import { IObservableItem } from './interfaces/observableItem.interface';
import truncateStringWithEllipsis from '@/utils/truncateStringWithEllipsis';
import { IValidatable } from './interfaces/validatable.interface';
import { getErrorMessage } from '@/store/services/validationService';

export class AttachmentUrl
    extends AccordionItemBase
    implements IContentItemConvertible, IObservableItem<IConvoState>, IValidatable<IConvoState>
{
    constructor(items = []) {
        super(items);
        this.type = ConvoMessageItemType.AttachmentUrl;
        this.icon = 'image';
        this.isDraggable = true;
        this.isDeletable = true;
        this.url = '';
    }

    @IsNotEmpty({ message: getErrorMessage('emptyAttachment', 'image') })
    url: string;

    setTitle() {
        this.subtitle = truncateStringWithEllipsis(this.url, 50) || '--';
    }

    itemHasChanges(state: IConvoState): boolean {
        const contentItemFactory = new AttachmentUrlContentItemFactory();
        const contentItem = contentItemFactory.make(this, state);

        contentItem.version = hash(contentItem);

        if (this.originalVersion) {
            return contentItem.version !== this.originalVersion;
        }
        return false;
    }

    toContentItem(state: IConvoState): IContentItem {
        const contentItemFactory = new AttachmentUrlContentItemFactory();
        return contentItemFactory.make(this, state);
    }

    toAccordionItem(contentItem: IContentItem, isClone?: boolean): AccordionItemBase {
        const accordionItemFactory = new AttachmentUrlAccordionItemFactory();
        return accordionItemFactory.make(contentItem);
    }

    async validate(state: IConvoState, path: number[]): Promise<any[]> {
        const errors = await validateClass(this);
        const errorMessages: any[] = [];

        if (errors.length > 0) {
            errors.forEach((item) => {
                errorMessages.push({
                    path,
                    errors: { [item.property]: Object.values(item.constraints || {}) },
                });
            });
        }

        return errorMessages;
    }
}
