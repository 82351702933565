<template>
    <div class="page-layout-container">
        <div class="page-layout-main">
            <FullPageLoadingSpinner
                :show="
                    $wait.is(LoadingFlag.MessagesSave) ||
                    $wait.is(LoadingFlag.UpdateConvoAttributes) ||
                    $wait.is(LoadingFlag.ConvoGetOne)
                "
            />

            <template>
                <PageHeading :breadcrumbs="breadcrumbs" title="Edit convo" />

                <div class="message-editor">
                    <ErrorMessage
                        v-if="modelHasErrors && !$wait.is(LoadingFlag.ConvoGetOne)"
                        ref="errorInfo"
                        size="large"
                    >
                        Incomplete fields
                    </ErrorMessage>

                    <ErrorMessage
                        v-if="
                            convoDetailsView.messagesView.errors &&
                            convoDetailsView.messagesView.errors.length > 0
                        "
                        >{{ convoDetailsView.messagesView.errors[0] }}</ErrorMessage
                    >

                    <WarningMessage
                        v-for="(warning, index) of warnings"
                        :key="index"
                        class="warning"
                        :message="warning.message"
                    />

                    <ConvoAttributes
                        :attributes="convoSettings.convoAttributes"
                        :data="convoDetailsView.convo"
                        :tags-data="tagsData"
                        :convo-category-options="availableConvoCategories"
                        :topic-search-result="topicSearchResult"
                        :show-group="true"
                        :show-end-logic="featureConvoEndLogic"
                        :disable-global-convo="isContentLibrary"
                        @onChange="convoAttributeOnChange"
                        @group-input="onTagsInput($event, 'group')"
                        @tag-input="onTagsInput($event, 'convo')"
                        @onSearchTopic="onSearchTopic"
                    />

                    <AnchorLink
                        v-if="isQuiz"
                        :disabled="isNewConvo"
                        class="m-b-4"
                        :has-right-arrow="!isNewConvo"
                        full-width
                        left-icon="award"
                        :to="{
                            name: isNewConvo ? '' : 'results',
                            params: { channelCode, convoId },
                        }"
                        :link-style="LinkStyle.DarkAccordionHeader"
                    >
                        {{
                            isNewConvo
                                ? 'Please save convo first'
                                : `Results (${personalityQuizResultsCount})`
                        }}
                    </AnchorLink>
                    <Accordion
                        :items="convoDetailsView.messagesView.messageItems"
                        :is-draggable="true"
                        :are-message-items-valid="areMessageItemsValid"
                        :current-channel="channelDetails.channel"
                        :current-convo="convoDetailsView.convo"
                        :yougov-definitions="definitions"
                        :convo-categories="availableConvoCategories"
                        :show-correlator="showCorrelator"
                        @openLogicJump="onOpenContentSelector"
                        @headerOnClick="itemHeaderOnClick"
                        @deleteOnClick="deleteOnClick"
                        @cloneOnClick="cloneOnClick"
                        @onDrop="onDrop"
                        @messageTypeOnSelect="messageTypeOnSelect"
                        @propertyOnChange="propertyOnChange"
                        @addItemOnClick="addItemOnClick"
                        @pinItem="onPinItem"
                        @pinAnswer="onPinAnswer"
                        @ignoreQuizQuestion="onIgnoreQuizQuestion"
                        @undoDeletedItemOnClick="undoDeletedItemOnClick"
                        @addAttribute="addAttribute"
                        @removeAttribute="removeAttribute"
                        @openCorrelationContent="onOpenCorrelationContentSeletor"
                        @closeCorrelationContentSeletor="onCloseCorrelationContentSeletor"
                    />

                    <div class="align-center">
                        <TextButton class="m-b-1" icon="plus-2" @click="addMessageOnClick">
                            Add message
                        </TextButton>

                        <TextButton
                            v-if="!isContentLibrary"
                            class="m-b-1"
                            icon="plus-2"
                            @click="showPdlSelector = true"
                        >
                            Add PDL message
                        </TextButton>
                    </div>
                </div>
            </template>
        </div>
        <div class="page-layout-aside">
            <div class="page-layout-aside-fixed-container">
                <ConvoPreview
                    class="convo-preview-component"
                    :convo-id="convoDetailsView.convo.id"
                    :fb-page-id="channelDetails.channel.fbPageId"
                    :messages="convoPreviewMessages"
                    :active-message-index="activeMessageIndex"
                    :show-overlay="showConvoPreviewOverlay"
                    :nav-is-opened="navIsOpened"
                    :is-loading="isLoading"
                    :messages-model-has-changes="messagesModelHasChanges"
                    @overlayOnClose="showConvoPreviewOverlay = false"
                />
                <Save
                    :confirm-button-text="confirmButtonText"
                    :nav-is-opened="navIsOpened"
                    :time="new Date(convoDetailsView.convo.updatedAt)"
                    :is-saved="!hasUnsavedChanges"
                    :info="publishedInfo"
                    @cancelButtonOnClick="backButtonClick($event)"
                    @confirmButtonOnClick="nextButtonClick($event)"
                />
            </div>
        </div>

        <Dialog
            show-confirm-button
            :is-dialog-visible="confirmDialogIsOpen"
            :is-loading="
                $wait.is(LoadingFlag.MessagesSave) ||
                $wait.is(LoadingFlag.UpdateConvoAttributes) ||
                $wait.is(LoadingFlag.ConvoGetOne)
            "
            @onClose="confirmDialogIsOpen = false"
            @onClickOutside="confirmDialogIsOpen = false"
            @closeOnEscapeEvent="confirmDialogIsOpen = false"
            @confirmButtonOnClick="dialogSaveButtonClick($event)"
        >
            <template #header>Some changes have been made to the convo.</template>
            <template #body>Do you want to save these changes?</template>
        </Dialog>
        <Dialog
            class="publish-dialogue"
            show-confirm-button
            :is-dialog-visible="publishDialogIsOpen"
            :is-loading="$wait.is(LoadingFlag.ChannelGet)"
            confirm-button-text="Publish"
            @onClose="publishDialogIsOpen = false"
            @onClickOutside="publishDialogIsOpen = false"
            @closeOnEscapeEvent="publishDialogIsOpen = false"
            @confirmButtonOnClick="dialogPublishButtonClick($event)"
        >
            <template #header>Publish Content Library convo</template>
            <template #body>
                <p v-if="yougovSurveyChannels.length">
                    The following channels are using this welcome survey.
                </p>
                <p>Do you want to proceed?</p>
                <ul v-if="yougovSurveyChannels.length" class="publish-dialogue-list">
                    <li v-for="(channel, index) in yougovSurveyChannels" :key="`sc-${index}`">
                        {{ channel }}
                    </li>
                </ul>
            </template>
        </Dialog>
        <Dialog
            show-confirm-button
            confirm-button-text="Discard changes"
            close-button-text="Continue Editing"
            :is-dialog-visible="leaveDialogIsOpen"
            @onClose="leaveDialogOnConfirm"
            @onClickOutside="leaveDialogOnConfirm"
            @closeOnEscapeEvent="leaveDialogOnConfirm"
            @confirmButtonOnClick="leaveDialogOnClose"
        >
            <template #header>There are unsaved changes made to the convo.</template>
            <template #body>Continue editing to save progress.</template>
        </Dialog>
        <Dialog
            show-confirm-button
            confirm-button-text="Yes, continue"
            :is-dialog-visible="datumCountryDeletionDialogIsOpen"
            @onClose="onDatumCountryDeletionDialogClose"
            @onClickOutside="onDatumCountryDeletionDialogClose"
            @closeOnEscapeEvent="onDatumCountryDeletionDialogClose"
            @confirmButtonOnClick="confirmDatumCountryDeletion"
        >
            <template #header>
                Removing the country will delete the selected Datum questions and answers.
            </template>
            <template #body>Would you like to continue?</template>
        </Dialog>

        <ContentSelector
            v-if="showContentSelector"
            title="Logic Jump"
            list-header-text="Jump to..."
            :value="contentItems"
            :show-content-id="true"
            :require-channel="$options.isChannelRequiredForLogicJump"
            @input="onContentSelectorChange"
            @close="onCloseContentSelector"
        />

        <ContentSelector
            v-if="showPdlSelector && !isContentLibrary"
            title="Select PDL convo"
            :value="pdlSelectorValue"
            validation-content-type="convo"
            :fixed-channel="true"
            :show-content-id="true"
            @selected="loadPdlConvo"
            @close="showPdlSelector = false"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { router } from '../router';
import FullPageLoadingSpinner from '../components/ui/FullPageLoadingSpinner';
import Accordion from '@/components/ui/Accordion';
import TextButton from '@/components/ui/TextButton';
import Link from '@/components/ui/Link';
import Save from '@/components/ui/Save';
import ErrorMessage from '@/components/ui/ErrorMessage';
import ConvoPreview from '@/components/ConvoPreview';
import ConvoAttributes from '@/components/ConvoAttributes';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import convoTypes from '../json/convoTypes';
import Dialog from '@/components/ui/Dialog';
import LeavingDialogMixin from '@/mixins/leavingDialogMixin';
import TagsMixin from '@/mixins/tagsMixin';
import {
    AnswerType,
    ContentType,
    LoadingFlag,
    ConvoTypes,
    LinkStyle,
    ButtonStyle,
    TagType,
    ConvoMessageItemType,
} from '@/store/enums';
import PageHeading from '@/components/ui/PageHeading';
import ContentSelector from '@/components/ContentSelector/ContentSelector';
import { ContentOption } from '@/store/models/contentOption.ts';
import { Routes } from '@/enums/index.ts';
import WarningMessage from '@/components/forms/partials/WarningMessage';
import config from '@/config';
import { Observable } from '@/services/observable';
import { TenantClient } from '@/api/tenant';
import { TopicClient } from '@/api/topic';

const convoAttributesObservable = new Observable();
const messagePropertiesObservable = new Observable();
const itemHeaderClickObservable = new Observable();
const deleteContentItemObservable = new Observable();

export default {
    name: 'Messages',
    components: {
        PageHeading,
        Accordion,
        TextButton,
        AnchorLink: Link,
        Save,
        FullPageLoadingSpinner,
        ConvoPreview,
        Dialog,
        ErrorMessage,
        ConvoAttributes,
        ContentSelector,
        WarningMessage,
    },
    mixins: [LeavingDialogMixin, TagsMixin],
    props: {
        navIsOpened: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            LoadingFlag,
            ButtonStyle,
            LinkStyle,
            channelCode: this.$route.params.channelCode,
            convoId: this.$route.params.convoId,
            showConvoPreviewOverlay: false,
            confirmDialogIsOpen: false,
            publishDialogIsOpen: false,
            breadcrumbs: [],
            showContentSelector: false,
            showCorrelator: false,
            showPdlSelector: false,
            datumCountryDeletionDialogIsOpen: false,
            prevCountries: '',
            topicSearchResult: [],
        };
    },
    computed: {
        ...mapGetters('main', {
            isLoading: 'isLoading',
        }),
        ...mapGetters('channel', {
            channelDetails: 'channelDetails',
        }),
        ...mapGetters('convoDetails', {
            activeMessagePath: 'activeMessagePath',
            messageByPath: 'messageByPath',
            convoDetailsView: 'convoDetailsView',
            isConvoValid: 'isConvoValid',
            areMessageItemsValid: 'areMessageItemsValid',
            areResultItemsValid: 'areResultItemsValid',
            convoModelHasChanges: 'convoModelHasChanges',
            messagesModelHasChanges: 'messagesModelHasChanges',
            resultsModelHasChanges: 'resultsModelHasChanges',
            convoPreviewMessages: 'convoPreviewMessages',
            availableTags: 'availableTags',
            availableConvoCategories: 'availableConvoCategories',
            selectedTags: 'selectedTags',
            isNewConvo: 'isNewConvo',
            yougovSurveyChannels: 'yougovSurveyChannels',
        }),
        ...mapGetters('logicJump', {
            contentItems: 'items',
        }),
        ...mapState('yougov', {
            definitions: 'definitions',
        }),
        tagsData() {
            return {
                selected: this.selectedTags(TagType.convo),
                available: this.availableTags(TagType.convo),
                selectedGroup: this.selectedTags(TagType.group),
                availableGroup: this.availableTags(TagType.group),
            };
        },
        confirmButtonText() {
            return this.hasUnsavedChanges ? 'Save changes' : 'Publish';
        },
        modelHasErrors() {
            let areResultsValid = true;
            if (this.isQuiz && !this.isNewConvo) {
                areResultsValid = this.areResultItemsValid;
            }
            return !this.areMessageItemsValid || !areResultsValid || !this.isConvoValid;
        },
        isQuiz() {
            return (
                this.convoDetailsView.convo.type === ConvoTypes.PersonalityQuiz ||
                this.convoDetailsView.convo.type === ConvoTypes.TriviaQuiz
            );
        },
        convoSettings() {
            return convoTypes.find((t) => t.name === this.convoDetailsView.convo.type) || {};
        },
        convoType() {
            return this.convoSettings.title || 'Convo';
        },
        activeMessage() {
            return this.convoDetailsView.messagesView.messageItems.find((item) => item.isOpen);
        },
        activeMessageIndex() {
            return this.activeMessage?.sort;
        },
        hasUnsavedChanges() {
            return (
                this.messagesModelHasChanges ||
                this.resultsModelHasChanges ||
                this.convoModelHasChanges
            );
        },
        personalityQuizResultsCount() {
            return this.convoDetailsView.resultsView.resultItems.filter((item) => !item.isDeleted)
                .length;
        },
        warnings() {
            const warnings = [];

            if (
                this.convoDetailsView.convo.lastPublishedVersion !==
                this.convoDetailsView.convo.version
            ) {
                warnings.push({
                    key: 'publishedInfo',
                    message: 'This version has not been published yet.',
                });
            }

            if (this.convoDetailsView.convo.type === ConvoTypes.YougovWelcomeChat) {
                const used = this.convoDetailsView.messagesView.messageItems.reduce(
                    (prev, curr) => {
                        if (curr.datumDefinition && ![...prev].includes(curr.datumDefinition)) {
                            return [...prev, curr.datumDefinition];
                        }
                        return [...prev];
                    },
                    [],
                );

                if (used.length < this.definitions.length) {
                    warnings.push({
                        message: `Some Datum definitons are not set: ${used.length}/${this.definitions.length}.`,
                    });
                }

                let buttonsWithOpenSurveyButton = [];
                const buttons = this.convoDetailsView.messagesView.messageItems.filter(
                    (o) => o.type === ConvoMessageItemType.Buttons,
                );

                if (buttons.length) {
                    buttonsWithOpenSurveyButton = buttons.filter((o) =>
                        o.items[0].items.some(
                            (answer) => answer.answerType.name === AnswerType.YougovWelcomeSurvey,
                        ),
                    );
                }

                if (!buttonsWithOpenSurveyButton.length) {
                    warnings.push({
                        message: 'There is no message to open YouGov Welcome Survey external link',
                    });
                }
            }

            return warnings;
        },
        publishedInfo() {
            const warning = this.warnings.find((o) => o.key === 'publishedInfo');
            if (warning) {
                return warning.message;
            }
            return undefined;
        },
        pdlSelectorValue() {
            const channel = new ContentOption({
                type: ContentType.Channel,
                text: 'PDL',
                id: -1,
                convoType: ConvoTypes.PDLConvo,
            });
            return { channel };
        },
        featureConvoEndLogic() {
            return config.featureToggle.convoEndLogic;
        },
        isContentLibrary() {
            return this.$route.matched.some(({ name }) => name === Routes.ContentLibraryMessages);
        },
    },
    async created() {
        if (!this.isNewConvo) {
            this.resetConvoDetailsState();
        }

        const options = {
            id: this.$route.params.convoId,
        };

        if (!this.isContentLibrary) {
            options.channelCode = this.$route.params.channelCode;
            await this.setChannelDetails();
        }

        if (this.$route.params.convoId) {
            await this.loadConvo(options);
        }

        if (!this.convoDetailsView.convo.id) {
            this.convoDetailsView.convo.topics = this.channelDetails.channel.topics;
        }

        this.$options.isChannelRequiredForLogicJump = true;

        if (this.convoDetailsView.convo.type === ConvoTypes.YougovWelcomeSurvey) {
            this.loadYougovSurveyChannels(options);
        } else if (this.convoDetailsView.convo.type === ConvoTypes.YougovWelcomeChat) {
            this.initYougovDefinitons();
            this.$options.isChannelRequiredForLogicJump = false;
        }

        this.setTags();
        this.setConvoCategories();
        this.setBreadcrumbs();

        convoAttributesObservable.subscribe(this.setConvoProperty);
    },
    destroyed() {
        convoAttributesObservable.unsubscribe(this.setConvoProperty);
        convoAttributesObservable.unsubscribe(this.yougovDefinitonsHandleConvoAttributeChange);
        messagePropertiesObservable.unsubscribe(this.yougovDefinitonsHandleMessagePropertyChange);
        itemHeaderClickObservable.unsubscribe(this.yougovDefinitonsHandleItemHeaderClick);
        deleteContentItemObservable.unsubscribe(this.yougovDefinitonsHandleContemItemDelete);
    },
    methods: {
        ...mapActions('convoDetails', {
            clearActiveMessagePath: 'clearActiveMessagePath',
            setActiveMessagePath: 'setActiveMessagePath',
            toggleMessageItem: 'toggleMessageItem',
            deleteMessage: 'deleteMessage',
            cloneMessage: 'cloneMessage',
            setConvoCategories: 'setConvoCategories',
            setMessageType: 'setMessageType',
            setMessageItemsOnReorder: 'setMessageItemsOnReorder',
            setMessageItem: 'setMessageItem',
            validateMessages: 'validateMessages',
            validateDatumResponses: 'validateDatumResponses',
            validateConvo: 'validateConvo',
            validateResult: 'validateResult',
            addMessageItem: 'addMessageItem',
            saveConvoMessages: 'saveConvoMessages',
            saveConvo: 'saveConvo',
            loadConvo: 'loadConvo',
            loadPdlConvo: 'loadPdlConvo',
            setConvo: 'setConvo',
            pinMessageItem: 'pinMessageItem',
            pinMessageAnswer: 'pinMessageAnswer',
            ignoreQuizQuestion: 'ignoreQuizQuestion',
            undoDeletedMessageItem: 'undoDeletedMessageItem',
            resetConvoDetailsState: 'resetConvoDetailsState',
            validateMessagesView: 'validateMessagesView',
            setConvoProperty: 'setConvoProperty',
            addAttribute: 'addAttribute',
            removeAttribute: 'removeAttribute',
            loadYougovSurveyChannels: 'loadYougovSurveyChannels',
            publishConvo: 'publish',
            clearDatumData: 'clearDatumData',
        }),
        ...mapActions('channel', {
            setChannelDetails: 'setChannelDetails',
        }),
        ...mapActions('logicJump', {
            clearLogicJump: 'clear',
            getLogicJumpData: 'getData',
        }),
        ...mapActions('yougov', {
            yougovSetDefinitions: 'setDefinitions',
            getDefinitionDetails: 'getDefinitionDetails',
        }),
        async onSearchTopic(query) {
            const topics = await new TopicClient().fetch({
                namespace: 'global',
                q: query,
                size: 15,
            });
            this.topicSearchResult = topics?.items || [];
        },
        itemHeaderOnClick(event, path) {
            this.toggleMessageItem(path);
            itemHeaderClickObservable.notify(this.activeMessage);
        },
        async deleteOnClick(event, path, parentPath) {
            deleteContentItemObservable.notify({ event, path, parentPath });
            await this.deleteMessage({ path, parentPath });
            await this.validateMessages();
        },
        async cloneOnClick(event, path, parentPath) {
            await this.cloneMessage({ path, parentPath });
            await this.validateMessages();
        },
        async onOpenContentSelector(path) {
            const item = this.messageByPath(path);
            if (!item) {
                return;
            }
            this.setActiveMessagePath(path);
            this.clearLogicJump();
            if (item.logicJump.items && Object.keys(item.logicJump.items).length) {
                await this.getLogicJumpData({
                    targetChannel: item.logicJump.channel ? item.logicJump.channel.code : '',
                    targetConvo: item.logicJump.convo ? item.logicJump.convo.id : 0,
                    targetContentItem: item.logicJump.message ? item.logicJump.message.id : 0,
                });
            }
            this.showContentSelector = true;
        },
        async onOpenCorrelationContentSeletor(path) {
            const item = this.messageByPath(path);

            if (!item) {
                return;
            }

            if (!item.correlation?.items || item.correlation?.items.channel.code) {
                this.showCorrelator = true;
                return;
            }

            const { channelCode } = await TenantClient.getLogicJumpData({
                targetContentItem: item.correlation.items.message.id,
            });

            item.correlation.items.channel.code = channelCode;

            this.propertyOnChange({ path, item });
            this.showCorrelator = true;
        },
        async onCloseCorrelationContentSeletor() {
            this.showCorrelator = false;
        },
        onContentSelectorChange(value) {
            if (!this.activeMessagePath) {
                return;
            }
            const item = this.messageByPath(this.activeMessagePath);
            item.logicJump = { ...value, items: value };
            this.propertyOnChange({ path: this.activeMessagePath, item });
        },
        onCloseContentSelector() {
            this.showContentSelector = false;
            this.clearActiveMessagePath();
        },
        onDrop(path, items) {
            this.setMessageItemsOnReorder({ path, items });
        },
        messageTypeOnSelect(event, path, index) {
            this.setMessageType({ path, messageTypeIndex: index });
        },
        backButtonClick: function (event) {
            let route = Routes.ConvoList;
            if (this.isContentLibrary) {
                route = Routes.ContentLibraryList;
            }
            this.$router.push({ name: route });
        },
        nextButtonClick: async function (event) {
            await this.validateConvo();
            await this.validateMessages();

            if (this.convoDetailsView.convo.type === ConvoTypes.YougovWelcomeChat) {
                await this.validateDatumResponses();
            }

            if (this.isQuiz && !this.isNewConvo) {
                await this.validateResult();
            }

            if (this.modelHasErrors) {
                this.$refs.errorInfo.animate();
                return;
            }
            if (this.hasUnsavedChanges) {
                this.confirmDialogIsOpen = true;
            } else {
                if (this.isContentLibrary) {
                    this.publishDialogIsOpen = true;
                    return;
                }

                router.push({
                    name: Routes.Publishing,
                    params: {
                        channelCode: router.currentRoute.params.channelCode,
                        convoId: this.convoDetailsView.convo.id,
                    },
                });
            }
        },
        async dialogSaveButtonClick(event) {
            if (this.isNewConvo) {
                await this.saveConvo();
                this.$router.push({
                    name: 'messages',
                    params: {
                        channelCode: this.$route.params.channelCode,
                        convoId: this.convoDetailsView.convo.id,
                    },
                });
                this.convoId = this.convoDetailsView.convo.id;
                this.setBreadcrumbs();
            }
            await this.setSelectedTags({
                tags: this.selectedTags([TagType.convo, TagType.group]),
            });
            await this.saveConvoMessages();
            this.confirmDialogIsOpen = false;
        },
        async dialogPublishButtonClick() {
            await this.publishConvo({ publish: true, isContentLibrary: true });
            this.publishDialogIsOpen = false;

            if (this.isContentLibrary) {
                this.$router.push({ name: Routes.ContentLibraryList });
            }
        },
        propertyOnChange(payload) {
            messagePropertiesObservable.notify(payload);
            this.setMessageItem(payload);
        },
        async addMessageOnClick(event) {
            await this.validateMessages();

            this.$nextTick(() => {
                this.addItemOnClick(event, null, ConvoMessageItemType.SelectMessageType);
            });

            if (!this.areMessageItemsValid && this.$refs.errorInfo) {
                this.$refs.errorInfo.animate();
            }
        },
        async addItemOnClick(event, path, type) {
            await this.addMessageItem({ path, type });
            await this.validateMessages();
            this.validateMessagesView();
        },
        onPinItem(path) {
            this.pinMessageItem(path);
        },
        onPinAnswer(path, isPinned) {
            this.pinMessageAnswer({ path, isPinned });
        },
        onIgnoreQuizQuestion(path, isToggled) {
            // TODO: no, this should be passed as a property from quick reply to quick reply answer
            this.ignoreQuizQuestion({ path, isToggled });
            this.$nextTick(this.validateMessages);
        },
        undoDeletedItemOnClick(event, path) {
            this.undoDeletedMessageItem(path);
        },
        setBreadcrumbs() {
            if (this.isContentLibrary) {
                this.breadcrumbs = [
                    new Breadcrumb('Content Library convos', { name: Routes.ContentLibraryList }),
                    new Breadcrumb(`${this.convoDetailsView.convo.name} ( ${this.convoType} )`),
                ];
                return;
            }
            const breadcrumbs = [];

            const channelsLink = { name: 'all-channels' };
            breadcrumbs.push(new Breadcrumb('channels', channelsLink));

            let convoLink;
            if (this.channelDetails.channel.code) {
                convoLink = {
                    name: 'convo-list',
                    params: { channelCode: this.channelDetails.channel.code },
                };
            }
            breadcrumbs.push(new Breadcrumb(this.channelDetails.channel.name, convoLink));
            breadcrumbs.push(
                new Breadcrumb(
                    this.convoDetailsView.convo.id === 0
                        ? `New ${this.convoType} CONVO`
                        : `${this.convoType} CONVO: ${this.convoDetailsView.convo.name}`,
                ),
            );

            this.breadcrumbs = breadcrumbs;
        },
        convoAttributeOnChange(data) {
            convoAttributesObservable.notify(data);
        },
        initYougovDefinitons() {
            this.yougovSetDefinitions(
                this.convoDetailsView.convo.countries.length
                    ? this.convoDetailsView.convo.countries[0]
                    : null,
            );

            convoAttributesObservable.subscribe(this.yougovDefinitonsHandleConvoAttributeChange);
            messagePropertiesObservable.subscribe(this.yougovDefinitonsHandleMessagePropertyChange);
            itemHeaderClickObservable.subscribe(this.yougovDefinitonsHandleItemHeaderClick);
            deleteContentItemObservable.subscribe(this.yougovDefinitonsHandleContemItemDelete);
        },
        yougovDefinitonsHandleConvoAttributeChange(data) {
            if (data.key === 'countries') {
                this.prevCountries = this.convoDetailsView.convo.countries;

                if (!data.value.length) {
                    this.datumCountryDeletionDialogIsOpen = true;
                } else {
                    this.yougovSetDefinitions(data.value[0]);
                }
            }
        },
        yougovDefinitonsHandleMessagePropertyChange({ item, key }) {
            if (key === 'datumDefinition') {
                this.getDefinitionDetails({
                    definition: item.datumDefinition,
                    country: this.convoDetailsView.convo.countries.length
                        ? this.convoDetailsView.convo.countries[0]
                        : null,
                });
            }
        },
        yougovDefinitonsHandleItemHeaderClick(activeMessage) {
            if (activeMessage?.datumDefinition) {
                this.getDefinitionDetails({
                    definition: activeMessage.datumDefinition,
                    country: this.convoDetailsView.convo.countries.length
                        ? this.convoDetailsView.convo.countries[0]
                        : null,
                });
            }
        },
        yougovDefinitonsHandleContemItemDelete({ path }) {
            const contentItem = this.messageByPath(path);
            this.getDefinitionDetails({
                definition: contentItem.datumDefinition,
                country: this.convoDetailsView.convo.countries.length
                    ? this.convoDetailsView.convo.countries[0]
                    : null,
            });
        },
        confirmDatumCountryDeletion() {
            this.clearDatumData();
            this.yougovSetDefinitions(null);
            this.datumCountryDeletionDialogIsOpen = false;
        },
        onDatumCountryDeletionDialogClose() {
            this.setConvoProperty({ key: 'countries', value: this.prevCountries });
            this.datumCountryDeletionDialogIsOpen = false;
        },
    },
    async beforeRouteLeave(to, from, next) {
        if (!this.$route.meta.group || to.meta.group !== this.$route.meta.group) {
            this.resetConvoDetailsState();
        }

        next();
    },
};
</script>

<style lang="scss" scoped>
.dialog-header p {
    font-weight: 300;
    font-size: 17px;
}

.page-layout-aside-fixed-container {
    @include center(y);
}

.message-editor {
    padding: 0 $message-editor-right-padding 25px $message-editor-left-padding;
}
.warning {
    font-size: 16px;
    /deep/ .icon {
        font-size: 14px;
    }
}
.publish-dialogue {
    &-alert {
        color: $error-color;
    }
    &-list {
        list-style: disc;
        margin: 20px 40px;
        max-height: 100px;
        overflow: auto;
        list-style-position: inside;
    }
}
</style>
