import { ANY } from '@/constants';
import { MessengerValidationTypes } from '@/enums';
import { PostValidationType } from '@/store/enums';

export default [
    {
        code: MessengerValidationTypes.None,
        label: 'None',
        countries: [ANY],
        convoTypes: [ANY],
    },
    {
        code: MessengerValidationTypes.USZipcode,
        label: 'US Zip code',
        regex: {
            base: '^((9(?:9(?:9(?:50|[0-4]\\d)|[0-8]\\d{2})|[0-8]\\d{3})|[1-8]\\d{4})|(?:00(?:5(?:0(?:[2-9])|[1-9][0-9])|(?:[6-9][0-9][0-9])))|(?:0(?:[1-9](\\d{3}))))$',
        },
        countries: [ANY],
        convoTypes: [ANY],
    },
    {
        code: MessengerValidationTypes.USState,
        label: 'US State',
        regex: {
            base: '^(?:Ala(?:(?:bam|sk)a)|American Samoa|Arizona|Arkansas|(?:^(?!Baja )California)|Colorado|Connecticut|Delaware|District of Columbia|Florida|Georgia|Hawaii|Idaho|Illinois|Indiana|Iowa|Kansas|Kentucky|Louisiana|Maine|Maryland|Massachusetts|Michigan|Minnesota|Miss(?:(?:issipp|our)i)|Montana|Nebraska|Nevada|New (?:Hampshire|Jersey|Mexico|York)|North (?:(?:Carolin|Dakot)a)|Ohio|Oklahoma|Oregon|Pennsylvania|Rhode Island|South (?:(?:Carolin|Dakot)a)|Tennessee|Texas|Utah|Vermont|Virginia|Washington|West Virginia|Wisconsin|Wyoming|(A[KLRSZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY]))$',
            modifiers: ['i'],
        },
        countries: ['US'],
        convoTypes: ['yougov_welcome_chat'],
        postValidation: PostValidationType.usStateMapping,
    },
    {
        code: MessengerValidationTypes.CharacterLimitMinimum25,
        label: 'Minimum 25 characters',
        regex: {
            base: '^.{25,}$',
        },
        countries: ['US', 'GB'],
        convoTypes: ['yougov_welcome_chat'],
    },
    {
        code: MessengerValidationTypes.UKPostcode,
        label: 'UK Postcode',
        regex: {
            base: '^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2})$',
            modifiers: ['i'],
        },
        countries: [ANY],
        convoTypes: [ANY],
        postValidation: PostValidationType.ukPostcodeFormatting,
    },
    {
        code: MessengerValidationTypes.EmailAddress,
        label: 'Email address',
        regex: {
            base: '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-z\\-0-9]+\\.)+[a-z]{2,}))$',
            modifiers: ['i'],
        },
        countries: [ANY],
        convoTypes: [ANY],
    },
];
