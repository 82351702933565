<template>
    <div>
        <div v-if="data.gif" class="m-b-2">
            <img :src="data.gif.images.original.url" class="gif-image" alt="gif-image" />
        </div>

        <ErrorMessage v-if="errorMessage">{{ errorMessage }}</ErrorMessage>
        <TextButton v-if="data.gif" icon="cross" @click="onClear()"> Clear Gif </TextButton>
        <TextButton v-else icon="plus-2" @click="showGiphySelector = true"> Add Gif </TextButton>

        <GifSelector
            :show="showGiphySelector"
            @close="showGiphySelector = false"
            @input="onInput('gif', $event)"
        />
    </div>
</template>

<script>
import TextButton from '@/components/ui/TextButton';
import ErrorMessage from '@/components/ui/ErrorMessage';
import itemFormMixin from '@/mixins/itemFormMixin';

export default {
    name: 'Gif',
    components: {
        GifSelector: () => import('@/components/GifSelector'),
        TextButton,
        ErrorMessage,
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            showGiphySelector: false,
        };
    },
    computed: {
        errorMessage() {
            if (this.data.errors && this.data.errors.gif && this.data.errors.gif.length > 0) {
                return this.data.errors.gif[0];
            }
            return null;
        },
    },
    methods: {
        onClear() {
            this.onInput('gif', undefined);
            this.$forceUpdate();
        },
    },
};
</script>
<style lang="scss" scoped>
.gif-image {
    max-height: 300px;
}
</style>
