import { getErrorMessage } from '@/store/services/validationService';
import { IsNotEmpty, validate as validator } from 'class-validator';
import { IValidatable } from '../interfaces/validatable.interface';

export class ContentDiscoveryMenu implements IValidatable<ContentDiscoveryMenu> {
    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Name') })
    name: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Statement') })
    statement: string;

    id: number;
    default: boolean;
    originalVersion: string;

    constructor() {
        this.name = '';
        this.id = 0;
        this.statement = '';
        this.default = false;
    }

    async validate(): Promise<any> {
        const errors = await validator(this);
        return errors.reduce((acc, cur) => {
            acc[cur.property] = Object.values(cur.constraints || {});
            return acc;
        }, <any>{});
    }
}
