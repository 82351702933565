<template>
    <div class="carousel">
        <ErrorMessage
            v-if="data.errors.hasCorrenctAnswer && data.errors.hasCorrenctAnswer.length > 0"
            >{{ data.errors.hasCorrenctAnswer[0] }}</ErrorMessage
        >

        <EditorInput
            v-if="$options.showStatement"
            :id="generateId('statement', path)"
            class="m-b-2"
            label="Message"
            show-remaining-char
            :content="data.statement"
            :disabled="isDisabled"
            :errors="data.errors.plainText"
            :max="640"
            :menu-items="[
                $options.EditorInputMenuItem.Bold,
                $options.EditorInputMenuItem.Italic,
                $options.EditorInputMenuItem.Strike,
                $options.EditorInputMenuItem.Underline,
            ]"
            @update="onInput('statement', $event)"
            @plainTextChange="onInput('plainText', $event)"
        />

        <Toggle
            v-if="tiles.length > 1"
            :id="generateId('are-tiles-shuffled', path)"
            class="m-b-1"
            label="Shuffle tiles?"
            :value="data.areTilesShuffled"
            @input="(value) => onInput('areTilesShuffled', value)"
            @changed="onChanged"
        />

        <QuizOptions
            v-if="isQuiz"
            class="m-b-2"
            :ignore-quiz-question="data.ignoreQuizQuestion"
            :show-quiz-results="data.showQuizResults"
            :disabled="isDisabled"
            :path="path"
            @change="onChanged"
            @input-show-results="onShowQuizResultsInput"
            @input-ignore-question="onIgnoreQuestionInput"
        />

        <Tabs
            :is-draggable="isMediumScreen"
            :show-add-button="tiles.length < data.maxItems"
            :active-tab="activeTab"
            add-button-text="<span>Add <span class='hide-for-small-only'>Tile</span> +</span>"
            @addButtonOnClick="addAnswerOnClick"
            @onChange="tabsOnChange"
            @onDragEnd="tabsOnDragEnd"
        >
            <Tab
                v-for="(item, index) in tiles"
                :key="index"
                :name="(index + 1).toString()"
                :is-pinned="item.isPinned"
            >
                <TabActions
                    :index="index"
                    :tabs-length="tiles.length"
                    :is-pinned="item.isPinned"
                    :next-is-pinned="nextIsPinned(index)"
                    @moveTab="moveTileOnClick"
                    @pin="pinOnClick"
                    @delete="deleteTile($event, [...path, 0, index], path)"
                />
                <CarouselTile
                    :data="item"
                    :path="[...path, 0, index]"
                    :index="index"
                    :marker="index.toString()"
                    :are-message-items-valid="areMessageItemsValid"
                    :ignore-quiz-question="data.ignoreQuizQuestion"
                    :current-channel="currentChannel"
                    :current-convo="currentConvo"
                    @openLogicJump="$emit('openLogicJump', $event)"
                    @headerOnClick="(event, path) => $emit('headerOnClick', event, path)"
                    @onExpand="(path) => $emit('onExpand', path)"
                    @onExpanded="(path) => $emit('onExpanded', path)"
                    @onContract="(path) => $emit('onContract', path)"
                    @onContracted="(path) => $emit('onContracted', path)"
                    @deleteOnClick="
                        (event, path, parentPath) => $emit('deleteOnClick', event, path, parentPath)
                    "
                    @onDrop="(path, value) => $emit('onDrop', path, value)"
                    @messageTypeOnSelect="
                        (event, path, index) => $emit('messageTypeOnSelect', event, path, index)
                    "
                    @propertyOnChange="$emit('propertyOnChange', $event)"
                    @addItemOnClick="
                        (event, path, type) => $emit('addItemOnClick', event, path, type)
                    "
                    @addAttribute="(payload) => $emit('addAttribute', payload)"
                    @removeAttribute="(payload) => $emit('removeAttribute', payload)"
                />
            </Tab>
        </Tabs>
        <Accordion
            v-if="data.items[1].items && !data.showQuizResults"
            :items="data.items[1].items"
            :path="[...path, 1]"
            :is-draggable="false"
            @headerOnClick="(event, path) => $emit('headerOnClick', event, path)"
            @onExpand="(path) => $emit('onExpand', path)"
            @onExpanded="(path) => $emit('onExpanded', path)"
            @onContract="(path) => $emit('onContract', path)"
            @onContracted="(path) => $emit('onContracted', path)"
            @deleteOnClick="
                (event, path, parentPath) => $emit('deleteOnClick', event, path, parentPath)
            "
            @onDrop="(path, value) => $emit('onDrop', path, value)"
            @messageTypeOnSelect="
                (event, path, index) => $emit('messageTypeOnSelect', event, path, index)
            "
            @propertyOnChange="$emit('propertyOnChange', $event)"
        />

        <div class="result-options-container">
            <Select
                label="Result Options"
                class="p-r-1 result-options"
                placeholder="Select results display option"
                :options="resultOptions"
                :value="getResultOption"
                :disabled="isDisabled"
                @input="handleResultOptions"
            />

            <Toggle
                v-if="showResponseCountToggle"
                :id="generateId('show-poll-count', path)"
                v-model="data.resultOptions.showResponseCount"
                :name="generateId('show-poll-count', path)"
                class="show-poll-count"
                label="Show Response Count"
            />
        </div>
    </div>
</template>

<script>
// TODO: use tabset component
import { Toggle, EditorInput, Select } from '@/components/forms';
import Tabs from '@/components/ui/Tabs';
import Tab from '@/components/ui/Tab';
import TabActions from '@/components/ui/TabActions';
import ErrorMessage from '@/components/ui/ErrorMessage';
import CarouselTile from '@/components/CarouselTile';
import itemFormMixin from '@/mixins/itemFormMixin';
import resultOptionsMixin from '@/mixins/resultOptionsMixin';
import QuizOptionsMixin from '@/mixins/QuizOptionsMixin';
import { ConvoMessageItemType, EditorInputMenuItem } from '@/store/enums';
import cssVariables from '@styles/abstracts/_variables.layout.scss';
import QuizOptions from '@/components/QuizOptions';
import config from '@/config';

let mediaQueryList;

export default {
    name: 'Carousel',
    components: {
        EditorInput,
        Toggle,
        Tabs,
        Tab,
        TabActions,
        CarouselTile,
        ErrorMessage,
        Select,
        Accordion: () => import('@/components/ui/Accordion'),
        QuizOptions,
    },
    mixins: [itemFormMixin, resultOptionsMixin, QuizOptionsMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        areMessageItemsValid: {
            type: Boolean,
            default: true,
        },
        currentChannel: {
            type: Object,
            default: undefined,
        },
        currentConvo: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            isMediumScreen: false,
        };
    },
    computed: {
        activeTab() {
            const activeTabIndex = this.tiles.findIndex((item) => item.isOpen);
            return activeTabIndex > -1 ? activeTabIndex : undefined;
        },
        tiles() {
            return this.data.items[0].items;
        },
    },
    created() {
        const medium = cssVariables['media-queries-medium'].slice(1, -1);
        mediaQueryList = matchMedia(medium);

        if (mediaQueryList.matches) {
            this.isMediumScreen = true;
        }

        mediaQueryList.addListener(this.setIsMediumScreen);
        this.$options.EditorInputMenuItem = EditorInputMenuItem;
        this.$options.showStatement = config.featureToggle.carouselStatement;
    },
    destroyed() {
        mediaQueryList.removeListener(this.setIsMediumScreen);
    },
    methods: {
        async addAnswerOnClick(event) {
            this.$emit('addItemOnClick', event, this.path, ConvoMessageItemType.CarouselTile);
        },
        tabsOnChange(value) {
            if (value !== this.activeTab) {
                this.$emit('headerOnClick', {}, [...this.path, 0, value]);
            }
        },
        tabsOnDragEnd(order) {
            const orderedTiles = [];
            for (let index in order) {
                orderedTiles.push(this.tiles[order[index]]);
            }

            this.data.items[0].items = orderedTiles;
            this.$emit('propertyOnChange', { path: this.path, item: this.data });
        },
        pinOnClick(index) {
            this.$emit('pinItem', [...this.path, 0, index]);
        },
        moveTileOnClick(direction, index) {
            let tiles = this.tiles.slice();
            let swapItemIndex;

            switch (direction) {
                case 'left':
                    swapItemIndex = index - 1;
                    break;
                case 'right':
                    swapItemIndex = index + 1;
                    break;
            }

            [tiles[index], tiles[swapItemIndex]] = [tiles[swapItemIndex], tiles[index]];
            this.data.items[0].items = tiles;
            this.$emit('propertyOnChange', { path: this.path, item: this.data });
        },
        nextIsPinned(index) {
            const tile = this.tiles[index + 1];

            return tile && tile.isPinned;
        },
        setIsMediumScreen(event) {
            if (event.matches) {
                this.isMediumScreen = true;
            } else {
                this.isMediumScreen = false;
            }
        },
        deleteTile(event, path, parentPath) {
            if (confirm('Are you sure you want to delete this tile?')) {
                this.$emit('deleteOnClick', event, path, parentPath);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.answers-title {
    margin-bottom: 10px;
    font-weight: $font-weight-light;
}

.buttons-statement {
    margin-bottom: 10px;
}

.toggle {
    @include media-query(medium) {
        display: flex;
    }
}

.tabs {
    margin-bottom: 15px;
}

.carousel {
    .error {
        font-size: 12px;
        font-weight: $font-weight-light;
        color: $error-color;
        font-style: italic;
    }
}

.result-options-container {
    display: flex;
}

.result-options {
    order: 0;
    width: 60%;
    margin-top: 15px;
}

.show-poll-count {
    order: 1;
    width: 40%;
    margin-top: 25px;
    padding: 0 10px;
}
</style>
