import { ConvoMessageItemType, ConvoTypes } from '../enums';

interface IButtonsAnswerType {
    name: string;
    title: string;
    linkRequired: boolean;
    canBeMultipleChoice: boolean;
    excludeMessageItemTypes?: ConvoMessageItemType[];
    excludeConvoTypes?: ConvoTypes[];
}

export class ButtonsAnswerType {
    name: string;
    title: string;
    linkRequired: boolean;
    canBeMultipleChoice: Boolean;
    excludeMessageItemTypes: ConvoMessageItemType[];
    excludeConvoTypes: ConvoTypes[];

    constructor({
        name,
        title,
        linkRequired,
        canBeMultipleChoice,
        excludeMessageItemTypes,
        excludeConvoTypes,
    }: IButtonsAnswerType) {
        this.name = name;
        this.title = title;
        this.linkRequired = linkRequired;
        this.canBeMultipleChoice = canBeMultipleChoice;
        this.excludeMessageItemTypes = excludeMessageItemTypes || [];
        this.excludeConvoTypes = excludeConvoTypes || [];
    }
}
