<template>
    <div class="panel-wrapper" :class="{ 'has-m-b': hasMarginBottom }">
        <div class="panel">
            <div class="panel-header">
                <slot name="header" />
            </div>
            <div class="panel-body">
                <slot />
            </div>
        </div>
        <div class="panel-buttons">
            <button v-if="isDraggable" class="drag-and-drop-handle">
                <Icon name="drag-handle" />
            </button>
            <button v-if="isDeletable" class="delete" @click="$emit('deleteOnClick', $event)">
                <Icon name="cross" />
            </button>
        </div>
    </div>
</template>

<script>
// TODO: move this file out from this folder
import Icon from '@/components/ui/Icon';

export default {
    name: 'Panel',
    components: {
        Icon,
    },
    props: {
        isDraggable: {
            type: Boolean,
            required: true,
        },
        isDeletable: {
            type: Boolean,
            required: true,
        },
        icon: {
            type: String,
            default: undefined,
        },
        hasMarginBottom: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.panel-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &.has-m-b {
        margin-bottom: 26px;
    }
}

.panel {
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
    padding: 10px 13px;
    border: 1px solid $panel-border-color;
}

.panel-buttons {
    @include media-query(medium) {
        display: flex;
    }
}

.panel-header {
    position: relative;
    padding-bottom: 5px;
    font-size: 14px;
    color: $text-highlight-color;
}

.delete {
    display: flex;
    order: 1;
    align-items: center;
    height: 40px;
    margin-left: 10px;
    cursor: pointer;

    .icon {
        fill: $container-default-main-color;
        font-size: 13px;
    }
}

.drag-and-drop-handle {
    display: flex;
    order: 2;
    align-items: center;
    height: 40px;
    margin: 0 0 -10px 8px;
    cursor: ns-resize;

    @include media-query(medium) {
        margin-bottom: 0;
        margin-left: 10px;
    }

    .icon {
        fill: $container-default-main-color;
        font-size: 17px;

        @include media-query(medium) {
            font-size: 18px;
        }
    }
}

.sortable-ghost {
    opacity: 0.5;
}
</style>
