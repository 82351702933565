import { validate as validateClass, ValidationError } from 'class-validator';
import hash from 'object-hash';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum';
import { AccordionItemBase } from './accordionItemBase';
import { IContentItem, IContentItemConvertible, IResultOption, IRichText } from './interfaces';
import { ItemsGroup } from './itemsGroup';
import { CarouselContentItemFactory } from './factories/carouselContentItem.factory';
import { CarouselAccordionItemFactory } from './factories/carouselAccordionItem.factory';
import { TextGlobalResponse } from './textGlobalResponse';
import { IConvoState } from '../modules/convo-details';
import { AccordionStyle, ConvoTypes, SortingOrder, ChartType } from '../enums';
import { CarouselTile } from './carouselTile';
import { IObservableItem } from './interfaces/observableItem.interface';
import { getErrorMessage, validateResultOptions } from '@/store/services/validationService';
import { IQuizContentItem } from './interfaces/quizContentItem.interface';
import { ButtonsAnswer } from '.';

export class Carousel
    extends AccordionItemBase
    implements IQuizContentItem, IContentItemConvertible, IObservableItem<IConvoState>, IRichText
{
    constructor(items = []) {
        super(items);

        const tiles = new ItemsGroup();
        const notDraggableItemList = new ItemsGroup();
        const globalResponse = new TextGlobalResponse();

        tiles.accordionStyle = AccordionStyle.Tabs;
        this.type = ConvoMessageItemType.Carousel;
        this.plainText = '';
        this.statement = '';
        this.resultOptions = {
            order: SortingOrder.None,
            chartType: ChartType.None,
            showResponseCount: false,
        } as IResultOption;
        this.isDraggable = true;
        this.isDeletable = true;
        this.icon = 'carousel-horizontal';
        this.maxItems = 10;
        this.areTilesShuffled = false;
        this.shuffleLast = false;
        globalResponse.hasMarginBottom = false;
        notDraggableItemList.items = [globalResponse];
        this.items = [tiles, notDraggableItemList];
        this.ignoreQuizQuestion = false;
        this.showQuizResults = false;
        this.children = [];
        this.setTilesDefaultState();
    }

    plainText: string;
    statement: string;
    maxItems: number;
    areTilesShuffled: boolean;
    shuffleLast: boolean;
    ignoreQuizQuestion: boolean;
    showQuizResults: boolean;
    resultOptions: IResultOption;
    children?: IContentItem[];

    addItem(item: AccordionItemBase): void {
        const tiles = this.items[0].items;
        const pinnedIndex = tiles.findIndex((item) => item.isPinned === true);

        tiles.forEach((item) => (item.isOpen = false));
        item.isOpen = true;

        if (pinnedIndex > -1) {
            tiles.splice(pinnedIndex, 0, item);
        } else {
            tiles.push(item);
        }
    }

    deleteItemCallback() {
        if (this.items[0].items.length === 0) {
            this.setTilesDefaultState();
        }
    }

    setTilesDefaultState() {
        const carouselTile = new CarouselTile();

        carouselTile.isOpen = true;
        this.items[0].items = [carouselTile];
    }

    openFirstTile() {
        if (this.isOpen) {
            this.items[0].items[0].isOpen = true;
        }
    }

    toggle() {
        this.isOpen = !this.isOpen;
        this.openFirstTile();
    }

    open() {
        this.isOpen = true;
        this.openFirstTile();
    }

    itemHasChanges(state: IConvoState): boolean {
        const contentItemFactory = new CarouselContentItemFactory();
        const contentItem = contentItemFactory.make(this, state);

        contentItem.version = hash(contentItem);

        if (this.originalVersion) {
            return contentItem.version !== this.originalVersion;
        }
        return false;
    }

    toContentItem(state: IConvoState): IContentItem {
        const contentItemFactory = new CarouselContentItemFactory();
        return contentItemFactory.make(this, state);
    }

    toAccordionItem(contentItem: IContentItem, isClone?: boolean): AccordionItemBase {
        const accordianItemFactory = new CarouselAccordionItemFactory();
        return accordianItemFactory.make(contentItem, isClone);
    }

    handleIgnoreQuiz(convoType: ConvoTypes) {
        for (const subItem of this.items[0].items) {
            const answer = subItem as CarouselTile;
            for (const answerItem of answer.items) {
                const buttonsAnswer = answerItem as ButtonsAnswer;
                if (convoType === ConvoTypes.PersonalityQuiz) {
                    buttonsAnswer.selectedPersonalityQuizResult = undefined;
                } else if (convoType === ConvoTypes.TriviaQuiz) {
                    buttonsAnswer.isCorrectAnswer = false;
                }
            }
        }
    }

    setTitle(): void {
        let subtitle = '';
        if (this.plainText !== '') {
            subtitle = this.plainText;
        } else {
            const tiles = this.items[0].items;
            if (tiles[0]) {
                subtitle = (tiles[0] as CarouselTile).tileTitle;
            }
        }
        this.subtitle = subtitle;
    }

    async validate(state: IConvoState, path: number[]): Promise<any[]> {
        const errors: ValidationError[] = await validateClass(this);
        const errorMessages: any[] = [];
        const mainLevelErrors: any = {};

        // this model
        if (errors.length > 0) {
            errors.forEach((item: ValidationError) => {
                mainLevelErrors[item.property] = Object.values(item.constraints || {});
            });
        }

        // trivia quiz - number of correct answers
        if (!this.ignoreQuizQuestion && state.convo.type === ConvoTypes.TriviaQuiz) {
            if (!this.items[0].items.some((item) => (item as CarouselTile).hasCorrectAnswer())) {
                if (!mainLevelErrors.hasCorrenctAnswer) {
                    mainLevelErrors.hasCorrenctAnswer = [];
                }

                mainLevelErrors.hasCorrenctAnswer.push(getErrorMessage('triviaQuizCorrectAnswer'));
            }
        }

        if (Object.entries(mainLevelErrors).length > 0) {
            errorMessages.push({ path, errors: mainLevelErrors });
        }

        // tiles
        for (let i = 0; i < this.items[0].items.length; i++) {
            const tile = this.items[0].items[i] as CarouselTile;
            const chilrenErrors: any[] = await tile.validate(state, [...path, 0, i]);

            if (Object.entries(chilrenErrors).length > 0) {
                errorMessages.push(...chilrenErrors);
            }
        }

        // global response
        const globalResponseErrors: any = await this.items[1].items[0].validate(state);

        validateResultOptions(
            this.resultOptions,
            this.items[1].items[0] as TextGlobalResponse,
            globalResponseErrors,
        );

        if (Object.entries(globalResponseErrors).length > 0) {
            errorMessages.push({ path: [...path, 1, 0], errors: globalResponseErrors });
        }

        return errorMessages;
    }
}
