<template>
    <div>
        <TextAreaInput
            :id="generateId('statement', path)"
            label="Message"
            show-remaining-char
            :value="data.statement"
            :disabled="isDisabled"
            :errors="data.errors.plainText"
            :max="640"
            @input="onInput('statement', $event)"
            @plainText="onInput('plainText', $event)"
        />
        <div class="rating-option">
            <Select
                :id="generateId('category', path)"
                label="Category"
                class="rating-option-category"
                placeholder="Select category"
                show-clear-icon
                :has-margin-bottom="false"
                :disabled="convoCategories.length === 0"
                :is-loading="$wait.is($options.LoadingFlag.CategoryGet)"
                :options="convoCategories"
                :value="data.ratingTarget && data.ratingTarget.id"
                :errors="data.errors.ratingTarget"
                id-key="slug"
                name-key="name"
                @input="onRatingTarget"
            />
            <NumberInput
                :id="generateId('steps', path)"
                class="rating-option-steps"
                :has-margin-bottom="false"
                :value="scoreOptions.length"
                :max="data.maxItems"
                label="Steps"
                @input="onUpdateSteps"
            />
        </div>
        <ErrorMessage v-if="ratingErrors">
            {{ ratingErrors }}
        </ErrorMessage>
        <RatingOption
            v-for="(item, index) in scoreOptions"
            :key="item.id + '-' + index"
            :data="item"
            :index="index"
            :path="[...path, index]"
            @propertyOnChange="$emit('propertyOnChange', $event)"
        />
        <div class="align-center m-b-2">
            <TextButton v-if="!logicJump" icon="plus-2" @click="openLogicJump">
                Add logic jump
            </TextButton>
            <AttributeButton
                v-else
                class="button-answer__attribute-button"
                icon="logic-jump"
                @click="$emit('openLogicJump', path)"
                @remove="
                    $emit('removeAttribute', { path: path, value: $options.attributes.LogicJump })
                "
            >
                <Badge v-if="logicJump.type" :type="logicJump.type" class="logic-jump-badge">{{
                    logicJump.type
                }}</Badge>
                {{ logicJump.label }}
            </AttributeButton>
        </div>
    </div>
</template>

<script>
import itemFormMixin from '@/mixins/itemFormMixin';
import { TextAreaInput, Select, NumberInput } from '@/components/forms';
import { LoadingFlag, ConvoMessageItemType, Attributes } from '@/store/enums';
import RatingOption from '@/components/RatingOption.vue';
import TextButton from '@/components/ui/TextButton';
import ErrorMessage from '@/components/ui/ErrorMessage';
import config from '@/config';
import Badge from '@/components/ui/Badge';
import AttributeButton from '@/components/ui/AttributeButton.vue';
export default {
    name: 'Rating',
    components: {
        AttributeButton,
        NumberInput,
        TextAreaInput,
        Select,
        RatingOption,
        ErrorMessage,
        TextButton,
        Badge,
    },
    mixins: [itemFormMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        convoCategories: {
            type: Array,
            default: () => [],
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        scoreOptions() {
            return this.data.items;
        },
        ratingErrors() {
            return this.data.errors.ratingOptions?.length && this.data.errors.ratingOptions[0];
        },
        logicJump() {
            const { message, convo, channel } = this.data.logicJump?.items || {};
            const defaultText = 'logic jump';
            if (message?.type) {
                return {
                    type: message.type,
                    label: message.text || defaultText,
                };
            }
            if (convo?.type) {
                return {
                    type: convo.type,
                    label: convo.text || defaultText,
                };
            }
            if (channel?.type) {
                return {
                    type: channel.type,
                    label: channel.text || defaultText,
                };
            }
            return undefined;
        },
    },
    created() {
        this.$options.attributes = Attributes;
        this.$options.richTextMessagesEnabled = config.featureToggle.richTextMessagesEnabled;
        this.$options.LoadingFlag = LoadingFlag;
    },
    methods: {
        openLogicJump() {
            this.$emit('addAttribute', {
                path: this.path,
                value: this.$options.attributes.LogicJump,
            });
            this.$emit('openLogicJump', this.path);
        },
        onRatingTarget(id) {
            if (id) {
                this.onInput('ratingTarget', { type: 'category', id });
            } else {
                this.onInput('ratingTarget', undefined);
            }
        },
        addAnswerOnClick(event) {
            this.$emit('addItemOnClick', event, this.path, ConvoMessageItemType.RatingOption);
        },
        onUpdateSteps(event) {
            if (event > this.scoreOptions.length) {
                this.$emit('addItemOnClick', event, this.path, ConvoMessageItemType.RatingOption);
            }
            if (event < this.scoreOptions.length) {
                this.$emit('deleteOnClick', event, [...this.path, event], undefined);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.rating-option {
    display: flex;
    gap: 1rem;
    margin: 1.5rem 0 4rem;
    &-category {
        flex-grow: 10;
    }
    &-steps {
        flex: 1 1 100px;
    }
}
.logic-jump-badge {
    margin-right: 0.5rem;
}
</style>
