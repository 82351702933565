import axios from '@/utils/request';
import { LoadingFlag } from '@/store/enums';
import { CmsUserDto } from '@/store/models/dtos';

export class UserClient {
    public static async getUserRoles(userId: string): Promise<any> {
        const url = `/users/${userId}/roles`;

        const result = await axios
            .request<any>({
                url,
                method: 'GET',
                errorMessage: 'Could not get user roles',
                loadingId: LoadingFlag.UserGetRoles,
            })
            .then((o) => o);

        return result as unknown as Promise<any>;
    }

    public static async getUsers(): Promise<CmsUserDto[]> {
        const url = `/users/list`;
        const result = await axios.request<any>({
            url,
            method: 'GET',
            errorMessage: 'Could not get users',
            loadingId: LoadingFlag.UserGet,
        });
        return result as unknown as Promise<CmsUserDto[]>;
    }

    public static async getUser(username: string): Promise<CmsUserDto> {
        const url = `/users/${username}`;
        const result = await axios.request<any>({
            url,
            method: 'GET',
            errorMessage: 'Could not get user',
            loadingId: LoadingFlag.UserGetOne,
        });
        return result as unknown as Promise<CmsUserDto>;
    }

    public static async updateUserGroups(username: string, groups: string[]): Promise<CmsUserDto> {
        const url = `/users/update-groups`;
        const result = await axios.request<any>({
            url,
            data: {
                username,
                group: groups,
            },
            method: 'POST',
            errorMessage: 'Could not update user groups',
            loadingId: LoadingFlag.UserSaveGroups,
        });
        return result as unknown as Promise<CmsUserDto>;
    }
}
