import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum';
import { getErrorMessage, validateResultOptions } from '@/store/services/validationService';
import { IsNotEmpty, Length, validate as validateClass } from 'class-validator';
import hash from 'object-hash';
import { QuickReplyAnswer } from '.';
import { ButtonViewLayout, ChartType, ConvoTypes, SortingOrder } from '../enums';
import { IConvoState } from '../modules/convo-details';
import { AccordionItemBase } from './accordionItemBase';
import { QuickReplyAccordionItemFactory } from './factories/quickReplyAccordionItem.factory';
import { QuickReplyContentItemFactory } from './factories/quickReplyContentItem.factory';
import {
    IContentItem,
    IContentItemConvertible,
    IDatumContentItem,
    IResultOption,
    IRichText,
    ITag,
} from './interfaces';
import { IObservableItem } from './interfaces/observableItem.interface';
import { IQuizContentItem } from './interfaces/quizContentItem.interface';
import { ItemsGroup } from './itemsGroup';
import { TextGlobalResponse } from './textGlobalResponse';

export class QuickReply
    extends AccordionItemBase
    implements
        IQuizContentItem,
        IContentItemConvertible,
        IObservableItem<IConvoState>,
        IRichText,
        IDatumContentItem
{
    constructor(items = []) {
        super(items);

        const quickReplyAnswers = new ItemsGroup();
        const notDraggableItemList = new ItemsGroup();
        const globalResponse = new TextGlobalResponse();

        this.type = ConvoMessageItemType.QuickReply;
        this.plainText = '';
        this.statement = '';
        this.isMultipleChoice = false;
        this.submitButtonText = '';
        this.layout = ButtonViewLayout.horizontal;
        this.minLimit = 0;
        this.maxLimit = 0;
        this.resultOptions = {
            order: SortingOrder.None,
            chartType: ChartType.None,
            showResponseCount: false,
        } as IResultOption;
        this.isDraggable = true;
        this.isDeletable = true;
        this.icon = 'quick-reply';
        this.maxItems = 11;
        this.areAnswersShuffled = false;
        this.pinLastAnswer = false;
        globalResponse.hasMarginBottom = false;
        notDraggableItemList.items = [globalResponse];
        this.items = [quickReplyAnswers, notDraggableItemList];
        this.ignoreQuizQuestion = false;
        this.showQuizResults = false;
        this.tags = [];
        this.datumDefinition = '';
    }

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Message') })
    @Length(0, 640, { message: getErrorMessage('length', 'Message', { min: 0, max: 640 }) })
    plainText: string;
    statement: string;
    submitButtonText: string;
    maxItems: number;
    isMultipleChoice: boolean;
    layout: string;
    minLimit: number;
    maxLimit: number;
    resultOptions: IResultOption;
    areAnswersShuffled: boolean;
    pinLastAnswer: boolean;
    ignoreQuizQuestion: boolean;
    showQuizResults: boolean;
    tags?: ITag[];
    datumDefinition: string;
    category: string;

    setTitle(): void {
        this.subtitle = this.plainText;
    }

    addItem(item: AccordionItemBase): void {
        const answer = this.items[0].items;
        const pinnedIndex = answer.findIndex((item) => item.isPinned === true);
        if (pinnedIndex > -1) {
            answer.splice(pinnedIndex, 0, item);
        } else {
            answer.push(item);
        }
    }

    itemHasChanges(state: IConvoState): boolean {
        const contentItemFactory = new QuickReplyContentItemFactory();
        const contentItem = contentItemFactory.make(this, state);

        contentItem.version = hash(contentItem);

        if (this.originalVersion) {
            return contentItem.version !== this.originalVersion;
        }
        return false;
    }

    toContentItem(state: IConvoState): IContentItem {
        const contentItemFactory = new QuickReplyContentItemFactory();
        return contentItemFactory.make(this, state);
    }

    toAccordionItem(contentItem: IContentItem, isClone?: boolean): AccordionItemBase {
        const accordianItemFactory = new QuickReplyAccordionItemFactory();
        return accordianItemFactory.make(contentItem, isClone);
    }

    handleIgnoreQuiz(convoType: ConvoTypes) {
        for (const subItem of this.items[0].items) {
            const answer = subItem as QuickReplyAnswer;
            if (convoType === ConvoTypes.PersonalityQuiz) {
                answer.selectedPersonalityQuizResult = undefined;
            } else if (convoType === ConvoTypes.TriviaQuiz) {
                answer.isCorrectAnswer = false;
            }
        }
    }

    async validate(state: IConvoState, path: number[]): Promise<any[]> {
        const errorMessages: any[] = [];
        const errors = await validateClass(this);
        const mainLevelErrors: any = {};

        // this component
        if (errors.length > 0) {
            errors.forEach((item) => {
                mainLevelErrors[item.property] = Object.values(item.constraints || {});
            });
        }

        // number of answers
        if (this.items[0].items.length === 0 || this.items[0].items.length > this.maxItems) {
            mainLevelErrors.quickReplyAnswers = [];
            mainLevelErrors.quickReplyAnswers.push(
                getErrorMessage('buttonsLength', 'Quick Reply', { min: 1, max: this.maxItems }),
            );
        }

        // trivia quiz - number of correct answers
        if (!this.ignoreQuizQuestion && state.convo.type === ConvoTypes.TriviaQuiz) {
            if (!this.items[0].items.some((item) => (item as QuickReplyAnswer).isCorrectAnswer)) {
                if (!mainLevelErrors.quickReplyAnswers) {
                    mainLevelErrors.quickReplyAnswers = [];
                }

                mainLevelErrors.quickReplyAnswers.push(getErrorMessage('triviaQuizCorrectAnswer'));
            }
        }

        if (Object.entries(mainLevelErrors).length > 0) {
            errorMessages.push({ path, errors: mainLevelErrors });
        }

        // answers
        for (let i = 0; i < this.items[0].items.length; i++) {
            const answer = this.items[0].items[i] as QuickReplyAnswer;
            const chilrenErrors = await answer.validate(state, [...path, 0, i]);

            if (Object.entries(chilrenErrors).length > 0) {
                errorMessages.push(...chilrenErrors);
            }
        }

        // global response
        const globalResponseErrors: any = await this.items[1].items[0].validate(state);

        validateResultOptions(
            this.resultOptions,
            this.items[1].items[0] as TextGlobalResponse,
            globalResponseErrors,
        );

        if (Object.entries(globalResponseErrors).length > 0) {
            errorMessages.push({ path: [...path, 1, 0], errors: globalResponseErrors });
        }

        return errorMessages;
    }

    clearDatumDefinition(): void {
        this.datumDefinition = '';
        this.items[0].items.forEach((item) => {
            (item as QuickReplyAnswer).clearDatumAnswer();
        });
    }
}
