<template>
    <div
        class="options-group"
        :class="{
            'form-field': isFormFieldStyled,
            'horizontal-styled': horizontalStyled,
        }"
    >
        <div
            v-if="isFormFieldStyled || showLabel"
            :class="{ 'form-field-label': isFormFieldStyled, 'checkbox-group-label': showLabel }"
        >
            <span>{{ label }}</span>
        </div>
        <div v-if="description" class="group-description">{{ description }}</div>
        <div class="group-options">
            <Checkbox
                v-for="(option, index) of options"
                :id="`options-${option.id}`"
                :key="index"
                class="checkbox-option"
                :has-margin-bottom="false"
                :label="option.name"
                :value="optionIsSelected(option.id)"
                @input="(value) => onCheckboxInput(option.id, value)"
            />
        </div>
        <div class="error-message">{{ errors[0] }}</div>
    </div>
</template>

<script>
import Checkbox from '@/components/forms/Checkbox';

export default {
    name: 'CheckboxGroup',
    components: {
        Checkbox,
    },
    props: {
        selectedOptions: {
            type: Array,
            default: null,
        },
        options: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        errors: {
            type: Array,
            default: () => [],
        },
        isFormFieldStyled: {
            type: Boolean,
            default: false,
        },
        horizontalStyled: {
            type: Boolean,
            default: false,
        },
        showLabel: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            innerSelectedOptions: this.selectedOptions,
        };
    },
    watch: {
        selectedOptions(newValue) {
            this.innerSelectedOptions = newValue;
        },
    },
    methods: {
        optionIsSelected(id) {
            if (this.selectedOptions) {
                return this.selectedOptions.some((o) => o.id === id);
            }
            return false;
        },
        onCheckboxInput(id, checked) {
            this.innerSelectedOptions = this.innerSelectedOptions.filter((o) => o.id !== id);

            this.options.forEach((opt) => {
                if (opt.id === id && checked) {
                    this.innerSelectedOptions.push({ id: opt.id, name: opt.name });
                }
            });
            this.$emit('input', this.innerSelectedOptions);
        },
    },
};
</script>

<style lang="scss" scoped>
$form-group-margin-bottom: 20px !default;

.group-options {
    margin-top: -12px;
}
.checkbox-option {
    padding-top: 12px;
}
.form-field {
    padding: 1rem;

    .group-options {
        display: flex;
        flex-flow: row wrap;
    }
    .checkbox-option {
        align-items: left;
        width: 33%;
    }
}
.checkbox-group-label {
    margin-bottom: 12px;
    font-size: $font-size-sm;
}
.group-description {
    font-weight: $font-weight-light;
}
.horizontal-styled {
    .group-options {
        margin: $form-group-margin-bottom;
        margin-top: 0;
        display: flex;
        flex-flow: row wrap;
    }
    .checkbox-option {
        align-items: left;
        width: 33%;
    }
}

.error-message {
    text-align: right;
    right: 5px;
    font-size: $font-size-xs;
    font-style: italic;
    color: $error-color;
}
</style>
