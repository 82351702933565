import { ConvoMessageItemType } from '@/store/enums';
import { TextGlobalResponse } from '@/store/models';
import { AccordionItemBase } from '@/store/models/accordionItemBase';
import { DatePickerAccordionItemFactory } from '@/store/models/factories/datePickerAccordionItem.factory';
import { DatePickerContentItemFactory } from '@/store/models/factories/datePickerContentItem.factory';
import {
    DateType,
    IContentItem,
    IContentItemConvertible,
    IDatumContentItem,
    IRichText,
} from '@/store/models/interfaces';
import { IObservableItem } from '@/store/models/interfaces/observableItem.interface';
import { IConvoState } from '@/store/modules/convo-details';
import { getErrorMessage } from '@/store/services/validationService';
import {
    IsNotEmpty,
    IsOptional,
    IsString,
    Length,
    validate as validateClass,
    ValidateIf,
    ValidationError,
} from 'class-validator';
import hash from 'object-hash';

export class DatePicker
    extends AccordionItemBase
    implements IContentItemConvertible, IObservableItem<IConvoState>, IRichText, IDatumContentItem
{
    @IsOptional()
    @IsString()
    datumDefinition: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Message') })
    @Length(0, 640, { message: getErrorMessage('length', 'Message', { min: 0, max: 640 }) })
    plainText: string;
    message: string;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Date type') })
    dateType: string;

    @ValidateIf((o) => o.dateType === DateType.FutureRange || o.dateType === DateType.PastRange)
    @IsNotEmpty()
    dateRangeType?: string;

    @ValidateIf((o) => o.dateType === DateType.FutureRange || o.dateType === DateType.PastRange)
    @IsNotEmpty()
    dateRangeValue?: number;

    dateRangeValueOffset?: number;

    @IsNotEmpty({ message: getErrorMessage('isNotEmpty', 'Submit button text') })
    submitButtonText: string;

    constructor(items = []) {
        super(items);
        this.type = ConvoMessageItemType.DatePicker;
        this.icon = 'calendar';
        this.plainText = '';
        this.message = '';
        this.dateType = '';
        this.submitButtonText = '';
        this.isDraggable = true;
        this.isDeletable = true;
        this.datumDefinition = '';
        const globalResponse = new TextGlobalResponse();
        globalResponse.hasMarginBottom = false;
        this.items = [globalResponse];
    }

    setTitle(): void {
        this.subtitle = this.plainText;
    }

    toContentItem(state: IConvoState): IContentItem {
        const contentItemFactory = new DatePickerContentItemFactory();
        return contentItemFactory.make(this, state);
    }

    toAccordionItem(contentItem: IContentItem, isClone?: boolean): AccordionItemBase {
        const accordionItemFactory = new DatePickerAccordionItemFactory();
        return accordionItemFactory.make(contentItem);
    }

    itemHasChanges(state: IConvoState): boolean {
        const contentItemFactory = new DatePickerContentItemFactory();
        const contentItem = contentItemFactory.make(this, state);
        contentItem.version = hash(contentItem);
        if (this.originalVersion) {
            return contentItem.version !== this.originalVersion;
        }
        return false;
    }

    async validate(state: IConvoState, path: number[]): Promise<any[]> {
        const errors: ValidationError[] = await validateClass(this);
        const errorMessages: any[] = [];

        if (errors.length > 0) {
            errors.forEach((item: ValidationError) => {
                errorMessages.push({
                    path,
                    errors: { [item.property]: Object.values(item.constraints || {}) },
                });
            });
        }

        const globalResponseErrors: any[] = await this.items[0].validate(state);
        if (Object.entries(globalResponseErrors).length > 0) {
            errorMessages.push({ path: [...path, 0], errors: globalResponseErrors });
        }

        return errorMessages;
    }

    clearDatumDefinition(): void {
        this.datumDefinition = '';
    }
}
