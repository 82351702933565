import templateVariables from '@/json/templateVariables';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum';
import {
    Buttons,
    TextStatement,
    QuickReply,
    TextInput,
    SelectMessageType,
    QuickReplyAnswer,
    SelectInlineResponseType,
    AttachmentUrl,
    ButtonsAnswer,
    Carousel,
    CarouselTile,
    InlineResponseTextStatement,
    Registration,
    DatePicker,
    Video,
    CommentsRating,
    CommentsRatingTargetInput,
    Gif,
    Image,
    Rating,
    RatingOption,
} from '@/store/models';
import { ConvoTypes, SortingOrder } from '../enums';
import { IConvoState } from '../modules/convo-details';

type item = {
    key: string;
    value: string;
    displayText: string;
    sampleText?: string;
    sortingOrder?: SortingOrder;
};

type TemplateVariable = {
    name: string;
    items: item[];
};

export const variableList = (templateVariables as TemplateVariable[])
    .map((item) => item.items)
    .reduce((acc, val) => acc.concat(val), []);

export const getConvoMessageItemByType = (messageType: string, state: IConvoState): any => {
    switch (messageType) {
        case ConvoMessageItemType.SelectMessageType:
            return new SelectMessageType(state.convo.type as ConvoTypes);
        case ConvoMessageItemType.SelectInlineResponseType:
            return new SelectInlineResponseType();
        case ConvoMessageItemType.Buttons:
            return new Buttons();
        case ConvoMessageItemType.ButtonsAnswer:
            return new ButtonsAnswer();
        case ConvoMessageItemType.TextStatement:
            return new TextStatement();
        case ConvoMessageItemType.QuickReply:
            return new QuickReply();
        case ConvoMessageItemType.QuickReplyAnswer:
            return new QuickReplyAnswer();
        case ConvoMessageItemType.TextInput:
            return new TextInput();
        case ConvoMessageItemType.AttachmentUrl:
            return new AttachmentUrl();
        case ConvoMessageItemType.Carousel:
            return new Carousel();
        case ConvoMessageItemType.CarouselTile:
            return new CarouselTile();
        case ConvoMessageItemType.InlineResponseTextStatement:
            return new InlineResponseTextStatement();
        case ConvoMessageItemType.Registration:
            return new Registration();
        case ConvoMessageItemType.DatePicker:
            return new DatePicker();
        case ConvoMessageItemType.Video:
            return new Video();
        case ConvoMessageItemType.CommentsRating:
            return new CommentsRating();
        case ConvoMessageItemType.CommentsRatingTargetInput:
            return new CommentsRatingTargetInput();
        case ConvoMessageItemType.Gif:
            return new Gif();
        case ConvoMessageItemType.Image:
            return new Image();
        case ConvoMessageItemType.Rating:
            return new Rating();
        case ConvoMessageItemType.RatingOption:
            return new RatingOption();
        default:
            throw new Error(`There is no message type with this name: '${messageType}'.`);
    }
};

export const replaceTemplateVariablesForPreview = (
    text: string,
    sortingOrder?: SortingOrder,
): string => {
    for (const variable of variableList) {
        let isItemPresent = false;
        if (sortingOrder) {
            isItemPresent = text.includes(variable.value) && sortingOrder == variable.sortingOrder;
        } else {
            isItemPresent = text.includes(variable.value);
        }
        if (isItemPresent) {
            text = text.replace(
                variable.value,
                `${variable.sampleText || `[${variable.displayText}]`}`,
            );
            break;
        }
    }

    return text;
};
