import { IContentItem, IConvo, ITag } from '../interfaces';
import { Convo, ItemsGroup } from '..';
import { ConvoEndLogic } from '../convo/convoEndLogic';
import { ChatGroup, TagType } from '@/store/enums';
import config from '@/config';

export class ConvoFactory {
    public make(savedConvo: IConvo): Convo {
        const convo = new Convo();

        convo.id = savedConvo.id;
        convo.createdAt = savedConvo.createdAt;
        convo.updatedAt = savedConvo.updatedAt;
        convo.userId = savedConvo.userId;
        convo.channelId = savedConvo.channelId;
        convo.segment = savedConvo.segment;
        convo.type = savedConvo.type;
        convo.name = savedConvo.name;
        convo.widgetsTotal = savedConvo.widgetsTotal;
        convo.broadcasted = savedConvo.broadcasted;
        convo.sort = savedConvo.sort;
        convo.contentItems = savedConvo.contentItems;
        convo.statuses = savedConvo.statuses;
        convo.results = savedConvo.results;
        convo.broadcasts = savedConvo.broadcasts;
        convo.redirectUrl = savedConvo.redirectUrl;
        convo.published = savedConvo.published;
        convo.version = savedConvo.version || 0;
        convo.lastPublishedVersion = savedConvo.lastPublishedVersion || 0;
        convo.image = savedConvo.image ? savedConvo.image : '';
        convo.description = savedConvo.description ? savedConvo.description : '';
        convo.pdlTagsInConvo = this.getPdlTagsInConvo(savedConvo.contentItems);
        convo.tags = savedConvo.tags;
        convo.topics = savedConvo.topics;

        convo.showFromDate = savedConvo.showFromDate
            ? new Date(savedConvo.showFromDate as unknown as string)
            : undefined;
        convo.hideFromDate = savedConvo.hideFromDate
            ? new Date(savedConvo.hideFromDate as unknown as string)
            : undefined;

        if (config.featureToggle.convoEndLogic) {
            const convoEndLogic = new ConvoEndLogic();
            if (savedConvo.endLogic) {
                convoEndLogic.type = savedConvo.endLogic.type;
                convoEndLogic.content = savedConvo.endLogic.content;
            }
            convo.endLogic = convoEndLogic;
        }

        convo.chatGroup = (savedConvo.chatGroup as ChatGroup) || ChatGroup.Editorial;
        convo.dailyChat = savedConvo.dailyChat;

        convo.isGlobal = savedConvo.isGlobal === undefined ? true : savedConvo.isGlobal;
        convo.countries = savedConvo.countries || [];
        convo.categories = savedConvo.categories || [];

        return convo;
    }

    private getPdlTagsInConvo(contentItems: IContentItem[]): ITag[] {
        return (contentItems || []).reduce((tags, contentItem) => {
            const contentTags = ((contentItem.isPdl && contentItem.tags) || []).filter((tag) => {
                const isUnique = tags.find((t) => t.id === tag.id) === undefined;
                const isPdlTag = tag.type === TagType.pdl;
                return isUnique && isPdlTag;
            });
            return [...tags, ...contentTags];
        }, <ITag[]>[]);
    }
}
