<template>
    <div v-click-outside="onBlur" class="form-group">
        <div
            class="form-field"
            :class="{
                'form-field--has-error': errors.length > 0,
                'form-field--has-warning': warnings.length > 0 && errors.length === 0,
                'form-field--is-focused': isFocused,
                'form-field--is-disabled': isDisabled,
            }"
        >
            <label v-if="label" :for="forId" class="form-field-label">
                <span>{{ label }}</span>
            </label>
            <slot />
        </div>
        <div v-if="showAdditionalInfo" class="form-field-additional-information">
            <div class="form-field-additional-information__left">
                <div v-if="errors && errors.length > 0">
                    <ErrorMessage :message="errors[0]" />
                </div>
                <div v-else-if="warnings && warnings.length > 0">
                    <WarningMessage :message="warnings[0]" />
                </div>
                <div v-if="infoText" class="form-field-info-text">{{ infoText }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import WarningMessage from '@/components/forms/partials/WarningMessage';
import ErrorMessage from '@/components/forms/partials/ErrorMessage';

export default {
    name: 'FormFieldWrapper',
    components: {
        ErrorMessage,
        WarningMessage,
    },
    props: {
        errors: {
            type: Array,
            default: () => [],
        },
        warnings: {
            type: Array,
            default: () => [],
        },
        infoText: {
            type: String,
            default: undefined,
        },
        label: {
            type: String,
            default: undefined,
        },
        forId: {
            type: String,
            default: undefined,
        },
        showErrorMessages: {
            type: Boolean,
            default: true,
        },
        showWarningMessages: {
            type: Boolean,
            default: true,
        },
        isFocused: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        showAdditionalInfo() {
            return (
                (this.showErrorMessages && this.errors?.length > 0) ||
                (this.showWarningMessages && this.warnings?.length > 0) ||
                this.infoText
            );
        },
    },
    methods: {
        onBlur() {
            this.$emit('blur');
        },
    },
};
</script>

<style lang="scss" scoped>
.form-field-additional-information {
    margin-top: 0.3rem;
}
</style>
