<template>
    <div v-if="accordionStyle === AccordionStyle.ItemsGroup">
        <slot />
    </div>
    <Panel
        v-else-if="accordionStyle === AccordionStyle.Panel"
        v-bind="$attrs"
        @deleteOnClick="$emit('deleteOnClick', $event)"
    >
        <template #header>
            <slot name="header" />
        </template>
        <slot />
    </Panel>
    <DefaultAccordionItem
        v-else
        v-bind="$attrs"
        :accordion-style="accordionStyle"
        @headerOnClick="$emit('headerOnClick', $event)"
        @onExpand="$emit('onExpand')"
        @onExpanded="$emit('onExpanded')"
        @onContract="$emit('onContract')"
        @onContracted="$emit('onContracted')"
        @deleteOnClick="$emit('deleteOnClick', $event)"
        @cloneOnClick="$emit('cloneOnClick', $event)"
        @undoDeletedItemOnClick="$emit('undoDeletedItemOnClick', $event)"
    >
        <template #header>
            <slot name="header" />
        </template>
        <slot />
    </DefaultAccordionItem>
</template>

<script>
import DefaultAccordionItem from '@/components/ui/Accordion/DefaultAccordionItem';
import Panel from '@/components/ui/Accordion/Panel';
import { AccordionStyle } from '@/store/enums/accordionStyle.enum.ts';

export default {
    name: 'AccordionItem',
    components: {
        DefaultAccordionItem,
        Panel,
    },
    props: {
        accordionStyle: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            AccordionStyle,
        };
    },
};
</script>
